/**
 * Greek translations for common/profile-image.
 *
 * @module languages/el/common/profile-image
 */
const profileImage = {
  cancel_button_label: 'Ακύρωση',
  change_button_label: 'Μεταβολή',
  delete_button_label: 'Διαγραφή',
  editor_title_company_agent: 'Λογότυπο',
  editor_title_job_seeker: 'Εικόνα προφίλ',
  no_logo_message: 'Ανεβάστε το λογότυπό σας εδώ.',
  no_picture_message: 'Ανεβάστε την εικόνα του προφίλ σας εδώ',
  save_button_label: 'Αποθήκευση',
  too_large_image_error: 'Μεταφορτώστε μια εικόνα έως 8 MB.',
  unsupported_image_format_error: 'Ανεβάστε μια εικόνα σε μορφή JPEG ή PNG.',
  upload_button_label: 'Μεταφόρτωση',
};

export default profileImage;
