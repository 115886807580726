/**
 * Greek translations for job-seekers/share-profile-button.
 *
 * @module languages/el/job-seekers/share-profile-button
 */
const shareProfileButton = {
  modal: {
    copy_link_button_label: 'Αντιγραφή συνδέσμου',
    link_copied_message: 'Ο σύνδεσμος αντιγράφηκε.',
    link_copy_failed_error: 'Η αντιγραφή συνδέσμου απέτυχε.',
    title: 'Μοιραστείτε το προφίλ σας',
  },
  share_profile_button_label: 'Μοιραστείτε το προφίλ σας',
};

export default shareProfileButton;
