/* eslint-disable */
import React, { memo } from 'react';

const PhotoCardIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 51 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.96978 36.8518C3.35023 29.1159 2.91949 19.7603 3.60867 11.8176C3.97048 7.64814 4.02217 7.18295 8.41564 6.78668C10.9828 6.54547 13.5672 6.54547 16.1516 6.52824C21.0275 6.47655 25.9206 6.61439 30.7965 6.64885C34.2251 6.66607 38.4119 5.95967 41.7543 6.61439C45.9066 7.42416 45.6826 8.23394 46.0272 12.5585C46.3029 16.0388 46.4235 19.5191 46.7336 22.9995C46.9231 25.1359 48.8356 31.9587 47.7846 33.8022C45.8205 37.2481 35.6379 37.093 32.037 37.2653C22.7677 37.7305 13.1537 37.7822 3.90156 36.7829C2.48876 36.6278 2.48876 38.8504 3.90156 39.0055C18.6326 40.625 34.6731 40.3666 49.2319 37.4893C49.6971 37.4031 50.0589 36.8863 50.0416 36.4211C49.921 28.978 48.9217 21.4833 48.3532 14.0402C48.1981 12.0761 48.8356 6.76945 46.9748 5.13267C44.7867 3.20298 39.1183 4.40904 36.5683 4.42627C31.4857 4.44349 26.403 4.35735 21.3204 4.32289C16.7719 4.28843 5.46943 2.47935 1.79959 6.20088C-0.112865 8.14779 1.50669 14.3848 1.54115 16.8486C1.61007 23.8265 1.40331 30.5631 2.85058 37.4204C3.12624 38.8332 5.26268 38.2474 4.96978 36.8518Z'
        fill='#161616'
      />
      <path
        d='M23.51 9.37107C17.2385 10.1808 12.3282 14.2814 12.0008 20.8975C11.6907 27.1345 15.55 32.8546 21.6492 34.4052C30.0054 36.5244 37.3796 31.6141 38.017 22.8788C38.6373 14.4193 31.4182 8.06164 23.2171 9.42275C21.8043 9.66396 22.4073 11.8004 23.8029 11.5592C32.004 10.1981 37.6208 18.158 35.2087 25.7217C33.0206 32.5789 24.9055 34.6981 18.9959 30.9249C10.5708 25.5322 13.9305 12.8342 23.51 11.5936C24.9055 11.4041 24.9228 9.18154 23.51 9.37107Z'
        fill='#161616'
      />
      <path
        d='M35.4649 4.99474C35.4649 3.73701 35.4477 2.4965 35.4994 1.23876C35.1376 1.60057 34.7758 1.97962 34.3967 2.34143C35.5511 2.25529 38.8763 1.60057 38.5145 3.58194C38.2561 4.97752 40.3925 5.58054 40.651 4.16774C40.9439 2.53095 40.4959 0.997546 38.8247 0.360062C37.4291 -0.174047 35.844 0.0154756 34.3967 0.118851C33.7765 0.15331 33.3113 0.601272 33.2941 1.22153C33.2424 2.47927 33.2596 3.71978 33.2596 4.97752C33.2596 6.40755 35.4649 6.42478 35.4649 4.99474Z'
        fill='#161616'
      />
      <path
        d='M24.1454 14.2468C20.286 14.7465 17.2364 17.262 17.0469 21.3453C16.8746 25.1357 19.235 28.6333 22.9566 29.5809C28.0737 30.8731 32.605 27.8235 32.984 22.4824C33.3458 17.2792 28.8835 13.4543 23.8697 14.2813C22.4569 14.5225 23.06 16.6589 24.4555 16.4177C28.8318 15.6941 31.7263 20.0186 30.4513 23.9986C29.2797 27.6512 24.9379 28.7367 21.8022 26.7208C17.3915 23.878 19.08 17.1241 24.1626 16.4694C25.541 16.2971 25.5582 14.0745 24.1454 14.2468Z'
        fill='#161616'
      />
    </svg>
  );
});

PhotoCardIcon.displayName = 'PhotoCardIcon';

PhotoCardIcon.propTypes = {};

PhotoCardIcon.defaultProps = {};

export default PhotoCardIcon;
