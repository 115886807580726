/**
 * Search Sort component
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PopupMenu from 'components/common/popup-menu/popup-menu';
import { useSm, useXs } from 'hooks/media-queries';
import sortImage from 'images/sort.png';
import roles, * as Roles from 'utilities/auth/roles';
import companiesSortKeys, * as CompaniesSortKeys from 'utilities/companies/sort-keys';
import endorsersSortKeys, * as EndorsersSortKeys from 'utilities/endorsers/sort-keys';
import jobSeekersSortKeys, * as JobSeekersSortKeys from 'utilities/job-seekers/sort-keys';

import './search-sort.scss';

const SearchSort = memo((props) => {
  const { sort, onChange, role } = props;

  const { t } = useTranslation();

  const isXs = useXs();

  const isSm = useSm();

  // popup position
  const popupPosition = useMemo(() => {
    return isXs || isSm ? 'left' : 'right';
  }, [ isSm, isXs ]);

  // The sorting label
  const sortingLabel = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t(`utilities:job_seekers.sort_keys.${ sort }`);
    case Roles.COMPANY_AGENT:
      return t(`utilities:companies.sort_keys.${ sort }`);
    case Roles.ENDORSER:
      return t(`utilities:endorsers.sort_keys.${ sort }`);
    default:
      return '';
    }
  }, [ role, sort, t ]);

  // sort button label
  const buttonLabel = useMemo(() => {
    return `${ t('common:search_sort.sort_by_button_label') }: ${ sortingLabel }`;
  }, [ sortingLabel, t ]);

  // Function that handles the sorting option click
  const handleSortKeyClick = useCallback((sortKey) => {
    return (_event) => {
      onChange(sortKey);
    };
  }, [ onChange ]);

  // The sort keys based on the role
  const sortKeys = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersSortKeys;
    case Roles.COMPANY_AGENT:
      return companiesSortKeys;
    case Roles.ENDORSER:
      return endorsersSortKeys;
    default:
      return [];
    }
  }, [ role ]);

  // function that generates the sort key label popup option based on the role
  const generateSortKeyLabel = useCallback((sortKey) => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t(`utilities:job_seekers.sort_keys.${ sortKey }`);
    case Roles.COMPANY_AGENT:
      return t(`utilities:companies.sort_keys.${ sortKey }`);
    case Roles.ENDORSER:
      return t(`utilities:endorsers.sort_keys.${ sortKey }`);
    default:
      return '';
    }
  }, [ role, t ]);

  // popup options
  // We will need to take into consideration the ENDORSERS role also
  const popupOptions = useMemo(() => {
    return sortKeys.map((sortKey) => {
      return (
        <button
          className='sort-key txt txt-md'
          key={ sortKey }
          onClick={ handleSortKeyClick(sortKey) }
          type='button'
        >
          { generateSortKeyLabel(sortKey) }
        </button>
      );
    });
  }, [ generateSortKeyLabel, handleSortKeyClick, sortKeys ]);

  return (
    <div className='search-sort light'>
      <PopupMenu
        className='sort-popupmenu'
        offsetY={ 10 }
        position={ `bottom ${ popupPosition }` }
        trigger={
          <button
            className={
              clsx('btn btn-custom btn-rounded-sm', {
                'btn-purple': role === Roles.ENDORSER,
                'btn-red': role === Roles.JOB_SEEKER,
                'btn-yellow': role === Roles.COMPANY_AGENT,
              })
            }
            type='button'
          >
            <img alt='Sort' src={ sortImage } />
            <span>
              { buttonLabel }
            </span>
          </button>
        }
      >
        { popupOptions }
      </PopupMenu>
    </div>
  );
});

SearchSort.displayName = 'SearchSort';

SearchSort.propTypes = {
  // The function ((string) => void) to invoke when the sort key changes.
  onChange: PropTypes.func,
  // The search role
  role: PropTypes.oneOf(roles).isRequired,
  // The key to sort by
  sort: PropTypes.oneOf([
    ...jobSeekersSortKeys,
    ...companiesSortKeys,
    ...endorsersSortKeys,
  ]).isRequired,
};

SearchSort.defaultProps = {
  onChange: () => {},
  sort: JobSeekersSortKeys.DEFAULT_SORT_KEY || CompaniesSortKeys.DEFAULT_SORT_KEY || EndorsersSortKeys.DEFAULT_SORT_KEY,
};

export default SearchSort;
