/**
 * English translations for utilities/companies/filter-types.
 *
 * @module languages/en/utilities/companies/filter-types
 */
const filterTypes = {
  NUMBER_OF_EMPLOYEES: 'Number of employees',
  PROFESSION: 'Job interested in',
};

export default filterTypes;
