/**
 * Resource method to get job seeker profiles.
 *
 * @module resources/job-seekers/get-statistics
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/job-seekers/statistics~Statistics} Result
 */

/**
 * Gets the statistics of a job seeker.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/job-seekers/get-statistics~Result>} - The promise.
 * @static
 */
const getJobSeekersStatistics = () => {
  return axios.request({
    method: 'get',
    url: '/job-seekers/statistics',
  }).then((response) => {
    return response.data;
  });
};

export default getJobSeekersStatistics;
