/**
 * Privacy Policy page.
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Markdown from 'components/common/markdown/markdown';

import './privacy-policy.scss';

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();

  // The content.
  const [ content, setContent ] = useState('');

  // The selected language.
  const selectedLanguage = i18n.language;

  // Load the content for the selected language.
  useEffect(() => {
    import(`languages/${ selectedLanguage }/privacy-policy.md`).then((result) => {
      return fetch(result.default);
    }).then((result) => {
      return result.text();
    }).then((result) => {
      setContent(result);
    });
  }, [ selectedLanguage ]);

  return (
    <div className='ody-privacy-policy dark'>
      <Markdown>
        { content }
      </Markdown>
    </div>
  );
};

PrivacyPolicy.propTypes = {
};

PrivacyPolicy.defaultProps = {
};

export default PrivacyPolicy;
