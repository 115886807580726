/**
 * Resource method to get endorsers profiles.
 *
 * @module resources/endorsers/get-statistics
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/endorsers/statistics~Statistics} Result
 */

/**
 * Gets the statistics of endorsers.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/endorsers/get-statistics~Result>} - The promise.
 * @static
 */
const getStatistics = () => {
  return axios.request({
    method: 'get',
    url: '/endorsers/statistics',
  }).then((response) => {
    return response.data;
  });
};

export default getStatistics;
