/**
 * Settings - Account.
 */
import React from 'react';

import './account.scss';
import DeactivateAccount from './deactivate-account/deactivate-account';
import Email from './email/email';
import Password from './password/password';

const Account = (_props) => {
  return (
    <div className='account section'>
      <Email />
      <Password />
      <DeactivateAccount />
    </div>
  );
};

Account.propTypes = {
};

Account.defaultProps = {
};

export default Account;
