/**
 * Utility functions.
 *
 * @module utilities/chisels
 */
import _ from 'lodash';
import { DateTime } from 'luxon';

import * as Roles from './auth/roles';
import CompanyAgentFilterTypes from './companies/filter-types';
import companiesSortKeys, * as CompaniesSortKeys from './companies/sort-keys';
import criterionTypes, * as CriterionTypes from './criterion-types';
import endorsersSortKeys, * as EndorsersSortKeys from './endorsers/sort-keys';
import JobSeekerFilterTypes from './job-seekers/filter-types';
import jobSeekersSortKeys, * as JobSeekersSortKeys from './job-seekers/sort-keys';

/**
 * Checks whether the given values are equal.
 *
 * @param {*} v1 - Some value.
 * @param {*} v2 - Some other value.
 * @returns {boolean} - Whether the values are equal.
 * @static
 */
const areEqual = (v1, v2) => {
  return _.isEqual(v1, v2);
};

/**
 * Checks whether the given values are not equal.
 *
 * @param {*} v1 - Some value.
 * @param {*} v2 - Some other value.
 * @returns {boolean} - Whether the values are not equal.
 * @static
 */
const areNotEqual = (v1, v2) => {
  return !areEqual(v1, v2);
};

/**
 * Returns the largest integer that is greater than or equal to the given number.
 *
 * @param {number} n - The number.
 * @returns {number} - The rounded number.
 * @static
 */
const ceil = (n) => {
  return _.ceil(n, 0);
};

/**
 * Gets the current year.
 *
 * @returns {number} - The current year.
 * @static
 */
const currentYear = () => {
  return DateTime.now().toObject().year;
};

/**
 * Returns the difference between the given strings, which represent dates in the ISO 8601 format (e.g 2030-02-01), in
 * the given units (e.g. { years: 1, months: 9 }).
 *
 * @param {string} s1 - Some date.
 * @param {string} s2 - Some other date.
 * @param {string[]} units - The units.
 * @returns {object} - The difference.
 * @static
 */
const diff = (s1, s2, units) => {
  return undefined === s1 || undefined === s2 ? undefined
    : DateTime.fromISO(s2).diff(DateTime.fromISO(s1), units).toObject();
};

/**
 * Gets the filename from the given value of the HTTP Content-Disposition response header.
 *
 * @param {string} contentDisposition - The value of the Content-Disposition header.
 * @returns {string} - The filename.
 * @static
 */
const extractFilename = (contentDisposition) => {
  if (isEmpty(contentDisposition)) {
    return undefined;
  }
  const firstQuoteIndex = contentDisposition.indexOf('"');
  const lastQuoteIndex = contentDisposition.lastIndexOf('"');
  return contentDisposition.substring(firstQuoteIndex + 1, lastQuoteIndex);
};

/**
 * Returns the largest integer that is less than or equal to the given number.
 *
 * @param {number} n - The number.
 * @returns {number} - The rounded number.
 * @static
 */
const floor = (n) => {
  return _.floor(n, 0);
};

/**
 * Formats the given string, which represents a date in the ISO 8601 format (e.g 2030-02-01), in the given format.
 *
 * @param {string} s - The string to format.
 * @param {string} fmt - The format.
 * @param {string} language - The language to use to format.
 * @returns {string} - The formatted string (localized).
 * @static
 */
const formatDate = (s, fmt, language) => {
  return undefined === s ? undefined : DateTime.fromISO(s).setLocale(language).toFormat(fmt);
};

/**
 * Checks whether the given value is empty.
 *
 * @param {*} v - The value.
 * @returns {boolean} - Whether the value is empty.
 * @static
 */
const isEmpty = (v) => {
  return _.isEmpty(v);
};

/**
 * Checks whether the given value is not empty.
 *
 * @param {*} v - The value.
 * @returns {boolean} - Whether the value is not empty.
 * @static
 */
const isNotEmpty = (v) => {
  return !isEmpty(v);
};

/**
 * Returns the maximum value in the given array.
 *
 * @param {number[]} a - The array.
 * @returns {number} - The maximum value in the array.
 * @static
 */
const max = (a) => {
  return _.max(a);
};

/**
 * Returns the minimum value in the given array.
 *
 * @param {number[]} a - The array.
 * @returns {number} - The minimum value in the array.
 * @static
 */
const min = (a) => {
  return _.min(a);
};

/**
 * Checks whether the given value is not zero.
 *
 * @param {*} value - The value.
 * @returns {boolean} - Whether the value is not zero.
 * @static
 */
const isNotZero = (value) => {
  return 0 !== value;
};

/**
 * Gets the months in the given language.
 *
 * @param {string} l - The language.
 * @returns {string[]} - The months.
 * @static
 */
const months = (l) => {
  let dt = DateTime.now().setLocale(l);
  return Array(12).fill(0).map((_, i) => {
    dt = dt.set({ month: i + 1 });
    return dt.toFormat('MMMM');
  });
};

/**
 * Returns a copy of the given object without the given paths.
 *
 * @param {object} o - The object.
 * @param {string[]} [paths] - The paths to omit.
 * @returns {boolean} - The new object.
 * @static
 */
const omit = (o, paths) => {
  return _.omit(o, paths);
};

/**
 * Creates a slice of a given array with n elements taken from the beginning.
 *
 * @param {any[]} array - The given array.
 * @param {number} [elements] - The number of the elements to take.
 * @returns {any[]} - The new sliced array.
 * @static
 */
const takeFirstN = (array, elements) => {
  return _.take(array, elements);
};

/**
 * Parses the given string as a range.
 *
 * @param {string} s - The string to parse.
 * @returns {module:types/common~Range} - The range.
 * @static
 */
const parseRange = (s) => {
  if (undefined === s) {
    return undefined;
  }
  const pieces = s.split('-');
  return {
    end: parseFloat(pieces[1]),
    start: parseFloat(pieces[0]),
  };
};

/**
 * Converts the given range from years to months.
 *
 * @param {module:types/common~Range} r - The range in years.
 * @returns {module:types/common~Range} - The range in months.
 * @static
 */
const toMonths = (r) => {
  if (undefined === r) {
    return undefined;
  }
  return {
    end: r.end * 12,
    start: r.start * 12,
  };
};

/**
 * Converts the given range from months to years.
 *
 * @param {module:types/common~Range} r - The range in months.
 * @returns {module:types/common~Range} - The range in years.
 * @static
 */
const toYears = (r) => {
  if (undefined === r) {
    return undefined;
  }
  let end = ceil(r.end / 12);
  end = 0.5 <= (end - r.end / 12) ? end - 0.5 : end;
  let start = floor(r.start / 12);
  start = 0.5 <= (r.start / 12 - start) ? start + 0.5 : start;
  return {
    end,
    start,
  };
};

/**
 * Converts the given search parameters to URL search parameters.
 *
 * @param {object} params - The search parameters.
 * @param {object[]} params.criteria - The criteria.
 * @param {string} params.criteria.type - The type of the criterion.
 * @param {string} params.criteria.value - The value of the criterion.
 * @param {object[]} [params.filters] - The filters.
 * @param {string} params.filters.type - The type of the filter.
 * @param {string} params.filters.value - The value of the filter.
 * @param {number} params.page - The number of the page to get.
 * @param {object[]} params.sort - The key to sort by.
 * @returns {URLSearchParams} - The URL search parameters.
 * @static
 */
const toUrlSearchParams = (params) => {
  const urlSearchParams = new URLSearchParams();
  // Dump the criteria.
  params.criteria?.forEach((c) => {
    urlSearchParams.append('c', `${ c.type }:${ c.value }`);
  });
  // Dump the filters.
  params.filters?.forEach((f) => {
    urlSearchParams.append('f', `${ f.type }:${ f.value }`);
  });
  // Dump the page.
  if (undefined !== params.page) {
    urlSearchParams.append('p', params.page.toString());
  }
  // Dump the sort.
  if (undefined !== params.sort) {
    urlSearchParams.append('s', params.sort);
  }
  // Sort the key/value pairs by their keys.
  urlSearchParams.sort();
  return urlSearchParams;
};

/**
 * Converts the given URL search parameters to search parameters.
 *
 * @param {URLSearchParams} urlSearchParams - The URL search parameters.
 * @param {('ADMINISTRATOR' | 'COMPANY_AGENT' | 'ENDORSER' | 'JOB_SEEKER')} role
 * - The role from which component is called the function
 * @returns {object} - The search parameters or `undefined` if the search parameters represented by the given URL
 *   search parameters are not valid.
 * @static
 */
const fromUrlSearchParams = (urlSearchParams, role) => {
  const params = {};
  let sortKeys;
  let SortKeys;
  switch (role) {
  case Roles.JOB_SEEKER:
    sortKeys = jobSeekersSortKeys;
    SortKeys = JobSeekersSortKeys;
    break;
  case Roles.COMPANY_AGENT:
    sortKeys = companiesSortKeys;
    SortKeys = CompaniesSortKeys;
    break;
  case Roles.ENDORSER:
    sortKeys = endorsersSortKeys;
    SortKeys = EndorsersSortKeys;
    break;
  default:
    break;
  }
  // Parse the criteria.
  const cs = urlSearchParams.getAll('c') || [];
  params.criteria = cs.map((c) => {
    const pieces = c.split(':');
    if (2 !== pieces.length) {
      return undefined;
    }
    const [ type, value ] = pieces;
    if (-1 === criterionTypes.indexOf(type)) {
      return undefined;
    }
    return {
      type,
      value,
    };
  }).filter((c) => {
    return undefined !== c;
  });
  // Parse the filters.
  const fs = urlSearchParams.getAll('f') || [];
  params.filters = fs.map((f) => {
    const pieces = f.split(':');
    if (2 !== pieces.length) {
      return undefined;
    }
    const [ type, value ] = pieces;
    if (-1 === JobSeekerFilterTypes.indexOf(type)
      && -1 === CompanyAgentFilterTypes.indexOf(type)
    ) {
      return undefined;
    }
    return {
      type,
      value,
    };
  }).filter((f) => {
    return undefined !== f;
  });
  // Parse the page.
  const p = urlSearchParams.get('p');
  params.page = parseInt(p) || 1;
  // Parse the sort.
  const s = urlSearchParams.get('s') || SortKeys.DEFAULT_SORT_KEY;
  params.sort = -1 === sortKeys?.indexOf(s) ? SortKeys.DEFAULT_SORT_KEY : s;
  // No criteria, no params.
  if (isEmpty(params.criteria)) {
    return {};
  }
  return params;
};

/**
 * Function that returns a sanitized version of the given search parameters.
 * @param {object} params - The search parameters.
 * @param {('ADMINISTRATOR' | 'COMPANY_AGENT' | 'ENDORSER' | 'JOB_SEEKER')} role
 * - The role from which component is called the function
 * @param {('ADMINISTRATOR' | 'COMPANY_AGENT' | 'ENDORSER' | 'JOB_SEEKER')} userRole - the role of the user
 * @param {boolean} userAuthenticated - Whether the user is authenticated
 * @returns {object} - a sanitized version of the given params
 */
const sanitizeParams = (params, role, userRole, userAuthenticated) => {
  if (isEmpty(params)) {
    return params;
  }
  if (userAuthenticated && role !== userRole) {
    const criteria = params.criteria?.filter((criterion, index) => {
      // At most 4 criteria.
      return 4 > index;
    });
    return {
      ...params,
      criteria,
    };
  }
  const criteria = params.criteria?.filter((criterion) => {
    // No search terms.
    return CriterionTypes.TERM !== criterion.type;
  }).filter((_criterion, index) => {
    // At most 4 criteria.
    return 4 > index;
  });
  return {
    ...params,
    criteria,
    // No filters.
    filters: undefined,
  };
};

/**
 * Parses the given came case object key and returns a translation snake and lower case.
 *
 * @param {string} camelCaseObjectKey - The camel case object key to parse.
 * @returns {string} - The translation key.
 * @static
 */
const getTranslationKey = (camelCaseObjectKey) => {
  return camelCaseObjectKey.split(/(?=[A-Z])/).map((s) => {
    return s.toLowerCase();
  }).join('_');
};

export {
  areEqual,
  areNotEqual,
  ceil,
  currentYear,
  diff,
  extractFilename,
  floor,
  formatDate,
  takeFirstN,
  isEmpty,
  isNotEmpty,
  isNotZero,
  max,
  min,
  months,
  omit,
  parseRange,
  toMonths,
  toYears,
  fromUrlSearchParams,
  toUrlSearchParams,
  sanitizeParams,
  getTranslationKey,
};
