/**
 * Greek translations for job-seekers/profile-experiences.
 *
 * @module languages/el/job-seekers/profile-experiences
 */
const profileExperiences = {
  company_label: 'Εταιρία',
  company_placeholder: 'Εταιρία',
  company_required_error: 'Το πεδίο εταιρία απαιτείται',
  current_label: 'Αυτή τη στιγμή εργάζομαι σε αυτή τη θέση',
  description_label: 'Περιγραφή',
  description_placeholder: 'Περιγραφή',
  editor_title: 'Θέση',
  ended_in_after_now_error: 'Η ημερομηνία λήξης δεν μπορεί να είναι στο μέλλον.',
  ended_in_label: 'Ημερομηνία λήξης ',
  ended_in_placeholder: 'Ημερομηνία λήξης ',
  ended_in_required_error: 'Το πεδίο ημερομηνία λήξης απαιτείται',
  months_one: '{{count}} μήνας',
  months_other: '{{count}} μήνες',
  present: 'Παρόν',
  see_all_button_label: 'Προβολή όλων',
  see_less_button_label: 'Δείτε λιγότερα',
  skills_label: 'Δεξιότητες',
  skills_placeholder: 'Επιλέξτε τις δεξιότητες που αποκτήσατε',
  started_in_after_ended_in_error: 'Η ημερομηνία έναρξης δεν μπορεί να είναι μετά την ημερομηνία λήξης.',
  started_in_after_now_error: 'Η ημερομηνία έναρξης δεν μπορεί να είναι στο μέλλον.',
  started_in_label: 'Ημερομηνία έναρξης',
  started_in_placeholder: 'Ημερομηνία έναρξης',
  started_in_required_error: 'Το πεδίο ημερομηνία έναρξης απαιτείται',
  title: 'Εμπειρία',
  title_label: 'Τίτλος εργασίας',
  title_placeholder: 'Τίτλος εργασίας',
  title_required_error: 'Το πεδίο τίτλος εργασίας απαιτείται',
  years_one: '{{count}} χρόνο',
  years_other: '{{count}} χρόνια',
};

export default profileExperiences;
