import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AverageBubbles from 'components/common/average-bubbles/average-bubbles';
import communicationCooperationSkillsLevels from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import './endorsement-information.scss';

const EndorsementInformation = memo((props) => {
  const { endorsement, allHashtags } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The motivation answer index
  const motivationAnswerIndex = useMemo(() => {
    return motivationalLevels.findIndex((motivationLevel) => {
      return motivationLevel === endorsement?.motivationLevel;
    }) + 1;
  }, [ endorsement?.motivationLevel ]);

  // The communication cooperation answer index
  const communicationCooperationIndex = useMemo(() => {
    return communicationCooperationSkillsLevels.findIndex((communicationCooperationSkillsLevel) => {
      return communicationCooperationSkillsLevel === endorsement?.communicationCooperationSkillsLevel;
    }) + 1;
  }, [ endorsement?.communicationCooperationSkillsLevel ]);

  // The critical thinking answer index
  const criticalThinkingAnswerIndex = useMemo(() => {
    return criticalThinkingLevels.findIndex((criticalThinkingLevel) => {
      return criticalThinkingLevel === endorsement?.criticalThinkingLevel;
    }) + 1;
  }, [ endorsement?.criticalThinkingLevel ]);

  // The rendered professionalism attributes
  const renderedProfessionalismAttributes = useCallback((professionalismAttributes) => {
    return professionalismAttributes?.map((professionalismAttribute) => {
      return t(`endorsement:endorsement_information.professionalism.answers.${ professionalismAttribute }`);
    }).join(' | ');
  }, [ t ]);

  // The rendered personality qualities
  const renderedPersonalityQualities = useMemo(() => {
    return endorsement?.hashtags?.map((hashtagId) => {
      const foundHashtag = allHashtags?.find((hashtag) => {
        return hashtag.id === hashtagId;
      })?.[selectedLanguage];
      return (
        <span
          className='txt txt-sm'
          key={ hashtagId }
        >
          { `#${ foundHashtag }` }
        </span>
      );
    });
  }, [ allHashtags, endorsement?.hashtags, selectedLanguage ]);

  // The job seeker feedbacks answer index
  const renderJobSeekerFeedbacksAnswerIndex = useCallback((answer) => {
    return jobSeekerFeedbackLevels?.findIndex((jobSeekerFeedbackLevel) => {
      return answer === jobSeekerFeedbackLevel;
    }) + 1;
  }, []);

  // The sections
  const sections = useMemo(() => {
    return {
      motivation: {
        answer: t(`endorsement:endorsement_information.motivation.answers.${ endorsement?.motivationLevel }`),
        answerIndex: motivationAnswerIndex,
        average: endorsement?.motivationScore || 0,
        question: t('endorsement:endorsement_information.motivation.question'),
        title: t('endorsement:endorsement_information.motivation.title'),
      },
      // eslint-disable-next-line sort-keys
      communicationCooperation: {
        // eslint-disable-next-line max-len
        answer: t(`endorsement:endorsement_information.communication_cooperation.answers.${ endorsement?.communicationCooperationSkillsLevel }`),
        answerIndex: communicationCooperationIndex,
        average: endorsement?.communicationCooperationScore || 0,
        question: t('endorsement:endorsement_information.communication_cooperation.question'),
        title: t('endorsement:endorsement_information.communication_cooperation.title'),
      },
      // eslint-disable-next-line sort-keys
      criticalThinking: {
        // eslint-disable-next-line max-len
        answer: t(`endorsement:endorsement_information.critical_thinking.answers.${ endorsement?.criticalThinkingLevel }`),
        answerIndex: criticalThinkingAnswerIndex,
        average: endorsement?.criticalThinkingScore || 0,
        question: t('endorsement:endorsement_information.critical_thinking.question'),
        title: t('endorsement:endorsement_information.critical_thinking.title'),
      },
      // eslint-disable-next-line sort-keys
      professionalism: {
        answerIndex: endorsement?.professionalCharacteristics?.length,
        answers: endorsement?.professionalCharacteristics,
        average: endorsement?.professionalismScore || 0,
        question: t('endorsement:endorsement_information.professionalism.question'),
        title: t('endorsement:endorsement_information.professionalism.title'),
      },
      // eslint-disable-next-line sort-keys
      testimonial: {
        answer: endorsement?.testimonial,
        title: t('endorsement:endorsement_information.testimonial.title'),
      },
      // eslint-disable-next-line sort-keys
      personalityQualities: {
        answers: endorsement?.hashtags,
        title: t('endorsement:endorsement_information.personality_qualities.title'),
      },
      // eslint-disable-next-line sort-keys
      employerFeedback: {
        answer: endorsement?.employerFeedback,
        title: !endorsement?.jobSeekerFeedbacks?.length
          ? t('endorsement:endorsement_information.feedback.title')
          : t('endorsement:endorsement_information.companies_feedback.title'),
      },
      // eslint-disable-next-line sort-keys
      jobSeekerFeedbacks: {
        answers: endorsement?.jobSeekerFeedbacks,
        title: endorsement?.employerFeedback !== undefined
          ? t('endorsement:endorsement_information.job_seekers_feedback.title')
          : t('endorsement:endorsement_information.feedback.title'),
      },
    };
  }, [
    communicationCooperationIndex,
    criticalThinkingAnswerIndex,
    endorsement?.communicationCooperationScore,
    endorsement?.communicationCooperationSkillsLevel,
    endorsement?.criticalThinkingLevel,
    endorsement?.criticalThinkingScore,
    endorsement?.employerFeedback,
    endorsement?.hashtags,
    endorsement?.jobSeekerFeedbacks,
    endorsement?.motivationLevel,
    endorsement?.motivationScore,
    endorsement?.professionalCharacteristics,
    endorsement?.professionalismScore,
    endorsement?.testimonial,
    motivationAnswerIndex,
    t,
  ]);

  // function that renders the job seeker feedback answers
  const renderedJobSeekerFeedbackAnswers = useCallback((answers) => {
    return answers?.map((answer, index) => {
      return (
        <p
          className='txt txt-sm'
          key={ answer + index }
        >
          {
            `${ renderJobSeekerFeedbacksAnswerIndex(answer) }) 
           ${ t(`endorsement:endorsement_information.job_seekers_feedback.answers.${ answer }`) }`
          }
        </p>
      );
    });
  }, [ renderJobSeekerFeedbacksAnswerIndex, t ]);

  // the motivation section
  const sectionsToBeRendered = useMemo(() => {
    return Object.keys(sections).map((section) => {
      if ('motivation' === section || 'communicationCooperation' === section || 'criticalThinking' === section) {
        return (
          <div
            className='endorsement-information__section endorsement-information__section-main'
            key={ section }
          >
            <p className='txt txt-md txt-bold'>
              { sections[section].title }
            </p>
            <p className='txt txt-sm endorsement-information__section-question'>
              { sections[section].question }
            </p>
            <AverageBubbles
              average={ sections[section].average }
              small
            />
            <div className='endorsement-information__section-answer-container'>
              <span className='txt txt-sm txt-bold'>
                { sections[section].answerIndex }
              </span>
              <p className='txt txt-sm'>
                { sections[section].answer }
              </p>
            </div>
          </div>
        );
      }
      if ('professionalism' === section) {
        return (
          <div
            className='endorsement-information__section endorsement-information__section-main'
            key={ section }
          >
            <p className='txt txt-md txt-bold'>
              { sections[section].title }
            </p>
            <p className='txt txt-sm endorsement-information__section-question'>
              { sections[section].question }
            </p>
            <AverageBubbles
              average={ sections[section].average }
              small
            />
            <div className='endorsement-information__section-answer-container'>
              <span className='txt txt-sm txt-bold'>
                { sections[section].answerIndex }
              </span>
              <p className='txt txt-sm endorsement-information__section-answer-professionalism'>
                { renderedProfessionalismAttributes(sections[section].answers) }
              </p>
            </div>
          </div>
        );
      }
      if ('testimonial' === section) {
        return !sections[section].answer ? null : (
          <div
            className='endorsement-information__section endorsement-information__section-main'
            key={ section }
          >
            <p className='txt txt-md txt-bold'>
              { sections[section].title }
            </p>
            <p className='txt txt-sm'>
              { sections[section].answer }
            </p>
          </div>
        );
      }
      if ('personalityQualities' === section) {
        return !endorsement?.hashtags?.length ? null : (
          <div
            className='endorsement-information__section endorsement-information__section-main'
            key={ section }
          >
            <p className='txt txt-md txt-bold'>
              { sections[section].title }
            </p>
            <div className='txt txt-sm endorsement-information__section-answer-personality-qualities'>
              { renderedPersonalityQualities }
            </div>
          </div>
        );
      }
      if ('jobSeekerFeedbacks' === section) {
        return !sections[section]?.answers?.length ? null : (
          <div
            className='endorsement-information__section endorsement-information__section-main'
            key={ section }
          >
            <p className='txt txt-md txt-bold'>
              { sections[section].title }
            </p>
            { renderedJobSeekerFeedbackAnswers(sections[section].answers) }
          </div>
        );
      }
      return !sections[section]?.answer ? null : (
        <div
          className='endorsement-information__section endorsement-information__section-main'
          key={ section }
        >
          <p className='txt txt-md txt-bold'>
            { sections[section].title }
          </p>
          <p className='txt txt-sm'>
            { sections[section].answer || sections[section].answers }
          </p>
        </div>
      );
    });
  }, [
    endorsement?.hashtags?.length,
    renderedJobSeekerFeedbackAnswers,
    renderedPersonalityQualities,
    renderedProfessionalismAttributes,
    sections,
  ]);

  // The endorser's name
  const endorsersName = useMemo(() => {
    if (!endorsement?.endorserProfile?.firstName && !endorsement?.endorserProfile?.lastName) {
      return null;
    }
    return (
      <p className='txt txt-lg txt-bold'>
        { `${ endorsement?.endorserProfile?.firstName || '' } ${ endorsement?.endorserProfile?.lastName || '' }` }
      </p>
    );
  }, [ endorsement?.endorserProfile?.firstName, endorsement?.endorserProfile?.lastName ]);

  // The company name and position
  const endorsersCompanyNamePosition = useMemo(() => {
    if (!endorsement?.endorserProfile?.companyName && !endorsement?.endorserProfile?.companyPosition) {
      return null;
    }
    if (endorsement?.endorserProfile?.companyName && !endorsement?.endorserProfile?.companyPosition) {
      return (
        <p className='txt txt-sm endorsement-information__company-information'>
          { endorsement?.endorserProfile?.companyName }
        </p>
      );
    }
    if (!endorsement?.endorserProfile?.companyName && endorsement?.endorserProfile?.companyPosition) {
      return (
        <p className='txt txt-sm endorsement-information__company-information'>
          { endorsement?.endorserProfile?.companyPosition }
        </p>
      );
    }
    return (
      <p className='txt txt-sm endorsement-information__company-information'>
        { `${ endorsement?.endorserProfile?.companyPosition } at ${ endorsement?.endorserProfile?.companyName }` }
      </p>
    );
  }, [ endorsement?.endorserProfile?.companyName, endorsement?.endorserProfile?.companyPosition ]);

  return (
    <div className='endorsement-information'>
      <div className='endorsement-information__section'>
        <p className='txt txt-sm'>
          { t('endorsement:endorsement_information.endorsed_by_title') }
        </p>
        { endorsersName }
        { endorsersCompanyNamePosition }
      </div>
      { sectionsToBeRendered }
    </div>
  );
});

EndorsementInformation.displayName = 'EndorsementInformation';

EndorsementInformation.propTypes = {
  // All the hashtags available
  allHashtags: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of hashtag
      el: PropTypes.string,
      // english translation of hashtag
      en: PropTypes.string,
      // the id of the hashtag
      id: PropTypes.string,
    })
  ),
  // The endorsement
  endorsement: PropTypes.shape({
    // The access level
    accessLevel: PropTypes.string,
    // The communication and cooperation skills level score
    communicationCooperationScore: PropTypes.number,
    // The communication and cooperation skills level
    communicationCooperationSkillsLevel: PropTypes.oneOf(communicationCooperationSkillsLevels),
    // The critical thinking level
    criticalThinkingLevel: PropTypes.oneOf(criticalThinkingLevels),
    // The critical thinking score
    criticalThinkingScore: PropTypes.number,
    // The employer feedback
    employerFeedback: PropTypes.string,
    // The endorser id
    endorser: PropTypes.string,
    // The endorser profile
    endorserProfile: PropTypes.shape({
      // The company name
      companyName: PropTypes.string,
      // The company position
      companyPosition: PropTypes.string,
      // The first name
      firstName: PropTypes.string,
      // the last name
      lastName: PropTypes.string,
    }),
    // The hashtags UUID array
    hashtags: PropTypes.arrayOf(PropTypes.string),
    // The job seeker feedbacks array
    jobSeekerFeedbacks: PropTypes.arrayOf(PropTypes.oneOf(jobSeekerFeedbackLevels)),
    // The motivation level
    motivationLevel: PropTypes.oneOf(motivationalLevels),
    // The motivation score
    motivationScore: PropTypes.number,
    // The professional characteristics array
    professionalCharacteristics: PropTypes.arrayOf(PropTypes.oneOf(professionalCharacteristicLevels)),
    // The profesionalism score
    professionalismScore: PropTypes.number,
    // The request id
    requestId: PropTypes.string,
    // The testimonial
    testimonial: PropTypes.string,
  }),
};

EndorsementInformation.defaultProps = {};

export default EndorsementInformation;
