/**
 * Reset-password page.
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import PasswordInput from 'components/common/password-input/password-input';
import env from 'config/env';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as authMethods from 'resources/auth';
import * as toasts from 'toasts';
import { isNotEmpty } from 'utilities/chisels';
import { resetPasswordSchemaValidator } from 'utilities/validators';

import './reset-password.scss';

const ResetPassword = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth ]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const recaptchaRef = useRef(null);

  const [ searchParams ] = useSearchParams();

  const { t, i18n } = useTranslation();

  // Whether the recaptcha is successfully submitted
  const [ recaptchaSubmitted, setRecaptchaSubmitted ] = useState(false);

  // The password reset token.
  const passwordResetToken = searchParams.get('t');

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  const { formState: { errors }, handleSubmit, control } = useFormWithSchema(resetPasswordSchemaValidator);

  // Verify the password reset token.
  useEffect(() => {
    // Reset the recaptcha every time the form is rendering
    recaptchaRef.current?.reset();
    if (!passwordResetToken) {
      navigate('/sign-in', { replace: true });
      return;
    }
    dispatch(requestsActions.request(authMethods.verifyPasswordResetToken, {
      passwordResetToken,
    }, {
      onFailure: (_error) => {
        toasts.error(t('verify_email:general_error'));
        navigate('/sign-in', { replace: true });
      },
    }));
  }, [ dispatch, navigate, passwordResetToken, t ]);

  // Function that resets the password of a user based on the given values.
  const resetPassword = useCallback((values) => {
    if (!recaptchaSubmitted) {
      return;
    }
    dispatch(requestsActions.request(authMethods.resetPassword, {
      newPassword: values.newPassword,
      passwordResetToken,
    }, {
      onFailure: (_error) => {
        toasts.error(t('verify_email:general_error'));
      },
      onSuccess: (_result) => {
        toasts.info(t('reset_password:reset_message'));
        navigate('/sign-in', { replace: true });
      },
    }));
  }, [ dispatch, navigate, passwordResetToken, recaptchaSubmitted, t ]);

  // The rendered new password input element
  const renderedNewPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.newPassword }
        id='reset-new-password-input'
        label={ t('reset_password:new_password_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('reset_password:new_password_placeholder') }
        value={ value }
      />
    );
  }, [ errors.newPassword, t ]);

  // The rendered new password input element
  const renderedNewPasswordConfirmationInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.newPasswordConfirmation }
        id='reset-new-password-confirmation-input'
        label={ t('reset_password:new_password_confirmation_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('reset_password:new_password_confirmation_placeholder') }
        value={ value }
      />
    );
  }, [ errors.newPasswordConfirmation, t ]);

  // the empty new password error
  const emptyNewPasswordError = useMemo(() => {
    if ('string.empty' !== errors.newPassword?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('reset_password:new_password_required_error') }
      </span>
    );
  }, [ errors.newPassword?.type, t ]);

  // invalid pattern new password
  const invalidNewPasswordPattern = useMemo(() => {
    if (1 === [ 'string.min', 'string.pattern.base' ].indexOf(errors.newPassword?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('reset_password:new_password_invalid_error') }
      </span>
    );
  }, [ errors.newPassword?.type, t ]);

  // new password confirmation error
  const newPasswordConfirmationError = useMemo(() => {
    if ('any.only' !== errors.newPasswordConfirmation?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('reset_password:new_password_and_new_password_confirmation_mismatch_error') }
      </span>
    );
  }, [ errors.newPasswordConfirmation?.type, t ]);

  // function that is invoked when user has successfully added recaptcha
  const handleRecaptchaChange = useCallback((token) => {
    if (!token) {
      return;
    }
    setRecaptchaSubmitted(true);
  }, []);

  // function that is invoked when recaptcha expires
  const handleRecaptchaExpire = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // function that is invoked when recaptcha has error
  const handleRecaptchaError = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <div className='ody-reset-password'>
        <div className='ody-reset-password__form-wrapper'>
          <h1 className='ody-reset-password__form-title hdg hdg-xl'>
            { t('reset_password:title') }
          </h1>
          <form
            className='ody-reset-password__form'
            onSubmit={ handleSubmit(resetPassword) }
          >
            <div className='ody-reset-password__form-control'>
              <Controller
                control={ control }
                defaultValue=''
                name='newPassword'
                render={ renderedNewPasswordInputElement }
              />
              { emptyNewPasswordError }
              { invalidNewPasswordPattern }
            </div>
            <div className='ody-reset-password__form-control'>
              <Controller
                control={ control }
                defaultValue=''
                name='newPasswordConfirmation'
                render={ renderedNewPasswordConfirmationInputElement }
              />
              { newPasswordConfirmationError }
            </div>
            <div className='ody-reset-password__form-control'>
              <ReCAPTCHA
                hl={ selectedLanguage }
                onChange={ handleRecaptchaChange }
                onErrored={ handleRecaptchaError }
                onExpired={ handleRecaptchaExpire }
                ref={ recaptchaRef }
                sitekey={ env.RECAPTCHA_KEY }
                size='normal'
              />
            </div>
            <button
              className='ody-reset-password__submit-button btn btn-custom btn-rounded-sm btn-blue'
              disabled={ isNotEmpty(errors) || !recaptchaSubmitted }
              id='submit-reset-password'
              name='submit reset password'
              type='submit'
            >
              { t('reset_password:reset_password_button_label') }
            </button>
          </form>
        </div>
        <div className='ody-reset-password__logo'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [
    control,
    emptyNewPasswordError,
    errors,
    handleRecaptchaChange,
    handleRecaptchaError,
    handleRecaptchaExpire,
    handleSubmit,
    invalidNewPasswordPattern,
    newPasswordConfirmationError,
    recaptchaSubmitted,
    renderedNewPasswordConfirmationInputElement,
    renderedNewPasswordInputElement,
    resetPassword,
    selectedLanguage,
    t,
    userAuthenticated,
  ]);
};

ResetPassword.propTypes = {
};

ResetPassword.defaultProps = {
};

export default ResetPassword;
