/**
 * Custom validators for job seekers.
 *
 * @module utilities/job-seekers/validators
 */

import computerSkillsLevels from 'utilities/computer-skills-levels';
import drivingLicenseCategories from 'utilities/driving-license-categories';
import languages from 'utilities/languages';
import qualificationTypes from 'utilities/qualification-types';
import workAvailabilities from 'utilities/work-availabilities';

/**
 * Validates the entries in the given computer skills levels facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateComputerSkillsLevelsFacet = (propValue, key, _componentName, _location, _propFullName) => {
  if (!computerSkillsLevels.includes(key)) {
    return new Error(`Key (${ key }) is not a computer skills level.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given driving license categories facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateDrivingLicenseCategoriesFacet = (propValue, key, _componentName, _location, _propFullName) => {
  if (!drivingLicenseCategories.includes(key)) {
    return new Error(`Key (${ key }) is not a driving license category.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given languages facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateLanguagesFacet = (propValue, key, _componentName, _location, _propFullName) => {
  if (!languages.includes(key)) {
    return new Error(`Key (${ key }) is not a language.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given professions facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateProfessionsFacet = (propValue, key, _componentName, _location, _propFullName) => {
  if ('string' !== typeof(key)) {
    return new Error(`Key (${ key }) is not a string.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given qualification types facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateQualificationTypes = (propValue, key, _componentName, _location, _propFullName) => {
  if (!qualificationTypes.includes(key)) {
    return new Error(`Key (${ key }) is not a qualification type.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given relocation intentions facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateRelocationIntentions = (propValue, key, _componentName, _location, _propFullName) => {
  if (![ 'false', 'true' ].includes(key)) {
    return new Error(`Key (${ key }) is not a boolean.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

/**
 * Validates the entries in the given work availabilities facet.
 *
 * @param {object} propValue - The facet.
 * @param {string} key - The key of the entry to validate.
 * @param {string} _componentName - The name of the component.
 * @param {string} _location - The location of the entry to validate.
 * @param {string} _propFullName - The fully resolved name of the entry to validate.
 * @returns {null | Error} - Nothing, if valid; error, otherwise.
 * @static
 */
const validateWorkAvailabilities = (propValue, key, _componentName, _location, _propFullName) => {
  if (!workAvailabilities.includes(key)) {
    return new Error(`Key (${ key }) is not a work availability.`);
  }
  const value = propValue[key];
  if ('number' !== typeof(value)) {
    return new Error(`Value (${ value }) is not a number.`);
  }
  return null;
};

export default {
  validateComputerSkillsLevelsFacet,
  validateDrivingLicenseCategoriesFacet,
  validateLanguagesFacet,
  validateProfessionsFacet,
  validateQualificationTypes,
  validateRelocationIntentions,
  validateWorkAvailabilities,
};
