/**
 * Pricing plans.
 *
 * @module utilities/pricing-plans
 */

/**
 * The free pricing plan.
 *
 * @constant {string}
 * @static
 */
export const FREE = 'FREE';

/**
  * The basic pricing plan.
  *
  * @constant {string}
  * @static
  */
export const BASIC = 'BASIC';

/**
  * The enterprise pricing plan.
  *
  * @constant {string}
  * @static
  */
export const ENTERPRISE = 'ENTERPRISE';

export default [
  FREE,
  BASIC,
  ENTERPRISE,
];
