/* eslint-disable */
import React, { memo } from 'react';

import './lamp-icon.scss';

const LampIcon = memo(() => {
  return (
    <svg
      className='lamp-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 802 490'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        height='490'
        id='mask0_7173_16246'
        maskUnits='userSpaceOnUse'
        width='802'
        x='0'
        y='0'
      >
        <rect fill='#C4C4C4' height='490' width='802' />
      </mask>
      <g mask='url(#mask0_7173_16246)'>
        <path
          d='M455.886 416.786C438.775 390.815 419.285 364.744 389.623 352.335C363.493 341.43 338.541 332.481 317.046 313.178C278.54 278.583 263.81 224.284 277.664 174.448C292.545 120.977 339.593 86.9835 394.683 87.535C425.072 87.8358 456.237 96.3842 479.811 116.138C501.957 134.664 521.072 161.538 524.53 190.843C528.087 220.95 521.649 251.158 528.162 281.115C534.35 309.543 546.676 334.912 564.613 357.75C570.576 365.346 581.148 354.641 575.236 347.121C556.045 322.654 542.943 292.546 540.262 261.411C537.782 232.507 544.371 202.725 536.48 174.323C520.045 115.01 464.279 77.2819 404.955 72.945C350.641 68.9591 298.808 94.3536 273.655 143.664C249.63 190.743 252.486 251.81 282.098 295.805C298.657 320.423 322.457 339.274 348.988 352.185C365.873 360.407 384.712 364.468 400.12 375.473C418.333 388.459 430.809 405.932 442.959 424.382C448.195 432.429 461.222 424.909 455.886 416.786Z'
          fill='#74ABC3'
        />
        <path
          d='M513.206 382.743C504.813 368.73 493.264 356.973 484.02 343.536C475.026 330.45 466.133 317.314 456.863 304.404C442.483 284.374 426.299 260.082 402.249 251.033C386.19 244.991 364.22 246.044 357.906 265.021C350.867 286.104 372.086 309.443 393.481 301.37C402.449 297.986 398.566 283.446 389.472 286.856C378.975 290.816 367.276 278.784 372.712 268.28C376.119 261.737 384.462 262.038 390.675 263.266C407.059 266.5 419.234 280.263 429.356 292.596C442.157 308.214 453.481 325.211 464.78 341.931C475.953 358.427 490.032 373.342 500.229 390.364C505.189 398.637 518.191 391.091 513.206 382.743Z'
          fill='#74ABC3'
        />
        <path
          d='M515.461 382.142C494.367 353.288 483.218 317.966 468.262 285.803C460.696 269.533 449.848 251.384 447.218 233.409C445.514 221.727 453.707 210.998 466.107 213.756C476.78 216.112 484.471 228.471 473.247 235.941C465.231 241.256 472.746 254.292 480.838 248.927C499.202 236.743 497.323 212.678 477.882 202.249C458.316 191.745 434.266 203.553 432.061 226.014C430.759 239.426 437.372 252.712 442.408 264.745C448.496 279.31 455.385 293.474 461.949 307.813C474.625 335.539 484.346 364.944 502.484 389.762C508.12 397.484 521.173 389.988 515.461 382.142Z'
          fill='#74ABC3'
        />
        <path
          d='M444.938 448.022C491.31 411.873 542.943 384.699 592.421 353.338C600.563 348.174 593.048 335.138 584.831 340.352C534.25 372.415 481.74 400.417 434.316 437.393C426.7 443.334 437.398 453.888 444.938 448.022Z'
          fill='#74ABC3'
        />
        <path
          d='M458.441 476.776C488.128 457.548 519.444 440.978 548.956 421.474C568.672 408.439 588.037 393.849 608.856 382.593C617.374 377.98 609.783 364.995 601.265 369.607C580.446 380.863 561.081 395.453 541.365 408.489C511.853 428.017 480.538 444.562 450.851 463.79C442.784 469.029 450.299 482.04 458.441 476.776Z'
          fill='#74ABC3'
        />
        <path
          d='M470.767 512.147C526.358 484.396 576.538 446.392 628.397 412.449C636.463 407.16 628.948 394.149 620.806 399.464C568.947 433.407 518.767 471.411 463.176 499.162C454.533 503.474 462.124 516.459 470.767 512.147Z'
          fill='#74ABC3'
        />
        <path
          d='M253.964 354.09C253.839 353.889 253.689 353.689 253.563 353.488C251.409 350.204 246.799 348.525 243.292 350.781C240.01 352.886 238.281 357.549 240.586 361.059C240.711 361.259 240.862 361.46 240.987 361.661C243.142 364.944 247.751 366.624 251.258 364.368C254.54 362.262 256.269 357.624 253.964 354.09Z'
          fill='#74ABC3'
        />
        <path
          d='M208.669 265.297C192.36 264.068 176.502 269.057 160.243 268.305C150.573 267.854 150.598 282.895 160.243 283.346C176.527 284.098 192.385 279.109 208.669 280.338C218.315 281.065 218.265 266.024 208.669 265.297Z'
          fill='#74ABC3'
        />
        <path
          d='M435.644 37.7988C433.514 21.4541 439.727 4.88368 441.706 -11.3107C442.759 -19.9844 446.191 -32.0926 441.757 -40.2649C437.147 -48.7883 424.17 -41.1925 428.779 -32.6691C431.31 -28.0064 427.001 -13.1156 426.249 -7.65064C424.17 7.56602 418.583 22.2813 420.612 37.7988C421.84 47.2747 436.896 47.375 435.644 37.7988Z'
          fill='#74ABC3'
        />
        <path
          d='M494.342 54.2689C497.749 44.2164 501.281 34.3393 507.143 25.44C512.479 17.3428 499.477 9.79713 494.166 17.8442C487.628 27.7713 483.619 39.0773 479.836 50.283C476.755 59.4581 491.26 63.3939 494.342 54.2689Z'
          fill='#74ABC3'
        />
        <path
          d='M546.175 93.3258C555.845 93.3258 555.87 78.2846 546.175 78.2846C536.505 78.2846 536.48 93.3258 546.175 93.3258Z'
          fill='#74ABC3'
        />
        <path
          d='M330.549 57.0014C340.219 57.0014 340.244 41.9602 330.549 41.9602C320.879 41.9602 320.854 57.0014 330.549 57.0014Z'
          fill='#74ABC3'
        />
        <path
          d='M594.476 259.18C604.146 259.18 604.171 244.139 594.476 244.139C584.806 244.139 584.781 259.18 594.476 259.18Z'
          fill='#74ABC3'
        />
        <path
          d='M167.659 105.359C177.329 105.359 177.354 90.3176 167.659 90.3176C157.964 90.3176 157.964 105.359 167.659 105.359Z'
          fill='#74ABC3'
        />
        <path
          d='M450.901 162.641C460.571 162.641 460.596 147.599 450.901 147.599C441.231 147.599 441.205 162.641 450.901 162.641Z'
          fill='#74ABC3'
        />
      </g>
    </svg>
  );
});

LampIcon.displayName = 'LampIcon';

LampIcon.propTypes = {};

LampIcon.defautlProps = {};

export default LampIcon;
