import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as requestsActions } from 'ducks/requests';
import defaultProfilePicture from 'images/default-profile-picture.png';
import location from 'images/location.png';
import StarIconFilled from 'images/star-filled.png';
import StarIconOutlined from 'images/star-outlined.png';
import * as companiesMethods from 'resources/companies';
import * as toasts from 'toasts';
import roles, * as Roles from 'utilities/auth/roles';
import { isNotEmpty, takeFirstN } from 'utilities/chisels';
import allCities from 'utilities/cities';
import * as Parts from 'utilities/companies/parts';

import './search-result.scss';

const SearchResult = memo((props) => {
  // The props
  const { result, allProfessions, savedJobSeekers, userAuthenticated, userRole, companyId, role } = props;

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Show the first 3 professions of the array
  const narrowedProfessions = useMemo(() => {
    return takeFirstN(result?.professions, 3);
  }, [ result?.professions ]);

  // The profile link
  const profileLink = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return `/job-seekers/${ result?.id }/profile`;
    case Roles.COMPANY_AGENT:
      return `/companies/${ result?.id }/profile`;
    default:
      return '';
    }
  }, [ result?.id, role ]);

  // the profile image source
  const profilePictureImageSource = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ result?.id }/profile/picture?id=${ result?.profilePicture?.id }`;
    case Roles.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ result?.id }/profile/logo?id=${ result?.logo?.id }`;
    default:
      return '';
    }
  }, [ result?.id, result?.logo?.id, result?.profilePicture?.id, role ]);

  // The rendered profile picture
  const renderedProfilePicture = useMemo(() => {
    if (undefined === result?.profilePicture && undefined === result?.logo) {
      return defaultProfilePicture;
    }
    return profilePictureImageSource;
  }, [ profilePictureImageSource, result?.logo, result?.profilePicture ]);

  // The profile picture
  const profilePicture = useMemo(() => {
    return (
      <CircularImage
        alt='Profile Picture'
        imageSource={ renderedProfilePicture }
      />
    );
  }, [ renderedProfilePicture ]);

  // The short bio or description
  const shortBio = useMemo(() => {
    if (undefined === result.shortBio && undefined === result.description) {
      return null;
    }
    return (
      <div className='short-bio'>
        <div className='title hdg hdg-sm'>
          { t('common:search_results.about_title') }
        </div>
        <div className='value txt txt-sm'>
          { result.shortBio || result.description }
        </div>
      </div>
    );
  }, [ result.description, result.shortBio, t ]);

  // The profession displayed searched from the all professions array
  const professionDisplayed = useCallback((professionId) => {
    return allProfessions.find((p) => {
      return p.id === professionId;
    })?.[selectedLanguage];
  }, [ allProfessions, selectedLanguage ]);

  // The professions
  const professions = useMemo(() => {
    return narrowedProfessions?.map((id, professionIndex) => {
      return (
        <div
          className={
            clsx( 'value txt txt-sm', {
              'purple': 1 === professionIndex % 3,
              'red': 0 === professionIndex % 3,
              'yellow': 1 !== professionIndex % 3 && 0 !== professionIndex % 3,
            })
          }
          key={ id }
        >
          { professionDisplayed(id) }
        </div>
      );
    });
  }, [ narrowedProfessions, professionDisplayed ]);

  const renderedProfessionsTitle = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('common:search_results.job_seekers_professions_title');
    case Roles.COMPANY_AGENT:
      return t('common:search_results.companies_professions_title');
    default:
      return '';
    }
  }, [ role, t ]);

  // The professions container element
  const professionsContainer = useMemo(() => {
    if (0 === (result.professions || []).length) {
      return null;
    }
    return (
      <div className='professions hidden-sm hidden-xs'>
        <div className='title hdg hdg-sm'>
          { renderedProfessionsTitle }
        </div>
        <div className='values'>
          { professions }
        </div>
      </div>
    );
  }, [ professions, renderedProfessionsTitle, result.professions ]);

  // The location display string
  const locationDisplayString = useMemo(() => {
    return [
      [
        result?.location?.district,
        result?.location?.city && t(`utilities:cities.${ result?.location?.city }`),
      ].filter(isNotEmpty).join(', '),
    ];
  }, [ result?.location?.city, result?.location?.district, t ]);

  // The location
  const renderedLocation = useMemo(() => {
    if (undefined === result.location) {
      return null;
    }
    return (
      <div className='location'>
        <img alt='Location' src={ location } />
        <div className='txt txt-sm'>
          { locationDisplayString }
        </div>
      </div>
    );
  }, [ locationDisplayString, result.location ]);

  // Whether the job seeker is already in the list of the saved ones
  const foundSavedJobSeeker = useMemo(() => {
    return savedJobSeekers?.find((savedJobSeekerId) => {
      return result.id === savedJobSeekerId;
    });
  }, [ result.id, savedJobSeekers ]);

  // The star icon image source
  const starIconImageSource = useMemo(() => {
    return undefined !== foundSavedJobSeeker ? StarIconFilled : StarIconOutlined;
  }, [ foundSavedJobSeeker ]);

  // The filtered saved job seekers
  const filteredSavedJobSeekers = useMemo(() => {
    return savedJobSeekers?.filter((savedJobSeekerId) => {
      return savedJobSeekerId !== result.id;
    });
  }, [ result.id, savedJobSeekers ]);

  // function that is invoked when saving a job seeker as a company agent
  const handleSaveJobSeekerClick = useCallback((event) => {
    event.preventDefault();
    const savedJobSeekersList = undefined !== foundSavedJobSeeker ? filteredSavedJobSeekers : [
      ...savedJobSeekers,
      result.id,
    ];
    dispatch(requestsActions.request(companiesMethods.updateProfile, {
      part: Parts.SAVED_JOB_SEEKERS,
      profile: {
        id: companyId,
        savedJobSeekers: savedJobSeekersList,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:common.general_error'));
      },
      onSuccess: (result) => {
        toasts.info(t('profile:common.changes_saved_message'));
        dispatch(profilesActions.setProfile(result));
      },
    }));
  }, [ foundSavedJobSeeker, filteredSavedJobSeekers, savedJobSeekers, result.id, dispatch, companyId, t ]);

  // The save job seeker star button
  const saveJobSeekerStarButton = useMemo(() => {
    if (!userAuthenticated || userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <button
        className='save-job-seeker-button'
        onClick={ handleSaveJobSeekerClick }
        type='button'
      >
        <img
          alt='star icon'
          src={ starIconImageSource }
        />
      </button>
    );
  }, [ handleSaveJobSeekerClick, starIconImageSource, userAuthenticated, userRole ]);

  // The job seeker card name
  const jobSeekerCardName = useMemo(() => {
    return (
      <>
        <p className='hdg hdg-md'>
          { result.firstName }
        </p>
        <p className='hdg hdg-md'>
          { result.lastName }
        </p>
      </>
    );
  }, [ result.firstName, result.lastName ]);

  // The company card name
  const companyCardName = useMemo(() => {
    return (
      <p className='hdg hdg-md'>
        { result.companyName }
      </p>
    );
  }, [ result.companyName ]);

  // Renders the name in the card
  const renderedName = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekerCardName;
    case Roles.COMPANY_AGENT:
      return companyCardName;
    default:
      return null;
    }
  }, [ companyCardName, jobSeekerCardName, role ]);

  return (
    <Link className='search-result dark' to={ profileLink }>
      { saveJobSeekerStarButton }
      <div className='left'>
        <div className='profile-picture-and-name'>
          <div className={
            clsx('profile-picture', {
              'no-picture': undefined === result.profilePicture,
            })
          }
          >
            { profilePicture }
          </div>
          <div className='full-name'>
            { renderedName }
          </div>
        </div>
        { renderedLocation }
      </div>
      <div className='right'>
        { shortBio }
        { professionsContainer }
      </div>
      <button
        className='btn btn-sm btn-rounded-sm btn-blue hidden-md hidden-lg'
        type='button'
      >
        { t('common:search_results.skills_and_traits_button_title') }
      </button>
    </Link>
  );
});

SearchResult.displayName = 'SearchResult';

SearchResult.propTypes = {
  // The professions array
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the profession
      el: PropTypes.string,
      // The english translation of the profession
      en: PropTypes.string,
      // The id of the profession
      id: PropTypes.string,
    })
  ).isRequired,
  // The company id that the user belongs
  companyId: PropTypes.string,
  // The props.result.
  result: PropTypes.oneOfType([
    // job seeker result
    PropTypes.shape({
      // The first name of the job seeker.
      firstName: PropTypes.string.isRequired,
      // The ID of the job seeker.
      id: PropTypes.string.isRequired,
      // The last name of the job seeker.
      lastName: PropTypes.string.isRequired,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The IDs of the professions that the job seeker is interested in.
      professions: PropTypes.arrayOf(PropTypes.string),
      // The profile picture of the job seeker.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string.isRequired,
        // The MIME type of the picture.
        type: PropTypes.string.isRequired,
      }),
      // The short bio of the job seeker.
      shortBio: PropTypes.string,
    }),
    // company result
    PropTypes.shape({
      // the name of the company
      companyName: PropTypes.string,
      // The description of the company
      description: PropTypes.string,
      // The company id
      id: PropTypes.string,
      // The location of the company
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The logo of the company
      logo: PropTypes.shape({
        // the extension of the logo
        extension: PropTypes.string,
        // The id of the logo
        id: PropTypes.string,
        // The type of the logo
        type: PropTypes.string,
      }),
      // The IDs of the professions that the company is interested in.
      professions: PropTypes.arrayOf(PropTypes.string),
    }),
  ]).isRequired,
  // the role of the card
  role: PropTypes.oneOf(roles).isRequired,
  // The saved job seekers id's array if the user is a company agent
  savedJobSeekers: PropTypes.arrayOf(PropTypes.string),
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool,
  // The signed in user role
  userRole: PropTypes.oneOf(roles),
};

SearchResult.deafaultProps = {};

export default SearchResult;
