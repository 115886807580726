/**
 * Greek translations for endorsers/search-result-card.
 *
 * @module languages/el/endorsers/search-result-card
 */
const searchResultCard = {
  company_website_label: 'Ιστοσελίδα εταιρείας',
};

export default searchResultCard;
