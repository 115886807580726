import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSm, useXs } from 'hooks/media-queries';
import BriefCase from 'images/briefcase.png';
import Edit from 'images/edit.png';
import { ceil, diff, formatDate } from 'utilities/chisels';

import './profile-experience-card.scss';

const ProfileExperienceCard = memo((props) => {
  const { canEdit, detailed, expanded, onClick, onEdit, experience, allSkills } = props;

  const isSm = useSm();

  const { i18n, t } = useTranslation();

  const isXs = useXs();

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // function that handles the edit button click
  const onEditButtonClick = useCallback((event) => {
    event.stopPropagation();
    onEdit();
  }, [ onEdit ]);

  // The rendered edit button
  const renderedEditButton = useMemo(() => {
    if (!canEdit || (detailed && !isSm && !isXs)) {
      return null;
    }
    return (
      <div className='profile-experience-card__actions'>
        <button
          className='profile-experience-card__edit-button btn btn-trans'
          onClick={ onEditButtonClick }
          type='button'
        >
          <img alt='Edit' src={ Edit } />
        </button>
      </div>
    );
  }, [ canEdit, detailed, isSm, isXs, onEditButtonClick ]);

  // The amount of time that the job seeker was (or has been) working in the position.
  let difference = useMemo(() => {
    let d = diff(experience?.startedIn, experience?.endedIn || DateTime.now(), [ 'years' ]);
    if (0 === parseInt(d?.years)) {
      d = diff(experience?.startedIn, experience?.endedIn || DateTime.now(), [ 'months' ]);
    }
    return d;
  }, [ experience?.endedIn, experience?.startedIn ]);

  const duration = useMemo(() => {
    return t(`job_seekers:profile_experiences.${ difference?.years ? 'years' : 'months' }`,
      { count: ceil(difference?.years || difference?.months) });
  }, [ difference?.months, difference?.years, t ]);

  // The time interval that the job seeker was (or has been) working in the position.
  const from = useMemo(() => {
    return formatDate(experience?.startedIn, 'LLL yyyy', selectedLanguage);
  }, [ experience?.startedIn, selectedLanguage ]);

  const to = useMemo(() => {
    return experience?.current ? t('job_seekers:profile_experiences.present')
      : formatDate(experience?.endedIn, 'LLL yyyy', selectedLanguage);
  }, [ experience, selectedLanguage, t ]);

  const interval = useMemo(() => {
    return `${ from } - ${ to }`;
  }, [ from, to ]);

  // The interval and duration
  const intervalAndDurationSection = useMemo(() => {
    if (!expanded || !detailed) {
      return null;
    }
    return (
      <div className='profile-experience-card__row'>
        <p className='profile-experience-card__row--text txt txt-sm'>
          { `${ interval } (${ duration })` }
        </p>
      </div>
    );
  }, [ detailed, duration, expanded, interval ]);

  // The description section
  const descriptionSection = useMemo(() => {
    if (!expanded || !experience?.description) {
      return null;
    }
    return (
      <div className='profile-experience-card__row'>
        <p className='profile-experience-card__row--description txt txt-sm'>
          { experience?.description || '' }
        </p>
      </div>
    );
  }, [ expanded, experience?.description ]);

  // The rendered skills
  const renderedExperienceSkills = useMemo(() => {
    return experience?.skills?.map((skillId) => {
      const foundSkill = allSkills?.find((skill) => {
        return skill.id === skillId;
      })?.[selectedLanguage];
      return (
        <span
          className='txt txt-sm'
          key={ skillId }
        >
          { `#${ foundSkill }` }
        </span>
      );
    });
  }, [ allSkills, experience?.skills, selectedLanguage ]);

  // The skills section
  const skillsSection = useMemo(() => {
    if (!expanded || !experience?.skills?.length) {
      return null;
    }
    return (
      <div className='profile-experience-card__column'>
        { renderedExperienceSkills }
      </div>
    );
  }, [ expanded, experience?.skills?.length, renderedExperienceSkills ]);

  return (
    <div
      className={
        clsx('profile-experience-card', {
          'profile-experience-card__detailed': detailed,
          'profile-experience-card__expanded': expanded,
        })
      }
      onClick={ onClick }
      role='presentation'
    >
      { renderedEditButton }
      <div className='profile-experience-card__row'>
        <img
          alt='experience'
          className='profile-experience-card__row--image'
          src={ BriefCase }
        />
        <p className='profile-experience-card__row--text txt txt-sm'>
          { experience?.company || '' }
        </p>
      </div>
      <div className='profile-experience-card__row'>
        <p className='profile-experience-card__row--title txt txt-md'>
          { experience?.title || '' }
        </p>
      </div>
      { intervalAndDurationSection }
      { descriptionSection }
      { skillsSection }
    </div>
  );
});

ProfileExperienceCard.displayName = 'ProfileExperienceCard';

ProfileExperienceCard.propTypes = {
// All the skills available
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of skill
      el: PropTypes.string,
      // english translation of skill
      en: PropTypes.string,
      // the id of the skill
      id: PropTypes.string,
    })
  ),
  // Whether the user can edit the experience
  canEdit: PropTypes.bool,
  // Whether the card contains all the details about the experience.
  detailed: PropTypes.bool.isRequired,
  // Whether the card is expanded.
  expanded: PropTypes.bool.isRequired,
  // The experience that the card is about.
  experience: PropTypes.shape({
    // The company where the position was.
    company: PropTypes.string,
    // Whether this is the current position of the job seeker.
    current: PropTypes.bool,
    // The description of the position.
    description: PropTypes.string,
    // The date when the job seeker stopped working in the position.
    endedIn: PropTypes.string,
    // The IDs of the skills associated to the position.
    skills: PropTypes.arrayOf(PropTypes.string),
    // The date when the job seeker started working in the position
    startedIn: PropTypes.string,
    // The job title of the position.
    title: PropTypes.string,
  }),
  // The function (() => void) to invoke when the user clicks on the card.
  onClick: PropTypes.func,
  // The function (() => void) to invoke when the user clicks on the Edit button.
  onEdit: PropTypes.func,
};

ProfileExperienceCard.defaultProps = {};

export default ProfileExperienceCard;
