/**
 * Greek translations for search.
 *
 * @module languages/el/search
 */
const search = {
  endorsers_title: 'Οι υποστηρικτές μας',
  search_failed_error: 'Η αναζήτηση απέτυχε',
};

export default search;
