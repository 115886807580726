/**
 * Greek translations for sign-in.
 *
 * @module languages/el/sign-in
 */
const signIn = {
  title: 'Συνδεθείτε',
};

export default signIn;
