import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import HopeAnimationIcon from 'animations/hope.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './learn-more-request-endorsement-section.scss';

const LearnMoreRequestEndorsementSection = memo(() => {
  const { t } = useTranslation();

  return (
    <div className='learn-more-request-endorsement-section'>
      <div className='learn-more-request-endorsement-section__content'>
        <h2 className='hdg hdg-xxl learn-more-request-endorsement-section__title'>
          { t('job_seekers:learn_more.request_endorsement_section.title') }
        </h2>
        <p className='txt txt-md learn-more-request-endorsement-section__description'>
          { t('job_seekers:learn_more.request_endorsement_section.description') }
        </p>
        <Link
          className='btn btn-sm btn-rounded-sm btn-yellow learn-more-request-endorsement-section__button'
          to='/endorsers/learn-more'
        >
          { t('job_seekers:learn_more.request_endorsement_section.button_label') }
        </Link>
      </div>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-request-endorsement-section__animated-icon'
        animationData={ HopeAnimationIcon }
        className='learn-more-request-endorsement-section__animation-container'
      />
    </div>
  );
});

LearnMoreRequestEndorsementSection.displayName = 'LearnMoreRequestEndorsementSection';

LearnMoreRequestEndorsementSection.propTypes = {};

LearnMoreRequestEndorsementSection.defaultProps = {};

export default LearnMoreRequestEndorsementSection;
