/**
 * Administrators - Dashboard.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as administratorsActions } from 'ducks/administrators';
import { actions as metricsActions } from 'ducks/metrics';
import { actions as requestActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as administratorsMethods from 'resources/administrators';
import * as metricsMethods from 'resources/metrics';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';
import { areEqual, getTranslationKey, isEmpty } from 'utilities/chisels';
import { adminControlledMetricsSchemaValidator } from 'utilities/validators';

import ControlledMetricsSection from './controlled-metrics-section/controlled-metrics-section';
import PendingSection from './pending-section/pending-section';
import TotalUsersCard from './total-users-card/total-users-card';

import './dashboard.scss';

const Dashboard = (_props) => {
  // The administrators state object
  const administrators = useSelector((store) => {
    return store.administrators;
  }, shallowEqual);

  // The requests state object
  const requests = useSelector((store) => {
    return store.requests;
  }, shallowEqual);

  // The metrics state object
  const metrics = useSelector((store) => {
    return store.metrics;
  }, shallowEqual);

  // the pending companies profile
  const pendingCompaniesProfiles = useMemo(() => {
    return administrators?.pendingCompaniesProfiles || [];
  }, [ administrators?.pendingCompaniesProfiles ]);

  // the pending endorsers profiles
  const pendingEndorsersProfiles = useMemo(() => {
    return administrators?.pendingEndorsersProfiles || [];
  }, [ administrators?.pendingEndorsersProfiles ]);

  // whether the request is active
  const activeRequest = useMemo(() => {
    return requests?.active;
  }, [ requests?.active ]);

  // The total users
  const totalUsers = useMemo(() => {
    return administrators?.totalUsers;
  }, [ administrators?.totalUsers ]);

  // The metrics id
  const metricsId = useMemo(() => {
    return metrics?.id;
  }, [ metrics?.id ]);

  // The metrics sections to be rendered
  const generateMetricsSectionsToBeRendered = useCallback(() => {
    return {
      talentPlatformMetrics: metrics?.talentPlatformMetrics,
      // eslint-disable-next-line sort-keys
      organizationMetrics: metrics?.organizationMetrics,
    };
  }, [ metrics?.organizationMetrics, metrics?.talentPlatformMetrics ]);

  // The local companies pending profiles
  const [ localCompaniesPendingProfiles, setLocalCompaniesPendingProfiles ] = useState([]);

  // The local endorsers profiles
  const [ localEndorsersPendingProfiles, setLocalEndorsersPendingProfiles ] = useState([]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { formState: { errors }, handleSubmit, register, reset }
    = useFormWithSchema(adminControlledMetricsSchemaValidator);

  // Function that is invoked when approving a pending entity
  const onApproval = useCallback((userId, role) => {
    let filteredLocalPendingProfiles;
    if (role === Roles.COMPANY_AGENT) {
      filteredLocalPendingProfiles = localCompaniesPendingProfiles.filter((localCompanyPendingProfile) => {
        return localCompanyPendingProfile.companyAgentIds?.[0] !== userId;
      });
      setLocalCompaniesPendingProfiles(filteredLocalPendingProfiles);
      return;
    }
    filteredLocalPendingProfiles = localEndorsersPendingProfiles.filter((localEndorserPendingProfile) => {
      return localEndorserPendingProfile?.id !== userId;
    });
    setLocalEndorsersPendingProfiles(filteredLocalPendingProfiles);
  }, [ localCompaniesPendingProfiles, localEndorsersPendingProfiles ]);

  // function that is invoked when confirming an approval
  const handleConfirmApprove = useCallback((userId, role) => {
    dispatch(requestActions.request(usersMethods.approvePending, {
      id: userId,
    }, {
      onFailure: () => {
        toasts.error(t('administrators:dashboard.general_error'));
      },
      onSuccess: () => {
        onApproval(userId, role);
      },
    }));
  }, [ dispatch, onApproval, t ]);

  // function that handles the pending companies
  const fetchPendingCompanies = useCallback(() => {
    dispatch(requestActions.request(administratorsMethods.getPendingCompanies, {}, {
      onFailure: (_error) => {
        toasts.error(t('administrators:dashboard.failed_fetch_pending_companies'));
      },
      onSuccess: (result) => {
        dispatch(administratorsActions.setPendingCompanies(result.profiles));
      },
    }));
  }, [ dispatch, t ]);

  // function that handles the pending endorsers
  const fetchPendingEndorsers = useCallback(() => {
    dispatch(requestActions.request(administratorsMethods.getPendingEndorsers, {}, {
      onFailure: (_error) => {
        toasts.error(t('administrators:dashboard.failed_fetch_pending_endorsers'));
      },
      onSuccess: (result) => {
        dispatch(administratorsActions.setPendingEndorsers(result.profiles));
      },
    }));
  }, [ dispatch, t ]);

  // function that handles the fetch of the total users
  const fetchTotalUsers = useCallback(() => {
    dispatch(requestActions.request(administratorsMethods.getTotalUsers, {}, {
      onFailure: (_error) => {
        toasts.error(t('administrators:dashboard.failed_fetch_total_users'));
      },
      onSuccess: (result) => {
        dispatch(administratorsActions.setTotalUsers(result));
      },
    }));
  }, [ dispatch, t ]);

  useEffect(() => {
    fetchPendingCompanies();
    fetchPendingEndorsers();
    fetchTotalUsers();
  }, [ fetchPendingCompanies, fetchPendingEndorsers, fetchTotalUsers ]);

  // fill up the states with the synced pending profiles
  useEffect(() => {
    setLocalEndorsersPendingProfiles(pendingEndorsersProfiles);
    setLocalCompaniesPendingProfiles(pendingCompaniesProfiles);
  }, [ pendingCompaniesProfiles, pendingEndorsersProfiles ]);

  // Set the metrics values
  useEffect(() => {
    const values = {
      collaboratingCompanies: metrics?.organizationMetrics?.collaboratingCompanies,
      companies: metrics?.talentPlatformMetrics?.companies,
      employabilityServicesPerYear: metrics?.organizationMetrics?.employabilityServicesPerYear,
      findEmployabilityPerYear: metrics?.organizationMetrics?.findEmployabilityPerYear,
      jobPositionsFromOurCompanies: metrics?.talentPlatformMetrics?.jobPositionsFromOurCompanies,
      matchedJobSeekersWithCompanies: metrics?.talentPlatformMetrics?.matchedJobSeekersWithCompanies,
      peopleHired: metrics?.talentPlatformMetrics?.peopleHired,
      peopleInterviewed: metrics?.talentPlatformMetrics?.peopleInterviewed,
      profiles: metrics?.talentPlatformMetrics?.profiles,
      registeredPerYear: metrics?.organizationMetrics?.registeredPerYear,
      studentsPerYear: metrics?.organizationMetrics?.studentsPerYear,
    };
    reset(values);
  }, [
    metrics?.organizationMetrics?.collaboratingCompanies,
    metrics?.talentPlatformMetrics?.companies,
    metrics?.organizationMetrics?.employabilityServicesPerYear,
    metrics?.organizationMetrics?.findEmployabilityPerYear,
    metrics?.organizationMetrics?.registeredPerYear,
    metrics?.organizationMetrics?.studentsPerYear,
    metrics?.talentPlatformMetrics?.jobPositionsFromOurCompanies,
    metrics?.talentPlatformMetrics?.matchedJobSeekersWithCompanies,
    metrics?.talentPlatformMetrics?.peopleHired,
    metrics?.talentPlatformMetrics?.peopleInterviewed,
    metrics?.talentPlatformMetrics?.profiles,
    reset,
  ]);

  // The rendered view link inside the total users card
  const renderedViewLink = useCallback((totalUsersKey) => {
    switch (totalUsersKey) {
    case 'totalJobSeekers':
      return '/job-seekers/search';
    case 'totalCompanies':
      return '/companies/search';
    case 'totalEndorsers':
      return '/endorsers/search';
    default:
      return '';
    }
  }, []);

  // The total users cards
  const totalUsersCards = useMemo(() => {
    if (!totalUsers) {
      return null;
    }
    return Object.keys(totalUsers).map((totalUsersKey, index) => {
      return (
        <TotalUsersCard
          key={ totalUsersKey + index }
          linkTitle={ t(`administrators:dashboard.view_${ getTranslationKey(totalUsersKey) }_link_title`) }
          title={ t(`administrators:dashboard.${ getTranslationKey(totalUsersKey) }_title`) }
          usersNumber={ totalUsers?.[totalUsersKey] }
          viewLink={ renderedViewLink(totalUsersKey) }
        />
      );
    });
  }, [ renderedViewLink, t, totalUsers ]);

  // The params to send
  const paramsToSend = useCallback((values) => {
    if (metricsId) {
      return {
        id: metricsId,
        ...values,
      };
    }
    return {
      ...values,
    };
  }, [ metricsId ]);

  // The controlled metrics flattened
  const flattenedControlledMetrics = useMemo(() => {
    let flattenedMetrics = {};
    const metricsSectionsToBeRendered = generateMetricsSectionsToBeRendered();
    Object.keys(metricsSectionsToBeRendered).forEach((metricsKey) => {
      if (!metricsSectionsToBeRendered?.[metricsKey]) {
        return;
      }
      Object.keys(metricsSectionsToBeRendered?.[metricsKey]).forEach((metricsSubKey) => {
        flattenedMetrics[metricsSubKey] = metricsSectionsToBeRendered?.[metricsKey]?.[metricsSubKey];
      });
    });
    return flattenedMetrics;
  }, [ generateMetricsSectionsToBeRendered ]);

  // Function that submits the controlled metrics
  const onSubmitControlledMetrics = useCallback((values) => {
    if (areEqual(values, flattenedControlledMetrics)) {
      return;
    }
    const method = metricsId ? metricsMethods.updateControlledMetrics : metricsMethods.createControlledMetrics;
    dispatch(requestActions.request(method, paramsToSend(values), {
      onFailure: (_error) => {
        toasts.error(t('administrators:dashboard.failed_to_update_controlled_metrics'));
      },
      onSuccess: (result) => {
        dispatch(metricsActions.setControlledMetrics(result));
      },
    }));
  }, [ dispatch, flattenedControlledMetrics, metricsId, paramsToSend, t ]);

  // The rendered controlled metrics sections
  const renderControlledMetricsSections = useCallback(() => {
    if (!metrics) {
      return null;
    }
    const metricsSectionsToBeRendered = generateMetricsSectionsToBeRendered();
    return Object.keys(metricsSectionsToBeRendered).map((metricsGroupKey, index) => {
      return (
        <ControlledMetricsSection
          errors={ errors }
          key={ metricsGroupKey + index }
          metricsGroup={ metrics?.[metricsGroupKey] }
          metricsGroupKey={ metricsGroupKey }
          register={ register }
        />
      );
    });
  }, [ errors, generateMetricsSectionsToBeRendered, metrics, register ]);

  return (
    <div className='ody-dashboard__administrators'>
      <div className='ody-dashboard__administrators--top-banner ody-dashboard__administrators--section light'>
        <h1 className='hdg hdg-xxl'>
          { t('administrators:dashboard.title') }
        </h1>
      </div>
      <div className='ody-dashboard__administrators--total-users ody-dashboard__administrators--section dark'>
        <h2 className='ody-dashboard__administrators--total-users-title hdg hdg-lg'>
          { t('administrators:dashboard.total_users_title') }
        </h2>
        <div className='ody-dashboard__administrators--total-users-cards'>
          { totalUsersCards }
        </div>
      </div>
      <form
        className='ody-dashboard__administrators--controlled-metrics-form ody-dashboard__administrators--section dark'
        noValidate
        onSubmit={ handleSubmit(onSubmitControlledMetrics) }
      >
        <div className='ody-dashboard__administrators--controlled-metrics-form-title-container'>
          <h2 className='ody-dashboard__administrators--controlled-metrics-form-title hdg hdg-lg'>
            { t('administrators:dashboard.controlled_metrics.title') }
          </h2>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue
            ody-dashboard__administrators--controlled-metrics-update-button'
            disabled={ !isEmpty(errors) }
            type='submit'
          >
            { t('administrators:dashboard.update_all_button_title') }
          </button>
        </div>
        { renderControlledMetricsSections() }
      </form>
      <div className='ody-dashboard__administrators--pending-approvals ody-dashboard__administrators--section dark'>
        <h2 className='ody-dashboard__administrators--pending-approvals-title hdg hdg-lg'>
          { t('administrators:dashboard.pending_title') }
        </h2>
        <PendingSection
          activeRequest={ activeRequest }
          onConfirmApprove={ handleConfirmApprove }
          pendingProfiles={ localCompaniesPendingProfiles }
          role={ Roles.COMPANY_AGENT }
          title={ t('administrators:dashboard.pending_companies_title') }
        />
        <PendingSection
          activeRequest={ activeRequest }
          onConfirmApprove={ handleConfirmApprove }
          pendingProfiles={ localEndorsersPendingProfiles }
          role={ Roles.ENDORSER }
          title={ t('administrators:dashboard.pending_endorsers_title') }
        />
      </div>
    </div>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
