/**
 * Greek translations for common/profile-professions.
 *
 * @module languages/el/common/profile-professions
 */
const profileProfessions = {
  editor_title: 'Θέσεις εργασία που σας ενδιαφέρουν',
  professions_placeholder: 'Επιλέξτε τις θέσεις εργασία που σας ενδιαφέρουν',
  title: 'Θέσεις εργασία που σας ενδιαφέρουν',
};

export default profileProfessions;
