/* eslint-disable */
import React, { memo } from 'react';

import './arrow-card-right-icon.scss';

const ArrowCardRightIcon = memo(() => {
  return (
    <svg
      className='arrow-card-right-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 24 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4148 1.12245C12.968 0.569209 13.865 0.569209 14.4182 1.12245L22.9182 9.62245C23.1839 9.88813 23.3332 10.2485 23.3332 10.6242C23.3332 10.9999 23.1839 11.3602 22.9182 11.6259L14.4182 20.1259C13.865 20.6792 12.968 20.6792 12.4148 20.1259C11.8615 19.5727 11.8615 18.6757 12.4148 18.1225L18.4964 12.0409L2.08317 12.0409C1.30077 12.0409 0.666504 11.4066 0.666504 10.6242C0.666504 9.84179 1.30077 9.20752 2.08317 9.20752H18.4964L12.4148 3.12592C11.8615 2.57268 11.8615 1.67569 12.4148 1.12245Z'
        fill='white'
      />
    </svg>
  );
});

ArrowCardRightIcon.displayName = 'ArrowCardRightIcon';

ArrowCardRightIcon.propTypes = {};

ArrowCardRightIcon.defaultProps = {};

export default ArrowCardRightIcon;
