/**
 * English translations for common/dashboard-contact-share-section.
 *
 * @module languages/en/common/dashboard-contact-share-section
 */
const dashboardContactShareSection = {
  contact_odyssea_title_companies: 'Need support in finding the right candidate or using our platform?',
  contact_odyssea_title_endorsers: 'Need support for endorsing or using our platform?',
  download_failed_error: 'Download failed',
  download_profile: 'Download Profile',
  endorsement_guide: 'Endorsement Guide',
  share_title: 'Want to share your profile with a new company?',
};

export default dashboardContactShareSection;
