/**
 * Greek translations for utilities/endorsers/filter-types.
 *
 * @module languages/el/utilities/endorsers/filter-types
 */
const filterTypes = {
  TOTAL_ENDORSEMENTS_RANGE: 'Σύνολο συστάσεων',
};

export default filterTypes;
