/**
 * Greek translations for utilities/language-levels.
 *
 * @module languages/el/utilities/language-levels
 */
const languageLevels = {
  ADVANCED: 'Προχωρημένη',
  BASIC: 'Βασική',
  INTERMEDIATE: 'Μέτρια',
  NATIVE: 'Μητρική',
  NO: 'Οχι',
};

export default languageLevels;
