/**
 * English translations for social-responsibility.
 *
 * @module languages/en/social-responsibility
 */
const socialResponsibility = {
  companies_collection_section: {
    title: 'Companies we have worked with',
  },
  csr_packages_section: {
    failed_email: 'We failed to send email to an admin. Please try again',
    package_button_label: 'Contact us',
    packages: {
      item1: {
        description: {
          item1: 'Support the training of 15 vulnerable youth',
          item2: 'Training tailor made to your needs and the labour market’s needs',
          item3: 'Employability support and after-hire support for participants',
        },
        title: 'Tailor Made Training',
      },
      item2: {
        description: {
          item1: 'Support the employment of 15 vulnerable youth',
          item2: 'Career counselling, soft skills training and interview preparation',
          item3: 'Employability support and after-hire support for participants',
        },
        title: 'Employability Support',
      },
      item3: {
        description: {
          item1: 'Provide 200 meals to people in need in Piraeus and Athens',
          item2: 'Support 10 families in need with monthly boxes',
          item3: 'Participate with your company’s volunteers in our social kitchen',
        },
        title: 'Relief Interventions',
      },
    },
    pricing_interest_different_role_error: 'You have to be a company agent to send us a pricing interest',
    success_email: 'An email has been sent to an admin, for a CSR pricing interest.',
    title: 'CSR packages',
  },
  first_section: {
    // eslint-disable-next-line max-len
    description: 'Support vulnerable population through CSR activities, professional training and employment opportunities.\n\nIncrease the social impact of your company and share it with a wider audience in collaboration with Odyssea.',
    title: 'Social Responsibility the easy way',
  },
  grow_social_impact_section: {
    // eslint-disable-next-line max-len
    info: 'Strengthen your SDG goals by leveraging our expertise on education, decent employment or diversity and inclusion in the workplace.\n\nLet’s work together to scale social impact.',
    title: 'Grow your social impact',
  },
  impact_stories_section: {
    stories: {
      item1: {
        item1: {
          age_location: 'Ag. I. Rentis',
          name: 'Odyssea Academy',
          title: 'Welcome to Odyssea Academy',
          video_url: 'https://www.youtube.com/embed/qZtce2c9i0M',
        },
        item2: {
          age_location: 'Piraeus Port',
          name: 'Social Kitchen',
          title: 'Offering meals together with CVC',
          video_url: 'https://www.youtube.com/embed/USGQBDVK0Ck',
        },
        item3: {
          // eslint-disable-next-line max-len
          description: 'Taking into account the current social and economic needs of the market, the "SKILLS 4 ALL" programme was designed to support and professionally train 100 young men and women aged 17-34 from vulnerable social groups. For more information on the action and how to register,  visit the program’s page.',
          name: 'SKILLS 4 ALL',
          title: 'Piraeus Bank, in cooperation with Odyssea, implements the "SKILLS 4 ALL" programme.',
        },
      },
    },
    title: 'Impact stories',
  },
  sdg_section: {
    link_label: 'Visit our webpage to see more',
    title: 'Lets work together on the following SDGs',
  },
};

export default socialResponsibility;
