/**
 * English translations for common/profile-contact-details.
 *
 * @module languages/en/common/profile-contact-details
 */
const profileContactDetails = {
  company_position_label: 'Company position',
  company_position_placeholder: 'Company position',
  company_position_required_error: 'Company position is required.',
  editor_title: 'Contact',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Email is required.',
  first_name_label: 'First name',
  first_name_placeholder: 'First name',
  first_name_required_error: 'First name is required.',
  last_name_label: 'Last name',
  last_name_placeholder: 'Last name',
  last_name_required_error: 'Last name is required.',
  linkedin_profile: 'LinkedIn profile',
  linkedin_profile_url: 'LinkedIn profile URL',
  linkedin_profile_url_invalid_error: 'Enter a valid URL.',
  linkedin_profile_url_label: 'LinkedIn profile URL',
  linkedin_profile_url_placeholder: 'Paste your LinkedIn profile URL',
  phone_number_1_placeholder: 'Mobile phone number',
  phone_number_2_placeholder: 'Other phone number',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Phone number must be a number with the country prefix (e.g +30) and not exceed 10 numbers',
  phone_number_label: 'Phone',
  phone_number_placeholder: 'Phone number',
  phone_number_required_error: 'Phone number is required.',
  title: 'Contact',
};

export default profileContactDetails;
