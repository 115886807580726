/**
 * Language levels.
 *
 * @module utilities/language-levels
 */

/**
 * Advanced.
 *
 * @constant {string}
 * @static
 */
export const ADVANCED = 'ADVANCED';

/**
 * Basic.
 *
 * @constant {string}
 * @static
 */
export const BASIC = 'BASIC';

/**
 * Intermediate.
 *
 * @constant {string}
 * @static
 */
export const INTERMEDIATE = 'INTERMEDIATE';

/**
 * Native.
 *
 * @constant {string}
 * @static
 */
export const NATIVE = 'NATIVE';

/**
 * No knowledge.
 *
 * @constant {string}
 * @static
 */
export const NO = 'NO';

export default [
  ADVANCED,
  BASIC,
  INTERMEDIATE,
  NATIVE,
  NO,
];
