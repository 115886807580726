/**
 * Modal.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import BasePopup from 'reactjs-popup';

import './modal.scss';

const Modal = React.forwardRef((props, ref) => {
  // Local reference to the popup.
  const localPopupRef = useRef(undefined);
  // reference to the popup.
  const popupRef = ref || localPopupRef;

  return (
    <div className={ clsx('ody-modal', props.className) }>
      <BasePopup
        arrow={ false }
        className={ props.className }
        closeOnDocumentClick={ false }
        closeOnEscape
        modal
        on={ [ 'click' ] }
        onClose={ props.onClose }
        onOpen={ props.onOpen }
        open={ props.open }
        ref={ popupRef }
        trigger={ props.trigger }
      >
        <button
          className='close-modal btn btn-trans'
          onClick={
            () => {
              popupRef.current?.close();
              props.onClose?.();
            }
          }
          type='button'
        >
          x
        </button>
        { props.children }
      </BasePopup>
    </div>
  );
});

Modal.propTypes = {
  // The content of the modal.
  children: PropTypes.node.isRequired,
  // The class(es) to apply to the modal.
  className: PropTypes.string,
  // The function (() => void) to invoke when the modal is closed.
  onClose: PropTypes.func,
  // The function (() => void) to invoke when the modal is opened.
  onOpen: PropTypes.func,
  // Whether the modal is open.
  open: PropTypes.bool,
  // The trigger of the modal.
  trigger: PropTypes.node.isRequired,
};

Modal.defaultProps = {
};

export default Modal;
