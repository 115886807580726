import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import UnemploymentAnimatedIcon from 'animations/unemployment.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './about-us-unemployment-section.scss';

const AboutUsUnemploymentSection = memo((props) => {
  const { title, infoCollection } = props;

  // The rendered cards
  const renderedCards = useMemo(() => {
    return infoCollection.map((info, infoIndex) => {
      return (
        <div
          className='txt txt-md about-us-unemployment-section__card'
          key={ info + infoIndex }
        >
          { info }
        </div>
      );
    });
  }, [ infoCollection ]);

  return (
    <div className='about-us-unemployment-section'>
      <LottieAnimationContainer
        animatedIconClassName='about-us-unemployment-section__animated-icon'
        animationData={ UnemploymentAnimatedIcon }
        className='about-us-unemployment-section__animation-container'
      />
      <div className='about-us-unemployment-section__content-container'>
        <h3 className='hdg hdg-lg about-us-unemployment-section__title'>
          { title }
        </h3>
        <div className='about-us-unemployment-section__cards-container'>
          { renderedCards }
        </div>
      </div>
    </div>
  );
});

AboutUsUnemploymentSection.displayName = 'AboutUsUnemploymentSection';

AboutUsUnemploymentSection.propTypes = {
  // The info collection
  infoCollection: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsUnemploymentSection.defaultProps = {};

export default AboutUsUnemploymentSection;
