import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardEndorsementCard from 'components/endorsers/dashboard-endorsement-card/dashboard-endorsement-card';
import { takeFirstN } from 'utilities/chisels';
import allCities from 'utilities/cities';
import endorsementStatuses, * as EndorsementStatuses from 'utilities/endorsement-status';

import './dashboard-endorsements-section.scss';

const DashboardEndorsementsSection = memo((props) => {
  const {
    endorsementRequests,
    status,
    claimEndorsementRequest,
    handleReviewClaimedEndorsement,
    revokeClaimedEndorsementRequest,
  } = props;

  const [ viewAll, setViewAll ] = useState(false);

  const { t } = useTranslation();

  // Whether to render the whole array of the endorsement requests or the first 4 elements
  const endorsementRequestsToRender = useMemo(() => {
    return viewAll ? endorsementRequests : takeFirstN(endorsementRequests, 4);
  }, [ endorsementRequests, viewAll ]);

  // Function that is invoked when view all button in the saved job seekers section is clicked
  const handleOnViewAllClick = useCallback(() => {
    setViewAll(!viewAll);
  }, [ viewAll ]);

  // The section title
  const title = useMemo(() => {
    if (status === EndorsementStatuses.CREATED) {
      return t('endorsers:dashboard_endorsements_section.created_endorsement_requests_title');
    }
    return t('endorsers:dashboard_endorsements_section.claimed_endorsement_requests_title');
  }, [ status, t ]);

  // The text when no results found
  const noResultsText = useMemo(() => {
    if (status === EndorsementStatuses.CREATED) {
      return t('endorsers:dashboard_endorsements_section.created_endorsement_requests_no_results');
    }
    return t('endorsers:dashboard_endorsements_section.claimed_endorsement_requests_no_results');
  }, [ status, t ]);

  // the endorsement requests results
  const endorsementRequestsResults = useMemo(() => {
    return endorsementRequestsToRender.map((endorsementRequest, index) => {
      return (
        <DashboardEndorsementCard
          claimEndorsementRequest={ claimEndorsementRequest }
          endorsementRequest={ endorsementRequest }
          handleReviewClaimedEndorsement={ handleReviewClaimedEndorsement }
          key={ endorsementRequest?.endorseeProfile?.id + index }
          revokeClaimedEndorsementRequest={ revokeClaimedEndorsementRequest }
          status={ status }
        />
      );
    });
  }, [
    claimEndorsementRequest,
    endorsementRequestsToRender,
    handleReviewClaimedEndorsement,
    revokeClaimedEndorsementRequest,
    status,
  ]);

  // The endorsement requests results
  const endorsementRequestsResultsSection = useMemo(() => {
    if (!endorsementRequestsToRender?.length) {
      return (
        <div className='dashboard-endorsements-section__no-results'>
          <span className='hdg hdg-sm'>
            { noResultsText }
          </span>
        </div>
      );
    }
    return (
      <div className='dashboard-endorsements-section__results'>
        { endorsementRequestsResults }
      </div>
    );
  }, [ endorsementRequestsResults, endorsementRequestsToRender?.length, noResultsText ]);

  // the view all button title
  const viewAllButtonTitle = useMemo(() => {
    if (viewAll) {
      return t('endorsers:dashboard_endorsements_section.view_less_button_title');
    }
    return t('endorsers:dashboard_endorsements_section.view_all_button_title');
  }, [ t, viewAll ]);

  return (
    <div className='dashboard-endorsements-section'>
      <div className='dashboard-endorsements-section__header'>
        <h2 className='dashboard-endorsements-section__header-title hdg hdg-md'>
          { title }
        </h2>
        <button
          className='btn btn-sm btn-rounded-sm btn-blue'
          disabled={ !endorsementRequestsToRender?.length }
          onClick={ handleOnViewAllClick }
        >
          { viewAllButtonTitle }
        </button>
      </div>
      { endorsementRequestsResultsSection }
    </div>
  );
});

DashboardEndorsementsSection.displayName = 'DashboardEndorsementsSection';

DashboardEndorsementsSection.propTypes = {
  // The function (endorsementId) =>> void to claim a CREATED endorsement request
  claimEndorsementRequest: PropTypes.func,
  // The endorsement requests
  endorsementRequests: PropTypes.arrayOf(
    // The endorsement request
    PropTypes.shape({
      // The profile of the endorsee
      endorseeProfile: PropTypes.shape({
        // The first name of the endorsee
        firstName: PropTypes.string,
        // The id of the endorsee
        id: PropTypes.string,
        // The last name of the endorsee
        lastName: PropTypes.string,
        // The location where the endorsee is.
        location: PropTypes.shape({
          // The city.
          city: PropTypes.oneOf(allCities),
          // The district.
          district: PropTypes.string,
        }),
        // The profile picture of the endorsee.
        profilePicture: PropTypes.shape({
          // The ID of the picture.
          id: PropTypes.string,
          // The MIME type of the picture.
          type: PropTypes.string,
        }),
        // The short bio of the endorsee
        shortBio: PropTypes.string,
      }),
      // the id of the endorsement
      id: PropTypes.string,
      // The status of the endorsements in the section
      status: PropTypes.oneOf(endorsementStatuses).isRequired,
    })
  ).isRequired,
  // The function (endorsement) => void to be invoked when reviewing a claimed endorsement
  handleReviewClaimedEndorsement: PropTypes.func,
  // The function (endorsementId) => void to revoke a CLAIMED endorsement request
  revokeClaimedEndorsementRequest: PropTypes.func,
  // The status of the endorsement requests section
  status: PropTypes.oneOf(endorsementStatuses),
};

DashboardEndorsementsSection.defautlProps = {};

export default DashboardEndorsementsSection;
