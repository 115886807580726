/* eslint-disable */
import React, { memo } from 'react';

const PersonIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7173_15003)'>
        <path
          d='M20 20C23.6833 20 26.6666 17.0167 26.6666 13.3334C26.6666 9.65002 23.6833 6.66669 20 6.66669C16.3166 6.66669 13.3333 9.65002 13.3333 13.3334C13.3333 17.0167 16.3166 20 20 20ZM20 23.3334C15.55 23.3334 6.66663 25.5667 6.66663 30V31.6667C6.66663 32.5834 7.41663 33.3334 8.33329 33.3334H31.6666C32.5833 33.3334 33.3333 32.5834 33.3333 31.6667V30C33.3333 25.5667 24.45 23.3334 20 23.3334Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_7173_15003'>
          <rect fill='white' height='40' width='40' />
        </clipPath>
      </defs>
    </svg>
  );
});

PersonIcon.displayName = 'PersonIcon';

PersonIcon.propTypes = {};

PersonIcon.defaultProps = {};

export default PersonIcon;
