/**
 * Greek translations for utilities/countries.
 *
 * @module languages/el/utilities/countries
 *
 * NOTE: Taken from https://github.com/Dinuks/country-nationality-list.
 */
const countries = {
  ABW: 'Αρούμπα',
  AFG: 'Αφγανιστάν',
  AGO: 'Αγκόλα',
  AIA: 'Ανγκουίλα',
  ALA: 'Νησιά Åland',
  ALB: 'Αλβανία',
  AND: 'Ανδόρα',
  ARE: 'Ηνωμένα Αραβικά Εμιράτα',
  ARG: 'Αργεντινή',
  ARM: 'Αρμενία',
  ASM: 'Αμερικάνικη Σαμόα',
  ATA: 'Ανταρτική',
  ATF: 'Γαλλικά Νότια Εδάφη',
  ATG: 'Αντίγκουα και Μπαρμπούντα',
  AUS: 'Αυστραλία',
  AUT: 'Αυστρία',
  AZE: 'Αζερμπαϊτζάν',
  BDI: 'Μπουρούντι',
  BEL: 'Βέλγιο',
  BEN: 'Μπενίν',
  BES: 'Μποναίρ, Άγιος Ευστάθιος και Σάμπα',
  BFA: 'Μπουρκίνα Φάσο',
  BGD: 'Μπαγκλαντές',
  BGR: 'Βουλγαρία',
  BHR: 'Μπαχρέιν',
  BHS: 'Μπαχάμες',
  BIH: 'Βοσνία και Ερζεγοβίνη',
  BLM: 'Άγιος Βαρθολομαίος',
  BLR: 'Λευκορωσία',
  BLZ: 'Μπελίζε',
  BMU: 'Βερμούδα',
  BOL: 'Βολιβία',
  BRA: 'Βραζιλία',
  BRB: 'Μπαρμπάντος',
  BRN: 'Μπρουνάι Νταρουσαλάμ',
  BTN: 'Μπουτάν',
  BVT: 'Νήσος Μπουβέ',
  BWA: 'Μποτσουάνα',
  CAF: 'Δημοκρατία Κεντρικής Αφρικής',
  CAN: 'Καναδάς',
  CCK: 'Νησιά CoCos ',
  CHE: 'Ελβετία',
  CHL: 'Χιλή',
  CHN: 'Κίνα',
  CIV: 'Ακτή Ελεφαντοστού',
  CMR: 'Καμερούν',
  COD: 'Κονγκό',
  COG: 'Κονγκό',
  COK: 'Νησιά Κουκ',
  COL: 'Κολομβία',
  COM: 'Κομόρες Νήσοι',
  CPV: 'Πράσινο Ακρωτήριο ',
  CRI: 'Κόστα Ρίκα',
  CUB: 'Κούβα',
  CUW: 'Κουρασάο',
  CXR: 'Νησί των Χριστουγέννων',
  CYM: 'Νησιά Κέιμαν',
  CYP: 'Κύπρος',
  CZE: 'Τσεχία',
  DEU: 'Γερμανία',
  DJI: 'Τζιμπουτί',
  DMA: 'Κοινοπολιτεία της Ντομίνικα',
  DNK: 'Δανία',
  DOM: 'Δομινικανή Δημοκρατία',
  DZA: 'Αλγερία',
  ECU: 'Εκουαδόρ',
  EGY: 'Αίγυπτος',
  ERI: 'Ερέτρια',
  ESH: 'Δυτική Σαχάρα',
  ESP: 'Ισπανία',
  EST: 'Εσθονία',
  ETH: 'Αιθιοπία',
  FIN: 'Φιλανδία',
  FJI: 'Φίτζι',
  FLK: 'Νησιά Φώκλαντ (Μαλβίνες)',
  FRA: 'Γαλλία',
  FRO: 'Νήσοι Φερόες',
  FSM: 'Μικρονησία (Ομοσπονδιακά κράτη)',
  GAB: 'Γκαμπόν',
  GBR: 'Ηνωμένο Βασίλειο της Μεγάλης Βρετανίας και της Βόρειας Ιρλανδίας',
  GEO: 'Γεωργία',
  GGY: 'Γκέρνσεϊ',
  GHA: 'Γκάνα',
  GIB: 'Γιβραλτάρ',
  GIN: 'Γουινέα',
  GLP: 'Γουαδελούπη',
  GMB: 'Γκάμπια',
  GNB: 'Γουινέα-Μπισάου',
  GNQ: 'Ισημερινή Γουινέα',
  GRC: 'Ελλάδα',
  GRD: 'Γρενάδα',
  GRL: 'Γροιλανδία',
  GTM: 'Γουατεμάλα',
  GUF: 'Γαλλική Γουιάνα',
  GUM: 'Γκουάμ',
  GUY: 'Συνεργατική Δημοκρατία της Γουιάνας ',
  HKG: 'Χονγκ Κονγκ',
  HMD: 'Χερντ και ΜακΝτόναλντ Νήσοι',
  HND: 'Ονδούρα',
  HRV: 'Κροατία',
  HTI: 'Αΐτη',
  HUN: 'Ουγγαρία',
  IDN: 'Δημοκρατία της Ινδονησίας ',
  IMN: 'Νήσος του Μαν',
  IND: 'Ινδία',
  IOT: 'Βρετανο-Ινδική ωκεανική έκταση',
  IRL: 'Ιρλανδία',
  IRN: 'Ιράν',
  IRQ: 'Ιράκ',
  ISL: 'Ισλανδία',
  ISR: 'Ισραήλ',
  ITA: 'Ιταλία',
  JAM: 'Τζαμάικα',
  JEY: 'Τζέρσεϋ',
  JOR: 'Χασεμιτικό Βασίλειο της Ιορδανίας ',
  JPN: 'Ιαπωνία',
  KAZ: 'Καζακστάν',
  KEN: 'Κένυα',
  KGZ: 'Κιργιστάν',
  KHM: 'Καμπότζη',
  KIR: 'Κιριμπάτι',
  KNA: 'Σαιντ Κιτς και Νέβις p',
  KOR: 'Δημοκρατία της Κορέας',
  KWT: 'Κουβέιτ',
  LAO: 'Λαϊκή Δημοκρατία του Λάος ',
  LBN: 'Λίβανος',
  LBR: 'Λιβερία',
  LBY: 'Λιβύη',
  LCA: 'Σάντα Λουτσία ',
  LIE: 'Πριγκηπάτο του Λιχτενστάιν',
  LKA: 'Σρι Λάνκα',
  LSO: 'Βασίλειο του Λεσόθο ',
  LTU: 'Λιθουανία',
  LUX: 'Λουξεμβούργο',
  LVA: 'Λετονία',
  MAC: 'Μακάο',
  MAF: 'Saint Martin (Γαλλικό τμήμα)',
  MAR: 'Μαρόκο ',
  MCO: 'Μονακό',
  MDA: 'Μολδαβία (Δημοκρατία της Μολδαβίας)',
  MDG: 'Δημοκρατία της Μαδαγασκάρης',
  MDV: 'Μαλδίβες',
  MEX: 'Μεξικό',
  MHL: 'Νήσοι Μάρσαλ',
  MKD: 'Μακεδονία (Πρώην Γιουγκοσλαβική Δημοκρατία της)',
  MLI: 'Δημοκρατία του Μαλί ',
  MLT: 'Μάλτα',
  MMR: 'Μιανμάρ',
  MNE: 'Μαυροβούνιο',
  MNG: 'Μογγολία',
  MNP: 'Νήσοι Βόρειες Μαριάνες',
  MOZ: 'Μοζαμβίκη',
  MRT: 'Μαυριτανία',
  MSR: 'Μονσεράτ',
  MTQ: 'Μαρτινίκα',
  MUS: 'Μαυρίκιος',
  MWI: 'Μαλάουι',
  MYS: 'Μαλαισία',
  MYT: 'Μαγιότ',
  NAM: 'Ναμίμπια',
  NCL: 'Νέα Καληδονία',
  NER: 'Νίγηρας',
  NFK: 'Νησί Νόρφολκ',
  NGA: 'Νιγηρία',
  NIC: 'Νικαράγουα',
  NIU: 'Νιούε',
  NLD: 'Ολλανδία',
  NOR: 'Νορβηγία',
  NPL: 'Νεπάλ',
  NRU: 'Ναούρου',
  NZL: 'Νέα Ζηλανδία',
  OMN: 'Ομάν',
  PAK: 'Πακιστάν',
  PAN: 'Παναμάς',
  PCN: 'Νησιά Πίτκαιρν',
  PER: 'Περού',
  PHL: 'Φιλιππίνες',
  PLW: 'Παλάου',
  PNG: 'Παπούα-Νέα Γουινέα',
  POL: 'Πολωνία',
  PRI: 'Πουέρτο Ρίκο',
  PRK: 'Δημοκρατία της Κορέας (Λαϊκή Δημοκρατία της Κορέας)',
  PRT: 'Πορτογαλία',
  PRY: 'Παραγουάη',
  PSE: 'Παλαιστίνη',
  PYF: 'Γαλλική Πολυνησία',
  QAT: 'Κατάρ',
  REU: 'Ρεϋνιόν',
  ROU: 'Ρουμανία',
  RUS: 'Ρωσική Ομοσπονδία',
  RWA: 'Ρουάντα',
  SAU: 'Σαουδική Αραβία',
  SDN: 'Σουδάν',
  SEN: 'Σενεγάλη',
  SGP: 'Σιγκαπούρη',
  SGS: 'Νότια Γεωργία και τα Νότια Σάντουιτς Νησιά',
  SHN: 'Νήσου Αναλήψεως',
  SJM: 'Νήσοι Σφάλμπαρ και Γιαν Μαϋέν ',
  SLB: 'Νησιά του Σολομώντα',
  SLE: 'Σιέρρα Λεόνε',
  SLV: 'Ελ Σαλβαδόρ',
  SMR: 'Σαν Μαρίνο',
  SOM: 'Σομαλία',
  SPM: 'Σαιν Πιερ και Μικελόν',
  SRB: 'Σερβία',
  SSD: 'Νότιο Σουδάν',
  STP: 'Σάο Τομέ και Πρίνσιπε',
  SUR: 'Σουρινάμ',
  SVK: 'Σλοβακία',
  SVN: 'Σλοβενία',
  SWE: 'Σουηδία',
  SWZ: 'Ζουαζιλάνδη',
  SXM: 'Sint Maarten (Ολλανδικό μέρος)',
  SYC: 'Σεϋχέλλες',
  SYR: 'Αραβική Δημοκρατία της Συρίας\n',
  TCA: 'Νήσοι Τερκς και Κάικος',
  TCD: 'Τσαντ',
  TGO: 'Τόγκο',
  THA: 'Ταϊλάνδη',
  TJK: 'Τατζικιστάν',
  TKL: 'Τοκελάου',
  TKM: 'Τουρκμενιστάν',
  TLS: 'Ανατολικό Τιμόρ',
  TON: 'Τόνγκα',
  TTO: 'Τρινιδάδ και Τομπάγκο',
  TUN: 'Τυνησία',
  TUR: 'Τουρκία',
  TUV: 'Τουβαλού',
  TWN: 'Ταϊβάν',
  TZA: 'Τανζανία',
  UGA: 'Ουγκάντα',
  UKR: 'Ουκρανία',
  UMI: 'Μικρά Απομακρυσμένα Νησιά των Ηνωμένων Πολιτειών',
  URY: 'Ουρουγουάη',
  USA: 'Ηνωμένες Πολιτείες της Αμερικής',
  UZB: 'Ουζμπεκιστάν',
  VAT: 'Κράτος της Πόλης του Βατικανού',
  VCT: 'Άγιος Βικέντιος και Γρεναδίνες',
  VEN: 'Βενεζουέλα',
  VGB: 'Παρθένοι Νήσοι (Βρετανία)',
  VIR: 'Παρθένοι Νήσοι (ΗΠΑ)',
  VNM: 'Βιετνάμ',
  VUT: 'Βανουάτου',
  WLF: 'Νήσοι Ουώλις και Φουτούνα',
  WSM: 'Σαμόα',
  YEM: 'Υεμένη',
  ZAF: 'Νότια Αφρική',
  ZMB: 'Ζάμπια',
  ZWE: 'Ζιμπάμπουε',
};

export default countries;
