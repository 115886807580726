import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Tags from 'components/common/tags/tags';
import roles, * as Roles from 'utilities/auth/roles';
import { parseRange, toYears } from 'utilities/chisels';
import companiesFilterTypes, * as CompaniesFilterTypes from 'utilities/companies/filter-types';
import endorsersFilterTypes, * as EndorsersFilterTypes from 'utilities/endorsers/filter-types';
import jobSeekersFilterTypes, * as JobSeekersFilterTypes from 'utilities/job-seekers/filter-types';

import './search-selected-filters.scss';

const SearchSelectedFilters = memo((props) => {
  const { allProfessions, filters, onChange, role } = props;

  const { i18n, t } = useTranslation();

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Function that is invoked when deleting tag
  const handleOnDeleteTag = useCallback((_tag, index) => {
    const filteredTags = filters.filter((_filter, filterIndex) => {
      return index !== filterIndex;
    });
    onChange(filteredTags);
  }, [ filters, onChange ]);

  // The experience length filter label
  const constructExperienceLengthFilterLabel = useCallback((filter) => {
    const range = toYears(parseRange(filter.value));
    return `${ t('utilities:job_seekers.filter_types.EXPERIENCE_LENGTH') }: 
    ${ range.start } - ${ range.end }`;
  }, [ t ]);

  // The profession filter label
  const constructProfessionFilterLabel = useCallback((filter) => {
    const foundProfession = allProfessions.find((profession) => {
      return profession.id === filter.value;
    });
    return `${ t('utilities:job_seekers.filter_types.PROFESSION') }: ${ foundProfession[selectedLanguage] }`;
  }, [ allProfessions, selectedLanguage, t ]);

  // The profile completion percentage filter label
  const constructProfileCompletionPercentageFilterLabel = useCallback((filter) => {
    const range = parseRange(filter.value);
    return `${ t('utilities:job_seekers.filter_types.PROFILE_COMPLETION_PERCENTAGE') }: `
      + `${ range.start } - ${ range.end }`;
  }, [ t ]);

  // The total endorsements range filter label
  const constructTotalEndorsementsRangeFilterLabel = useCallback((filter) => {
    const range = parseRange(filter.value);
    return `${ t('utilities:endorsers.filter_types.TOTAL_ENDORSEMENTS_RANGE') }: `
      + `${ range.start } - ${ range.end }`;
  }, [ t ]);

  // Function that creates the filter tag label
  const createFilterTag = useCallback((filter) => {
    let label = '';
    switch (filter.type) {
    case JobSeekersFilterTypes.COMPUTER_SKILLS_LEVEL:
      label = `${ t('utilities:job_seekers.filter_types.COMPUTER_SKILLS_LEVEL') }: `
        + `${ t(`utilities:computer_skills_levels.${ filter.value }`) }`;
      break;
    case JobSeekersFilterTypes.DRIVING_LICENSE_CATEGORY:
      label = `${ t('utilities:job_seekers.filter_types.DRIVING_LICENSE_CATEGORY') }: `
        + `${ t(`utilities:driving_license_categories.${ filter.value }`) }`;
      break;
    case JobSeekersFilterTypes.LANGUAGE:
      label = `${ t('utilities:job_seekers.filter_types.LANGUAGE') }: `
          + `${ t(`utilities:languages.${ filter.value }`) }`;
      break;
    case JobSeekersFilterTypes.EXPERIENCE_LENGTH:
      label = constructExperienceLengthFilterLabel(filter);
      break;
    case JobSeekersFilterTypes.PROFESSION:
      label = constructProfessionFilterLabel(filter);
      break;
    case JobSeekersFilterTypes.PROFILE_COMPLETION_PERCENTAGE:
      label = constructProfileCompletionPercentageFilterLabel(filter);
      break;
    case JobSeekersFilterTypes.QUALIFICATION_TYPE:
      label = `${ t('utilities:job_seekers.filter_types.QUALIFICATION_TYPE') }: `
          + `${ t(`utilities:qualification_types.${ filter.value }`) }`;
      break;
    case JobSeekersFilterTypes.WILLING_TO_RELOCATE:
      label = `${ t('utilities:job_seekers.filter_types.WILLING_TO_RELOCATE') }: `
          + `${ t(`job_seekers:search.selected_filters.${ 'true' === filter.value ? 'yes' : 'no' }`) }`;
      break;
    case JobSeekersFilterTypes.WORK_AVAILABILITY:
      label = `${ t('utilities:job_seekers.filter_types.WORK_AVAILABILITY') }: `
          + `${ t(`utilities:work_availabilities.${ filter.value }`) }`;
      break;
    case CompaniesFilterTypes.NUMBER_OF_EMPLOYEES:
      label = `${ t('utilities:companies.filter_types.NUMBER_OF_EMPLOYEES') }: `
            + `${ t(`utilities:number_of_employees.${ filter.value }`) }`;
      break;
    case EndorsersFilterTypes.TOTAL_ENDORSEMENTS_RANGE:
      label = constructTotalEndorsementsRangeFilterLabel(filter);
      break;
    default:
      label = '';
      break;
    }
    return {
      label,
      value: filter.value,
    };
  }, [
    constructExperienceLengthFilterLabel,
    constructProfessionFilterLabel,
    constructProfileCompletionPercentageFilterLabel,
    constructTotalEndorsementsRangeFilterLabel,
    t,
  ]);

  // The filter tags
  const filterTags = useMemo(() => {
    return filters?.map((filter) => {
      return createFilterTag(filter);
    });
  }, [ createFilterTag, filters ]);

  return (
    <Tags
      className={
        clsx('search-selected-filters light', {
          'search-selected-filters__companies': role === Roles.COMPANY_AGENT,
          'search-selected-filters__endorsers': role === Roles.ENDORSER,
          'search-selected-filters__job-seekers': role === Roles.JOB_SEEKER,
        })
      }
      moveable={ false }
      onDelete={ handleOnDeleteTag }
      tags={ filterTags }
    />
  );
});

SearchSelectedFilters.displayName = 'SearchSelectedFilters';

SearchSelectedFilters.propTypes = {
  // All the professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // The green translation of the profession
      el: PropTypes.string.isRequired,
      // The english translation of the profession
      en: PropTypes.string.isRequired,
      // The id of the profession
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  // The filters
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      // The type of the filter
      type: PropTypes.oneOf([
        ...jobSeekersFilterTypes,
        ...companiesFilterTypes,
        ...endorsersFilterTypes,
      ]).isRequired,
      // The value of the actual type of the filter
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  // The function ((object[]) => void) to invoke when the filters change.
  onChange: PropTypes.func.isRequired,
  // The role from which this is component is called
  role: PropTypes.oneOf(roles).isRequired,
};

SearchSelectedFilters.defaultProps = {
  filters: [],
};

export default SearchSelectedFilters;
