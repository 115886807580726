import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import CompanyName from 'images/legalname.png';
import Location from 'images/location.png';
import Position from 'images/position.png';
import Website from 'images/share.png';
import { isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import endorsementStatuses from 'utilities/endorsement-status';

import './search-result-card.scss';

const EndorsersSearchResultCard = memo((props) => {
  const { result } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  // The rendered profile picture
  const renderedProfilePicture = useMemo(() => {
    if (undefined === result?.profilePicture) {
      return defaultProfilePicture;
    }
    return `${ env.CORE_BASE_URL }/endorsers/${ result?.id }/profile/picture?id=${ result?.profilePicture?.id }`;
  }, [ result?.id, result?.profilePicture ]);

  // The profile picture
  const profilePicture = useMemo(() => {
    return (
      <CircularImage
        alt='Profile Picture'
        imageSource={ renderedProfilePicture }
      />
    );
  }, [ renderedProfilePicture ]);

  // The short bio section
  const renderedShortBio = useMemo(() => {
    if (!result?.shortBio) {
      return null;
    }
    return (
      <p className='search-result-card__short-bio txt txt-sm dark'>
        { result?.shortBio }
      </p>
    );
  }, [ result?.shortBio ]);

  // The location
  const locationDisplayString = useMemo(() => {
    let city = '';
    if (result?.location?.city) {
      city = t(`utilities:cities.${ result?.location.city }`);
    }
    return [
      result?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ result?.location?.city, result?.location?.district, t ]);

  // website section
  const website = useMemo(() => {
    if (!result?.website) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='Website'
        href={ result.website }
        icon={ Website }
        textDisplayed={ t('endorsers:search_result_card.company_website_label') }
      />
    );
  }, [ result?.website, t ]);

  // location section
  const locationSection = useMemo(() => {
    if (!result?.location) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='location'
        icon={ Location }
        textDisplayed={ locationDisplayString }
      />
    );
  }, [ locationDisplayString, result?.location ]);

  // The company position
  const companyPositionSection = useMemo(() => {
    if (!result?.companyPosition) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company position'
        icon={ Position }
        textDisplayed={ result.companyPosition }
      />
    );
  }, [ result?.companyPosition ]);

  // The company Name
  const companyNameSection = useMemo(() => {
    if (!result?.companyName) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company name'
        icon={ CompanyName }
        textDisplayed={ result.companyName }
      />
    );
  }, [ result.companyName ]);

  // function to be invoked when clicking the card
  const handleCardClick = useCallback(() => {
    navigate(`/endorsers/${ result?.id }/profile`);
  }, [ navigate, result?.id ]);

  return (
    <button
      className='search-result-card'
      onClick={ handleCardClick }
      type='button'
    >
      <div className='search-result-card__top-section'>
        <div className='search-result-card__profile-picture-and-name'>
          <div className={
            clsx('search-result-card__profile-picture', {
              'search-result-card__no-picture': undefined === result.profilePicture,
            })
          }
          >
            { profilePicture }
          </div>
          <div className='search-result-card__full-name'>
            <p className='hdg hdg-md dark'>
              { result.firstName }
            </p>
            <p className='hdg hdg-md dark'>
              { result.lastName }
            </p>
          </div>
        </div>
        { renderedShortBio }
      </div>
      <div className='search-result-card__bottom-section'>
        { website }
        { locationSection }
        { companyNameSection }
        { companyPositionSection }
      </div>
    </button>
  );
});

EndorsersSearchResultCard.displayName = 'SearchResultCard';

EndorsersSearchResultCard.propTypes = {
  // endorser profile
  result: PropTypes.shape({
    // The endorser company name
    companyName: PropTypes.string,
    // The endorser company position
    companyPosition: PropTypes.string,
    // The endorsements viewed by companies
    endorsementsViewsByCompanies: PropTypes.number,
    // The first name
    firstName: PropTypes.string,
    // Whether it is hidden
    hidden: PropTypes.bool,
    // The id of the endorser
    id: PropTypes.string,
    // The last name
    lastName: PropTypes.string,
    // Where the job seeker is currently located.
    location: PropTypes.shape({
      // The city.
      city: PropTypes.oneOf(allCities),
      // The district.
      district: PropTypes.string,
    }),
    // The mobile phone number
    mobilePhoneNumber: PropTypes.string,
    // The pending endorsement requests
    pendingEndorsementRequests: PropTypes.arrayOf(
      // The endorsement request
      PropTypes.shape({
        // The profile of the endorsee
        endorseeProfile: PropTypes.shape({
          // The first name of the endorsee
          firstName: PropTypes.string,
          // The id of the endorsee
          id: PropTypes.string,
          // The last name of the endorsee
          lastName: PropTypes.string,
          // The location where the endorsee is.
          location: PropTypes.shape({
            // The city.
            city: PropTypes.oneOf(allCities),
            // The district.
            district: PropTypes.string,
          }),
          // The profile picture of the endorsee.
          profilePicture: PropTypes.shape({
            // The ID of the picture.
            id: PropTypes.string,
            // The MIME type of the picture.
            type: PropTypes.string,
          }),
          // The short bio of the endorsee
          shortBio: PropTypes.string,
        }),
        // the id of the endorsement
        id: PropTypes.string,
        // The status of the endorsements in the section
        status: PropTypes.oneOf(endorsementStatuses),
      }),
    ),
    // The profile picture of the job seeker.
    profilePicture: PropTypes.shape({
      // The ID of the picture.
      id: PropTypes.string.isRequired,
      // The MIME type of the picture.
      type: PropTypes.string.isRequired,
    }),
    // The short bio of the job seeker.
    shortBio: PropTypes.string,
    // The total endorsements
    totalEndorsements: PropTypes.number,
    // The website
    website: PropTypes.string,
  }),
};

EndorsersSearchResultCard.defaultProps = {};

export default EndorsersSearchResultCard;
