/**
 * Scrollable.
 */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './scrollable.scss';

const Scrollable = memo((props) => {
  const location = useLocation();

  // Reference to the scrollable area.
  const ref = useRef();

  // Scroll to top when location changes.
  useEffect(() => {
    // Scroll into view if there is an anchor element with smooth scrolling
    if (location.hash && document.querySelector(`${ location.hash }`)) {
      const elementToScroll = document.querySelector(`${ location.hash }`);
      elementToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      return;
    }
    ref.current?.scrollTo?.(0, 0);
  }, [ location ]);

  return (
    <div className='ody-scrollable' ref={ ref }>
      { props.children }
    </div>
  );
});

Scrollable.displayName = 'Scrollable';

Scrollable.propTypes = {
  // The content to scroll.
  children: PropTypes.node,
};

Scrollable.defaultProps = {
};

export default Scrollable;
