/**
 * English translations for endorsers/dashboard-endorsements-section.
 *
 * @module languages/en/endorsers/dashboard-endorsements-section
 */
const dashboardEndorsementsSection = {
  claimed_endorsement_requests_no_results: 'You have currently no pending endorsement requests.',
  claimed_endorsement_requests_title: 'My Pending Endorsements',
  created_endorsement_requests_no_results: 'There are currently no endorsement requests.',
  created_endorsement_requests_title: 'Endorsement Requests',
  view_all_button_title: 'View All',
  view_less_button_title: 'View Less',
};

export default dashboardEndorsementsSection;
