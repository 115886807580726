/**
 * Resource method to create the controlled metrics.
 *
 * @module resources/metrics/create-controlled-metrics
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef {module:types/metrics~ControlledMetrics} Params
 */

/**
 * Creates the controlled metrics.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/metrics/create-controlled-metrics~Params} params - The params
 * @returns {Promise<void>} - The promise.
 * @static
 */
const createControlledMetrics = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/metrics/',
  }).then((response) => {
    return response.data;
  });
};

export default createControlledMetrics;
