/**
 * The communication and cooperation skills levels.
 *
 * @module utilities/endorsement/communication-cooperation-skills-levels
 */

/**
 * Poor.
 *
 * @constant {string}
 * @static
 */
export const POOR = 'POOR';

/**
 * Willness.
 *
 * @constant {string}
 * @static
 */
export const WILLNESS = 'WILLNESS';

/**
 * Ability.
 *
 * @constant {string}
 * @static
 */
export const ABILITY = 'ABILITY';

/**
 * Good level.
 *
 * @constant {string}
 * @static
 */
export const GOOD_LEVEL = 'GOOD_LEVEL';

/**
 * Professional.
 *
 * @constant {string}
 * @static
 */
export const PROFESSIONAL = 'PROFESSIONAL';

export default [
  POOR,
  WILLNESS,
  ABILITY,
  GOOD_LEVEL,
  PROFESSIONAL,
];
