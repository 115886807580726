/**
 * Settings - Preferences - Language.
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import Modal from 'components/common/modal/modal';

import LanguageEditor from './language-editor/language-editor';
import './language.scss';

const Language = (_props) => {
  // The application language.
  const language = useSelector((store) => {
    return store.settings.language;
  }, shallowEqual);

  const { t } = useTranslation();

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  return (
    <div className='language subsection'>
      <div className='title-and-text'>
        <div className='title'>
          { t('settings:preferences.language.title') }
        </div>
        <div className='text'>
          { t(`utilities:languages.${ language }`) }
        </div>
      </div>
      <Modal
        className='settings-editor-modal'
        onClose={
          () => {
            setEditorOpen(false);
          }
        }
        onOpen={
          () => {
            setEditorOpen(true);
          }
        }
        open={ editorOpen }
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='button'
          >
            { t('settings:common.change_button_label') }
          </button>
        }
      >
        <LanguageEditor
          onClose={
            () => {
              setEditorOpen(false);
            }
          }
        />
      </Modal>
    </div>
  );
};

Language.propTypes = {
};

Language.defaultProps = {
};

export default Language;
