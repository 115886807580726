/**
 * Resource method to verify a password reset token.
 *
 * @module resources/auth/verify-password-reset-token
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/auth/verify-password-reset-token~Params
 * @type {object}
 * @property {string} passwordResetToken - The password reset token.
 */

/**
 * Verifies a password reset token based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/auth/verify-password-reset-token~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const verifyPasswordResetToken = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/auth/password-reset-token/verify',
  }).then((response) => {
    return response.data;
  });
};

export default verifyPasswordResetToken;
