/**
 * Greek translations for common/dashboard-statistics.
 *
 * @module languages/el/common/dashboard-statistics
 */
const dashboardStatistics = {
  companies_interested: 'Εταιρείες που ενδιαφέρονται για εσάς',
  endorsements_viewed_by_companies: 'από τις συστάσεις σας προβλήθηκαν από εταιρείες',
  job_ready_profiles: 'Προφίλ έτοιμα για εργασία',
  job_seekers_available: 'Διαθέσιμα προφίλ ατόμων που αναζητούν εργασία',
  my_total_endorsements: 'Οι συνολικές σας συστάσεις ',
  new_endorsements_last_week: 'Νέα αιτήματα συστάσεων',
  new_job_seeker_profiles: 'Νέα προφίλ την προηγουμένη εβδομάδα',
  profile_views: 'Εταιρίες που είδαν το προφίλ σας',
  profile_views_last_week: 'Εταιρίες που είδαν το προφίλ σας την προηγουμένη εβδομάδα',
};

export default dashboardStatistics;
