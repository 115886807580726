/**
 * English translations for utilities/computer-skills-levels.
 *
 * @module languages/en/utilities/computer-skills-levels
 */
const computerSkillsLevels = {
  ADVANCED: 'Advanced (Basic usage plus job-specific software)',
  BASIC: 'Basic (Browsers, email, office suites, social media)',
  EXPERT: 'Expert (Programming, networks, operating systems)',
  NO: 'No',
};

export default computerSkillsLevels;
