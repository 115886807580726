/**
 * Sign-up form.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import PasswordInput from 'components/common/password-input/password-input';
import env from 'config/env';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as toasts from 'toasts';
import roles, * as Roles from 'utilities/auth/roles';
import { isNotEmpty, omit } from 'utilities/chisels';
import {
  companiesSignUpSchemaValidator,
  endorsersSignUpSchemaValidator,
  jobSeekersSignUpSchemaValidator,
} from 'utilities/validators';

const SignUpForm = memo((props) => {
  const { role, handleCreated } = props;

  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  // Whether the recaptcha is successfully submitted
  const [ recaptchaSubmitted, setRecaptchaSubmitted ] = useState(false);

  useEffect(() => {
    // Reset the recaptcha every time the form is rendering
    recaptchaRef.current.reset();
  }, []);

  // The validation schema depending on the role
  const validationSchema = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersSignUpSchemaValidator;
    case Roles.COMPANY_AGENT:
      return companiesSignUpSchemaValidator;
    case Roles.ENDORSER:
      return endorsersSignUpSchemaValidator;
    default:
      return jobSeekersSignUpSchemaValidator;
    }
  }, [ role ]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useFormWithSchema(validationSchema);

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The sign up params values
  const signUpParams = useCallback((values) => {
    if ('' === values.companyName) {
      delete values.companyName;
    }
    if ('' === values.companyPosition) {
      delete values.companyPosition;
    }
    if ('' === values.mobilePhoneNumber) {
      delete values.mobilePhoneNumber;
    }
    return {
      ...omit(values, [
        'agreedWithPrivacyPolicy',
        'agreedWithTermsOfService',
        'passwordConfirmation',
      ]),
      email: values.email.toLowerCase(),
      language: selectedLanguage,
    };
  }, [ selectedLanguage ]);

  // function that sets the sign up error
  const setSignUpError = useCallback((error) => {
    switch (error.message) {
    case 'USER_ALREADY_EXISTS':
      toasts.error(t('sign_up:user_already_exists_error'));
      // reset the email
      setValue('email', '');
      // manually trigger the form validation for the email
      trigger('email');
      break;
    default:
      toasts.error(t('sign_up:general_error'));
      break;
    }
  }, [ setValue, t, trigger ]);

  // function that handles the sign up as a job seeker
  const signUpAsJobSeeker = useCallback((values) => {
    dispatch(requestsActions.request(jobSeekersMethods.signUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);
  //
  // function that handles the sign up as a company agent
  const signupAsCompanyAgent = useCallback((values) => {
    dispatch(requestsActions.request(companiesMethods.signUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);
  //
  // function that handles the sign up as an endorser
  const signUpAsEndorser = useCallback((values) => {
    dispatch(requestsActions.request(endorsersMethods.signUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);

  // function that triggers when the handleSubmit from react hook form is triggering
  const onSubmit = useCallback((values) => {
    if (!recaptchaSubmitted) {
      return;
    }
    switch (role) {
    case Roles.JOB_SEEKER:
      signUpAsJobSeeker(values);
      break;
    case Roles.COMPANY_AGENT:
      signupAsCompanyAgent(values);
      break;
    case Roles.ENDORSER:
      signUpAsEndorser(values);
      break;
    default:
      signUpAsJobSeeker(values);
      break;
    }
  }, [ recaptchaSubmitted, role, signUpAsEndorser, signUpAsJobSeeker, signupAsCompanyAgent ]);

  // The rendered password input
  const renderedPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.password }
        id='sign-up-password-input'
        label={ t('sign_up:password_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('sign_up:password_placeholder') }
        value={ value }
      />
    );
  }, [ errors.password, t ]);

  // The rendered confirmation password input element
  const renderedConfirmationPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.passwordConfirmation }
        id='sign-up-password-confirmation-input'
        label={ t('sign_up:password_confirmation_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('sign_up:password_confirmation_placeholder') }
        value={ value }
      />
    );
  }, [ errors.passwordConfirmation, t ]);

  // company name empty string error
  const companyNameEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.companyName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:company_name_required_error') }
      </span>
    );
  }, [ errors.companyName?.type, t ]);

  // The company name input
  const companyNameInput = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='ody-sign-up__form-control'>
        <label htmlFor='companyName'>
          { t('sign_up:company_name_label') }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.companyName),
            })
          }
          id='companyName'
          maxLength={ 256 }
          name='companyName'
          placeholder={ t('sign_up:company_name_placeholder') }
          type='text'
          { ...register('companyName') }
        />
        { companyNameEmptyStringError }
      </div>
    );
  }, [ companyNameEmptyStringError, errors.companyName, register, role, t ]);

  // the empty phone number error
  const emptyPhoneNumberError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // the mobile phone number register string
  const mobilePhoneNumberRegisterString = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return 'phoneNumber';
    }
    return 'mobilePhoneNumber';
  }, [ role ]);

  // The phone number pattern error
  const phoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:phone_number_pattern_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // The phone number label
  const phoneNumberLabel = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return t('sign_up:phone_number_label');
    }
    return t('sign_up:phone_number_label_endorsers');
  }, [ role, t ]);

  // The phone number input
  const phoneNumberInput = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='ody-sign-up__form-control'>
        <label htmlFor={ mobilePhoneNumberRegisterString }>
          { phoneNumberLabel }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.phoneNumber),
            })
          }
          id={ mobilePhoneNumberRegisterString }
          maxLength={ 14 }
          name={ mobilePhoneNumberRegisterString }
          placeholder={ t('sign_up:phone_number_placeholder') }
          type='text'
          { ...register(mobilePhoneNumberRegisterString) }
        />
        { emptyPhoneNumberError }
        { phoneNumberPatternError }
      </div>
    );
  }, [
    emptyPhoneNumberError,
    errors.phoneNumber,
    mobilePhoneNumberRegisterString,
    phoneNumberLabel,
    phoneNumberPatternError,
    register,
    role,
    t,
  ]);

  // The endorser's optional company info inputs
  const endorsersCompanyInputs = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <>
        <div className='ody-sign-up__form-control'>
          <label htmlFor='companyName'>
            { t('sign_up:endorser_company_name_label') }
          </label>
          <input
            id='companyName'
            maxLength={ 256 }
            name='companyName'
            placeholder={ t('sign_up:company_name_placeholder') }
            type='text'
            { ...register('companyName') }
          />
        </div>
        <div className='ody-sign-up__form-control'>
          <label htmlFor='companyPosition'>
            { t('sign_up:endorser_company_position_label') }
          </label>
          <input
            id='companyPosition'
            maxLength={ 256 }
            name='companyPosition'
            placeholder={ t('sign_up:company_position_placeholder') }
            type='text'
            { ...register('companyPosition') }
          />
        </div>
      </>
    );
  }, [ register, role, t ]);
  // first name empty string error
  const firstNameEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.firstName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:first_name_required_error') }
      </span>
    );
  }, [ errors.firstName?.type, t ]);

  // last name empty string error
  const lastNameEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.lastName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:last_name_required_error') }
      </span>
    );
  }, [ errors.lastName?.type, t ]);

  // the empty string email error
  const emailEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:email_required_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // the false email pattern error
  const invalidEmailPatternError = useMemo(() => {
    if ('string.email' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:email_invalid_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // The empty string password error
  const emptyStringPasswordError = useMemo(() => {
    if ('string.empty' !== errors.password?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:password_required_error') }
      </span>
    );
  }, [ errors.password?.type, t ]);

  // The invalid pattern password error
  const invalidPatternPasswordError = useMemo(() => {
    if (-1 === [ 'string.min', 'string.pattern.base' ].indexOf(errors.password?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:password_invalid_error') }
      </span>
    );
  }, [ errors.password?.type, t ]);

  // The password confirmation error
  const passwordConfirmationError = useMemo(() => {
    if ('any.only' !== errors.passwordConfirmation?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:password_and_password_confirmation_mismatch_error') }
      </span>
    );
  }, [ errors.passwordConfirmation?.type, t ]);

  // function that is invoked when user has successfully added recaptcha
  const handleRecaptchaChange = useCallback((token) => {
    if (!token) {
      return;
    }
    setRecaptchaSubmitted(true);
  }, []);

  // function that is invoked when recaptcha expires
  const handleRecaptchaExpire = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // function that is invoked when recaptcha has error
  const handleRecaptchaError = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // The 'agreed with terms of service' error
  const agreedWithTermsOfServiceError = useMemo(() => {
    if ('any.only' !== errors.agreedWithTermsOfService?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:must_agree_with_terms_of_service_error') }
      </span>
    );
  }, [ errors.agreedWithTermsOfService?.type, t ]);

  // The 'agreed with privacy policy' error
  const agreedWithPrivacyPolicyError = useMemo(() => {
    if ('any.only' !== errors.agreedWithPrivacyPolicy?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:must_agree_with_privacy_policy_error') }
      </span>
    );
  }, [ errors.agreedWithPrivacyPolicy?.type, t ]);

  return (
    <form
      className='ody-sign-up__form'
      noValidate
      onSubmit={ handleSubmit(onSubmit) }
    >
      { companyNameInput }
      <div className='ody-sign-up__form-control'>
        <label htmlFor='firstName'>
          { t('sign_up:first_name_label') }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.firstName),
            })
          }
          id='firstName'
          maxLength={ 256 }
          name='firstName'
          placeholder={ t('sign_up:first_name_placeholder') }
          type='text'
          { ...register('firstName') }
        />
        { firstNameEmptyStringError }
      </div>
      <div className='ody-sign-up__form-control'>
        <label htmlFor='lastName'>
          { t('sign_up:last_name_label') }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.lastName),
            })
          }
          id='lastName'
          maxLength={ 256 }
          name='lastName'
          placeholder={ t('sign_up:last_name_placeholder') }
          type='text'
          { ...register('lastName') }
        />
        { lastNameEmptyStringError }
      </div>
      <div className='ody-sign-up__form-control'>
        <label htmlFor='email'>
          { t('sign_up:email_label') }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.lastName),
            })
          }
          id='email'
          maxLength={ 256 }
          name='email'
          placeholder={ t('sign_up:email_placeholder') }
          type='text'
          { ...register('email') }
        />
        { emailEmptyStringError }
        { invalidEmailPatternError }
      </div>
      <div className='ody-sign-up__form-control'>
        <Controller
          control={ control }
          defaultValue=''
          name='password'
          render={ renderedPasswordInputElement }
        />
        { emptyStringPasswordError }
        { invalidPatternPasswordError }
      </div>
      <div className='ody-sign-up__form-control'>
        <Controller
          control={ control }
          defaultValue=''
          name='passwordConfirmation'
          render={ renderedConfirmationPasswordInputElement }
        />
        { passwordConfirmationError }
      </div>
      { phoneNumberInput }
      { endorsersCompanyInputs }
      <div className='ody-sign-up__form-control'>
        <ReCAPTCHA
          hl={ selectedLanguage }
          onChange={ handleRecaptchaChange }
          onErrored={ handleRecaptchaError }
          onExpired={ handleRecaptchaExpire }
          ref={ recaptchaRef }
          sitekey={ env.RECAPTCHA_KEY }
          size='normal'
        />
      </div>
      <div className='ody-sign-up__form-control'>
        <div className='ody-sign-up__form-control--link'>
          <input
            className={
              clsx({
                'error': isNotEmpty(errors.agreedWithTermsOfService),
              })
            }
            id='agreedWithTermsOfService'
            name='agreedWithTermsOfService'
            type='checkbox'
            { ...register('agreedWithTermsOfService') }
          />
          <label
            className='inline ody-sign-up__form-control--link-label'
            htmlFor='agreedWithTermsOfService'
          >
            { t('sign_up:agree_to') }
            &nbsp;
            <Link
              className='link inline'
              rel='noopener noreferrer'
              target='_blank'
              to='/terms-of-service'
            >
              { t('sign_up:terms_of_service_link_text') }
            </Link>
          </label>
        </div>
        { agreedWithTermsOfServiceError }
      </div>
      <div className='ody-sign-up__form-control'>
        <div className='ody-sign-up__form-control--link'>
          <input
            className={
              clsx({
                'error': isNotEmpty(errors.agreedWithPrivacyPolicy),
              })
            }
            id='agreedWithPrivacyPolicy'
            name='agreedWithPrivacyPolicy'
            type='checkbox'
            { ...register('agreedWithPrivacyPolicy') }
          />
          <label
            className='inline inline ody-sign-up__form-control--link-label'
            htmlFor='agreedWithPrivacyPolicy'
          >
            { t('sign_up:agree_to') }
            &nbsp;
            <Link
              className='link inline'
              rel='noopener noreferrer'
              target='_blank'
              to='/privacy-policy'
            >
              { t('sign_up:privacy_policy_link_text') }
            </Link>
          </label>
        </div>
        { agreedWithPrivacyPolicyError }
      </div>
      <button
        aria-label='Sign Up'
        className='btn btn-custom btn-blue btn-rounded-sm ody-sign-up__sign-up-button'
        disabled={ isNotEmpty(errors) || !recaptchaSubmitted }
        id='local-form-sign-up-button'
        name='local form sign up button'
        type='submit'
      >
        { t('sign_up:sign_up_button_label') }
      </button>
    </form>
  );
});

SignUpForm.displayName = 'SignUpForm';

SignUpForm.propTypes = {
  // The function (created) => void that handles whether the user has been created
  handleCreated: PropTypes.func.isRequired,
  // The role of the person that is signing up
  role: PropTypes.oneOf(roles).isRequired,
};

SignUpForm.defaultProps = {};

export default SignUpForm;
