/**
 * Greek translations for utilities/languages.
 *
 * @module languages/el/utilities/languages
 */
const languages = {
  aa: 'Αφάρ',
  ab: 'Αβχαζιανά',
  af: 'Αφρικάνικα',
  ak: 'Ακάν',
  am: 'Αμαρικά',
  an: 'Αραγωνικά',
  ar: 'Αραβικά',
  as: 'Ασαμικά',
  av: 'Αβάρ',
  ay: 'Αϊμαρακά',
  az: 'Αζερικά',
  ba: 'Μπασκίρ',
  be: 'Λευκορωσικά ',
  bg: 'Βουλγάρικα',
  bh: 'Μπιχάρι',
  bi: 'Μπισλαμά',
  bm: 'Μπαμπάρα',
  bn: 'Μπενγκάλι',
  bo: 'Θιβετανικά',
  br: 'Βρετονικά ',
  bs: 'Βοσνικά ',
  ca: 'Καταλανικά',
  ce: 'Τσετσένικα',
  ch: 'Τσαμόρο',
  co: 'Κορσικανικά',
  cr: 'Κριό',
  cs: 'Τσέχικα',
  cu: 'Παλαιά Εκκλησία Σλαβονική / Παλαιά Βουλγαρική',
  cv: 'Τσουβάς',
  cy: 'Ουαλικά',
  da: 'Δανικά',
  de: 'Γερμανικά',
  dv: 'Μαλδιβικά',
  dz: 'Τζόνγκα',
  ee: 'Ewe',
  el: 'Ελληνικά',
  en: 'Αγγλικά',
  eo: 'Εσπεράντο',
  es: 'Ισπανικά',
  et: ' Εσθονικά',
  eu: 'Βασκικά',
  fa: 'Περσικά ',
  ff: 'Πούλ',
  fi: 'Φινλανδικά',
  fj: ' Φιτζιανά',
  fo: 'Φεροϊκή',
  fr: 'Γαλλικά',
  fy: 'Δυτικά Φριζιανά',
  ga: 'Ιρλανδικά',
  gd: 'Σκωτσέζικα',
  gl: 'Γαλικιακά',
  gn: 'Γκουαρανί',
  gu: 'Γκουτζαρατικά ',
  gv: 'Μανξ ',
  ha: 'Χάουσα',
  he: 'Εβραϊκά',
  hi: 'Χιντού',
  ho: 'Χίρι Μότου',
  hr: 'Κροατικά ',
  ht: 'Αϊτινή κρεολή',
  hu: 'Ουγγρικά',
  hy: 'Αρμενικά ',
  hz: 'Χερέρο',
  ia: 'Ιντερλίνγκουα ',
  id: 'Ινδονήσια',
  ie: 'Ιντερλίνγκουε',
  ig: 'Ίγκμπο',
  ii: 'Σιτσουάν Γι',
  ik: 'Ινούπιακ',
  io: 'Ίντο',
  is: 'Ισλανδικά',
  it: 'Ιταλικά',
  iu: 'Φιλανδικά',
  ja: 'Ιαπωνικά',
  jv: 'Ιάβα',
  ka: 'Γεωργιανά',
  kg: 'Κονγκολέζικα',
  ki: 'Κικούγιου ',
  kj: 'Κουανιάμα',
  kk: 'Καζαχστανικά',
  kl: 'Γροιλανδικά',
  km: 'Καμποτζιανά',
  kn: 'Κανάντα',
  ko: 'Κορεάτικα',
  kr: 'Κανούρι',
  ks: 'Κασμιρικά',
  ku: 'Κουρδικά',
  kv: 'Κόμι',
  kw: 'Κορνουαλικά ',
  ky: 'Κιργιζικά',
  la: 'Λατινικά',
  lb: 'Λουξεμβουργιανά ',
  lg: 'Γκάντιαν',
  li: 'Λιμβουργιανά',
  ln: 'Λινγκάλα',
  lo: 'Λάτιαν',
  lt: 'Λιθουανικά',
  lu: 'Λούμπα-Κατάνγκα',
  lv: 'Λεττονικά',
  mg: 'Μαλγασικά',
  mh: 'Μαρσαλέζικα',
  mi: 'Μαορί',
  mk: 'Σλαβομακεδονικά ',
  ml: 'Μαλαγιαλαμικά',
  mn: 'Μογγολικά',
  mo: 'Μολδαβικά',
  mr: 'Μαραθικά',
  ms: 'Μαλαϊκά',
  mt: 'Μαλτέζικα',
  my: 'Βιρμανικά',
  na: 'Ναουρουάν',
  nb: 'Μπολγκάλι',
  nd: 'Βόρεια Νδεμπέλε',
  ne: 'Νεπαλικά',
  ng: 'Τσόνγκα',
  nl: 'Ολλανδικά',
  nn: 'Νορβηγικά Nynorsk',
  no: 'Νορβηγικά',
  nr: 'Νότια Νδεμπέλε',
  nv: 'Ναβάχο',
  ny: 'Τσιτσούα',
  oc: 'Οσιτανικά',
  oj: 'Οτζιμπουακά',
  om: 'Ορόμο',
  or: 'Ορίγια',
  os: 'Οσετικά',
  pa: 'Παντζαπικά',
  pi: 'Παλικά',
  pl: 'Πολωνικά',
  ps: 'Πάστο',
  pt: 'Πορτογαλικά',
  qu: 'Κέτσουα',
  rm: 'Ραιτιανά',
  rn: 'Κιρούντι',
  ro: 'Ρουμανικά ',
  ru: 'Ρωσικά ',
  rw: 'Ρουάντι',
  sa: 'Σανσκριτικά',
  sc: 'Σαρδηνιακά ',
  sd: 'Σίντι',
  se: 'Βόρεια λαπωνικά',
  sg: 'Σάντζο',
  sh: 'Σερβοκροατικά',
  si: 'Σιναλέζικα',
  sk: 'Σλοβακικά',
  sl: 'Σλοβενικά ',
  sm: 'Σαμοανικά ',
  sn: 'Σόνα',
  so: 'Σομαλικά',
  sq: 'Αλβανικά',
  sr: 'Σερβικά',
  ss: 'Σουάτι',
  st: 'Νότια Σόθο',
  su: 'Σουνδανικά',
  sv: 'Σουηδικά',
  sw: 'Σουαχίλι',
  ta: 'Ταμιλικά',
  te: 'Τελουγκουικά',
  tg: 'Ιρανικά Περσικά ',
  th: 'Ταϊλανδικά',
  ti: 'Τιγκρινιακά',
  tk: 'Τουρκμενικά',
  tl: 'Ταγκαλόγκ / Φιλιππινέζικα',
  tn: 'Τσουάνα',
  to: 'Τόνγκαν',
  tr: 'Τουρκικά',
  ts: 'Τσόνγκα',
  tt: 'Ταταρικά',
  tw: 'Tουί',
  ty: 'Ταϊτιανά',
  ug: 'Ουιγούρικά',
  uk: 'Ουκρανικά',
  ur: 'Ουρντού',
  uz: 'Ουζμπεκικά',
  ve: 'Βένδα',
  vi: 'Βιετναμέζικα',
  vo: 'Βολαπούκ',
  wa: 'Βαλλωνικά',
  wo: 'Γουόλοφ',
  xh: 'Κόσα ',
  yi: '(Γίντις) Γερμανοεβραϊκή διάλεκτος',
  yo: 'Γιορούμπα',
  za: 'Τζουάνγκ',
  zh: 'Κινέζικα',
  zu: 'Ζουλού',
};

export default languages;
