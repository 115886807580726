/**
 * See-example-profile button.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';

import exampleProfile from './images/example-profile.png';
import exampleProfileMobile from './images/mobile/example-profile.jpg';
import './see-example-profile-button.scss';

const SeeExampleProfileButton = memo((props) => {
  const { t } = useTranslation();

  const { className } = props;

  return (
    <div className='see-example-profile-button'>
      <Modal
        className='see-example-profile-modal'
        trigger={
          <button
            className={ clsx('btn', className) }
            id='see-example-profile'
            name='See example profile'
            type='button'
          >
            { t('see_example_profile_button:see_example_profile_button_label') }
          </button>
        }
      >
        <div className='see-example-profile'>
          <div className='image'>
            <img alt='Example Profile' className='hidden-xs hidden-sm' src={ exampleProfile } />
            <img alt='Example Profile' className='hidden-md hidden-lg' src={ exampleProfileMobile } />
          </div>
        </div>
      </Modal>
    </div>
  );
});

SeeExampleProfileButton.displayName = 'SeeExampleProfileButton';

SeeExampleProfileButton.propTypes = {
  // The class(es) to apply to the button.
  className: PropTypes.string,
};

SeeExampleProfileButton.defaultProps = {
};

export default SeeExampleProfileButton;
