/**
 * Basic Pricing plans.
 *
 * @module utilities/basic-pricing-plans
 */

/**
 * The Starter basic pricing plan.
 *
 * @constant {string}
 * @static
 */
export const STARTER = 'STARTER';

/**
 * The Advance pricing plan.
 *
 * @constant {string}
 * @static
 */
export const ADVANCE = 'ADVANCE';

/**
 * The Business pricing plan.
 *
 * @constant {string}
 * @static
 */
export const BUSINESS = 'BUSINESS';

export default [
  STARTER,
  ADVANCE,
  BUSINESS,
];
