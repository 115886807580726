/**
 * Carousel.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Carousel as BaseCarousel } from 'react-responsive-carousel';

import './carousel.scss';

const Carousel = memo((props) => {
  const { autoplay, children, className, infiniteLoop, stopOnHover } = props;

  return (
    <BaseCarousel
      autoPlay={ autoplay }
      className={ clsx('ody-carousel', className) }
      infiniteLoop={ infiniteLoop }
      showArrows={ false }
      showStatus={ false }
      showThumbs={ false }
      stopOnHover={ stopOnHover }
    >
      { children }
    </BaseCarousel>
  );
});

Carousel.propTypes = {
  // Whether to be an autoplay
  autoplay: PropTypes.bool,
  // The content of the carousel.
  children: PropTypes.node.isRequired,
  // The class(es) to apply to the carousel.
  className: PropTypes.string,
  // Whether the carousel to be on infinite loop
  infiniteLoop: PropTypes.bool,
  // Whether the carouse to stop on hover
  stopOnHover: PropTypes.bool,
};

Carousel.defaultProps = {
  autoplay: false,
  infiniteLoop: false,
  stopOnHover: false,
};

export default Carousel;
