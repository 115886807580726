import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AverageBubbles from 'components/common/average-bubbles/average-bubbles';
import Confirm from 'components/common/confirm/confirm';
import Modal from 'components/common/modal/modal';
import EndorsementInformation from 'components/endorsement/endorsement-information/endorsement-information';
import { getTranslationKey } from 'utilities/chisels';
import communicationCooperationSkillsLevels from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import EndorsementFeedbackCard from './endorsement-feedback-card/endorsement-feedback-card';

import './profile-endorser-feedback.scss';

const ProfileEndorserFeedback = memo((props) => {
  const { onRequestEndorsement, profile, isCurrentSignedInUserProfile, allHashtags } = props;

  const { t } = useTranslation();

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  // The endorsement index.
  const [ endorsementIndex, setEndorsementIndex ] = useState(false);

  // The average score
  const averageScore = useMemo(() => {
    return profile?.endorserFeedback?.averageScore;
  }, [ profile?.endorserFeedback?.averageScore ]);

  // The endorsements
  const endorsements = useMemo(() => {
    return profile?.endorserFeedback?.endorsements;
  }, [ profile?.endorserFeedback?.endorsements ]);

  // Toggles the editor open
  const toggleEditor = useCallback((open) => {
    return (_event) => {
      setEditorOpen(open);
    };
  }, []);

  // The request endorsement button
  const requestEndorsementButton = useMemo(() => {
    if (!isCurrentSignedInUserProfile) {
      return null;
    }
    return (
      <Confirm
        className='profile-endorser-feedback__request-endorsement-button'
        onConfirm={ onRequestEndorsement }
        text={ t('job_seekers:profile_endorsers_feedback.request_endorsement_confirm') }
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ !profile?.allowedToRequestEndorsement }
          >
            { t('job_seekers:profile_endorsers_feedback.request_endorsement_button_title') }
          </button>
        }
      />
    );
  }, [ isCurrentSignedInUserProfile, onRequestEndorsement, profile?.allowedToRequestEndorsement, t ]);

  // The rendered averages
  const renderedAverages = useMemo(() => {
    if (!averageScore) {
      return null;
    }
    return Object.keys(averageScore)?.map((averageScoreKey) => {
      return (
        <div
          className='profile-endorser-feedback__average-score-container'
          key={ averageScoreKey }
        >
          <AverageBubbles
            average={ averageScore?.[averageScoreKey] }
          />
          <p className='txt txt-md txt-bold'>
            { t(`job_seekers:profile_endorsers_feedback.average.${ getTranslationKey(averageScoreKey) }_title`) }
          </p>
        </div>
      );
    });
  }, [ averageScore, t ]);

  // The rendered average section
  const renderedAverageSection = useMemo(() => {
    if (!averageScore) {
      return null;
    }
    return (
      <div className='profile-endorser-feedback__average-section'>
        { renderedAverages }
      </div>
    );
  }, [ averageScore, renderedAverages ]);

  // The function to be invoked when a card is clicked
  const onEndorsementFeedbackCardClick = useCallback((endorsementIndex) => {
    setEndorsementIndex(endorsementIndex);
    setEditorOpen(true);
  }, []);

  // The rendered endorsements cards
  const renderedEndorsementCards = useMemo(() => {
    if (!endorsements?.length) {
      return null;
    }
    return endorsements?.map((endorsement, index) => {
      return (
        <EndorsementFeedbackCard
          allHashtags={ allHashtags }
          endorsement={ endorsement }
          endorsementIndex={ index }
          key={ endorsement.id }
          onEndorsementFeedbackCardClick={ onEndorsementFeedbackCardClick }
        />
      );
    });
  }, [ allHashtags, endorsements, onEndorsementFeedbackCardClick ]);

  // The rendered endorsement feedback cards
  const renderedEndorsementFeedbackCards = useMemo(() => {
    if (!endorsements?.length) {
      return null;
    }
    return (
      <div className='profile-endorser-feedback__endorsements-section'>
        { renderedEndorsementCards }
      </div>
    );
  }, [ endorsements?.length, renderedEndorsementCards ]);

  return (
    <div className='profile-endorser-feedback'>
      <div className='profile-endorser-feedback__title-container'>
        <h2 className='hdg hdg-md'>
          { t('job_seekers:profile_endorsers_feedback.title') }
        </h2>
        { requestEndorsementButton }
      </div>
      { renderedAverageSection }
      { renderedEndorsementFeedbackCards }
      <Modal
        className='endorsement-information-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <EndorsementInformation
          allHashtags={ allHashtags }
          endorsement={ endorsements?.[endorsementIndex] }
        />
      </Modal>
    </div>
  );
});

ProfileEndorserFeedback.displayName = 'ProfileEndorserFeedback';

ProfileEndorserFeedback.propTypes = {
  // All the hashtags available
  allHashtags: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of hashtag
      el: PropTypes.string,
      // english translation of hashtag
      en: PropTypes.string,
      // the id of the hashtag
      id: PropTypes.string,
    })
  ),
  // Whether the profile is the current signed in user's
  isCurrentSignedInUserProfile: PropTypes.bool,
  // function that is invoked when request endorsement button is clicked
  onRequestEndorsement: PropTypes.func,
  // The job seeker profile
  profile: PropTypes.shape({
    // If the user is allowed to request an endorsement
    allowedToRequestEndorsement: PropTypes.bool,
    // The endorser feedback
    endorserFeedback: PropTypes.shape({
      // The average score
      averageScore: PropTypes.shape({
        // The communication and cooperation skills level score
        communicationCooperationSkillsLevelAverageScore: PropTypes.number,
        // The critical thinking level average score
        criticalThinkingLevelAverageScore: PropTypes.number,
        // The motivation level average score
        motivationLevelAverageScore: PropTypes.number,
        // The professional characteristics average score
        professionalCharacteristicsAverageScore: PropTypes.number,
      }),
      // The endorsements
      endorsements: PropTypes.arrayOf(
        // The endorsement
        PropTypes.shape({
          // The access level
          accessLevel: PropTypes.string,
          // The communication and cooperation skills level score
          communicationCooperationScore: PropTypes.number,
          // The communication and cooperation skills level
          communicationCooperationSkillsLevel: PropTypes.oneOf(communicationCooperationSkillsLevels),
          // The critical thinking level
          criticalThinkingLevel: PropTypes.oneOf(criticalThinkingLevels),
          // The critical thinking score
          criticalThinkingScore: PropTypes.number,
          // The employer feedback
          employerFeedback: PropTypes.string,
          // The endorser id
          endorser: PropTypes.string,
          // The endorser profile
          endorserProfile: PropTypes.shape({
            // The company name
            companyName: PropTypes.string,
            // The company position
            companyPosition: PropTypes.string,
            // The first name
            firstName: PropTypes.string,
            // the last name
            lastName: PropTypes.string,
          }),
          // The hashtags UUID array
          hashtags: PropTypes.arrayOf(PropTypes.string),
          // The job seeker feedbacks array
          jobSeekerFeedbacks: PropTypes.arrayOf(PropTypes.oneOf(jobSeekerFeedbackLevels)),
          // The motivation level
          motivationLevel: PropTypes.oneOf(motivationalLevels),
          // The motivation score
          motivationScore: PropTypes.number,
          // The professional characteristics array
          professionalCharacteristics: PropTypes.arrayOf(PropTypes.oneOf(professionalCharacteristicLevels)),
          // The profesionalism score
          professionalismScore: PropTypes.number,
          // The request id
          requestId: PropTypes.string,
          // The testimonial
          testimonial: PropTypes.string,
        })
      ),
    }),
  }),
};

ProfileEndorserFeedback.defaultProps = {};

export default ProfileEndorserFeedback;
