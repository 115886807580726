/**
 * Greek translations for the job-seekers.
 *
 * @module languages/el/job-seekers
 */
import downloadProfileButton from './download-profile-button';
import learnMore from './learn-more';
import profileCompetencies from './profile-competencies';
import profileEndorsersFeedback from './profile-endorsers-feedback';
import profileExperiences from './profile-experiences';
import profileLanguages from './profile-languages';
import profileQualifications from './profile-qualifications';
import profileResume from './profile-resume';
import shareProfileButton from './share-profile-button';
import steps from './steps';
import takeAssessment from './take-assessment';

const jobSeekers = {
  download_profile_button: downloadProfileButton,
  learn_more: learnMore,
  profile_competencies: profileCompetencies,
  profile_endorsers_feedback: profileEndorsersFeedback,
  profile_experiences: profileExperiences,
  profile_languages: profileLanguages,
  profile_qualifications: profileQualifications,
  profile_resume: profileResume,
  share_profile_button: shareProfileButton,
  steps,
  take_assessment: takeAssessment,
};

export default jobSeekers;
