/* eslint-disable */
import React, { memo } from 'react';

const ArrowCardIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.825227 36.1339C0.681083 38.2934 0.845826 40.193 1.15471 42.3126C1.25767 42.9924 2.01957 43.3723 2.63733 43.4123C9.80335 43.9322 17.0723 43.7723 24.2589 43.7123C28.8509 43.6723 34.4519 44.3521 38.9616 43.3324C39.4558 43.2324 39.95 42.7725 40.0324 42.2926C40.3825 40.5729 40.8766 38.9533 40.7531 37.1937C40.6295 35.2741 37.5407 35.2541 37.6643 37.1937C37.7261 38.3734 37.5613 39.5332 37.2113 40.6729C38.3026 40.453 38.4674 40.393 37.6849 40.453C36.923 40.8129 35.1109 40.533 34.2872 40.5529C30.663 40.6529 27.0594 40.6729 23.4352 40.7129C19.3168 40.7529 15.1984 40.7529 11.0595 40.6929C9.55624 40.6729 7.02343 41.0728 5.60259 40.5729C4.90246 40.5529 4.20229 40.513 3.50216 40.473C3.70808 39.0333 3.85225 37.5936 3.91403 36.1339C4.03758 34.2143 0.948779 34.2143 0.825227 36.1339Z'
        fill='#161616'
      />
      <path
        d='M23.1468 32.4147C22.8585 23.1567 22.2407 13.9187 21.7671 4.6607C21.6641 2.74111 18.5753 2.72112 18.6783 4.6607C19.1519 13.8987 19.7697 23.1567 20.058 32.4147C20.1198 34.3343 23.2086 34.3343 23.1468 32.4147Z'
        fill='#161616'
      />
      <path
        d='M12.7889 14.8185C15.3835 10.4394 18.4929 6.36029 21.5405 2.28117C20.7374 2.38115 19.9138 2.48112 19.1107 2.5811C21.4993 5.00058 23.435 7.72 25.2059 10.5594C25.8854 11.6192 26.5444 13.3388 27.7181 13.9986C29.4272 14.9784 30.9922 12.379 29.2831 11.3992C28.7065 11.0593 27.1621 7.87996 26.5237 6.92017C24.9793 4.62066 23.2702 2.42114 21.2934 0.441563C20.5521 -0.318273 19.4401 -0.0183373 18.8635 0.741499C15.7953 4.82062 12.7065 8.89974 10.1119 13.2788C9.12353 14.9784 11.8005 16.4981 12.7889 14.8185Z'
        fill='#161616'
      />
    </svg>
  );
});

ArrowCardIcon.displayName = 'ArrowCardIcon';

ArrowCardIcon.propTypes = {};

ArrowCardIcon.defaultProps = {};

export default ArrowCardIcon;
