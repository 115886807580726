/**
 * Search criteria types for company.
 *
 * @module utilities/endorsers/sort-keys
 */

/**
 * Based on when their profile was last modified.
 *
 * @constant {string}
 * @static
 */
export const LAST_MODIFIED_AT = 'LAST_MODIFIED_AT';

/**
 * Based on their last name.
 *
 * @constant {string}
 * @static
 */
export const LAST_NAME = 'LAST_NAME';

/**
 * Based on their total endorsements.
 *
 * @constant {string}
 * @static
 */
export const TOTAL_ENDORSEMENTS = 'TOTAL_ENDORSEMENTS';

/**
 * The default sort key.
 *
 * @constant {string}
 * @default
 * @static
 */
export const DEFAULT_SORT_KEY = LAST_MODIFIED_AT;

export default [
  LAST_MODIFIED_AT,
  LAST_NAME,
  TOTAL_ENDORSEMENTS,
];
