import React, { memo, useMemo } from 'react';

import { useLg, useMd } from 'hooks/media-queries';
import odysseaMobile from 'images/mobile/odyssea-logo-mobile.png';
import odyssea from 'images/odyssea-logo.png';

const OdysseaLogo = memo((_props) => {
  const lg = useLg();

  const md = useMd();

  // Large Screens
  const largeScreens = useMemo(() => {
    return lg || md;
  }, [ lg, md ]);

  // Whether to render the mobile or the desktop image
  const odysseaLogoImageSource = useMemo(() => {
    if (largeScreens) {
      return odyssea;
    }
    return odysseaMobile;
  }, [ largeScreens ]);

  return (
    <img
      alt='Odyssea'
      src={ odysseaLogoImageSource }
    />
  );
});

OdysseaLogo.displayName = 'OdysseaLogo';

OdysseaLogo.propTypes = {

};

OdysseaLogo.defaultProps = {

};

export default OdysseaLogo;
