/**
 * Terms of Service page.
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Markdown from 'components/common/markdown/markdown';

import './terms-of-service.scss';

const TermsOfService = (_props) => {
  const { i18n } = useTranslation();

  // The content.
  const [ content, setContent ] = useState('');

  // The selected language.
  const selectedLanguage = i18n.language;

  // Load the content for the selected language.
  useEffect(() => {
    import(`languages/${ selectedLanguage }/terms-of-service.md`).then((result) => {
      return fetch(result.default);
    }).then((result) => {
      return result.text();
    }).then((result) => {
      setContent(result);
    });
  }, [ selectedLanguage ]);

  return (
    <div className='ody-terms-of-service dark'>
      <Markdown>
        { content }
      </Markdown>
    </div>
  );
};

TermsOfService.propTypes = {
};

TermsOfService.defaultProps = {
};

export default TermsOfService;
