/**
 * Resource method to sign up as a company.
 *
 * @module resources/companies/event-profile-interaction
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/event-profile-interaction~Params
 * @type {object}
 * @property {string} jobSeekerId - The job seeker id.
 * @property {string} companyId - the company id.
 * @property {('VIEWED' | 'INTERVIEWED' | 'HIRED')} interactionEventType - The interaction event type enum.
 */

/**
 * Interacts a company with a job seeker's profile
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/event-profile-interaction~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const interact = (params) => {
  return axios.request({
    data: params,
    method: 'post',
    url: '/companies/interaction',
  }).then((response) => {
    return response.data;
  });
};

export default interact;
