/**
 * Greek translations for common/profile-background-image.
 *
 * @module languages/el/common/profile-background-image
 */
const profileBackgroundImage = {
  editor_title: 'Εικόνα φόντου',
  save_button_label: 'Αποθήκευση',
};

export default profileBackgroundImage;
