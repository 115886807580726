import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import PersonIcon from 'components/common/icons/person-icon/person-icon';
import ShieldCardIcon from 'components/common/icons/shield-card-icon/shield-card-icon';
import env from 'config/env';
import useElementOnScreen from 'hooks/use-element-on-screen';
import { isEmpty } from 'utilities/chisels';

import './learn-more-our-endorsers-card.scss';
import CircularImage from '../../../../common/circular-image/circular-image';

const LearnMoreOurEndorsersCard = memo((props) => {
  const { firstName, lastName, companyPosition, companyName, profilePicture, profileId } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // the displayed name
  const displayedName = useMemo(() => {
    return `${ firstName }, ${ lastName.split('')[0] }.`;
  }, [ firstName, lastName ]);

  // the rendered profile picture
  const renderedProfilePicture = useMemo(() => {
    if (isEmpty(profilePicture)) {
      return (
        <div className='learn-more-our-endorsers-card__icon-container'>
          <PersonIcon />
        </div>
      );
    }
    return (
      <div className='learn-more-our-endorsers-card__image-container'>
        <CircularImage
          alt='Profile Picture'
          imageSource={ `${ env.CORE_BASE_URL }/endorsers/${ profileId }/profile/picture?id=${ profilePicture?.id }` }
        />
      </div>
    );
  }, [ profileId, profilePicture ]);

  return (
    <div
      className={
        clsx('learn-more-our-endorsers-card', {
          'learn-more-our-endorsers-card__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      { renderedProfilePicture }
      <div className='learn-more-our-endorsers-card__name-container'>
        <p className='txt txt-lg txt-bold learn-more-our-endorsers-card__name'>
          { displayedName }
        </p>
        <div className='learn-more-our-endorsers-card__name-icon-container'>
          <ShieldCardIcon />
        </div>
      </div>
      <div className='learn-more-our-endorsers-card__info-container'>
        <p className='txt txt-md learn-more-our-endorsers-card__info'>
          { companyPosition }
        </p>
        <p className='txt txt-md learn-more-our-endorsers-card__info'>
          { companyName }
        </p>
      </div>
    </div>
  );
});

LearnMoreOurEndorsersCard.displayName = 'LearnMoreOurEndorsersCard';

LearnMoreOurEndorsersCard.propTypes = {
  // The company name of the endorser
  companyName: PropTypes.string,
  // The job position of the endorser
  companyPosition: PropTypes.string,
  // The first name of the endorser
  firstName: PropTypes.string.isRequired,
  // The last name of the endorser
  lastName: PropTypes.string.isRequired,
  // the profile id of the endorser
  profileId: PropTypes.string,
  // The profile picture of the job seeker.
  profilePicture: PropTypes.shape({
    // The ID of the picture.
    id: PropTypes.string.isRequired,
    // The MIME type of the picture.
    type: PropTypes.string.isRequired,
  }),
};

LearnMoreOurEndorsersCard.defaultProps = {};

export default LearnMoreOurEndorsersCard;
