/**
 * Constants.
 *
 * @module config/constants
 */

/**
 * The page size.
 *
 * @constant {number}
 * @default
 * @static
 */
const PAGE_SIZE = 10;

export default {
  PAGE_SIZE,
};
