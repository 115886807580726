/**
 * English translations for common/profile-details-section.
 *
 * @module languages/en/common/profile-details-section
 */
const profileDetailsSection = {
  born_on_invalid_error: 'Enter a valid date.',
  born_on_label: 'Date of birth',
  born_on_placeholder: 'Date of birth',
  citizen: 'citizen',
  citizenship_label: 'Citizenship',
  citizenship_placeholder: 'Select your citizenship',
  city_placeholder: 'Select your city',
  companies_endorsers_title: 'Details',
  company_website_label: 'Company\'s website',
  day_placeholder: 'Day',
  district_label: 'District',
  district_placeholder: 'District',
  first_name_label: 'First name',
  first_name_placeholder: 'First name',
  first_name_required_error: 'First name is required.',
  gender_label: 'Gender',
  gender_placeholder: 'Select your gender',
  hires_per_year_label: 'Hires per year',
  hires_per_year_placeholder: 'Hires per year',
  job_seekers_title: 'Personal',
  last_name_label: 'Last name',
  last_name_placeholder: 'Last name',
  last_name_required_error: 'Last name is required.',
  location_label: 'Location',
  location_placeholder: 'Location',
  month_placeholder: 'Month',
  number_of_employees_label: 'Number of employees',
  number_of_employees_placeholder: 'Number of employees',
  origin_label: 'Country of origin',
  origin_placeholder: 'Select your country of origin',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Phone number must be a number with the country prefix (e.g +30) and not exceed 10 numbers',
  phone_number_label: 'Phone',
  phone_number_placeholder: 'Phone number',
  phone_number_required_error: 'Phone number is required.',
  website_invalid_error: 'Enter a valid URL e.g https://www.example.com or http://www.example.com.',
  website_label: 'Website',
  website_placeholder: 'https://www.example.com',
  year_placeholder: 'Year',
  years_old_one: '{{count}} year old',
  years_old_other: '{{count}} years old',
};

export default profileDetailsSection;
