/**
 * English translations for utilities/citizenships.
 *
 * @module languages/en/utilities/citizenships
 */
const citizenships = {
  ABW: 'Aruban',
  AFG: 'Afghan',
  AGO: 'Angolan',
  AIA: 'Anguillan',
  ALA: 'Åland Island',
  ALB: 'Albanian',
  AND: 'Andorran',
  ARE: 'Emirati, Emirian, Emiri',
  ARG: 'Argentine',
  ARM: 'Armenian',
  ASM: 'American Samoan',
  ATA: 'Antarctic',
  ATF: 'French Southern Territories',
  ATG: 'Antiguan or Barbudan',
  AUS: 'Australian',
  AUT: 'Austrian',
  AZE: 'Azerbaijani, Azeri',
  BDI: 'Burundian',
  BEL: 'Belgian',
  BEN: 'Beninese, Beninois',
  BES: 'Bonaire',
  BFA: 'Burkinabé',
  BGD: 'Bangladeshi',
  BGR: 'Bulgarian',
  BHR: 'Bahraini',
  BHS: 'Bahamian',
  BIH: 'Bosnian or Herzegovinian',
  BLM: 'Barthélemois',
  BLR: 'Belarusian',
  BLZ: 'Belizean',
  BMU: 'Bermudian, Bermudan',
  BOL: 'Bolivian',
  BRA: 'Brazilian',
  BRB: 'Barbadian',
  BRN: 'Bruneian',
  BTN: 'Bhutanese',
  BVT: 'Bouvet Island',
  BWA: 'Motswana, Botswanan',
  CAF: 'Central African',
  CAN: 'Canadian',
  CCK: 'Cocos Island',
  CHE: 'Swiss',
  CHL: 'Chilean',
  CHN: 'Chinese',
  CIV: 'Ivorian',
  CMR: 'Cameroonian',
  COD: 'Congolese',
  COG: 'Congolese',
  COK: 'Cook Island',
  COL: 'Colombian',
  COM: 'Comoran, Comorian',
  CPV: 'Cabo Verdean',
  CRI: 'Costa Rican',
  CUB: 'Cuban',
  CUW: 'Curaçaoan',
  CXR: 'Christmas Island',
  CYM: 'Caymanian',
  CYP: 'Cypriot',
  CZE: 'Czech',
  DEU: 'German',
  DJI: 'Djiboutian',
  DMA: 'Dominican',
  DNK: 'Danish',
  DOM: 'Dominican',
  DZA: 'Algerian',
  ECU: 'Ecuadorian',
  EGY: 'Egyptian',
  ERI: 'Eritrean',
  ESH: 'Sahrawi, Sahrawian, Sahraouian',
  ESP: 'Spanish',
  EST: 'Estonian',
  ETH: 'Ethiopian',
  FIN: 'Finnish',
  FJI: 'Fijian',
  FLK: 'Falkland Island',
  FRA: 'French',
  FRO: 'Faroese',
  FSM: 'Micronesian',
  GAB: 'Gabonese',
  GBR: 'British, UK',
  GEO: 'Georgian',
  GGY: 'Channel Island',
  GHA: 'Ghanaian',
  GIB: 'Gibraltar',
  GIN: 'Guinean',
  GLP: 'Guadeloupe',
  GMB: 'Gambian',
  GNB: 'Bissau-Guinean',
  GNQ: 'Equatorial Guinean, Equatoguinean',
  GRC: 'Greek',
  GRD: 'Grenadian',
  GRL: 'Greenlandic',
  GTM: 'Guatemalan',
  GUF: 'French Guianese',
  GUM: 'Guamanian, Guambat',
  GUY: 'Guyanese',
  HKG: 'Hong Kong, Hong Kongese',
  HMD: 'Heard Island or McDonald Islands',
  HND: 'Honduran',
  HRV: 'Croatian',
  HTI: 'Haitian',
  HUN: 'Hungarian, Magyar',
  IDN: 'Indonesian',
  IMN: 'Manx',
  IND: 'Indian',
  IOT: 'BIOT',
  IRL: 'Irish',
  IRN: 'Iranian, Persian',
  IRQ: 'Iraqi',
  ISL: 'Icelandic',
  ISR: 'Israeli',
  ITA: 'Italian',
  JAM: 'Jamaican',
  JEY: 'Channel Island',
  JOR: 'Jordanian',
  JPN: 'Japanese',
  KAZ: 'Kazakhstani, Kazakh',
  KEN: 'Kenyan',
  KGZ: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  KHM: 'Cambodian',
  KIR: 'I-Kiribati',
  KNA: 'Kittitian or Nevisian',
  KOR: 'South Korean',
  KWT: 'Kuwaiti',
  LAO: 'Lao, Laotian',
  LBN: 'Lebanese',
  LBR: 'Liberian',
  LBY: 'Libyan',
  LCA: 'Saint Lucian',
  LIE: 'Liechtenstein',
  LKA: 'Sri Lankan',
  LSO: 'Basotho',
  LTU: 'Lithuanian',
  LUX: 'Luxembourg, Luxembourgish',
  LVA: 'Latvian',
  MAC: 'Macanese, Chinese',
  MAF: 'Saint-Martinoise',
  MAR: 'Moroccan',
  MCO: 'Monégasque, Monacan',
  MDA: 'Moldovan',
  MDG: 'Malagasy',
  MDV: 'Maldivian',
  MEX: 'Mexican',
  MHL: 'Marshallese',
  MKD: 'Macedonian',
  MLI: 'Malian, Malinese',
  MLT: 'Maltese',
  MMR: 'Burmese',
  MNE: 'Montenegrin',
  MNG: 'Mongolian',
  MNP: 'Northern Marianan',
  MOZ: 'Mozambican',
  MRT: 'Mauritanian',
  MSR: 'Montserratian',
  MTQ: 'Martiniquais, Martinican',
  MUS: 'Mauritian',
  MWI: 'Malawian',
  MYS: 'Malaysian',
  MYT: 'Mahoran',
  NAM: 'Namibian',
  NCL: 'New Caledonian',
  NER: 'Nigerien',
  NFK: 'Norfolk Island',
  NGA: 'Nigerian',
  NIC: 'Nicaraguan',
  NIU: 'Niuean',
  NLD: 'Dutch, Netherlandic',
  NOR: 'Norwegian',
  NPL: 'Nepali, Nepalese',
  NRU: 'Nauruan',
  NZL: 'New Zealand, NZ',
  OMN: 'Omani',
  PAK: 'Pakistani',
  PAN: 'Panamanian',
  PCN: 'Pitcairn Island',
  PER: 'Peruvian',
  PHL: 'Philippine, Filipino',
  PLW: 'Palauan',
  PNG: 'Papua New Guinean, Papuan',
  POL: 'Polish',
  PRI: 'Puerto Rican',
  PRK: 'North Korean',
  PRT: 'Portuguese',
  PRY: 'Paraguayan',
  PSE: 'Palestinian',
  PYF: 'French Polynesian',
  QAT: 'Qatari',
  REU: 'Réunionese, Réunionnais',
  ROU: 'Romanian',
  RUS: 'Russian',
  RWA: 'Rwandan',
  SAU: 'Saudi, Saudi Arabian',
  SDN: 'Sudanese',
  SEN: 'Senegalese',
  SGP: 'Singaporean',
  SGS: 'South Georgia or South Sandwich Islands',
  SHN: 'Saint Helenian',
  SJM: 'Svalbard',
  SLB: 'Solomon Island',
  SLE: 'Sierra Leonean',
  SLV: 'Salvadoran',
  SMR: 'Sammarinese',
  SOM: 'Somali, Somalian',
  SPM: 'Saint-Pierrais or Miquelonnais',
  SRB: 'Serbian',
  SSD: 'South Sudanese',
  STP: 'São Toméan',
  SUR: 'Surinamese',
  SVK: 'Slovak',
  SVN: 'Slovenian, Slovene',
  SWE: 'Swedish',
  SWZ: 'Swazi',
  SXM: 'Sint Maarten',
  SYC: 'Seychellois',
  SYR: 'Syrian',
  TCA: 'Turks and Caicos Island',
  TCD: 'Chadian',
  TGO: 'Togolese',
  THA: 'Thai',
  TJK: 'Tajikistani',
  TKL: 'Tokelauan',
  TKM: 'Turkmen',
  TLS: 'Timorese',
  TON: 'Tongan',
  TTO: 'Trinidadian or Tobagonian',
  TUN: 'Tunisian',
  TUR: 'Turkish',
  TUV: 'Tuvaluan',
  TWN: 'Chinese, Taiwanese',
  TZA: 'Tanzanian',
  UGA: 'Ugandan',
  UKR: 'Ukrainian',
  UMI: 'American',
  URY: 'Uruguayan',
  USA: 'American',
  UZB: 'Uzbekistani, Uzbek',
  VAT: 'Vatican',
  VCT: 'Saint Vincentian, Vincentian',
  VEN: 'Venezuelan',
  VGB: 'British Virgin Island',
  VIR: 'U.S. Virgin Island',
  VNM: 'Vietnamese',
  VUT: 'Ni-Vanuatu, Vanuatuan',
  WLF: 'Wallis and Futuna, Wallisian or Futunan',
  WSM: 'Samoan',
  YEM: 'Yemeni',
  ZAF: 'South African',
  ZMB: 'Zambian',
  ZWE: 'Zimbabwean',
};

export default citizenships;
