/**
 * Resource method to get job seeker public profiles.
 *
 * @module resources/job-seekers/get-public-profile
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/get-public-profile~Params
 * @type {object}
 * @property {string} id - The ID of the job seeker.
 */

/**
 * Result.
 *
 * @typedef {module:types/job-seekers/profiles~Profile} Result
 */

/**
 * Gets the public profile of a job seeker based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/get-public-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/get-public-profile~Result>} - The promise.
 * @static
 */
const getPublicProfile = (params) => {
  return axios.request({
    method: 'get',
    url: `/job-seekers/${ params.id }/profile/public`,
  }).then((response) => {
    return response.data;
  });
};

export default getPublicProfile;
