import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import roles from 'utilities/auth/roles';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileJobSectorsInterestedEditor
  from './profile-job-sectors-interested-editor/profile-job-sectors-interested-editor';

const ProfileJobSectorsInterestedIn = memo((props) => {
  const { allJobSectors, profile, onChange, canEdit } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The rendered found job sector from the list of all
  const renderFoundJobSector = useCallback((profileJobSectorId) => {
    return allJobSectors.find((jobSector) => {
      return jobSector.id === profileJobSectorId;
    })?.[selectedLanguage];
  }, [ allJobSectors, selectedLanguage ]);

  // The rendered job sectors
  const renderedJobSectors = useMemo(() => {
    return profile.jobSectors?.map((jobSectorId) => {
      return (
        <ProfileInfoRow
          key={ jobSectorId }
          textDisplayed={ renderFoundJobSector(jobSectorId) }
        />
      );
    });
  }, [ profile.jobSectors, renderFoundJobSector ]);

  // function that handles the on change of job sectors
  const onJobSectorsInterestedChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    // We can use either Job seeker parts or company parts because JOB_SECTORS are exactly the same
    onChange(paramsToBeUpdated, JobSeekerParts.JOB_SECTORS, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileJobSectorsInterestedEditor
          allJobSectors={ allJobSectors }
          onCancel={ toggleEditor(false) }
          onSave={ onJobSectorsInterestedChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [
    allJobSectors,
    canEdit,
    editorOpen,
    onJobSectorsInterestedChange,
    profile,
    toggleEditor,
  ]);

  return (
    <div className='profile-job-sectors-interested-in'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('common:profile_job_sectors_interested_in.title') }
      />
      <div className='profile-job-sectors-interested-in__fields'>
        { renderedJobSectors }
      </div>
      { editorModal }
    </div>
  );
});

ProfileJobSectorsInterestedIn.displayName = 'ProfileJobSectorsInterestedIn';

ProfileJobSectorsInterestedIn.propTypes = {
  // The all job sectors
  allJobSectors: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the job sector
      el: PropTypes.string,
      // The english translation of the job sector
      en: PropTypes.string,
      // The id of the job sector
      id: PropTypes.string,
    })
  ).isRequired,
  // Whether the user can edit the details section
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func.isRequired,
  // The profile of the user.
  profile: PropTypes.shape({
    // The IDs of the job sectors that the user is interested in.
    jobSectors: PropTypes.arrayOf(PropTypes.string),
  }),
  // the role of the profile based on route
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileJobSectorsInterestedIn.defaultProps = {};

export default ProfileJobSectorsInterestedIn;
