/**
 * Greek translations for the administrators.
 *
 * @module languages/el/administrators
 */
import dashboard from './dashboard';

const administrators = {
  dashboard,
};

export default administrators;
