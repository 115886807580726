import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import EconomicGrowth from './images/economic-growth.png';
import GenderEquality from './images/gender-equality.png';
import NoPoverty from './images/no-poverty.png';
import Partnerships from './images/partnerships.png';
import QualityEducation from './images/quality-education.png';
import ReducedInequalities from './images/reduced-inequalities.png';
import StrongInstitutions from './images/strong-institutions.png';
import WellBeing from './images/well-being.png';
import ZeroHunger from './images/zero-hunger.png';

import './social-responsibility-sdg-section.scss';

const SocialResponsibilitySdgSection = memo((props) => {
  const { title, linkLabel } = props;

  // The sdg's images array
  const sdgsArray = useMemo(() => {
    return [
      NoPoverty,
      ZeroHunger,
      WellBeing,
      QualityEducation,
      GenderEquality,
      EconomicGrowth,
      ReducedInequalities,
      StrongInstitutions,
      Partnerships,
    ];
  }, []);

  // The rendered sgd's
  const renderedSdgs = useMemo(() => {
    return sdgsArray.map((sdgImage) => {
      return (
        <div
          className='social-responsibility-sdg-section__sdg'
          key={ sdgImage }
        >
          <img
            alt='sdg'
            className='social-responsibility-sdg-section__image'
            src={ sdgImage }
          />
        </div>
      );
    });
  }, [ sdgsArray ]);

  return (
    <div className='social-responsibility-sdg-section'>
      <h2 className='hdg hdg-xxl social-responsibility-sdg-section__title'>
        { title }
      </h2>
      <div className='social-responsibility-sdg-section__sdgs-container'>
        { renderedSdgs }
        <a
          className='social-responsibility-sdg-section__webpage-link'
          href='https://odyssea.com/'
        >
          { linkLabel }
        </a>
      </div>
    </div>
  );
});

SocialResponsibilitySdgSection.displayName = 'SocialResponsibilitySdgSection';

SocialResponsibilitySdgSection.propTypes = {
  // The website link label
  linkLabel: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

SocialResponsibilitySdgSection.defaultProps = {};

export default SocialResponsibilitySdgSection;
