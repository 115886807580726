/**
 * Duck for endorsers.
 *
 * @module ducks/endorsers
 */
import filterTypes from 'utilities/endorsers/filter-types';

/**
 * The type of actions to describe that a search was completed (successfully or not).
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_SEARCH_COMPLETED = 'ENDORSERS_SEARCH_COMPLETED';

/**
 * The type of actions to describe that a search was made.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_SEARCH_MADE = 'ENDORSERS_SEARCH_MADE';

/**
 * The type of actions to describe that a search was refined.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_SEARCH_REFINED = 'COMPANIES_SEARCH_REFINED';

/**
 * The type of actions to describe that the endorsers statistics was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_STATISTICS_SET = 'ENDORSERS_STATISTICS_SET';

/**
 * The type of actions to describe that the created endorsement requests are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_CREATED_ENDORSEMENT_REQUESTS_SET = 'ENDORSERS_CREATED_ENDORSEMENT_REQUESTS_SET';

/**
 * The type of actions to describe that the claimed endorsement requests are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_CLAIMED_ENDORSEMENT_REQUESTS_SET = 'ENDORSERS_CLAIMED_ENDORSEMENT_REQUESTS_SET';

/**
 * The type of actions to describe that the endorsers state object is reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_RESET = 'ENDORSERS_RESET';

/**
 * The type of actions to describe that an endorsement has been claimed
 *
 * @constant {string}
 * @default
 * @static
 */
const CLAIMED_ENDORSEMENT_REQUEST = 'CLAIMED_ENDORSEMENT_REQUEST';

/**
 * The type of actions to describe that an endorsement has been revoked
 *
 * @constant {string}
 * @default
 * @static
 */
const REVOKE_ENDORSEMENT_REQUEST = 'REVOKE_ENDORSEMENT_REQUEST';

/**
 * The type of actions to describe that an endorsement is set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSEMENT_SET = 'ENDORSEMENT_SET';

/**
 * The type of actions to describe that the completed endorsements are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ENDORSERS_COMPLETED_ENDORSEMENTS_SET = 'ENDORSERS_COMPLETED_ENDORSEMENTS_SET';

/**
 * Filters.
 *
 * @typedef module:ducks/endorsers~Filter
 * @type {object}
 * @property {('TOTAL_ENDORSEMENTS_RANGE')} type - The type of the filter.
 * @property {string} value - The value of the filter.
 */

/**
 * Search result.
 *
 * @typedef module:ducks/endorsers~Result
 * @type {object}
 * @property {module:types/endorsers/searches~Facets} [facets] - The facets.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {module:types/endorsers/profiles~Profile[]} [profiles] - The profiles.
 */

/**
 * Creates an action to complete a search with the given result.
 *
 * @param {module:ducks/endorsers~Result} result - The search result.
 * @returns {object} - The action.
 * @static
 */
const completeSearch = (result) => {
  return {
    result,
    type: ENDORSERS_SEARCH_COMPLETED,
  };
};

/**
 * Search parameters.
 *
 * @typedef module:ducks/endorsers~Params
 * @type {object}
 * @property {module:ducks/endorsers~Filter[]} [filters] - The filters.
 * @property {number} page - The number of the current page.
 * @property {('LAST_MODIFIED_AT' | 'LAST_NAME' | 'TOTAL_ENDORSEMENTS')} sort - The key to sort by.
 */

/**
 * Creates an action to make a search with the given parameters.
 *
 * @param {module:ducks/endorsers~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const makeSearch = (params) => {
  return {
    params,
    type: ENDORSERS_SEARCH_MADE,
  };
};

/**
 * Creates an action to refine a search with the given parameters.
 *
 * @param {module:ducks/endorsers~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const refineSearch = (params) => {
  return {
    params,
    type: ENDORSERS_SEARCH_REFINED,
  };
};

/**
 * Creates an action to set the statistics of the endorsers based on the given params.
 *
 * @param {module:types/endorsers/statistics~Statistics} statistics - The profile.
 * @returns {object} - The action.
 * @static
 */
const setStatistics = (statistics) => {
  return {
    statistics,
    type: ENDORSERS_STATISTICS_SET,
  };
};

/**
 * Creates an action to set the created endorsement requests of the endorsers based on the given params.
 *
 * @param {module:types/common~EndorsementRequest[]} createdEndorsementRequests - The profile.
 * @returns {object} - The action.
 * @static
 */
const setCreatedEndorsementRequests = (createdEndorsementRequests) => {
  return {
    createdEndorsementRequests,
    type: ENDORSERS_CREATED_ENDORSEMENT_REQUESTS_SET,
  };
};

/**
 * Creates an action to set the claimed endorsement requests of the endorsers based on the given params.
 *
 * @param {module:types/common~EndorsementRequest[]} claimedEndorsementRequests - The profile.
 * @returns {object} - The action.
 * @static
 */
const setClaimedEndorsementRequests = (claimedEndorsementRequests) => {
  return {
    claimedEndorsementRequests,
    type: ENDORSERS_CLAIMED_ENDORSEMENT_REQUESTS_SET,
  };
};

/**
 * Creates an action to reset the endorsers state object
 *
 * @returns {object} - The action.
 * @static
 */
const resetEndorsers = () => {
  return {
    type: ENDORSERS_RESET,
  };
};

/**
 * The claimed endorsement request action payload
 *
 * @typedef module:ducks/endorsers~ClaimedEndorsementRequestPayload
 * @type {object}
 * @property {module:types/common~EndorsementRequest[]} createdEndorsementRequests - The created endorsement requests
 * @property {module:types/common~EndorsementRequest[]} claimedEndorsementRequests - The claimed endorsement requests
 */

/**
 * Creates an action to claim an endorsement request
 *
 * @param {module:ducks/endorsers~ClaimedEndorsementRequestPayload} claimedEndorsementPayload
 * - The claimed endorsement request payload
 * @returns {object} - The action.
 * @static
 */
const claimEndorsementRequest = (claimedEndorsementPayload) => {
  return {
    claimedEndorsementPayload,
    type: CLAIMED_ENDORSEMENT_REQUEST,
  };
};

/**
 * The revoked endorsement request action payload
 *
 * @typedef module:ducks/endorsers~RevokedEndorsementRequest
 * @type {object}
 * @property {module:types/common~EndorsementRequest[]} createdEndorsementRequests - The created endorsement requests
 * @property {module:types/common~EndorsementRequest[]} claimedEndorsementRequests - The claimed endorsement requests
 */

/**
 * Creates an action to revoke an endorsement request
 *
 * @param {module:ducks/endorsers~RevokedEndorsementRequest} revokedEndorsementPayload
 * - The revoked endorsement request payload
 * @returns {object} - The action.
 * @static
 */
const revokeEndorsementRequest = (revokedEndorsementPayload) => {
  return {
    revokedEndorsementPayload,
    type: REVOKE_ENDORSEMENT_REQUEST,
  };
};

/**
 * Creates an action to set a specific endorsement
 *
 * @param {module:types/common~EndorsementRequest} endorsement - The endorsement to be submitted
 * @returns {object} - The action.
 * @static
 */
const setEndorsement = (endorsement) => {
  return {
    endorsement,
    type: ENDORSEMENT_SET,
  };
};

/**
 * Creates an action to set a specific endorsement
 *
 * @param {any} endorsements - The endorsement to be submitted
 * @returns {object} - The action.
 * @static
 */
const setCompletedEndorsements = (endorsements) => {
  return {
    endorsements,
    type: ENDORSERS_COMPLETED_ENDORSEMENTS_SET,
  };
};

/**
 * Endorsers state.
 *
 * @typedef module:ducks/endorsers~State
 * @type {object}
 * @property {module:types/common~EndorsementRequest} [endorsement] - The endorsement to be submitted.
 * @property {module:types/endorsers/statistics~Statistics} [statistics] - The endorsers statistics.
 * @property {module:types/common~EndorsementRequest[]} claimedEndorsementRequests - The claimed endorsement requests
 * @property {module:types/common~EndorsementRequest[]} createdEndorsementRequests - The created endorsement requests.
 * @property {any} completedEndorsements - The completed endorsements
 * @property {module:types/endorsers/searches~Facets} [facets] - The facets.
 * @property {module:ducks/endorsers~Filter[]} [filters] - The filters.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {number} [page] - The number of the current page.
 * @property {module:types/endorsers/profiles~Profile[]} [profiles] - The profiles.
 * @property {('LAST_MODIFIED_AT' | 'LAST_NAME' | 'TOTAL_ENDORSEMENTS')} [sort] - The key to sort by.
 */

/**
 * The initial endorsers state.
 *
 * @type {module:ducks/endorsers~State}
 * @static
 */
const initialState = {
  claimedEndorsementRequests: [],
  completedEndorsements: [],
  createdEndorsementRequests: [],
  endorsement: undefined,
  expanded: filterTypes.reduce((acc, ft) => {
    return { ...acc, [ft]: true };
  }, {}),
  facets: undefined,
  filters: undefined,
  meta: undefined,
  page: undefined,
  profiles: undefined,
  sort: undefined,
  statistics: undefined,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/endorsers~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/endorsers~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case CLAIMED_ENDORSEMENT_REQUEST:
    return {
      ...state,
      claimedEndorsementRequests: action.claimedEndorsementPayload.claimedEndorsementRequests,
      createdEndorsementRequests: action.claimedEndorsementPayload.createdEndorsementRequests,
    };
  case REVOKE_ENDORSEMENT_REQUEST:
    return {
      ...state,
      claimedEndorsementRequests: action.revokedEndorsementPayload.claimedEndorsementRequests,
      createdEndorsementRequests: action.revokedEndorsementPayload.createdEndorsementRequests,
    };
  case ENDORSERS_STATISTICS_SET:
    return {
      ...state,
      statistics: action.statistics,
    };
  case ENDORSERS_CREATED_ENDORSEMENT_REQUESTS_SET:
    return {
      ...state,
      createdEndorsementRequests: action.createdEndorsementRequests,
    };
  case ENDORSERS_CLAIMED_ENDORSEMENT_REQUESTS_SET:
    return {
      ...state,
      claimedEndorsementRequests: action.claimedEndorsementRequests,
    };
  case ENDORSERS_RESET:
    return {
      ...initialState,
    };
  case ENDORSEMENT_SET:
    return {
      ...state,
      endorsement: action.endorsement,
    };
  case ENDORSERS_COMPLETED_ENDORSEMENTS_SET:
    return {
      ...state,
      completedEndorsements: action.endorsements,
    };
  case ENDORSERS_SEARCH_COMPLETED:
    return {
      ...state,
      facets: action.result.facets,
      meta: action.result.meta,
      profiles: action.result.profiles,
    };
  case ENDORSERS_SEARCH_MADE:
    return {
      ...state,
      expanded: filterTypes.reduce((acc, ft) => {
        return { ...acc, [ft]: true };
      }, {}),
      facets: undefined,
      filters: action.params.filters,
      meta: undefined,
      page: action.params.page,
      profiles: undefined,
      sort: action.params.sort,
    };
  case ENDORSERS_SEARCH_REFINED:
    return {
      ...state,
      filters: action.params.filters,
      page: action.params.page,
      sort: action.params.sort,
    };
  default:
    return state;
  }
};

const actions = {
  claimEndorsementRequest,
  completeSearch,
  makeSearch,
  refineSearch,
  resetEndorsers,
  revokeEndorsementRequest,
  setClaimedEndorsementRequests,
  setCompletedEndorsements,
  setCreatedEndorsementRequests,
  setEndorsement,
  setStatistics,
};

const types = {};

export {
  actions,
  reducer,
  types,
};
