/**
 * Resource method to revoke a CLAIMED endorsement request.
 *
 * @module resources/endorsers/revoke-claimed-endorsement-request
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/endorsers/revoke-claimed-endorsement-request~Params
 * @type {object}
 * @property {string} id - The ID of the endorsement
 */

/**
 * Revokes a CLAIMED endorsement request.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/endorsers/revoke-claimed-endorsement-request~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const claimEndorsementRequest = (params) => {
  return axios.request({
    method: 'post',
    url: `/endorsement-requests/claimed/${ params.id }/revoke`,
  }).then((response) => {
    return response.data;
  });
};

export default claimEndorsementRequest;
