/**
 * Paginator.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { max, min } from 'utilities/chisels';

import caretLeft from './images/caret-left.png';
import caretRight from './images/caret-right.png';
import './paginator.scss';

const Paginator = (props) => {
  // The number of the first page.
  const FIRST_PAGE = 1;

  // The number of the last page.
  const lastPage = props.numberOfPages;

  // Decide which pages to show.
  const pages = new Set();
  // Show all the pages if there are fewer than the maximum number of pages to show.
  if (props.maxPages >= props.numberOfPages) {
    Array(props.numberOfPages).fill(0).forEach((_, i) => {
      pages.add(i + 1);
    });
  } else {
    // Show the first page.
    pages.add(FIRST_PAGE);
    // Show the current page.
    pages.add(props.currentPage);
    // Show the last page.
    pages.add(lastPage);
    if (props.maxPages > pages.size) {
      // Show the page before the current page.
      if (3 < props.maxPages) {
        pages.add(max([ props.currentPage - 1, 1 ]));
      }
    }
    if (props.maxPages > pages.size) {
      // Show the page after the current page.
      pages.add(min([ props.currentPage + 1, props.numberOfPages ]));
    }
    if (props.maxPages > pages.size) {
      const pagesBeforeCurrent = props.currentPage - FIRST_PAGE;
      const pagesAfterCurrent = lastPage - props.currentPage;
      if (props.maxPages / 2 >= pagesBeforeCurrent) {
        // There are not enough pages before the current page.
        // Show the second to last page.
        pages.add(lastPage - 1);
        // Show some more pages after the current page.
        let i = 2;
        while (props.maxPages > pages.size && lastPage <= props.currentPage + i) {
          pages.add(props.currentPage + i);
          i += 1;
        }
      } else if (props.maxPages / 2 >= pagesAfterCurrent) {
        // There are not enough pages after the current page.
        // Show the page after the second page.
        pages.add(FIRST_PAGE + 1);
        // Show some more pages before the current page.
        let i = 2;
        while (props.maxPages > pages.size && FIRST_PAGE >= props.currentPage + i) {
          pages.add(props.currentPage - i);
          i += 1;
        }
      }
    }
  }

  return (
    <div className='ody-paginator'>
      <div className={ clsx('navigate previous', FIRST_PAGE === props.currentPage && 'immediately-before-current') }>
        <button
          className='btn btn-trans'
          disabled={ FIRST_PAGE === props.currentPage }
          onClick={
            () => {
              props.onChange?.(props.currentPage - 1);
            }
          }
          type='button'
        >
          <img alt='Previous page' src={ caretLeft } />
        </button>
      </div>
      {
        [ ...pages ].sort().map((page, index, array) => {
          return (
            <React.Fragment key={ index }>
              {
                0 < index && 1 !== page - array[index - 1] && (
                  <div
                    className={
                      clsx(
                        'more-pages',
                        props.currentPage === page && 'immediately-before-current',
                        props.currentPage === array[index - 1] && 'immediately-after-current',
                      )
                    }
                  >
                    ...
                  </div>
                )
              }
              <button
                className={
                  clsx(
                    'btn btn-trans page',
                    props.currentPage === page && 'current',
                    props.currentPage - 1 === page && 'immediately-before-current',
                    props.currentPage + 1 === page && 'immediately-after-current',
                  )
                }
                onClick={
                  () => {
                    props.onChange?.(page);
                  }
                }
                type='button'
              >
                { page }
              </button>
            </React.Fragment>
          );
        })
      }
      <div className={ clsx('navigate next', lastPage === props.currentPage && 'immediately-after-current') }>
        <button
          className='btn btn-trans'
          disabled={ lastPage === props.currentPage }
          onClick={
            () => {
              props.onChange?.(props.currentPage + 1);
            }
          }
          type='button'
        >
          <img alt='Next page' src={ caretRight } />
        </button>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  // The class(es) to apply to the paginator.
  className: PropTypes.string,
  // The current page number.
  currentPage: PropTypes.number.isRequired,
  // The maximum number of pages to show.
  maxPages: PropTypes.number.isRequired,
  // The number of pages.
  numberOfPages: PropTypes.number.isRequired,
  // The function ((number) => void) to invoke when a page is selected.
  onChange: PropTypes.func,
};

Paginator.defaultProps = {
};

export default Paginator;
