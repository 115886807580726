/**
 * English translations for common/profile-about.
 *
 * @module languages/en/common/profile-about
 */
const profileAbout = {
  change_button_label: 'Change',
  delete_button_label: 'Delete',
  description_error: 'The description must not exceed 1024 characters',
  editor_title_companies: 'About',
  editor_title_job_seekers: 'About me',
  no_video_message_companies: 'Upload a video describing your company here.',
  no_video_message_job_seekers: 'Upload a video describing yourself here.',
  short_bio_label_companies: 'Company description',
  short_bio_label_job_seekers: 'Short bio',
  title_companies: 'About',
  title_job_seekers: 'About me',
  too_large_video_error: 'Upload a video up to 16MB.',
  unsupported_video_format_error: 'Upload a video in MP4 or OGG format.',
  upload_button_label: 'Upload',
};

export default profileAbout;
