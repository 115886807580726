/**
 * Main home page.
 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import PublicCompaniesCollectionSection
  from 'components/common/public-companies-collection-section/public-companies-collection-section';
import PublicGrowSocialImpactSection
  from 'components/common/public-grow-social-impact-section/public-grow-social-impact-section';
import PublicStatisticsSection from 'components/common/public-statistics-section/public-statistics-section';
import PublicTestimonialsSection from 'components/common/public-testimonials-section/public-testimonials-section';
import AdeccoImage from 'images/companies/adecco.png';
import FourlisImage from 'images/companies/fourlis.png';
import KineoImage from 'images/companies/kineo.png';
import ManpowerGroupImage from 'images/companies/manpower.png';
import MsfImage from 'images/companies/msf.png';
import SaniResortImage from 'images/companies/sani.png';
import TexNekImage from 'images/companies/technek.png';
import FirstCompanyFirst from 'images/our-companies-say/first/first.png';
import FirstCompanyFourth from 'images/our-companies-say/first/fourth.png';
import FirstCompanySecond from 'images/our-companies-say/first/second.jpeg';
import FirstCompanyThird from 'images/our-companies-say/first/third.png';
import SecondCompanyFirst from 'images/our-companies-say/second/first.jpeg';
import SecondCompanyFourth from 'images/our-companies-say/second/fourth.jpeg';
import SecondCompanySecond from 'images/our-companies-say/second/second.jpeg';
import SecondCompanyThird from 'images/our-companies-say/second/third.jpeg';
import FirstTestimonialImage from 'images/testimonials/first.jpeg';
import SecondTestimonialImage from 'images/testimonials/second.jpeg';
import ThirdTestimonialImage from 'images/testimonials/third.jpeg';

import HomeCreateProfileSection from './home-create-profile-section/home-create-profile-section';
import HomeFindTalentSection from './home-find-talent-section/home-find-talent-section';
import HomeLearnMoreSection from './home-learn-more-section/home-learn-more-section';
import HomeOurCompaniesSaySection from './home-our-companies-say-section/home-our-companies-say-section';
import HomeWelcomeSection from './home-welcome-section/home-welcome-section';

import './home.scss';

const Home = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The metrics state object
  const metrics = useSelector((store) => {
    return store.metrics;
  }, shallowEqual);

  // the talent platform metrics
  const talentPlatformMetrics = useMemo(() => {
    return metrics?.talentPlatformMetrics;
  }, [ metrics?.talentPlatformMetrics ]);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return auth?.id !== undefined;
  }, [ auth?.id ]);

  const { t } = useTranslation();

  // statistics
  const statistics = useMemo(() => {
    return [
      {
        number: talentPlatformMetrics?.profiles,
        title: t('home:statistics_section.statistics.item1.title'),
      },
      {
        number: talentPlatformMetrics?.companies,
        title: t('home:statistics_section.statistics.item2.title'),
      },
      {
        number: talentPlatformMetrics?.peopleHired,
        title: t('home:statistics_section.statistics.item3.title'),
      },
      {
        number: talentPlatformMetrics?.matchedJobSeekersWithCompanies,
        title: t('home:statistics_section.statistics.item4.title'),
      },
    ];
  }, [
    t,
    talentPlatformMetrics?.companies,
    talentPlatformMetrics?.matchedJobSeekersWithCompanies,
    talentPlatformMetrics?.peopleHired,
    talentPlatformMetrics?.profiles,
  ]);

  // The companies images collection
  const companiesImagesCollection = useMemo(() => {
    return [
      AdeccoImage,
      FourlisImage,
      KineoImage,
      ManpowerGroupImage,
      MsfImage,
      SaniResortImage,
      TexNekImage,
    ];
  }, []);

  // The testimonials collection
  const testimonialsCollection = useMemo(() => {
    return [
      [
        {
          ageLocation: t('home:testimonials_section.testimonials.item1.item1.age_location'),
          name: t('home:testimonials_section.testimonials.item1.item1.name'),
          title: t('home:testimonials_section.testimonials.item1.item1.title'),
          videoUrl: t('home:testimonials_section.testimonials.item1.item1.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item1.item2.age_location'),
          name: t('home:testimonials_section.testimonials.item1.item2.name'),
          title: t('home:testimonials_section.testimonials.item1.item2.title'),
          videoUrl: t('home:testimonials_section.testimonials.item1.item2.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item1.item3.age_location'),
          description: t('home:testimonials_section.testimonials.item1.item3.description'),
          imageUrl: FirstTestimonialImage,
          name: t('home:testimonials_section.testimonials.item1.item3.name'),
          title: t('home:testimonials_section.testimonials.item1.item3.title'),
        },
      ], [
        {
          ageLocation: t('home:testimonials_section.testimonials.item2.item1.age_location'),
          name: t('home:testimonials_section.testimonials.item2.item1.name'),
          title: t('home:testimonials_section.testimonials.item2.item1.title'),
          videoUrl: t('home:testimonials_section.testimonials.item2.item1.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item2.item2.age_location'),
          name: t('home:testimonials_section.testimonials.item2.item2.name'),
          title: t('home:testimonials_section.testimonials.item2.item2.title'),
          videoUrl: t('home:testimonials_section.testimonials.item2.item2.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item2.item3.age_location'),
          description: t('home:testimonials_section.testimonials.item2.item3.description'),
          imageUrl: SecondTestimonialImage,
          name: t('home:testimonials_section.testimonials.item2.item3.name'),
          title: t('home:testimonials_section.testimonials.item2.item3.title'),
        },
      ], [
        {
          ageLocation: t('home:testimonials_section.testimonials.item3.item1.age_location'),
          name: t('home:testimonials_section.testimonials.item3.item1.name'),
          title: t('home:testimonials_section.testimonials.item3.item1.title'),
          videoUrl: t('home:testimonials_section.testimonials.item3.item1.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item3.item2.age_location'),
          name: t('home:testimonials_section.testimonials.item3.item2.name'),
          title: t('home:testimonials_section.testimonials.item3.item2.title'),
          videoUrl: t('home:testimonials_section.testimonials.item3.item2.video_url'),
        },
        {
          ageLocation: t('home:testimonials_section.testimonials.item3.item3.age_location'),
          description: t('home:testimonials_section.testimonials.item3.item3.description'),
          imageUrl: ThirdTestimonialImage,
          name: t('home:testimonials_section.testimonials.item3.item3.name'),
          title: t('home:testimonials_section.testimonials.item3.item3.title'),
        },
      ],
    ];
  }, [ t ]);

  const ourCompaniesSayCollection = useMemo(() => {
    return [
      [
        {
          description: t('home:our_companies_say_section.collection.item1.item1.description'),
          imageUrl: FirstCompanyFirst,
          name: t('home:our_companies_say_section.collection.item1.item1.name'),
          occupation: t('home:our_companies_say_section.collection.item1.item1.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item1.item2.description'),
          imageUrl: FirstCompanySecond,
          name: t('home:our_companies_say_section.collection.item1.item2.name'),
          occupation: t('home:our_companies_say_section.collection.item1.item2.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item1.item3.description'),
          imageUrl: FirstCompanyThird,
          name: t('home:our_companies_say_section.collection.item1.item3.name'),
          occupation: t('home:our_companies_say_section.collection.item1.item3.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item1.item4.description'),
          imageUrl: FirstCompanyFourth,
          name: t('home:our_companies_say_section.collection.item1.item4.name'),
          occupation: t('home:our_companies_say_section.collection.item1.item4.occupation'),
        },
      ],
      [
        {
          description: t('home:our_companies_say_section.collection.item2.item1.description'),
          imageUrl: SecondCompanyFirst,
          name: t('home:our_companies_say_section.collection.item2.item1.name'),
          occupation: t('home:our_companies_say_section.collection.item2.item1.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item2.item2.description'),
          imageUrl: SecondCompanySecond,
          name: t('home:our_companies_say_section.collection.item2.item2.name'),
          occupation: t('home:our_companies_say_section.collection.item2.item2.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item2.item3.description'),
          imageUrl: SecondCompanyThird,
          name: t('home:our_companies_say_section.collection.item2.item3.name'),
          occupation: t('home:our_companies_say_section.collection.item2.item3.occupation'),
        },
        {
          description: t('home:our_companies_say_section.collection.item2.item4.description'),
          imageUrl: SecondCompanyFourth,
          name: t('home:our_companies_say_section.collection.item2.item4.name'),
          occupation: t('home:our_companies_say_section.collection.item2.item4.occupation'),
        },
      ],
    ];
  }, [ t ]);

  return (
    <div className='ody-home'>
      <HomeWelcomeSection
        userAuthenticated={ userAuthenticated }
      />
      <HomeLearnMoreSection />
      <HomeCreateProfileSection />
      <PublicStatisticsSection
        statistics={ statistics }
      />
      <HomeFindTalentSection />
      <PublicGrowSocialImpactSection
        buttonLabel={ t('home:grow_social_impact_section.button_label') }
        hasButton
        info={ t('home:grow_social_impact_section.info') }
        isBlue
        title={ t('home:grow_social_impact_section.title') }
      />
      <PublicCompaniesCollectionSection
        buttonLabel={ t('home:companies_collection_section.button_label') }
        hasButton
        imagesCollection={ companiesImagesCollection }
        title={ t('home:companies_collection_section.title') }
      />
      <HomeOurCompaniesSaySection
        collection={ ourCompaniesSayCollection }
        title={ t('home:our_companies_say_section.title') }
      />
      <PublicTestimonialsSection
        testimonials={ testimonialsCollection }
        title={ t('home:testimonials_section.title') }
      />
    </div>
  );
};

Home.propTypes = {
};

Home.defaultProps = {
};

export default Home;
