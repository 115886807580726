/**
 * Greek translations for companies/steps.
 *
 * @module languages/el/companies/steps
 */
const steps = {
  CONTACT: 'Υποβάλετε τα προσωπικά σας στοιχεία',
  DESCRIPTION: 'Προσθέστε μια περιγραφή της εταιρείας',
  DETAILS: 'Ανεβάστε τα στοιχεία της εταιρείας',
  JOB_POSITIONS: 'Προσθέστε τις θέσεις εργασίας που σας ενδιαφέρουν',
  JOB_SECTORS: 'Προσθέστε τους τομείς εργασίας της εταιρείας',
  LOGO: 'Ανεβάστε το λογότυπο της εταιρείας',
  PRICING: 'Ενημερώστε τα στοιχεία χρέωσής σας',
  VIDEO: 'Ανεβάστε ένα βίντεο για την εταιρεία σας',
};

export default steps;
