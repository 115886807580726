/**
 * Greek translations for companies/learn-more.
 *
 * @module languages/el/companies/learn-more
 */
const learnMore = {
  ai_talent_recommender_section: {
    button_label: 'Έρχεται σύντομα',
    // eslint-disable-next-line max-len
    description: 'Ο αλγόριθμος AI μας ταιριάζει με τις ανάγκες σας με διαθέσιμους υποψηφίους στην πλατφόρμα. Δεν θα χάσετε ποτέ μια μεγάλη ευκαιρία για εσάς.',
    title: 'Ο αλγόριθμος AI διασύνδεσης υποψήφιων',
  },
  consulting_section: {
    // eslint-disable-next-line max-len
    description: 'Σχετικά με τη συμβολή σας στη ζωή και τη σταδιοδρομία των υποψήφιων μας σας απονέμουμε περήφανα με το σήμα κοινωνικής βιωσιμότητας.',
    title: 'Συμβουλευτικές υπηρεσίες σχετικά με την DE&I στρατηγική σας',
  },
  csr_packages_section: {
    button_label: 'Τα CSR πακέτα μας ',
    // eslint-disable-next-line max-len
    description: 'Περιηγηθείτε στα πακέτα CSR μας και επιλέξτε αυτό που προτιμάτε. Μπορείτε να υποστηρίξετε ευάλωτους πληθυσμούς μέσω της εκπαίδευσης, του επαγγελματικού προσανατολισμού και άλλων τρόπων. Συνεργαζόμενοι σε αυτό το επίπεδο, σας προσφέρουμε τις υπηρεσίες μας δωρεάν.',
    title: 'Συνδυάστε τις HR ανάγκες σας με τα CSR πακέτα μας',
  },
  diversity_section: {
    // eslint-disable-next-line max-len
    description: 'Συνδεθείτε με υποψήφιους από διαφορετικό πολιτισμικό υπόβαθρο. Αναπτύξτε τη διαφορετικότητα στην εταιρεία σας και επικοινωνήστε εντός και εκτός του οργανισμού σας τις προσπάθειές σας για μια κοινωνία χωρίς αποκλεισμούς.',
    title: 'Η διαφορετικότητα και η ένταξη στο χώρο εργασίας',
  },
  endorsement_section: {
    button_label: 'Μάθετε περισσότερα',
    // eslint-disable-next-line max-len
    description: 'Το δίκτυο συμβούλων και συνεργατών μας συναντά τους υποψήφιους χρησιμοποιώντας την πλατφόρμα μας και παρέχουν πολύτιμη ανατροφοδότηση σχετικά με τις δεξιότητες και τη νοοτροπία τους',
    title: 'Συστάσεις',
  },
  example_profile_section: {
    button_label: 'Δείτε ένα παράδειγμα προφίλ',
    // eslint-disable-next-line max-len
    description: 'Αναζητήστε υποψηφίους σύμφωνα με τις δεξιότητες, τα χαρακτηριστικά, τη γλωσσική επάρκεια, το εργασιακό τους ιστορικό και πολλά άλλα.',
    title: 'Φιλτράρετε τους υποψηφίους ανάλογα με τις δεξιότητές τους',
  },
  faqs_section: {
    faqs: {
      item1: {
        // eslint-disable-next-line max-len
        answer: 'Η Talent platform είναι ένας χώρος στον οποίο οι εταιρείες:\n\n - Εξοικονομούν χρόνο στην αναζήτηση υποψήφιων, \n - Φιλτράρουν τους υποψηφίους με βάση τις δεξιότητές τους\n - Λαμβάνουν προτάσεις υποψηφίων από τον ΑΙ αλγόριθμό μας και τους συμβούλους απασχολησιμότητάς μας\n. - Συνδυάσουν τις HR ανάγκες τους με CSR πακέτα μας',
        question: 'Τι ειναι η talent platform by Odyssea;',
      },
      item2: {
        // eslint-disable-next-line max-len
        answer: 'Για να εγγραφείτε στην πλατφόρμα, πρέπει να δημιουργήσετε το προσωπικό σας προφίλ κάνοντας κλικ στο κουμπί εγγραφής στο επάνω μέρος της σελίδας.',
        question: 'Πώς μπορώ να συμμετέχω στην πλατφόρμα;',
      },
      item3: {
        // eslint-disable-next-line max-len
        answer: 'Ναι, η δημιουργία του λογαριασμού σας στην πλατφόρμα είναι δωρεάν!\n\n\nΓια να επωφεληθείτε πλήρως από τα πλεονεκτήματα της πλατφόρμας μας, επιλέξτε ένα από τα πακέτα μας εδώ!',
        question: 'Η εγγραφή στην πλατφόρμα είναι δωρεάν;',
      },
      item4: {
        answer: 'Για οποιαδήποτε απορία μπορείτε να επικοινωνήσετε μαζί μας στο: talent@odyssea.com',
        question: 'Πώς μπορώ να επικοινωνήσω;',
      },
    },
    title: 'FAQ',
  },
  hr_packages_section: {
    failed_email: 'Αποτυχία αποστολής email στον διαχειριστή. Παρακαλούμε προσπαθήστε ξανά',
    free_pricing_button_label: 'Εγγραφείτε',
    free_pricing_plan: {
      description: {
        item1: 'Γνωρίστε την πλατφόρμα μας',
        item2: 'Προβολή προφίλ χωρίς πρόσβαση στα στοιχεία επικοινωνίας',
      },
      title: 'Δωρεάν',
    },
    pricing_button_label: 'Διαλέξτε πακέτο',
    // eslint-disable-next-line max-len
    pricing_interest_different_role_error: 'Πρέπει να είστε αντιπρόσωπος της εταιρείας για να εκδηλώσετε ενδιαφέρον για τα πακέτα μας',
    pricing_plans: {
      item1: {
        description: {
          item1: '5 φιλτραρισμένα προφίλ για τις θέσεις εργασίας σας',
          item2: 'Πλήρης πρόσβαση στη βάση δεδομένων της πλατφόρμας για 6 μήνες',
          item3: 'Υποστηρίξτε την κοινωνική κουζίνα μας με τηνπαροχή 100 γευμάτων',
        },
        pricing_description: '350€   ΦΠΑ',
        title: 'Βασικό',
        title_description: '1 θέση εργασίας',
      },
      item2: {
        description: {
          item1: '10 φιλτραρισμένα προφίλ για τις θέσεις εργασίας σας',
          item2: 'Πλήρης πρόσβαση στη βάση δεδομένων της πλατφόρμας για 6 μήνες',
          item3: 'Υποστηρίξτε την κοινωνική κουζίνα μας με την παροχή 200 γευμάτων',
        },
        pricing_description: '600€   ΦΠΑ',
        title: 'Προχωρημένο',
        title_description: '2-5 θέσεις εργασίας',
      },
      item3: {
        description: {
          item1: '40 φιλτραρισμένα προφίλ για τις θέσεις εργασίας σας',
          item2: 'Πλήρης πρόσβαση στη βάση δεδομένων της πλατφόρμας για 6 μήνες',
        },
        pricing_description: '1200€   ΦΠΑ',
        title: 'Business',
        title_description: '6-10 θέσεις εργασίας',
      },
      item4: {
        description: {
          item1: 'Οργάνωση ημερών καριέρας με συνεντεύξεις υποψήφιων για τις θέσεις εργασίας σας',
          item2: 'Πλήρης πρόσβαση στη βάση δεδομένων της πλατφόρμας για 6 μήνες',
        },
        pricing_description: 'Επικοινωνήστε μαζί μας',
        title: 'Enterprise',
        title_description: '10 θέσεις',
      },
    },
    success_email: 'Έχει σταλεί email στον διαχειριστή',
    title: 'HR Πακέτα',
  },
  pool_of_verified_job_seekers_section: {
    button_label: 'Δείτε υποψήφιους',
    // eslint-disable-next-line max-len
    description: 'Συνδεθείτε με υποψηφίους. Αναζητήστε αποτελεσματικά νέα ταλέντα. Όλα σύμφωνα με το προφίλ σας και τις ανάγκες σας. Εγγραφείτε για να γίνετε μέλος της κοινότητας εταιρειών και συνεργατών μας.',
    title: 'Λίστα επιλεγμένων υποψήφιων',
  },
  sign_in_section: {
    title: 'Αναγνωρίστε τον/ην υποψήφιο/α που ταιριάζει καλύτερα στις ανάγκες σας',
  },
};

export default learnMore;
