/**
 * Greek translations for job-seekers/profile-endorsers-feedback.
 *
 * @module languages/el/job-seekers/profile-endorsers-feedback
 */
const profileEndorsersFeedback = {
  average: {
    communication_cooperation_skills_level_average_score_title: 'Επικοινωνία & Συνεργασία',
    critical_thinking_level_average_score_title: 'Κριτική σκέψη',
    motivation_level_average_score_title: 'Κίνητρο',
    professional_characteristics_average_score_title: 'Επαγγελματισμός',
  },
  card: {
    personality_qualities_title: 'Ιδιότητες προσωπικότητας',
    testimonial_title: 'Είπαν για εσάς',
  },
  request_endorsement_button_title: 'Αίτηση για σύσταση',
  request_endorsement_confirm: 'Είστε βέβαιοι ότι θέλετε να ζητήσετε σύσταση;',
  title: 'Ανατροφοδότηση υποστηρικτή',
};

export default profileEndorsersFeedback;
