/**
 * English translations for endorsers/profile-professional-details.
 *
 * @module languages/en/endorsers/profile-professional-details
 */
const profileProfessionalDetails = {
  city_placeholder: 'Select your city',
  company_name_label: 'Company Name (Optional)',
  company_name_placeholder: 'Enter your company\'s name',
  company_position_label: 'Company Position (Optional)',
  company_position_placeholder: 'Enter the position you have in the company',
  company_website_label: 'Company\'s website (Optional)',
  company_website_placeholder: 'Enter your company\'s website',
  company_website_text_label: 'Company\'s website',
  district_label: 'District',
  district_placeholder: 'District',
  editor_title: 'Professional',
  linkedin_profile: 'LinkedIn profile',
  linkedin_profile_url_label: 'LinkedIn profile URL',
  linkedin_profile_url_placeholder: 'Paste your LinkedIn profile URL',
  location_label: 'Location',
  location_placeholder: 'Location',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Phone number must be a number with the country prefix (e.g +30) and not exceed 10 numbers',
  phone_number_label: 'Phone Number',
  phone_number_placeholder: 'Enter your professional phone number',
  phone_number_required_error: 'The phone number must not be empty',
  title: 'Professional',
  website_invalid_url: 'Enter a valid URL e.g https://www.example.com or http://www.example.com.',
};

export default profileProfessionalDetails;
