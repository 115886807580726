/**
 * Resource method to verify an email address.
 *
 * @module resources/auth/verify-email
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/auth/verify-email~Params
 * @type {object}
 * @property {string} emailVerificationToken - The email verification token.
 */

/**
 * Verifies an email address based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/auth/verify-email~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const verifyEmail = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/auth/email/verify',
  }).then((response) => {
    return response.data;
  });
};

export default verifyEmail;
