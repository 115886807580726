/**
 * English translations for common/profile-overview.
 *
 * @module languages/en/common/profile-overview
 */
const profileOverview = {
  edit_profile_button_label: 'Edit profile',
};

export default profileOverview;
