import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './take-assesment-card.scss';

const TakeAssessmentCard = memo((props) => {
  const { icon, title, description, isLastCard } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // The rendered icon container
  const renderedIconContainer = useMemo(() => {
    if (isLastCard) {
      return null;
    }
    return (
      <div className='take-assessment-card__icon-container'>
        { icon }
      </div>
    );
  }, [ icon, isLastCard ]);

  // The rendered card title
  const renderedCardTitle = useMemo(() => {
    if (isLastCard) {
      return null;
    }
    return (
      <h3 className='hdg hdg-xl take-assessment-card__title'>
        { title }
      </h3>
    );
  }, [ isLastCard, title ]);

  return (
    <div
      className={
        clsx('take-assessment-card', {
          'take-assessment-card__last-card': isLastCard,
          'take-assessment-card__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      { renderedIconContainer }
      <div className='take-assessment-card__content'>
        { renderedCardTitle }
        <p
          className={
            clsx('txt txt-sm take-assessment-card__description', {
              'take-assessment-card__description--last-card': isLastCard,
            })
          }
        >
          { description }
        </p>
      </div>
    </div>
  );
});

TakeAssessmentCard.displayName = 'TakeAssessmentCard';

TakeAssessmentCard.propTypes = {
  // The description of the card
  description: PropTypes.string.isRequired,
  // The icon to be displayed in the card
  icon: PropTypes.node,
  // Whether the card is the last one of the collection
  isLastCard: PropTypes.bool,
  // The card title
  title: PropTypes.string,
};

TakeAssessmentCard.defaultProps = {
  isLastCard: false,
};

export default TakeAssessmentCard;
