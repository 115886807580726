/**
 * Confirm modal.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';

import './confirm.scss';

const Confirm = memo((props) => {
  const { className, trigger, text, onDeny, onConfirm } = props;

  const { t } = useTranslation();

  // Reference to the modal.
  const modalRef = useRef(undefined);

  // function that is invoked when onDeny button is clicked
  const onDenyClicked = useCallback(() => {
    modalRef.current?.close();
    onDeny?.();
  }, [ onDeny ]);

  // function that is invoked when onDeny button is clicked
  const onConfirmClicked = useCallback(() => {
    modalRef.current?.close();
    onConfirm?.();
  }, [ onConfirm ]);

  return (
    <div className={ clsx(`ody-confirm ${ className }`) }>
      <Modal
        className='confirm-modal'
        ref={ modalRef }
        trigger={ trigger }
      >
        <div className='confirm'>
          <div className='txt txt-sm'>
            { text || '' }
          </div>
          <div className='buttons'>
            <button
              className='no btn btn-sm btn-rounded-sm btn-white'
              onClick={ onDenyClicked }
              type='button'
            >
              { t('common:confirm.no_button_label') }
            </button>
            <button
              className='yes btn btn-sm btn-rounded-sm btn-red'
              onClick={ onConfirmClicked }
              type='button'
            >
              { t('common:confirm.yes_button_label') }
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

Confirm.displayName = 'Confirm';

Confirm.propTypes = {
  // The class(es) to apply to the confirm modal.
  className: PropTypes.string,
  // The No button.
  noButton: PropTypes.shape({
    // The label of the No button.
    label: PropTypes.string,
  }),
  // The function (() => void) to invoke when the confirmation is given.
  onConfirm: PropTypes.func,
  // The function (() => void) to invoke when the confirmation is denied.
  onDeny: PropTypes.func,
  // The text to show.
  text: PropTypes.string,
  // The trigger of the 'confirm' modal.
  trigger: PropTypes.node.isRequired,
  // The Yes button.
  yesButton: PropTypes.shape({
    // The label of the Yes button.
    label: PropTypes.string,
  }),
};

Confirm.defaultProps = {
};

export default Confirm;
