/**
 * English translations for common/profile-completion.
 *
 * @module languages/en/common/profile-completion
 */
const profileCompletion = {
  almost_there: 'You are almost there!',
  complete: 'Your profile is {{percentage}}% complete.',
  great_job: 'Great job!',
  see_your_profile_link: 'See your profile',
  title: 'Next steps',
};

export default profileCompletion;
