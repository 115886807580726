/**
 * English translations for common/search-results.
 *
 * @module languages/en/common/search-results
 */
const searchResults = {
  about_title: 'About',
  companies_professions_title: 'Jobs positions',
  job_seekers_professions_title: 'Jobs interested in',
  skills_and_traits_button_title: 'Skills and traits',
};

export default searchResults;
