/**
 * Resource method to get the controlled metrics.
 *
 * @module resources/metrics/get-controlled-metrics
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/metrics~ControlledMetrics[]} Result
 */

/**
 * Gets the controlled metrics.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/metrics/get-controlled-metrics~Result>} - The promise.
 * @static
 */
const getControlledMetrics = () => {
  return axios.request({
    method: 'get',
    url: '/metrics/',
  }).then((response) => {
    return response.data;
  });
};

export default getControlledMetrics;
