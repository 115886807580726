import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import DiamondCardIcon from 'components/common/icons/diamond-card-icon/diamond-card-icon';
import useElementOnScreen from 'hooks/use-element-on-screen';

import './learn-more-info-item.scss';

const LearnMoreInfoItem = memo((props) => {
  const { title, description, index } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('learn-more-info-item', {
          'learn-more-info-item__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='learn-more-info-item__icon-container'>
        <DiamondCardIcon />
      </div>
      <div className='learn-more-info-item__info-container'>
        <h3 className='hdg hdg-lg learn-more-info-item__title'>
          { title }
        </h3>
        <p className='txt txt-sm learn-more-info-item__description'>
          { description }
        </p>
      </div>
      <span className='learn-more-info-item__number'>
        { index }
      </span>
    </div>
  );
});

LearnMoreInfoItem.displayName = 'LearnMoreInfoItem';

LearnMoreInfoItem.propTypes = {
  // The info description
  description: PropTypes.string.isRequired,
  // The info index number
  index: PropTypes.number.isRequired,
  // The info title
  title: PropTypes.string.isRequired,
};

LearnMoreInfoItem.defaultProps = {};

export default LearnMoreInfoItem;
