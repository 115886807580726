/**
 * Greek translations for utilities/driving-license-categories.
 *
 * @module languages/el/utilities/driving-license-categories
 */
const drivingLicenseCategories = {
  CATEGORY_A: 'Κατηγορία Α (Μοτοσυκλέτες)',
  CATEGORY_B: 'Κατηγορία Β (Ι.Χ)',
  CATEGORY_C: 'Κατηγορία Γ (Φορτηγά)',
  CATEGORY_D: 'Κατηγορία Δ (Λεωφορεία)',
};

export default drivingLicenseCategories;
