/**
 * Genders.
 *
 * @module utilities/genders
 */
export default [
  'FEMALE',
  'MALE',
  'OTHER',
];
