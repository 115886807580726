/**
 * Greek translations for common/file-preview.
 *
 * @module languages/el/common/file-preview
 */
const filePreview = {
  file_loading_message: 'Φόρτωση αρχείου...',
  no_preview_available_message: 'Μη διαθέσιμη προεπισκόπηση.',
  number_of_total: '{{number}} από {{total}}',
  page_loading_message: 'Φόρτωση σελίδας...',
  preview_failed_error: 'Η προεπισκόπηση απέτυχε.',
};

export default filePreview;
