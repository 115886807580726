/* eslint-disable sort-keys */
/**
 * Greek translations.
 *
 * @module languages/el
 */
import aboutUs from './about-us';
import administrators from './administrators';
import common from './common';
import companies from './companies';
import dashboard from './dashboard';
import endorsement from './endorsement';
import endorsers from './endorsers';
import footer from './footer';
import forgotPassword from './forgot-password';
import header from './header';
import home from './home';
import jobSeekers from './job-seekers';
import profile from './profile';
import resetPassword from './reset-password';
import search from './search';
import seeExampleProfileButton from './see-example-profile-button';
import settings from './settings';
import signIn from './sign-in';
import signInForm from './sign-in-form';
import signUp from './sign-up';
import socialResponsibility from './social-responsibility';
import utilities from './utilities';
import verifyEmail from './verify-email';

export default {
  about_us: aboutUs,
  administrators,
  common,
  companies,
  dashboard,
  endorsement,
  endorsers,
  footer,
  forgot_password: forgotPassword,
  header,
  home,
  job_seekers: jobSeekers,
  profile,
  reset_password: resetPassword,
  search,
  see_example_profile_button: seeExampleProfileButton,
  settings,
  sign_in: signIn,
  sign_in_form: signInForm,
  sign_up: signUp,
  social_responsibility: socialResponsibility,
  utilities,
  verify_email: verifyEmail,
};
