/**
 * Greek translations for endorsers/endorsement-card.
 *
 * @module languages/el/endorsers/endorsement-card
 */
const endorsementCard = {
  about_title: 'Σχετικά',
  claimed_button_title: 'Ανασκόπηση',
  created_button_title: 'Αποδοχή',
  created_confirmation_message: 'Είστε βέβαιοι ότι θέλετε να αποδεχτείτε τη σύσταση του/ης {{jobSeekerName}}',
  endorsement_overview_title: 'Επισκόπηση σύστασης',
  overview_list: {
    communication: 'Επικοινωνία : {{level}}',
    critical_thinking: 'Κριτική σκέψη: {{level}}',
    motivation: 'Κίνητρο: {{level}}',
    professionalism: 'Επαγγελματισμός: {{level}}',
  },
  processed_button_title: 'Προβολή επισκόπησης',
  revoke_button_title: 'Ανάκληση',
  revoke_confirmation_message: 'Είστε βέβαιοι ότι θέλετε να ανακαλέσετε τη σύσταση του {{jobSeekerName}};',
  see_profile_link: 'Προβολή προφίλ',
};

export default endorsementCard;
