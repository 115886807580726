/**
 * English translations for utilities/criterion-types.
 *
 * @module languages/en/utilities/criterion-types
 */
const criterionTypes = {
  CITY: 'Location',
  JOB_SECTOR: 'Job sector',
  TERM: 'Search',
};

export default criterionTypes;
