/**
 * Competencies editor.
 */
import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from 'components/common/date-picker/date-picker';
import MultiSelect from 'components/common/multi-select/multi-select';
import Select from 'components/common/select/select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import roles, * as Roles from 'utilities/auth/roles';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';
import computerSkillsLevels, * as ComputerSkillsLevels from 'utilities/computer-skills-levels';
import drivingLicenseCategories from 'utilities/driving-license-categories';
import workAvailabilities, * as WorkAvailabilities from 'utilities/work-availabilities';

const ProfileCompetenciesEditor = memo((props) => {
  const { profile, onSave, onCancel, userRole } = props;

  const { t } = useTranslation();

  // The extra attributes to be validated if user role is an administrator
  const extraAttributesToBeValidated = useCallback((Joi) => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return {};
    }
    return {
      authorizedToWork: Joi.boolean(),
      workAuthorizationExpiresOn: Joi.date().allow(null),
    };
  }, [ userRole ]);

  const { control, formState: { errors }, handleSubmit, register, setValue, watch }
    = useFormWithSchemaBuilder((Joi) => {
      return Joi.object({
        computerSkillsLevel: Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...computerSkillsLevels),
        }).allow(null),
        drivingLicenseCategories: Joi.array().max(4).items(Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...drivingLicenseCategories),
        })),
        willingToRelocate: Joi.boolean(),
        workAvailability: Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...workAvailabilities),
        }).allow(null),
        ...extraAttributesToBeValidated(Joi),
      });
    });

  // Whether the job seeker is authorized to work.
  const authorizedToWork = watch('authorizedToWork');

  // Clear the date of expiry if the job seeker is not authorized to work.
  useEffect(() => {
    if (false === authorizedToWork) {
      setValue('workAuthorizationExpiresOn', null);
    }
  }, [ authorizedToWork, setValue ]);

  // The extra attributes from profile
  const extraAttributesFromProfile = useMemo(() => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return {};
    }
    return {
      authorizedToWork: profile?.authorizedToWork || undefined,
      workAuthorizationExpiresOn: profile?.workAuthorizationExpiresOn || undefined,
    };
  }, [ profile?.authorizedToWork, profile?.workAuthorizationExpiresOn, userRole ]);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      computerSkillsLevel: profile?.computerSkillsLevel || undefined,
      drivingLicenseCategories: profile?.drivingLicenseCategories || undefined,
      willingToRelocate: profile?.willingToRelocate || undefined,
      workAvailability: profile?.workAvailability || undefined,
      ...extraAttributesFromProfile,
    };
  }, [
    extraAttributesFromProfile,
    profile?.computerSkillsLevel,
    profile?.drivingLicenseCategories,
    profile?.willingToRelocate,
    profile?.workAvailability,
  ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that renders driving license categories to be updated
  const drivingLicenceCategoriesToBeUpdated = useCallback((drivingLicenseCategories) => {
    return drivingLicenseCategories?.map((drivingLicenseCategory) => {
      return drivingLicenseCategory.value;
    });
  }, []);

  // The work authorization expires date to be updated
  const workAuthorizationExpiryDateToBeUpdated = useCallback((workAuthorizationExpiresOn) => {
    if (null === workAuthorizationExpiresOn || workAuthorizationExpiresOn === undefined) {
      return undefined;
    }
    return DateTime.fromJSDate(workAuthorizationExpiresOn).toISODate();
  }, []);

  // The extra attributes to be updated
  const extraAttributesToBeUpdated = useCallback((values) => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return {};
    }
    return {
      authorizedToWork: values?.authorizedToWork,
      workAuthorizationExpiresOn: workAuthorizationExpiryDateToBeUpdated(values?.workAuthorizationExpiresOn),
    };
  }, [ userRole, workAuthorizationExpiryDateToBeUpdated ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      computerSkillsLevel: values.computerSkillsLevel?.value || undefined,
      drivingLicenseCategories: drivingLicenceCategoriesToBeUpdated(values.drivingLicenseCategories),
      willingToRelocate: values.willingToRelocate,
      workAvailability: values.workAvailability?.value || undefined,
      ...extraAttributesToBeUpdated(values),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ drivingLicenceCategoriesToBeUpdated, extraAttributesToBeUpdated, onSave, valuesAreToBeUpdated ]);

  // The computer skills field default value
  const computerSkillsFieldDefaultValue = useMemo(() => {
    if (profile?.computerSkillsLevel === undefined) {
      return null;
    }
    return {
      label: t(`utilities:computer_skills_levels.${ profile.computerSkillsLevel }`),
      value: profile.computerSkillsLevel,
    };
  }, [ profile?.computerSkillsLevel, t ]);

  // The computer skills level select options
  const computerSkillsLevelSelectOptions = useMemo(() => {
    return [
      ComputerSkillsLevels.NO,
      ComputerSkillsLevels.BASIC,
      ComputerSkillsLevels.ADVANCED,
      ComputerSkillsLevels.EXPERT,
    ].map((computerSkillsLevel) => {
      return { label: t(`utilities:computer_skills_levels.${ computerSkillsLevel }`), value: computerSkillsLevel };
    });
  }, [ t ]);

  // The rendered computer skills select component
  const renderedComputerSkillsSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ computerSkillsLevelSelectOptions }
        placeholder={ t('job_seekers:profile_competencies.computer_skills_level_placeholder') }
      />
    );
  }, [ computerSkillsLevelSelectOptions, t ]);

  // The computer skills field
  const computerSkillsField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_competencies.computer_skills_level_label') }
          <Controller
            control={ control }
            defaultValue={ computerSkillsFieldDefaultValue }
            name='computerSkillsLevel'
            render={ renderedComputerSkillsSelect }
          />
        </label>
      </div>
    );
  }, [ computerSkillsFieldDefaultValue, control, renderedComputerSkillsSelect, t ]);

  // The driving license categories default value
  const drivingLicenseCategoriesDefaultValueField = useMemo(() => {
    if (profile?.drivingLicenseCategories === undefined) {
      return [];
    }
    return drivingLicenseCategories.filter((drivingLicense) => {
      return -1 !== profile.drivingLicenseCategories.indexOf(drivingLicense);
    }).map((drivingLicense) => {
      return {
        label: t(`utilities:driving_license_categories.${ drivingLicense }`),
        value: drivingLicense,
      };
    });
  }, [ profile?.drivingLicenseCategories, t ]);

  // Whether the driving license multi select is disabled
  const drivingLicenseMultiSelectIsDisabled = useCallback((field) => {
    return 4 <= field.value?.length;
  }, []);

  // The driving license categories options
  const drivingLicenseCategoriesOptions = useMemo(() => {
    return drivingLicenseCategories.map((drivingLicense) => {
      return { label: t(`utilities:driving_license_categories.${ drivingLicense }`), value: drivingLicense };
    });
  }, [ t ]);

  // The rendered driving license multi select component
  const renderedDrivingLicenseMultiSelect = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ drivingLicenseMultiSelectIsDisabled(field) }
        options={ drivingLicenseCategoriesOptions }
        placeholder={ t('job_seekers:profile_competencies.driving_license_categories_placeholder') }
      />
    );
  }, [ drivingLicenseCategoriesOptions, drivingLicenseMultiSelectIsDisabled, t ]);

  // The driving license categories field
  const drivingLicenseCategoriesField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_competencies.driving_license_categories_label') }
          <Controller
            control={ control }
            defaultValue={ drivingLicenseCategoriesDefaultValueField }
            name='drivingLicenseCategories'
            render={ renderedDrivingLicenseMultiSelect }
          />
        </label>
      </div>
    );
  }, [ control, drivingLicenseCategoriesDefaultValueField, renderedDrivingLicenseMultiSelect, t ]);

  // The authorized to work field
  const authorizedToWorkField = useMemo(() => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label className='inline'>
          <input
            className={
              clsx({
                'error': errors?.authorizedToWork,
              })
            }
            defaultChecked={ profile?.authorizedToWork }
            type='checkbox'
            { ...register('authorizedToWork') }
          />
          { t('job_seekers:profile_competencies.authorized_to_work_label') }
        </label>
      </div>
    );
  }, [ errors?.authorizedToWork, profile?.authorizedToWork, register, t, userRole ]);

  // Whether the datepicker is disabled
  const datePickerDisabled = useMemo(() => {
    return authorizedToWork !== undefined ? !authorizedToWork : !profile?.authorizedToWork;
  }, [ authorizedToWork, profile?.authorizedToWork ]);

  // The rendered datepicker for the authorized to work expiry field
  const renderedDatePicker = useCallback(({ field }) => {
    return (
      <DatePicker
        { ...field }
        disabled={ datePickerDisabled }
        placeholder={
          t('job_seekers:profile_competencies.work_authorization_expires_on_placeholder')
        }
        type='day-month-year'
      />
    );
  }, [ datePickerDisabled, t ]);

  // The authorized to work expiration default value
  const authorizedToWorkExpiryDefaultValue = useMemo(() => {
    if (profile?.workAuthorizationExpiresOn === undefined) {
      return null;
    }
    return DateTime.fromISO(profile.workAuthorizationExpiresOn).toJSDate();
  }, [ profile.workAuthorizationExpiresOn ]);

  // authorized to work expiry
  const authorizedToWorkExpiryField = useMemo(() => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_competencies.work_authorization_expires_on_label') }
          <Controller
            control={ control }
            defaultValue={ authorizedToWorkExpiryDefaultValue }
            name='workAuthorizationExpiresOn'
            render={ renderedDatePicker }
          />
        </label>
      </div>
    );
  }, [ authorizedToWorkExpiryDefaultValue, control, renderedDatePicker, t, userRole ]);

  // the work availability select options
  const workAvailabilityOptions = useMemo(() => {
    return [
      WorkAvailabilities.AVAILABLE_NOW,
      WorkAvailabilities.AVAILABLE_IN_LESS_THAN_A_MONTH,
      WorkAvailabilities.AVAILABLE_AFTER_A_MONTH,
      WorkAvailabilities.NOT_AVAILABLE,
    ].map((workAvailability) => {
      return { label: t(`utilities:work_availabilities.${ workAvailability }`), value: workAvailability };
    });
  }, [ t ]);

  // The rendered work availability select component
  const workAvailabilitySelectComponent = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ workAvailabilityOptions }
        placeholder={ t('job_seekers:profile_competencies.work_availability_placeholder') }
      />
    );
  }, [ t, workAvailabilityOptions ]);

  // The work availability default value
  const workAvailabilityDefaultValue = useMemo(() => {
    if (profile?.workAvailability === undefined) {
      return null;
    }
    return {
      label: t(`utilities:work_availabilities.${ profile.workAvailability }`),
      value: profile.workAvailability,
    };
  }, [ profile?.workAvailability, t ]);

  // the work availability field
  const workAvailabilityField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_competencies.work_availability_label') }
          <Controller
            control={ control }
            defaultValue={ workAvailabilityDefaultValue }
            name='workAvailability'
            render={ workAvailabilitySelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, t, workAvailabilityDefaultValue, workAvailabilitySelectComponent ]);

  // The willing to relocate field
  const willingToRelocateField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label className='inline'>
          <input
            className={
              clsx({
                'error': errors?.willingToRelocate,
              })
            }
            defaultChecked={ profile?.willingToRelocate }
            type='checkbox'
            { ...register('willingToRelocate') }
          />
          { t('job_seekers:profile_competencies.willing_to_relocate_label') }
        </label>
      </div>
    );
  }, [ errors?.willingToRelocate, profile?.willingToRelocate, register, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('job_seekers:profile_competencies.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { computerSkillsField }
        { drivingLicenseCategoriesField }
        { authorizedToWorkField }
        { authorizedToWorkExpiryField }
        { workAvailabilityField }
        { willingToRelocateField }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileCompetenciesEditor.displayName = 'ProfileCompetenciesEditor';

ProfileCompetenciesEditor.propTypes = {
  // The function (() => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((object) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // Whether the job seeker is authorized to work.
    authorizedToWork: PropTypes.bool,
    // The level of the computer skills that the job seeker has.
    computerSkillsLevel: PropTypes.oneOf(computerSkillsLevels),
    // The categories of the driving licenses that the job seeker has.
    drivingLicenseCategories: PropTypes.arrayOf(PropTypes.oneOf(drivingLicenseCategories)),
    // Whether the job seeker is willing to relocate.
    willingToRelocate: PropTypes.bool,
    // The date when the work authorization of the job seeker expires.
    workAuthorizationExpiresOn: PropTypes.string,
    // The work availability of the job seeker.
    workAvailability: PropTypes.oneOf(workAvailabilities),
  }),
  // The signed in user role
  userRole: PropTypes.oneOf(roles).isRequired,
};

ProfileCompetenciesEditor.defaultProps = {};

export default ProfileCompetenciesEditor;
