/**
 * The search form component
 */
import { joiResolver } from '@hookform/resolvers/joi';
import clsx from 'clsx';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select/select';
import Tags from 'components/common/tags/tags';
import magnifyingGlass from 'images/magnifying-glass.png';
import wavesImage from 'images/waves.png';
import roles, * as Roles from 'utilities/auth/roles';
import { areEqual, isEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import criterionTypes, * as CriterionTypes from 'utilities/criterion-types';

import './search-form.scss';

export const SearchForm = memo((props) => {
  const { onChange, jobSectors, criteria, authenticated, role, userRole, companyDashboard } = props;

  const { i18n, t } = useTranslation();

  // The validation schema.
  const schema = useMemo(() => {
    return Joi.object({
      formCriteria: Joi.array().min(1).max(8).items(Joi.object({
        type: Joi.string().valid(...criterionTypes).required(),
        value: Joi.string().required(),
      })).required(),
    });
  }, []);

  const { formState: { errors }, handleSubmit, register, reset, setValue, watch } = useForm({
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  // Function that updates the criteria based on the given values.
  const updateCriteria = useCallback((values) => {
    onChange(values.formCriteria);
  }, [ onChange ]);

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The form criteria.
  const formCriteria = watch('formCriteria');

  // Fill in the form with the criteria.
  useEffect(() => {
    const values = {};
    values.formCriteria = criteria;
    reset(values);
  }, [ criteria, reset ]);

  // The TERM criteria
  const termCriteria = useMemo(() => {
    return formCriteria?.filter((criterion) => {
      return CriterionTypes.TERM === criterion.type;
    });
  }, [ formCriteria ]);

  // The search form section title
  const searchFormTitle = useMemo(() => {
    if (companyDashboard) {
      return t('common:search_form.company_dashboard_title');
    }
    if (role === Roles.JOB_SEEKER) {
      return t('common:search_form.job_seekers_form_title');
    }
    return t('common:search_form.companies_form_title');
  }, [ companyDashboard, role, t ]);

  // whether the select component is disabled
  const selectDisabled = useMemo(() => {
    return 4 <= formCriteria?.length;
  }, [ formCriteria?.length ]);

  // Whether the select component is editable
  const selectIsEditable = useMemo(() => {
    return authenticated && role !== userRole && 1 > termCriteria?.length;
  }, [ authenticated, role, termCriteria?.length, userRole ]);

  // function that is invoked when Job sector select component is changed
  const handleJobSectorChange = useCallback((option) => {
    const { value } = option;
    const foundCriterion = formCriteria?.find((criterion) => {
      return value === criterion.value;
    });
    if (undefined !== foundCriterion) {
      return;
    }
    setValue('formCriteria', [
      ...(formCriteria || []),
      {
        type: true === option.__isNew__ ? CriterionTypes.TERM : CriterionTypes.JOB_SECTOR,
        value,
      },
    ]);
  }, [ formCriteria, setValue ]);

  // The options for the job sector select component
  const jobSectorsOptions = useMemo(() => {
    return jobSectors.filter((jobSector) => {
      const foundCriterion = formCriteria?.find((criterion) => {
        return jobSector.id === criterion.value;
      });
      return undefined === foundCriterion;
    }).map((jobSector) => {
      return {
        label: jobSector[selectedLanguage],
        value: jobSector.id,
      };
    });
  }, [ formCriteria, jobSectors, selectedLanguage ]);

  // function that is invoked when location/city select component is changed
  const handleLocationCityChange = useCallback((option) => {
    setValue('formCriteria', [
      ...(formCriteria || []),
      {
        type: CriterionTypes.CITY,
        value: option.value,
      },
    ]);
  }, [ formCriteria, setValue ]);

  // The options for the location/city Select component
  const citiesOptions = useMemo(() => {
    return allCities.filter((city) => {
      const foundCity = formCriteria?.find((criterion) => {
        return city === criterion.value;
      });
      return undefined === foundCity;
    }).map((city) => {
      return {
        label: t(`utilities:cities.${ city }`),
        value: city,
      };
    });
  }, [ formCriteria, t ]);

  // The error message
  const formErrorMessage = useMemo(() => {
    if (-1 === [ 'array.min' ].indexOf(errors.formCriteria?.type) || companyDashboard) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:search_form.at_least_one_criterion_required_error') }
      </span>
    );
  }, [ companyDashboard, errors.formCriteria?.type, t ]);

  // function invoked when deleting a tag
  const onTagDelete = useCallback((_tag, index) => {
    const filteredFormCriteria = formCriteria?.filter((_criterion, i) => {
      return index !== i;
    });
    setValue('formCriteria', filteredFormCriteria);
  }, [ formCriteria, setValue ]);

  // the tags to be rendered
  const tagsToBeRendered = useMemo(() => {
    return (formCriteria || []).map((criterion) => {
      let label = '';
      if (CriterionTypes.CITY === criterion.type) {
        label = `${ t('utilities:criterion_types.CITY') }: `
            + `${ t(`utilities:cities.${ criterion.value }`) }`;
      } else if (CriterionTypes.JOB_SECTOR === criterion.type) {
        const foundJobSector = jobSectors.find((s) => {
          return s.id === criterion.value;
        });
        label = foundJobSector
          ? `${ t('utilities:criterion_types.JOB_SECTOR') }: ${ foundJobSector[selectedLanguage] }`
          : '';
      } else if (CriterionTypes.TERM === criterion.type) {
        label = `${ t('utilities:criterion_types.TERM') }: ${ criterion.value }`;
      }
      return {
        label,
        value: criterion.value,
      };
    });
  }, [ formCriteria, jobSectors, selectedLanguage, t ]);

  // The tags
  const tags = useMemo(() => {
    if (0 === formCriteria?.length) {
      return null;
    }
    return (
      <Tags
        className='tags'
        moveable={ false }
        onDelete={ onTagDelete }
        tags={ tagsToBeRendered }
      />
    );
  }, [ formCriteria?.length, onTagDelete, tagsToBeRendered ]);

  // Function that is invoked when the form button is clicked only from the company dashboard
  const handleFormButtonClick = useCallback(() => {
    onChange(formCriteria);
  }, [ formCriteria, onChange ]);

  // Whether the submit button is disabled
  const submitButtonDisabled = useMemo(() => {
    return areEqual(criteria, formCriteria) && !isEmpty(formCriteria);
  }, [ criteria, formCriteria ]);

  // The form button
  const formButton = useMemo(() => {
    if (!companyDashboard) {
      return (
        <button
          className={
            clsx('btn btn-custom btn-rounded-sm', {
              'btn-red': role === Roles.JOB_SEEKER,
              'btn-yellow-dark': role === Roles.COMPANY_AGENT,
            })
          }
          disabled={ submitButtonDisabled }
          type='submit'
        >
          { t('common:search_form.search_button_label') }
        </button>
      );
    }
    return (
      <button
        className={
          clsx('btn btn-custom btn-rounded-sm', {
            'btn-red': role === Roles.JOB_SEEKER,
          })
        }
        onClick={ handleFormButtonClick }
        type='button'
      >
        { t('common:search_form.search_button_label') }
      </button>
    );
  }, [ companyDashboard, handleFormButtonClick, role, submitButtonDisabled, t ]);

  return (
    <div
      className={
        clsx('search-form', {
          'companies': role === Roles.COMPANY_AGENT,
          'company-dashboard': companyDashboard,
          'job-seekers': role === Roles.JOB_SEEKER,
        })
      }
    >
      <div className='hdg hdg-md light'>
        { searchFormTitle }
      </div>
      <div className='form dark'>
        <form
          noValidate
          onSubmit={ handleSubmit(updateCriteria) }
        >
          <div className='fields-and-button'>
            <Select
              disabled={ selectDisabled }
              editable={ selectIsEditable }
              icon= {
                <img alt='Search job sector' src={ magnifyingGlass } />
              }
              maxLength={ 32 }
              onChange={ handleJobSectorChange }
              options={ jobSectorsOptions }
              placeholder={ t('common:search_form.search_placeholder') }
              value={ null }
            />
            <Select
              disabled={ selectDisabled }
              icon= {
                <img alt='Search location city' src={ magnifyingGlass } />
              }
              onChange={ handleLocationCityChange }
              options={ citiesOptions }
              placeholder={ t('common:search_form.location_city_placeholder') }
              value={ null }
            />
            <input
              type='hidden'
              { ...register('formCriteria') }
            />
            { formButton }
          </div>
          { formErrorMessage }
        </form>
        { tags }
      </div>
      <img
        alt='waves design'
        className='waves-image'
        src={ wavesImage }
      />
    </div>
  );
});

SearchForm.propTypes = {
  // Whether the user is authenticated
  authenticated: PropTypes.bool.isRequired,
  // Whether the component is rendered in company agent dashboard
  companyDashboard: PropTypes.bool,
  // The search form criteria
  criteria: PropTypes.arrayOf(
    // The search criterion type
    PropTypes.shape({
      // The type of search criterion
      type: PropTypes.oneOf(criterionTypes).isRequired,
      // The value of search criterion
      value: PropTypes.string.isRequired,
    })
  ),
  // The job sectors array
  jobSectors: PropTypes.arrayOf(
    // The job sector type
    PropTypes.shape({
      // The greek translation of job sector
      el: PropTypes.string,
      // The english translation of job sector
      en: PropTypes.string,
      // The id of job sector
      id: PropTypes.string,
    }).isRequired,
  ).isRequired,
  // The function ((object[]) => void) to invoke when the criteria change.
  onChange: PropTypes.func,
  // The role based on what UI will be rendered
  role: PropTypes.oneOf(roles).isRequired,
  // The signed-in user role
  userRole: PropTypes.oneOf(roles),
};

SearchForm.defaultProps = {
  companyDashboard: false,
  criteria: [],
  jobSectors: [],
  onChange: () => {},
};

// The display name of the memoized component in the dev tools
SearchForm.displayName = 'SearchForm';

export default SearchForm;
