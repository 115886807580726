/**
 * Computer skills levels.
 *
 * @module utilities/computer-skills-levels
 */

/**
 * Advanced.
 *
 * @constant {string}
 * @static
 */
export const ADVANCED = 'ADVANCED';

/**
 * Basic.
 *
 * @constant {string}
 * @static
 */
export const BASIC = 'BASIC';

/**
 * Expert.
 *
 * @constant {string}
 * @static
 */
export const EXPERT = 'EXPERT';

/**
 * No knowledge.
 *
 * @constant {string}
 * @static
 */
export const NO = 'NO';

export default [
  ADVANCED,
  BASIC,
  EXPERT,
  NO,
];
