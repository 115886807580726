/**
 * English translations for common/search-filters.
 *
 * @module languages/en/common/search-filters
 */
const searchFilters = {
  clear_all_button_label: 'Clear all',
  filters: {
    computer_skills_level: {
      title: 'Computer skills',
    },
    driving_license_category: {
      title: 'Driving licenses',
    },
    experience_length: {
      title: 'Experience (in years)',
    },
    language: {
      title: 'Languages',
    },
    number_of_employees: {
      title: 'Number of Employees',
    },
    profession: {
      title: 'Jobs interested in',
    },
    profile_completion_percentage: {
      title: 'Profile completion (%)',
    },
    qualification_type: {
      title: 'Qualifications',
    },
    total_endorsements_range: {
      title: 'Number of Endorsements',
    },
    willing_to_relocate: {
      title: 'Relocation',
    },
    work_availability: {
      title: 'Work availability',
    },
  },
  filters_button_label: 'Filters',
  title: 'Filters',
};

export default searchFilters;
