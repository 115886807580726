/**
 * Settings - Account - Deactivate account.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Confirm from 'components/common/confirm/confirm';
import { actions as authActions } from 'ducks/auth';
import { actions as requestsActions } from 'ducks/requests';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';

import './deactivate-account.scss';

const DeactivateAccount = (_props) => {
  // Whether the signed-in user is deactivated.
  const userDeactivated = useSelector((store) => {
    return store.auth.deactivated;
  }, shallowEqual);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className='deactivate-account subsection'>
      <div className='title-and-text'>
        <div className='title'>
          {
            userDeactivated ? t('settings:account.deactivate_account.deactivated_title')
              : t('settings:account.deactivate_account.non_deactivated_title')
          }
        </div>
        <div className='text'>
          { t('settings:account.deactivate_account.instructions') }
        </div>
      </div>
      {
        userDeactivated && (
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            onClick={
              () => {
                dispatch(requestsActions.request(usersMethods.reactivateMe, null, {
                  onFailure: (_error) => {
                    toasts.error(t('settings:account.deactivate_account.account_reactivation_failed_error'));
                  },
                  onSuccess: (_result) => {
                    dispatch(authActions.setDeactivated(false));
                    toasts.info(t('settings:account.deactivate_account.account_reactivated_message'));
                  },
                }));
              }
            }
            type='button'
          >
            { t('settings:account.deactivate_account.reactivate_button_label') }
          </button>
        )
      }
      {
        !userDeactivated && (
          <Confirm
            className='deactivate-account-confirm-modal'
            onConfirm={
              () => {
                dispatch(requestsActions.request(usersMethods.deactivateMe, null, {
                  onFailure: (_error) => {
                    toasts.error(t('settings:account.deactivate_account.account_deactivation_failed_error'));
                  },
                  onSuccess: (_result) => {
                    dispatch(authActions.setDeactivated(true));
                    toasts.info(t('settings:account.deactivate_account.account_deactivated_message'));
                  },
                }));
              }
            }
            text={ t('settings:account.deactivate_account.deactivate_account_confirm_text') }
            trigger={
              <button
                className='btn btn-sm btn-rounded-sm btn-red'
                type='button'
              >
                { t('settings:account.deactivate_account.deactivate_button_label') }
              </button>
            }
          />
        )
      }
    </div>
  );
};

DeactivateAccount.propTypes = {
};

DeactivateAccount.defaultProps = {
};

export default DeactivateAccount;
