/**
 * English translations for endorsers/steps.
 *
 * @module languages/en/endorsers/steps
 */
const steps = {
  ABOUT_ME: 'Submit your short bio',
  ENDORSEMENTS: 'Complete your first endorsement',
  PERSONAL: 'Submit your personal details',
  PROFESSIONAL: 'Submit your professional details',
  PROFILE_PICTURE: 'Upload your profile picture',
};

export default steps;
