import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './learn-more-profile-features-cards-section.scss';
import LearnMoreProfileFeaturesCard from './learn-more-profile-features-card/learn-more-profile-features-card';

const LearnMoreProfileFeaturesCardsSection = memo((props) => {
  const { cardsCollection } = props;

  // The rendered cards
  const renderedCards = useMemo(() => {
    return cardsCollection.map((card) => {
      return (
        <LearnMoreProfileFeaturesCard
          description={ card.description }
          icon={ card.icon }
          key={ card.title }
          title={ card.title }
        />
      );
    });
  }, [ cardsCollection ]);

  return (
    <div className='learn-more-profile-features-card-section'>
      { renderedCards }
    </div>
  );
});

LearnMoreProfileFeaturesCardsSection.displayName = 'LearnMoreProfileFeaturesCardsSection';

LearnMoreProfileFeaturesCardsSection.propTypes = {
  // The collection to map the cards
  cardsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      // The card description
      description: PropTypes.string,
      // The icon of the card
      icon: PropTypes.node,
      // The title of the card
      title: PropTypes.string,
    })
  ).isRequired,
};

LearnMoreProfileFeaturesCardsSection.defaultProps = {};

export default LearnMoreProfileFeaturesCardsSection;
