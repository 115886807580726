/**
 * English translations for common/profile-background-image.
 *
 * @module languages/en/common/profile-background-image
 */
const profileBackgroundImage = {
  editor_title: 'Background image',
  save_button_label: 'Save',
};

export default profileBackgroundImage;
