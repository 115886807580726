/**
 * Multi-select.
 */
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo } from 'react';

import Select from 'components/common/select/select';
import Tags from 'components/common/tags/tags';

import './multi-select.scss';

const MultiSelect = forwardRef((props, ref) => {
  const { value, options, className, disabled, onChange, placeholder } = props;

  // The selected values.
  const values = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    }
    if (null === value || undefined === value) {
      return [];
    }
    return [ value ];
  }, [ value ]);

  // The options.
  const multiSelectOptions = useMemo(() => {
    return options.filter((option) => {
      return -1 === values.findIndex((v) => {
        return v.value === option.value;
      });
    });
  }, [ options, values ]);

  // function that is invoked when the select component is changed
  const onSelectChange = useCallback((value, { action }) => {
    switch (action) {
    case 'select-option':
      onChange?.([ ...values, value ]);
      break;
    default:
      break;
    }
  }, [ onChange, values ]);

  // function that is invoked when deleting a tag
  const onDeleteTags = useCallback((_option, index) => {
    const valuesFiltered = values.filter((value, i) => {
      return index !== i;
    });
    onChange?.(valuesFiltered);
  }, [ onChange, values ]);

  // function that is invoked when moving the tags with drag
  const onMoveTags = useCallback((option, currPos, newPos) => {
    const newValues = values.slice();
    newValues.splice(currPos, 1);
    newValues.splice(newPos, 0, option);
    onChange?.(newValues);
  }, [ onChange, values ]);

  return (
    <>
      <Select
        className={ className }
        disabled={ disabled || false }
        onChange={ onSelectChange }
        options={ multiSelectOptions }
        placeholder={ placeholder }
        ref={ ref }
        value={ null }
      />
      <Tags
        className='ody-multi-select'
        moveable
        onDelete={ onDeleteTags }
        onMove={ onMoveTags }
        tags={ values }
      />
    </>
  );
});

MultiSelect.propTypes = {
  // The className
  className: PropTypes.string,
  // Whether the multi-select is disabled.
  disabled: PropTypes.bool,
  // The function ((object) => void) to invoke when something is selected or unselected.
  onChange: PropTypes.func,
  // The available options.
  options: PropTypes.arrayOf(PropTypes.shape({
    // The label of the option.
    label: PropTypes.string.isRequired,
    // The value of the option.
    value: PropTypes.any.isRequired,
  }).isRequired).isRequired,
  // The placeholder.
  placeholder: PropTypes.string,
  // The selected options.
  value: PropTypes.arrayOf(PropTypes.shape({
    // The label of the option.
    label: PropTypes.string.isRequired,
    // The value of the option.
    value: PropTypes.any.isRequired,
  })),
};

MultiSelect.defaultProps = {
};

export default MultiSelect;
