/**
 * English translations for settings.
 *
 * @module languages/en/settings
 */
const settings = {
  account: {
    deactivate_account: {
      account_deactivated_message: 'Your account has been deactivated.',
      account_deactivation_failed_error: 'Account deactivation failed.',
      account_reactivated_message: 'Your account has been reactivated.',
      account_reactivation_failed_error: 'Account reactivation failed.',
      deactivate_account_confirm_text: 'Are you sure you want to deactivate your account?',
      deactivate_button_label: 'Deactivate',
      deactivated_title: 'Reactivate account',
      // eslint-disable-next-line max-len
      instructions: 'When your account is deactivated, no one else can see your profile and you cannot interact with any other Odyssea users.\n\nYou can reactivate your account at any moment.',
      non_deactivated_title: 'Deactivate account',
      reactivate_button_label: 'Reactivate',
    },
    email: {
      title: 'Email',
    },
    password: {
      bad_password_error: 'Current password is wrong.',
      current_password_label: 'Current password',
      current_password_placeholder: 'Current password',
      current_password_required_error: 'Current password is required.',
      editor_title: 'Password',
      // eslint-disable-next-line max-len
      new_password_and_new_password_confirmation_mismatch_error: 'New password and new password confirmation do not match.',
      new_password_confirmation_label: 'Confirm new password',
      new_password_confirmation_placeholder: 'Confirm new password',
      // eslint-disable-next-line max-len
      new_password_invalid_error: 'Password must contain:\nAt least 8 characters.\nAt least one digit.\nAt least one lowercase letter.\nAt least one uppercase letter.\nAt least one special character (!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~).',
      new_password_label: 'New password',
      new_password_placeholder: 'New password',
      new_password_required_error: 'New password is required.',
      password_changed_message: 'Your password was changed.',
      title: 'Password',
    },
    title: 'Account',
  },
  common: {
    cancel_button_label: 'Cancel',
    change_button_label: 'Change',
    general_error: 'Oops! Something went wrong.',
  },
  preferences: {
    language: {
      editor_title: 'Language',
      language_changed_message: 'Your language was changed.',
      language_label: 'Language',
      language_placeholder: 'Select the application language',
      language_required_error: 'Language is required.',
      title: 'Language',
    },
    title: 'Preferences',
  },
};

export default settings;
