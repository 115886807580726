/**
 * English translations for job-seekers/profile-endorsers-feedback.
 *
 * @module languages/en/job-seekers/profile-endorsers-feedback
 */
const profileEndorsersFeedback = {
  average: {
    communication_cooperation_skills_level_average_score_title: 'Communication & Cooperation',
    critical_thinking_level_average_score_title: 'Critical Thinking',
    motivation_level_average_score_title: 'Motivation',
    professional_characteristics_average_score_title: 'Professionalism',
  },
  card: {
    personality_qualities_title: 'Personality qualities',
    testimonial_title: 'Testimonials',
  },
  request_endorsement_button_title: 'Request Endorsement',
  request_endorsement_confirm: 'Are you sure you want to request an endorsement?',
  title: 'Endorser Feedback',
};

export default profileEndorsersFeedback;
