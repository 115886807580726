/**
 * English translations for utilities/endorsers/sort-keys.
 *
 * @module languages/en/utilities/endorsers/sort-keys
 */
const sortKeys = {
  LAST_MODIFIED_AT: 'Last updated',
  LAST_NAME: 'Last name',
  TOTAL_ENDORSEMENTS: 'Total endorsements',
};

export default sortKeys;
