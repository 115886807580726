/**
 * Entry point.
 */
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'components/app/app';
import webVitals from 'helpers/web-vitals';
import { DEFAULT_LANGUAGE } from 'i18n';
import el from 'languages/el';
import en from 'languages/en';
import { persistor, store } from 'store';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'styles/styles.scss';

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    el,
    en,
  },
});

const element = (
  <React.StrictMode>
    <Provider store={ store }>
      { /* Do not render the app, until the persisted state has been loaded. */ }
      <PersistGate loading={ null } persistor={ persistor }>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
const container = document.getElementById('root');
ReactDOM.render(element, container);

// Log web vitals.
// eslint-disable-next-line no-console
webVitals.configure(console.log);
