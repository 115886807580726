/**
 * Resource method to show pricing interest in a CSR package.
 *
 * @module resources/companies/csr-pricing-interest
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/pricing-interest~Params
 * @type {object}
 * @property {('TAILOR_MADE_TRAINING' | 'EMPLOYABILITY_SUPPORT' | 'RELIEF_INTERVENTIONS')} csrPricingPlan -
 * The csr pricing plan that the company agent is interested in.
 */

/**
 * Declares the csr package pricing interest that a company has.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/csr-pricing-interest~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const showCsrPricingInterest = (params) => {
  return axios.request({
    data: params,
    method: 'post',
    url: '/companies/csr-pricing/interest',
  }).then((response) => {
    return response.data;
  });
};

export default showCsrPricingInterest;
