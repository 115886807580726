/**
 * English translations for common/search-total.
 *
 * @module languages/en/common/search-total
 */
const searchTotal = {
  companies_found_one: '{{count}} company found',
  companies_found_other: '{{count}} companies found',
  endorsers_found_one: '{{count}} endorser found',
  endorsers_found_other: '{{count}} endorsers found',
  job_seekers_found_one: '{{count}} job seeker found',
  job_seekers_found_other: '{{count}} job seekers found',
};

export default searchTotal;
