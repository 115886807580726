/**
 * English translations for the common.
 *
 * @module languages/en/common
 */
import confirm from './confirm';
import contactButton from './contact-button';
import dashboardContactShareSection from './dashboard-contact-share-section';
import dashboardStatistics from './dashboard-statistics';
import filePreview from './file-preview';
import optionFilter from './option-filter';
import profileAbout from './profile-about';
import profileBackgroundImage from './profile-background-image';
import profileCompletion from './profile-completion';
import profileContactDetails from './profile-contact-details';
import profileDetailsSection from './profile-details-section';
import profileImage from './profile-image';
import profileJobSectorsInterestedIn from './profile-job-sectors-interested-in';
import profileOverview from './profile-overview';
import profileProfessions from './profile-professions';
import rangeFilter from './range-filter';
import searchFilters from './search-filters';
import searchForm from './search-form';
import searchResults from './search-results';
import searchSort from './search-sort';
import searchTotal from './search-total';
import sidebarProfileCompletion from './sidebar-profile-completion';
import videosAndGuides from './videos-and-guides';

const common = {
  confirm,
  contact_button: contactButton,
  dashboard_contact_share_section: dashboardContactShareSection,
  dashboard_statistics: dashboardStatistics,
  file_preview: filePreview,
  option_filter: optionFilter,
  profile_about: profileAbout,
  profile_background_image: profileBackgroundImage,
  profile_completion: profileCompletion,
  profile_contact_details: profileContactDetails,
  profile_details_section: profileDetailsSection,
  profile_image: profileImage,
  profile_job_sectors_interested_in: profileJobSectorsInterestedIn,
  profile_overview: profileOverview,
  profile_professions: profileProfessions,
  range_filter: rangeFilter,
  search_filters: searchFilters,
  search_form: searchForm,
  search_results: searchResults,
  search_sort: searchSort,
  search_total: searchTotal,
  sidebar_profile_completion: sidebarProfileCompletion,
  videos_and_guides: videosAndGuides,
};

export default common;
