import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import HomeFindTalentAnimationIcon from 'animations/lens.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './home-find-talent-section.scss';

const HomeFindTalentSection = memo(() => {
  const { t } = useTranslation();

  return (
    <div className='home-find-talent-section'>
      <div className='home-find-talent-section__info-container'>
        <h4 className='hdg hdg-xxl home-find-talent-section__title'>
          { t('home:find_talent_section.title') }
        </h4>
        <p className='txt txt-lg home-find-talent-section__info'>
          { t('home:find_talent_section.info') }
        </p>
        <Link
          className='btn btn-sm txt txt-bold btn-blue btn-rounded-sm home-find-talent-section__explore-button'
          to='/job-seekers/search'
        >
          { t('home:find_talent_section.button_label') }
        </Link>
      </div>
      <LottieAnimationContainer
        animatedIconClassName='home-find-talent-section__animated-icon'
        animationData={ HomeFindTalentAnimationIcon }
        className='home-find-talent-section__animation-container'
      />
    </div>
  );
});

HomeFindTalentSection.displayName = 'HomeFindTalentSection';

HomeFindTalentSection.propTypes = {};

HomeFindTalentSection.defaultProps = {};

export default HomeFindTalentSection;
