/**
 * Duck for job sectors.
 *
 * @module ducks/job-sectors
 */

/**
 * The type of actions to describe that the job sectors were set.
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SECTORS_SET = 'JOB_SECTORS_SET';

/**
 * Creates an action to set the job sectors.
 *
 * @param {module:types/job-sectors~JobSector[]} jobSectors - The job sectors.
 * @returns {object} - The action.
 * @static
 */
const setJobSectors = (jobSectors) => {
  return {
    jobSectors,
    type: JOB_SECTORS_SET,
  };
};

/**
 * Job sectors state.
 *
 * @typedef module:ducks/job-sectors~State
 * @type {object}
 * @property {module:types/job-sectors~JobSector[]} jobSectors - The different job sectors.
 */

/**
 * The initial job sectors state.
 *
 * @type {module:ducks/job-sectors~State}
 * @static
 */
const initialState = {
  jobSectors: [],
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/job-sectors~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/job-sectors~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case JOB_SECTORS_SET:
    return {
      ...state,
      jobSectors: action.jobSectors,
    };
  default:
    return state;
  }
};

const actions = {
  setJobSectors,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
