import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import ArrowsUpGroupIcon from 'components/common/icons/arrows-up-group/arrows-up-group';

import './about-us-matching-problem-section.scss';

const AboutUsMatchingProblemSection = memo((props) => {
  const { title, matchingTitle, problems } = props;

  // The rendered problems
  const renderedProblems = useMemo(() => {
    return problems.map((problem, problemIndex) => {
      return (
        <div
          className='about-us-matching-problem-section__problem'
          key={ problem.title + problemIndex }
        >
          <div className='about-us-matching-problem-section__icon-container'>
            <ArrowsUpGroupIcon
              isDark={ 1 === problemIndex }
            />
          </div>
          <div className='about-us-matching-problem-section__problem-info'>
            <h4 className='hdg hdg-md about-us-matching-problem-section__problem-title'>
              { problem.title }
            </h4>
            <p className='txt txt-md about-us-matching-problem-section__problem-description'>
              { problem.description }
            </p>
          </div>
        </div>
      );
    });
  }, [ problems ]);

  return (
    <div className='about-us-matching-problem-section'>
      <h3 className='hdg hdg-lg about-us-matching-problem-section__title'>
        { title }
      </h3>
      <div className='about-us-matching-problem-section__problems-container'>
        { renderedProblems }
      </div>
      <h2 className='hdg hdg-xxl about-us-matching-problem-section__matching-problem-title'>
        { matchingTitle }
      </h2>
    </div>
  );
});

AboutUsMatchingProblemSection.displayName = 'AboutUsMatchingProblemSection';

AboutUsMatchingProblemSection.propTypes = {
  // The matching title
  matchingTitle: PropTypes.string.isRequired,
  // The problems array
  problems: PropTypes.arrayOf(
    PropTypes.shape({
      // The description of the problem
      description: PropTypes.string,
      // Whether is the problem dark color
      isDark: PropTypes.bool,
      // the title of the problem
      title: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

export default AboutUsMatchingProblemSection;
