/**
 * Greek translations for common/videos-and-guides.
 *
 * @module languages/el/common/videos-and-guides
 */
const videosAndGuides = {
  title: 'Βίντεο & Οδηγοί',
};

export default videosAndGuides;
