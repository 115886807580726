/**
 * Markdown.
 */
import PropTypes from 'prop-types';
import React from 'react';
import BaseMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './markdown.scss';

const Markdown = (props) => {
  return (
    <BaseMarkdown
      allowedElements={
        [
          'a',
          'h1',
          'h2',
          'h3',
          'li',
          'p',
          'strong',
          'table',
          'tbody',
          'td',
          'th',
          'thead',
          'tr',
          'ul',
        ]
      }
      className='ody-markdown'
      linkTarget='_blank'
      remarkPlugins={ [ remarkGfm ] }
    >
      { props.children }
    </BaseMarkdown>
  );
};

Markdown.propTypes = {
  // The markdown.
  children: PropTypes.node.isRequired,
};

Markdown.defaultProps = {
};

export default Markdown;
