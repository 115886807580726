import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import NextFabFoundation from 'images/companies/netx-fab-foundation.png';

import AboutUsHowDoesItWorkSection from './about-us-how-does-it-work-section/about-us-how-does-it-work-section';
import AboutUsMatchingProblemSection from './about-us-matching-problem-section/about-us-matching-problem-section';
import AboutUsMilestonesSection from './about-us-milestones-section/about-us-milestones-section';
import AboutUsMissionVisionSection from './about-us-mission-vision-section/about-us-mission-vision-section';
import AboutUsOurSupportersSection from './about-us-our-supporters-section/about-us-our-supporters-section';
import AboutUsSolutionSection from './about-us-solution-section/about-us-solution-section';
import AboutUsTargetGroupSection from './about-us-target-group-section/about-us-target-group-section';
import AboutUsTeamSection from './about-us-team-section/about-us-team-section';
import AboutUsUnemploymentSection from './about-us-unemployment-section/about-us-unemployment-section';
import AboutUsWelcomeSection from './about-us-welcome-section/about-us-welcome-section';
import CEOImage from './images/team/ceo.png';
import CTOImage from './images/team/cto.png';
import EmployabilityManagerImage from './images/team/employability-manager.jpeg';
import EmployabilityOfficerImage from './images/team/employability-officer.jpeg';
import FieldOfficerImage from './images/team/field-officer.jpeg';
import FinanceManagerImage from './images/team/finance-manager.png';
import ProgramManagerImage from './images/team/program-manager.png';

import './about-us.scss';

const AboutUs = () => {
  // The metrics state object
  const metrics = useSelector((store) => {
    return store.metrics;
  }, shallowEqual);

  const { t } = useTranslation();

  // The key metrics
  const keyMetrics = useMemo(() => {
    return [
      {
        label: t('about_us:welcome_section.key_metrics.item1.label'),
        number: metrics?.organizationMetrics?.registeredPerYear,
      },
      {
        label: t('about_us:welcome_section.key_metrics.item2.label'),
        number: metrics?.organizationMetrics?.studentsPerYear,
      },
      {
        label: t('about_us:welcome_section.key_metrics.item3.label'),
        number: metrics?.organizationMetrics?.employabilityServicesPerYear,
      },
      {
        label: t('about_us:welcome_section.key_metrics.item4.label'),
        number: metrics?.organizationMetrics?.findEmployabilityPerYear,
      },
      {
        label: t('about_us:welcome_section.key_metrics.item5.label'),
        number: metrics?.organizationMetrics?.collaboratingCompanies,
      },
    ];
  }, [
    metrics?.organizationMetrics?.collaboratingCompanies,
    metrics?.organizationMetrics?.employabilityServicesPerYear,
    metrics?.organizationMetrics?.findEmployabilityPerYear,
    metrics?.organizationMetrics?.registeredPerYear,
    metrics?.organizationMetrics?.studentsPerYear,
    t,
  ]);

  // The problems collection
  const problemsCollection = useMemo(() => {
    return [
      {
        description: t('about_us:matching_problem_section.problems.item1.description'),
        title: t('about_us:matching_problem_section.problems.item1.title'),
      },
      {
        description: t('about_us:matching_problem_section.problems.item2.description'),
        isDark: true,
        title: t('about_us:matching_problem_section.problems.item2.title'),
      },
    ];
  }, [ t ]);

  // The unemployment info collection
  const unemploymentInfoCollection = useMemo(() => {
    return [
      t('about_us:unemployment_section.info.item1'),
      t('about_us:unemployment_section.info.item2'),
      t('about_us:unemployment_section.info.item3'),
      t('about_us:unemployment_section.info.item4'),
    ];
  }, [ t ]);

  // The target group cards
  const targetGroupCards = useMemo(() => {
    return [
      {
        text: t('about_us:target_group_section.cards.item1'),
      },
      {
        image: <OdysseaLogo />,
      },
      {
        text: t('about_us:target_group_section.cards.item2'),
      },
    ];
  }, [ t ]);

  // The how does it work steps
  const howDoesItWorkSteps = useMemo(() => {
    return [
      {
        description: t('about_us:how_does_it_work_section.steps.item1.description'),
        title: t('about_us:how_does_it_work_section.steps.item1.title'),
      },
      {
        description: t('about_us:how_does_it_work_section.steps.item2.description'),
        title: t('about_us:how_does_it_work_section.steps.item2.title'),
      },
      {
        description: t('about_us:how_does_it_work_section.steps.item3.description'),
        title: t('about_us:how_does_it_work_section.steps.item3.title'),
      },
    ];
  }, [ t ]);

  // The supporters collection
  const supportersCollection = useMemo(() => {
    return [ NextFabFoundation ];
  }, []);

  // The team members
  const teamMembers = useMemo(() => {
    return [
      {
        imageSource: CEOImage,
        jobTitle: t('about_us:team_section.team_members.member1.job_title'),
        name: t('about_us:team_section.team_members.member1.name'),
      },
      {
        imageSource: CTOImage,
        jobTitle: t('about_us:team_section.team_members.member2.job_title'),
        name: t('about_us:team_section.team_members.member2.name'),
      },
      {
        imageSource: FinanceManagerImage,
        jobTitle: t('about_us:team_section.team_members.member3.job_title'),
        name: t('about_us:team_section.team_members.member3.name'),
      },
      {
        imageSource: ProgramManagerImage,
        jobTitle: t('about_us:team_section.team_members.member4.job_title'),
        name: t('about_us:team_section.team_members.member4.name'),
      },
      {
        imageSource: EmployabilityManagerImage,
        jobTitle: t('about_us:team_section.team_members.member5.job_title'),
        name: t('about_us:team_section.team_members.member5.name'),
      },
      {
        imageSource: EmployabilityOfficerImage,
        jobTitle: t('about_us:team_section.team_members.member6.job_title'),
        name: t('about_us:team_section.team_members.member6.name'),
      },
      {
        imageSource: FieldOfficerImage,
        jobTitle: t('about_us:team_section.team_members.member7.job_title'),
        name: t('about_us:team_section.team_members.member7.name'),
      },
    ];
  }, [ t ]);

  // The milestones
  const milestones = useMemo(() => {
    return [
      {
        month: t('about_us:milestones_section.months.item1'),
        quarter: {
          description: t('about_us:milestones_section.milestones.item1.description'),
          title: t('about_us:milestones_section.milestones.item1.title'),
        },
      },
      {
        month: t('about_us:milestones_section.months.item2'),
      },
      {
        month: t('about_us:milestones_section.months.item3'),
      },
      {
        month: t('about_us:milestones_section.months.item4'),
        quarter: {
          description: t('about_us:milestones_section.milestones.item2.description'),
          title: t('about_us:milestones_section.milestones.item2.title'),
        },
      },
      {
        month: t('about_us:milestones_section.months.item5'),
      },
      {
        month: t('about_us:milestones_section.months.item6'),
      },
      {
        month: t('about_us:milestones_section.months.item7'),
        quarter: {
          description: t('about_us:milestones_section.milestones.item3.description'),
          title: t('about_us:milestones_section.milestones.item3.title'),
        },
      },
      {
        month: t('about_us:milestones_section.months.item8'),
      },
      {
        month: t('about_us:milestones_section.months.item9'),
      },
      {
        month: t('about_us:milestones_section.months.item10'),
        quarter: {
          description: t('about_us:milestones_section.milestones.item4.description'),
          title: t('about_us:milestones_section.milestones.item4.title'),
        },
      },
      {
        month: t('about_us:milestones_section.months.item11'),
      },
      {
        month: t('about_us:milestones_section.months.item12'),
      },
    ];
  }, [ t ]);

  return (
    <div className='about-us'>
      <AboutUsWelcomeSection
        buttonLabel={ t('about_us:welcome_section.button_label') }
        description={ t('about_us:welcome_section.description') }
        keyMetrics={ keyMetrics }
        keyMetricsTitle={ t('about_us:welcome_section.key_metrics_title') }
        title={ t('about_us:welcome_section.title') }
      />
      <AboutUsMatchingProblemSection
        matchingTitle={ t('about_us:matching_problem_section.matching_title') }
        problems={ problemsCollection }
        title={ t('about_us:matching_problem_section.title') }
      />
      <AboutUsUnemploymentSection
        infoCollection={ unemploymentInfoCollection }
        title={ t('about_us:unemployment_section.title') }
      />
      <AboutUsSolutionSection
        description={ t('about_us:solution_section.description') }
        title={ t('about_us:solution_section.title') }
      />
      <AboutUsTargetGroupSection
        cards={ targetGroupCards }
        title={ t('about_us:target_group_section.title') }
      />
      <AboutUsHowDoesItWorkSection
        steps={ howDoesItWorkSteps }
        title={ t('about_us:how_does_it_work_section.title') }
      />
      <AboutUsMilestonesSection
        milestones={ milestones }
        title={ t('about_us:milestones_section.title') }
      />
      <AboutUsOurSupportersSection
        supporters={ supportersCollection }
        title={ t('about_us:our_supporters_section.title') }
      />
      <AboutUsMissionVisionSection
        missionDescription={ t('about_us:vision_mission_section.mission_description') }
        missionTitle={ t('about_us:vision_mission_section.mission_title') }
        title={ t('about_us:vision_mission_section.title') }
        visionDescription={ t('about_us:vision_mission_section.vision_description') }
        visionTitle={ t('about_us:vision_mission_section.vision_title') }
      />
      <AboutUsTeamSection
        members={ teamMembers }
        title={ t('about_us:team_section.title') }
      />
    </div>
  );
};

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
