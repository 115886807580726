import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'components/common/carousel/carousel';
import { useSm, useXs } from 'hooks/media-queries';
import useElementOnScreen from 'hooks/use-element-on-screen';
import allCities from 'utilities/cities';
import endorsementStatuses from 'utilities/endorsement-status';

import LearnMoreOurEndorsersCard from './learn-more-our-endorsers-card/learn-more-our-endorsers-card';

import './learn-more-our-endorsers-section.scss';

const LearnMoreOurEndorsersSection = memo((props) => {
  const { title, endorsersProfiles, buttonLabel } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  const isSm = useSm();

  const isXs = useXs();

  // whether we are on small screens
  const smallScreens = useMemo(() => {
    return isSm || isXs;
  }, [ isSm, isXs ]);

  // The rendered endorsers
  const renderedEndorsers = useMemo(() => {
    return endorsersProfiles.map((endorser, index) => {
      return (
        <LearnMoreOurEndorsersCard
          companyName={ endorser?.companyName }
          companyPosition={ endorser?.companyPosition }
          firstName={ endorser?.firstName }
          key={ endorser?.firstName + endorser?.lastName + index }
          lastName={ endorser?.lastName }
          profileId={ endorser.id }
          profilePicture={ endorser.profilePicture }
        />
      );
    });
  }, [ endorsersProfiles ]);

  // The rendered content
  const renderedContent = useMemo(() => {
    if (!smallScreens) {
      return (
        <div className='learn-more-our-endorsers-section__cards-container'>
          { renderedEndorsers }
        </div>
      );
    }
    return (
      <Carousel
        autoplay={ isVisible }
        infiniteLoop
      >
        { renderedEndorsers }
      </Carousel>
    );
  }, [ isVisible, renderedEndorsers, smallScreens ]);

  return (
    <div
      className='learn-more-our-endorsers-section'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl learn-more-our-endorsers-section__title'>
        { title }
      </h2>
      { renderedContent }
      <div className='learn-more-our-endorsers-section__button-container'>
        <Link
          className='btn btn-sm btn-purple btn-rounded-sm learn-more-our-endorsers-section__button'
          to='/endorsers/search'
        >
          { buttonLabel }
        </Link>
      </div>
    </div>
  );
});

LearnMoreOurEndorsersSection.displayName = 'LearnMoreOurEndorsersSection';

LearnMoreOurEndorsersSection.propTypes = {
  // The button label of the section
  buttonLabel: PropTypes.string.isRequired,
  endorsersProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      // The endorser company name
      companyName: PropTypes.string,
      // The endorser company position
      companyPosition: PropTypes.string,
      // The endorsements viewed by companies
      endorsementsViewsByCompanies: PropTypes.number,
      // The first name
      firstName: PropTypes.string,
      // Whether it is hidden
      hidden: PropTypes.bool,
      // The id of the endorser
      id: PropTypes.string,
      // The last name
      lastName: PropTypes.string,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The mobile phone number
      mobilePhoneNumber: PropTypes.string,
      // The pending endorsement requests
      pendingEndorsementRequests: PropTypes.arrayOf(
        // The endorsement request
        PropTypes.shape({
          // The profile of the endorsee
          endorseeProfile: PropTypes.shape({
            // The first name of the endorsee
            firstName: PropTypes.string,
            // The id of the endorsee
            id: PropTypes.string,
            // The last name of the endorsee
            lastName: PropTypes.string,
            // The location where the endorsee is.
            location: PropTypes.shape({
              // The city.
              city: PropTypes.oneOf(allCities),
              // The district.
              district: PropTypes.string,
            }),
            // The profile picture of the endorsee.
            profilePicture: PropTypes.shape({
              // The ID of the picture.
              id: PropTypes.string,
              // The MIME type of the picture.
              type: PropTypes.string,
            }),
            // The short bio of the endorsee
            shortBio: PropTypes.string,
          }),
          // the id of the endorsement
          id: PropTypes.string,
          // The status of the endorsements in the section
          status: PropTypes.oneOf(endorsementStatuses),
        }),
      ),
      // The profile picture of the job seeker.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string.isRequired,
        // The MIME type of the picture.
        type: PropTypes.string.isRequired,
      }),
      // The short bio of the job seeker.
      shortBio: PropTypes.string,
      // The total endorsements
      totalEndorsements: PropTypes.number,
      // The website
      website: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

LearnMoreOurEndorsersSection.defaultProps = {};

export default LearnMoreOurEndorsersSection;
