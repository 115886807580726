/**
 * Greek translations for job-seekers/profile-languages.
 *
 * @module languages/el/job-seekers/profile-languages
 */
const profileLanguages = {
  editor_title: 'Γλώσσες',
  english_level_placeholder: 'Επιλέξτε το επίπεδο των αγγλικών σας',
  greek_level_placeholder: 'Επιλέξτε το επίπεδο των ελληνικών σας',
  other: 'Άλλο',
  other_languages_label: 'Άλλο',
  other_languages_placeholder: 'Επιλέξτε άλλες γλώσσες που μιλάτε',
  title: 'Γλώσσες',
};

export default profileLanguages;
