/**
 * Filter types.
 *
 * @module utilities/companies/interaction-event-type
 */

/**
 * profile viewed.
 *
 * @constant {string}
 * @static
 */
export const VIEWED = 'VIEWED';

/**
 * profile interviewed.
 *
 * @constant {string}
 * @static
 */
export const INTERVIEWED = 'INTERVIEWED';

/**
 * profile hired.
 *
 * @constant {string}
 * @static
 */
export const HIRED = 'HIRED';

export default [
  VIEWED,
  INTERVIEWED,
  HIRED,
];
