/**
 * Date picker.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import BaseDatePicker from 'react-datepicker';

import './date-picker.scss';

const DatePicker = forwardRef((props, ref) => {
  // The date formats per date picker type.
  const DATE_FORMATS = {
    'day-month-year': 'dd MMM yyyy',
    'month-year': 'MMM yyyy',
  };

  const extraProps = 'month-year' === props.type && { showMonthYearPicker: true };

  return (
    <BaseDatePicker
      className={ clsx('ody-date-picker-input', props.className) }
      dateFormat={ DATE_FORMATS[props.type] }
      disabled={ props.disabled }
      fixedHeight
      onChange={ props.onChange }
      placeholderText={ props.placeholder }
      popperClassName='ody-date-picker-popper'
      ref={ ref }
      selected={ props.value }
      showPopperArrow={ false }
      wrapperClassName='ody-date-picker-wrapper'
      { ...extraProps }
    />
  );
});

DatePicker.propTypes = {
  // The class(es) to apply to the date picker.
  className: PropTypes.string,
  // Whether the date picker is disabled.
  disabled: PropTypes.bool,
  // The function ((object) => void) to invoke when a date is selected.
  onChange: PropTypes.func,
  // The placeholder.
  placeholder: PropTypes.string,
  // The type of the date picker.
  type: PropTypes.oneOf([ 'day-month-year', 'month-year' ]).isRequired,
  // The selected date.
  value: PropTypes.instanceOf(Date),
};

DatePicker.defaultProps = {
  disabled: false,
};

export default DatePicker;
