/**
 * Resource method to reactivate the user that is currently signed-in.
 *
 * @module resources/users/reactivate-me
 */
import axios from 'axios';

/**
 * Reactivates the user that is currently signed-in.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<void>} - The promise.
 * @static
 */
const reactivateMe = () => {
  return axios.request({
    method: 'post',
    url: '/users/me/reactivate',
  }).then((response) => {
    return response.data;
  });
};

export default reactivateMe;
