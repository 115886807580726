/**
 * The critical thinking levels
 *
 * @module utilities/endorsement/critical-thinking-levels
 */

/**
 * Slow responsiveness.
 *
 * @constant {string}
 * @static
 */
export const SLOW_RESPONSIVENESS = 'SLOW_RESPONSIVENESS';

/**
 * Understanding.
 *
 * @constant {string}
 * @static
 */
export const UNDERSTANDING = 'UNDERSTANDING';

/**
 * Need Guidance.
 *
 * @constant {string}
 * @static
 */
export const NEED_GUIDANCE = 'NEED_GUIDANCE';

/**
 * Nice responsiveness.
 *
 * @constant {string}
 * @static
 */
export const NICE_RESPONSIVENESS = 'NICE_RESPONSIVENESS';

/**
 * Fast Responsiveness.
 *
 * @constant {string}
 * @static
 */
export const FAST_RESPONSIVENESS = 'FAST_RESPONSIVENESS';

export default [
  SLOW_RESPONSIVENESS,
  UNDERSTANDING,
  NEED_GUIDANCE,
  NICE_RESPONSIVENESS,
  FAST_RESPONSIVENESS,
];
