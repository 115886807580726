/**
 * Greek translations for sign-in-form.
 *
 * @module languages/el/sign-in-form
 */
const signInForm = {
  bad_credentials_error: 'Το email και/ή ο κωδικός πρόσβασης είναι λάθος.',
  continue_with_google_button_label: 'Συνδεθείτε με Google',
  continue_with_linkedin_button_label: 'Συνδεθείτε με το LinkedIn',
  dont_have_account: 'Δεν έχετε λογαριασμό;',
  email_invalid_error: 'Εισάγετε μια έγκυρη διεύθυνση email.',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Το πεδίο Email είναι απαραίτητο',
  forgot_password_link_text: 'Ξεχάσατε τον κωδικό σας',
  general_error: 'Ωχ! Κάτι πήγε στραβά.',
  locked_account_error: 'Ο λογαριασμός σας είναι κλειδωμένος. Παρακαλώ προσπάθησε ξανά αργότερα.',
  // eslint-disable-next-line max-len
  new_account_error: 'Δεν έχετε ενεργοποιήσει ακόμα τον λογαριασμό σας. Ελέγξτε τα εισερχόμενα σας ή επικοινωνήστε με την Odyssea για βοήθεια.',
  or: 'Ή',
  password_label: 'Κωδικός πρόσβασης',
  password_placeholder: 'Κωδικός πρόσβασης',
  password_required_error: 'Το πεδίο κωδικός πρόσβασης είναι απαραίτητο',
  rejected_account_error: 'Η αίτηση σας για τη δημιουργία λογαριασμού απορρίφθηκε.',
  retrieve_google_profile_error: 'Απέτυχε η ανάκτηση των στοιχείων του χρήστη μέσω της Google',
  retrieve_linkedin_profile_error: 'Απέτυχε η ανάκτηση των στοιχείων του χρήστη μέσω του LinkedIn',
  sign_in_button_label: 'Συνδεθείτε',
  sign_up_for_free_link_text: 'Εγγραφείτε δωρεάν',
  suspended_account_error: 'Ο λογαριασμός σας έχει απενεργοποιηθεί.',
  user_not_found_error: 'Φαίνεται ότι δεν έχετε λογαριασμό στην πλατφόρμα. Πρέπει πρώτα να εγγραφείτε.',
  verified_account_error: 'Η αίτηση σας για τη δημιουργία λογαριασμού εκκρεμεί.',
  // eslint-disable-next-line max-len
  verify_with_google_error: 'Κάτι πήγε στραβά κατά την επαλήθευση με την Google. Ανανεώστε το πρόγραμμα περιήγησής σας και δοκιμάστε ξανά.',
  // eslint-disable-next-line max-len
  verify_with_linkedin_error: 'Κάτι πήγε στραβά κατά την επαλήθευση με τo LinkedIn. Ανανεώστε το πρόγραμμα περιήγησής σας και δοκιμάστε ξανά.',
};

export default signInForm;
