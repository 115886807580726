/**
 * Resource method to update the language of the user that is currently signed-in.
 *
 * @module resources/users/update-my-language
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/users/update-my-language~Params
 * @type {object}
 * @property {string} language - The ISO 639-1 alpha-2 code of the language.
 */

/**
 * Result.
 *
 * @typedef module:resources/users/update-my-language~Result
 * @type {object}
 * @property {string} language - The ISO 639-1 alpha-2 code of the language.
 */

/**
 * Updates the language of the user that is currently signed-in.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/users/update-my-language~Params} params - The parameters.
 * @returns {Promise<module:resources/users/update-my-language~Result>} - The promise.
 * @static
 */
const updateLanguage = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: '/users/me/language',
  }).then((response) => {
    return response.data;
  });
};

export default updateLanguage;
