import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import Location from 'images/location-white.png';
import { isEmpty, isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';

import './endorsee-profile-information.scss';

const EndorseeProfileInformation = memo((props) => {
  const { endorseeProfile } = props;

  const { t } = useTranslation();

  // The profile image
  const profileImage = useMemo(() => {
    if (endorseeProfile === undefined) {
      return null;
    }
    if (endorseeProfile?.profilePicture === undefined) {
      return (
        <CircularImage
          alt='Default Profile Picture'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='Profile Picture'
        imageSource={
          `${ env.CORE_BASE_URL }/job-seekers/${ endorseeProfile?.id }
          /profile/picture?id=${ endorseeProfile?.profilePicture?.id }`
        }
      />
    );
  }, [ endorseeProfile ]);

  // The short bio
  const shortBio = useMemo(() => {
    if (!endorseeProfile?.shortBio) {
      return null;
    }
    return (
      <p className='endorsee-profile-information__short-bio txt txt-sm'>
        { endorseeProfile?.shortBio }
      </p>
    );
  }, [ endorseeProfile?.shortBio ]);

  // The location display string of the endorsee location
  const locationDisplayString = useMemo(() => {
    let city = '';
    if (endorseeProfile?.location?.city) {
      city = t(`utilities:cities.${ endorseeProfile?.location?.city }`);
    }
    return [
      endorseeProfile?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ endorseeProfile?.location?.city, endorseeProfile?.location?.district, t ]);

  // The location section
  const locationSection = useMemo(() => {
    if (isEmpty(endorseeProfile?.location)) {
      return null;
    }
    return (
      <div className='endorsee-profile-information__location-container'>
        <img alt='endorsee location' className='endorsee-profile-information__location-icon' src={ Location } />
        <span className='txt txt-sm'>
          { locationDisplayString }
        </span>
      </div>
    );
  }, [ endorseeProfile?.location, locationDisplayString ]);

  return (
    <div className='endorsee-profile-information'>
      <div className='endorsee-profile-information__profile-image-name-container'>
        <div className={
          clsx('endorsee-profile-information__profile-image-container', {
            'no-picture': undefined === endorseeProfile?.profilePicture,
          })
        }
        >
          { profileImage }
        </div>
        <div className='endorsee-profile-information__profile-name-container light'>
          <p className='txt txt-xl txt-bold'>
            { `${ endorseeProfile?.firstName },` }
          </p>
          <p className='txt txt-xl txt-bold'>
            { endorseeProfile?.lastName }
          </p>
        </div>
      </div>
      <div className='endorsee-profile-information__profile-information light'>
        <p className='txt txt-md txt-bold'>
          { t('endorsement:profile_overview_title') }
        </p>
        { shortBio }
        { locationSection }
        <Link
          className='endorsee-profile-information__view-profile-button btn btn-sm btn-rounded-sm'
          to={ `/job-seekers/${ endorseeProfile?.id }/profile` }
        >
          { t('endorsement:view_profile_button_title') }
        </Link>
      </div>
    </div>
  );
});

EndorseeProfileInformation.displayName = 'EndorseeProfileInformation';

EndorseeProfileInformation.propTypes = {
  endorseeProfile: PropTypes.shape({
    // The first name of the endorsee
    firstName: PropTypes.string,
    // The id of the endorsee
    id: PropTypes.string,
    // The last name of the endorsee
    lastName: PropTypes.string,
    // The location where the endorsee is.
    location: PropTypes.shape({
      // The city.
      city: PropTypes.oneOf(allCities),
      // The district.
      district: PropTypes.string,
    }),
    // The profile picture of the endorsee.
    profilePicture: PropTypes.shape({
      // The ID of the picture.
      id: PropTypes.string,
      // The MIME type of the picture.
      type: PropTypes.string,
    }),
    // The short bio of the endorsee
    shortBio: PropTypes.string,
  }),
};

EndorseeProfileInformation.defaultProps = {};

export default EndorseeProfileInformation;
