/**
 * Content.
 */
import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompaniesLearnMore from 'components/companies/learn-more/learn-more';
import Dashboard from 'components/dashboard/dashboard';
import Endorsement from 'components/endorsement/endorsement';
import EndorsersLearnMore from 'components/endorsers/learn-more/learn-more';
import ForgotPassword from 'components/forgot-password/forgot-password';
import Home from 'components/home/home';
import JobSeekersLearnMore from 'components/job-seekers/learn-more/learn-more';
import TakeAssessment from 'components/job-seekers/take-assessment/take-assessment';
import LinkedinCallbackComponent from 'components/linkedin-callback-component/linkedin-callback-component';
import PrivacyPolicy from 'components/privacy-policy/privacy-policy';
import Profile from 'components/profile/profile';
import RequireAuth from 'components/require-auth/require-auth';
import ResetPassword from 'components/reset-password/reset-password';
import Search from 'components/search/search';
import Settings from 'components/settings/settings';
import SignIn from 'components/sign-in/sign-in';
import SignUp from 'components/sign-up/sign-up';
import SocialResponsibility from 'components/social-responsibility/social-responsibility';
import TermsOfService from 'components/terms-of-service/terms-of-service';
import VerifyEmail from 'components/verify-email/verify-email';
import * as Roles from 'utilities/auth/roles';

import './content.scss';
import AboutUs from '../about-us/about-us';

const Content = memo((_props) => {
  return (
    <div className='ody-content'>
      <Routes>
        <Route element={ <SignIn /> } exact path='/sign-in' />
        <Route element={ <TermsOfService /> } exact path='/terms-of-service' />
        <Route element={ <VerifyEmail /> } exact path='/verify-email' />
        <Route element={ <Home /> } exact path='/home' />
        <Route element={ <ForgotPassword /> } exact path='/forgot-password' />
        <Route element={ <TakeAssessment /> } exact path='/job-seekers/take-assessment' />
        <Route element={ <PrivacyPolicy /> } exact path='/privacy-policy' />
        <Route element={ <ResetPassword /> } exact path='/reset-password' />
        <Route element={ <SocialResponsibility /> } exact path='/social-responsibility' />
        <Route element={ <AboutUs /> } exact path='/about-us' />
        <Route
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
          exact
          path='/dashboard'
        />
        <Route element={ <SignUp role={ Roles.JOB_SEEKER } /> } exact path='job-seekers/sign-up' />
        <Route element={ <JobSeekersLearnMore /> } exact path='/job-seekers/learn-more' />
        <Route element={ <Profile role={ Roles.JOB_SEEKER } /> } exact path='/job-seekers/:jobSeekerId/profile' />
        <Route element={ <Search role={ Roles.JOB_SEEKER } /> } exact path='/job-seekers/search' />
        <Route element={ <SignUp role={ Roles.COMPANY_AGENT } /> } exact path='companies/sign-up' />
        <Route element={ <CompaniesLearnMore /> } exact path='/companies/learn-more' />
        <Route element={ <Profile role={ Roles.COMPANY_AGENT } /> } exact path='/companies/:companyId/profile' />
        <Route element={ <Search role={ Roles.COMPANY_AGENT } /> } exact path='/companies/search' />
        <Route element={ <SignUp role={ Roles.ENDORSER } /> } exact path='endorsers/sign-up' />
        <Route element={ <EndorsersLearnMore /> } exact path='/endorsers/learn-more' />
        <Route element={ <Profile role={ Roles.ENDORSER } /> } exact path='/endorsers/:endorserId/profile' />
        <Route element={ <Search role={ Roles.ENDORSER } /> } exact path='/endorsers/search' />
        <Route
          element={
            <RequireAuth>
              <Endorsement />
            </RequireAuth>
          }
          exact
          path='/endorsers/:endorsementId/endorsement'
        />
        <Route
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
          exact
          path='/settings'
        />
        <Route element={ <Navigate replace to='/job-seekers/sign-up' /> } path='/sign-up' />
        <Route element={ <Navigate replace to='/home' /> } path='/' />
        <Route element={ <LinkedinCallbackComponent /> } exact path='/linkedin' />
        <Route
          element={ <Navigate replace to='/home' /> }
          path='*'
        />
      </Routes>
    </div>
  );
});

Content.displayName = 'Content';

Content.propTypes = {
};

Content.defaultProps = {
};

export default Content;
