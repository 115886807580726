/**
 * Popup menu.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import BasePopup from 'reactjs-popup';

import './popup-menu.scss';

const PopupMenu = (props) => {
  // Reference to the popup.
  const popupRef = useRef(undefined);

  return (
    <div className={ clsx('ody-popup-menu', props.className) }>
      <BasePopup
        arrow={ false }
        className={ props.className }
        closeOnDocumentClick
        closeOnEscape
        offsetX={ props.offsetX }
        offsetY={ props.offsetY }
        on={ [ 'click' ] }
        position={ props.position }
        ref={ popupRef }
        trigger={ props.trigger }
      >
        {
          React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
              onClick: (e) => {
                popupRef?.current.close();
                child.props.onClick?.(e);
              },
            });
          })
        }
      </BasePopup>
    </div>
  );
};

PopupMenu.propTypes = {
  // The content of the popup.
  children: PropTypes.node.isRequired,
  // The class(es) to apply to the popup.
  className: PropTypes.string,
  // The x-offset to apply to the popup.
  offsetX: PropTypes.number,
  // The y-offset to apply to the popup.
  offsetY: PropTypes.number,
  // The position of the popup.
  position: PropTypes.oneOf([
    'bottom center',
    'bottom left',
    'bottom right',
    'center center',
    'left center',
    'right center',
    'top center',
    'top left',
    'top right',
  ]),
  // The trigger of the popup.
  trigger: PropTypes.node.isRequired,
};

PopupMenu.defaultProps = {
};

export default PopupMenu;
