import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import LearnMoreSignInSectionJobSeekersAnimatedIcon from 'animations/employable.json';
import LearnMoreSignInSectionCompaniesAnimatedIcon from 'animations/lens.json';
import LearnMoreSignInSectionEndorsersAnimatedIcon from 'animations/planet.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';
import SignInForm from 'components/sign-in-form/sign-in-form';
import roles, * as Roles from 'utilities/auth/roles';

import './public-learn-more-sign-in-section.scss';

const PublicLearnMoreSignInSection = memo((props) => {
  const { title, role } = props;

  // The rendered icon
  const renderedIcon = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return (
        <LottieAnimationContainer
          animatedIconClassName='public-learn-more-sign-in-section__animated-icon--job-seekers'
          animationData={ LearnMoreSignInSectionJobSeekersAnimatedIcon }
          className='public-learn-more-sign-in-section__animation-container'
        />
      );
    }
    if (role === Roles.ENDORSER) {
      return (
        <LottieAnimationContainer
          animatedIconClassName='public-learn-more-sign-in-section__animated-icon--endorsers'
          animationData={ LearnMoreSignInSectionEndorsersAnimatedIcon }
          className='public-learn-more-sign-in-section__animation-container'
        />
      );
    }
    return (
      <LottieAnimationContainer
        animatedIconClassName='public-learn-more-sign-in-section__animated-icon--companies'
        animationData={ LearnMoreSignInSectionCompaniesAnimatedIcon }
        className='public-learn-more-sign-in-section__animation-container'
      />
    );
  }, [ role ]);

  return (
    <div
      className={
        clsx('public-learn-more-sign-in-section', {
          'public-learn-more-sign-in-section__companies': role === Roles.COMPANY_AGENT,
        })
      }
    >
      <div className='public-learn-more-sign-in-section__sign-in-container'>
        <h2 className='hdg hdg-xl public-learn-more-sign-in-section__title'>
          { title }
        </h2>
        <div className='public-learn-more-sign-in-section__sign-in-form'>
          <SignInForm role={ role } />
        </div>
      </div>
      { renderedIcon }
    </div>
  );
});

PublicLearnMoreSignInSection.displayName = 'PublicLearnMoreSignInSection';

PublicLearnMoreSignInSection.propTypes = {
  // The role of the sign in form to be passed
  role: PropTypes.oneOf(roles).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

PublicLearnMoreSignInSection.defaultProps = {};

export default PublicLearnMoreSignInSection;
