/**
 * English translations for companies/profile-pricing-section.
 *
 * @module languages/en/companies/profile-pricing-section
 */
const profilePricingSection = {
  address_label: 'Address',
  address_placeholder: 'Address',
  editor_title: 'Pricing',
  legal_name_label: 'Legal name',
  legal_name_placeholder: 'Legal name',
  occupation_label: 'Occupation',
  occupation_placeholder: 'Occupation',
  pricing_plan_label: 'Pricing plan',
  pricing_plan_placeholder: 'Select the pricing plan',
  tax_office_label: 'Tax office',
  tax_office_placeholder: 'Tax office',
  title: 'Pricing',
  vat_number_label: 'Vat number',
  vat_number_placeholder: 'Vat number',
};

export default profilePricingSection;
