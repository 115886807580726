/**
 * English translations for companies/steps.
 *
 * @module languages/en/companies/steps
 */
const steps = {
  CONTACT: 'Submit your personal details',
  DESCRIPTION: 'Add a description of the company',
  DETAILS: 'Upload company\'s details',
  JOB_POSITIONS: 'Add the job positions you are interested in',
  JOB_SECTORS: 'Add company\'s job sectors',
  LOGO: 'Upload company\'s logo',
  PRICING: 'Update your billing information',
  VIDEO: 'Upload company\'s video',
};

export default steps;
