import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSm, useXs } from 'hooks/media-queries';
import Badge from 'images/badge.png';
import Edit from 'images/edit.png';
import { formatDate } from 'utilities/chisels';
import qualificationTypes from 'utilities/qualification-types';

import './profile-qualification-card.scss';

const ProfileQualificationCard = memo((props) => {
  const { canEdit, detailed, expanded, onClick, onEdit, qualification, allSkills } = props;

  const isSm = useSm();

  const { i18n, t } = useTranslation();

  const isXs = useXs();

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // function that handles the edit button click
  const onEditButtonClick = useCallback((event) => {
    event.stopPropagation();
    onEdit();
  }, [ onEdit ]);

  // The rendered edit button
  const renderedEditButton = useMemo(() => {
    if (!canEdit || (detailed && !isSm && !isXs)) {
      return null;
    }
    return (
      <div className='profile-qualification-card__actions'>
        <button
          className='profile-qualification-card__edit-button btn btn-trans'
          onClick={ onEditButtonClick }
          type='button'
        >
          <img alt='Edit' src={ Edit } />
        </button>
      </div>
    );
  }, [ canEdit, detailed, isSm, isXs, onEditButtonClick ]);

  // the rendered issued in date
  const renderedDate = useCallback((dateToTransform) => {
    return formatDate(dateToTransform, 'LLL yyyy', selectedLanguage);
  }, [ selectedLanguage ]);

  // The rendered issued in section
  const renderedIssuedInSection = useMemo(() => {
    if (qualification?.issuedIn === undefined) {
      return null;
    }
    return (
      <span className='txt txt-sm'>
        { `${ t('job_seekers:profile_qualifications.issued_in') } ${ renderedDate(qualification?.issuedIn) }` }
      </span>
    );
  }, [ qualification?.issuedIn, renderedDate, t ]);

  // The rendered expires in section
  const renderedExpiresInSection = useMemo(() => {
    if (qualification?.expiresIn === undefined) {
      return null;
    }
    return (
      <span className='txt txt-sm'>
        { `${ t('job_seekers:profile_qualifications.expires_in') } ${ renderedDate(qualification?.expiresIn) }` }
      </span>
    );
  }, [ qualification?.expiresIn, renderedDate, t ]);

  // The rendered issued dates
  const renderedDates = useMemo(() => {
    if (!expanded || !detailed || (qualification?.issuedIn === undefined && qualification.expiresIn === undefined)) {
      return null;
    }
    return (
      <div className='qualification-card__dates-container'>
        { renderedIssuedInSection }
        { ' - ' }
        { renderedExpiresInSection }
      </div>
    );
  }, [
    detailed,
    expanded,
    qualification.expiresIn,
    qualification?.issuedIn,
    renderedExpiresInSection,
    renderedIssuedInSection,
  ]);

  // The description section
  const descriptionSection = useMemo(() => {
    if (!expanded || qualification?.description === undefined) {
      return null;
    }
    return (
      <div className='profile-qualification-card__row'>
        <span className='profile-qualification-card__row--description txt txt-sm'>
          { qualification.description }
        </span>
      </div>
    );
  }, [ expanded, qualification.description ]);

  // The credential url section
  const credentialUrlSection = useMemo(() => {
    if (!expanded || !detailed || !qualification?.credentialUrl) {
      return null;
    }
    return (
      <div className='profile-qualification-card__row'>
        <a
          className='link inline'
          href={ qualification.credentialUrl }
          rel='noopener noreferrer'
          target='_blank'
        >
          { t('job_seekers:profile_qualifications.credential') }
        </a>
      </div>
    );
  }, [ detailed, expanded, qualification.credentialUrl, t ]);

  // The rendered skills
  const renderedQualificationSkills = useMemo(() => {
    return qualification?.skills?.map((skillId) => {
      const foundSkill = allSkills?.find((skill) => {
        return skill.id === skillId;
      })?.[selectedLanguage];
      return (
        <span
          className='txt txt-sm'
          key={ skillId }
        >
          { `#${ foundSkill }` }
        </span>
      );
    });
  }, [ allSkills, qualification?.skills, selectedLanguage ]);

  // The skills section
  const skillsSection = useMemo(() => {
    if (!expanded || !qualification?.skills?.length) {
      return null;
    }
    return (
      <div className='profile-qualification-card__column'>
        { renderedQualificationSkills }
      </div>
    );
  }, [ expanded, qualification?.skills?.length, renderedQualificationSkills ]);

  return (
    <div
      className={
        clsx('profile-qualification-card', {
          'profile-qualification-card__detailed': detailed,
          'profile-qualification-card__expanded': expanded,
        })
      }
      onClick={ onClick }
      role='presentation'
    >
      { renderedEditButton }
      <div className='profile-qualification-card__row'>
        <img
          alt='Qualification'
          className='profile-qualification-card__row--image'
          src={ Badge }
        />
        <p className='profile-qualification-card__row--text txt txt-sm'>
          { qualification?.organization || '' }
        </p>
      </div>
      <div className='profile-qualification-card__row'>
        <p className='profile-qualification-card__row--title txt txt-md'>
          { qualification?.title || '' }
        </p>
      </div>
      <div className='profile-qualification-card__row'>
        <p className='profile-qualification-card__row--text txt txt-sm'>
          { t(`utilities:qualification_types.${ qualification?.type }`) }
        </p>
      </div>
      { renderedDates }
      { descriptionSection }
      { credentialUrlSection }
      { skillsSection }
    </div>
  );
});

ProfileQualificationCard.displayName = 'ProfileQualificationCard';

ProfileQualificationCard.propTypes = {
  // All the skills available
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of skill
      el: PropTypes.string,
      // english translation of skill
      en: PropTypes.string,
      // the id of the skill
      id: PropTypes.string,
    })
  ),
  // Whether the user can edit the qualification
  canEdit: PropTypes.bool,
  // Whether the card contains all the details about the qualification.
  detailed: PropTypes.bool.isRequired,
  // Whether the card is expanded.
  expanded: PropTypes.bool.isRequired,
  // The function (() => void) to invoke when the user clicks on the card.
  onClick: PropTypes.func,
  // The function (() => void) to invoke when the user clicks on the Edit button.
  onEdit: PropTypes.func,
  // The qualification that the card is about.
  qualification: PropTypes.shape({
    // The URL to the credential.
    credentialUrl: PropTypes.string,
    // The description of the qualification.
    description: PropTypes.string,
    // The date when the credential expires.
    expiresIn: PropTypes.string,
    // The date when the qualification was issued
    issuedIn: PropTypes.string,
    // The organization that issued the credential.
    organization: PropTypes.string,
    // The IDs of the skills associated to the qualification.
    skills: PropTypes.arrayOf(PropTypes.string),
    // Whether the credential expires.
    subjectToExpiration: PropTypes.bool,
    // The title of the qualification.
    title: PropTypes.string,
    // The type of the qualification.
    type: PropTypes.oneOf(qualificationTypes),
  }),
};

ProfileQualificationCard.defaultProps = {};

export default ProfileQualificationCard;
