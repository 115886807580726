/**
 * Sign-in form.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AuthSocialButtons from 'components/common/auth-social-buttons/auth-social-buttons';
import PasswordInput from 'components/common/password-input/password-input';
import { actions as authActions } from 'ducks/auth';
import { actions as jobSeekersActions } from 'ducks/job-seekers';
import { actions as requestsActions } from 'ducks/requests';
import { actions as settingsActions } from 'ducks/settings';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as authMethods from 'resources/auth';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';
import * as AuthenticationProvider from 'utilities/auth/provider';
import roles, * as Roles from 'utilities/auth/roles';
import { isNotEmpty } from 'utilities/chisels';
import { signInFormOAuthSchemaValidation, signInFormSchemaValidation } from 'utilities/validators';

import './sign-in-form.scss';

const SignInForm = memo((props) => {

  const { className, role } = props;

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  // The oauth authorization token (e.g. linkedin authorization code, google authentication token).
  const [ oauthAuthorizationToken, setOAuthAuthorizationToken ] = useState('');

  // The schema validation
  const schemaValidation = useMemo(() => {
    if (oauthAuthorizationToken) {
      return signInFormOAuthSchemaValidation;
    }
    return signInFormSchemaValidation;
  }, [ oauthAuthorizationToken ]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    unregister,
    control,
    setValue,
    reset,
  } = useFormWithSchema(schemaValidation);

  useEffect(() => {
    // always reset the oauth tokens when render
    setOAuthAuthorizationToken('');
  }, []);

  // The page that the user tried to visit when they got redirected here.
  const fromLocation = useMemo(() => {
    return location.state?.from?.pathname || '/dashboard';
  }, [ location.state?.from?.pathname ]);

  // function that sets the bad credentials error based on the provider
  const setBadCredentialsError = useCallback((provider) => {
    if (AuthenticationProvider.GOOGLE === provider || AuthenticationProvider.LINKEDIN === provider) {
      toasts.error(t('sign_in_form:user_not_found_error'));
      return;
    }
    toasts.error(t('sign_in_form:bad_credentials_error'));
  }, [ t ]);

  // function that sets the sign in error
  const setSignInError = useCallback((error, provider = AuthenticationProvider.LOCAL) => {
    switch (error.message) {
    case 'BAD_CREDENTIALS':
      setBadCredentialsError(provider);
      break;
    case 'LOCKED_ACCOUNT':
      toasts.error(t('sign_in_form:locked_account_error'));
      break;
    case 'NEW_ACCOUNT':
      toasts.error(t('sign_in_form:new_account_error'));
      break;
    case 'REJECTED_ACCOUNT':
      toasts.error(t('sign_in_form:rejected_account_error'));
      break;
    case 'SUSPENDED_ACCOUNT':
      toasts.error(t('sign_in_form:suspended_account_error'));
      break;
    case 'VERIFIED_ACCOUNT':
      toasts.error(t('sign_in_form:verified_account_error'));
      break;
    case 'FAILED_GOOGLE_VERIFICATION':
      toasts.error(t('sign_in_form:verify_with_google_error'));
      break;
    case 'FAILED_LINKEDIN_VERIFICATION':
      toasts.error(t('sign_in_form:verify_with_linkedin_error'));
      break;
    default:
      toasts.error(t('sign_in_form:general_error'));
      break;
    }
  }, [ setBadCredentialsError, t ]);

  // The sign in method based on the provider.
  const signInMethod = useCallback((provider) => {
    if (provider === AuthenticationProvider.GOOGLE) {
      return authMethods.googleSignIn;
    }
    if (provider === AuthenticationProvider.LINKEDIN) {
      return authMethods.linkedInSignIn;
    }
    return authMethods.signIn;
  }, []);

  // Function that signs in a user based on the given values.
  const signIn = useCallback((values, provider = AuthenticationProvider.LOCAL) => {
    dispatch(requestsActions.request(signInMethod(provider), {
      ...values,
      email: values.email.toLowerCase(),
    }, {
      navigate,
      onFailure: (error) => {
        setSignInError(error, provider);
        // clear the oauth authorization token
        setOAuthAuthorizationToken('');
        // register the fields that were unregistered during the sign-in process.
        register('password');
        // reset the form after the error
        reset();
      },
      onSuccess: (result) => {
        dispatch(authActions.signIn(result));
        // Set the settings with values from the signed-in user.
        dispatch(settingsActions.setSettings({
          email: result.email,
          language: result.language,
        }));
        // Reset everything about job seeker searches.
        dispatch(jobSeekersActions.resetSearch());
        // Take the user to the page they requested.
        navigate(fromLocation, { replace: true });
      },
    }));
  }, [ dispatch, fromLocation, navigate, register, reset, setSignInError, signInMethod ]);

  // The rendered password input element
  const renderedPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        disabled={ isNotEmpty(oauthAuthorizationToken) }
        error={ !!errors.password }
        id='sign-in-password-input'
        label={ t('sign_in_form:password_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('sign_in_form:password_label') }
        value={ value }
      />
    );
  }, [ errors.password, oauthAuthorizationToken, t ]);

  // The empty email error
  const emptyEmailError = useMemo(() => {
    if ('string.empty' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_in_form:email_required_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // invalid email error
  const invalidEmailError = useMemo(() => {
    if ( 'string.email' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_in_form:email_invalid_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // empty password error
  const emptyPasswordError = useMemo(() => {
    if ('string.empty' !== errors.password?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_in_form:password_required_error') }
      </span>
    );
  }, [ errors.password?.type, t ]);

  // Link to sign up
  const linkToSignUp = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return '/job-seekers/sign-up';
    case Roles.COMPANY_AGENT:
      return '/companies/sign-up';
    case Roles.ENDORSER:
      return '/endorsers/sign-up';
    default:
      return '/';
    }
  }, [ role ]);

  // The function that handles the Google sign in
  const handleGoogleSignIn = useCallback((accessToken, tokenType) => {
    dispatch(requestsActions.request(usersMethods.getUsersGoogleProfile, {
      accessToken,
      tokenType,
    }, {
      onFailure: (_error) => {
        toasts.error('sign_in_form:retrieve_google_profile_error');
      },
      onSuccess: (res) => {
        setOAuthAuthorizationToken(accessToken);
        // We have to unregister the fields that we will hide and set the values provided via Google.
        unregister('password');
        setValue('email', res.email);
        signIn({
          accessToken,
          email: res.email,
        }, AuthenticationProvider.GOOGLE);
      },
    }));
  }, [ dispatch, setValue, signIn, unregister ]);

  // The function that handles the linkedin sign in
  const handleLinkedInSignIn = useCallback((authorizationCode) => {
    dispatch(requestsActions.request(usersMethods.getUsersLinkedInEmail, {
      authorizationCode,
    }, {
      onFailure: (_error) => {
        toasts.error(t('sign_in_form:retrieve_linkedin_profile_error'));
      },
      onSuccess: (res) => {
        setOAuthAuthorizationToken(res.accessToken);
        // We have to unregister the fields that we will hide and set the values provided via Google.
        unregister('password');
        setValue('email', res.email);
        signIn({
          accessToken: res.accessToken,
          email: res.email,
        }, AuthenticationProvider.LINKEDIN);
      },
    }));
  }, [ dispatch, setValue, signIn, t, unregister ]);

  // Whether to render the social buttons
  const renderedSocialButtons = useMemo(() => {
    if (oauthAuthorizationToken) {
      return null;
    }
    return (
      <>
        <div className='ody-sign-in-form__separator'>{ t('sign_in_form:or') }</div>
        <AuthSocialButtons
          googleButtonLabel={ t('sign_in_form:continue_with_google_button_label') }
          handleGoogleSign={ handleGoogleSignIn }
          handleLinkedinSign={ handleLinkedInSignIn }
          linkedinButtonLabel={ t('sign_in_form:continue_with_linkedin_button_label') }
        />
      </>
    );
  }, [ oauthAuthorizationToken, t, handleGoogleSignIn, handleLinkedInSignIn ]);

  return (
    <>
      <form
        className={
          clsx('ody-sign-in-form', className)
        }
        onSubmit={ handleSubmit(signIn) }
      >
        <div className='ody-sign-in-form__form-control'>
          <label htmlFor='email'>
            { t('sign_in_form:email_label') }
          </label>
          <input
            className={ clsx(errors.email && 'error') }
            disabled={ isNotEmpty(oauthAuthorizationToken) }
            id='email'
            maxLength={ 256 }
            name='email'
            placeholder={ t('sign_in_form:email_placeholder') }
            type='text'
            { ...register('email') }
          />
          { emptyEmailError }
          { invalidEmailError }
        </div>
        <div className='ody-sign-in-form__password-container'>
          <div className='ody-sign-in-form__form-control'>
            <Controller
              control={ control }
              defaultValue=''
              name='password'
              render={ renderedPasswordInputElement }
            />
            { emptyPasswordError }
          </div>
          <Link className='link inline ody-sign-in-form__password-container--link' to='/forgot-password'>
            { t('sign_in_form:forgot_password_link_text') }
          </Link>
        </div>
        <button
          className='btn btn-custom btn-rounded-sm btn-blue ody-sign-in-form__sign-in-button'
          disabled={ isNotEmpty(errors) || isNotEmpty(oauthAuthorizationToken) }
          type='submit'
        >
          { t('sign_in_form:sign_in_button_label') }
        </button>
        <div className='txt txt-sm'>
          <span>{ t('sign_in_form:dont_have_account') }</span>
          &nbsp;
          <Link className='link inline' to={ linkToSignUp }>
            { t('sign_in_form:sign_up_for_free_link_text') }
          </Link>
        </div>
        { renderedSocialButtons }
      </form>
    </>
  );
});

SignInForm.displayName = 'SignInForm';

SignInForm.propTypes = {
  // The class(es) to apply to the form.
  className: PropTypes.string,
  // The role to target with the form.
  role: PropTypes.oneOf(roles).isRequired,
};

SignInForm.defaultProps = {
};

export default SignInForm;
