/**
 * Components that can be accessed only by authenticated users.
 */
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = memo((props) => {
  const { children, permissions } = props;

  const location = useLocation();

  // The auth state obbject
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  // The user permissions
  const userPermissions = useMemo(() => {
    return auth?.permissions || [];
  }, [ auth?.permissions ]);

  // Whether the user is authorized (i.e. has any of the required permissions).
  const userAuthorized = useMemo(() => {
    return 0 === permissions.length || permissions.some((permission) => {
      return 0 <= userPermissions.indexOf(permission);
    });
  }, [ permissions, userPermissions ]);

  return useMemo(() => {
    if (!userAuthenticated) {
      return (
        <Navigate replace state={ { from: location } } to='/sign-in' />
      );
    }
    if (!userAuthorized) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <>
        { children }
      </>
    );
  }, [ children, location, userAuthenticated, userAuthorized ]);
});

RequireAuth.displayName = 'RequireAuth';

RequireAuth.propTypes = {
  // The components to render.
  children: PropTypes.node,
  // The required permissions.
  permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
};

RequireAuth.defaultProps = {
  permissions: [],
};

export default RequireAuth;
