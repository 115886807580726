/**
 * Greek translations for common/search-results.
 *
 * @module languages/el/common/search-results
 */
const searchResults = {
  about_title: 'Σχετικά',
  companies_professions_title: 'Θέσεις εργασίας',
  job_seekers_professions_title: 'Θέσεις εργασίας που σας ενδιαφέρουν',
  skills_and_traits_button_title: 'Δεξιότητες και χαρακτηριστικά',
};

export default searchResults;
