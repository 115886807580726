/**
 * Languages editor.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MultiSelect from 'components/common/multi-select/multi-select';
import Select from 'components/common/select/select';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import { areNotEqual } from 'utilities/chisels';
import languageLevels, * as LanguageLevels from 'utilities/language-levels';
import languages from 'utilities/languages';
import { jobSeekersLanguagesSchemaValidator } from 'utilities/validators';

const ProfileLanguagesEditor = memo((props) => {
  const { profile, onSave, onCancel } = props;

  const { t } = useTranslation();

  const { control, handleSubmit } = useFormWithSchema(jobSeekersLanguagesSchemaValidator);

  // The other languages to be updated
  const otherLanguagesToBeUpdated = useCallback((otherLanguages) => {
    return otherLanguages?.map((language) => {
      return language.value;
    });
  }, []);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      englishLevel: profile?.englishLevel,
      greekLevel: profile?.greekLevel,
      otherLanguages: profile?.otherLanguages,
    };
  }, [ profile?.englishLevel, profile?.greekLevel, profile?.otherLanguages ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      englishLevel: values.englishLevel?.value,
      greekLevel: values.greekLevel?.value,
      otherLanguages: otherLanguagesToBeUpdated(values?.otherLanguages),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ onSave, otherLanguagesToBeUpdated, valuesAreToBeUpdated ]);

  // The greek level default value
  const greekLevelDefaultValue = useMemo(() => {
    if (!profile?.greekLevel) {
      return null;
    }
    return {
      label: t(`utilities:language_levels.${ profile.greekLevel }`),
      value: profile.greekLevel,
    };
  }, [ profile?.greekLevel, t ]);

  // languages options
  const languagesOptions = useMemo(() => {
    return [
      LanguageLevels.NO,
      LanguageLevels.BASIC,
      LanguageLevels.INTERMEDIATE,
      LanguageLevels.ADVANCED,
      LanguageLevels.NATIVE,
    ].map((level) => {
      return { label: t(`utilities:language_levels.${ level }`), value: level };
    });
  }, [ t ]);

  // The rendered greek level component
  const renderedGreeKLevelComponent = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ languagesOptions }
        placeholder={ t('job_seekers:profile_languages.greek_level_placeholder') }
      />
    );
  }, [ languagesOptions, t ]);

  // The greek level field
  const greekLevelField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('utilities:languages.el') }
          <Controller
            control={ control }
            defaultValue={ greekLevelDefaultValue }
            name='greekLevel'
            render={ renderedGreeKLevelComponent }
          />
        </label>
      </div>
    );
  }, [ control, greekLevelDefaultValue, renderedGreeKLevelComponent, t ]);

  // The rendered english level component
  const renderedEnglishLevelComponent = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ languagesOptions }
        placeholder={ t('job_seekers:profile_languages.english_level_placeholder') }
      />
    );
  }, [ languagesOptions, t ]);

  // The english level default value
  const englishLevelDefaultValue = useMemo(() => {
    if (!profile?.englishLevel) {
      return null;
    }
    return {
      label: t(`utilities:language_levels.${ profile.englishLevel }`),
      value: profile.englishLevel,
    };
  }, [ profile?.englishLevel, t ]);

  // The english level field
  const englishLevelField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('utilities:languages.en') }
          <Controller
            control={ control }
            defaultValue={ englishLevelDefaultValue }
            name='englishLevel'
            render={ renderedEnglishLevelComponent }
          />
        </label>
      </div>
    );
  }, [ control, englishLevelDefaultValue, renderedEnglishLevelComponent, t ]);

  // The other languages default value
  const otherLanguagesDefaultValue = useMemo(() => {
    if (undefined === profile?.otherLanguages) {
      return [];
    }
    return languages.filter((language) => {
      return -1 !== profile.otherLanguages.indexOf(language);
    }).map((language) => {
      return {
        label: t(`utilities:languages.${ language }`),
        value: language,
      };
    });
  }, [ profile.otherLanguages, t ]);

  // whether the other languages multi select is diasbled
  const otherLanguagesMultiSelectDisabled = useCallback((field) => {
    return 8 <= field.value?.length;
  }, []);

  // The other languages options
  const otherLanguagesOptions = useMemo(() => {
    return languages.filter((language) => {
      return -1 === [ 'el', 'en' ].indexOf(language);
    }).map((language) => {
      return { label: t(`utilities:languages.${ language }`), value: language };
    });
  }, [ t ]);

  // The other languages rendered multi select component
  const otherLanguagesMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ otherLanguagesMultiSelectDisabled(field) }
        options={ otherLanguagesOptions }
        placeholder={ t('job_seekers:profile_languages.other_languages_placeholder') }
      />
    );
  }, [ otherLanguagesMultiSelectDisabled, otherLanguagesOptions, t ]);

  // The other languages field
  const otherLanguagesField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_languages.other_languages_label') }
          <Controller
            control={ control }
            defaultValue={ otherLanguagesDefaultValue }
            name='otherLanguages'
            render={ otherLanguagesMultiSelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, otherLanguagesDefaultValue, otherLanguagesMultiSelectComponent, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('job_seekers:profile_competencies.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { greekLevelField }
        { englishLevelField }
        { otherLanguagesField }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileLanguagesEditor.displayName = 'ProfileLanguagesEditor';

ProfileLanguagesEditor.propTypes = {
  // The function (() => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((object) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The level that the job seeker knows English at.
    englishLevel: PropTypes.oneOf(languageLevels),
    // The level that the job seeker knows Greek at.
    greekLevel: PropTypes.oneOf(languageLevels),
    // The ISO 639-1 alpha-2 codes of the other languages that the job seeker knows.
    otherLanguages: PropTypes.arrayOf(PropTypes.oneOf(languages)),
  }).isRequired,
};

ProfileLanguagesEditor.defaultProps = {};

export default ProfileLanguagesEditor;
