import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as requestsActions } from 'ducks/requests';
import * as endorsersMethods from 'resources/endorsers';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';

import EndorseeProfileInformation from './endorsee-profile-information/endorsee-profile-information';
import EndorsementForm from './endorsement-form/endorsement-form';
import './endorsement.scss';

const Endorsement = () => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The endorsers state object
  const endorsers = useSelector((store) => {
    return store.endorsers;
  }, shallowEqual);

  // The hashtags state object
  const hashtags = useSelector((store) => {
    return store.hashtags;
  }, shallowEqual);

  // The endorsement to be submitted
  const endorsement = useMemo(() => {
    return endorsers?.endorsement;
  }, [ endorsers?.endorsement ]);

  // The claimed endorsement requests
  const claimedEndorsementRequests = useMemo(() => {
    return endorsers?.claimedEndorsementRequests;
  }, [ endorsers?.claimedEndorsementRequests ]);

  // The signed in user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // the endorsee profile
  const endorseeProfile = useMemo(() => {
    return endorsement?.endorseeProfile;
  }, [ endorsement?.endorseeProfile ]);

  // All the hashtags
  const allHashtags = useMemo(() => {
    return hashtags?.hashtags || [];
  }, [ hashtags?.hashtags ]);

  // The endorsement id
  const { endorsementId } = useParams();

  // Whether the endorsement is in signed in user's claimed endorsement requests
  const isEndorsementInUsersClaimedEndorsementRequests = useMemo(() => {
    return claimedEndorsementRequests.find((endorsementRequest) => {
      return endorsementRequest?.id === endorsementId;
    }) !== undefined;
  }, [ claimedEndorsementRequests, endorsementId ]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (endorsementId !== endorsement?.id
      || userRole !== Roles.ENDORSER
      || !isEndorsementInUsersClaimedEndorsementRequests) {
      navigate('/dashboard');
    }
  }, [ endorsement?.id, endorsementId, isEndorsementInUsersClaimedEndorsementRequests, navigate, userRole ]);

  // The function that is invoked when canceling the endorsement
  const handleCancelEndorsement = useCallback(() => {
    navigate('/dashboard');
  }, [ navigate ]);

  // The submit values
  const submitValues = useCallback((values) => {
    if ('' === values?.testimonial) {
      delete values?.testimonial;
    }
    if (!values?.hashtags?.length) {
      delete values?.hashtags;
    }
    return {
      ...values,
      requestId: endorsementId,
    };
  }, [ endorsementId ]);

  // The function that is invoked when submitting the endorsement
  const handleSubmitEndorsement = useCallback((values) => {
    dispatch(requestsActions.request(endorsersMethods.submitEndorsement,
      submitValues(values),
      {
        onFailure: (_error) => {
          toasts.info(t('endorsement:form.failed_submit_endorsement'));
        },
        onSuccess: (_result) => {
          toasts.info(t('endorsement:form.successful_submitted_endorsement'));
          navigate('/dashboard');
        },
      }));
  }, [ dispatch, navigate, submitValues, t ]);

  return (
    <div className='endorsement'>
      <div className='endorsement__top-banner'>
        <h2 className='hdg hdg-xl light'>
          { t('endorsement:title') }
        </h2>
      </div>
      <EndorseeProfileInformation
        endorseeProfile={ endorseeProfile }
      />
      <div className='endorsement__form-container'>
        <p className='hdg hdg-lg dark'>
          { t('endorsement:list_of_questions_title') }
        </p>
        <EndorsementForm
          allHashtags={ allHashtags }
          onCancel={ handleCancelEndorsement }
          onSubmit={ handleSubmitEndorsement }
        />
      </div>
    </div>
  );
};

export default Endorsement;
