import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import env from 'config/env';
import accessLevels, * as AccessLevels from 'utilities/access-levels';
import roles, * as Roles from 'utilities/auth/roles';
import { isEmpty } from 'utilities/chisels';
import * as CompaniesParts from 'utilities/companies/parts';
import * as EndorsersParts from 'utilities/endorsers/parts';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileAboutEditorEndorser from './profile-about-editor-endorser/profile-about-editor-endorser';
import ProfileAboutEditor from './profile-about-editor/profile-about-editor';

import './profile-about.scss';

const ProfileAbout = memo((props) => {
  const { canEdit, onChange, profile, role } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  // Whether the editor endorser modal is open.
  const [ editorEndorserOpen, setEditorEndorserOpen ] = useState(false);

  const { t } = useTranslation();

  // Reference to the video.
  const videoRef = useRef(null);

  // Reload the video.
  useEffect(() => {
    videoRef.current?.load();
  }, [ profile.video?.id ]);

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      if (role === Roles.ENDORSER) {
        setEditorEndorserOpen(toggle);
        return;
      }
      setEditorOpen(toggle);
    };
  }, [ role ]);

  // The text to be displayed on the title depending on role
  const titleDisplayed = useMemo(() => {
    switch (role) {
    case Roles.COMPANY_AGENT:
      return t('common:profile_about.title_companies');
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return t('common:profile_about.title_job_seekers');
    default:
      return '';
    }
  }, [ role, t ]);

  // The video source depending on role
  const videoSource = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ profile?.id }/profile/video?id=${ profile?.video?.id }`;
    case Roles.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ profile?.id }/profile/video?id=${ profile?.video?.id }`;
    default:
      return '';
    }
  }, [ profile?.id, profile?.video?.id, role ]);

  // The lean access levels
  const leanAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_1,
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The rendered video section
  const renderedVideoSection = useMemo(() => {
    if (isEmpty(profile?.video)
      || (role !== Roles.COMPANY_AGENT && !leanAccessLevels.includes(profile?.accessLevel))
      || role === Roles.ENDORSER
    ) {
      return null;
    }
    return (
      <div className='profile-about__video-section'>
        <video
          className='profile-about__video'
          controls
          crossOrigin='anonymous'
          preload='auto'
          ref={ videoRef }
          width='100%'
        >
          <source
            src={ videoSource }
            type={ profile.video.type }
          />
        </video>
      </div>
    );
  }, [ leanAccessLevels, profile?.accessLevel, profile.video, role, videoSource ]);

  // The rendered description section
  const renderedDescriptionSection = useMemo(() => {
    if (!profile?.shortBio && !profile?.description) {
      return null;
    }
    return (
      <div className='profile-about__description-section'>
        <span className='profile-about__description txt txt-sm'>
          { profile?.shortBio || profile?.description }
        </span>
      </div>
    );
  }, [ profile?.description, profile?.shortBio ]);

  // The part to be updated depending on the role
  const partToBeUpdated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekerParts.ABOUT_ME;
    case Roles.COMPANY_AGENT:
      return CompaniesParts.ABOUT;
    case Roles.ENDORSER:
      return EndorsersParts.ABOUT_ME;
    default:
      return JobSeekerParts.ABOUT_ME;
    }
  }, [ role ]);

  // function that handles the on change of about section
  const onAboutChange = useCallback((paramsToBeUpdated, isToBeUpdated, file) => {
    setEditorOpen(false);
    setEditorEndorserOpen(false);
    onChange(paramsToBeUpdated, partToBeUpdated, isToBeUpdated, file);
  }, [ onChange, partToBeUpdated ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit || role === Roles.ENDORSER) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileAboutEditor
          onCancel={ toggleEditor(false) }
          onSave={ onAboutChange }
          profile={ profile }
          role={ role }
          videoSource={ videoSource }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onAboutChange, profile, role, toggleEditor, videoSource ]);

  // The profile about editor endorser modal
  const editorEndorsersModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorEndorserOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileAboutEditorEndorser
          onCancel={ toggleEditor(false) }
          onSave={ onAboutChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ canEdit, editorEndorserOpen, onAboutChange, profile, toggleEditor ]);

  return (
    <div className='profile-about'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isMainSection
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ titleDisplayed }
      />
      <div className='profile-about__main-section'>
        { renderedVideoSection }
        { renderedDescriptionSection }
      </div>
      { editorModal }
      { editorEndorsersModal }
    </div>
  );
});

ProfileAbout.displayName = 'ProfileAbout';

ProfileAbout.propTypes = {
  // Whether the user can edit the details section
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func.isRequired,
  // the user's profile
  profile: PropTypes.oneOfType([
    //job seeker profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The id of the profile
      id: PropTypes.string,
      // The short bio of the job seeker.
      shortBio: PropTypes.string,
      // The video of the job seeker.
      video: PropTypes.shape({
        // The ID of the video.
        id: PropTypes.string,
        // The MIME type of the video.
        type: PropTypes.string,
      }),
    }),
    // company profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The description of the company.
      description: PropTypes.string,
      // The id of the profile
      id: PropTypes.string,
      // The video of the company.
      video: PropTypes.shape({
        // The ID of the video.
        id: PropTypes.string,
        // The MIME type of the video.
        type: PropTypes.string,
      }),
    }),
  ]),
  // the role of the profile based on route
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileAbout.defaultProps = {};

export default ProfileAbout;
