/**
 * Greek translations for endorsers/learn-more.
 *
 * @module languages/el/endorsers/learn-more
 */
const learnMore = {
  become_endorser_partner_section: {
    // eslint-disable-next-line max-len
    description: 'Ακολουθούμε μια διαδικασία επαλήθευσης για τους υποστηρικτές μας. \n\nΕγγραφείτε και γίνετε ο συνεργάτης μας στην κλιμάκωση του κοινωνικού αντίκτυπου.\n \n\nΌποιος θέλει οικειοθελώς να υποστηρίξει υποψήφιους στην εύρεση εργασίας μπορεί να συμμετάσχει ',
    example_endorsement_button_label: 'Δείτε ένα παράδειγμα σύστασης',
    join_us_button_label: 'Δηλώστε συμμετοχή',
    title: 'Πώς να γίνετε υποστηρικτής',
  },
  our_endorsers_section: {
    button_label: 'Δείτε όλους τους υποστηρικτές',
    title: 'Οι υποστηρικτές μας',
  },
  sign_in_section: {
    title: 'Βοηθήστε τους υποψήφιους μας',
  },
  what_endorser_do_section: {
    endorsers_actions: {
      item1: 'Πραγματοποιεί μια 15λεπτη συνέντευξη με τον/ην υποψήφιο/α',
      item2: 'Εντοπίζει τα δυνατά χαρακτηρίστηκα του/ης υποψήφιου/ας',
      item3: 'Αξιολογεί τις ήπιες δεξιότητες του/ης υποψήφιου/ας',
      item4: 'Τονίζει την ιστορία του/ης υποψήφιου/ας',
      item5: 'Παρέχει εποικοδομητική ανατροφοδότηση',
    },
    title: 'Τι κάνουν οι υποστηρικτές;',
  },
  why_section: {
    // eslint-disable-next-line max-len
    description: 'Ένας υποστηρικτής βοηθά τα άτομα που αναζητούν εργασία να εντοπίσουν τα δυνατά τους χαρακτηρίστηκα και να βελτιώσουν το επαγγελματικό τους προφίλ. \n\nΜε λίγα λεπτά από το χρόνο σας, μπορείτε να παρέχετε πολύτιμη υποστήριξη στους υποψήφιους μας.\n Η όλη διαδικασία είναι πολύ καλά δομημένη και σας καθοδηγεί διεξοδικά.',
    title: 'Γιατί;',
  },
};

export default learnMore;
