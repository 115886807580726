/**
 * The motivational levels
 *
 * @module utilities/endorsement/motivational-levels
 */

/**
 * first step.
 *
 * @constant {string}
 * @static
 */
export const FIRST_STEP = 'FIRST_STEP';

/**
 * Find job.
 *
 * @constant {string}
 * @static
 */
export const FIND_JOB = 'FIND_JOB';

/**
 * Take job.
 *
 * @constant {string}
 * @static
 */
export const TAKE_JOB = 'TAKE_JOB';

/**
 * Keep the job.
 *
 * @constant {string}
 * @static
 */
export const KEEP_JOB = 'KEEP_JOB';

/**
 * Work hard.
 *
 * @constant {string}
 * @static
 */
export const WORK_HARD = 'WORK_HARD';

export default [
  FIRST_STEP,
  FIND_JOB,
  TAKE_JOB,
  KEEP_JOB,
  WORK_HARD,
];
