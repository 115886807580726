import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './learn-more-profile-features-card.scss';

const LearnMoreProfileFeaturesCard = memo((props) => {
  const { description, icon, title } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('learn-more-profile-features-card', {
          'learn-more-profile-features-card__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='learn-more-profile-features-card__icon-container'>
        { icon }
      </div>
      <h4 className='learn-more-profile-features-card__title txt txt-bold'>
        { title }
      </h4>
      <p className='learn-more-profile-features-card__description txt txt-sm'>
        { description }
      </p>
    </div>
  );
});

LearnMoreProfileFeaturesCard.displayName = 'LearnMoreProfileFeaturesCard';

LearnMoreProfileFeaturesCard.propTypes = {
  // The card description
  description: PropTypes.string.isRequired,
  // The icon of the card
  icon: PropTypes.node.isRequired,
  // The title of the card
  title: PropTypes.string.isRequired,
};

LearnMoreProfileFeaturesCard.defaultProps = {};

export default LearnMoreProfileFeaturesCard;
