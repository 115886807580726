/**
 * Duck for administrators.
 *
 * @module ducks/administrators
 */

/**
 * The type of actions to describe that the pending companies are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const PENDING_COMPANIES_SET = 'PENDING_COMPANIES_SET';

/**
 * The type of actions to describe that the administrators' state object is reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const ADMINISTRATORS_RESET = 'ADMINISTRATORS_RESET';

/**
 * The type of actions to describe that the pending endorsers are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const PENDING_ENDORSERS_SET = 'PENDING_ENDORSERS_SET';

/**
 * The type of actions to describe that the total users are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const TOTAL_USERS_SET = 'TOTAL_USERS_SET';

/**
 * Creates an action to set the pending companies.
 *
 * @param {module:types/companies/profiles~Profile[]} profiles - The pending companies profiles.
 * @returns {object} - The action.
 * @static
 */
const setPendingCompanies = (profiles) => {
  return {
    profiles,
    type: PENDING_COMPANIES_SET,
  };
};

/**
 * Creates an action to set the pending endorsers.
 *
 * @param {module:types/endorsers/profiles~Profile[]} profiles - The pending endorsers' profiles.
 * @returns {object} - The action.
 * @static
 */
const setPendingEndorsers = (profiles) => {
  return {
    profiles,
    type: PENDING_ENDORSERS_SET,
  };
};

/**
 * Creates an action to set the total users.
 *
 * @param {module:types/administrators~TotalUsers} totalUsers - The total users.
 * @returns {object} - The action.
 * @static
 */
const setTotalUsers = (totalUsers) => {
  return {
    totalUsers,
    type: TOTAL_USERS_SET,
  };
};

/**
 * Creates an action to reset the administrators.
 *
 * @returns {object} - The action.
 * @static
 */
const resetAdministrators = () => {
  return {
    type: ADMINISTRATORS_RESET,
  };
};

/**
 * Administrators State.
 *
 * @typedef module:ducks/administrators~State
 * @type {object}
 * @property {module:types/companies/profiles~Profile[]} pendingCompaniesProfiles - The pending companies profiles.
 * @property {module:types/endorsers/profiles~Profile[]} pendingEndorsersProfiles - The pending endorsers profiles.
 * @property {module:types/administrators~TotalUsers} totalUsers - The total users.
 */

/**
 * The initial administrators' state.
 *
 * @type {module:ducks/administrators~State}
 * @static
 */
const initialState = {
  pendingCompaniesProfiles: [],
  pendingEndorsersProfiles: [],
  totalUsers: {
    totalJobSeekers: undefined,
    // eslint-disable-next-line sort-keys
    totalCompanies: undefined,
    totalEndorsers: undefined,
  },
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/administrators~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/administrators~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case PENDING_COMPANIES_SET:
    return {
      ...state,
      pendingCompaniesProfiles: action.profiles,
    };
  case PENDING_ENDORSERS_SET:
    return {
      ...state,
      pendingEndorsersProfiles: action.profiles,
    };
  case TOTAL_USERS_SET:
    return {
      ...state,
      totalUsers: {
        ...state.totalUsers,
        totalCompanies: action.totalUsers.totalCompanies,
        totalEndorsers: action.totalUsers.totalEndorsers,
        totalJobSeekers: action.totalUsers.totalJobSeekers,
      },
    };
  case ADMINISTRATORS_RESET:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  resetAdministrators,
  setPendingCompanies,
  setPendingEndorsers,
  setTotalUsers,
};

const types = {};

export {
  actions,
  reducer,
  types,
};
