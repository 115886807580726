/**
 * Greek translations for utilities/criterion-types.
 *
 * @module languages/el/utilities/criterion-types
 */
const criterionTypes = {
  CITY: 'Τοποθεσία',
  JOB_SECTOR: 'Επαγγελματικός κλάδος',
  TERM: 'Αναζήτηση',
};

export default criterionTypes;
