/**
 * English translations for job-seekers/profile-experiences.
 *
 * @module languages/en/job-seekers/profile-experiences
 */
const profileExperiences = {
  company_label: 'Company',
  company_placeholder: 'Company',
  company_required_error: 'Company is required.',
  current_label: 'I am currently working in this position',
  description_label: 'Description',
  description_placeholder: 'Description',
  editor_title: 'Position',
  ended_in_after_now_error: 'End date cannot be in the future.',
  ended_in_label: 'End date',
  ended_in_placeholder: 'End date',
  ended_in_required_error: 'End date is required.',
  months_one: '{{count}} month',
  months_other: '{{count}} months',
  present: 'Present',
  see_all_button_label: 'See all',
  see_less_button_label: 'See less',
  skills_label: 'Skills',
  skills_placeholder: 'Select the skills you gained',
  started_in_after_ended_in_error: 'Start date cannot be after end date.',
  started_in_after_now_error: 'Start date cannot be in the future.',
  started_in_label: 'Start date',
  started_in_placeholder: 'Start date',
  started_in_required_error: 'Start date is required.',
  title: 'Experience',
  title_label: 'Job title',
  title_placeholder: 'Job title',
  title_required_error: 'Job title is required.',
  years_one: '{{count}} year',
  years_other: '{{count}} years',
};

export default profileExperiences;
