/* eslint-disable */
import React, { memo } from 'react';

const ShieldCardIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7173_15007)'>
        <path
          d='M18.65 2.26663L6.98333 7.44996C5.78333 7.98329 5 9.18329 5 10.5V18.3333C5 27.5833 11.4 36.2333 20 38.3333C28.6 36.2333 35 27.5833 35 18.3333V10.5C35 9.18329 34.2167 7.98329 33.0167 7.44996L21.35 2.26663C20.5 1.88329 19.5 1.88329 18.65 2.26663ZM15.4833 27.15L11.1667 22.8333C10.5167 22.1833 10.5167 21.1333 11.1667 20.4833C11.8167 19.8333 12.8667 19.8333 13.5167 20.4833L16.6667 23.6166L26.4667 13.8166C27.1167 13.1666 28.1667 13.1666 28.8167 13.8166C29.4667 14.4666 29.4667 15.5166 28.8167 16.1666L17.8333 27.15C17.2 27.8 16.1333 27.8 15.4833 27.15Z'
          fill='#AA91C9'
        />
      </g>
      <defs>
        <clipPath id='clip0_7173_15007'>
          <rect fill='white' height='40' width='40' />
        </clipPath>
      </defs>
    </svg>
  );
});

ShieldCardIcon.displayName = 'ShieldCardIcon';

ShieldCardIcon.propTypes = {};

ShieldCardIcon.defaultProps = {};

export default ShieldCardIcon;
