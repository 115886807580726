/**
 * Greek translations for header.
 *
 * @module languages/el/header
 */
const header = {
  about_us_link_text: 'Σχετικά με εμάς',
  find_talent_menu: {
    items: {
      item1: 'Περισσότερες πληροφορίες',
      item2: 'Υποστηρικτές',
      item3: 'Κοινωνική ευθύνη',
      item4: 'Αναζήτηση ταλέντων',
    },
    title: 'Βρείτε ταλέντα',
  },
  find_work_menu: {
    items: {
      item1: 'Περισσότερες πληροφορίες',
      item2: 'Κάντε αξιολόγηση',
      item3: 'Αναζήτηση εταιρειών',
    },
    title: 'Αναζήτηση εργασίας',
  },
  profile_menu: {
    items: {
      item1: 'Προφίλ',
      item2: 'Ρυθμίσεις',
      item3: 'Αποσύνδεση',
    },
  },
  sign_in_button_label: 'Συνδεθείτε',
  sign_up_button_label: 'Εγγραφείτε',
};

export default header;
