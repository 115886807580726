/**
 * Greek translations for common/profile-overview.
 *
 * @module languages/el/common/profile-overview
 */
const profileOverview = {
  edit_profile_button_label: 'Επεξεργασία προφίλ',
};

export default profileOverview;
