/**
 * Greek translations for job-seekers/take-assessment.
 *
 * @module languages/el/job-seekers/take-assessment
 */
const takeAssessment = {
  cards_section: {
    cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Δείτε την αξιολόγησή σας σε διαφορετικά χαρακτηριστικά όπως: Προσοχή, Προσπάθεια, Δικαιοσύνη, Λήψη Αποφάσεων, Συναίσθημα, Εστίαση, Γενναιοδωρία, Μάθηση, Ανοχή Κινδύνου. Δεν υπάρχουν καλές και κακές απαντήσεις αλλά διαφορετικές προσωπικότητες.',
        title: 'Γνώρισε τα χαρακτηριστικά σου',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Κατανοήστε τον εαυτό σου καλύτερα και μάθε ποια είναι τα δυνατά σου σημεία και ποια σημεία πρέπει να αναπτύξεις στην καθημερινή σου ζωή.',
        title: 'Ανάπτυξε την προσωπικότητά σου',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Δεν είσαι σίγουρος για το ποιο θα είναι το επόμενο βήμα σου; Τα χαρακτηριστικά σου αντιστοιχίζονται με τα κατάλληλα επαγγέλματα. Μάθε τι σου ταιριάζει καλύτερα.',
        title: 'Κατάλληλα επαγγελματικά βήματα',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Έχοντας τα χαρακτηριστικά σου, η πλατφόρμα μπορεί να προωθήσει το προφίλ σου σε κατάλληλους εργοδότες που αναζητούν τις δεξιότητές σου. Δεν χρειάζεται να ψάχνεις για θέσεις εργασίας, οι εταιρείες θα σε βρουν!',
        title: 'Προσωποποιημένες συστάσεις',
      },
      item5: {
        // eslint-disable-next-line max-len
        description: 'Συμπλήρωσε το βιογραφικό σου σημείωμα με τα χαρακτηριστικά σου και δώσε στους εργοδότες την ευκαιρία να κατανοήσουν τις πραγματικές σου δυνατότητες. ',
        title: 'Εξασφάλισε πλεονέκτημα έναντι άλλων υποψηφίων',
      },
      last_item: {
        description: 'Η όλη διαδικασία είναι πραγματικά εύκολη και γρήγορη, παίζοντας απλώς μερικά παιχνίδια!',
      },
    },
  },
  first_section: {
    button_label: 'Έρχεται σύντομα',
    // eslint-disable-next-line max-len
    description: 'Η πλατφόρμα μας προσφέρει μια σειρά εργαλείων αξιολόγησης για να σε βοηθήσει να εντοπίσεις τα καλύτερα χαρακτηριστικά σου. Χρησιμοποιώντας αυτά τα εργαλεία μπορείς να αναγνωρίσεις την καταλληλότερη πορεία καριέρας για σένα και να παρουσιάσεις με τον καλύτερο τρόπο το προφίλ σου σε πιθανούς εργοδότες.',
    title: 'Παίξε τα παιχνίδια και ανακάλυψε το ταλέντο σου!',
  },
};

export default takeAssessment;
