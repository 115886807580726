import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import VideoGuideCard from './video-guide-card/video-guide-card';
import './videos-and-guides-section.scss';

const VideosAndGuidesSection = memo(() => {
  const { t } = useTranslation();

  const videosAndGuidesInfo = useMemo(() => {
    return [
      {
        description: 'Searching for the best candidate can be a tedious job.'
          + ' But this is where our filters can help you efficiently.',
        title: 'How to efficiently search for talent',
        type: 'video',
      },
      {
        description: 'Build your company’s EDI (equity, diversity and inclusion) '
          + 'strategy using Odyssea Talent platform.',
        title: 'Bring inclusion and diversity in your company',
        type: 'video',
      },
      {
        description: 'Filtering hundred of profiles and CVs can be a really long and difficult process. '
          + 'Why not let us do it for you? He manage daily hundred of profiles from job seekers. We interview them, '
          + 'check their legal documents and support them with training when needed. Let us know your job position '
          + 'details and we can match for you the best candidates.',
        title: 'Recruiting services',
        type: 'guide',
      },
      {
        description: 'You can find several articles regarding the Odyssea Talent Platform, how to use it,'
          + ' what technologies and features we innovate, our vision, our other activities and much more. '
          + 'Read about our psychometric tools, advanced filters and matching services.',
        title: 'Read our blog',
        type: 'guide',
      },
    ];
  }, []);

  const videosAndGuides = useMemo(() => {
    return videosAndGuidesInfo.map((videoAndGuide, index) => {
      return (
        <VideoGuideCard
          key={ `${ videoAndGuide.title }-${ index }` }
          videoGuide={ videoAndGuide }
        />
      );
    });
  }, [ videosAndGuidesInfo ]);

  return (
    <div className='videos-and-guides'>
      <h2 className='videos-and-guides__title hdg hdg-md'>
        { t('common:videos_and_guides.title') }
      </h2>
      <div className='videos-and-guides__content'>
        { videosAndGuides }
      </div>
    </div>
  );
});

VideosAndGuidesSection.displayName = 'VideosAndGuidesSection';

VideosAndGuidesSection.propTypes = {};

VideosAndGuidesSection.defaultProps = {};

export default VideosAndGuidesSection;
