import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './average-bubbles.scss';

const AverageBubbles = memo((props) => {
  const { average, small } = props;

  // The percentage of the decimal average part
  const backgroundPercentage = useMemo(() => {
    return (average - Math.trunc(average)) * 100;
  }, [ average ]);

  // The rendered bubbles
  const renderedBubbles = useMemo(() => {
    return Array.from(Array(5).keys()).map((item) => {
      if (item + 1 <= average) {
        return (
          <div
            className={
              clsx('average-bubbles__bubble average-bubbles__bubble--whole', {
                'average-bubbles__bubble--small': small,
              })
            }
            key={ item + 1 }
          />
        );
      }
      if (average < item + 1 && average >= item) {
        return (
          <div
            className={
              clsx('average-bubbles__bubble', {
                'average-bubbles__bubble--small': small,
              })
            }
            key={ item + 1 }
            style={
              {
                background: `linear-gradient(to right, 
                #5096B4 ${ backgroundPercentage }%, white ${ backgroundPercentage }%)`,
              }
            }
          />
        );
      }
      return (
        <div
          className={
            clsx('average-bubbles__bubble', {
              'average-bubbles__bubble--small': small,
            })
          }
          key={ item + 1 }
        />
      );
    });
  }, [ average, backgroundPercentage, small ]);

  return (
    <div
      className='average-bubbles'
      title={ `${ average }/5` }
    >
      { renderedBubbles }
    </div>
  );
});

AverageBubbles.displayName = 'AverageBubbles';

AverageBubbles.propTypes = {
  // The average number to be displayed through the bubbles
  average: PropTypes.number,
  // Whether the bubbles are small
  small: PropTypes.bool,
};

AverageBubbles.defaultProps = {
  small: false,
};

export default AverageBubbles;
