/**
 * English translations for utilities/qualification-types.
 *
 * @module languages/en/utilities/qualification-types
 */
const qualificationTypes = {
  AWARD: 'Award',
  BACHELORS_DEGREE: 'Bachelor\'s degree',
  DIPLOMA: 'Diploma',
  DOCTORAL_DEGREE: 'Doctoral degree',
  MASTERS_DEGREE: 'Master\'s degree',
  MBA: 'MBA',
  OTHER_CERTIFICATE: 'Other certificate',
  VOCATIONAL_CERTIFICATE: 'Vocational certificate',
};

export default qualificationTypes;
