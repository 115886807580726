/**
 * English translations for about-us.
 *
 * @module languages/en/about-us
 */
const aboutUs = {
  how_does_it_work_section: {
    steps: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Job seekers register. They complete their profile with video presentation and psychometric tools.',
        title: 'Step 1',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Odyssea and collaborating partners meet with job seekers and provide feedback and verification on their profile.',
        title: 'Step 2',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Companies can look for talent. They can filter their search according to skills, verifications and psychometric results and contact potential candidates.',
        title: 'Step 3',
      },
    },
    title: 'How does it work?',
  },
  matching_problem_section: {
    matching_title: 'But matching is really difficult!',
    problems: {
      item1: {
        description: 'HORECA, Construction, Services etc.',
        title: 'Growing needs in the labour market',
      },
      item2: {
        description: '1 million unemployed in Greece',
        title: 'High unemployment rate',
      },
    },
    title: 'The Problem',
  },
  milestones_section: {
    milestones: {
      item1: {
        description: 'Public presentation and Registrations of job seekers and companies',
        title: 'Q2 2023',
      },
      item2: {
        description: 'Market feedback and preparation for new features',
        title: 'Q3 2023',
      },
      item3: {
        description: 'Development of new features and platform improvements.',
        title: 'Q4 2023',
      },
      item4: {
        description: 'Second version is live',
        title: 'Q1 2024',
      },
    },
    months: {
      item1: 'April',
      item10: 'January',
      item11: 'February',
      item12: 'March',
      item2: 'May',
      item3: 'June',
      item4: 'July',
      item5: 'August',
      item6: 'September',
      item7: 'October',
      item8: 'November',
      item9: 'December',
    },
    title: 'Milestones',
  },
  our_supporters_section: {
    title: 'Our supporters',
  },
  solution_section: {
    // eslint-disable-next-line max-len
    description: 'Odyssea is creating an innovative online employability platform to address the problem.\n\nThe platform combines innovative tools such as psychometrics, profile verification and AI recommendations to support both job seekers and employers.',
    title: 'Our Solution',
  },
  target_group_section: {
    cards: {
      item1: 'HORECA\n\nConstruction Services\n\nBusiness\n\nTech Industries\n',
      item2: 'Unemployed\n\nThird country nationals\n\nYouth entering the labour marker',
    },
    title: 'Our target group',
  },
  team_section: {
    team_members: {
      member1: {
        job_title: 'CEO',
        name: 'Jai Mexis',
      },
      member2: {
        job_title: 'CTO',
        name: 'Loukas Oikonomakis',
      },
      member3: {
        job_title: 'Finance Manager',
        name: 'Anastasia Zisi',
      },
      member4: {
        job_title: 'Program Manager',
        name: 'Thodoris Kostoulas',
      },
      member5: {
        job_title: 'Employability Manager',
        name: 'Charoula Roufa',
      },
      member6: {
        job_title: 'Employability Officer',
        name: 'Panagiota Koutri',
      },
      member7: {
        job_title: 'Field Officer',
        name: 'Janette Mensah',
      },
    },
    title: 'The Team',
  },
  unemployment_section: {
    info: {
      item1: 'Employers have to filter tons of CVs',
      item2: 'Need for tedious background check on potential employee’s profile and papers',
      item3: 'Job seekers do not have the tools to efficiently capture their experience and soft skills',
      item4: 'Mismatch of expectations between employees and employers',
    },
    title: 'Why?',
  },
  vision_mission_section: {
    // eslint-disable-next-line max-len
    mission_description: 'Our mission is to ensure that all young people have equal opportunities in society by harnessing the power of knowledge for employment and personal development',
    mission_title: 'Mission',
    title: 'About Odyssea',
    vision_description: 'A world where all young people are able to realise their full potential',
    vision_title: 'Vision',
  },
  welcome_section: {
    button_label: 'Visit our website',
    // eslint-disable-next-line max-len
    description: 'Odyssea is a nonprofit organization that supports young vulnerable people to have access to employment opportunities in society. We provide tailored vocational and life-skills training combined with employability services that bridge young people with the world of work and empower them to realize their full potential.',
    key_metrics: {
      item1: {
        label: 'Registered per year',
      },
      item2: {
        label: 'Students per year',
      },
      item3: {
        label: 'Employability services per year',
      },
      item4: {
        label: 'Find employability per year',
      },
      item5: {
        label: 'Collaborating companies',
      },
    },
    key_metrics_title: 'Odyssea Key Metrics',
    title: 'About Us',
  },
};

export default aboutUs;
