import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import './total-users-card.scss';

const TotalUsersCard = memo((props) => {
  const { title, usersNumber, viewLink, linkTitle } = props;

  return (
    <div className='total-users-card'>
      <div className='total-users-card__title-and-number'>
        <h2 className='hdg hdg-lg'>
          { title }
        </h2>
        <p className='hdg hdg-xxl'>
          { usersNumber }
        </p>
      </div>
      <Link
        className='btn btn-sm btn-rounded-sm btn-blue total-users-card__link'
        to={ viewLink }
      >
        { linkTitle }
      </Link>
    </div>
  );
});

TotalUsersCard.displayName = 'TotalUsersCard';

TotalUsersCard.propTypes = {
  // The link title
  linkTitle: PropTypes.string,
  // The card title
  title: PropTypes.string,
  // The users number to display
  usersNumber: PropTypes.number,
  // The view link
  viewLink: PropTypes.string,
};

TotalUsersCard.defaultProps = {
  linkTitle: '',
  title: '',
  usersNumber: 0,
  viewLink: '',
};

export default TotalUsersCard;
