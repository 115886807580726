/**
 * English translations for sign-in-form.
 *
 * @module languages/en/sign-in-form
 */
const signInForm = {
  bad_credentials_error: 'Email and/or password are wrong.',
  continue_with_google_button_label: 'Continue with Google',
  continue_with_linkedin_button_label: 'Continue with LinkedIn',
  dont_have_account: 'Don\'t have an account?',
  email_invalid_error: 'Enter a valid email address.',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Email is required.',
  forgot_password_link_text: 'Forgot password?',
  general_error: 'Oops! Something went wrong.',
  locked_account_error: 'Your account is locked. Please try again later.',
  new_account_error: 'You have not verified your account yet. Please check your inbox or contact Odyssea for help.',
  or: 'or',
  password_label: 'Password',
  password_placeholder: 'Password',
  password_required_error: 'Password is required.',
  rejected_account_error: 'Your account request has been rejected.',
  retrieve_google_profile_error: 'Failed to retrieve user\'s information via Google',
  retrieve_linkedin_profile_error: 'Failed to retrieve user\'s information via LinkedIn',
  sign_in_button_label: 'Sign in',
  sign_up_for_free_link_text: 'Sign up for free',
  suspended_account_error: 'Your account has been suspended.',
  user_not_found_error: 'It seems you don\'t have an account in the platfom. You have to sign up first.',
  verified_account_error: 'Your account request is pending approval.',
  verify_with_google_error: 'Something went wrong verifying with Google. Please refresh your browser and try again.',
  // eslint-disable-next-line max-len
  verify_with_linkedin_error: 'Something went wrong verifying with LinkedIn. Please refresh your browser and try again.',
};

export default signInForm;
