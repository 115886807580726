import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Carousel from 'components/common/carousel/carousel';
import useElementOnScreen from 'hooks/use-element-on-screen';

import PublicTestimonial from './public-testimonial/public-testimonial';

import './public-testimonials-section.scss';

const PublicTestimonialsSection = memo((props) => {
  const { title, testimonials } = props;

  // Whether the section is visible to the DOM the first time
  const [ isSectionVisible, setIsSectionVisible ] = useState(false);
  // Whether a video is in full screen mode
  const [ isInFullScreen, setIsInFullScreen ] = useState(false);

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // the fullscreen callback function
  const fullScreenCallback = useCallback((_event) => {
    if (document.fullscreenElement) {
      setIsInFullScreen(true);
    } else {
      setIsInFullScreen(false);
    }
  }, []);

  // Listen for video full screen events
  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenCallback);
    return () => {
      document.removeEventListener('fullscreenchange', fullScreenCallback);
    };
  }, [ fullScreenCallback ]);

  // Set the first time that the section is visible the visibility
  useEffect(() => {
    if (!isVisible) {
      return;
    }
    setIsSectionVisible(true);
  }, [ isVisible ]);

  // The rendered testimonials
  const renderedTestimonials = useMemo(() => {
    return testimonials.map((testimonialItem, testimonialItemIndex) => {
      return (
        <PublicTestimonial
          key={ `Root-testimonial-${ testimonialItemIndex }` }
          testimonial={ testimonialItem }
        />
      );
    });
  }, [ testimonials ]);

  // The rendered carousel is rendered when the section is in the DOM
  // to increase performance of the first load and start fetching videos images and thumbnails after the section
  // is in the DOM
  const renderedCarousel = useMemo(() => {
    if (!isSectionVisible) {
      return null;
    }
    return (
      <Carousel
        autoplay={ isVisible && !isInFullScreen }
        infiniteLoop={ 1 < testimonials?.length }
        stopOnHover
      >
        { renderedTestimonials }
      </Carousel>
    );
  }, [ isInFullScreen, isSectionVisible, isVisible, renderedTestimonials, testimonials?.length ]);

  return (
    <div
      className='public-testimonials-section'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl public-testimonials-section__title'>
        { title }
      </h2>
      { renderedCarousel }
    </div>
  );
});

PublicTestimonialsSection.displayName = 'PublicTestimonialsSection';

PublicTestimonialsSection.propTypes = {
  // The testimonials collection
  testimonials: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        // Video testimonial
        PropTypes.shape({
          // the age location
          ageLocation: PropTypes.string,
          // the name of the person of the testimonial
          name: PropTypes.string,
          // The title of the testimonial
          title: PropTypes.string,
          // the video url of the testimonial
          videoUrl: PropTypes.string,
        }),
        // The description image testimonial
        PropTypes.shape({
          // the age location
          ageLocation: PropTypes.string,
          // the image backgroundPosition
          backgroundPosition: PropTypes.string,
          // The description of the testimonial
          description: PropTypes.string,
          // The image url of the testimonial
          imageUrl: PropTypes.string,
          // the name of the person of the testimonial
          name: PropTypes.string,
          // The title of the testimonial
          title: PropTypes.string,
        }),
      ])
    )
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

PublicTestimonialsSection.defaultProps = {};

export default PublicTestimonialsSection;
