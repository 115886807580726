/**
 * English translations for forgot-password.
 *
 * @module languages/en/forgot-password
 */
const forgotPassword = {
  captcha_label: 'Captcha',
  email_invalid_error: 'Enter a valid email address.',
  email_placeholder: 'Email',
  email_required_error: 'Email is required.',
  general_error: 'Oops! Something went wrong.',
  instructions: 'Enter your email address to reset your password.',
  locked_account_error: 'Your account is locked. Please try again later.',
  new_account_error: 'You have not verified your account yet. Please check your inbox or contact Odyssea for help.',
  rejected_account_error: 'Your account request has been rejected.',
  reset_password_button_label: 'Reset password',
  // eslint-disable-next-line max-len
  sent_message: 'An email has been sent to the email address you specified. Please check your inbox, and follow the instructions in that email to reset your password.',
  suspended_account_error: 'Your account has been suspended.',
  title: 'Forgot password?',
  // eslint-disable-next-line max-len
  user_not_found_error: 'It seems you don\'t have a local account for resetting your password. You have to sign up locally, first or try another authentication provider.',
  verified_account_error: 'Your account request is pending approval.',
};

export default forgotPassword;
