import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ArrowCardRightIcon from 'components/common/icons/arrow-card-right-icon/arrow-card-right-icon';
import CardListArrowIcon from 'components/common/icons/card-list-arrow-icon/card-list-arrow-icon';
import useElementOnScreen from 'hooks/use-element-on-screen';
import roles, * as Roles from 'utilities/auth/roles';
import pricingPlans from 'utilities/pricing-plans';

import './learn-more-hr-package.scss';

const LearnMoreHrPackage = memo((props) => {
  const {
    basicPricingPlan,
    title,
    titleDescription,
    pricingDescription,
    pricingPlan,
    buttonLabel,
    descriptionCollection,
    userAuthenticated,
    handlePricingPlanClick,
    userRole,
  } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // The rendered pricing description
  const renderedPricingDescription = useMemo(() => {
    if (!pricingDescription) {
      return null;
    }
    return (
      <p className='txt txt-md learn-more-hr-package__pricing-description'>
        { pricingDescription }
      </p>
    );
  }, [ pricingDescription ]);

  // The rendered title description
  const renderedTitleDescription = useMemo(() => {
    if (!titleDescription) {
      return null;
    }
    return (
      <span className='txt txt-md learn-more-hr-package__title-description'>
        &nbsp;&#8208;&nbsp;
        { titleDescription }
      </span>
    );
  }, [ titleDescription ]);

  // The rendered description items
  const renderedDescriptionItems = useMemo(() => {
    return descriptionCollection.map((descriptionItem) => {
      return (
        <li
          className='learn-more-hr-package__description-item'
          key={ descriptionItem }
        >
          <div className='learn-more-hr-package__description-icon-container'>
            <CardListArrowIcon />
          </div>
          <span className='txt txt-md learn-more-hr-package__description-text'>
            { descriptionItem }
          </span>
        </li>
      );
    });
  }, [ descriptionCollection ]);

  // The click handler of the choose plan button click
  const handleChoosePlanButtonClick = useCallback((pricingPlan, basicPricingPlan) => {
    return (_event) => {
      handlePricingPlanClick(pricingPlan, basicPricingPlan);
    };
  }, [ handlePricingPlanClick ]);

  // Whether the pricing interest button should be disabled
  const buttonDisabled = useMemo(() => {
    return userRole !== Roles.COMPANY_AGENT;
  }, [ userRole ]);

  // The rendered button
  const renderedButton = useMemo(() => {
    if (userAuthenticated) {
      return (
        <button
          className='btn btn-sm btn-rounded-sm btn-blue learn-more-hr-package__button'
          disabled={ buttonDisabled }
          id='choose-plan'
          name='Choose Plan'
          onClick={ handleChoosePlanButtonClick(pricingPlan, basicPricingPlan) }
          type='button'
        >
          { buttonLabel }
          <ArrowCardRightIcon />
        </button>
      );
    }
    return (
      <Link
        className='btn btn-sm btn-rounded-sm btn-blue learn-more-hr-package__button'
        to='/companies/sign-up'
      >
        { buttonLabel }
        <ArrowCardRightIcon />
      </Link>
    );
  }, [
    basicPricingPlan,
    buttonDisabled,
    buttonLabel,
    handleChoosePlanButtonClick,
    pricingPlan,
    userAuthenticated,
  ]);

  return (
    <div
      className={
        clsx('learn-more-hr-package', {
          'learn-more-hr-package__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='learn-more-hr-package__title-section'>
        <div className='learn-more-hr-package__title-container'>
          <h4 className='txt txt-lg txt-bold learn-more-hr-package__title'>
            { title }
          </h4>
          { renderedTitleDescription }
        </div>
        { renderedPricingDescription }
      </div>
      <ul className='learn-more-hr-package__description-container'>
        { renderedDescriptionItems }
      </ul>
      { renderedButton }
    </div>
  );
});

LearnMoreHrPackage.displayName = 'LearnMoreHrPackage';

LearnMoreHrPackage.propTypes = {
  // The basic pricing plan
  basicPricingPlan: PropTypes.string,
  // The pricing plan button label
  buttonLabel: PropTypes.string.isRequired,
  // The description collection of the package
  descriptionCollection: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The click handler of the card
  handlePricingPlanClick: PropTypes.func.isRequired,
  // The pricing description of the package
  pricingDescription: PropTypes.string,
  // The pricing plan
  pricingPlan: PropTypes.oneOf(pricingPlans).isRequired,
  // The title of the package
  title: PropTypes.string.isRequired,
  // The title description of the package
  titleDescription: PropTypes.string,
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
  // The user role
  userRole: PropTypes.oneOf(roles),
};

LearnMoreHrPackage.defaultProps = {
  pricingDescription: '',
  titleDescription: '',
};

export default LearnMoreHrPackage;
