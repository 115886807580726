import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import BubblesAnimationIcon from 'animations/circles.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './take-assesment-first-section.scss';

const TakeAssessmentFirstSection = memo(() => {
  const { t } = useTranslation();

  return (
    <div className='take-assessment-first-section'>
      <LottieAnimationContainer
        animatedIconClassName='take-assessment-first-section__animated-icon'
        animationData={ BubblesAnimationIcon }
        className='take-assessment-first-section__animation-container'
        loop={ false }
      />
      <div className='take-assessment-first-section__content'>
        <h2 className='hdg hdg-xl take-assessment-first-section__title'>
          { t('job_seekers:take_assessment.first_section.title') }
        </h2>
        <p className='txt txt-md take-assessment-first-section__description'>
          { t('job_seekers:take_assessment.first_section.description') }
        </p>
        <button
          className='take-assessment-first-section__button'
          disabled
          id='coming-soon'
          name='Coming Soon'
        >
          { t('job_seekers:take_assessment.first_section.button_label') }
        </button>
      </div>
    </div>
  );
});

TakeAssessmentFirstSection.displayName = 'TakeAssessmentFirstSection';

TakeAssessmentFirstSection.propTypes = {};

TakeAssessmentFirstSection.defaultProps = {};

export default TakeAssessmentFirstSection;
