/**
 * English translations for endorsers/profile-completed-endorsements.
 *
 * @module languages/en/endorsers/profile-completed-endorsements
 */
const profileCompletedEndorsements = {
  no_completed_endorsements: 'You do not have any completed endorsements yet',
  title: 'My endorsements',
  view_all_button_title: 'View All',
  view_less_button_title: 'View Less',
};

export default profileCompletedEndorsements;
