/**
 * Circular Image.
 */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './circular-image.scss';

const CircularImage = memo((props) => {
  const { imageSource, alt } = props;

  return (
    <img
      alt={ alt }
      className='ody-circular-image'
      src={ imageSource }
    />
  );
});

CircularImage.displayName = 'CircularImage';

CircularImage.propTypes = {
  // The alt of the image
  alt: PropTypes.string.isRequired,
  // The image source that will be displayed as a circular image
  imageSource: PropTypes.string.isRequired,
};

CircularImage.defaultProps = {
};

export default CircularImage;
