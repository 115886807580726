/**
 * English translations for common/search-sort.
 *
 * @module languages/en/common/search-sort
 */
const searchSort = {
  sort_by_button_label: 'Sort by',
};

export default searchSort;
