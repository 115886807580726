/**
 * Greek translations for endorsers/profile-completed-endorsements.
 *
 * @module languages/el/endorsers/profile-completed-endorsements
 */
const profileCompletedEndorsements = {
  no_completed_endorsements: 'Δεν έχετε ακόμη ολοκληρωμένες συστάσεις',
  title: 'Οι συστάσεις μου',
  view_all_button_title: 'Προβολή όλων',
  view_less_button_title: 'Δείτε λιγότερα',
};

export default profileCompletedEndorsements;
