/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const FlagsCardIcon = memo((props) => {
  const { isWhite } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_9398_7901)'>
        <path
          d='M30.3719 19.4717C27.6348 19.7206 19.5546 19.8515 18.4414 23.2696C17.5378 26.0591 21.7678 26.7793 23.6275 27.1067C26.8491 27.6568 33.0304 26.3341 35.833 27.9842C39.0153 29.8569 31.0267 29.5164 30.0052 29.5164C27.7527 29.5033 25.4871 29.2938 23.2215 29.2283C18.0354 29.0973 9.47061 28.2592 5.04415 31.638C4.72984 31.8737 4.57269 32.1749 4.66436 32.5678C6.15731 39.0111 24.2561 36.2609 29.4814 36.418C31.2494 36.4704 35.9639 36.0775 37.4176 37.2038C40.6261 39.6921 30.516 40.7397 28.7218 40.8707C19.7903 41.4862 10.4528 41.5255 1.58679 40.3338C1.66536 40.8707 1.73084 41.4207 1.80942 41.9577C1.99276 41.8922 2.16301 41.8267 2.34636 41.7612C3.34166 41.4076 2.90949 39.8099 1.90109 40.1635C1.71775 40.229 1.5475 40.2945 1.36415 40.36C0.434332 40.6874 0.670061 41.866 1.58679 41.9839C8.86819 42.9661 16.1627 42.8089 23.4965 42.6649C28.2635 42.5732 33.4102 42.6518 37.876 40.7004C43.6644 38.1729 39.6701 35.7763 35.5056 35.187C32.3625 34.7417 8.71104 35.6585 8.63247 32.9214C7.82051 31.7427 8.21339 31.2844 9.79802 31.5594C10.8981 31.3499 11.9982 31.2189 13.1113 31.1534C14.1983 31.0487 15.2853 30.9832 16.3722 30.9308C20.1963 30.7344 23.968 30.9308 27.792 31.0617C30.9482 31.1796 37.601 32.3059 39.5523 28.9009C39.788 28.4949 39.6308 28.0235 39.2511 27.7615C36.4878 25.8495 33.1745 25.9019 29.9005 25.7971C28.6563 25.7578 22.8155 26.0983 22.2393 25.1423C19.8951 21.279 29.4028 21.2004 30.3588 21.1088C31.4196 21.0302 31.4327 19.367 30.3719 19.4717Z'
          fill={ isWhite ?  '#ffffff' : '#161616' }
        />
        <path
          d='M31.1441 19.3539C29.8475 13.7094 30.8036 7.09593 30.5809 1.30746C30.5547 0.613372 29.7297 0.246692 29.2189 0.744343C28.9177 1.03246 28.8653 1.26818 28.8392 1.66106C28.7868 2.69565 30.3845 2.68256 30.4369 1.66106C30.4107 1.72654 30.3714 1.80513 30.3452 1.87061C29.8868 1.68726 29.4285 1.49081 28.9832 1.30746C29.2058 7.29237 28.2629 13.9452 29.5987 19.786C29.8344 20.7813 31.3667 20.3622 31.1441 19.3539Z'
          fill={ isWhite ?  '#ffffff' : '#161616' }
        />
        <path
          d='M29.4548 3.66472C28.2369 4.55525 27.307 4.00521 25.9843 3.80877C24.439 3.58614 22.396 3.45518 20.8638 3.80877C18.7815 4.29333 18.1922 6.17917 16.3064 6.96493C14.8527 7.56735 12.3775 7.80308 10.8584 7.33162C12.0501 9.36151 17.5636 10.4223 17.5243 10.2258C16.1361 11.9414 13.8443 12.2819 11.762 12.2295C14.2503 13.382 18.3886 13.1987 20.6673 11.6926C22.1734 10.6973 22.7365 10.0425 24.6224 10.0425C26.0367 10.0556 28.7345 10.3044 28.7083 10.4092'
          fill={ isWhite ?  '#ffffff' : '#161616' }
        />
        <path
          d='M29.2059 3.24568C26.6653 4.48981 23.0639 2.44684 20.2744 3.50762C19.4232 3.83502 18.8862 4.38506 18.166 4.92199C15.9265 6.61138 13.8443 7.47572 11.0024 6.8602C10.4917 6.75543 10.2298 7.30547 10.531 7.68526C11.8144 9.33536 15.5468 11.2081 17.6683 10.7104C17.5112 10.4354 17.354 10.1604 17.1969 9.88539C15.5598 11.4831 13.8705 11.7188 11.7751 11.745C11.1989 11.7581 11.1465 12.5177 11.6441 12.7141C14.2764 13.7094 17.3671 13.788 19.9863 12.6356C21.2697 12.0593 22.1472 11.2081 23.5354 10.8414C24.989 10.4616 27.1237 10.4485 28.6035 10.9069C29.2059 11.0902 29.4679 10.1473 28.8654 9.96396C27.1237 9.42702 24.4521 9.25678 22.7365 9.88539C21.6102 10.2914 20.7066 11.1688 19.6196 11.6664C17.1838 12.7796 14.3943 12.7141 11.906 11.7712C11.8668 12.0986 11.8144 12.4129 11.7751 12.7403C14.1848 12.7141 16.1361 12.295 17.8909 10.5926C18.2576 10.239 17.8779 9.66276 17.4195 9.76753C15.6384 10.1866 12.1811 8.19599 11.2381 6.99115C11.081 7.26617 10.9238 7.54119 10.7667 7.81621C13.5954 8.43172 15.9396 7.86859 18.2445 6.25778C19.8554 5.13152 20.7852 4.14933 22.9198 4.08385C24.2556 4.04456 25.4998 4.31956 26.8225 4.47671C28.0535 4.62077 28.5904 4.66007 29.7429 4.09694C30.2667 3.80882 29.7691 2.97066 29.2059 3.24568Z'
          fill={ isWhite ?  '#ffffff' : '#161616' }
        />
      </g>
      <defs>
        <clipPath id='clip0_9398_7901'>
          <rect fill='white' height='42.2573' transform='translate(0.772461 0.514526)' width='40' />
        </clipPath>
      </defs>
    </svg>
  );
});

FlagsCardIcon.displayName = 'FlagsCardIcon';

FlagsCardIcon.propTypes = {
  // Whether the icon is white
  isWhite: PropTypes.bool,
};

FlagsCardIcon.defaultProps = {
  isWhite: false,
};

export default FlagsCardIcon;
