/**
 * Greek translations for reset-password.
 *
 * @module languages/el/reset-password
 */
const resetPassword = {
  general_error: 'Ωχ! Κάτι πήγε στραβά.',
  // eslint-disable-next-line max-len
  new_password_and_new_password_confirmation_mismatch_error: 'Ο νέος κωδικός πρόσβασης και η επιβεβαίωση νέου κωδικού πρόσβασης δεν ταιριάζουν',
  new_password_confirmation_label: 'Επιβεβαιώστε τον κωδικό σας',
  new_password_confirmation_placeholder: 'Επιβεβαιώστε τον κωδικό σας',
  // eslint-disable-next-line max-len
  new_password_invalid_error: 'Ο νέος κωδικός πρόσβασης πρέπει να περιέχει:\nΤουλάχιστον 8 χαρακτήρες. \nΤουλάχιστον ένα ψηφίο.\nΤουλάχιστον ένα πεζό γράμμα. \nΤουλάχιστον ένα κεφαλαίο γράμμα.\nΤουλάχιστον έναν ειδικό χαρακτήρα (!"#$%&\'()* ,-./:;<=>@[]^_`{|}~).',
  new_password_label: 'Νέος κωδικός',
  new_password_placeholder: 'Νέος κωδικός',
  new_password_required_error: 'Το πεδίο νέος κωδικός είναι απαραίτητο',
  reset_message: 'Ο κωδικός πρόσβασης σας επαναφέρθηκε. Τώρα μπορείτε να συνδεθείτε.',
  reset_password_button_label: 'Επαναφορά κωδικού πρόσβασης',
  title: 'Επαναφορά κωδικού πρόσβασης',
};

export default resetPassword;
