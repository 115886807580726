/**
 * English translations for common/videos-and-guides.
 *
 * @module languages/en/common/videos-and-guides
 */
const videosAndGuides = {
  title: 'Videos & Guides',
};

export default videosAndGuides;
