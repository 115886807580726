/**
 * English translations for job-seekers/take-assessment.
 *
 * @module languages/en/job-seekers/take-assessment
 */
const takeAssessment = {
  cards_section: {
    cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'See how your evaluation is in different traits such as: Attention, Effort, Fairness, Decision making, Emotion, Focus, Generosity, Learning, Risk Tolerance. There are no good and bad answers but different personalities.',
        title: 'Know your traits',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Understand yourself through your traits and learn what are your strengths and what you should develop in your everyday life.',
        title: 'Develop your personality',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Still unsure what your next career step will be? Your traits are matched with suitable career paths. Learn what suits you best and go for it.',
        title: 'Recommended suitable career paths',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'By having your traits, our platform can promote your profile to suitable employers looking for your skills. You don\'t need to look for jobs, companies will find you!',
        title: 'Personalised recommendations',
      },
      item5: {
        // eslint-disable-next-line max-len
        description: 'Complete your CV with your traits and give employers the chance to understand your true potential. ',
        title: 'Gain an edge against other candidates',
      },
      last_item: {
        description: 'The whole process is really easy and quick, by just playing a few games!',
      },
    },
  },
  first_section: {
    button_label: 'Coming soon',
    // eslint-disable-next-line max-len
    description: 'The Talent platform offers a series of assessment tools to help you identify your best features. Using these tools you can identify the most suitable career path for you and best present your profile to potential employers.',
    title: 'Play the games and discover your talent!',
  },
};

export default takeAssessment;
