/**
 * Greek translations for utilities/computer-skills-levels.
 *
 * @module languages/el/utilities/computer-skills-levels
 */
const computerSkillsLevels = {
  ADVANCED: 'Μέτρια (Βασική χρήση συν λογισμικά για συγκεκριμένες εργασίες)',
  BASIC: 'Βασική (Ίντερνετ, Email, Office, μέσα κοινωνικής δικτύωσης)',
  EXPERT: 'Προχωρημένη (Προγραμματισμός, δίκτυα, λειτουργικά συστήματα)',
  NO: 'Οχι',
};

export default computerSkillsLevels;
