/**
 * Settings - Account - Password.
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';

import PasswordEditor from './password-editor/password-editor';
import './password.scss';

const Password = (_props) => {
  const { t } = useTranslation();

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  return (
    <div className='password subsection'>
      <div className='title-and-text'>
        <div className='title'>
          { t('settings:account.password.title') }
        </div>
        <div className='text'>
          {
            Array(11).fill('*').join('')
          }
        </div>
      </div>
      <Modal
        className='settings-editor-modal'
        onClose={
          () => {
            setEditorOpen(false);
          }
        }
        onOpen={
          () => {
            setEditorOpen(true);
          }
        }
        open={ editorOpen }
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='button'
          >
            { t('settings:common.change_button_label') }
          </button>
        }
      >
        <PasswordEditor
          onClose={
            () => {
              setEditorOpen(false);
            }
          }
        />
      </Modal>
    </div>
  );
};

Password.propTypes = {
};

Password.defaultProps = {
};

export default Password;
