import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CaretDownWhite from 'images/caret-down-white.png';
import { isEmpty } from 'utilities/chisels';
import workAvailabilities, * as WorkAvailabilities from 'utilities/work-availabilities';

import './dashboard-work-availability.scss';

const DashboardWorkAvailability = memo((props) => {
  const { userProfile, onChange } = props;

  const { t } = useTranslation();

  // Whether work availability is expanded.
  const [ expanded, setExpanded ] = useState(false);

  // The work availability options array
  const workAvailabilityOptionsArray = useMemo(() => {
    return [
      WorkAvailabilities.AVAILABLE_NOW,
      WorkAvailabilities.AVAILABLE_IN_LESS_THAN_A_MONTH,
      WorkAvailabilities.AVAILABLE_AFTER_A_MONTH,
      WorkAvailabilities.NOT_AVAILABLE,
    ];
  }, []);

  // The work availability object is empty
  const emptyWorkAvailability = useMemo(() => {
    return isEmpty(userProfile?.workAvailability);
  }, [ userProfile?.workAvailability ]);

  // Current and select extra css class
  const specificWorkAvailabilityCSSClass = useMemo(() => {
    return userProfile?.workAvailability?.toLowerCase()?.replaceAll('_', '-');
  }, [ userProfile?.workAvailability ]);

  // The current work availability title
  const currentWorkAvailabilityTitle = useMemo(() => {
    return emptyWorkAvailability ? '' : t(`utilities:work_availabilities.${ userProfile?.workAvailability }`);
  }, [ emptyWorkAvailability, t, userProfile?.workAvailability ]);

  // Function that is invoked when the work availability button is clicked
  const handleWorkAvailabilityClick = useCallback(() => {
    setExpanded(!expanded);
  }, [ expanded ]);

  // Function that is invoked when changing the checkbox
  const handleOnChangedCheckbox = useCallback((workAvailability) => {
    setExpanded(false);
    return (_event) => {
      onChange(workAvailability);
    };
  }, [ onChange ]);

  // The work availability options
  const workAvailabilityOptions = useMemo(() => {
    return workAvailabilityOptionsArray.map((workAvailability) => {
      return (
        <div className='option' key={ workAvailability }>
          <label htmlFor={ workAvailability }>
            { t(`utilities:work_availabilities.${ workAvailability }`) }
          </label>
          <input
            checked={ userProfile?.workAvailability === workAvailability }
            id={ workAvailability }
            name='work-availability'
            onChange={ handleOnChangedCheckbox(workAvailability) }
            type='radio'
            value={ workAvailability }
          />
        </div>
      );
    });
  }, [ handleOnChangedCheckbox, t, userProfile?.workAvailability, workAvailabilityOptionsArray ]);

  return (
    <button
      className={
        clsx('work-availability', {
          'collapsed': !expanded,
          'expanded': expanded,
        })
      }
      onClick={ handleWorkAvailabilityClick }
      type='button'
    >
      <div
        className={
          clsx('current-and-select', {
            'expanded': expanded,
            'none': emptyWorkAvailability,
            [specificWorkAvailabilityCSSClass]: !emptyWorkAvailability,
          })
        }
      >
        <div className='current'>
          { currentWorkAvailabilityTitle }
        </div>
        <img
          alt='Caret'
          className={
            clsx('select', {
              'expanded': expanded,
            })
          }
          src={ CaretDownWhite }
        />
      </div>
      <div className='options'>
        { workAvailabilityOptions }
      </div>
    </button>
  );
});

DashboardWorkAvailability.displayName = 'DashboardWorkAvailability';

DashboardWorkAvailability.propTypes = {
  // The function () => void that is invoked when changing work availability
  onChange: PropTypes.func,
  // The user profile
  userProfile: PropTypes.shape({
    // The work availability
    workAvailability: PropTypes.oneOf(workAvailabilities),
  }).isRequired,
};

DashboardWorkAvailability.defaultProps = {};

export default DashboardWorkAvailability;
