import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Roles from 'utilities/auth/roles';

import HomeLearnMoreCard from './home-learn-more-card/home-learn-more-card';

import './home-learn-more-cards-section.scss';

const HomeLearnMoreCardsSection = memo((props) => {
  const { t } = useTranslation();

  const { cardsCollection, learnMoreButtonLink, title, role } = props;

  // The rendered cards
  const renderedCards = useMemo(() => {
    return cardsCollection.map((card) => {
      return (
        <HomeLearnMoreCard
          description={ card?.description ?? '' }
          icon={ card?.icon ?? null }
          key={ card?.title }
          role={ role }
          title={ card?.title ?? '' }
        />
      );
    });
  }, [ cardsCollection, role ]);

  return (
    <div
      className={
        clsx('home-learn-more-cards-section', {
          'home-learn-more-cards-section__job-seekers': role === Roles.JOB_SEEKER,
        })
      }
    >
      <h2 className='hdg hdg-lg'>
        { title }
      </h2>
      { renderedCards }
      <Link
        className='btn btn-sm btn-rounded-sm btn-blue home-learn-more-cards-section__learn-more-button'
        name='learn more'
        to={ learnMoreButtonLink }
      >
        <span className='txt txt-bold light home-learn-more-cards-section__learn-more-button-text'>
          { t('home:learn_more_section.learn_more_button_label') }
        </span>
      </Link>
    </div>
  );
});

HomeLearnMoreCardsSection.displayName = 'HomeLearnMoreCardsSection';

HomeLearnMoreCardsSection.propTypes = {
  // The collection to map the cards
  cardsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      // The card description
      description: PropTypes.string,
      // The icon of the card
      icon: PropTypes.node,
      // The title of the card
      title: PropTypes.string,
    })
  ).isRequired,
  // The 'learn-more' button link
  learnMoreButtonLink: PropTypes.string.isRequired,
  // Whether the section is a company or job seeker collection of cards
  role: PropTypes.oneOf([ Roles.JOB_SEEKER, Roles.COMPANY_AGENT ]).isRequired,
  // The cards section title
  title: PropTypes.string.isRequired,
};

HomeLearnMoreCardsSection.defaultProps = {};

export default HomeLearnMoreCardsSection;
