/**
 * English translations for utilities/genders.
 *
 * @module languages/en/utilities/genders
 */
const genders = {
  FEMALE: 'Female',
  MALE: 'Male',
  OTHER: 'Other',
};

export default genders;
