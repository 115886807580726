/**
 * Greek translations for job-seekers/download-profile-button.
 *
 * @module languages/el/job-seekers/download-profile-button
 */
const downloadProfileButton = {
  download_profile_button_label: 'Λήψη προφίλ',
};

export default downloadProfileButton;
