import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './about-us-member.scss';

const AboutUsMember = memo((props) => {
  const { imageSource, name, jobTitle } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('about-us-member', {
          'about-us-member__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='about-us-member__image-container'>
        <img
          alt={ name }
          className='about-us-member__image'
          src={ imageSource }
        />
      </div>
      <div className='about-us-member__info-container'>
        <p className='txt txt-md txt-bold about-us-member__name'>
          { name }
        </p>
        <p className='txt txt-md about-us-member__job-title'>
          { jobTitle }
        </p>
      </div>
    </div>
  );
});

AboutUsMember.displayName = 'AboutUsMember';

AboutUsMember.propTypes = {
  // The image source of the member
  imageSource: PropTypes.string.isRequired,
  // The job position of the member
  jobTitle: PropTypes.string.isRequired,
  // The name of the member
  name: PropTypes.string.isRequired,
};

AboutUsMember.defaultProps = {};

export default AboutUsMember;
