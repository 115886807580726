/**
 * English translations for common/confirm.
 *
 * @module languages/en/common/confirm
 */
const confirm = {
  no_button_label: 'No',
  yes_button_label: 'Yes',
};

export default confirm;
