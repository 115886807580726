/**
 * Resource method to get endorsers profiles.
 *
 * @module resources/endorsers/get-profiles
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/endorsers/get-profiles~params
 * @type {object}
 * @property {module:types/endorsers/searches~Filters} [filters] - The filters.
 * @property {module:types/common~Page} page - The page to get.
 * @property {string} [searchId] - The ID of the search to further refine.
 * @property {module:types/common~Sort} sort - The way to sort.
 */

/**
 * Result.
 *
 * @typedef module:resources/endorsers/get-profiles~Result
 * @type {object}
 * @property {module:types/endorsers/searches~Facets} [facets] - The facets.
 * @property {module:types/common~Meta} meta - The pagination metadata.
 * @property {module:types/endorsers/profiles~Profile[]} profiles - Some matching profiles.
 * @property {string} searchId - The ID of the search for further refinement.
 */

/**
 * Gets endorsers profiles based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/endorsers/get-profiles~Params} params - The parameters.
 * @returns {Promise<module:resources/endorsers/get-profiles~Result>} - The promise.
 * @static
 */
const getProfiles = (params) => {
  const searchParams = new URLSearchParams();
  if (undefined !== params.filters?.totalEndorsementsRange) {
    searchParams.append('filters.totalEndorsementsRange.end', params.filters?.totalEndorsementsRange.end);
    searchParams.append('filters.totalEndorsementsRange.start', params.filters?.totalEndorsementsRange.start);
  }
  searchParams.append('page.number', params.page.number);
  searchParams.append('page.size', params.page.size);
  if (undefined !== params.searchId) {
    searchParams.append('searchId', params.searchId);
  }
  searchParams.append('sort.direction', params.sort.direction);
  searchParams.append('sort.key', params.sort.key);
  return axios.request({
    method: 'get',
    params: searchParams,
    url: '/endorsers/profiles',
  }).then((response) => {
    return response.data;
  });
};

export default getProfiles;
