import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import OptionFilter from 'components/common/option-filter/option-filter';
import RangeFilter from 'components/common/range-filter/range-filter';
import { parseRange, toMonths, toYears } from 'utilities/chisels';
import * as CompaniesFilterTypes from 'utilities/companies/filter-types';
import CompaniesValidators from 'utilities/companies/validators';
import * as EndorsersFilterTypes from 'utilities/endorsers/filter-types';
import * as JobSeekersFilterTypes from 'utilities/job-seekers/filter-types';
import JobSeekersValidators from 'utilities/job-seekers/validators';

import './filter-box.scss';

const FilterBox = memo((props) => {
  const { onChange, filters, facet, facetKey, className, allProfessions } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Function that selects a filter of the given type.
  const selectFilter = useCallback((type) => {
    return (value) => {
      onChange([
        ...filters,
        {
          type,
          value,
        },
      ]);
    };
  }, [ filters, onChange ]);

  // Function that unselects a filter of the given type.
  const unselectFilter = useCallback((type) => {
    return (value) => {
      const filtersToChange = filters.filter((filter) => {
        return type !== filter.type || value !== filter.value;
      });
      onChange(filtersToChange);
    };
  }, [ filters, onChange ]);

  // Function that replaces a filter of the given type.
  // NOTE: Applicable only to filter types, for which at most one filter should exist at any point in time (e.g.
  // profile completion range).
  const replaceFilter = useCallback((type) => {
    const filtersToReplace = filters.filter((filter) => {
      return type !== filter.type;
    });
    return (value) => {
      onChange([
        ...filtersToReplace,
        {
          type,
          value,
        },
      ]);
    };
  }, [ filters, onChange ]);

  // The filter type
  const filterType = useMemo(() => {
    switch (facetKey) {
    case 'computerSkillsLevels':
      return JobSeekersFilterTypes.COMPUTER_SKILLS_LEVEL;
    case 'drivingLicenseCategories':
      return JobSeekersFilterTypes.DRIVING_LICENSE_CATEGORY;
    case 'experienceLengthRange':
      return JobSeekersFilterTypes.EXPERIENCE_LENGTH;
    case 'languages':
      return JobSeekersFilterTypes.LANGUAGE;
    case 'professions':
      return JobSeekersFilterTypes.PROFESSION;
    case 'profileCompletionPercentageRange':
      return JobSeekersFilterTypes.PROFILE_COMPLETION_PERCENTAGE;
    case 'qualificationTypes':
      return JobSeekersFilterTypes.QUALIFICATION_TYPE;
    case 'relocationIntentions':
      return JobSeekersFilterTypes.WILLING_TO_RELOCATE;
    case 'workAvailabilities':
      return JobSeekersFilterTypes.WORK_AVAILABILITY;
    case 'numberOfEmployees':
      return CompaniesFilterTypes.NUMBER_OF_EMPLOYEES;
    case 'totalEndorsementsRange':
      return EndorsersFilterTypes.TOTAL_ENDORSEMENTS_RANGE;
    default:
      return '';
    }
  }, [ facetKey ]);

  // handle the change in the range filter.
  // We are only check for one key since we have only 2 filters that use range filter component
  // if there are more we should change it a switch case scenario.
  const handleChangeRangeFilter = useCallback((value) => {
    let range;
    switch (facetKey) {
    case 'experienceLengthRange':
      range = toMonths(parseRange(value));
      replaceFilter(JobSeekersFilterTypes.EXPERIENCE_LENGTH)(`${ range.start }-${ range.end }`);
      break;
    case 'profileCompletionPercentageRange':
      range = parseRange(value);
      replaceFilter(JobSeekersFilterTypes.PROFILE_COMPLETION_PERCENTAGE)(`${ range.start }-${ range.end }`);
      break;
    case 'totalEndorsementsRange':
      range = parseRange(value);
      replaceFilter(EndorsersFilterTypes.TOTAL_ENDORSEMENTS_RANGE)(`${ range.start }-${ range.end }`);
      break;
    default:
      break;
    }
  }, [ facetKey, replaceFilter ]);

  // The RangeFilter component range prop.
  // Again here we only check one filter because we have 2 range filters
  // If they become more we should change it a switch case scenario.
  const rangeProp = useMemo(() => {
    if ('experienceLengthRange' === facetKey) {
      return toYears(facet);
    }
    return facet;
  }, [ facet, facetKey ]);

  // experience length range selected
  const experienceLengthRangeSelected = useMemo(() => {
    return filters.filter((filter) => {
      return JobSeekersFilterTypes.EXPERIENCE_LENGTH === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((e) => {
      return !!e;
    }) || facet;
  }, [ facet, filters ]);

  // The profile completion selected filters
  const profileCompletionSelected = useMemo(() => {
    return filters.filter((filter) => {
      return JobSeekersFilterTypes.PROFILE_COMPLETION_PERCENTAGE === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((e) => {
      return !!e;
    }) || facet;
  }, [ facet, filters ]);

  // The total endorsements range selected filters
  const totalEndorsementsRangeSelected = useMemo(() => {
    return filters.filter((filter) => {
      return EndorsersFilterTypes.TOTAL_ENDORSEMENTS_RANGE === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((e) => {
      return !!e;
    }) || facet;
  }, [ facet, filters ]);

  // The range filter selected prop
  // Again here we only check one filter because we have 2 range filters
  // If they become more we should change it a switch case scenario.
  const rangeSelectedProp = useMemo(() => {
    switch (facetKey) {
    case 'experienceLengthRange':
      return toYears(experienceLengthRangeSelected);
    case 'profileCompletionPercentageRange':
      return profileCompletionSelected;
    case 'totalEndorsementsRange':
      return totalEndorsementsRangeSelected;
    default:
      return undefined;
    }
  }, [ experienceLengthRangeSelected, facetKey, profileCompletionSelected, totalEndorsementsRangeSelected ]);

  // The selected option from the options array
  const selectedOption = useCallback((subfacetKey) => {
    return filters.some((filter) => {
      return filterType === filter.type && subfacetKey === filter.value;
    });
  }, [ filterType, filters ]);

  // The filter titles
  const filterTitle = useMemo(() => {
    switch (filterType) {
    case JobSeekersFilterTypes.COMPUTER_SKILLS_LEVEL:
      return t('common:search_filters.filters.computer_skills_level.title');
    case JobSeekersFilterTypes.DRIVING_LICENSE_CATEGORY:
      return t('common:search_filters.filters.driving_license_category.title');
    case JobSeekersFilterTypes.EXPERIENCE_LENGTH:
      return t('common:search_filters.filters.experience_length.title');
    case JobSeekersFilterTypes.LANGUAGE:
      return t('common:search_filters.filters.language.title');
    case JobSeekersFilterTypes.PROFESSION:
      return t('common:search_filters.filters.profession.title');
    case JobSeekersFilterTypes.PROFILE_COMPLETION_PERCENTAGE:
      return t('common:search_filters.filters.profile_completion_percentage.title');
    case JobSeekersFilterTypes.QUALIFICATION_TYPE:
      return t('common:search_filters.filters.qualification_type.title');
    case JobSeekersFilterTypes.WILLING_TO_RELOCATE:
      return t('common:search_filters.filters.willing_to_relocate.title');
    case JobSeekersFilterTypes.WORK_AVAILABILITY:
      return t('common:search_filters.filters.work_availability.title');
    case CompaniesFilterTypes.NUMBER_OF_EMPLOYEES:
      return t('common:search_filters.filters.number_of_employees.title');
    case EndorsersFilterTypes.TOTAL_ENDORSEMENTS_RANGE:
      return t('common:search_filters.filters.total_endorsements_range.title');
    default:
      return '';
    }
  }, [ filterType, t ]);

  // selected profession based on language
  const getSelectedProfession = useCallback((subfacetKey) => {
    return allProfessions.find((profession) => {
      return subfacetKey === profession.id;
    })?.[selectedLanguage];
  }, [ allProfessions, selectedLanguage ]);

  // The option facet label
  const createOptionFacetLabel = useCallback((subfacetKey) => {
    switch (filterType) {
    case JobSeekersFilterTypes.COMPUTER_SKILLS_LEVEL:
      return t(`utilities:computer_skills_levels.${ subfacetKey }`);
    case JobSeekersFilterTypes.DRIVING_LICENSE_CATEGORY:
      return t(`utilities:driving_license_categories.${ subfacetKey }`);
    case JobSeekersFilterTypes.LANGUAGE:
      return t(`utilities:languages.${ subfacetKey }`);
    case JobSeekersFilterTypes.PROFESSION:
      return getSelectedProfession(subfacetKey);
    case JobSeekersFilterTypes.QUALIFICATION_TYPE:
      return t(`utilities:qualification_types.${ subfacetKey }`);
    case JobSeekersFilterTypes.WILLING_TO_RELOCATE:
      return t(`job_seekers:search.selected_filters.${ 'true' === subfacetKey ? 'yes' : 'no' }`);
    case JobSeekersFilterTypes.WORK_AVAILABILITY:
      return t(`utilities:work_availabilities.${ subfacetKey }`);
    case CompaniesFilterTypes.NUMBER_OF_EMPLOYEES:
      return t(`utilities:number_of_employees.${ subfacetKey }`);
    default:
      return '';
    }
  }, [ filterType, getSelectedProfession, t ]);

  // The options array of the option filter
  const optionsArray = useMemo(() => {
    return Object.keys(facet).map((subfacetKey) => {
      return {
        label: createOptionFacetLabel(subfacetKey),
        number: facet[subfacetKey],
        selected: selectedOption(subfacetKey),
        value: subfacetKey,
      };
    });
  }, [ createOptionFacetLabel, facet, selectedOption ]);

  // The filter component
  const individualFilter = useMemo(() => {
    if ('profileCompletionPercentageRange' === facetKey
      || 'experienceLengthRange' === facetKey
      || 'totalEndorsementsRange' === facetKey) {
      return (
        <RangeFilter
          onChange={ handleChangeRangeFilter }
          range={ rangeProp }
          selected={ rangeSelectedProp }
          title={ filterTitle }
          type={ filterType }
        />
      );
    }
    return (
      <OptionFilter
        onSelect={ selectFilter(filterType) }
        onUnselect={ unselectFilter(filterType) }
        options={ optionsArray }
        title={ filterTitle }
        type={ filterType }
      />
    );
  }, [
    facetKey,
    filterTitle,
    filterType,
    handleChangeRangeFilter,
    optionsArray,
    rangeProp,
    rangeSelectedProp,
    selectFilter,
    unselectFilter,
  ]);

  return (
    <div className={
      clsx('filter-box dark', className)
    }
    >
      { individualFilter }
    </div>
  );
});

FilterBox.displayName = 'FilterBox';

FilterBox.propTypes = {
  // All the professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // The green translation of the profession
      el: PropTypes.string.isRequired,
      // The english translation of the profession
      en: PropTypes.string.isRequired,
      // The id of the profession
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  // The class(es) to apply to the filter box.
  className: PropTypes.string,
  // The specific facet.
  facet: PropTypes.oneOfType([
    // Job Seeker possible facet levels
    PropTypes.objectOf(JobSeekersValidators.validateComputerSkillsLevelsFacet),
    PropTypes.objectOf(JobSeekersValidators.validateDrivingLicenseCategoriesFacet),
    PropTypes.shape({ end: PropTypes.number.isRequired, start: PropTypes.number.isRequired }),
    PropTypes.objectOf(JobSeekersValidators.validateLanguagesFacet),
    PropTypes.objectOf(JobSeekersValidators.validateProfessionsFacet),
    PropTypes.shape({ end: PropTypes.number.isRequired, start: PropTypes.number.isRequired }),
    PropTypes.objectOf(JobSeekersValidators.validateQualificationTypes),
    PropTypes.objectOf(JobSeekersValidators.validateRelocationIntentions),
    PropTypes.objectOf(JobSeekersValidators.validateWorkAvailabilities),
    // company agents possible facet levels
    PropTypes.objectOf(CompaniesValidators.validateNumberOfEmployees),
    PropTypes.objectOf(CompaniesValidators.validateProfessionsFacet),
  ]).isRequired,
  // The root level facet key of the JSON object
  facetKey: PropTypes.string.isRequired,
  // The filters.
  filters: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  // The function ((object[]) => void) to invoke when the filters change.
  onChange: PropTypes.func.isRequired,
};

FilterBox.defaultProps = {
  filters: [],
};

export default FilterBox;
