/**
 * Greek translations for utilities/qualification-types.
 *
 * @module languages/el/utilities/qualification-types
 */
const qualificationTypes = {
  AWARD: 'Βραβείο',
  BACHELORS_DEGREE: 'Πτυχίο',
  DIPLOMA: 'Δίπλωμα',
  DOCTORAL_DEGREE: 'Διδακτορικό',
  MASTERS_DEGREE: 'Μεταπτυχιακό',
  MBA: 'MBA',
  OTHER_CERTIFICATE: 'Άλλο πιστοποιητικό',
  VOCATIONAL_CERTIFICATE: 'Πιστοποιητικό επαγγελματικής εκπαίδευσης',
};

export default qualificationTypes;
