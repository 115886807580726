import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import VortexAnimation from 'animations/spiral.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './learn-more-dark-red-section.scss';

const LearnMoreDarkRedSection = memo((props) => {
  const { title, description, isComingSoon, buttonLabel } = props;

  // The rendered button
  const renderedButton = useMemo(() => {
    if (isComingSoon) {
      return (
        <button
          className='learn-more-dark-red-section__coming-soon-button'
          disabled
          id='companies-learn-more-ai-recommender-coming-soon'
          name='Coming Soon'
        >
          { buttonLabel }
        </button>
      );
    }
    return (
      <Link
        className='btn btn-white btn-sm btn-rounded-sm learn-more-dark-red-section__button'
        to='/social-responsibility#csr-packages'
      >
        { buttonLabel }
      </Link>
    );
  }, [ buttonLabel, isComingSoon ]);

  return (
    <div className='learn-more-dark-red-section'>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-dark-red-section__animated-icon'
        animationData={ VortexAnimation }
        className='learn-more-dark-red-section__animation-container'
      />
      <div className='learn-more-dark-red-section__content-container'>
        <h2 className='hdg hdg-xxl learn-more-dark-red-section__title'>
          { title }
        </h2>
        <p className='txt txt-md learn-more-dark-red-section__description'>
          { description }
        </p>
        { renderedButton }
      </div>
    </div>
  );
});

LearnMoreDarkRedSection.displayName = 'LearnMoreDarkRedSection';

LearnMoreDarkRedSection.propTypes = {
  // The button label
  buttonLabel: PropTypes.string.isRequired,
  // The description of the section
  description: PropTypes.string.isRequired,
  // if the section is coming soon
  isComingSoon: PropTypes.bool,
  // The title of the section
  title: PropTypes.string.isRequired,
};

LearnMoreDarkRedSection.defaultProps = {
  isComingSoon: false,
};

export default LearnMoreDarkRedSection;
