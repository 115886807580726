/**
 * Profile Image
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';

import CircularImage from 'components/common/circular-image/circular-image';
import Modal from 'components/common/modal/modal';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import edit from 'images/edit.png';
import roles, * as Roles from 'utilities/auth/roles';
import * as CompanyParts from 'utilities/companies/parts';
import * as EndorsersParts from 'utilities/endorsers/parts';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileImageEditor from './profile-image-editor.js/profile-image-editor';

import './profile-image.scss';

const ProfileImage = memo((props) => {
  const { profile, onProfilePictureChange, canEdit, role } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  // The profile image source
  const profileImageSource = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ profile?.id }/profile/picture?id=${ profile?.profilePicture?.id }`;
    case Roles.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ profile?.id }/profile/logo?id=${ profile?.logo?.id }`;
    case Roles.ENDORSER:
      return `${ env.CORE_BASE_URL }/endorsers/${ profile?.id }/profile/picture?id=${ profile?.profilePicture?.id }`;
    default:
      return '';
    }
  }, [ role, profile?.id, profile?.profilePicture?.id, profile?.logo?.id ]);

  // The part to be updated
  const partToBeUpdated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekerParts.PROFILE_PICTURE;
    case Roles.COMPANY_AGENT:
      return CompanyParts.LOGO;
    case Roles.ENDORSER:
      return EndorsersParts.PROFILE_PICTURE;
    default:
      return JobSeekerParts.PROFILE_PICTURE;
    }
  }, [ role ]);

  // Whether to add placeholder image
  const addPlaceholderImage = useMemo(() => {
    return undefined === profile?.profilePicture && undefined === profile?.logo;
  }, [ profile?.logo, profile?.profilePicture ]);

  // The profile image
  const profileImage = useMemo(() => {
    if (profile === undefined) {
      return null;
    }
    if (addPlaceholderImage) {
      return (
        <CircularImage
          alt='Default Profile Picture'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='Profile Picture'
        imageSource={ profileImageSource }
      />
    );
  }, [ addPlaceholderImage, profile, profileImageSource ]);

  // function that is invoked when handling editor open
  const handleEditorOpen = useCallback((editorOpen) => {
    return (_event) => {
      setEditorOpen(editorOpen);
    };
  }, []);

  // The edit button
  const editButton = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <button
        className='edit-button btn btn-trans'
        onClick={ handleEditorOpen(true) }
      >
        <img alt='Edit' src={ edit } />
      </button>
    );
  }, [ canEdit, handleEditorOpen ]);

  // function that is invoked when handling the save on profile picture editor
  const handleOnSaveProfilePictureEditor = useCallback((paramsToBeUpdated, isToBeUpdated, file) => {
    setEditorOpen(false);
    onProfilePictureChange(paramsToBeUpdated, partToBeUpdated, isToBeUpdated, file);
  }, [ onProfilePictureChange, partToBeUpdated ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-image-editor-modal'
        onClose={ handleEditorOpen(false) }
        open={ editorOpen }
        trigger={
          <button>&nbsp;</button>
        }
      >
        <ProfileImageEditor
          onCancel={ handleEditorOpen(false) }
          onSave={ handleOnSaveProfilePictureEditor }
          profile={ profile }
          role={ role }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, handleEditorOpen, handleOnSaveProfilePictureEditor, profile, role ]);

  return (
    <div className={
      clsx('profile-image', {
        'no-picture': addPlaceholderImage,
      })
    }
    >
      <div className='picture'>
        { profileImage }
      </div>
      { editButton }
      { editorModal }
    </div>
  );
});

ProfileImage.displayName = 'ProfileImage';

ProfileImage.propTypes = {
  // Whether the user can edit the profile image
  canEdit: PropTypes.bool.isRequired,
  // The function (Profile) => void when changing the profile picture
  onProfilePictureChange: PropTypes.func.isRequired,
  // The profile of the user.
  profile: PropTypes.oneOfType([
    // The profile attributes of the JOB SEEKER
    PropTypes.shape({
      // The id of the user of the profile
      id: PropTypes.string,
      // The profile picture of the job seeker.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string,
        // The MIME type of the picture.
        type: PropTypes.string,
      }),
    }),
    // The profile attributes of the COMPANY AGENT
    PropTypes.shape({
      // The id of the user of the profile
      id: PropTypes.string,
      // The profile picture of the company agent.
      logo: PropTypes.shape({
        // The ID of the logo.
        id: PropTypes.string,
        // The MIME type of the logo.
        type: PropTypes.string,
      }),
    }),
  ]),
  // The role of the user's profile
  role: PropTypes.oneOf(roles).isRequired,
  // the user's profile id
  userId: PropTypes.string,
};

ProfileImage.defaultProps = {
  userId: '',
};

export default ProfileImage;
