/**
 * File preview.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import env from 'config/env';

import './file-preview.scss';
import caretLeft from './images/caret-left.png';
import caretRight from './images/caret-right.png';

// Needed, so that the PDF viewer works in a separate thread.
pdfjs.GlobalWorkerOptions.workerSrc = `${ env.PUBLIC_URL }/pdfjs-dist/pdf.worker.min.js`;

const FilePreview = (props) => {
  const { t } = useTranslation();

  // The number of pages in the file.
  const [ numberOfPages, setNumberOfPages ] = useState(undefined);
  // The current page number.
  const [ pageNumber, setPageNumber ] = useState(undefined);
  // Whether the file can be previewed.
  const [ previewable, setPreviewable ] = useState(undefined);

  // Check whether the file can be previewed.
  useEffect(() => {
    // Only PDF files can be previewed.
    setPreviewable('application/pdf' === props.file.type);
  }, [ props.file.id, props.file.type ]);

  // Reset number of pages and page number.
  useEffect(() => {
    setNumberOfPages(undefined);
    setPageNumber(undefined);
  }, [ props.file.id ]);

  return (
    <div className={ clsx('ody-file-preview', props.className) }>
      {
        previewable ? (
          <>
            <div className='navigation-and-preview'>
              {
                undefined !== numberOfPages && undefined !== pageNumber && (
                  <button
                    className='btn btn-trans'
                    disabled={ 1 === pageNumber }
                    onClick={
                      () => {
                        setPageNumber((pageNumber) => {
                          return pageNumber - 1;
                        });
                      }
                    }
                    type='button'
                  >
                    <img alt='Previous Page' src={ caretLeft } />
                  </button>
                )
              }
              <Document
                error={ t('common:file_preview.preview_failed_error') }
                file={ props.file.data }
                loading={ t('common:file_preview.file_loading_message') }
                onLoadSuccess={
                  ({ numPages }) => {
                    setNumberOfPages(numPages);
                    setPageNumber(1);
                  }
                }
              >
                {
                  undefined !== pageNumber && (
                    <Page
                      error={ t('common:file_preview.preview_failed_error') }
                      loading={ t('common:file_preview.page_loading_message') }
                      pageNumber={ pageNumber }
                      renderAnnotationLayer={ false }
                      renderTextLayer={ false }
                    />
                  )
                }
              </Document>
              {
                undefined !== numberOfPages && undefined !== pageNumber && (
                  <button
                    className='btn btn-trans'
                    disabled={ numberOfPages === pageNumber }
                    onClick={
                      () => {
                        setPageNumber((pageNumber) => {
                          return pageNumber + 1;
                        });
                      }
                    }
                    type='button'
                  >
                    <img alt='Next Page' src={ caretRight } />
                  </button>
                )
              }
            </div>
            {
              undefined !== numberOfPages && undefined !== pageNumber && (
                <div className='page-number txt txt-sm'>
                  { t('common:file_preview.number_of_total', { number: pageNumber, total: numberOfPages }) }
                </div>
              )
            }
          </>
        ) : (
          <div className='no-preview txt txt-sm'>
            { t('common:file_preview.no_preview_available_message') }
          </div>
        )
      }
    </div>
  );
};

FilePreview.propTypes = {
  // The class(es) to apply to the file preview.
  className: PropTypes.string,
  // The file.
  file: PropTypes.shape({
    // The content of the file.
    data: PropTypes.any.isRequired,
    // The ID of the file.
    id: PropTypes.string.isRequired,
    // The type of the file.
    type: PropTypes.string.isRequired,
  }).isRequired,
};

FilePreview.defaultProps = {
};

export default FilePreview;
