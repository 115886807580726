import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Incomplete from 'images/incomplete.png';
import roles, * as Roles from 'utilities/auth/roles';

import './sidebar-profile-completion.scss';

const SidebarProfileCompletion = memo((props) => {
  const { profile, role } = props;

  const { t } = useTranslation();

  // renders the part step depending the role
  const renderIncompleteStep = useCallback((part) => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t(`job_seekers:steps.${ part }`);
    case Roles.COMPANY_AGENT:
      return t(`companies:steps.${ part }`);
    case Roles.ENDORSER:
      return t(`endorsers:steps.${ part }`);
    default:
      return '';
    }
  }, [ role, t ]);

  // The rendered incomplete steps
  const renderedIncompleteSteps = useMemo(() => {
    if (!profile?.completion?.partCompletions?.length) {
      return null;
    }
    return profile.completion.partCompletions.filter((partCompletion) => {
      return !partCompletion.complete;
    }).map((partCompletion) => {
      return (
        <div
          className='sidebar-profile-completion__incomplete-step'
          key={ partCompletion.part }
        >
          <span className='txt txt-sm'>
            { renderIncompleteStep(partCompletion.part) }
          </span>
          <img alt='Incomplete Step' src={ Incomplete } />
        </div>
      );
    });
  }, [ profile?.completion?.partCompletions, renderIncompleteStep ]);

  return (
    <div className='sidebar-profile-completion'>
      <div className='sidebar-profile-completion__title-container'>
        <h2 className='hdg hdg-sm'>
          { t('common:sidebar_profile_completion.title') }
        </h2>
        <div className='sidebar-profile-completion__percentage'>
          <span className='hdg hdg-sm'>
            { `${ profile?.completion?.percentage || 0 }%` }
          </span>
        </div>
      </div>
      <div className='sidebar-profile-completion__progress-bar'>
        <div
          className='sidebar-profile-completion__progress'
          style={
            {
              width: `${ profile?.completion?.percentage || 0 }%`,
            }
          }
        >
        </div>
      </div>
      <div className='sidebar-profile-completion__incomplete-steps'>
        { renderedIncompleteSteps }
      </div>
    </div>
  );
});

SidebarProfileCompletion.displayName = 'SidebarProfileCompletion';

SidebarProfileCompletion.propTypes = {
  // The profile of the company agent.
  profile: PropTypes.shape({
    // The profile completion.
    completion: PropTypes.shape({
      // The part completions.
      partCompletions: PropTypes.arrayOf(PropTypes.shape({
        // Whether the part is complete.
        complete: PropTypes.bool.isRequired,
        // The part.
        part: PropTypes.string.isRequired,
      })).isRequired,
      // The completion percentage.
      percentage: PropTypes.number.isRequired,
    }),
  }),
  // The role that component is based
  role: PropTypes.oneOf(roles),
};

SidebarProfileCompletion.defaultProps = {};

export default SidebarProfileCompletion;
