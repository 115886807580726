/**
 * Greek translations for common/confirm.
 *
 * @module languages/el/common/confirm
 */
const confirm = {
  no_button_label: 'Οχι',
  yes_button_label: 'Ναι',
};

export default confirm;
