import fileDownload from 'js-file-download';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ClassRoomAnimationIcon from 'animations/consulting-service.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';
import * as toasts from 'toasts';

import ExampleCV from './example-CV.pdf';

import './learn-more-create-CV-section.scss';

const LearnMoreCreateCVSection = memo(() => {
  const { t } = useTranslation();

  const handleDownloadExampleCV = useCallback(() => {
    // We use fetch to bypass axios configuration and to transform the file module to Blob
    fetch(ExampleCV)
      .then((response) => { return response.blob(); })
      .then((blob) => {
        fileDownload(blob, 'example-CV.pdf');
      })
      .catch(() => {
        toasts.error(t('job_seekers:learn_more.create_cv_section.error_downloading_cv'));
      });
  }, [ t ]);

  return (
    <div className='learn-more-create-cv-section'>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-create-cv-section__animated-icon'
        animationData={ ClassRoomAnimationIcon }
        className='learn-more-create-cv-section__animation-container'
      />
      <div className='learn-more-create-cv-section__content-section'>
        <h2 className='hdg hdg-xxl learn-more-create-cv-section__title'>
          { t('job_seekers:learn_more.create_cv_section.title') }
        </h2>
        <p className='txt txt-lg learn-more-create-cv-section__description'>
          { t('job_seekers:learn_more.create_cv_section.description') }
        </p>
        <button
          className='btn btn-sm btn-yellow btn-rounded-sm learn-more-create-cv-section__button'
          id='download-cv'
          name='Download CV'
          onClick={ handleDownloadExampleCV }
          type='button'
        >
          { t('job_seekers:learn_more.create_cv_section.button_label') }
        </button>
      </div>
    </div>
  );
});

LearnMoreCreateCVSection.displayName = 'LearnMoreCreateCVSection';

LearnMoreCreateCVSection.propTypes = {};

LearnMoreCreateCVSection.defaultProps = {};

export default LearnMoreCreateCVSection;
