/**
 * Password editor.
 */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PasswordInput from 'components/common/password-input/password-input';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';
import { isEmpty } from 'utilities/chisels';
import { passwordEditorSchemaValidator } from 'utilities/validators';

import './password-editor.scss';

const PasswordEditor = (props) => {
  const { onClose } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // The error when saving the changes.
  const [ error, setError ] = useState(undefined);

  const { formState: { errors }, handleSubmit, control } = useFormWithSchema(passwordEditorSchemaValidator);

  // Function that updates the password of the currently signed-in user based on the given values.
  const updatePassword = useCallback((values) => {
    setError(undefined);
    dispatch(requestsActions.request(usersMethods.updateMyPassword, {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    }, {
      onFailure: (error) => {
        switch (error.message) {
        case 'BAD_PASSWORD':
          setError(t('settings:account.password.bad_password_error'));
          break;
        default:
          setError(t('settings:common.general_error'));
          break;
        }
      },
      onSuccess: (_result) => {
        toasts.info(t('settings:account.password.password_changed_message'));
        onClose?.();
      },
    }));
  }, [ dispatch, onClose, t ]);

  // The rendered current password input
  const renderedCurrentPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.currentPassword }
        id='settings-current-password-input'
        label={ t('settings:account.password.current_password_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('settings:account.password.current_password_placeholder') }
        value={ value }
      />
    );
  }, [ errors.currentPassword, t ]);

  // The rendered new password input element
  const renderedNewPasswordInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.newPassword }
        id='settings-new-password-input'
        label={ t('settings:account.password.new_password_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('settings:account.password.new_password_placeholder') }
        value={ value }
      />
    );
  }, [ errors.newPassword, t ]);

  // The rendered new password confirmation input element
  const renderedNewPasswordConfirmationInputElement = useCallback(({ field: { value, onChange } }) => {
    return (
      <PasswordInput
        error={ !!errors.newPasswordConfirmation }
        id='settings-new-password-confirmation-input'
        label={ t('settings:account.password.new_password_confirmation_label') }
        maxLength={ 256 }
        onChange={ onChange }
        placeholder={ t('settings:account.password.new_password_confirmation_placeholder') }
        value={ value }
      />
    );
  }, [ errors.newPasswordConfirmation, t ]);

  // the empty current password error
  const emptyCurrentPasswordError = useMemo(() => {
    if ('string.empty' !== errors.currentPassword?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('settings:account.password.current_password_required_error') }
      </span>
    );
  }, [ errors.currentPassword?.type, t ]);

  // the empty new password error
  const emptyNewPasswordError = useMemo(() => {
    if ( 'string.empty' !== errors.newPassword?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('settings:account.password.new_password_required_error') }
      </span>
    );
  }, [ errors.newPassword?.type, t ]);

  // invalid password pattern
  const invalidPasswordPattern = useMemo(() => {
    if (-1 === [ 'string.min', 'string.pattern.base' ].indexOf(errors.newPassword?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('settings:account.password.new_password_invalid_error') }
      </span>
    );
  }, [ errors.newPassword?.type, t ]);

  // password confirmation error
  const passwordConfirmationError = useMemo(() => {
    if ('any.only' !== errors.newPasswordConfirmation?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('settings:account.password.new_password_and_new_password_confirmation_mismatch_error') }
      </span>
    );
  }, [ errors.newPasswordConfirmation?.type, t ]);

  // generic error
  const genericError = useMemo(() => {
    if (undefined === error) {
      return null;
    }
    return (
      <div className='error'>{ error }</div>
    );
  }, [ error ]);

  // function that handles the close button click
  const handleClose = useCallback(() => {
    onClose?.();
  }, [ onClose ]);

  return (
    <div className='password editor dark'>
      <div className='hdg hdg-md'>{ t('settings:account.password.editor_title') }</div>
      <form onSubmit={ handleSubmit(updatePassword) }>
        <div className='fields'>
          <div className='field current-password'>
            <Controller
              control={ control }
              defaultValue=''
              name='currentPassword'
              render={ renderedCurrentPasswordInputElement }
            />
            { emptyCurrentPasswordError }
          </div>
          <div className='field new-password'>
            <Controller
              control={ control }
              defaultValue=''
              name='newPassword'
              render={ renderedNewPasswordInputElement }
            />
            { emptyNewPasswordError }
            { invalidPasswordPattern }
          </div>
          <div className='field new-password-confirmation'>
            <Controller
              control={ control }
              defaultValue=''
              name='newPasswordConfirmation'
              render={ renderedNewPasswordConfirmationInputElement }
            />
            { passwordConfirmationError }
          </div>
        </div>
        { genericError }
        <div className='buttons'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ handleClose }
            type='reset'
          >
            { t('settings:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ !isEmpty(errors) }
            type='submit'
          >
            { t('settings:common.change_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
};

PasswordEditor.propTypes = {
  // The function ((object) => void) to invoke when the editor is closed.
  onClose: PropTypes.func,
};

PasswordEditor.defaultProps = {
};

export default PasswordEditor;
