import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import PlaceholderImage from './images/placeholder-image.png';
import './video-guide-card.scss';

const VideoGuideCard = memo((props) => {
  const { videoGuide } = props;

  const buttonLabel = useMemo(() => {
    if ('video' !== videoGuide.type) {
      return 'Learn more';
    }
    return 'See Video';
  }, [ videoGuide.type ]);

  // The image to render if type is video
  const videoImage = useMemo(() => {
    if ('video' !== videoGuide.type) {
      return null;
    }
    return (
      <img
        alt='video'
        className='hidden-xs hidden-sm'
        src={ PlaceholderImage }
      />
    );
  }, [ videoGuide.type ]);

  return (
    <div className='video-guide-card'>
      <h2 className='video-guide-card__title hdg hdg-sm'>
        { videoGuide.title }
      </h2>
      { videoImage }
      <p className={
        clsx('txt txt-sm video-guide-card__description', {
          'video-guide-card__description--guide': 'guide' === videoGuide.type,
          'video-guide-card__description--video': 'video' === videoGuide.type,
        })
      }
      >
        { videoGuide.description }
      </p>
      <button
        className='btn btn-sm btn-rounded-sm btn-blue'
        type='button'
      >
        { buttonLabel }
      </button>
    </div>
  );
});

VideoGuideCard.displayName = 'VideoGuideCard';

VideoGuideCard.propTypes = {
  // The video guide object
  videoGuide: PropTypes.object.isRequired,
};

VideoGuideCard.defaultProps = {};

export default VideoGuideCard;
