/**
 * English translations for endorsement.
 *
 * @module languages/en/endorsement
 */
const endorsement = {
  endorsement_information: {
    communication_cooperation: {
      answers: {
        ABILITY: 'They can communicate and cooperate with some limitation',
        GOOD_LEVEL: 'They communicate and cooperate in a good level',
        POOR: 'They have poor communication and cooperation skills',
        PROFESSIONAL: 'They communicate and cooperate professionally',
        WILLNESS: 'They want (intension) to communicate and cooperate',
      },
      // eslint-disable-next-line max-len
      question: 'Do they have interaction ability verbally and non-verbally with their environment? Can they function within a team and in cooperation?',
      title: 'Communication and cooperation',
    },
    companies_feedback: {
      title: 'Companies feedback',
    },
    critical_thinking: {
      answers: {
        FAST_RESPONSIVENESS: 'They seem witty and clever, really fast in responses, quick learner',
        NEED_GUIDANCE: 'They seem to understand but need guidance and time',
        NICE_RESPONSIVENESS: 'They seem smart, respond nicely and to the point',
        SLOW_RESPONSIVENESS: 'They seem slow in responsiveness ',
        UNDERSTANDING: 'They seem a bit slow in response but pay attention and understand',
      },
      // eslint-disable-next-line max-len
      question: 'Can they understand, process and evaluate information given to them? Can they manage to propose suggestions or solutions if needed?',
      title: 'Critical Thinking',
    },
    endorsed_by_title: 'Endorsement by',
    feedback: {
      title: 'Feedback',
    },
    job_seekers_feedback: {
      answers: {
        // eslint-disable-next-line max-len
        KNOWLEDGE_UPGRADE: 'You need to upgrade your knowledge to respond to the new data (educational courses, seminars etc.)',
        LANGUAGES_IMPROVEMENT: 'You need to improve your Greek and/or English language skills to find a job',
        // eslint-disable-next-line max-len
        MORE_PREPARATION: 'Υou need to prepare more to find a job (complete your resume, preparation for an interview, targeting positions you can apply for)',
        PERSONAL_FAMILY_MATTERS: 'Some personal or family matters need to be sorted out in order to look for work',
        PRIORITIZATION: 'You need to prioritize your priorities in order to focus on finding a job',
        SUPPORT_NEEDED: 'You need to be supported and networked to find a job you are ready to take on tasks',
      },
      title: 'Job Seeker\'s feedback',
    },
    motivation: {
      answers: {
        FIND_JOB: 'They seem to be ready to make an effort to find a job',
        FIRST_STEP: 'They seem to be ready to make the first step (intension)',
        KEEP_JOB: 'They seem to be ready to keep the job /make an effort to be efficient at work',
        TAKE_JOB: 'They seem to be ready to take a job position',
        WORK_HARD: 'They seem really eager to work hard, looking passionately for a job',
      },
      // eslint-disable-next-line max-len
      question: 'Are they ready to the responsibilities of a new job position? Are they thinking their career change in a positive way?',
      title: 'Motivation',
    },
    personality_qualities: {
      title: 'Personality Qualities',
    },
    professionalism: {
      answers: {
        ATTITUDE: 'Attitude',
        CONSISTENCY: 'Consistency',
        GOOD_INTENTION: 'Good intention',
        POLITENESS: 'Politeness',
        SELF_CONTROL: 'Self-control',
      },
      question: 'Can their general image be characterized as typically professional?',
      title: 'Professionalism',
    },
    testimonial: {
      title: 'Testimonial',
    },
  },
  form: {
    cancel_button_label: 'Cancel',
    communication_cooperation_skills_level: {
      answers: {
        ABILITY: 'They can communicate and cooperate with some limitation',
        GOOD_LEVEL: 'They communicate and cooperate in a good level',
        POOR: 'They have poor communication and cooperation skills',
        PROFESSIONAL: 'They communicate and cooperate professionally',
        WILLNESS: 'They want (intension) to communicate and cooperate',
      },
      questions: {
        primary: 'How good are their communication and cooperation skills?',
        // eslint-disable-next-line max-len
        secondary: 'Do they have interaction ability verbally and non-verbally with their environment? Can they function within a team and in cooperation?',
      },
    },
    confirm_submit: 'Are you sure you want to submit the form?',
    critical_thinking_level: {
      answers: {
        FAST_RESPONSIVENESS: 'They seem witty and clever, really fast in responses, quick learner',
        NEED_GUIDANCE: 'They seem to understand but need guidance and time',
        NICE_RESPONSIVENESS: 'They seem smart, respond nicely and to the point',
        SLOW_RESPONSIVENESS: 'They seem slow in responsiveness ',
        UNDERSTANDING: 'They seem a bit slow in response but pay attention and understand',
      },
      questions: {
        primary: 'How would you rate their critical thinking?',
        // eslint-disable-next-line max-len
        secondary: 'Can they understand, process and evaluate information given to them? Can they manage to propose suggestions or solutions if needed?',
      },
    },
    employer_feedback: {
      questions: {
        primary: 'Give some feedback to his/her potential employer (Only employers can see this feedback)',
        // eslint-disable-next-line max-len
        secondary: 'The meeting with the candidate strongly created a feeling, or an impression that needs to be mentioned with the aim of promoting the candidate in business. Then we would say that ...',
      },
    },
    error_message: 'The response to this question is required.',
    failed_submit_endorsement: 'The endorsement failed to be submitted',
    hashtags_error: 'The hashtags are required',
    hashtags_placeholder: 'Add a Hashtag',
    hashtags_subtitle: 'Give 3 qualities of the job seeker',
    hashtags_title: 'Hashtags',
    job_seeker_feedbacks: {
      answers: {
        // eslint-disable-next-line max-len
        KNOWLEDGE_UPGRADE: 'You need to upgrade your knowledge to respond to the new data (educational courses, seminars etc.)',
        LANGUAGES_IMPROVEMENT: 'You need to improve your Greek and/or English language skills to find a job',
        // eslint-disable-next-line max-len
        MORE_PREPARATION: 'Υou need to prepare more to find a job (complete your resume, preparation for an interview, targeting positions you can apply for)',
        PERSONAL_FAMILY_MATTERS: 'Some personal or family matters need to be sorted out in order to look for work',
        PRIORITIZATION: 'You need to prioritize your priorities in order to focus on finding a job',
        SUPPORT_NEEDED: 'You need to be supported and networked to find a job you are ready to take on tasks',
      },
      questions: {
        primary: 'Give some personal feedback to the job seeker (Only the job seeker can see this feedback)',
      },
    },
    motivation_level: {
      answers: {
        FIND_JOB: 'They seem to be ready to make an effort to find a job',
        FIRST_STEP: 'They seem to be ready to make the first step (intension)',
        KEEP_JOB: 'They seem to be ready to keep the job /make an effort to be efficient at work',
        TAKE_JOB: 'They seem to be ready to take a job position',
        WORK_HARD: 'They seem really eager to work hard, looking passionately for a job',
      },
      questions: {
        primary: 'How motivated are they to pursue a new job?',
        // eslint-disable-next-line max-len
        secondary: 'Are they ready to the responsibilities of a new job position? Are they thinking their career change in a positive way? \'',
      },
    },
    professional_characteristics: {
      answers: {
        ATTITUDE: 'Attitude',
        CONSISTENCY: 'Consistency',
        GOOD_INTENTION: 'Good intention',
        POLITENESS: 'Politeness',
        SELF_CONTROL: 'Self-control',
      },
      questions: {
        primary: 'How would you rate their professionalism?',
        secondary: 'Can their general image be characterized as typically professional?',
      },
    },
    submit_button_label: 'Submit',
    successful_submitted_endorsement: 'You have successfully submitted the endorsement',
    testimonial: {
      questions: {
        primary: 'Give a simple quick testimonial for this job seeker (optional)',
        // eslint-disable-next-line max-len
        secondary: 'You can write  a sentence of something that impressed you, or something that worths mentioning for this job seeker.',
      },
    },
    textarea_error_message: 'Your feedback must not exceed 1024 characters',
    textarea_placeholder: 'Text...',
  },
  list_of_questions_title: 'List of Questions',
  profile_overview_title: 'Profile Overview',
  title: 'Endorsement Form',
  view_profile_button_title: 'View Profile',
};

export default endorsement;
