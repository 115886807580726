/**
 * Sort keys for company.
 *
 * @module utilities/companies/sort-keys
 */

/**
 * Based on when their profile was last modified.
 *
 * @constant {string}
 * @static
 */
export const LAST_MODIFIED_AT = 'LAST_MODIFIED_AT';

/**
 * Based on the company name.
 *
 * @constant {string}
 * @static
 */
export const COMPANY_NAME = 'COMPANY_NAME';

/**
 * The default sort key.
 *
 * @constant {string}
 * @default
 * @static
 */
export const DEFAULT_SORT_KEY = LAST_MODIFIED_AT;

export default [
  LAST_MODIFIED_AT,
  COMPANY_NAME,
];
