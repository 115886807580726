import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormWithSchema } from 'hooks/use-form-with-validation';
import { profileBackgroundImageSchemaValidator } from 'utilities/validators';

import BackgroundImages from '../profile-background-images';

import './profile-background-image-editor.scss';

const ProfileBackgroundImageEditor = memo((props) => {
  const { profile, onSave, onCancel } = props;

  // The index of the selected image.
  const [ selected, setSelected ] = useState(undefined);

  const backgroundImage = useMemo(() => {
    return profile?.backgroundImage;
  }, [ profile.backgroundImage ]);

  const { formState: { _errors }, handleSubmit, register, setValue }
    = useFormWithSchema(profileBackgroundImageSchemaValidator);

  const { t } = useTranslation();

  // Selected image index from props
  const selectedImage = useMemo(() => {
    return undefined === backgroundImage ? 0 : backgroundImage - 1;
  }, [ backgroundImage ]);

  // Select the background image in the profile.
  useEffect(() => {
    setSelected(selectedImage);
  }, [ selectedImage ]);

  // Function that is invoked when clicking a background image
  const handleOnBackgroundImageClick = useCallback((index) => {
    return (_event) => {
      setSelected(index);
      setValue('backgroundImage', index + 1);
    };
  }, [ setValue ]);

  // The background images
  const backgroundImages = useMemo(() => {
    return BackgroundImages.map((backgroundImage, index) => {
      return (
        <button
          className={
            clsx('btn btn-trans', {
              'selected': selected === index,
            })
          }
          key={ backgroundImage }
          onClick={ handleOnBackgroundImageClick(index) }
          type='button'
        >
          <img
            alt={ `Background Image #${ index + 1 }` }
            src={ backgroundImage }
          />
        </button>
      );
    });
  }, [ handleOnBackgroundImageClick, selected ]);

  // Function that is invoked when save is pressed
  const handleSave = useCallback((values) => {
    const isTobeUpdated = backgroundImage.toString() !== values?.backgroundImage.toString();
    onSave(values, isTobeUpdated);
  }, [ backgroundImage, onSave ]);

  return (
    <div className='profile-background-image-editor dark'>
      <div className='hdg hdg-md'>
        { t('common:profile_background_image.editor_title') }
      </div>
      <div className='background-images'>
        { backgroundImages }
      </div>
      <form
        noValidate
        onSubmit={ handleSubmit(handleSave) }
      >
        <div className='fields'>
          <div className='field background-image'>
            <input
              defaultValue={ backgroundImage || 1 }
              type='hidden'
              { ...register('backgroundImage') }
            />
          </div>
        </div>
        <div className='buttons'>
          <div className='right-buttons'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white'
              onClick={ onCancel }
            >
              { t('profile:common.cancel_button_label') }
            </button>
            <button
              className='btn btn-sm btn-rounded-sm btn-blue'
              type='submit'
            >
              { t('common:profile_background_image.save_button_label') }
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

ProfileBackgroundImageEditor.displayName = 'ProfileBackgroundImageEditor';

ProfileBackgroundImageEditor.propTypes = {
  // The function (() => void) to invoke when canceling the background image editor.
  onCancel: PropTypes.func.isRequired,
  // The function ((Profile) => void) to invoke when saving the background image to profile.
  onSave: PropTypes.func.isRequired,
  // The profile of the company Agent.
  profile: PropTypes.shape({
    // The ID of the background image of the company.
    backgroundImage: PropTypes.number.isRequired,
  }),
};

ProfileBackgroundImageEditor.defaultProps = {

};

export default ProfileBackgroundImageEditor;
