/**
 * Greek translations for utilities/job-seekers/sort-keys.
 *
 * @module languages/el/utilities/job-seekers/sort-keys
 */
const sortKeys = {
  LAST_MODIFIED_AT: 'Τελευταία ενημέρωση',
  PROFILE_COMPLETION: 'Ολοκλήρωση προφίλ',
  WORK_AVAILABILITY: 'Διαθεσιμότητα',
};

export default sortKeys;
