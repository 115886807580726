/**
 * Greek translations for utilities/genders.
 *
 * @module languages/el/utilities/genders
 */
const genders = {
  FEMALE: 'Γυναίκα',
  MALE: 'Άνδρας',
  OTHER: 'Άλλο',
};

export default genders;
