/**
 * English translations for sign-in.
 *
 * @module languages/en/sign-in
 */
const signIn = {
  title: 'Sign in',
};

export default signIn;
