import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import SearchResult from 'components/common/search-result/search-result';
import EndorsersSearchResultCard from 'components/endorsers/search-result-card/search-result-card';
import roles, * as Roles from 'utilities/auth/roles';
import allCities from 'utilities/cities';
import endorsementStatuses from 'utilities/endorsement-status';
import numberOfEmployeesLevels from 'utilities/number-of-employees';

import './results.scss';

const Results = memo((props) => {
  const { results, allProfessions, userAuthenticated, userRole, savedJobSeekers, companyId, role } = props;

  const resultsRendered = useMemo(() => {
    if (role === Roles.ENDORSER) {
      return results?.map((result) => {
        return (
          <EndorsersSearchResultCard
            key={ result.id }
            result={ result }
            role={ role }
          />
        );
      });
    }
    return results?.map((result) => {
      return (
        <SearchResult
          allProfessions={ allProfessions }
          companyId={ companyId }
          key={ result.id }
          result={ result }
          role={ role }
          savedJobSeekers={ savedJobSeekers }
          userAuthenticated={ userAuthenticated }
          userRole={ userRole }
        />
      );
    });
  }, [ allProfessions, companyId, results, role, savedJobSeekers, userAuthenticated, userRole ]);

  return (
    <div className={
      clsx({
        'results': role !== Roles.ENDORSER,
        'results__endorsers': role === Roles.ENDORSER,
      })
    }
    >
      { resultsRendered }
    </div>
  );
});

Results.displayName = 'Results';

Results.propTypes = {
  // The professions array
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the profession
      el: PropTypes.string,
      // The english translation of the profession
      en: PropTypes.string,
      // The id of the profession
      id: PropTypes.string,
    })
  ).isRequired,
  // The company id that the user belongs
  companyId: PropTypes.string,
  // The results
  results: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // the job seeker profile
      PropTypes.shape({
        // The first name of the job seeker.
        firstName: PropTypes.string.isRequired,
        // The ID of the job seeker.
        id: PropTypes.string.isRequired,
        // The last name of the job seeker.
        lastName: PropTypes.string.isRequired,
        // Where the job seeker is currently located.
        location: PropTypes.shape({
          // The city.
          city: PropTypes.oneOf(allCities),
          // The district.
          district: PropTypes.string,
        }),
        // The IDs of the professions that the job seeker is interested in.
        professions: PropTypes.arrayOf(PropTypes.string),
        // The profile picture of the job seeker.
        profilePicture: PropTypes.shape({
          // The ID of the picture.
          id: PropTypes.string.isRequired,
          // The MIME type of the picture.
          type: PropTypes.string.isRequired,
        }),
        // The short bio of the job seeker.
        shortBio: PropTypes.string,
      }),
      // The company profile
      PropTypes.shape({
        // the name of the company
        companyName: PropTypes.string,
        // The description of the company
        description: PropTypes.string,
        // The company id
        id: PropTypes.string,
        // The job sectors id's array
        jobSectors: PropTypes.arrayOf(PropTypes.string),
        // The location of the company
        location: PropTypes.shape({
          // The city.
          city: PropTypes.oneOf(allCities),
          // The district.
          district: PropTypes.string,
        }),
        // The logo of the company
        logo: PropTypes.shape({
          // the extension of the logo
          extension: PropTypes.string,
          // The id of the logo
          id: PropTypes.string,
          // The type of the logo
          type: PropTypes.string,
        }),
        // The number of employees level
        numberOfEmployeesLevel: PropTypes.oneOf(numberOfEmployeesLevels),
        // The IDs of the professions that the company is interested in.
        professions: PropTypes.arrayOf(PropTypes.string),
        // The company website
        website: PropTypes.string,
      }),
      // The endorser profile
      PropTypes.shape({
        // The endorsements viewed by companies
        endorsementsViewsByCompanies: PropTypes.number,
        // The first name
        firstName: PropTypes.string,
        // Whether it is hidden
        hidden: PropTypes.bool,
        // The id of the endorser
        id: PropTypes.string,
        // The last name
        lastName: PropTypes.string,
        // Where the job seeker is currently located.
        location: PropTypes.shape({
          // The city.
          city: PropTypes.oneOf(allCities),
          // The district.
          district: PropTypes.string,
        }),
        // The mobile phone number
        mobilePhoneNumber: PropTypes.string,
        // The pending endorsement requests
        pendingEndorsementRequests: PropTypes.arrayOf(
          // The endorsement request
          PropTypes.shape({
            // The profile of the endorsee
            endorseeProfile: PropTypes.shape({
              // The first name of the endorsee
              firstName: PropTypes.string,
              // The id of the endorsee
              id: PropTypes.string,
              // The last name of the endorsee
              lastName: PropTypes.string,
              // The location where the endorsee is.
              location: PropTypes.shape({
                // The city.
                city: PropTypes.oneOf(allCities),
                // The district.
                district: PropTypes.string,
              }),
              // The profile picture of the endorsee.
              profilePicture: PropTypes.shape({
                // The ID of the picture.
                id: PropTypes.string,
                // The MIME type of the picture.
                type: PropTypes.string,
              }),
              // The short bio of the endorsee
              shortBio: PropTypes.string,
            }),
            // the id of the endorsement
            id: PropTypes.string,
            // The status of the endorsements in the section
            status: PropTypes.oneOf(endorsementStatuses),
          }),
        ),
        // The profile picture of the job seeker.
        profilePicture: PropTypes.shape({
          // The ID of the picture.
          id: PropTypes.string.isRequired,
          // The MIME type of the picture.
          type: PropTypes.string.isRequired,
        }),
        // The short bio of the job seeker.
        shortBio: PropTypes.string,
        // The total endorsements
        totalEndorsements: PropTypes.number,
      }),
    ])
  ).isRequired,
  // The role based on what UI will be rendered
  role: PropTypes.oneOf(roles),
  // The saved job seekers id's array if the user is a company agent
  savedJobSeekers: PropTypes.arrayOf(PropTypes.string),
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool,
  // The signed in user role
  userRole: PropTypes.oneOf(roles),
};

Results.defaultProps = {
  companyId: '',
  savedJobSeekers: [],
};

export default Results;
