/**
 * Greek translations for common/search-total.
 *
 * @module languages/el/common/search-total
 */
const searchTotal = {
  companies_found_one: 'Βρέθηκε {{count}} εταιρία',
  companies_found_other: 'Βρέθηκαν {{count}} εταιρίες ',
  endorsers_found_one: 'Βρέθηκε {{count}} υποστηρικτής',
  endorsers_found_other: 'Βρέθηκαν {{count}} υποστηρικτές',
  job_seekers_found_one: 'Βρέθηκε {{count}} υποψήφιος/α',
  job_seekers_found_other: 'Βρέθηκαν {{count}} υποψήφιοι',
};

export default searchTotal;
