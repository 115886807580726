/**
 * Greek translations for job-seekers/profile-qualifications.
 *
 * @module languages/el/job-seekers/profile-qualifications
 */
const profileQualifications = {
  credential: 'Πιστοποιητικό',
  credential_url_invalid_error: 'Εισάγετε μια έγκυρη διεύθυνση URL.',
  credential_url_label: 'URL Πιστοποιητικού',
  credential_url_placeholder: 'Επικολλήστε τη διεύθυνση URL του πιστοποιητικού',
  description_label: 'Περιγραφή',
  description_placeholder: 'Περιγραφή',
  editor_title: 'Εκπαίδευση/κατάρτιση',
  expires_in: 'Λήγει σε',
  expires_in_before_issued_in_error: 'Η ημερομηνία λήξης δεν μπορεί να είναι πριν από την ημερομηνία έκδοσης.',
  expires_in_label: 'Ημερομηνία λήξης',
  expires_in_placeholder: 'Ημερομηνία λήξης',
  issued_in: 'Εκδόθηκε',
  issued_in_after_now_error: 'Η ημερομηνία έκδοσης δεν μπορεί να είναι στο μέλλον',
  issued_in_label: 'Ημερομηνία έκδοσης',
  issued_in_placeholder: 'Ημερομηνία έκδοσης',
  not_subject_to_expiration_label: 'Αυτό το διαπιστευτήριο δε λήγει.',
  organization_label: 'Εταιρεία / Εκπαιδευτικό Ίδρυμα',
  organization_placeholder: 'Εταιρεία / Εκπαιδευτικό Ίδρυμα',
  organization_required_error: 'Το πεδίο εταιρεία / εκπαιδευτικό ίδρυμα είναι απαραίτητο',
  see_all_button_label: 'Προβολή όλων',
  see_less_button_label: 'Δείτε λιγότερα',
  skills_label: 'Δεξιότητες',
  skills_placeholder: 'Επιλέξτε τις δεξιότητες που αποκτήσατε',
  title: 'Εκπαιδεύσεις/καταρτίσεις',
  title_label: 'Τίτλος',
  title_placeholder: 'Τίτλος',
  title_required_error: 'Το πεδίο τίτλος είναι απαραίτητο',
  type_label: 'Είδος',
  type_placeholder: 'Είδος',
  type_required_error: 'Το πεδίο είδος είναι απαραίτητο',
};

export default profileQualifications;
