/**
 * Resource method to get job seeker profiles.
 *
 * @module resources/job-seekers/export-profile
 */
import axios from 'axios';

import { extractFilename } from 'utilities/chisels';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/export-profile~Params
 * @type {object}
 * @property {string} id - The ID of the job seeker.
 */

/**
 * Result.
 *
 * @typedef {module:types/common~Content} Result
 */

/**
 * Gets the profile of a job seeker (as a pdf) based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {Params} params - The parameters.
 * @returns {Promise<Result>} - The promise.
 * @static
 */
const exportProfile = (params) => {
  return axios.request({
    method: 'post',
    raw: true,
    responseType: 'blob',
    url: `/job-seekers/${ params.id }/profile/export`,
  }).then((response) => {
    return {
      data: response.data,
      filename: extractFilename(response.headers['content-disposition']),
      type: response.headers['content-type'],
    };
  });
};

export default exportProfile;
