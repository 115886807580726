/**
 * Resource method to get skills.
 *
 * @module resources/skills/get-skills
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/skills/get-skills~Result
 * @type {object}
 * @property {module:types/skills~Skill[]} skills - The skills.
 */

/**
 * Gets the skills.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/skills/get-skills~Result>} - The promise.
 * @static
 */
const getSkills = () => {
  return axios.request({
    method: 'get',
    url: '/skills/',
  }).then((response) => {
    return response.data;
  });
};

export default getSkills;
