import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormWithSchema } from 'hooks/use-form-with-validation';
import roles, * as Roles from 'utilities/auth/roles';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';
import { companiesContactDetailsSchemaValidator, jobSeekersContactDetailsSchemaValidator } from 'utilities/validators';

const ProfileContactDetailsEditor = memo((props) => {
  const { role, profile, onSave, onCancel } = props;

  const { t } = useTranslation();

  // the schema to be validated depending on the role
  const schemaToBeValidated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersContactDetailsSchemaValidator;
    case Roles.COMPANY_AGENT:
      return companiesContactDetailsSchemaValidator;
    default:
      return {};
    }
  }, [ role ]);

  const { formState: { errors }, handleSubmit, register } = useFormWithSchema(schemaToBeValidated);

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return {
        linkedinProfileUrl: profile?.linkedinProfileUrl,
        phoneNumber1: profile?.phoneNumbers?.[0],
        phoneNumber2: profile?.phoneNumbers?.[1],
        phoneNumbers: profile?.phoneNumbers,
      };
    case Roles.COMPANY_AGENT:
      return {
        companyPosition: profile?.companyPosition,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        phoneNumber: profile?.phoneNumber,
      };
    default:
      return {};
    }
  }, [
    profile?.companyPosition,
    profile?.firstName,
    profile?.lastName,
    profile?.linkedinProfileUrl,
    profile?.phoneNumber,
    profile?.phoneNumbers,
    role,
  ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    let paramsToBeUpdated = {};
    switch (role) {
    case Roles.COMPANY_AGENT:
      paramsToBeUpdated = {
        ...values,
        companyPosition: values.companyPosition || undefined,
        firstName: values.firstName || undefined,
        lastName: values.lastName || undefined,
        phoneNumber: values.phoneNumber || undefined,
      };
      break;
    case Roles.JOB_SEEKER:
      paramsToBeUpdated = {
        ...values,
        phoneNumbers: Array.of(values.phoneNumber1, values.phoneNumber2).filter(isNotEmpty),
      };
      break;
    default:
      break;
    }
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ onSave, role, valuesAreToBeUpdated ]);

  // The first name field empty error
  const firstNameFieldEmptyError = useMemo(() => {
    if ('string.empty' !== errors.firstName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.first_name_required_error') }
      </span>
    );
  }, [ errors?.firstName?.type, t ]);

  // firstName field
  const firstNameField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.first_name_label') }
          <input
            className={
              clsx({
                'error': errors?.firstName,
              })
            }
            defaultValue={ profile?.firstName || '' }
            id='firstName'
            placeholder={ t('common:profile_contact_details.first_name_placeholder') }
            type='text'
            { ...register('firstName') }
          />
        </label>
        { firstNameFieldEmptyError }
      </div>
    );
  }, [ errors?.firstName, firstNameFieldEmptyError, profile?.firstName, register, role, t ]);

  // The last name field empty error
  const lastNameFieldEmptyError = useMemo(() => {
    if ('string.empty' !== errors.lastName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.last_name_required_error') }
      </span>
    );
  }, [ errors.lastName?.type, t ]);

  // firstName field
  const lastNameField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.last_name_label') }
          <input
            className={
              clsx({
                'error': errors?.lastName,
              })
            }
            defaultValue={ profile?.lastName || '' }
            id='lastName'
            placeholder={ t('common:profile_contact_details.last_name_placeholder') }
            type='text'
            { ...register('lastName') }
          />
        </label>
        { lastNameFieldEmptyError }
      </div>
    );
  }, [ errors?.lastName, lastNameFieldEmptyError, profile?.lastName, register, role, t ]);

  // The company position error
  const companyPositionFieldEmptyField = useMemo(() => {
    if ('string.empty' !== errors.companyPosition?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.company_position_required_error') }
      </span>
    );
  }, [ errors.companyPosition?.type, t ]);

  // email field
  const companyPositionField = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.company_position_label') }
          <input
            className={
              clsx({
                'error': errors?.companyPosition,
              })
            }
            defaultValue={ profile?.companyPosition || '' }
            id='companyPosition'
            placeholder={ t('common:profile_contact_details.company_position_placeholder') }
            type='text'
            { ...register('companyPosition') }
          />
        </label>
        { companyPositionFieldEmptyField }
      </div>
    );
  }, [ companyPositionFieldEmptyField, errors?.companyPosition, profile.companyPosition, register, role, t ]);

  // The phone number empty field error
  const phoneNumberEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // The phone number pattern error
  const phoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_invalid_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // phone number field
  const phoneNumberField = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.phone_number_label') }
          <input
            className={
              clsx({
                'error': errors?.phoneNumber,
              })
            }
            defaultValue={ profile?.phoneNumber || '' }
            id='phoneNumber'
            maxLength={ 14 }
            placeholder={ t('common:profile_contact_details.phone_number_placeholder') }
            type='text'
            { ...register('phoneNumber') }
          />
        </label>
        { phoneNumberEmptyFieldError }
        { phoneNumberPatternError }
      </div>
    );
  }, [
    errors?.phoneNumber,
    phoneNumberEmptyFieldError,
    phoneNumberPatternError,
    profile?.phoneNumber,
    register,
    role,
    t,
  ]);

  // The first phone number empty field error
  const firstPhoneNumberEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber1?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber1?.type, t ]);

  // The first phone number field empty string error
  const firstPhoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber1?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_invalid_error') }
      </span>
    );
  }, [ errors.phoneNumber1?.type, t ]);

  // The first phone number field for job seeker
  const firstPhoneNumberField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.phone_number_label') }
          <input
            className={
              clsx({
                'error': errors?.phoneNumber1,
              })
            }
            defaultValue={ profile?.phoneNumbers?.[0] || '' }
            id='phoneNumber1'
            maxLength={ 14 }
            placeholder={ t('common:profile_contact_details.phone_number_1_placeholder') }
            type='text'
            { ...register('phoneNumber1') }
          />
        </label>
        { firstPhoneNumberEmptyFieldError }
        { firstPhoneNumberPatternError }
      </div>
    );
  }, [
    errors?.phoneNumber1,
    firstPhoneNumberEmptyFieldError,
    firstPhoneNumberPatternError,
    profile?.phoneNumbers,
    register,
    role,
    t,
  ]);

  // The second phone number empty field error
  const secondhoneNumberEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber1?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber1?.type, t ]);

  // The second phone number field empty string error
  const secondPhoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber2?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.phone_number_invalid_error') }
      </span>
    );
  }, [ errors.phoneNumber2?.type, t ]);

  // The second phone number field for job seeker
  const secondPhoneNumberField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          <input
            className={
              clsx({
                'error': errors?.phoneNumber2,
              })
            }
            defaultValue={ profile?.phoneNumbers?.[1] || '' }
            id='phoneNumber2'
            maxLength={ 14 }
            placeholder={ t('common:profile_contact_details.phone_number_2_placeholder') }
            type='text'
            { ...register('phoneNumber2') }
          />
        </label>
        { secondhoneNumberEmptyFieldError }
        { secondPhoneNumberPatternError }
      </div>
    );
  }, [
    errors?.phoneNumber2,
    profile?.phoneNumbers,
    register,
    role,
    secondPhoneNumberPatternError,
    secondhoneNumberEmptyFieldError,
    t,
  ]);

  // The LinkedIn profile url field empty string error
  const linkedInEmptyStringError = useMemo(() => {
    if ('string.uri' !== errors.linkedInProfileUrl?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_contact_details.linkedin_profile_url_invalid_error') }
      </span>
    );
  }, [ errors.linkedInProfileUrl?.type, t ]);

  // The linkedIn profile url field
  const linkedInProfileUrlField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER || !profile?.linkedinProfileUrl) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_contact_details.linkedin_profile_url_label') }
          <input
            className={
              clsx({
                'error': errors?.linkedinProfileUrl,
              })
            }
            defaultValue={ profile?.linkedinProfileUrl || '' }
            id='linkedinProfileUrl'
            placeholder={ t('common:profile_contact_details.linkedin_profile_url_placeholder') }
            type='text'
            { ...register('linkedinProfileUrl') }
          />
        </label>
        { linkedInEmptyStringError }
      </div>
    );
  }, [ errors?.linkedinProfileUrl, linkedInEmptyStringError, profile?.linkedinProfileUrl, register, role, t ]);

  // The rendered section between role COMPANY_AGENT and ENDORSER
  const renderedSection = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return (
        <>
          { companyPositionField }
          { phoneNumberField }
        </>
      );
    }
    return (
      <>
        { phoneNumberField }
        { companyPositionField }
      </>
    );
  }, [ companyPositionField, phoneNumberField, role ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('common:profile_contact_details.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { firstPhoneNumberField }
        { secondPhoneNumberField }
        { linkedInProfileUrlField }
        { firstNameField }
        { lastNameField }
        { renderedSection }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileContactDetailsEditor.displayName = 'ProfileContactDetailsEditor';

ProfileContactDetailsEditor.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the user.
  profile: PropTypes.oneOfType([
    // The profile of the company
    PropTypes.shape({
      // The position of the company agent.
      companyPosition: PropTypes.string,
      // The firstname of the company agent.
      firstName: PropTypes.string,
      // The lastname of the company agent.
      lastName: PropTypes.string,
      // The phone number of the company agent.
      phoneNumber: PropTypes.string,
    }),
    // The profile of the job seeker
    PropTypes.shape({
      // The URL to the LinkedIn profile of the job seeker.
      linkedinProfileUrl: PropTypes.string,
      // The phone number(s) of the job seeker.
      phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    }),
  ]).isRequired,
  // The role based on the router
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileContactDetailsEditor.defaultProps = {};

export default ProfileContactDetailsEditor;
