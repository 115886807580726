import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import HomeShipAnimation from 'animations/ship1.json';

import './home-welcome-section.scss';
import LottieAnimationContainer from '../../common/lottie-animation-container/lottie-animation-container';

const HomeWelcomeSection = memo((props) => {
  const { userAuthenticated } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The rendered buttons container
  const renderedButtonsContainer = useMemo(() => {
    if (userAuthenticated) {
      return null;
    }
    return (
      <div className='welcome-section__buttons-container'>
        <Link
          className={
            clsx('btn btn-rounded-sm btn-blue welcome-section__button-link', {
              'welcome-section__button-link--greek': 'gr' === selectedLanguage || 'el' === selectedLanguage,
            })
          }
          id='join-as-job-seeker'
          name='Join as a job seeker'
          to='/job-seekers/sign-up'
        >
          <span className='welcome-section__button-text txt txt-md txt-bold light'>
            { t('home:welcome_section.job_seeker_button_label') }
          </span>
        </Link>
        <span className='txt txt-sm'>
          { t('home:welcome_section.or') }
        </span>
        <Link
          className={
            clsx('btn btn-rounded-sm btn-blue welcome-section__button-link', {
              'welcome-section__button-link--greek': 'gr' === selectedLanguage || 'el' === selectedLanguage,
            })
          }
          id='join-as-job-seeker'
          name='Join as a job seeker'
          to='/companies/sign-up'
        >
          <span className='welcome-section__button-text txt txt-md txt-bold light'>
            { t('home:welcome_section.company_button_label') }
          </span>
        </Link>
      </div>
    );
  }, [ selectedLanguage, t, userAuthenticated ]);

  return (
    <div className='welcome-section'>
      <LottieAnimationContainer
        animatedIconClassName='welcome-section__animated-icon'
        animationData={ HomeShipAnimation }
        className='welcome-section__animation-container'
      />
      <div className='welcome-section__welcome-card'>
        <p className='welcome-section__card-text hdg hdg-lg dark'>
          { t('home:welcome_section.card_description') }
        </p>
        { renderedButtonsContainer }
      </div>
    </div>
  );
});

HomeWelcomeSection.displayName = 'WelcomeSection';

HomeWelcomeSection.propTypes = {
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
};

HomeWelcomeSection.defaultProps = {};

export default HomeWelcomeSection;
