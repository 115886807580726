import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';
import * as Roles from 'utilities/auth/roles';

import './home-learn-more-card.scss';

const HomeLearnMoreCard = memo((props) => {
  const { description, icon, title, role } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // visible animation on job seeker card
  const jobSeekerCardVisibleAnimation = useMemo(() => {
    return isVisible && role === Roles.JOB_SEEKER;
  }, [ isVisible, role ]);

  // visible animation on company card
  const companyCardVisibleAnimation = useMemo(() => {
    return isVisible && role === Roles.COMPANY_AGENT;
  }, [ isVisible, role ]);

  return (
    <div
      className={
        clsx('home-learn-more-card', {
          'home-learn-more-card__company-card-visible': companyCardVisibleAnimation,
          'home-learn-more-card__job-seeker-card-visible': jobSeekerCardVisibleAnimation,
        })
      }
      ref={ containerRef }
    >
      <div className='home-learn-more-card__icon-container'>
        { icon }
      </div>
      <h4 className='home-learn-more-card__title txt txt-bold'>
        { title }
      </h4>
      <p className='home-learn-more-card__description txt txt-sm'>
        { description }
      </p>
    </div>
  );
});

HomeLearnMoreCard.displayName = 'LearnMoreCard';

HomeLearnMoreCard.propTypes = {
  // The card description
  description: PropTypes.string.isRequired,
  // The icon of the card
  icon: PropTypes.node.isRequired,
  // Whether the section is a company or job seeker collection of cards
  role: PropTypes.oneOf([ Roles.JOB_SEEKER, Roles.COMPANY_AGENT ]).isRequired,
  // The title of the card
  title: PropTypes.string.isRequired,
};

HomeLearnMoreCard.defaultProps = {};

export default HomeLearnMoreCard;
