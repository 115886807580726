/**
 * Greek translations for utilities/citizenships.
 *
 * @module languages/el/utilities/citizenships
 */
const citizenships = {
  ABW: 'Αρούμπαν',
  AFG: 'Aφγανική',
  AGO: 'Aγκολέζικη',
  AIA: 'Ανγκούλαν',
  ALA: 'Νησί Åland',
  ALB: 'Αλβανική',
  AND: 'Ανδορρανή',
  ARE: 'Εμιράτα',
  ARG: 'Αργεντίνικη',
  ARM: 'Αρμενική',
  ASM: 'Αμερικανική Σαμόα',
  ATA: 'Ανταρκτική ',
  ATF: 'Γαλλικά Νότια Εδάφη',
  ATG: 'Αντίγκουα ή Μπαρμπούντα',
  AUS: 'Αυστραλιανή',
  AUT: 'Αυστριακή',
  AZE: 'Αζέρικη',
  BDI: 'Μπουρουντιανή ',
  BEL: 'Βελγική',
  BEN: 'Μπενινέζικη',
  BES: 'Μποναίρ',
  BFA: 'Μπουρκιναμπέ',
  BGD: 'Μπαγκλαντεσιανή',
  BGR: 'Βουλγαρική',
  BHR: 'Μπαχρέιν',
  BHS: 'Μπαχαμέζικη',
  BIH: 'Βοσνιακή',
  BLM: 'Βαρθολομέζικη',
  BLR: 'Λευκορωσική',
  BLZ: 'Μπελίζ',
  BMU: 'Βερμουδιανή',
  BOL: 'Βολιβιανή ',
  BRA: 'Βραζιλιάνικη',
  BRB: 'Μπαρμπάντος',
  BRN: 'Μπρούνιαν',
  BTN: 'Μπουτάν',
  BVT: 'Νησί Μπουβέ',
  BWA: 'Τσουάνα, Μποβουτανάν',
  CAF: 'Κεντροαφρικανική',
  CAN: 'Καναδική',
  CCK: 'Νησί Κόκος',
  CHE: 'Ελβετική',
  CHL: 'Χιλιανή',
  CHN: 'Κινεζική',
  CIV: 'Ιβοριανή ',
  CMR: 'Καμερουνέζικη',
  COD: 'Κονγκολεζική',
  COG: 'Κονγκολεζική',
  COK: 'Νησί Κουκ',
  COL: 'Κολομβιανή',
  COM: 'Κομορικανή',
  CPV: 'Πράσινου Ακρωτηρίου',
  CRI: 'Κοσταρικανή',
  CUB: 'Κουβανέζικη',
  CUW: 'Κουρασάοαν',
  CXR: 'Νήσος των Χριστουγέννων',
  CYM: 'Καϊμανική',
  CYP: 'Κυπριακή',
  CZE: 'Τσεχία',
  DEU: 'Γερμανική',
  DJI: 'Τζιμπουτιανή',
  DMA: 'Δομινικανοική',
  DNK: 'Δανέζικη',
  DOM: 'Δομινικανοική',
  DZA: 'Αλγερινή',
  ECU: 'Εκουαδορική',
  EGY: 'Αιγυπτιακή',
  ERI: 'Ερυθραϊκή',
  ESH: 'Σαχραουιάν',
  ESP: 'Ισπανική',
  EST: 'Εστονική',
  ETH: 'Αιθιοπική',
  FIN: 'Φινλανδική',
  FJI: 'Φίτζι',
  FLK: 'Νησί Φώκλαντ',
  FRA: 'Γαλλική',
  FRO: 'Φεροϊκή',
  FSM: 'Μικρονησιακή',
  GAB: 'Γκαμπονέζικη',
  GBR: 'Αγγλική',
  GEO: 'Γεωργιανή',
  GGY: 'Αγγλονορμανδικές νήσοι',
  GHA: 'Γκανέζικη',
  GIB: 'Γιβραλτάρ',
  GIN: 'Γουινέας',
  GLP: 'Γουαδελούπη',
  GMB: 'Γκάμπια',
  GNB: 'Μπισάου-Γουινέας',
  GNQ: 'Ισημερινή Γουινέα',
  GRC: 'Ελληνική',
  GRD: 'Γρεναδική',
  GRL: 'Γροιλανδική',
  GTM: 'Γουατεμάλα',
  GUF: 'Γαλλική Γουιάνα',
  GUM: 'Γουαμανική',
  GUY: 'Γουιάνας',
  HKG: 'Χονγκ Κονγκέζικη',
  HMD: 'Νήσος Χερντ ή Νήσοι Μακ Ντόναλντ',
  HND: 'Ονδουρανική',
  HRV: 'Κροατική ',
  HTI: 'Αϊτινή',
  HUN: 'Ουγγρική',
  IDN: 'Ινδονησιακή',
  IMN: 'Μανξ',
  IND: 'Ινδική',
  IOT: 'Μπιότ',
  IRL: 'Ιρλανδική',
  IRN: 'Ιρανική',
  IRQ: 'Ιρακινή',
  ISL: 'Ισλανδική ',
  ISR: 'Ισραηλινή',
  ITA: 'Ιταλική',
  JAM: 'Τζαμαϊκανή',
  JEY: 'Αγγλονορμανδικές νήσοι',
  JOR: 'Ιορδανική',
  JPN: 'Ιάπωνική',
  KAZ: 'Καζακική',
  KEN: 'Κενυάτικη',
  KGZ: 'Κιργιζιστάν',
  KHM: 'Καμποτζιανή',
  KIR: 'Ι-Κιριμπάτι',
  KNA: 'Νεβϊζιανή',
  KOR: 'Νοτιοκορεατική',
  KWT: 'Κουβεϊτιανή',
  LAO: 'Λαοτινή',
  LBN: 'Λιβανέζικη',
  LBR: 'Λιβεριανή',
  LBY: 'Λιβυκή',
  LCA: 'Σαίν Λούσιαν',
  LIE: 'Λιχτενστάιν',
  LKA: 'Σιναλεζική',
  LSO: 'Μπασόθο',
  LTU: 'Λιθουανική',
  LUX: 'Λουξεμβουργιανή',
  LVA: 'Λεττονική',
  MAC: 'Κινεζική',
  MAF: 'Σαίν Μαρτινίζ',
  MAR: 'Μαροκινή',
  MCO: 'Μονεγασκική',
  MDA: 'Μολδαβική',
  MDG: 'Μαλγασική',
  MDV: 'Μαλδιβική',
  MEX: 'Μεξικάνικη',
  MHL: 'Μαρσαλέζικη',
  MKD: 'Σλαβομακεδονική',
  MLI: 'Μαλινέζικη',
  MLT: 'Μαλτέζικη',
  MMR: 'Βιρμανική',
  MNE: 'Μαυροβουνιακή',
  MNG: 'Μογγολική',
  MNP: 'Βόρεια Μαριανά',
  MOZ: 'Μοζαμβικανική',
  MRT: 'Μαυριτανική',
  MSR: 'Μονσερατιανική',
  MTQ: 'Μαρτινικανή',
  MUS: 'Μαυρικιανική',
  MWI: 'Μαλάουιαν',
  MYS: 'Μαλαισιανή',
  MYT: 'Μαχοράν',
  NAM: 'Ναμίμπια',
  NCL: 'Νέα Καληδονία',
  NER: 'Νιγηριανή',
  NFK: 'Νησί Νόρφολκ',
  NGA: 'Νιγηριανή',
  NIC: 'Νικαραγουινή',
  NIU: 'Νίουαν',
  NLD: 'Ολλανδική',
  NOR: 'Νορβηγική',
  NPL: 'Νεπαλική',
  NRU: 'Ναουρουάν',
  NZL: 'Νεοζηλανδική',
  OMN: 'Ομάνι',
  PAK: 'Πακιστανική',
  PAN: 'Παναμάνιαν',
  PCN: 'Νησί Πίτκερν',
  PER: 'Περουβιανή',
  PHL: 'Φιλιππινέζικη',
  PLW: 'Παλαουάν',
  PNG: 'Παπούα Νέα Γουινέα',
  POL: 'Πολωνική',
  PRI: 'Πορτορικανική',
  PRK: 'Βορειοκορεάτικη',
  PRT: 'Πορτογαλική',
  PRY: 'Παραγουανή',
  PSE: 'Παλαιστίνια',
  PYF: 'Γαλλική Πολυνησιακή',
  QAT: 'Κατάρ',
  REU: 'Ρειουνέζ',
  ROU: 'Ρουμάνικη',
  RUS: 'Ρωσική',
  RWA: 'Ρουάντα',
  SAU: 'Σαουδαραβική',
  SDN: 'Σουδανέζικη',
  SEN: 'Σενεγαλέζικη',
  SGP: 'Σιγκαπουριανή',
  SGS: 'Νότια Γεωργία ή Νήσοι Σάντουιτς',
  SHN: 'Σαίν Χελένιος',
  SJM: 'Σβάλμπαρντ',
  SLB: 'Νήσος Σολομώντα',
  SLE: 'Σιέρα Λεόνε',
  SLV: 'Σαλβαδοριανή',
  SMR: 'Σαμαριανή',
  SOM: 'Σομαλάκη',
  SPM: 'Σαίν Πιέραις',
  SRB: 'Σερβική',
  SSD: 'Νότιο Σουδάν',
  STP: 'Σάο Τομέαν',
  SUR: 'Σουριναμεζική',
  SVK: 'Σλοβάκικη',
  SVN: 'Σλοβένικη',
  SWE: 'Σουηδική ',
  SWZ: 'Σουαζί',
  SXM: 'Άγιος Μαρτίνος',
  SYC: 'Σεϋχέλλικη',
  SYR: 'Συριακή',
  TCA: 'Νήσος Τερκς και Κάικος',
  TCD: 'Τσαντ',
  TGO: 'Τόγκο',
  THA: 'Ταϊλανδέζικη',
  TJK: 'Τατζικιστάν',
  TKL: 'Τοκελαουάν',
  TKM: 'Τουρκμενική',
  TLS: 'Τιμόρ',
  TON: 'Τόνγκα',
  TTO: 'Τογκγιόνιαν',
  TUN: 'Τυνησιακή',
  TUR: 'Τουρκική',
  TUV: 'Τάφτιαν',
  TWN: 'Κινεζική',
  TZA: 'Τανζανική',
  UGA: 'Ουγκάντα',
  UKR: 'Ουκρανική',
  UMI: 'Αμερικανική',
  URY: 'Ουρουγουανή',
  USA: 'Αμερικανική',
  UZB: 'Ουζμπεκιστάν',
  VAT: 'Βατικανή',
  VCT: 'Άγιος Βικεντιανός',
  VEN: 'Βενεζουέλικη',
  VGB: 'Βρετανική Παρθένος Νήσος',
  VIR: 'Παρθένο Νησί των ΗΠΑ',
  VNM: 'Βιετναμέζικη',
  VUT: 'NI-Βανουάτου',
  WLF: 'Φουτουνάν',
  WSM: 'Σαμοανική',
  YEM: 'Υεμένη',
  ZAF: 'Νοτιοαφρικανική',
  ZMB: 'Ζάμπιαν',
  ZWE: 'Ζιμπάμπουε',
};

export default citizenships;
