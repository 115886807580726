/**
 * English translations for job-seekers/profile-qualifications.
 *
 * @module languages/en/job-seekers/profile-qualifications
 */
const profileQualifications = {
  credential: 'Credential',
  credential_url_invalid_error: 'Enter a valid URL.',
  credential_url_label: 'Credential URL',
  credential_url_placeholder: 'Paste the credential URL',
  description_label: 'Description',
  description_placeholder: 'Description',
  editor_title: 'Qualification',
  expires_in: 'Expires in',
  expires_in_before_issued_in_error: 'Date of expiry cannot be before issue date.',
  expires_in_label: 'Date of expiry',
  expires_in_placeholder: 'Date of expiry',
  issued_in: 'Issued in',
  issued_in_after_now_error: 'Issue date cannot be in the future.',
  issued_in_label: 'Issue date',
  issued_in_placeholder: 'Issue date',
  not_subject_to_expiration_label: 'This credential does not expire.',
  organization_label: 'Company / Institution',
  organization_placeholder: 'Company / Institution',
  organization_required_error: 'Company / institution is required.',
  see_all_button_label: 'See all',
  see_less_button_label: 'See less',
  skills_label: 'Skills',
  skills_placeholder: 'Select the skills you gained',
  title: 'Qualifications',
  title_label: 'Title',
  title_placeholder: 'Title',
  title_required_error: 'Title is required.',
  type_label: 'Type',
  type_placeholder: 'Type',
  type_required_error: 'Type is required.',
};

export default profileQualifications;
