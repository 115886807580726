/**
 * English translations for common/profile-job-sectors-interested-in.
 *
 * @module languages/en/common/profile-job-sectors-interested-in
 */
const profileJobSectorsInterestedIn = {
  editor_title: 'Job sectors interested in',
  job_sectors_placeholder: 'Select the job sectors you are interested in',
  title: 'Job sectors interested in',
};

export default profileJobSectorsInterestedIn;
