/**
 * Select.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import BaseSelect, { components as SelectComponents } from 'react-select';
import Creatable from 'react-select/creatable';

import './select.scss';

const Select = forwardRef((props, ref) => {
  const SelectComponent = props.editable ? Creatable : BaseSelect;

  const Control = ({ children, ...controlProps }) => {
    return (
      <SelectComponents.Control { ...controlProps }>
        { props.icon }
        { children }
      </SelectComponents.Control>
    );
  };
  Control.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const Input = ({ ...inputProps }) => {
    return (
      <SelectComponents.Input { ...inputProps } maxLength={ props.maxLength } />
    );
  };
  Input.propTypes = {
  };

  return (
    <SelectComponent
      className={ clsx('ody-select', props.className) }
      classNamePrefix='ody-select'
      components={
        {
          Control,
          Input,
        }
      }
      isClearable={ props.clearable }
      isDisabled={ props.disabled }
      maxMenuHeight={ 256 }
      menuPlacement='auto'
      menuPortalTarget={ document.body }
      menuPosition='fixed'
      onChange={ props.onChange }
      options={ props.options }
      placeholder={ props.placeholder }
      ref={ ref }
      value={ props.value }
    />
  );
});

Select.propTypes = {
  // The class(es) to apply to the select.
  className: PropTypes.string,
  // Whether the select is clearable.
  clearable: PropTypes.bool,
  // Whether the select is disabled.
  disabled: PropTypes.bool,
  // Whether the select is editable.
  editable: PropTypes.bool,
  // The icon to show in the select.
  icon: PropTypes.node,
  // The maximum length of the characters to type.
  maxLength: PropTypes.number,
  // The function ((object) => void) to invoke when something is selected or unselected.
  onChange: PropTypes.func,
  // The available options.
  options: PropTypes.arrayOf(PropTypes.shape({
    // The label of the option.
    label: PropTypes.string.isRequired,
    // The value of the option.
    value: PropTypes.any.isRequired,
  }).isRequired).isRequired,
  // The placeholder.
  placeholder: PropTypes.string,
  // The selected option.
  value: PropTypes.shape({
    // The label of the option.
    label: PropTypes.string.isRequired,
    // The value of the option.
    value: PropTypes.any.isRequired,
  }),
};

Select.defaultProps = {
  clearable: true,
  disabled: false,
  editable: false,
  maxLength: 32,
};

export default Select;
