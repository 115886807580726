/**
 * English translations for utilities for job seekers.
 *
 * @module languages/en/utilities/job-seekers
 */
import filterTypes from './filter-types';
import sortKeys from './sort-keys';

const jobSeekers = {
  filter_types: filterTypes,
  sort_keys: sortKeys,
};

export default jobSeekers;
