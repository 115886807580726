/**
 * Greek translations for settings.
 *
 * @module languages/el/settings
 */
const settings = {
  account: {
    deactivate_account: {
      account_deactivated_message: 'Ο λογαριασμός σας έχει απενεργοποιηθεί',
      account_deactivation_failed_error: 'Αποτυχία απενεργοποίησης λογαριασμού',
      account_reactivated_message: 'Ο λογαριασμός σας έχει ενεργοποιηθεί',
      account_reactivation_failed_error: 'Αποτυχία ενεργοποίησης λογαριασμού',
      deactivate_account_confirm_text: 'Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε τον λογαριασμό σας;',
      deactivate_button_label: 'Απενεργοποίηση',
      deactivated_title: 'Ενεργοποίηση λογαριασμού',
      // eslint-disable-next-line max-len
      instructions: 'Όταν ο λογαριασμός σας απενεργοποιηθεί, κανείς δεν μπορεί να δει το προφίλ σας και δεν μπορείτε να αλληλεπιδράσετε με άλλους χρήστες.\n\nΜπορείτε να ενεργοποιήσετε ξανά τον λογαριασμό σας ανά πάσα στιγμή.',
      non_deactivated_title: 'Απενεργοποίηση λογαριασμού',
      reactivate_button_label: 'Ενεργοποίηση',
    },
    email: {
      title: 'Email',
    },
    password: {
      bad_password_error: 'Ο τρέχων κωδικός πρόσβασης είναι λάθος.',
      current_password_label: 'Τρέχων κωδικός πρόσβασης',
      current_password_placeholder: 'Τρέχων κωδικός πρόσβασης',
      current_password_required_error: 'Το πεδίο τρέχων κωδικός πρόσβασης είναι απαραίτητο',
      editor_title: 'Κωδικός',
      // eslint-disable-next-line max-len
      new_password_and_new_password_confirmation_mismatch_error: 'Ο νέος κωδικός πρόσβασης και η επιβεβαίωση νέου κωδικού πρόσβασης δεν ταιριάζουν',
      new_password_confirmation_label: 'Επιβεβαιώστε τον καινούργιο κωδικό πρόσβασης',
      new_password_confirmation_placeholder: 'Επιβεβαιώστε τον καινούργιο κωδικό πρόσβασης',
      // eslint-disable-next-line max-len
      new_password_invalid_error: 'Ο κωδικός πρόσβασης πρέπει να περιέχει:\nΤουλάχιστον 8 χαρακτήρες.\nΤουλάχιστον ένα ψηφίο.\nΤουλάχιστον ένα πεζό γράμμα.\nΤουλάχιστον ένα κεφαλαίο γράμμα.\nΤουλάχιστον έναν ειδικό χαρακτήρα (!"#$%&\'()* ,-./:;<=>@[]^_`{|}~).',
      new_password_label: 'Νέος κωδικός πρόσβασης',
      new_password_placeholder: 'Νέος κωδικός πρόσβασης',
      new_password_required_error: 'Το πεδίο νέος κωδικός πρόσβασης είναι απαραίτητο',
      password_changed_message: 'Ο κωδικός πρόσβασης σας έχει αλλάξει',
      title: 'Κωδικός πρόσβασης',
    },
    title: 'Λογαριασμός',
  },
  common: {
    cancel_button_label: 'Ακύρωση',
    change_button_label: 'Μετατροπή',
    general_error: 'Ωχ! Κάτι πήγε στραβά.',
  },
  preferences: {
    language: {
      editor_title: 'Γλώσσα',
      language_changed_message: 'Η γλώσσα σας άλλαξε.',
      language_label: 'Γλώσσα',
      language_placeholder: 'Επιλέξτε τη γλώσσα της εφαρμογής',
      language_required_error: 'Το πεδίο γλώσσα είναι απαραίτητο',
      title: 'Γλώσσα',
    },
    title: 'Προτιμήσεις',
  },
};

export default settings;
