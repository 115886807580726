/**
 * English translations for common/profile-professions.
 *
 * @module languages/en/common/profile-professions
 */
const profileProfessions = {
  editor_title: 'Jobs interested in',
  professions_placeholder: 'Select the jobs you are interested in',
  title: 'Jobs interested in',
};

export default profileProfessions;
