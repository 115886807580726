/**
 * Resource method to get the completed endorsements.
 *
 * @module resources/endorsers/get-completed-endorsements
 */
import axios from 'axios';
/**
 * Params.
 *
 * @typedef module:resources/endorsers/get-completed-endorsements~Params
 * @type {object}
 * @property {string} id - The ID of the endorser.
 */

/**
 * Result.
 *
 * @typedef module:resources/endorsers/get-completed-endorsements~Result
 * @type {object}
 * @property {any} endorsements
 */

/**
 * Gets the completed endorsements.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/endorsers/get-completed-endorsements~Params} params - The parameters.
 * @returns {Promise<module:resources/endorsers/get-completed-endorsements~Result>} - The promise.
 * @static
 */
const getCompletedEndorsements = (params) => {
  return axios.request({
    method: 'get',
    url: `/endorsers/${ params.id }/endorsements/`,
  }).then((response) => {
    return response.data;
  });
};

export default getCompletedEndorsements;
