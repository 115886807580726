/**
 * Profile image editor
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import upload from 'images/upload.png';
import roles, * as Roles from 'utilities/auth/roles';
import { isEmpty } from 'utilities/chisels';
import { profileImageSchemaValidator } from 'utilities/validators';

import './profile-image-editor.scss';

const ProfileImageEditor = memo((props) => {
  const { profile, role, onSave, onCancel } = props;

  // The image.
  const [ image, setImage ] = useState(undefined);
  // The error when uploading an image.
  const [ imageError, setImageError ] = useState('');

  // The allowed image formats.
  const ALLOWED_IMAGE_FORMATS = useMemo(() => {
    return [
      'image/jpeg',
      'image/png',
    ];
  }, []);

  // The maximum allowed image size (in bytes).
  const MAXIMUM_ALLOWED_IMAGE_SIZE = useMemo(() => {
    return 8 * 1024 * 1024;
  }, []);

  // Reference to the file input.
  const fileRef = useRef(null);

  const { t } = useTranslation();

  const { formState: { _errors }, handleSubmit, reset, register, setValue }
    = useFormWithSchema(profileImageSchemaValidator);

  // The profile image based on the user role
  const profileImage = useMemo(() => {
    return profile?.logo || profile?.profilePicture;
  }, [ profile?.logo, profile?.profilePicture ]);

  // The profile image url based on the user role
  const profileImageUrl = useMemo(() => {
    switch (role) {
    case Roles.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ profile?.id }/profile/logo`
          + `?id=${ profileImage?.id }`;
    case Roles.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ profile?.id }/profile/picture`
          + `?id=${ profileImage?.id }`;
    case Roles.ENDORSER:
      return `${ env.CORE_BASE_URL }/endorsers/${ profile?.id }/profile/picture`
          + `?id=${ profileImage?.id }`;
    default:
      return '';
    }
  }, [ profile?.id, profileImage?.id, role ]);

  // Fill in the form with values from the profile.
  useEffect(() => {
    const values = {};
    values.file = null;
    values.profileImage = profileImage?.id || '';
    reset(values);
  }, [ profileImage?.id, reset ]);

  // Preview the logo in the profile.
  useEffect(() => {
    if (undefined === profileImage) {
      setImage(undefined);
      return;
    }
    setImage({
      ...profileImage,
      url: profileImageUrl,
    });
  }, [ profileImage, profileImageUrl ]);

  const { ref, ...rest } = register('file', {
    onChange: (e) => {
      const [ file, ..._others ] = e.target.files;
      if (undefined === file) {
        return;
      }
      if (undefined !== image) {
        URL.revokeObjectURL(image.url);
      }
      setImageError('');
      setImage(undefined);
      setValue('profileImage', '');
      // Check that the uploaded image has one of the allowed formats.
      if (-1 === ALLOWED_IMAGE_FORMATS.indexOf(file.type)) {
        setImageError(t('profile_image:unsupported_image_format_error'));
        setValue('file', null);
        return;
      }
      // Check that the uploaded image is not too large.
      if (MAXIMUM_ALLOWED_IMAGE_SIZE < file.size) {
        setImageError(t('profile_image:too_large_image_error'));
        setValue('file', null);
        return;
      }
      setImage({
        type: file.type,
        url: URL.createObjectURL(file),
      });
    },
  });

  // The editor title
  const editorTitle = useMemo(() => {
    switch (role) {
    case Roles.COMPANY_AGENT:
      return t('common:profile_image.editor_title_company_agent');
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return t('common:profile_image.editor_title_job_seeker');
    default:
      return '';
    }
  }, [ role, t ]);

  // Function that is invoked when you want to invoke the file ref
  const onFileRefClick = useCallback(() => {
    fileRef.current?.click();
  }, []);

  // The upload button
  const uploadButton = useMemo(() => {
    if (undefined === image?.url) {
      return (
        <button
          className='no-picture btn btn-trans'
          onClick={ onFileRefClick }
        >
          <img alt='Upload' src={ upload } />
          <div className='txt txt-sm'>
            { t('common:profile_image.no_logo_message') }
          </div>
        </button>
      );
    }
    return (
      <div className='wrapper'>
        <CircularImage
          alt='Logo'
          imageSource={ image?.url }
        />
      </div>
    );
  }, [ image?.url, onFileRefClick, t ]);

  // Function that is invoked then deleting the image
  const onDeleteImageClick = useCallback(() => {
    if (undefined !== image) {
      URL.revokeObjectURL(image.url);
    }
    setImage(undefined);
    setValue('profileImage', '');
    setValue('file', null);
  }, [ image, setValue ]);

  // The upload/change button label
  const uploadChangeButtonLabel = useMemo(() => {
    if (undefined === image?.url) {
      return t('common:profile_image.upload_button_label');
    }
    return t('common:profile_image.change_button_label');
  }, [ image?.url, t ]);

  // The upload image error
  const uploadImageError = useMemo(() => {
    if (undefined === imageError) {
      return null;
    }
    return (
      <div className='error'>{ imageError }</div>
    );
  }, [ imageError ]);

  // Function that is invoked when clicking the input type file
  const onInputFileClick = useCallback((e) => {
    ref(e);
    fileRef.current = e;
  }, [ ref ]);

  // whether the values are not the same to be updated based  on the role
  const areValuesToBeUpdated = useCallback((profileImage) => {
    switch (role) {
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return profileImage !== profile?.profilePicture?.id;
    case Roles.COMPANY_AGENT:
      return profileImage !== profile?.logo?.id;
    default:
      return false;
    }
  }, [ profile?.logo?.id, profile?.profilePicture?.id, role ]);

  // renders the profile picture to be updated
  const renderProfilePictureToBeUpdated = useCallback((profileImage) => {
    if (isEmpty(profileImage)) {
      return undefined;
    }
    return {
      id: profileImage,
    };
  }, []);

  // function that handles the save
  const handleSave = useCallback((values) => {
    const { file } = values;
    const paramsToBeUpdated = {
      file: undefined,
      profilePicture: renderProfilePictureToBeUpdated(values.profileImage),
    };
    const isToBeUpdated = areValuesToBeUpdated(values.profileImage);
    onSave(paramsToBeUpdated, isToBeUpdated, file);
  }, [ areValuesToBeUpdated, onSave, renderProfilePictureToBeUpdated ]);

  return (
    <div className='profile-image-editor dark'>
      <div className='hdg hdg-md'>
        { editorTitle }
      </div>
      <div className='image'>
        <div className='preview'>
          { uploadButton }
        </div>
        <div className='buttons'>
          <button
            className='btn btn-sm btn-rounded-sm btn-red'
            onClick={ onDeleteImageClick }
          >
            { t('common:profile_image.delete_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onFileRefClick }
          >
            { uploadChangeButtonLabel }
          </button>
        </div>
        { uploadImageError }
      </div>
      <form
        noValidate
        onSubmit={ handleSubmit(handleSave) }
      >
        <div className='fields'>
          <div className='field'>
            <input
              type='hidden'
              { ...register('profileImage') }
            />
            <input
              accept={ ALLOWED_IMAGE_FORMATS.join(', ') }
              className='hidden'
              ref={ onInputFileClick }
              type='file'
              { ...rest }
            />
          </div>
        </div>
        { uploadImageError }
        <div className='buttons'>
          <div className='right-buttons'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white'
              onClick={ onCancel }
            >
              { t('common:profile_image.cancel_button_label') }
            </button>
            <input
              className='btn btn-sm btn-rounded-sm btn-blue'
              type='submit'
              value={ t('common:profile_image.save_button_label') }
            />
          </div>
        </div>
      </form>
    </div>
  );
});

ProfileImageEditor.displayName = 'ProfileImageEditor';

ProfileImageEditor.propTypes = {
  // The function (() => void) to invoke when canceling the profile image editor.
  onCancel: PropTypes.func.isRequired,
  // The function ((Profile) => void) to invoke when saving the profile image to profile.
  onSave: PropTypes.func.isRequired,
  // The profile of the user.
  profile: PropTypes.oneOfType([
    // The profile attributes of the JOB SEEKER
    PropTypes.shape({
      // The profile picture of the job seeker.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string,
        // The MIME type of the picture.
        type: PropTypes.string,
      }),
    }),
    // The profile attributes of the COMPANY AGENT
    PropTypes.shape({
      // The profile id of the company
      id: PropTypes.string,
      // The profile picture of the company agent.
      logo: PropTypes.shape({
        // The ID of the logo.
        id: PropTypes.string,
        // The MIME type of the logo.
        type: PropTypes.string,
      }),
    }),
  ]),
  // The role of the user's profile
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileImageEditor.defaultProps = {};

export default ProfileImageEditor;
