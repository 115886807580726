/**
 * Resource method to get pending companies profiles.
 *
 * @module resources/administrators/get-pending-companies
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/companies/profiles~Profile[]} Result
 */

/**
 * Gets the profile of pending companies.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/administrators/get-pending-companies~Result>} - The promise.
 * @static
 */
const getProfile = () => {
  return axios.request({
    method: 'get',
    url: 'administrators/companies/pending',
  }).then((response) => {
    return response.data;
  });
};

export default getProfile;
