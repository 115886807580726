/**
 * Common - Profile Completion
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import incomplete from 'images/incomplete.png';
import roles, * as Roles from 'utilities/auth/roles';
import companiesParts from 'utilities/companies/parts';
import jobSeekersParts, * as JOB_SEEKER_PARTS from 'utilities/job-seekers/parts';

import './profile-completion.scss';

const ProfileCompletion = memo((props) => {
  // The props
  const { partCompletions, percentage, userRole, userId, companyId } = props;

  const { t } = useTranslation();

  // Whether the profile is complete.
  const complete = useMemo(() => {
    return 100 === percentage;
  }, [ percentage ]);

  // Next steps title
  const nextStepsTitle = useMemo(() => {
    if (complete) {
      return null;
    }
    return (
      <div className='hdg hdg-md'>
        { t('common:profile_completion.title') }
      </div>
    );
  }, [ complete, t ]);

  // Percentage Title
  const percentageTitle = useMemo(() => {
    if (100 === percentage) {
      return t('common:profile_completion.great_job');
    }
    if (70 <= percentage) {
      return t('common:profile_completion.almost_there');
    }
    return '';
  }, [ percentage, t ]);

  // Incomplete part completions steps
  const incompletePartCompletionsSteps = useMemo(() => {
    return partCompletions.filter((partCompletion) => {
      return !partCompletion.complete;
    });
  }, [ partCompletions ]);

  const renderIncompletePartCompletionStepDisplayString = useCallback((incompletePartCompletionsStep) => {
    if (userRole === Roles.JOB_SEEKER) {
      return t(`job_seekers:steps.${ incompletePartCompletionsStep.part }`);
    }
    return t(`companies:steps.${ incompletePartCompletionsStep.part }`);
  }, [ t, userRole ]);

  // incomplete steps
  const incompleteSteps = useMemo(() => {
    return incompletePartCompletionsSteps.map((incompletePartCompletionsStep) => {
      return (
        <div
          className='incomplete-step'
          key={ incompletePartCompletionsStep.part }
        >
          <span className='txt txt-sm'>
            { renderIncompletePartCompletionStepDisplayString(incompletePartCompletionsStep) }
          </span>
          <div className='image'>
            <img alt='Incomplete Step' src={ incomplete } />
          </div>
        </div>
      );
    });
  }, [ incompletePartCompletionsSteps, renderIncompletePartCompletionStepDisplayString ]);

  // Whether to render the incomplete steps
  const renderedIncompleteSteps = useMemo(() => {
    if (complete) {
      return null;
    }
    return (
      <div className='incomplete-steps'>
        { incompleteSteps }
      </div>
    );
  }, [ complete, incompleteSteps ]);

  // The milestones on the roadmap for the profile completion.
  const jobSeekersParts = useMemo(() => {
    if (userRole !== Roles.JOB_SEEKER) {
      return {};
    }
    return partCompletions?.reduce((acc, partCompletion) => {
      acc[partCompletion.part] = partCompletion.complete;
      return acc;
    }, {});
  }, [ partCompletions, userRole ]);

  // The milestones of the profile completion
  const jobSeekersMilestones = useMemo(() => {
    if (userRole !== Roles.JOB_SEEKER) {
      return [];
    }
    return [
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.PERSONAL] && jobSeekersParts[JOB_SEEKER_PARTS.CONTACT],
        name: t('dashboard:next_steps.milestones.item1'),
      },
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.PROFESSIONS] && jobSeekersParts[JOB_SEEKER_PARTS.JOB_SECTORS],
        name: t('dashboard:next_steps.milestones.item2'),
      },
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.LANGUAGES] && jobSeekersParts[JOB_SEEKER_PARTS.COMPETENCIES],
        name: t('dashboard:next_steps.milestones.item3'),
      },
      {
        complete: false,
        name: t('dashboard:next_steps.milestones.item4'),
      },
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.ABOUT_ME],
        name: t('dashboard:next_steps.milestones.item5'),
      },
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.EXPERIENCE] && jobSeekersParts[JOB_SEEKER_PARTS.QUALIFICATIONS],
        name: t('dashboard:next_steps.milestones.item6'),
      },
      {
        complete: jobSeekersParts[JOB_SEEKER_PARTS.RESUME],
        name: t('dashboard:next_steps.milestones.item7'),
      },
      {
        complete: false,
        name: t('dashboard:next_steps.milestones.item8'),
      },
    ];
  }, [ jobSeekersParts, t, userRole ]);

  // The milestones rendered to the UI
  const renderedMilestones = useMemo(() => {
    if (userRole !== Roles.JOB_SEEKER) {
      return null;
    }
    return jobSeekersMilestones.map((milestone, index) => {
      return (
        <div
          className={
            clsx('milestone', {
              'complete': milestone.complete,
            })
          }
          key={ milestone.name + index }
        >
          <div className='number'>
            { index + 1 }
          </div>
          <div className='txt txt-md'>
            { milestone.name }
          </div>
        </div>
      );
    });
  }, [ jobSeekersMilestones, userRole ]);

  // The roadmap of the profile completion
  const renderedRoadmap = useMemo(() => {
    if (userRole !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='roadmap light hidden-xs hidden-sm hidden-md'>
        { renderedMilestones }
      </div>
    );
  }, [ renderedMilestones, userRole ]);

  // See your profile link
  const seeYourProfileLink = useMemo(() => {
    if (userRole === Roles.COMPANY_AGENT) {
      return `/companies/${ companyId }/profile`;
    }
    return `/job-seekers/${ userId }/profile`;
  }, [ companyId, userId, userRole ]);

  return (
    <div className='profile-completion'>
      <div className={
        clsx('next-steps-section', {
          'complete': complete,
        })
      }
      >
        { nextStepsTitle }
        <Link
          className={
            clsx('btn btn-sm btn-rounded-sm', {
              'btn-blue': userRole === Roles.COMPANY_AGENT,
              'btn-white': userRole === Roles.JOB_SEEKER,
            })
          }
          to={ seeYourProfileLink }
        >
          { t('common:profile_completion.see_your_profile_link') }
        </Link>
      </div>
      <div
        className={
          clsx('progress dark', {
            'company-dashboard': userRole === Roles.COMPANY_AGENT,
            'complete': complete,
          })
        }
      >
        <div className='status'>
          <div
            className={
              clsx('hdg', {
                'hdg-lg': complete,
                'hdg-md': !complete,
              })
            }
          >
            { percentageTitle }
            { t('common:profile_completion.complete', { percentage }) }
          </div>
          <div className='progress-bar'>
            <div
              className='progress'
              style={
                {
                  width: `${ percentage }%`,
                }
              }
            />
          </div>
        </div>
        { renderedIncompleteSteps }
      </div>
      { renderedRoadmap }
    </div>
  );
});

ProfileCompletion.propTypes = {
  // The companyId that the user belongs to
  companyId: PropTypes.string,
  // part completions of the user profile
  partCompletions: PropTypes.arrayOf(
    // one of the part completions of each role either COMPANY_AGENT or JOB_SEEKER
    PropTypes.oneOfType([
      // JOB_SEEKER part completion object
      PropTypes.shape({
        // whether the part is complete
        complete: PropTypes.bool,
        // The completion part
        part: PropTypes.oneOf(jobSeekersParts),
      }),
      // Company agent part completion object
      PropTypes.shape({
        // whether the part is complete
        complete: PropTypes.bool,
        // The completion part
        part: PropTypes.oneOf(companiesParts),
      }),
    ]),
  ).isRequired,
  // percentage of completion of user profile
  percentage: PropTypes.number.isRequired,
  // The signed-in user id
  userId: PropTypes.string.isRequired,
  // The role of the signed-in user
  userRole: PropTypes.oneOf(roles).isRequired,
};

ProfileCompletion.defaultProps = {
  companyId: '',
};

ProfileCompletion.displayName = 'ProfileCompletion';

export default ProfileCompletion;
