/**
 * Resource method to show pricing interest.
 *
 * @module resources/companies/pricing-interest
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/pricing-interest~Params
 * @type {object}
 * @property {('FREE' | 'BASIC' | 'ENTERPRISE')} pricingPlan - The pricing plan that the company agent is interested in.
 * @property {('STARTER' | 'ADVANCE' | 'BUSINESS')} [basicPricingPlan] - The basic pricing plan that the
 * company agent is interested in.
 */

/**
 * Declares the pricing interest that a company has.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/pricing-interest~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const showPricingPlansInterest = (params) => {
  return axios.request({
    data: params,
    method: 'post',
    url: '/companies/pricing/interest',
  }).then((response) => {
    return response.data;
  });
};

export default showPricingPlansInterest;
