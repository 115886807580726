import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SkillsAndTraitsAnimationIcon from 'animations/skill.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './learn-more-discover-traits-section.scss';

const LearnMoreDiscoverTraitsSection = memo(() => {
  const { t } = useTranslation();

  return (
    <div className='learn-more-discover-traits-section'>
      <div className='learn-more-discover-traits-section__content'>
        <h2 className='hdg hdg-xxl learn-more-discover-traits-section__title'>
          { t('job_seekers:learn_more.discover_traits_section.title') }
        </h2>
        <p className='txt txt-md learn-more-discover-traits-section__description'>
          { t('job_seekers:learn_more.discover_traits_section.description') }
        </p>
        <Link
          className='btn btn-sm btn-rounded-sm btn-white learn-more-discover-traits-section__button'
          to='/job-seekers/take-assessment'
        >
          { t('job_seekers:learn_more.discover_traits_section.button_label') }
        </Link>
      </div>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-discover-traits-section__animated-icon'
        animationData={ SkillsAndTraitsAnimationIcon }
        className='learn-more-discover-traits-section__animation-container'
      />
    </div>
  );
});

LearnMoreDiscoverTraitsSection.displayName = 'LearnMoreDiscoverTraitsSection';

LearnMoreDiscoverTraitsSection.propTypes = {};

LearnMoreDiscoverTraitsSection.defaultProps = {};

export default LearnMoreDiscoverTraitsSection;
