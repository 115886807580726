/**
 * Duck for professions.
 *
 * @module ducks/professions
 */

/**
 * The type of actions to describe that the professions were set.
 *
 * @constant {string}
 * @default
 * @static
 */
const PROFESSIONS_SET = 'PROFESSIONS_SET';

/**
 * Creates an action to set the professions to the given professions.
 *
 * @param {module:types/professions~Profession[]} professions - The professions.
 * @returns {object} - The action.
 * @static
 */
const setProfessions = (professions) => {
  return {
    professions,
    type: PROFESSIONS_SET,
  };
};

/**
 * Professions state.
 *
 * @typedef module:ducks/professions~State
 * @type {object}
 * @property {module:types/professions~Profession[]} professions - The different professions.
 */

/**
 * The initial professions state.
 *
 * @type {module:ducks/professions~State}
 * @static
 */
const initialState = {
  professions: [],
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/professions~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/professions~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case PROFESSIONS_SET:
    return {
      ...state,
      professions: action.professions,
    };
  default:
    return state;
  }
};

const actions = {
  setProfessions,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
