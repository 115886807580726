/**
 * English translations for header.
 *
 * @module languages/en/header
 */
const header = {
  about_us_link_text: 'About us',
  find_talent_menu: {
    items: {
      item1: 'More info',
      item2: 'Endorsers',
      item3: 'Social Responsibility',
      item4: 'Search talent',
    },
    title: 'Find talent',
  },
  find_work_menu: {
    items: {
      item1: 'More info',
      item2: 'Take assessment',
      item3: 'Search companies',
    },
    title: 'Find work',
  },
  profile_menu: {
    items: {
      item1: 'Profile',
      item2: 'Settings',
      item3: 'Sign out',
    },
  },
  sign_in_button_label: 'Sign in',
  sign_up_button_label: 'Sign up',
};

export default header;
