import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import communicationCooperationSkillsLevels from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import './endorsement-feedback-card.scss';

const EndorsementFeedbackCard = memo((props) => {
  const { endorsement, allHashtags, onEndorsementFeedbackCardClick, endorsementIndex } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The endorser's name displayed
  const endorsersNameDisplayed = useMemo(() => {
    return `${ endorsement?.endorserProfile?.firstName || '' } ${ endorsement?.endorserProfile?.lastName || '' }`;
  }, [ endorsement?.endorserProfile?.firstName, endorsement?.endorserProfile?.lastName ]);

  // The testimonials section
  const testimonialsSection = useMemo(() => {
    if (!endorsement?.testimonial) {
      return null;
    }
    return (
      <div className='endorsement-feedback-card__testimonial-section'>
        <p className='txt txt-sm'>
          { t('job_seekers:profile_endorsers_feedback.card.testimonial_title') }
        </p>
        <p className='txt txt-sm txt-bold endorsement-feedback-card__testimonial'>
          { `"${ endorsement?.testimonial }"` }
        </p>
      </div>
    );
  }, [ endorsement?.testimonial, t ]);

  // The rendered personality qualities
  const renderedPersonalityQualities = useMemo(() => {
    return endorsement?.hashtags?.map((hashtagId) => {
      const foundHashtag = allHashtags?.find((hashtag) => {
        return hashtag.id === hashtagId;
      })?.[selectedLanguage];
      return (
        <span
          className='txt txt-sm txt-bold'
          key={ hashtagId }
        >
          { `#${ foundHashtag }` }
        </span>
      );
    });
  }, [ allHashtags, endorsement?.hashtags, selectedLanguage ]);

  // The personality qualities section
  const personalityQualitiesSection = useMemo(() => {
    if (!endorsement?.hashtags?.length) {
      return null;
    }
    return (
      <div className='endorsement-feedback-card__testimonial-section'>
        <p className='txt txt-sm'>
          { t('job_seekers:profile_endorsers_feedback.card.personality_qualities_title') }
        </p>
        { renderedPersonalityQualities }
      </div>
    );
  }, [ endorsement?.hashtags?.length, renderedPersonalityQualities, t ]);

  // function that handles the card click
  const handleEndorsementFeedbackCardClick = useCallback(() => {
    onEndorsementFeedbackCardClick(endorsementIndex);
  }, [ endorsementIndex, onEndorsementFeedbackCardClick ]);

  return (
    <div
      className='endorsement-feedback-card'
      onClick={ handleEndorsementFeedbackCardClick }
      role='presentation'
    >
      <p className='endorsement-feedback-card__name txt txt-md txt-bold'>
        { endorsersNameDisplayed }
      </p>
      { testimonialsSection }
      { personalityQualitiesSection }
    </div>
  );
});

EndorsementFeedbackCard.displayName = 'EndorsementFeedbackCard';

EndorsementFeedbackCard.propTypes = {
  // All the hashtags available
  allHashtags: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of hashtag
      el: PropTypes.string,
      // english translation of hashtag
      en: PropTypes.string,
      // the id of the hashtag
      id: PropTypes.string,
    })
  ),
  // The endorsement
  endorsement: PropTypes.shape({
    // The access level
    accessLevel: PropTypes.string,
    // The communication and cooperation skills level score
    communicationCooperationScore: PropTypes.number,
    // The communication and cooperation skills level
    communicationCooperationSkillsLevel: PropTypes.oneOf(communicationCooperationSkillsLevels),
    // The critical thinking level
    criticalThinkingLevel: PropTypes.oneOf(criticalThinkingLevels),
    // The critical thinking score
    criticalThinkingScore: PropTypes.number,
    // The employer feedback
    employerFeedback: PropTypes.string,
    // The endorser id
    endorser: PropTypes.string,
    // The endorser profile
    endorserProfile: PropTypes.shape({
      // The company name
      companyName: PropTypes.string,
      // The company position
      companyPosition: PropTypes.string,
      // The first name
      firstName: PropTypes.string,
      // the last name
      lastName: PropTypes.string,
    }),
    // The hashtags UUID array
    hashtags: PropTypes.arrayOf(PropTypes.string),
    // The job seeker feedbacks array
    jobSeekerFeedbacks: PropTypes.arrayOf(PropTypes.oneOf(jobSeekerFeedbackLevels)),
    // The motivation level
    motivationLevel: PropTypes.oneOf(motivationalLevels),
    // The motivation score
    motivationScore: PropTypes.number,
    // The professional characteristics array
    professionalCharacteristics: PropTypes.arrayOf(PropTypes.oneOf(professionalCharacteristicLevels)),
    // The profesionalism score
    professionalismScore: PropTypes.number,
    // The request id
    requestId: PropTypes.string,
    // The testimonial
    testimonial: PropTypes.string,
  }),
  // The endorsementIndex
  endorsementIndex: PropTypes.number,
  // The function (endorsementIndex) => void to be invoked when a card is clicked
  onEndorsementFeedbackCardClick: PropTypes.func,
};

EndorsementFeedbackCard.defaultProps = {};

export default EndorsementFeedbackCard;
