/**
 * Job seekers - Learn more page.
 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import ArrowCardIcon from 'components/common/icons/arrow-card-icon/arrow-card-icon';
import BadgeCardIcon from 'components/common/icons/badge-card-icon/badge-card-icon';
import FlagsCardIcon from 'components/common/icons/flags-card-icon/flags-card-icon';
import LampCardIcon from 'components/common/icons/lamp-card-icon/lamp-card-icon';
import MeterCardIcon from 'components/common/icons/meter-card-icon/meter-card-icon';
import PhotoCardIcon from 'components/common/icons/photo-card-icon/photo-card-icon';
import TraitsCardIcon from 'components/common/icons/traits-card-icon/traits-card-icon';
import PublicFAQSection from 'components/common/public-faq-section/public-faq-section';
import PublicLearnMoreSignInSection
  from 'components/common/public-learn-more-sign-in-section/public-learn-more-sign-in-section';
import PublicStatisticsSection from 'components/common/public-statistics-section/public-statistics-section';
import PublicTestimonialsSection from 'components/common/public-testimonials-section/public-testimonials-section';
import FirstTestimonialImage from 'images/testimonials/first.jpeg';
import SecondTestimonialImage from 'images/testimonials/second.jpeg';
import ThirdTestimonialImage from 'images/testimonials/third.jpeg';
import * as Roles from 'utilities/auth/roles';

import LearnMoreCreateCVSection from './learn-more-create-CV-section/learn-more-create-CV-section';
import LearnMoreDifferentSection from './learn-more-different-section/learn-more-different-section';
import LearnMoreDiscoverTraitsSection from './learn-more-discover-traits-section/learn-more-discover-traits-section';
import LearnMoreProfileFeaturesSection from './learn-more-profile-features-section/learn-more-profile-features-section';
import LearnMoreRequestEndorsementSection
  from './learn-more-request-endorsement-section/learn-more-request-endorsement-section';

import './learn-more.scss';

const LearnMore = () => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The metrics state object
  const metrics = useSelector((store) => {
    return store.metrics;
  }, shallowEqual);

  // the talent platform metrics
  const talentPlatformMetrics = useMemo(() => {
    return metrics?.talentPlatformMetrics;
  }, [ metrics?.talentPlatformMetrics ]);

  // Whether the user is authenticated
  const isAuthenticated = useMemo(() => {
    return auth?.id !== undefined;
  }, [ auth?.id ]);

  const { t } = useTranslation();

  // The rendered sign in section
  const renderedSignInSection = useMemo(() => {
    if (isAuthenticated) {
      return null;
    }
    return (
      <PublicLearnMoreSignInSection
        role={ Roles.JOB_SEEKER }
        title={ t('job_seekers:learn_more.sign_in_section.title') }
      />
    );
  }, [ isAuthenticated, t ]);

  // The job seekers learn more rendered profile features card collection
  const profileFeaturesCardCollection = useMemo( () => {
    return [
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item1.description'),
        icon: <BadgeCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item1.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item2.description'),
        icon: <MeterCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item2.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item3.description'),
        icon: <PhotoCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item3.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item4.description'),
        icon: <ArrowCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item4.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item5.description'),
        icon: <LampCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item5.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item6.description'),
        icon: <FlagsCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item6.title'),
      },
      {
        description: t('job_seekers:learn_more.profile_features_section.cards.item7.description'),
        icon: <TraitsCardIcon />,
        title: t('job_seekers:learn_more.profile_features_section.cards.item7.title'),
      },
    ];
  }, [ t ]);

  // the statistics array
  const statistics = useMemo(() => {
    return [
      {
        number: talentPlatformMetrics?.peopleHired,
        title: t('job_seekers:learn_more.statistics_section.statistics.item1.title'),
      },
      {
        number: talentPlatformMetrics?.profiles,
        title: t('job_seekers:learn_more.statistics_section.statistics.item2.title'),
      },
      {
        number: talentPlatformMetrics?.companies,
        title: t('job_seekers:learn_more.statistics_section.statistics.item3.title'),
      },
    ];
  }, [
    t,
    talentPlatformMetrics?.companies,
    talentPlatformMetrics?.peopleHired,
    talentPlatformMetrics?.profiles,
  ]);

  // The faqs array
  const faqs = useMemo(() => {
    return [
      {
        answer: t('job_seekers:learn_more.faq_section.faqs.item1.answer'),
        question: t('job_seekers:learn_more.faq_section.faqs.item1.question'),
      },
      {
        answer: t('job_seekers:learn_more.faq_section.faqs.item2.answer'),
        question: t('job_seekers:learn_more.faq_section.faqs.item2.question'),
      },
      {
        answer: t('job_seekers:learn_more.faq_section.faqs.item3.answer'),
        question: t('job_seekers:learn_more.faq_section.faqs.item3.question'),
      },
      {
        answer: t('job_seekers:learn_more.faq_section.faqs.item4.answer'),
        question: t('job_seekers:learn_more.faq_section.faqs.item4.question'),
      },
    ];
  }, [ t ]);

  // The testimonials collection
  const testimonialsCollection = useMemo(() => {
    return [
      [
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item1.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item1.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item1.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item1.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item2.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item2.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item2.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item2.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item3.age_location'),
          description: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item3.description'),
          imageUrl: FirstTestimonialImage,
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item3.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item1.item3.title'),
        },
      ], [
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item1.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item1.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item1.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item1.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item2.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item2.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item2.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item2.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item3.age_location'),
          description: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item3.description'),
          imageUrl: SecondTestimonialImage,
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item3.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item2.item3.title'),
        },
      ], [
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item1.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item1.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item1.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item1.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item2.age_location'),
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item2.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item2.title'),
          videoUrl: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item2.video_url'),
        },
        {
          ageLocation: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item3.age_location'),
          description: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item3.description'),
          imageUrl: ThirdTestimonialImage,
          name: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item3.name'),
          title: t('job_seekers:learn_more.testimonials_section.testimonials.item3.item3.title'),
        },
      ],
    ];
  }, [ t ]);

  return (
    <div className='ody-job-seekers-learn-more'>
      { renderedSignInSection }
      <LearnMoreDifferentSection />
      <LearnMoreProfileFeaturesSection
        cardsCollection={ profileFeaturesCardCollection }
      />
      <LearnMoreCreateCVSection />
      <PublicStatisticsSection
        isYellow
        statistics={ statistics }
      />
      <LearnMoreRequestEndorsementSection />
      <LearnMoreDiscoverTraitsSection />
      <PublicTestimonialsSection
        testimonials={ testimonialsCollection }
        title={ t('job_seekers:learn_more.testimonials_section.title') }
      />
      <PublicFAQSection
        faqs={ faqs }
        title={ t('job_seekers:learn_more.faq_section.title') }
      />
    </div>
  );
};

LearnMore.propTypes = {
};

LearnMore.defaultProps = {
};

export default LearnMore;
