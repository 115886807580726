import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import './about-us-milestones-section.scss';

const AboutUsMilestonesSection = memo((props) => {
  const { title, milestones } = props;

  const { i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // function that displays the 3 first chars of the month
  const renderedDisplayedMonth = useCallback((month) => {
    return month.slice(0, 3);
  }, []);

  // Whether to render the quarter
  const renderedQuarter = useCallback((milestone, milestoneIndex) => {
    if (milestone?.quarter === undefined) {
      return null;
    }
    return (
      <div
        className={
          clsx('about-us-milestones-section__quarter', {
            'about-us-milestones-section__quarter--down': 0 !== milestoneIndex % 2,
            'about-us-milestones-section__quarter--greek': 'el' === selectedLanguage || 'gr' === selectedLanguage,
            'about-us-milestones-section__quarter--up': 0 === milestoneIndex % 2,
          })
        }
      >
        <h3 className='hdg hdg-lg about-us-milestones-section__quarter-title'>
          { milestone.quarter.title }
        </h3>
        <p className='txt txt-md about-us-milestones-section__quarter-description'>
          { milestone.quarter.description }
        </p>
      </div>
    );
  }, [ selectedLanguage ]);

  // The rendered months
  const renderedMonths = useMemo(() => {
    return milestones.map((milestone, milestoneIndex) => {
      return (
        <div
          className={
            clsx('about-us-milestones-section__month', {
              'about-us-milestones-section__month--has-quarter': milestone?.quarter !== undefined,
            })
          }
          key={ milestone.month + milestoneIndex }
        >
          <span
            className={
              clsx('about-us-milestones-section__displayed-month', {
                'about-us-milestones-section__displayed-month--has-quarter': milestone?.quarter !== undefined,
              })
            }
          >
            { renderedDisplayedMonth(milestone.month) }
          </span>
          { renderedQuarter(milestone, milestoneIndex) }
        </div>
      );
    });
  }, [ milestones, renderedDisplayedMonth, renderedQuarter ]);

  return (
    <div className='about-us-milestones-section'>
      <h3 className='hdg hdg-lg about-us-milestones-section__title'>
        { title }
      </h3>
      <div className='about-us-milestones-section__months-container'>
        { renderedMonths }
      </div>
    </div>
  );
});

AboutUsMilestonesSection.displayName = 'AboutUsMilestonesSection';

AboutUsMilestonesSection.propTypes = {
  // The milestones
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      // The month
      month: PropTypes.string,
      // The quarter
      quarter: PropTypes.shape({
        // The description of the quarter
        description: PropTypes.string,
        // The title of the quarter
        title: PropTypes.string,
      }),
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsMilestonesSection.defaultProps = {};

export default AboutUsMilestonesSection;
