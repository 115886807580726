import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import Email from 'images/email.png';
import LinkedIn from 'images/linkedin-black.png';
import Man from 'images/man.png';
import Position from 'images/position.png';
import Tel from 'images/tel.png';
import accessLevels from 'utilities/access-levels';
import roles, * as Roles from 'utilities/auth/roles';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileContactDetailsEditor from './profile-contact-details-editor/profile-contact-details-editor';

import './profile-contact-details.scss';

const ProfileContactDetails = memo((props) => {
  const { profile, onChange, canEdit, role } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The company agent name
  const companyAgentName = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT || (!profile?.firstName && !profile?.lastName)) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company agent name'
        icon={ Man }
        textDisplayed={ `${ profile.firstName } ${ profile.lastName }` }
      />
    );
  }, [ profile?.firstName, profile?.lastName, role ]);

  // The company agent position
  const companyPositionSection = useMemo(() => {
    if (role === Roles.JOB_SEEKER || !profile?.companyPosition) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company position'
        icon={ Position }
        textDisplayed={ profile.companyPosition }
      />
    );
  }, [ profile?.companyPosition, role ]);

  // The email
  const emailSection = useMemo(() => {
    if (!profile?.email) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='email'
        href={ `mailto:${ profile.email }` }
        icon={ Email }
        textDisplayed={ profile.email }
      />
    );
  }, [ profile?.email ]);

  // The phone number section
  const phoneNumberSection = useMemo(() => {
    if (!profile?.phoneNumber || role === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='phone Number'
        href={ `tel:${ profile.phoneNumber }` }
        icon={ Tel }
        textDisplayed={ profile?.phoneNumber || '' }
      />
    );
  }, [ profile?.phoneNumber, role ]);

  // the job seeker phone numbers section
  const phoneNumbersSection = useMemo(() => {
    if (role !== Roles.JOB_SEEKER || !profile?.phoneNumbers?.length) {
      return null;
    }
    return profile.phoneNumbers?.map((phoneNumber) => {
      return (
        <ProfileInfoRow
          alt='phone Number'
          href={ `tel:${ phoneNumber }` }
          icon={ Tel }
          key={ phoneNumber }
          textDisplayed={ phoneNumber }
        />
      );
    });
  }, [ profile?.phoneNumbers, role ]);

  // the linked in url
  const linkedInProfileUrlSection = useMemo(() => {
    if (role !== Roles.JOB_SEEKER || !profile?.linkedinProfileUrl) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='linkedIn url'
        href={ profile.linkedinProfileUrl }
        icon={ LinkedIn }
        textDisplayed={ t('common:profile_contact_details.linkedin_profile') }
      />
    );
  }, [ profile?.linkedinProfileUrl, role, t ]);

  // renders company position email and phone number based on the role
  const renderedPartContactDetails = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return (
        <>
          { companyPositionSection }
          { emailSection }
          { phoneNumberSection }
        </>
      );
    }
    return (
      <>
        { phoneNumberSection }
        { emailSection }
        { companyPositionSection }
      </>
    );
  }, [ companyPositionSection, emailSection, phoneNumberSection, role ]);

  // function that handles when profile contact details are changed
  const onProfileContactDetailsChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, JobSeekerParts.CONTACT, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileContactDetailsEditor
          onCancel={ toggleEditor(false) }
          onSave={ onProfileContactDetailsChange }
          profile={ profile }
          role={ role }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onProfileContactDetailsChange, profile, role, toggleEditor ]);

  return (
    <div className='profile-contact-details'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('common:profile_contact_details:title') }
      />
      <div className='profile-contact-details__fields'>
        { companyAgentName }
        { phoneNumbersSection }
        { renderedPartContactDetails }
        { linkedInProfileUrlSection }
      </div>
      { editorModal }
    </div>
  );
});

ProfileContactDetails.displayName = 'ProfileContactDetails';

ProfileContactDetails.propTypes = {
  // Whether the user can edit the details section
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func.isRequired,
  // the user's profile
  profile: PropTypes.oneOfType([
    //job seeker profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The email address of the job seeker.
      email: PropTypes.string,
      // The URL to the LinkedIn profile of the job seeker.
      linkedinProfileUrl: PropTypes.string,
      // The phone number(s) of the job seeker.
      phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    }),
    // company profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The position of the company agent
      companyPosition: PropTypes.string,
      // The email of the company agent
      email: PropTypes.string,
      // The firstname of the company agent
      firstName: PropTypes.string,
      // The lastname of the company agent
      lastName: PropTypes.string,
      // The phone number of the company agent.
      phoneNumber: PropTypes.string,
    }),
  ]),
  // the role of the profile based on route
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileContactDetails.defaulProps = {};

export default ProfileContactDetails;
