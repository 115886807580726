import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import TakeAssessmentCard from './take-assesment-card/take-assesment-card';

import './take-assessment-cards-section.scss';

const TakeAssessmentCardsSection = memo((props) => {
  const { cards } = props;

  // The rendered cards
  const renderedCards = useMemo(() => {
    return cards.map((card, index) => {
      return (
        <TakeAssessmentCard
          description={ card.description }
          icon={ card?.icon }
          isLastCard={ index === cards.length - 1 }
          key={ card.description }
          title={ card?.title }
        />
      );
    });
  }, [ cards ]);

  return (
    <div className='take-assessment-cards-section'>
      { renderedCards }
    </div>
  );
});

TakeAssessmentCardsSection.displayName = 'TakeAssessmentCardsSection';

TakeAssessmentCardsSection.propTypes = {
  // The array of the cards
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      // The description
      description: PropTypes.string.isRequired,
      // The icon
      icon: PropTypes.node,
      // The title
      title: PropTypes.string,
    })
  ).isRequired,
};

TakeAssessmentCardsSection.defaultProps = {};

export default TakeAssessmentCardsSection;
