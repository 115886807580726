/**
 * Greek translations for the utilities.
 *
 * @module languages/el/utilities
 */
import cities from './cities';
import citizenships from './citizenships';
import companies from './companies';
import computerSkillsLevels from './computer-skills-levels';
import countries from './countries';
import criterionTypes from './criterion-types';
import drivingLicenseCategories from './driving-license-categories';
import endorsers from './endorsers';
import genders from './genders';
import jobSeekers from './job-seekers';
import languageLevels from './language-levels';
import languages from './languages';
import numberOfEmployees from './number-of-employees';
import pricingPlans from './pricing-plans';
import qualificationTypes from './qualification-types';
import workAvailabilities from './work-availabilities';

const utilities = {
  cities,
  citizenships,
  companies,
  computer_skills_levels: computerSkillsLevels,
  countries,
  criterion_types: criterionTypes,
  driving_license_categories: drivingLicenseCategories,
  endorsers,
  genders,
  job_seekers: jobSeekers,
  language_levels: languageLevels,
  languages,
  number_of_employees: numberOfEmployees,
  pricing_plans: pricingPlans,
  qualification_types: qualificationTypes,
  work_availabilities: workAvailabilities,
};

export default utilities;
