/**
 * Search Pages
 */
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Paginator from 'components/common/paginator/paginator';
import constants from 'config/constants';
import { useMd } from 'hooks/media-queries';
import { ceil } from 'utilities/chisels';

const SearchPages = memo((props) => {
  const { page, total, onChange } = props;

  const isMd = useMd();

  const maxPages = useMemo(() => {
    return isMd ? 3 : 5;
  }, [ isMd ]);

  return (
    <div className='pages dark'>
      <Paginator
        currentPage={ page }
        maxPages={ maxPages }
        numberOfPages={ ceil(total / constants.PAGE_SIZE) }
        onChange={ onChange }
      />
    </div>
  );
});

SearchPages.displayName = 'SearchPages';

SearchPages.propTypes = {
  // The function ((number) => void) to invoke when the current page changes.
  onChange: PropTypes.func.isRequired,
  // The current page
  page: PropTypes.number.isRequired,
  // The total records
  total: PropTypes.number.isRequired,
};

SearchPages.defaultProps = {
  page: 0,
  total: 0,
};

export default SearchPages;
