/**
 * English translations for job-seekers/profile-languages.
 *
 * @module languages/en/job-seekers/profile-languages
 */
const profileLanguages = {
  editor_title: 'Languages',
  english_level_placeholder: 'Select your English level',
  greek_level_placeholder: 'Select your Greek level',
  other: 'Other',
  other_languages_label: 'Other',
  other_languages_placeholder: 'Select other languages you speak',
  title: 'Languages',
};

export default profileLanguages;
