/**
 * Sign-in page.
 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import SignInForm from 'components/sign-in-form/sign-in-form';
import * as Roles from 'utilities/auth/roles';

import './sign-in.scss';

const SignIn = (_props) => {
  // The auth reducer
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  const { t } = useTranslation();

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <div className='ody-sign-in'>
        <div className='ody-sign-in__form-wrapper'>
          <h1 className='ody-sign-in__form-title hdg hdg-xl'>
            { t('sign_in:title') }
          </h1>
          <SignInForm
            role={ Roles.JOB_SEEKER }
          />
        </div>
        <div className='ody-sign-in__logo'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [ t, userAuthenticated ]);
};

SignIn.propTypes = {
};

SignIn.defaultProps = {
};

export default SignIn;
