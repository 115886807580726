/**
 * Store.
 *
 * @module store
 */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import thunkMiddleware from 'redux-thunk';

import * as administrators from 'ducks/administrators';
import * as auth from 'ducks/auth';
import * as companies from 'ducks/companies';
import * as endorsers from 'ducks/endorsers';
import * as hashtags from 'ducks/hashtags';
import * as jobSectors from 'ducks/job-sectors';
import * as jobSeekers from 'ducks/job-seekers';
import * as metrics from 'ducks/metrics';
import * as professions from 'ducks/professions';
import * as profiles from 'ducks/profiles';
import * as requests from 'ducks/requests';
import * as settings from 'ducks/settings';
import * as skills from 'ducks/skills';
import * as toasts from 'ducks/toasts';
import { middleware as profilesMiddleware } from 'middlewares/profiles';
import { middleware as requestsMiddleware } from 'middlewares/requests';

/**
 * The root reducer.
 *
 * @type {function}
 * @static
 */
const reducer = combineReducers({
  // Administrators
  administrators: administrators.reducer,
  // Authn/authz.
  auth: auth.reducer,
  // Companies.
  companies: companies.reducer,
  // Endorsers
  endorsers: endorsers.reducer,
  // Hashtags
  hashtags: hashtags.reducer,
  // Job sectors.
  jobSectors: jobSectors.reducer,
  // Job seekers.
  jobSeekers: jobSeekers.reducer,
  // The metrics
  metrics: metrics.reducer,
  // Professions.
  professions: professions.reducer,
  // Profiles.
  profiles: profiles.reducer,
  // Requests.
  requests: requests.reducer,
  // Settings.
  settings: settings.reducer,
  // Skills.
  skills: skills.reducer,
  // Toasts.
  toasts: toasts.reducer,
});

/**
 * The redux state sync config
 *
 * @type {object}
 * @static
 */
const reduxStateSyncConfig = {
  // Only 'AUTH_RESET' and 'USER_SIGNED_OUT' will be triggered in other tabs
  whitelist: [ 'AUTH_RESET', 'USER_SIGNED_OUT' ],
};

/**
 * the redux state sync middleware
 *
 * @type {function}
 * @static
 */
const reduxStateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig);

/**
 * The middlewares to apply.
 *
 * @type {function[]}
 * @static
 */
const middlewares = [
  // Thunks.
  thunkMiddleware,
  // Requests.
  requestsMiddleware,
  // Profiles.
  profilesMiddleware,
  // The redux state sync middleware
  reduxStateSyncMiddleware,
];

/**
 * The enhancer.
 *
 * @type {function}
 * @static
 */
const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

/**
 * The store.
 *
 * @type {object}
 * @static
 */
const store = createStore(persistReducer({
  key: 'odyssea',
  storage,
  whitelist: [
    'auth',
    'settings',
  ],
}, reducer), enhancer);

// init state with other tabs from redux state sync
initStateWithPrevTab(store);

const persistor = persistStore(store);

export {
  persistor,
  store,
};
