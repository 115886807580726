import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import Confirm from 'components/common/confirm/confirm';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import Location from 'images/location.png';
import { isEmpty, isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import endorsementStatuses, * as EndorsementStatuses from 'utilities/endorsement-status';

import './dashboard-endorsement-card.scss';

const DashboardEndorsementCard = memo((props) => {
  const {
    status,
    endorsementRequest,
    claimEndorsementRequest,
    handleReviewClaimedEndorsement,
    revokeClaimedEndorsementRequest,
  } = props;

  const { t } = useTranslation();

  // The endorsee profile
  const endorseeProfile = useMemo(() => {
    return endorsementRequest?.endorseeProfile;
  }, [ endorsementRequest?.endorseeProfile ]);

  // The endorsement id
  const endorsementId = useMemo(() => {
    return endorsementRequest?.id;
  }, [ endorsementRequest?.id ]);

  // The endorsee profile picture
  const userProfilePicture = useMemo(() => {
    if (undefined === endorseeProfile?.profilePicture) {
      return (
        <CircularImage
          alt='Default image'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='endorsee image'
        imageSource={
          `${ env.CORE_BASE_URL }/job-seekers/${ endorseeProfile?.id }
          /profile/picture?id=${ endorseeProfile?.profilePicture?.id }`
        }
      />
    );
  }, [ endorseeProfile?.id, endorseeProfile?.profilePicture ]);

  // The location display string of the endorsee location
  const locationDisplayString = useMemo(() => {
    let city = '';
    if (endorseeProfile?.location?.city) {
      city = t(`utilities:cities.${ endorseeProfile?.location?.city }`);
    }
    return [
      endorseeProfile?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ endorseeProfile?.location?.city, endorseeProfile?.location?.district, t ]);

  // The location section
  const locationSection = useMemo(() => {
    if (isEmpty(endorseeProfile?.location)) {
      return null;
    }
    return (
      <div className='dashboard-endorsement-card__location-container'>
        <img alt='endorsee location' className='dashboard-endorsement-card__location-icon' src={ Location } />
        <span className='txt txt-sm'>
          { locationDisplayString }
        </span>
      </div>
    );
  }, [ endorseeProfile?.location, locationDisplayString ]);

  // The about section
  const aboutSection = useMemo(() => {
    if (!endorseeProfile?.shortBio) {
      return null;
    }
    return (
      <div className='dashboard-endorsement-card__about-section'>
        <p className='txt txt-md txt-bold'>
          { t('endorsers:endorsement_card.about_title') }
        </p>
        <p className='dashboard-endorsement-card__about-section-value txt txt-sm'>
          { endorseeProfile?.shortBio }
        </p>
      </div>
    );
  }, [ endorseeProfile?.shortBio, t ]);

  // button title
  const buttonTitle = useMemo(() => {
    if (status === EndorsementStatuses.CLAIMED) {
      return t('endorsers:endorsement_card.claimed_button_title');
    }
    return t('endorsers:endorsement_card.created_button_title');
  }, [ status, t ]);

  // the function to be invoked when the confirm approve is clicked
  const handleConfirmAccept = useCallback(() => {
    claimEndorsementRequest(endorsementId);
  }, [ claimEndorsementRequest, endorsementId ]);

  // The function to be invoked when the confirm revoke is clicked
  const handleConfirmRevoke = useCallback(() => {
    revokeClaimedEndorsementRequest(endorsementId);
  }, [ endorsementId, revokeClaimedEndorsementRequest ]);

  // The function to be invoked when reviewing an endorsement
  const onReviewClaimedEndorsement = useCallback((endorsement) => {
    return (_event) => {
      handleReviewClaimedEndorsement(endorsement);
    };
  }, [ handleReviewClaimedEndorsement ]);

  // The rendered button
  const renderedButton = useMemo(() => {
    if (status === EndorsementStatuses.CREATED) {
      return (
        <Confirm
          className='dashboard-endorsement-card__confirm-button'
          onConfirm={ handleConfirmAccept }
          text={
            t(
              'endorsers:endorsement_card.created_confirmation_message',
              { jobSeekerName: `${ endorseeProfile?.firstName } ${ endorseeProfile?.lastName }` }
            )
          }
          trigger={
            <button
              className='dashboard-endorsement-card__confirm-button btn btn-sm btn-rounded-sm btn-blue'
            >
              { buttonTitle }
            </button>
          }
        />
      );
    }
    return (
      <Link
        className='dashboard-endorsement-card__review-link btn btn-sm btn-rounded-sm btn-blue'
        onClick={ onReviewClaimedEndorsement(endorsementRequest) }
        to={ `/endorsers/${ endorsementId }/endorsement` }
      >
        { buttonTitle }
      </Link>
    );
  }, [
    buttonTitle,
    endorseeProfile?.firstName,
    endorseeProfile?.lastName,
    endorsementId,
    endorsementRequest,
    handleConfirmAccept,
    onReviewClaimedEndorsement,
    status,
    t,
  ]);

  // The rendered revoke button
  const renderedRevokeButton = useMemo(() => {
    if (status !== EndorsementStatuses.CLAIMED) {
      return null;
    }
    return (
      <Confirm
        className='dashboard-endorsement-card__confirm-button'
        onConfirm={ handleConfirmRevoke }
        text={
          t(
            'endorsers:endorsement_card.revoke_confirmation_message',
            { jobSeekerName: `${ endorseeProfile?.firstName } ${ endorseeProfile?.lastName }` }
          )
        }
        trigger={
          <button
            className='dashboard-endorsement-card__confirm-button btn btn-sm btn-rounded-sm btn-blue'
          >
            { t('endorsers:endorsement_card.revoke_button_title') }
          </button>
        }
      />
    );
  }, [ endorseeProfile?.firstName, endorseeProfile?.lastName, handleConfirmRevoke, status, t ]);

  return (
    <div className='dashboard-endorsement-card'>
      <div className='dashboard-endorsement-card__top-section'>
        <div className='dashboard-endorsement-card__header'>
          <div className={
            clsx('dashboard-endorsement-card__header-picture-container', {
              'no-picture': undefined === endorseeProfile?.profilePicture,
            })
          }
          >
            { userProfilePicture }
          </div>
          <div className='dashboard-endorsement-card__name-container dark'>
            <p className='txt txt-md txt-bold'>
              { `${ endorseeProfile?.firstName },` }
            </p>
            <p className='txt txt-md txt-bold'>
              { endorseeProfile?.lastName }
            </p>
          </div>
        </div>
        { locationSection }
        { aboutSection }
      </div>
      <div className='dashboard-endorsement-card__bottom-section'>
        <Link
          className='link txt txt-sm txt-bold'
          to={ `/job-seekers/${ endorseeProfile?.id }/profile` }
        >
          { t('endorsers:endorsement_card.see_profile_link') }
        </Link>
        { renderedButton }
        { renderedRevokeButton }
      </div>
    </div>
  );
});

DashboardEndorsementCard.displayName = 'EndorsementCard';

DashboardEndorsementCard.propTypes = {
  // The function (endorsementId) =>> void to claim a CREATED endorsement request
  claimEndorsementRequest: PropTypes.func,
  // The endorsement request
  endorsementRequest: PropTypes.shape({
    // The profile of the endorsee
    endorseeProfile: PropTypes.shape({
      // The first name of the endorsee
      firstName: PropTypes.string,
      // The id of the endorsee
      id: PropTypes.string,
      // The last name of the endorsee
      lastName: PropTypes.string,
      // The location where the endorsee is.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The profile picture of the endorsee.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string,
        // The MIME type of the picture.
        type: PropTypes.string,
      }),
      // The short bio of the endorsee
      shortBio: PropTypes.string,
    }),
    // the id of the endorsement
    id: PropTypes.string,
    // The status of the endorsements in the section
    status: PropTypes.oneOf(endorsementStatuses),
  }),
  // The function (endorsement) => void to be invoked when reviewing a claimed endorsement
  handleReviewClaimedEndorsement: PropTypes.func,
  // The function (endorsementId) => void to revoke a CLAIMED endorsement request
  revokeClaimedEndorsementRequest: PropTypes.func,
  // The status of the endorsement in the card
  status: PropTypes.oneOf(endorsementStatuses).isRequired,
};

DashboardEndorsementCard.defaultProps = {};

export default DashboardEndorsementCard;
