import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Carousel from 'components/common/carousel/carousel';
import useElementOnScreen from 'hooks/use-element-on-screen';

import HomeOurCompaniesSayRootCollectionItem
  from './home-our-companies-say-root-collection-item/home-our-companies-say-root-collection-item';

import './home-our-companies-say-section.scss';

const HomeOurCompaniesSaySection = memo((props) => {
  const { title, collection } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  const renderedCollectionItems = useMemo(() => {
    return collection.map((collectionItem, collectionItemIndex) => {
      return (
        <HomeOurCompaniesSayRootCollectionItem
          collectionItem={ collectionItem }
          key={ `Root-Collection-item-${ collectionItemIndex }` }
        />
      );
    });
  }, [ collection ]);

  return (
    <div
      className='home-our-companies-say-section'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl home-our-companies-say-section__title'>
        { title }
      </h2>
      <Carousel
        autoplay={ isVisible }
        infiniteLoop={ 1 < collection.length }
        stopOnHover
      >
        { renderedCollectionItems }
      </Carousel>
    </div>
  );
});

HomeOurCompaniesSaySection.displayName = 'HomeOurCompaniesSaySection';

HomeOurCompaniesSaySection.propTypes = {
  // The companies say collection
  collection: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        // the description
        description: PropTypes.string,
        // The image url
        imageUrl: PropTypes.string,
        // The name
        name: PropTypes.string,
        // The occupation
        occupation: PropTypes.string,
      })
    )
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

HomeOurCompaniesSaySection.defaultProps = {};

export default HomeOurCompaniesSaySection;
