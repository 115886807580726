import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Carousel from 'components/common/carousel/carousel';
import { useSm, useXs } from 'hooks/media-queries';
import useElementOnScreen from 'hooks/use-element-on-screen';
import roles from 'utilities/auth/roles';
import pricingPlans from 'utilities/pricing-plans';

import LearnMoreHrPackage from './learn-more-hr-package/learn-more-hr-package';

import './learn-more-hr-packages-section.scss';

const LearnMoreHrPackagesSection = memo((props) => {
  const { packagesCollection, title, handlePricingPlanClick, userAuthenticated, userRole } = props;

  const isXs = useXs();

  const isSm = useSm();

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // Whether we are on small screens
  const smallScreens = useMemo(() => {
    return isXs || isSm;
  }, [ isSm, isXs ]);

  // The rendered packages
  const renderedPackages = useMemo(() => {
    return packagesCollection.map((hrPackage) => {
      return (
        <LearnMoreHrPackage
          basicPricingPlan={ hrPackage?.basicPricingPlan ?? '' }
          buttonLabel={ hrPackage.buttonLabel }
          descriptionCollection={ hrPackage.description }
          handlePricingPlanClick={ handlePricingPlanClick }
          key={ hrPackage.title }
          pricingDescription={ hrPackage?.pricingDescription ?? '' }
          pricingPlan={ hrPackage.pricingPlan }
          title={ hrPackage.title }
          titleDescription={ hrPackage?.titleDescription ?? '' }
          userAuthenticated={ userAuthenticated }
          userRole={ userRole }
        />
      );
    });
  }, [ handlePricingPlanClick, packagesCollection, userAuthenticated, userRole ]);

  // The rendered content
  const renderedContent = useMemo(() => {
    if (smallScreens) {
      return (
        <Carousel
          autoplay={ isVisible }
          infiniteLoop
        >
          { renderedPackages }
        </Carousel>
      );
    }
    return renderedPackages;
  }, [ isVisible, renderedPackages, smallScreens ]);

  return (
    <div
      className='learn-more-hr-packages-section'
      id='pricing-plans'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl learn-more-hr-packages-section__title'>
        { title }
      </h2>
      { renderedContent }
    </div>
  );
});

LearnMoreHrPackagesSection.displayName = 'LearnMoreHrPackagesSection';

LearnMoreHrPackagesSection.propTypes = {
  // The click handler of the card
  handlePricingPlanClick: PropTypes.func.isRequired,
  // The pricing packages collection
  packagesCollection: PropTypes.arrayOf(
    PropTypes.shape({
      // The basic pricing plan
      basicPricingPlan: PropTypes.string,
      // The pricing plan button label
      buttonLabel: PropTypes.string,
      // The description collection of the package
      descriptionCollection: PropTypes.arrayOf(PropTypes.string),
      // The pricing description of the package
      pricingDescription: PropTypes.string,
      // The pricing plan
      pricingPlan: PropTypes.oneOf(pricingPlans),
      // The title of the package
      title: PropTypes.string,
      // The title description of the package
      titleDescription: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
  // The user role
  userRole: PropTypes.oneOf(roles),
};

LearnMoreHrPackagesSection.defaultProps = {};

export default LearnMoreHrPackagesSection;
