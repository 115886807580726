/**
 * Profile access levels.
 *
 * @module utilities/access-levels
 */

/**
 * Access level 0.
 *
 * @constant {string}
 * @static
 */
export const ACCESS_LEVEL_0 = 'ACCESS_LEVEL_0';

/**
 * Access level 1.
 *
 * @constant {string}
 * @static
 */
export const ACCESS_LEVEL_1 = 'ACCESS_LEVEL_1';

/**
 * Access level 2.
 *
 * @constant {string}
 * @static
 */
export const ACCESS_LEVEL_2 = 'ACCESS_LEVEL_2';

/**
 * Access level 3.
 *
 * @constant {string}
 * @static
 */
export const ACCESS_LEVEL_3 = 'ACCESS_LEVEL_3';

export default [
  ACCESS_LEVEL_0,
  ACCESS_LEVEL_1,
  ACCESS_LEVEL_2,
  ACCESS_LEVEL_3,
];
