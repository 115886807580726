/**
 * English translations for see-example-profile-button.
 *
 * @module languages/en/see-example-profile-button
 */
const seeExampleProfileButton = {
  see_example_profile_button_label: 'See example profile',
};

export default seeExampleProfileButton;
