/**
 * Greek translations for utilities/pricing-plans.
 *
 * @module languages/el/utilities/pricing-plans
 */
const pricingPlans = {
  BASIC: 'Βασικό',
  ENTERPRISE: 'Επαγγελματικό',
  FREE: 'Δωρεάν',
};

export default pricingPlans;
