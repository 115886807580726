/**
 * English translations for job-seekers/download-profile-button.
 *
 * @module languages/en/job-seekers/download-profile-button
 */
const downloadProfileButton = {
  download_profile_button_label: 'Download profile',
};

export default downloadProfileButton;
