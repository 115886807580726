/**
 * English translations for common/dashboard-statistics.
 *
 * @module languages/en/common/dashboard-statistics
 */
const dashboardStatistics = {
  companies_interested: 'Companies interested in you',
  endorsements_viewed_by_companies: 'Of your endorsements viewed by companies',
  job_ready_profiles: 'Job ready Profiles',
  job_seekers_available: 'Job Seekers Profiles Available',
  my_total_endorsements: 'Your total endorsements',
  new_endorsements_last_week: 'New endorsement requests last week',
  new_job_seeker_profiles: 'New Profiles Last Week',
  profile_views: 'Companies viewed your profile',
  profile_views_last_week: 'Companies viewed your profile last week',
};

export default dashboardStatistics;
