import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './learn-more-info-section.scss';
import LearnMoreInfoItem from './learn-more-info-item/learn-more-info-item';

const LearnMoreInfoSection = memo((props) => {
  const { info } = props;

  // The rendered info containers
  const renderedInformationContainers = useMemo(() => {
    return info.map((infoItem, index) => {
      return (
        <LearnMoreInfoItem
          description={ infoItem.description }
          index={ index + 1 }
          key={ infoItem.title }
          title={ infoItem.title }
        />
      );
    });
  }, [ info ]);

  return (
    <div className='learn-more-info-section'>
      { renderedInformationContainers }
    </div>
  );
});

LearnMoreInfoSection.displayName = 'LearnMoreInfoSection';

LearnMoreInfoSection.propTypes = {
  // The info array
  info: PropTypes.arrayOf(
    PropTypes.shape({
      // The info description
      description: PropTypes.string,
      // The info title
      title: PropTypes.string,
    })
  ).isRequired,
};

LearnMoreInfoSection.defaultProps = {};

export default LearnMoreInfoSection;
