/**
 * Greek translations for forgot-password.
 *
 * @module languages/el/forgot-password
 */
const forgotPassword = {
  captcha_label: 'Captcha',
  email_invalid_error: 'Εισάγετε μια έγκυρη διεύθυνση email.',
  email_placeholder: 'Email',
  email_required_error: 'To Email είναι απαραίτητο',
  general_error: 'Ωχ! Κάτι πήγε στραβά.',
  instructions: 'Εισαγάγετε τη διεύθυνση email σας για να επαναφέρετε τον κωδικό πρόσβασής σας.',
  locked_account_error: 'Ο λογαριασμός σας είναι κλειδωμένος. Παρακαλώ προσπάθησε ξανά αργότερα.',
  // eslint-disable-next-line max-len
  new_account_error: 'Δεν έχετε ενεργοποιήσει ακόμα τον λογαριασμό σας. Ελέγξτε τα εισερχόμενα σας ή επικοινωνήστε με την Odyssea για βοήθεια.',
  rejected_account_error: 'Η αίτηση σας για τη δημιουργία λογαριασμού απορρίφθηκε.',
  reset_password_button_label: 'Επαναφορά κωδικού πρόσβασης',
  // eslint-disable-next-line max-len
  sent_message: 'Ένα email έχει σταλεί στη διεύθυνση email που ορίσατε. Ελέγξτε τα εισερχόμενά σας και ακολουθήστε τις οδηγίες σε αυτό το email για να επαναφέρετε τον κωδικό πρόσβασής σας.',
  suspended_account_error: 'Ο λογαριασμός σας έχει απενεργοποιηθεί.',
  title: 'Ξεχάσατε τον κωδικό;',
  // eslint-disable-next-line max-len
  user_not_found_error: 'Φαίνεται ότι δεν έχετε τοπικό λογαριασμό για την επαναφορά του κωδικού πρόσβασής σας. Πρέπει πρώτα να εγγραφείτε τοπικά ή να δοκιμάσετε έναν άλλο πάροχο ελέγχου ταυτότητας.',
  verified_account_error: 'Η αίτηση σας για τη δημιουργία λογαριασμού εκκρεμεί.',
};

export default forgotPassword;
