/**
 * Greek translations for job-seekers/profile-competencies.
 *
 * @module languages/el/job-seekers/profile-competencies
 */
const profileCompetencies = {
  authorized_to_work_label: 'Είμαι διαθέσιμος/η να εργαστώ',
  computer_skills: 'Δεξιότητες Η/Υ',
  computer_skills_level_label: 'Δεξιότητες Η/Υ',
  computer_skills_level_placeholder: 'Επιλέξτε το επίπεδο γνώσης στον Η/Υ',
  driving_license_categories_label: 'Άδειες οδήγησης',
  driving_license_categories_placeholder: 'Επιλέξτε τη/ις κατηγορία/ες των αδειών οδήγησής σας',
  driving_licenses: 'Άδειες οδήγησης',
  editor_title: 'Δεξιότητες',
  expires_on: 'Λήγει στις',
  no: 'Οχι',
  skills_label: 'Ικανότητες',
  title: 'Δεξιότητες',
  willing_to_relocate: 'Διαθέσιμος/η για εργασία σε άλλη πόλη/νησί',
  willing_to_relocate_label: 'Είμαι διαθέσιμος/η για εργασία σε άλλη πόλη/νησί',
  work_authorization: 'Άδεια εργασίας',
  work_authorization_expires_on_label: 'Ημερομηνία Λήξης',
  work_authorization_expires_on_placeholder: 'Ημερομηνία Λήξης',
  work_authorization_label: 'Άδεια εργασίας',
  work_availability: 'Διαθεσιμότητα για εργασία',
  work_availability_label: 'Διαθεσιμότητα για εργασία',
  work_availability_placeholder: 'Επιλέξτε τη διαθεσιμότητα σας για εργασία',
  yes: 'Ναι',
};

export default profileCompetencies;
