/**
 * Application.
 */
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Content from 'components/content/content';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import Loader from 'components/loader/loader';
import Scrollable from 'components/scrollable/scrollable';
import Toasts from 'components/toasts/toasts';
import env from 'config/env';
import { actions as hashtagsActions } from 'ducks/hashtags';
import { actions as jobSectorsActions } from 'ducks/job-sectors';
import { actions as metricsActions } from 'ducks/metrics';
import { actions as professionsActions } from 'ducks/professions';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as requestsActions } from 'ducks/requests';
import { actions as skillsActions } from 'ducks/skills';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as hashtagsMethods from 'resources/hashtags';
import * as jobSectorsMethods from 'resources/job-sectors';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as metricsMethods from 'resources/metrics';
import * as professionsMethods from 'resources/professions';
import * as skillsMethods from 'resources/skills';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';

import './app.scss';

const App = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The settings state object
  const settings = useSelector((store) => {
    return store.settings;
  }, shallowEqual);

  // The application language.
  const language = useMemo(() => {
    return settings?.language;
  }, [ settings?.language ]);

  // The user id
  const userId = useMemo(() => {
    return auth?.id;
  }, [ auth?.id ]);

  // The companyId that the user is if he is a COMPANY_AGENT
  const companyId = useMemo(() => {
    return auth?.companyId;
  }, [ auth?.companyId ]);

  // The signed in user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return undefined !== userId;
  }, [ userId ]);

  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  // Whether the application is ready to be rendered.
  const [ ready, setReady ] = useState(false);

  // Get the job sectors, the professions and skills
  useEffect(() => {
    dispatch(requestsActions.request(jobSectorsMethods.getJobSectors, null, {
      onSuccess: (result) => {
        dispatch(jobSectorsActions.setJobSectors(result.jobSectors));
      },
    }));
    dispatch(requestsActions.request(professionsMethods.getProfessions, null, {
      onSuccess: (result) => {
        dispatch(professionsActions.setProfessions(result.professions));
      },
    }));
    dispatch(requestsActions.request(skillsMethods.getSkills, null, {
      onSuccess: (result) => {
        dispatch(skillsActions.setSkills(result.skills));
      },
    }));
    dispatch(requestsActions.request(hashtagsMethods.getHashtags, null, {
      onSuccess: (result) => {
        dispatch(hashtagsActions.setHashtags(result.hashtags));
      },
    }));
    dispatch(requestsActions.request(metricsMethods.getControlledMetrics, null, {
      onSuccess: (result) => {
        dispatch(metricsActions.setControlledMetrics(result.metrics?.[0]));
      },
    }));
  }, [ dispatch ]);

  // Get the company profile if the role is COMPANY_AGENT
  const getCompanyProfile = useCallback(() => {
    dispatch(requestsActions.request(companiesMethods.getProfile, {
      id: companyId,
    }, {
      onCompletion: () => {
        setReady(true);
      },
      onSuccess: (result) => {
        dispatch(profilesActions.setProfile(result));
      },
    }));
  }, [ companyId, dispatch ]);

  // Get the signed in user profile if the role is COMPANY_AGENT
  const getJobSeekerProfile = useCallback(() => {
    dispatch(requestsActions.request(jobSeekersMethods.getProfile, {
      id: userId,
    }, {
      onCompletion: () => {
        setReady(true);
      },
      onSuccess: (result) => {
        dispatch(profilesActions.setProfile(result));
      },
    }));
  }, [ dispatch, userId ]);

  const getEndorserProfile = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getProfile, {
      id: userId,
    }, {
      onCompletion: () => {
        setReady(true);
      },
      onSuccess: (result) => {
        dispatch(profilesActions.setProfile(result));
      },
    }));
  }, [ dispatch, userId ]);

  // The location path name
  const pathName = useMemo(() => {
    switch (userRole) {
    case Roles.JOB_SEEKER:
      return `/job-seekers/${ userId }/profile`;
    case Roles.COMPANY_AGENT:
      return `/companies/${ companyId }/profile`;
    case Roles.ADMINISTRATOR:
      return `/administrators/${ userId }/profile`;
    case Roles.ENDORSER:
      return `/endorsers/${ userId }/profile`;
    default:
      return '';
    }
  }, [ companyId, userId, userRole ]);

  // Get the signed in user profile
  useEffect(() => {
    if (!userAuthenticated) {
      setReady(true);
      return;
    }
    // If the authenticated user is a COMPANY_AGENT, and he is in his profile
    if (userRole === Roles.COMPANY_AGENT && window.location.pathname === pathName) {
      setReady(true);
      return;
    }
    // If the authenticated user is a JOB_SEEKER, and he is in his profile
    if (userRole === Roles.JOB_SEEKER && window.location.pathname === pathName) {
      setReady(true);
      return;
    }
    // If the authenticated user is an ADMINISTRATOR, and he is in his profile
    if (userRole === Roles.ADMINISTRATOR && window.location.pathname === pathName) {
      setReady(true);
      return;
    }
    // If the authenticated user is an ENDORSER, and he is in his profile
    if (userRole === Roles.ENDORSER && window.location.pathname === pathName) {
      setReady(true);
      return;
    }
    switch (userRole) {
    case Roles.JOB_SEEKER:
      getJobSeekerProfile();
      break;
    case Roles.COMPANY_AGENT:
      getCompanyProfile();
      break;
    case Roles.ENDORSER:
      getEndorserProfile();
      break;
    default:
      setReady(true);
      break;
    }

  }, [ getCompanyProfile, getEndorserProfile, getJobSeekerProfile, pathName, userAuthenticated, userId, userRole ]);

  // Set the selected language to the application language.
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [ i18n, language ]);

  const basename = useMemo(() => {
    return new URL(env.PUBLIC_URL).pathname;
  }, []);

  const handleOnGoogleOAuthScriptLoadError = useCallback(() => {
    toasts.error('Google OAuth script could not be loaded. Please refresh your browser');
  }, []);

  return useMemo(() => {
    if (!ready) {
      return null;
    }
    return (
      <div className='ody-app'>
        <Helmet>
        </Helmet>
        <BrowserRouter basename={ basename }>
          <Header />
          <Scrollable>
            <GoogleOAuthProvider
              clientId={ env.GOOGLE_CLIENT_ID }
              onScriptLoadError={ handleOnGoogleOAuthScriptLoadError }
            >
              <Content />
            </GoogleOAuthProvider>
            <Footer />
            <Loader />
            <Toasts />
          </Scrollable>
        </BrowserRouter>
      </div>
    );
  }, [ basename, handleOnGoogleOAuthScriptLoadError, ready ]);
};

App.propTypes = {};

App.defaultProps = {};

export default App;
