/**
 * English translations for utilities/job-seekers/sort-keys.
 *
 * @module languages/en/utilities/job-seekers/sort-keys
 */
const sortKeys = {
  LAST_MODIFIED_AT: 'Last updated',
  PROFILE_COMPLETION: 'Profile completion',
  WORK_AVAILABILITY: 'Availability',
};

export default sortKeys;
