import React, { memo } from 'react';

import './card-list-arrow-icon.scss';

const CardListArrowIcon = memo(() => {
  return (
    <svg
      className='card-list-arrow-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 9 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.833496 0.457438L8.8335 7.1241L0.833496 13.7908L0.833496 0.457438Z' fill='#222222' />
    </svg>
  );
});

CardListArrowIcon.displayName = 'CardListArrowIcon';

CardListArrowIcon.propTypes = {};

CardListArrowIcon.defaultProps = [];

export default CardListArrowIcon;
