/**
 * Statistics in public pages.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './public-statistics-section.scss';

const PublicStatisticsSection = memo((props) => {
  const { statistics, isYellow } = props;

  // The rendered statistics components
  const renderedStatisticComponents = useMemo(() => {
    return statistics.map((statistic, index) => {
      return (
        <div
          className={
            clsx('public-statistics__statistic', {
              'public-statistics__statistic--dark': !isYellow && 0 === index % 2,
              'public-statistics__statistic-yellow': isYellow,
              'public-statistics__statistic-yellow--dark': isYellow && 0 === index % 2,
            })
          }
          key={ `${ statistic.number } - ${ index }` }
        >
          <p className='hdg hdg-xxl public-statistics__statistic-number'>
            { statistic?.number ?? 0 }
          </p>
          <p className='txt txt-xl public-statistics__statistic-label'>
            { statistic?.title ?? '' }
          </p>
        </div>
      );
    });
  }, [ isYellow, statistics ]);

  return (
    <div className='public-statistics'>
      { renderedStatisticComponents }
    </div>
  );
});

PublicStatisticsSection.displayName = 'PublicStatisticsSection';

PublicStatisticsSection.propTypes = {
  // Whether the statistics have yellow color
  isYellow: PropTypes.bool,
  // The statistics in public pages
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      // statistic number
      number: PropTypes.string,
      // The text title
      title: PropTypes.string,
    })
  ).isRequired,
};

PublicStatisticsSection.defaultProps = {
  isYellow: false,
};

export default PublicStatisticsSection;
