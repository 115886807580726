import PropTypes from 'prop-types';
import React, { memo } from 'react';

import HeroAnimationIcon from 'animations/cape.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './learn-more-why-section.scss';

const LearnMoreWhySection = memo((props) => {
  const { title, description } = props;

  return (
    <div className='learn-more-why-section'>
      <div className='learn-more-why-section__content'>
        <h2 className='hdg hdg-xxl learn-more-why-section__title'>
          { title }
        </h2>
        <p className='txt txt-lg learn-more-why-section__description'>
          { description }
        </p>
      </div>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-why-section__animated-icon'
        animationData={ HeroAnimationIcon }
        className='learn-more-why-section__animation-container'
      />
    </div>
  );
});

LearnMoreWhySection.displayName = 'LearnMoreWhySection';

LearnMoreWhySection.propTypes = {
  // The section description
  description: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

LearnMoreWhySection.defaultProps = {};

export default LearnMoreWhySection;
