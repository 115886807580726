import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Carousel from 'components/common/carousel/carousel';
import { useSm, useXs } from 'hooks/media-queries';
import useElementOnScreen from 'hooks/use-element-on-screen';

import AboutUsMember from './about-us-member/about-us-member';

import './about-us-team-section.scss';

const AboutUsTeamSection = memo((props) => {
  const { title, members } = props;

  const isSm = useSm();

  const isXs = useXs();

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // The rendered members
  const renderedMembers = useMemo(() => {
    return members.map((member, memberIndex) => {
      return (
        <AboutUsMember
          imageSource={ member.imageSource }
          jobTitle={ member.jobTitle }
          key={ member.name + memberIndex }
          name={ member.name }
        />
      );
    });
  }, [ members ]);

  const smallScreens = useMemo(() => {
    return isSm || isXs;
  }, [ isSm, isXs ]);

  // The rendered content
  const renderedContent = useMemo(() => {
    // if the section is not visible don't request for images
    if (!isVisible) {
      return null;
    }
    if (!smallScreens) {
      return (
        <div className='about-us-team-section__members-container'>
          { renderedMembers }
        </div>
      );
    }
    return (
      <Carousel
        autoplay={ isVisible }
        infiniteLoop
      >
        { renderedMembers }
      </Carousel>
    );
  }, [ isVisible, renderedMembers, smallScreens ]);

  return (
    <div
      className='about-us-team-section'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl about-us-team-section__title'>
        { title }
      </h2>
      { renderedContent }
    </div>
  );
});

AboutUsTeamSection.displayName = 'AboutUsTeamSection';

AboutUsTeamSection.propTypes = {
  // The members
  members: PropTypes.arrayOf(
    PropTypes.shape({
      // The image source
      imageSource: PropTypes.string,
      // The job position
      jobTitle: PropTypes.string,
      // The member name
      name: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsTeamSection.defaultProps = {};

export default AboutUsTeamSection;
