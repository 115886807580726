/**
 * Greek translations for common/dashboard-contact-share-section.
 *
 * @module languages/el/common/dashboard-contact-share-section
 */
const dashboardContactShareSection = {
  // eslint-disable-next-line max-len
  contact_odyssea_title_companies: 'Χρειάζεστε υποστήριξη για να βρείτε τον κατάλληλο υποψήφιο ή να χρησιμοποιήσετε την πλατφόρμα μας;',
  contact_odyssea_title_endorsers: 'Χρειάζεστε υποστήριξη για να συστήσετε ή χρησιμοποιήσετε την πλατφόρμας μας;',
  download_failed_error: 'Η λήψη απέτυχε',
  download_profile: 'Λήψη προφίλ',
  endorsement_guide: 'Οδηγός σύστασης',
  share_title: 'Θέλετε να μοιραστείτε το προφίλ σας με μια νέα εταιρεία;',
};

export default dashboardContactShareSection;
