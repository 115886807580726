import PropTypes from 'prop-types';
import React, { memo } from 'react';

import LampIcon from 'components/common/icons/lamp-icon/lamp-icon';

import './about-us-solution-section.scss';

const AboutUsSolutionSection = memo((props) => {
  const { title, description } = props;

  return (
    <div className='about-us-solution-section'>
      <div className='about-us-solution-section__content'>
        <h3 className='hdg hdg-lg about-us-solution-section__title'>
          { title }
        </h3>
        <p className='txt txt-md about-us-solution-section__description'>
          { description }
        </p>
      </div>
      <div className='about-us-solution-section__icon-container'>
        <LampIcon />
      </div>
    </div>
  );
});

AboutUsSolutionSection.displayName = 'AboutUsSolutionSection';

AboutUsSolutionSection.propTypes = {
  // The section description
  description: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsSolutionSection.defaultProps = {};

export default AboutUsSolutionSection;
