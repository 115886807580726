/**
 * Settings - Account - Email.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import './email.scss';

const Email = (_props) => {
  // The email of the signed-in user.
  const userEmail = useSelector((store) => {
    return store.settings.email;
  }, shallowEqual);

  const { t } = useTranslation();

  return (
    <div className='email subsection'>
      <div className='title-and-text'>
        <div className='title'>
          { t('settings:account.email.title') }
        </div>
        <div className='text'>
          { userEmail }
        </div>
      </div>
    </div>
  );
};

Email.propTypes = {
};

Email.defaultProps = {
};

export default Email;
