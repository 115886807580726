/**
 * Company - Dashboard - Saved Job Seekers.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import accessLevels from 'utilities/access-levels';
import { takeFirstN } from 'utilities/chisels';
import allCities from 'utilities/cities';
import computerSkillsLevels from 'utilities/computer-skills-levels';
import drivingLicenseCategories from 'utilities/driving-license-categories';
import languageLevels from 'utilities/language-levels';

import DashboardSavedJobSeekerCard from './dashboard-saved-job-seeker-card/dashboard-saved-job-seeker-card';

import './dashboard-saved-job-seekers.scss';

const DashboardSavedJobSeekers = memo((props) => {
  const { savedJobSeekersProfiles, allProfessions, savedJobSeekers, handleOnUnsaveJobSeeker } = props;

  const { t } = useTranslation();

  const [ viewAll, setViewAll ] = useState(false);

  // Whether to render the whole array of the saved job seekers profiles or the first 4 elements
  const savedJobSeekersToRender = useMemo(() => {
    return viewAll ? savedJobSeekersProfiles : takeFirstN(savedJobSeekersProfiles, 4);
  }, [ savedJobSeekersProfiles, viewAll ]);

  // The saved job seekers cards
  const renderedResults = useMemo(() => {
    return savedJobSeekersToRender?.map((savedJobSeekerProfile) => {
      return (
        <DashboardSavedJobSeekerCard
          allProfessions={ allProfessions }
          handleOnUnsaveJobSeeker={ handleOnUnsaveJobSeeker }
          key={ savedJobSeekerProfile.id }
          profile={ savedJobSeekerProfile }
          savedJobSeekers={ savedJobSeekers }
          savedJobSeekersProfiles={ savedJobSeekersProfiles }
        />
      );
    });
  }, [
    allProfessions,
    handleOnUnsaveJobSeeker,
    savedJobSeekers,
    savedJobSeekersProfiles,
    savedJobSeekersToRender,
  ]);

  // The saved job-seekers results
  const savedJobSeekersResults = useMemo(() => {
    if (!savedJobSeekersToRender?.length) {
      return (
        <div className='dashboard-saved-job-seekers__no-results'>
          <span className='hdg hdg-sm'>
            { t('dashboard:saved_job_seekers.no_saved_job_seekers') }
          </span>
        </div>
      );
    }
    return (
      <div className='dashboard-saved-job-seekers__results'>
        { renderedResults }
      </div>
    );
  }, [ renderedResults, savedJobSeekersToRender?.length, t ]);

  // Function that is invoked when view all button in the saved job seekers section is clicked
  const handleOnViewAllClick = useCallback(() => {
    setViewAll(!viewAll);
  }, [ viewAll ]);

  // The view all button title
  const viewAllButtonTitle = useMemo(() => {
    if (viewAll) {
      return t('dashboard:saved_job_seekers.view_less_button_title');
    }
    return t('dashboard:saved_job_seekers.view_all_button_title');
  }, [ t, viewAll ]);

  return (
    <div className='dashboard-saved-job-seekers'>
      <div className='dashboard-saved-job-seekers__header'>
        <h2 className='dashboard-saved-job-seekers__header-title hdg hdg-md'>
          { t('dashboard:saved_job_seekers.title') }
        </h2>
        <button
          className='btn btn-sm btn-rounded-sm btn-blue'
          disabled={ !savedJobSeekersToRender?.length }
          onClick={ handleOnViewAllClick }
        >
          { viewAllButtonTitle }
        </button>
      </div>
      { savedJobSeekersResults }
    </div>
  );
});

DashboardSavedJobSeekers.propTypes = {
  // All the professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // The green translation of the profession
      el: PropTypes.string.isRequired,
      // The english translation of the profession
      en: PropTypes.string.isRequired,
      // The id of the profession
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  // The function () => void to be invoked when unsaving a job seeker from dashboard
  handleOnUnsaveJobSeeker: PropTypes.func,
  // The saved job seekers UUID array
  savedJobSeekers: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The saved job seekers profiles
  savedJobSeekersProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      // the access level
      accessLevel: PropTypes.oneOf(accessLevels),
      // The background image id of the profile
      backgroundImage: PropTypes.number,
      // The computer skill level of the profile
      computerSkillsLevel: PropTypes.oneOf(computerSkillsLevels),
      // the driving license categories
      drivingLicenseCategories: PropTypes.arrayOf(
        PropTypes.oneOf(drivingLicenseCategories)
      ),
      // The english level of the job seeker
      englishLevel: PropTypes.oneOf(languageLevels),
      // The experience of the profile
      experience: PropTypes.array,
      // The firstName of the profile
      firstName: PropTypes.string,
      // The greek level of the profile
      greekLevel: PropTypes.oneOf(languageLevels),
      // The id of the profile
      id: PropTypes.string,
      // The job sectors UUID array
      jobSectors: PropTypes.arrayOf(PropTypes.string),
      // the last name of the profile
      lastName: PropTypes.string,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      otherLanguages: PropTypes.array,
      // The phone numbers array
      phoneNumbers: PropTypes.array,
      // The professions UUID array
      professions: PropTypes.arrayOf(PropTypes.string),
      // The profile picture
      profilePicture: PropTypes.shape({
        // the id of the profile picture
        id: PropTypes.string,
      }),
      // the qualifications array
      qualifications: PropTypes.array,
      // The short bio
      shortBio: PropTypes.string,
    })
  ).isRequired,
};

DashboardSavedJobSeekers.defaultProps = {
  allProfessions: [],
  companyId: '',
  savedJobSeekers: [],
  savedJobSeekersProfiles: [],
};

export default DashboardSavedJobSeekers;
