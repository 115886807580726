/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './arrows-up-group.scss';

const ArrowsUpGroupIcon = memo((props) => {
  const { isDark } = props;

  return (
    <svg
      className='arrows-up-group-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 410 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7173_16295)'>
        <path
          d='M63.0237 35.9448C61.6297 33.0354 32.0777 3.44716 30.0792 0.793843C29.8152 1.41989 18.2223 16.1575 14.0488 21.0859C10.9208 24.7802 0.53497 35.3995 0.000181328 37.4113C3.98387 37.5948 55.6413 36.2059 63.0237 35.9448Z'
          fill={ isDark ? '#222222' : '#74ABC3' }
        />
      </g>
      <g clipPath='url(#clip1_7173_16295)'>
        <path
          d='M231.01 38.066C229.616 35.1566 200.064 5.56838 198.066 2.91506C197.802 3.5411 186.209 18.2787 182.035 23.2071C178.907 26.9014 168.522 37.5207 167.987 39.5325C171.97 39.7161 223.628 38.3271 231.01 38.066Z'
          fill={ isDark ? '#222222' : '#74ABC3' }
        />
      </g>
      <g clipPath='url(#clip2_7173_16295)'>
        <path
          d='M146.017 36.9927C144.623 34.0834 115.071 4.49513 113.073 1.84182C112.809 2.46786 101.216 17.2054 97.0422 22.1339C93.9142 25.8281 83.5284 36.4475 82.9936 38.4593C86.9773 38.6428 138.635 37.2539 146.017 36.9927Z'
          fill={ isDark ? '#222222' : '#74ABC3' }
        />
      </g>
      <g clipPath='url(#clip3_7173_16295)'>
        <path
          d='M320.003 39.1898C318.609 36.2804 289.057 6.69216 287.059 4.03884C286.795 4.66488 275.202 19.4025 271.028 24.3309C267.9 28.0252 257.514 38.6445 256.98 40.6563C260.963 40.8398 312.621 39.4509 320.003 39.1898Z'
          fill={ isDark ? '#222222' : '#74ABC3' }
        />
      </g>
      <g clipPath='url(#clip4_7173_16295)'>
        <path
          d='M408.996 40.3135C407.602 37.4042 378.05 7.81594 376.052 5.16262C375.788 5.78866 364.195 20.5262 360.021 25.4547C356.893 29.149 346.507 39.7683 345.973 41.7801C349.956 41.9636 401.614 40.5747 408.996 40.3135Z'
          fill={ isDark ? '#222222' : '#74ABC3' }
        />
      </g>
      <defs>
        <clipPath id='clip0_7173_16295'>
          <rect fill='white' height='37' transform='translate(0.467285 0.419922) rotate(0.723457)' width='63' />
        </clipPath>
        <clipPath id='clip1_7173_16295'>
          <rect fill='white' height='37' transform='translate(168.454 2.54114) rotate(0.723457)' width='63' />
        </clipPath>
        <clipPath id='clip2_7173_16295'>
          <rect fill='white' height='37' transform='translate(83.4607 1.4679) rotate(0.723457)' width='63' />
        </clipPath>
        <clipPath id='clip3_7173_16295'>
          <rect fill='white' height='37' transform='translate(257.447 3.66492) rotate(0.723457)' width='63' />
        </clipPath>
        <clipPath id='clip4_7173_16295'>
          <rect fill='white' height='37' transform='translate(346.44 4.7887) rotate(0.723457)' width='63' />
        </clipPath>
      </defs>
    </svg>
  );
});

ArrowsUpGroupIcon.displayName = 'ArrowsUpGroupIcon';

ArrowsUpGroupIcon.propTypes = {
  // whether the arrows are dark
  isDark: PropTypes.bool,
};

ArrowsUpGroupIcon.defaultProps = {
  isDark: false,
};

export default ArrowsUpGroupIcon;
