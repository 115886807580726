/**
 * The job seeker feedback levels
 *
 * @module utilities/endorsement/job-seeker-feedback-levels
 */

/**
 * More Preparation.
 *
 * @constant {string}
 * @static
 */
export const MORE_PREPARATION = 'MORE_PREPARATION';

/**
 * Knowledge upgrade.
 *
 * @constant {string}
 * @static
 */
export const KNOWLEDGE_UPGRADE = 'KNOWLEDGE_UPGRADE';

/**
 * Support needed.
 *
 * @constant {string}
 * @static
 */
export const SUPPORT_NEEDED = 'SUPPORT_NEEDED';

/**
 * Languages improvement.
 *
 * @constant {string}
 * @static
 */
export const LANGUAGES_IMPROVEMENT = 'LANGUAGES_IMPROVEMENT';

/**
 * Prioritization.
 *
 * @constant {string}
 * @static
 */
export const PRIORITIZATION = 'PRIORITIZATION';

/**
 * Personal Family Matters.
 *
 * @constant {string}
 * @static
 */
export const PERSONAL_FAMILY_MATTERS = 'PERSONAL_FAMILY_MATTERS';

export default [
  MORE_PREPARATION,
  KNOWLEDGE_UPGRADE,
  SUPPORT_NEEDED,
  LANGUAGES_IMPROVEMENT,
  PRIORITIZATION,
  PERSONAL_FAMILY_MATTERS,
];
