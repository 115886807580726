/**
 * Administrators - Dashboard - Pending section.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import roles from 'utilities/auth/roles';
import { takeFirstN } from 'utilities/chisels';

import './pending-section.scss';

import PendingCard from './pending-card/pending-card';

const PendingSection = memo((props) => {
  const { title, pendingProfiles, activeRequest, role, onConfirmApprove } = props;

  const { t } = useTranslation();

  const [ viewAll, setViewAll ] = useState(false);

  // The pending profiles to render
  const pendingProfilesToRender = useMemo(() => {
    return viewAll ? pendingProfiles : takeFirstN(pendingProfiles, 4);
  }, [ pendingProfiles, viewAll ]);

  // function that is invoked when clicking view all button
  const handleViewAllClick = useCallback(() => {
    setViewAll(!viewAll);
  }, [ viewAll ]);

  // Function that is invoked when approving a pending entity
  const onApproval = useCallback((userId) => {
    onConfirmApprove(userId, role);
  }, [ onConfirmApprove, role ]);

  // The pending profile cards
  const pendingProfileCards = useMemo(() => {
    return pendingProfilesToRender?.map((pendingProfile) => {
      return (
        <PendingCard
          key={ pendingProfile.id }
          onApproval={ onApproval }
          pendingProfile={ pendingProfile }
          role={ role }
        />
      );
    });
  }, [ onApproval, pendingProfilesToRender, role ]);

  // The rendered pending profile cards
  const renderedPendingProfileCards = useMemo(() => {
    if (!pendingProfilesToRender?.length && !activeRequest) {
      return (
        <div className='pending-section__no-pending-results'>
          <span className='hdg hdg-sm'>
            { t('administrators:dashboard.no_pending_results') }
          </span>
        </div>
      );
    }
    return (
      <div className='pending-section__cards'>
        { pendingProfileCards }
      </div>
    );
  }, [ activeRequest, pendingProfileCards, pendingProfilesToRender?.length, t ]);

  // The view all button title
  const viewAllButtonTitle = useMemo(() => {
    if (viewAll) {
      return t('administrators:dashboard.view_less_button_title');
    }
    return t('administrators:dashboard.view_all_button_title');
  }, [ t, viewAll ]);

  return (
    <div className='pending-section dark'>
      <div className='pending-section__title-section'>
        <h2 className='hdg hdg-md'>
          { title }
        </h2>
        <button
          className='btn btn-sm btn-rounded-sm btn-blue pending-section__view-all-button'
          disabled={ !pendingProfilesToRender?.length }
          onClick={ handleViewAllClick }
        >
          { viewAllButtonTitle }
        </button>
      </div>
      { renderedPendingProfileCards }
    </div>
  );
});

PendingSection.displayName = 'PendingSection';

PendingSection.propTypes = {
  // whether the request is active
  activeRequest: PropTypes.bool.isRequired,
  // The function (userId, role) => void that is invoked when approving a pending profile
  onConfirmApprove: PropTypes.func,
  // The pending profiles that are waiting for approval
  pendingProfiles: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Pending company
      PropTypes.shape({
        // the UUID's array of company agent ids
        companyAgentIds: PropTypes.arrayOf(PropTypes.string),
        // the company name
        companyName: PropTypes.string,
        // The email of the company agent
        email: PropTypes.string,
        // The firstName of the company agent
        firstName: PropTypes.string,
        // The company id
        id: PropTypes.string,
        // The lastName of the company agent
        lastName: PropTypes.string,
        // phone number
        phoneNumber: PropTypes.string,
      }),
      // Pending endorser
      PropTypes.shape({
        // the company name
        companyName: PropTypes.string,
        // The email of the endorser
        email: PropTypes.string,
        // The firstName of the endorser
        firstName: PropTypes.string,
        // The endorser id
        id: PropTypes.string,
        // The lastName of the endorser
        lastName: PropTypes.string,
        // phone number
        phoneNumber: PropTypes.string,
      }),
    ]),
  ).isRequired,
  // The role of the section
  role: PropTypes.oneOf(roles).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

PendingSection.defaultProps = {};

export default PendingSection;
