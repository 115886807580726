/**
 * English translations for endorsers/endorsement-card.
 *
 * @module languages/en/endorsers/endorsement-card
 */
const endorsementCard = {
  about_title: 'About',
  claimed_button_title: 'Review',
  created_button_title: 'Accept',
  created_confirmation_message: 'Are you sure you want to accept {{jobSeekerName}}\'s endorsement?',
  endorsement_overview_title: 'Endorsement overview',
  overview_list: {
    communication: 'Communication: {{level}}',
    critical_thinking: 'Critical thinking: {{level}}',
    motivation: 'Motivation: {{level}}',
    professionalism: 'Professionalism: {{level}}',
  },
  processed_button_title: 'View Review',
  revoke_button_title: 'Revoke',
  revoke_confirmation_message: 'Are you sure you want to revoke {{jobSeekerName}}\'s endorsement?',
  see_profile_link: 'See Profile',
};

export default endorsementCard;
