/**
 * Greek translations for utilities/job-seekers/filter-types.
 *
 * @module languages/el/utilities/job-seekers/filter-types
 */
const filterTypes = {
  COMPUTER_SKILLS_LEVEL: 'Δεξιότητες Η/Υ',
  DRIVING_LICENSE_CATEGORY: 'Δίπλωμα οδήγησης',
  EXPERIENCE_LENGTH: 'Εργασιακή εμπειρία (σε χρόνια)',
  LANGUAGE: 'Γλώσσες',
  PROFESSION: 'Επιθυμητές θέσεις εργασίας',
  PROFILE_COMPLETION_PERCENTAGE: 'Ολοκλήρωση προφίλ (%)',
  QUALIFICATION_TYPE: 'Εκπαίδευση/κατάρτιση',
  WILLING_TO_RELOCATE: 'Διαθεσιμότητα μετακίνησης εκτός τόπου κατοικίας',
  WORK_AVAILABILITY: 'Διαθεσιμότητα για εργασία',
};

export default filterTypes;
