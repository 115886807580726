/**
 * Company contact button
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import MailLogo from 'images/email.png';
import Monocular from 'images/monocular.png';
import PhoneLogo from 'images/tel.png';

import './contact-button.scss';

const ContactButton = memo((props) => {
  const { className } = props;

  const { t } = useTranslation();

  return (
    <div className='contact-button'>
      <Modal
        className='contact-modal'
        trigger={
          <button
            className={ clsx('btn', className) }
            type='button'
          >
            { t('common:contact_button:label') }
          </button>
        }
      >
        <div className='contact-odyssea'>
          <div className='header'>
            <img
              alt='odyssea monocular logo'
              className='header-logo'
              src={ Monocular }
            />
          </div>
          <div className='contact-content'>
            <h1 className='hdg hdg-md'>
              { t('common:contact_button:title') }
            </h1>
            <p className='txt txt-sm'>
              { t('common:contact_button:description') }
            </p>
            <div className='links'>
              <a
                className='link'
                href={ `mailto:${ t('contact_button:email') }` }
              >
                <img
                  alt='mail logo'
                  className='logo'
                  src={ MailLogo }
                />
                { t('common:contact_button:email') }
              </a>
              <a
                className='link'
                href={ `tel:${ t('contact_button:phoneNumber1') }` }
              >
                <img
                  alt='phone logo'
                  className='logo'
                  src={ PhoneLogo }
                />
                { t('common:contact_button:phoneNumber1') }
              </a>
              <a
                className='link'
                href={ `tel:${ t('contact_button:phoneNumber2') }` }
              >
                <img
                  alt='phone logo'
                  className='logo'
                  src={ PhoneLogo }
                />
                { t('common:contact_button:phoneNumber2') }
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

ContactButton.displayName = 'ContactButton';

ContactButton.propTypes = {
  // The class(es) to apply to the button.
  className: PropTypes.string,
};

ContactButton.defaultProps = {};

export default ContactButton;
