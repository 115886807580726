/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const DiamondCardIcon = memo((props) => {
  const { isWhite } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_9398_4949)'>
        <path
          d='M21.1587 45.2893C17.9244 41.5586 14.7061 37.844 11.4718 34.1133C9.35826 31.6636 7.24476 29.2299 5.13126 26.7801C3.65821 25.0829 3.28995 24.5065 3.14584 23.9621C2.58545 21.7365 2.3773 16.805 2.95371 14.5634C3.46607 12.61 1.76887 15.7803 3.37 13.939C3.99445 13.2345 5.21131 12.7381 6.02789 12.2098C8.97399 10.2884 11.9361 8.36703 14.8822 6.44566C15.9389 5.75717 20.6943 2.60293 20.31 2.60293C19.7977 2.60293 24.1208 5.43695 25.0814 6.06139C28.0275 7.99877 30.9576 9.92014 33.9037 11.8575C34.8644 12.498 36.3054 13.1064 37.09 13.955C38.563 15.5561 37.17 13.2985 37.5063 14.2112C38.2428 16.2927 37.6504 21.2402 37.3141 23.4337C36.9619 25.6433 32.2545 30.3987 29.5646 33.5369C26.2022 37.4597 22.8398 41.3825 19.4775 45.2893C18.4687 46.4581 20.1659 48.1553 21.1747 46.9865C25.1935 42.2952 29.2124 37.6198 33.2312 32.9285C36.1453 29.5341 39.2675 27.2284 39.7319 22.8733C40.2442 18.214 41.1569 14.2112 37.5863 11.3772C32.4467 7.29427 26.4264 4.04396 20.9345 0.425383C20.5983 0.201223 20.0699 0.201223 19.7176 0.425383C14.2738 3.97991 8.73382 7.40635 3.35399 11.089C-0.776945 13.907 0.183738 17.8137 0.728125 22.8733C1.17644 27.0683 3.96243 29.0858 6.81245 32.3681C11.0234 37.2356 15.2505 42.103 19.4614 46.9865C20.4702 48.1553 22.1674 46.4421 21.1587 45.2893Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M3.86698 14.9958C10.4316 15.0118 16.9803 15.0598 23.529 14.8196C27.788 14.6595 31.5667 14.8036 35.8257 15.2199C36.5782 15.3 37.2027 15.8444 37.9712 15.7483C39.4923 15.5562 39.5083 13.1544 37.9712 13.3466C36.8825 13.4907 34.7689 12.5941 33.8083 12.4499C30.3978 11.9696 26.9554 12.2898 23.529 12.4179C16.9643 12.6421 10.4156 12.5941 3.86698 12.5941C2.31387 12.578 2.31387 14.9797 3.86698 14.9958Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M3.86698 26.4599C15.2351 26.5239 26.4751 25.7074 37.8271 25.5633C39.3642 25.5472 39.3802 23.1455 37.8271 23.1615C26.4751 23.2896 15.2351 24.1222 3.86698 24.0582C2.31387 24.0422 2.31387 26.4439 3.86698 26.4599Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M10.7519 15.1558C11.3924 18.1019 11.1042 21.1761 11.9368 24.0902C12.3691 25.5632 14.6907 24.9388 14.2584 23.4497C13.4098 20.5517 13.714 17.4615 13.0736 14.5154C12.7373 12.9943 10.4157 13.6347 10.7519 15.1558Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M27.3867 14.5314C27.3867 16.5809 27.4668 18.6463 27.5628 20.6958C27.6109 21.6885 27.3707 24.8427 28.8598 25.0669C30.3648 25.3071 31.0213 22.9854 29.5002 22.7452C31.1334 23.0014 30.0286 23.866 30.1407 23.0655C30.1887 22.7292 30.0606 22.2649 30.0286 21.9287C29.9646 21 29.9165 20.0713 29.8845 19.1427C29.8205 17.6056 29.7724 16.0685 29.7724 14.5154C29.7884 12.9943 27.3867 12.9783 27.3867 14.5314Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M13.6813 15.1398C15.8428 11.3772 18.5327 7.96674 20.8544 4.31615C20.2299 4.3962 19.5895 4.47626 18.9651 4.55632C22.1994 7.29426 24.8412 10.5126 27.0828 14.0991C27.8994 15.412 29.9809 14.1952 29.1643 12.8822C26.8106 9.13557 24.0567 5.70914 20.6623 2.85911C20.0538 2.34674 19.2212 2.41079 18.7729 3.09928C16.4513 6.74988 13.7453 10.1603 11.5998 13.923C10.8473 15.2679 12.9127 16.4848 13.6813 15.1398Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
        <path
          d='M12.833 25.8675C14.7064 32.24 15.2347 39.253 19.3336 44.7289C19.9261 45.5135 21.223 45.5135 21.5272 44.4407C22.632 40.4699 24.3452 36.7873 25.9143 32.9765C26.9391 30.4788 27.7236 27.8369 29.1806 25.5473C30.0132 24.2503 27.9478 23.0335 27.0992 24.3304C25.3859 26.9883 24.5053 30.1425 23.2885 33.0406C21.7834 36.5951 20.2303 40.0696 19.2056 43.8002C19.9421 43.7042 20.6626 43.6081 21.3991 43.512C17.4283 38.2123 16.964 31.3914 15.1387 25.211C14.7064 23.754 12.4007 24.3784 12.833 25.8675Z'
          fill={ isWhite ? '#ffffff' : '#161616' }
        />
      </g>
      <defs>
        <clipPath id='clip0_9398_4949'>
          <rect fill='white' height='42.2573' transform='translate(0.227539 0.257263)' width='40' />
        </clipPath>
      </defs>
    </svg>
  );
});

DiamondCardIcon.displayName = 'DiamondCardIcon';

DiamondCardIcon.propTypes = {
  // Whether the icon is white
  isWhite: PropTypes.bool,
};

DiamondCardIcon.defaultProps = {
  isWhite: false,
};

export default DiamondCardIcon;
