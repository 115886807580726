import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslationKey } from 'utilities/chisels';

import './controlled-metrics-card.scss';

const ControlledMetricsCard = (props) => {
  const { register, subMetric, subMetricsKey, errors } = props;

  const { t } = useTranslation();

  // The input error
  const renderInputError = useCallback(() => {
    if (!errors?.[subMetricsKey]) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('administrators:dashboard.controlled_metrics.input_error_message') }
      </span>
    );
  }, [ errors, subMetricsKey, t ]);

  return (
    <div
      className={
        clsx('controlled-metrics-card', {
          'controlled-metrics-card__error': errors?.[subMetricsKey],
        })
      }
    >
      <label className='hdg hdg-sm txt-bold' htmlFor={ subMetricsKey }>
        { t(`administrators:dashboard.controlled_metrics.${ getTranslationKey(subMetricsKey) }_metric_title`) }
      </label>
      <div className='controlled-metrics-card__input-container'>
        <input
          className={
            clsx({
              'error': errors?.[subMetricsKey],
            })
          }
          defaultValue={ subMetric || '' }
          id={ subMetricsKey }
          maxLength={ 256 }
          placeholder={ t('administrators:dashboard.controlled_metrics.input_placeholder') }
          type='text'
          { ...register(subMetricsKey) }
        />
        { renderInputError() }
      </div>
    </div>
  );
};

ControlledMetricsCard.propTypes = {
  // The errors of the form
  errors: PropTypes.shape({
    // The collaborating companies
    collaboratingCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The employability services per year
    employabilityServicesPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The founded employability per year
    findEmployabilityPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The job positions from odysea's companies
    jobPositionsFromOurCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // the matched job seekers with companies
    matchedJobSeekersWithCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The people hired
    peopleHired: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The people interviewed
    peopleInterviewed: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // People registered per year
    registeredPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The students registered per year
    studentsPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
  }),
  // The register function to bind the controlled metrics input with the hook form
  register: PropTypes.func,
  // The sub metric to populate
  subMetric: PropTypes.string,
  // The sub metric key
  subMetricsKey: PropTypes.string,
};

ControlledMetricsCard.defaultProps = {};

export default ControlledMetricsCard;
