/**
 * English translations for common/option-filter.
 *
 * @module languages/en/common/option-filter
 */
const optionFilter = {
  no_options_message: 'No data.',
  see_less_button_label: 'See less',
  see_more_button_label: 'See more',
};

export default optionFilter;
