import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './about-us-key-metric.scss';

const AboutUsKeyMetric = memo((props) => {
  const { label, number } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('about-us-key-metric', {
          'about-us-key-metric__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='about-us-key-metric__number-container'>
        <p className='about-us-key-metric__number'>
          { number }
        </p>
      </div>
      <p className='txt txt-sm txt-bold about-us-key-metric__label'>
        { label }
      </p>
    </div>
  );
});

AboutUsKeyMetric.displayName = 'AboutUsKeyMetric';

AboutUsKeyMetric.propTypes = {
  // the key metric label
  label: PropTypes.string.isRequired,
  // the key metric number
  number: PropTypes.string.isRequired,
};

AboutUsKeyMetric.defaultProps = {};

export default AboutUsKeyMetric;
