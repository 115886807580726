/**
 * Resource method to sign up as a company via LinkedIn.
 *
 * @module resources/companies/linkedin-sign-up
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/linkedin-sign-up~Params
 * @type {object}
 * @property {string} accessToken - The access token provided via Google.
 * @property {string} companyName - The name of the company.
 * @property {string} email - The email of the company agent.
 * @property {string} firstName - The first name of the company agent.
 * @property {string} language - The ISO 639-1 alpha-2 code of the language of the company agent.
 * @property {string} lastName - The last name of the company agent.
 * @property {string} phoneNumber - The phone number of the company agent.
 */

/**
 * Result.
 *
 * @typedef module:resources/companies/linkedin-sign-up~Result
 * @type {object}
 * @property {string} id - The ID of the company.
 */

/**
 * Signs up a company and a company agent via LinkedIn based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/linkedin-sign-up~Params} params - The parameters.
 * @returns {Promise<module:resources/companies/linkedin-sign-up~Result>} - The promise.
 * @static
 */
const linkedInSignUp = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/companies/linkedin/sign-up',
  }).then((response) => {
    return response.data;
  });
};

export default linkedInSignUp;
