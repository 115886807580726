/**
 * English translations for verify-email.
 *
 * @module languages/en/verify-email
 */
const verifyEmail = {
  // eslint-disable-next-line max-len
  companies_verified_message: 'Thank you for your interest.\n\nThe Talent Platform team will shortly review your request and you will be able to connect to the platform soon.\n\n We may contact you for additional information, if needed.',
  general_error: 'Oops! Something went wrong.',
  job_seekers_verified_message: 'Your account has been activated. You can now sign in.',
};

export default verifyEmail;
