/**
 * Greek translations for common/profile-details-section.
 *
 * @module languages/el/common/profile-details-section
 */
const profileDetailsSection = {
  born_on_invalid_error: 'Εισάγετε μια έγκυρη ημερομηνία.',
  born_on_label: 'Ημερομηνία γέννησης',
  born_on_placeholder: 'Ημερομηνία γέννησης',
  citizen: 'Πολίτης',
  citizenship_label: 'Υπηκοότητά',
  citizenship_placeholder: 'Επιλέξτε την υπηκοότητά σας',
  city_placeholder: 'Επιλέξτε πόλη',
  companies_endorsers_title: 'Πληροφορίες',
  company_website_label: 'Ιστοσελίδα της εταιρείας',
  day_placeholder: 'Ημέρα',
  district_label: 'Περιοχή',
  district_placeholder: 'Περιοχή',
  first_name_label: 'Όνομα',
  first_name_placeholder: 'Όνομα',
  first_name_required_error: 'Η εισαγωγή του ονόματος είναι υποχρεωτική',
  gender_label: 'Γένος',
  gender_placeholder: 'Επιλέξτε Γένος',
  hires_per_year_label: 'Προσλήψεις ανά έτος',
  hires_per_year_placeholder: 'Προσλήψεις ανά έτος',
  job_seekers_title: 'Προσωπικές πληροφορίες',
  last_name_label: 'Επίθετο',
  last_name_placeholder: 'Επίθετο',
  last_name_required_error: 'Η εισαγωγή του επιθέτου είναι υποχρεωτική',
  location_label: 'Τοποθεσία',
  location_placeholder: 'Τοποθεσία',
  month_placeholder: 'Μήνας',
  number_of_employees_label: 'Αριθμός υπαλλήλων',
  number_of_employees_placeholder: 'Αριθμός υπαλλήλων',
  origin_label: 'Χώρα καταγωγής',
  origin_placeholder: 'Επιλέξτε χώρα καταγωγής',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Ο αριθμός τηλεφώνου πρέπει να είναι αριθμός με το πρόθεμα της χώρας (π.χ.  30) και να μην υπερβαίνει τους 10 αριθμούς',
  phone_number_label: 'Τηλέφωνο',
  phone_number_placeholder: 'Αριθμός τηλεφώνου',
  phone_number_required_error: 'Η εισαγωγή του αριθμού τηλεφώνου είναι υποχρεωτική',
  website_invalid_error: 'Εισαγάγετε μια έγκυρη διεύθυνση URL',
  website_label: 'Ιστοσελίδα',
  website_placeholder: 'https://www.example.com',
  year_placeholder: 'Έτος',
  years_old_one: '{{count}} έτους',
  years_old_other: '{{count}} χρονών',
};

export default profileDetailsSection;
