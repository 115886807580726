/**
 * Resource method to get job sectors.
 *
 * @module resources/job-sectors/get-job-sectors
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/job-sectors/get-job-sectors~Result
 * @type {object}
 * @property {module:types/job-sectors~JobSector[]} jobSectors - The job sectors.
 */

/**
 * Gets the job sectors.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/job-sectors/get-job-sectors~Result>} - The promise.
 * @static
 */
const getJobSectors = () => {
  return axios.request({
    method: 'get',
    url: '/job-sectors/',
  }).then((response) => {
    return response.data;
  });
};

export default getJobSectors;
