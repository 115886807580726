/**
 * Duck for companies.
 *
 * @module ducks/companies
 */

import filterTypes from 'utilities/companies/filter-types';

/**
 * The type of actions to describe the fetching of saved job-seekers (successfully or not).
 *
 * @constant {string}
 * @default
 * @static
 */
const SAVED_JOB_SEEKER_PROFILES_FETCHED = 'SAVED_JOB_SEEKER_PROFILES_FETCHED';

/**
 * The type of actions to describe that the reset of the initial state.
 *
 * @constant {string}
 * @default
 * @static
 */
const COMPANIES_RESET = 'COMPANIES_RESET';

/**
 * The type of actions to describe that a search was completed (successfully or not).
 *
 * @constant {string}
 * @default
 * @static
 */
const COMPANIES_SEARCH_COMPLETED = 'COMPANIES_SEARCH_COMPLETED';

/**
 * The type of actions to describe that a search was made.
 *
 * @constant {string}
 * @default
 * @static
 */
const COMPANIES_SEARCH_MADE = 'COMPANIES_SEARCH_MADE';

/**
 * The type of actions to describe that a search was refined.
 *
 * @constant {string}
 * @default
 * @static
 */
const COMPANIES_SEARCH_REFINED = 'COMPANIES_SEARCH_REFINED';

/**
 * Criteria.
 *
 * @typedef module:ducks/companies~Criterion
 * @type {object}
 * @property {('CITY' | 'JOB_SECTOR' | 'TERM')} type - The type of the criterion.
 * @property {string} value - The value of the criterion.
 */

/**
 * Filters.
 *
 * @typedef module:ducks/companies~Filter
 * @type {object}
 * @property {('NUMBER_OF_EMPLOYEES' | 'PROFESSION')} type - The type of the filter.
 * @property {string} value - The value of the filter.
 */

/**
 * Search result.
 *
 * @typedef module:ducks/companies~Result
 * @type {object}
 * @property {module:types/companies/searches~Facets} [facets] - The facets.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {module:types/companies/profiles~Profile[]} [profiles] - The profiles.
 */

/**
 * Creates an action to complete a search with the given result.
 *
 * @param {module:ducks/companies~Result} result - The search result.
 * @returns {object} - The action.
 * @static
 */
const completeSearch = (result) => {
  return {
    result,
    type: COMPANIES_SEARCH_COMPLETED,
  };
};

/**
 * Search parameters.
 *
 * @typedef module:ducks/companies~Params
 * @type {object}
 * @property {module:ducks/companies~Criterion[]} criteria - The criteria.
 * @property {module:ducks/companies~Filter[]} [filters] - The filters.
 * @property {number} page - The number of the current page.
 * @property {('LAST_MODIFIED_AT' | 'PROFILE_COMPLETION' | 'WORK_AVAILABILITY')} sort - The key to sort by.
 */

/**
 * Creates an action to make a search with the given parameters.
 *
 * @param {module:ducks/companies~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const makeSearch = (params) => {
  return {
    params,
    type: COMPANIES_SEARCH_MADE,
  };
};

/**
 * Creates an action to refine a search with the given parameters.
 *
 * @param {module:ducks/companies~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const refineSearch = (params) => {
  return {
    params,
    type: COMPANIES_SEARCH_REFINED,
  };
};

/**
 * Creates an action to fetch saved job seeker profiles result.
 *
 * @param {module:types/job-seekers/profiles~Profile[]} result - The saved job-seekers result array.
 * @returns {object} - The action.
 * @static
 */
const fetchSavedJobSeekersProfiles = (result) => {
  return {
    result,
    type: SAVED_JOB_SEEKER_PROFILES_FETCHED,
  };
};

/**
 * Creates an action to reset the state.
 *
 * @returns {object} - The action.
 * @static
 */
const reset = () => {
  return {
    type: COMPANIES_RESET,
  };
};

/**
 * Company agents state.
 *
 * @typedef {object} State
 * @property {module:ducks/companies~Criterion[]} [criteria] - The criteria.
 * @property {module:types/companies/searches~Facets} [facets] - The facets.
 * @property {module:ducks/companies~Filter[]} [filters] - The filters.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {number} [page] - The number of the current page.
 * @property {module:types/companies/profiles~Profile[]} [profiles] - The profiles.
 * @property {module:types/job-seekers/profiles~Profile[]} [savedJobSeekersProfiles] - The saved job seekers profiles
 * @property {('LAST_MODIFIED_AT' | 'PROFILE_COMPLETION' | 'WORK_AVAILABILITY')} [sort] - The key to sort by.
 */

/**
 * The initial company agents state.
 *
 * @type {module:ducks/companies~State}
 * @static
 */
const initialState = {
  criteria: undefined,
  expanded: filterTypes.reduce((acc, ft) => {
    return { ...acc, [ft]: true };
  }, {}),
  facets: undefined,
  filters: undefined,
  meta: undefined,
  page: undefined,
  profiles: undefined,
  savedJobSeekersProfiles: undefined,
  sort: undefined,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/companies~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/companies~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case COMPANIES_SEARCH_COMPLETED:
    return {
      ...state,
      facets: action.result.facets,
      meta: action.result.meta,
      profiles: action.result.profiles,
    };
  case COMPANIES_SEARCH_MADE:
    return {
      criteria: action.params.criteria,
      expanded: filterTypes.reduce((acc, ft) => {
        return { ...acc, [ft]: true };
      }, {}),
      facets: undefined,
      filters: action.params.filters,
      meta: undefined,
      page: action.params.page,
      profiles: undefined,
      sort: action.params.sort,
    };
  case COMPANIES_SEARCH_REFINED:
    return {
      ...state,
      criteria: action.params.criteria,
      filters: action.params.filters,
      page: action.params.page,
      sort: action.params.sort,
    };
  case SAVED_JOB_SEEKER_PROFILES_FETCHED:
    return {
      ...state,
      savedJobSeekersProfiles: action.result,
    };
  case COMPANIES_RESET:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  completeSearch,
  fetchSavedJobSeekersProfiles,
  makeSearch,
  refineSearch,
  reset,
};

const types = {};

export {
  actions,
  reducer,
  types,
};
