import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileProfessionsEditor from './profile-professions-editor/profile-professions-editor';

import './profile-professions.scss';

const ProfileProfessions = memo((props) => {
  const { allProfessions, isMainSection, profile, onChange, canEdit } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The rendered profession value
  const renderedProfessionValue = useCallback((professionId) => {
    return allProfessions?.find((profession) => {
      return profession.id === professionId;
    })?.[selectedLanguage];
  }, [ allProfessions, selectedLanguage ]);

  const renderedFields = useMemo(() => {
    return profile?.professions?.map((professionId, index) => {
      return (
        <div
          className={
            clsx('profile-professions__field', {
              'profile-professions__field--purple': 1 === index % 3,
              'profile-professions__field--red': 0 === index % 3,
              'profile-professions__field--yellow': 0 !== index % 3 && 1 !== index % 3,
            })
          }
          key={ professionId }
        >
          <span className='txt txt-sm'>
            { renderedProfessionValue(professionId) }
          </span>
        </div>
      );
    });
  }, [ profile?.professions, renderedProfessionValue ]);

  // function that handles the on change of professions
  const onProfessionsChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    // We can use either Job seeker parts or company or endorser parts because PROFESSIONS are exactly the same
    onChange(paramsToBeUpdated, JobSeekerParts.PROFESSIONS, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileProfessionsEditor
          allProfessions={ allProfessions }
          onCancel={ toggleEditor(false) }
          onSave={ onProfessionsChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ allProfessions, canEdit, editorOpen, onProfessionsChange, profile, toggleEditor ]);

  return (
    <div className='profile-professions'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isMainSection={ isMainSection }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('common:profile_professions.title') }
      />
      <div className='profile-professions__fields'>
        { renderedFields }
      </div>
      { editorModal }
    </div>
  );
});

ProfileProfessions.displayName = 'ProfileProfessions';

ProfileProfessions.propTypes = {
  // The all professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the profession
      el: PropTypes.string,
      // The english translation of the profession
      en: PropTypes.string,
      // The id of the profession
      id: PropTypes.string,
    })
  ).isRequired,
  // Whether the user can edit the details section
  canEdit: PropTypes.bool.isRequired,
  // whether the section is in main profile or the sidebar
  isMainSection: PropTypes.bool,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func.isRequired,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The IDs of the professions that the job seeker is interested in.
    professions: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProfileProfessions.defaultProps = {
  isMainSection: false,
};

export default ProfileProfessions;
