/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const BadgeCardIcon = memo((props) => {
  const { isWhite } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 44'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.2066 4.95911C15.7655 -0.662439 22.4571 0.00826854 23.7666 3.26621C25.5074 -0.199345 31.7198 2.21221 29.5 5.10283C33.5564 2.21221 36.0638 6.01314 34.2591 9.84601C38.3316 9.0475 41.1583 13.5192 37.5969 16.4577C43.474 19.6837 37.9004 26.6947 36.2714 25.7684C40.1202 28.8666 36.9741 34.584 32.5343 32.0447C33.5564 34.3125 34.866 37.0434 31.1449 38.0655C28.7334 38.7363 27.0725 38.0336 26.5774 35.8616C27.4238 41.4193 18.8638 43.0323 19.0714 37.3309C16.7237 39.7264 10.144 41.036 11.7889 35.127C8.61082 38.4648 4.45854 34.4882 6.43886 30.7511C2.60598 29.0742 -0.092998 27.0141 4.55436 24.2512C-0.651958 22.0473 -1.53033 17.7992 3.96346 16.0424C1.29642 10.4528 4.1551 9.5106 9.31351 10.6605C5.43273 2.83502 13.2262 3.31416 15.2066 4.95911Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M8.56292 35.3666C7.54082 38.353 6.2632 41.2277 5.01751 44.1343C4.26691 45.891 3.48436 47.7276 3.89959 49.66C4.09123 50.5862 5.27304 50.7619 5.89588 50.187C7.50888 48.7017 10.0162 44.2939 12.2042 47.0568C13.5776 48.7976 13.4977 51.0813 15.3024 52.6304C16.037 53.2533 16.9633 52.9658 17.2987 52.1034C18.8957 48.1588 20.9878 43.8787 21.5468 39.6147C21.7544 38.0975 19.3588 38.1135 19.1512 39.6147C18.6242 43.5753 16.4682 47.7915 14.983 51.4646C15.6537 51.2889 16.3245 51.1132 16.9793 50.9376C14.7434 49.0211 14.8872 43.8787 11.3897 43.3198C8.37128 42.8247 6.05558 46.7693 4.20303 48.4941C4.87378 48.6698 5.54453 48.8455 6.19931 49.0212C5.76812 46.9929 7.49291 44.5175 8.25948 42.7448C9.2177 40.5409 10.0961 38.2731 10.8786 36.0054C11.3737 34.5361 9.058 33.9133 8.56292 35.3666Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M21.3541 40.8124C23.7496 45.7472 25.139 51.0334 27.4707 55.9682C27.854 56.7667 29.1636 56.7348 29.5469 55.9682C30.4252 54.1795 29.6267 49.6599 31.2397 48.8294C33.0124 47.9032 35.919 51.6243 37.4521 52.7901C38.2666 53.4129 39.4325 53.0616 39.4963 51.9437C39.6561 48.6538 37.6917 44.7411 36.5259 41.7067C35.7274 39.5986 34.9288 37.2031 33.3158 35.5901C32.2299 34.5041 30.537 36.197 31.623 37.2829C33.1881 38.864 33.9067 41.4512 34.6573 43.5114C35.5677 46.0187 37.2445 49.2766 37.1008 51.9437C37.7875 51.6562 38.4583 51.3847 39.145 51.0973C36.989 49.4363 33.8748 45.3799 30.8085 45.6833C26.8479 46.0826 28.6685 52.3909 27.5027 54.7545C28.1894 54.7545 28.8761 54.7545 29.5788 54.7545C27.2471 49.8356 25.8577 44.5334 23.4622 39.5986C22.7435 38.2092 20.6674 39.423 21.3541 40.8124Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M36.3195 19.7156C36.0161 11.5069 30.4105 4.2563 21.7227 4.11256C13.5618 3.96883 6.87028 10.9479 5.86415 18.7734C4.85802 26.5669 9.69702 33.8813 17.5864 35.2068C26.37 36.692 35.7127 30.3997 36.3195 21.2807C36.4153 19.7475 34.0198 19.7475 33.924 21.2807C33.365 29.6651 23.719 34.9513 16.0692 32.3961C10.0643 30.3998 7.18969 24.0915 8.38746 18.0866C9.63314 11.8262 15.111 6.38035 21.7227 6.50811C29.085 6.65185 33.6685 12.7845 33.924 19.7156C33.9719 21.2488 36.3674 21.2647 36.3195 19.7156Z'
        fill={ isWhite ?  '#EBB446' : '#F4F4EF' }
      />
      <path
        d='M16.212 19.0609C17.4896 16.5535 19.6137 14.4934 21.6738 12.6248C20.9871 12.3374 20.3164 12.0659 19.6296 11.7784C19.6137 16.4897 19.6296 21.1849 19.6296 25.8962C19.6296 27.4453 22.0252 27.4453 22.0252 25.8962C22.0252 21.1849 22.0092 16.4897 22.0252 11.7784C22.0252 10.7084 20.7316 10.2453 19.981 10.932C17.7771 12.9283 15.5093 15.1801 14.1518 17.8631C13.4491 19.2206 15.5093 20.4343 16.212 19.0609Z'
        fill={ isWhite ?  '#EBB446' : '#F4F4EF' }
      />
      <path
        d='M13.6889 27.0779C18.3043 27.0779 22.9038 26.7585 27.5032 27.2216C29.0364 27.3813 29.0204 24.9859 27.5032 24.8262C22.9038 24.3471 18.3043 24.6665 13.6889 24.6824C12.1558 24.6824 12.1558 27.0779 13.6889 27.0779Z'
        fill={ isWhite ?  '#EBB446' : '#F4F4EF' }
      />
    </svg>
  );
});

BadgeCardIcon.displayName = 'BadgeCardIcon';

BadgeCardIcon.propTypes = {
  // Whether the icon is white
  isWhite: PropTypes.bool,
};

BadgeCardIcon.defaultProps = {
  isWhite: false,
};

export default BadgeCardIcon;
