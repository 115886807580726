/**
 * Greek translations for verify-email.
 *
 * @module languages/el/verify-email
 */
const verifyEmail = {
  // eslint-disable-next-line max-len
  companies_verified_message: 'Σας ευχαριστούμε για το ενδιαφέρον σας.\n\nΗ ομάδα της Talent Platform θα εξετάσει σύντομα το αίτημά σας και θα μπορείτε να συνδεθείτε στην πλατφόρμα σύντομα. \n\nΕνδέχεται να επικοινωνήσουμε μαζί σας για πρόσθετες πληροφορίες, εάν χρειαστεί.',
  general_error: 'Ωχ! Κάτι πήγε στραβά.',
  job_seekers_verified_message: 'Ο λογαριασμός σας έχει ενεργοποιηθεί. Τώρα μπορείτε να συνδεθείτε.',
};

export default verifyEmail;
