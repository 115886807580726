/**
 * Cities.
 *
 * @module utilities/cities
 */
export default [
  'AGIOS_NIKOLAOS',
  'ALEKSANDROYPOLI',
  'AMFISSA',
  'ARGOSTOLI',
  'ARTA',
  'ATHENS',
  'BEROIA',
  'BOLOS',
  'DRAMA',
  'EDESSA',
  'ERMOYPOLI',
  'FLORINA',
  'GREBENA',
  'HALKIDA',
  'HANIA',
  'HGOYMENITSA',
  'HRAKLEIO',
  'IOANNINA',
  'KABALA',
  'KALAMATA',
  'KARDITSA',
  'KARPENISI',
  'KASTORIA',
  'KATERINI',
  'KERKYRA',
  'KILKIS',
  'KOMOTINI',
  'KORINTHOS',
  'KOZANI',
  'KSANTHI',
  'LAMIA',
  'LARISSA',
  'LEIVADIA',
  'LEYKADA',
  'MESOLOGGI',
  'MYTILINI',
  'NAYPLION',
  'PATRA',
  'POLYGYROS',
  'PREBEZA',
  'PYRGOS',
  'RETHIMNO',
  'RODOS',
  'SAMOS',
  'SERRES',
  'SPARTI',
  'THESSALONIKI',
  'TRIKALA',
  'TRIPOLI',
  'XIOS',
  'ZAKYNTHOS',
];
