/**
 * English translations for home.
 *
 * @module languages/en/home
 */
const home = {
  companies_collection_section: {
    button_label: 'See all',
    title: 'See our companies',
  },
  create_profile_section: {
    button_label: 'See an example profile',
    description: 'Land your next job through the Odyssea Talent Platform. Start today.',
    title: 'Create your profile',
  },
  find_talent_section: {
    button_label: 'Explore Talent',
    // eslint-disable-next-line max-len
    info: 'Having difficulties finding a suitable candidate? Search our pool of verified talents to find the one that best fits your needs.',
    title: 'Find talent',
  },
  grow_social_impact_section: {
    button_label: 'Learn more',
    // eslint-disable-next-line max-len
    info: 'Achieve your SDG goals by leveraging our expertise on professional training, employability and decent employment or diversity and inclusion in the workplace.\n\nLet’s work together to scale social impact.',
    title: 'Grow your social impact',
  },
  learn_more_section: {
    companies_cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Save time and resources by using our platform to filter through pre-screened job seekers who match your requirements.',
        title: 'Eliminate screening time',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Access a pool of high-quality, verified talent with complete profiles and endorsements from trusted sources.',
        title: 'Discover verified profiles',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Diversify your workforce and promote inclusivity with our support and resources to help you hire a diverse range of job seekers.',
        title: 'Improve your DEI',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Make a difference in the world by partnering with us and supporting a social impact cause while hiring top talent.',
        title: 'Social impact',
      },
    },
    company_card_section_title: 'Company',
    job_seeker_card_section_title: 'Job seeker',
    job_seekers_cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Build an eye-catching profile, download it as a CV, and showcase your skills and experience to potential employers.',
        title: 'Create and share your CV',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Take psychometric tests to identify your strengths and weaknesses, and use the insights to improve your career prospects.',
        title: 'Know your traits',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Connect with endorsers who provide feedback and guidance to help you enhance your profile and stand out to employers.',
        title: 'Guidance by endorsers',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Use our powerful search tools and algorithms to increase your visibility and get noticed by top companies.',
        title: 'Get discovered by companies',
      },
    },
    learn_more_button_label: 'Learn More',
    subtitle: 'Explore the Advantages of Choosing Our Talent Platform',
    title: 'Why choose Odyssea Talent Platform',
  },
  our_companies_say_section: {
    collection: {
      item1: {
        item1: {
          description: '“We thank Odyssea very much for their help and hope for a long term cooperation.”',
          name: 'Eleni Tsakiri',
          occupation: 'Talent Acquisition Senior Manager at Sani Ikos Group',
        },
        item2: {
          // eslint-disable-next-line max-len
          description: '“We have a very nice cooperation with Odyssea. We hope that we can find a suitable candidate for the position we are looking for and that they can find a job that can be useful in our community.”',
          name: 'Mylonidis Stefanos',
          occupation: 'HR Department of Regency Casino Mont Parnes',
        },
        item3: {
          description: '“We are delighted to work with Odyssea to support people from vulnerable groups.”',
          name: 'Adia Zimboglou',
          occupation: 'Supervisor at Karriera.gr',
        },
        item4: {
          description: '“It is very gratifying to help people who really need to find work.”',
          name: 'Spyros Mathioudakis',
          occupation: 'Mathioudakis elevators',
        },
      },
      item2: {
        item1: {
          // eslint-disable-next-line max-len
          description: '“We are happy to support Odyssea and young people who are looking for work, who may have faced several difficulties in society.”',
          name: 'Stavrou Christina',
          occupation: 'Barilla',
        },
        item2: {
          // eslint-disable-next-line max-len
          description: '“We met some very nice candidates through Odyssea and we definitely want to see them again and work with them.”',
          name: 'Nefeli Maraka',
          occupation: 'Talent Acquisition Specialist at Apply2day',
        },
        item3: {
          description: '“The level of participants from the Odyssey is very good.”',
          name: 'Katerina Giannakaki',
          occupation: 'Senior Talent Acquisition Partner at Pepsico',
        },
        item4: {
          description: '“We are an HR company and we have a very good partnership with Odyssey.”',
          name: 'Veta Papanikolaou',
          occupation: 'KSM',
        },
      },
    },
    title: 'Our companies say...',
  },
  statistics_section: {
    statistics: {
      item1: {
        title: 'Profiles',
      },
      item2: {
        title: 'Companies',
      },
      item3: {
        title: 'People hired',
      },
      item4: {
        title: 'Matched job seekers with companies',
      },
    },
  },
  testimonials_section: {
    testimonials: {
      item1: {
        item1: {
          age_location: '29 years old, Athens',
          name: 'Awng Nu La',
          title: 'Awng Nu La’s from Myanmar empowering story',
          video_url: 'https://www.youtube.com/embed/igBvNpJN6fA',
        },
        item2: {
          age_location: '28 years old, Athens',
          name: 'Hana Ganji',
          title: '“Thank you about this opportunity to improve my skills and find a job”',
          video_url: 'https://www.youtube.com/embed/IjViKd72tE8',
        },
        item3: {
          age_location: '30 years old from Guinea',
          // eslint-disable-next-line max-len
          description: 'First of all I express all my gratitude and thanks to Odyssea, for the effort and the help towards all the people on the training until obtaining work and good integration. A welcoming team, smiling and attentive to people\'s concerns. For my part, I suggest to anyone looking for answers about their talents or what they should get into. Please accept the expression of my best regards!',
          name: 'Mamadou Oury Diallo',
          title: '“A welcoming team, smiling and attentive to people\'s concerns.”',
        },
      },
      item2: {
        item1: {
          age_location: '30 years old, Athens',
          name: 'Shah in Alon',
          title: 'The story of Shah in Alon from Bangladesh',
          video_url: 'https://www.youtube.com/embed/AOOuSiYLG7Q',
        },
        item2: {
          age_location: '26 years old, Athens',
          name: 'Hamed',
          title: '”Odyssea gave me the opportunity to work in my specialisation”',
          video_url: 'https://www.youtube.com/embed/UPzTNeYNvi0',
        },
        item3: {
          age_location: '18 years old from Gambia',
          // eslint-disable-next-line max-len
          description: 'Momodou wanting to encourage his sister, entered the course for Hotel Employee himself. Upon graduation, he attended the Odyssea Job Fair and secured a job within a top hotel group.',
          name: 'Momodou Jallow',
          // eslint-disable-next-line max-len
          title: '“I want to make my sister believe that she can follow her dreams. I want to thank Odyssea and its partners for their support in my pursuit of happiness”',
        },
      },
      item3: {
        item1: {
          age_location: 'Serafeio, Athens',
          name: 'Job Fair',
          title: 'Job Fair “ΘΕΛΗΣΗ” focusing on employability and volunteering',
          video_url: 'https://www.youtube.com/embed/5kIPN3Bob80',
        },
        item2: {
          age_location: 'Athens',
          name: 'Technical Training',
          title: 'Tailor Made Course with Mathioudakis Elevators',
          video_url: 'https://www.youtube.com/embed/seAWxtSWLYU',
        },
        item3: {
          age_location: 'from Athens',
          // eslint-disable-next-line max-len
          description: '“I joined Odyssea and attended the catering and service course in order to acquire the professional skills needed to obtain a new job position. Eventually, I was able to find a job and was able to integrate into the workplace immediately.”',
          name: 'Eleftheria S.',
          // eslint-disable-next-line max-len
          title: '“Luck brought me to Odyssea, the team and everything I learned was the satisfaction of being in an environment so nice that it was also authentic.”',
        },
      },
    },
    title: 'Job seekers tell their stories',
  },
  welcome_section: {
    // eslint-disable-next-line max-len
    card_description: 'Welcome to Odyssea Talent Platform. We help job seekers find meaningful employment. We help companies find best talent. Our vision is to create equal opportunities for all.',
    company_button_label: 'Company? Join now',
    job_seeker_button_label: 'Job seeker? Join now',
    or: 'or',
  },
};

export default home;
