import PropTypes from 'prop-types';
import React, { memo } from 'react';

import LearnMoreSignInSectionCompaniesAnimatedIcon from 'animations/lens.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './social-responsibility-first-section.scss';

const SocialResponsibilityFirstSection = memo((props) => {
  const { title, description } = props;

  return (
    <div className='social-responsibility-first-section'>
      <div className='social-responsibility-first-section__content'>
        <h2 className='hdg hdg-xxl social-responsibility-first-section__title'>
          { title }
        </h2>
        <p className='txt txt-xl txt-bold social-responsibility-first-section__description'>
          { description }
        </p>
      </div>
      <LottieAnimationContainer
        animatedIconClassName='social-responsibility-first-section__animated-icon'
        animationData={ LearnMoreSignInSectionCompaniesAnimatedIcon }
        className='social-responsibility-first-section__animation-container'
      />
    </div>
  );
});

SocialResponsibilityFirstSection.displayName = 'SocialResponsibilityFirstSection';

SocialResponsibilityFirstSection.propTypes = {
  // The section description
  description: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

SocialResponsibilityFirstSection.defaultProps = {};

export default SocialResponsibilityFirstSection;
