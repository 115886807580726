/**
 * Company - Dashboard - Saved Job Seeker individual Card.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import { useLg, useMd } from 'hooks/media-queries';
import defaultProfilePicture from 'images/default-profile-picture.png';
import SaveIconFilled from 'images/star-filled.png';
import accessLevels from 'utilities/access-levels';
import { takeFirstN } from 'utilities/chisels';
import allCities from 'utilities/cities';
import computerSkillsLevels from 'utilities/computer-skills-levels';
import drivingLicenseCategories from 'utilities/driving-license-categories';
import languageLevels from 'utilities/language-levels';

import './dashboard-saved-job-seeker-card.scss';

const DashboardSavedJobSeekerCard = memo((props) => {
  const { profile, savedJobSeekersProfiles, savedJobSeekers, allProfessions, handleOnUnsaveJobSeeker } = props;

  const { t, i18n } = useTranslation();

  const lg = useLg();

  const md = useMd();

  const largeScreens = useMemo(() => {
    return lg || md;
  }, [ lg, md ]);

  // The first 3 jobs that the saved job seeker is interested in
  const first3Professions = useMemo(() => {
    if (!profile?.professions) {
      return undefined;
    }
    return takeFirstN(profile.professions, 3);
  }, [ profile.professions ]);

  // filtered saved job seekers UUID list
  const filteredSavedJobSeekers = useMemo(() => {
    return savedJobSeekers.filter((savedJobSeekerId) => {
      return savedJobSeekerId !== profile.id;
    });
  }, [ profile.id, savedJobSeekers ]);

  // filtered saved job seekers profiles list
  const getFilteredSavedJobSeekersProfiles = useCallback(() => {
    return savedJobSeekersProfiles?.filter((savedJobSeekerProfile) => {
      return profile.id !== savedJobSeekerProfile.id;
    });
  }, [ profile.id, savedJobSeekersProfiles ]);

  // function that is invoked when the save button is clicked
  const handleOnUnsaveButtonClick = useCallback((event) => {
    event.preventDefault();
    handleOnUnsaveJobSeeker(filteredSavedJobSeekers, getFilteredSavedJobSeekersProfiles());
  }, [ filteredSavedJobSeekers, getFilteredSavedJobSeekersProfiles, handleOnUnsaveJobSeeker ]);

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The user profile picture
  const userProfilePicture = useMemo(() => {
    if (undefined === profile?.profilePicture) {
      return (
        <CircularImage
          alt='Default image'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='saved job seeker image'
        imageSource={
          `${ env.CORE_BASE_URL }/job-seekers/${ profile.id }/profile/picture?id=${ profile.profilePicture.id }`
        }
      />
    );
  }, [ profile.id, profile.profilePicture ]);

  // The about section
  const aboutSection = useMemo(() => {
    if (!profile?.shortBio) {
      return null;
    }
    return (
      <div className='saved-job-seeker-card__about-section dark'>
        <h2 className='txt txt-sm txt-bold'>
          { t('dashboard:saved_job_seekers.about_title') }
        </h2>
        <p className='txt txt-sm saved-job-seeker-card__about-section-value'>
          { profile.shortBio }
        </p>
      </div>
    );
  }, [ profile?.shortBio, t ]);

  // The profession title based on all the professions
  const professionTitle = useCallback((professionId) => {
    return allProfessions.find((profession) => {
      return profession.id === professionId;
    })?.[selectedLanguage];
  }, [ allProfessions, selectedLanguage ]);

  // The list of the jobs interested in
  const jobsInterestedInList = useMemo(() => {
    return first3Professions?.map((professionId, index) => {
      return (
        <div
          className={
            clsx('txt txt-sm saved-job-seeker-card__job', {
              'saved-job-seeker-card__job--purple': 1 === index % 3,
              'saved-job-seeker-card__job--red': 0 === index % 3,
              'saved-job-seeker-card__job--yellow': 0 !== index % 3 && 1 !== index % 3,
            })
          }
          key={ professionId }
        >
          { professionTitle(professionId) }
        </div>
      );
    });
  }, [ first3Professions, professionTitle ]);

  // The jobs interested in section
  const jobsInterestedInSection = useMemo(() => {
    if (!largeScreens) {
      return (
        <button
          className='btn btn-sm btn-rounded-sm btn-blue'
          type='button'
        >
          { t('dashboard:saved_job_seekers.view_profile_title') }
        </button>
      );
    }
    if (!profile?.professions?.length && largeScreens) {
      return null;
    }
    return (
      <div className='saved-job-seeker-card__jobs-interested-section dark'>
        <h2 className='txt txt-sm txt-bold'>
          { t('dashboard:saved_job_seekers.jobs_interested_title') }
        </h2>
        <div className='saved-job-seeker-card__jobs-interested-in'>
          { jobsInterestedInList }
        </div>
      </div>
    );
  }, [ jobsInterestedInList, largeScreens, profile?.professions?.length, t ]);

  return (
    <Link
      className='saved-job-seeker-card'
      to={ `/job-seekers/${ profile.id }/profile` }
    >
      <button
        className='saved-job-seeker-card__save-button'
        onClick={ handleOnUnsaveButtonClick }
        type='button'
      >
        <img
          alt='save icon'
          className='saved-job-seeker-card__save-icon'
          src={ SaveIconFilled }
        />
      </button>
      <div className='saved-job-seeker-card__header'>
        <div className={
          clsx('saved-job-seeker-card__header-logo-container', {
            'no-picture': undefined === profile?.profilePicture,
          })
        }
        >
          { userProfilePicture }
        </div>
        <div className='saved-job-seeker-card__name-container dark'>
          <p className='txt txt-md txt-bold'>
            { `${ profile.firstName },` }
          </p>
          <p className='txt txt-md txt-bold'>
            { profile.lastName }
          </p>
        </div>
      </div>
      { aboutSection }
      { jobsInterestedInSection }
    </Link>
  );
});

DashboardSavedJobSeekerCard.displayName = 'DashboardSavedJobSeekerCard';

DashboardSavedJobSeekerCard.propTypes = {
  // All the professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // The green translation of the profession
      el: PropTypes.string.isRequired,
      // The english translation of the profession
      en: PropTypes.string.isRequired,
      // The id of the profession
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  // The function () => void to be invoked when unsaving a job seeker from dashboard
  handleOnUnsaveJobSeeker: PropTypes.func,
  // The profile of the saved job seeker
  profile: PropTypes.shape({
    // The firstName of the saved job seeker
    firstName: PropTypes.string,
    // The id of the profile
    id: PropTypes.string,
    // The last name of the saved job seeker
    lastName: PropTypes.string,
    // The jobs that the saved job seeker is interested as UUID array
    professions: PropTypes.arrayOf(PropTypes.string),
    // The profile picture of the saved job seeker
    profilePicture: PropTypes.shape({
      // The id of the profile picture
      id: PropTypes.string,
    }),
    // The short bio of the saved job seeker
    shortBio: PropTypes.string,
  }).isRequired,
  // The saved job seekers UUID array
  savedJobSeekers: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The saved job seekers profiles
  savedJobSeekersProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      // the access level
      accessLevel: PropTypes.oneOf(accessLevels),
      // The background image id of the profile
      backgroundImage: PropTypes.number,
      // The computer skill level of the profile
      computerSkillsLevel: PropTypes.oneOf(computerSkillsLevels),
      // the driving license categories
      drivingLicenseCategories: PropTypes.arrayOf(
        PropTypes.oneOf(drivingLicenseCategories)
      ),
      // The english level of the job seeker
      englishLevel: PropTypes.oneOf(languageLevels),
      // The experience of the profile
      experience: PropTypes.array,
      // The firstName of the profile
      firstName: PropTypes.string,
      // The greek level of the profile
      greekLevel: PropTypes.oneOf(languageLevels),
      // The id of the profile
      id: PropTypes.string,
      // The job sectors UUID array
      jobSectors: PropTypes.arrayOf(PropTypes.string),
      // the last name of the profile
      lastName: PropTypes.string,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      otherLanguages: PropTypes.array,
      // The phone numbers array
      phoneNumbers: PropTypes.array,
      // The professions UUID array
      professions: PropTypes.arrayOf(PropTypes.string),
      // The profile picture
      profilePicture: PropTypes.shape({
        // the id of the profile picture
        id: PropTypes.string,
      }),
      // the qualifications array
      qualifications: PropTypes.array,
      // The short bio
      shortBio: PropTypes.string,
    })
  ).isRequired,
};

DashboardSavedJobSeekerCard.defaultProps = {};

export default DashboardSavedJobSeekerCard;
