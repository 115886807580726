/* eslint-disable */
import React, { memo } from 'react';

const ToolsIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 46 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.0094 23.4519C39.3051 28.5831 49.6922 20.5248 44.7031 11.4519C44.3643 10.8338 43.6048 10.8572 43.1258 11.2537C41.5601 12.5248 37.903 17.0496 36.0336 15.1954C34.3978 13.5744 39.6556 10.2624 40.8357 9.20118C41.3031 8.78136 41.1979 7.90672 40.6371 7.62684C32.8438 3.70847 25.775 10.5423 27.6678 18.6589C27.9599 19.93 29.8527 19.3819 29.6074 18.1225C29.5957 18.0758 29.584 18.0175 29.5723 17.9709C29.3269 16.6997 27.3874 17.2362 27.6328 18.5073C27.6444 18.5773 17.4677 25.8542 16.8601 26.3324C13.904 28.6647 10.8428 30.8222 8.40086 33.7143C8.17887 33.9592 8.01529 34.3207 8.14381 34.6706C8.7397 36.2916 9.87305 38.0292 11.2401 39.1137C11.614 39.4053 12.0229 39.4869 12.4552 39.2653C19.7227 35.6035 26.1255 28.5831 32.2129 23.2886C33.1944 22.4373 31.7689 21.0263 30.7875 21.8659C27.0719 25.0962 23.3097 28.2682 19.5357 31.4403C17.5845 33.0846 12.1398 39.7201 10.8662 35.5569C10.0133 32.8047 15.3879 30.0059 17.4326 28.3965C21.3935 25.2828 25.3777 22.2041 29.3386 19.0787C29.6307 18.8572 29.6658 18.4257 29.5957 18.0991C29.584 18.0525 29.5723 17.9942 29.5606 17.9475C29.3153 16.6764 27.3757 17.2128 27.6211 18.484C27.6328 18.5306 27.6444 18.5889 27.6561 18.6356C28.2988 18.4607 28.9531 18.2857 29.5957 18.0991C28.1585 11.9534 33.393 6.2041 39.6089 9.32946C39.5388 8.80468 39.4687 8.2799 39.4103 7.75512C37.2487 9.69098 35.0755 11.5919 32.9373 13.5394C32.5401 13.9009 32.5634 14.5889 32.9373 14.9621C34.094 16.105 35.2624 17.2128 36.4893 18.2857C36.9216 18.6589 37.4824 18.6589 37.9147 18.2857C40.0996 16.3732 42.2962 14.484 44.5512 12.6531C44.0255 12.5831 43.4997 12.5131 42.9739 12.4548C47.0867 19.9184 38.2419 25.551 32.026 21.7143C30.916 21.0379 29.9111 22.7755 31.0094 23.4519Z'
        fill='#A693C4'
      />
      <path
        d='M18.3672 11.0321C21.1013 14.4374 29.8877 25.0613 33.5915 29.4811C35.1688 31.3586 37.5874 33.8426 38.4988 36.1516C38.2768 36.7697 35.017 38.9855 34.4912 38.9855C31.9207 37.0729 29.7825 33.8659 27.7729 31.382C24.2209 27.0088 17.1053 17.8193 14.8036 14.6356L18.3672 11.0321Z'
        fill='#A693C4'
      />
      <path
        d='M17.6545 11.7435C21.1247 16.0583 24.6649 20.3149 28.1935 24.5714C29.9111 26.6473 31.6286 28.7347 33.3812 30.7872C34.2225 31.7784 37.6693 35.0321 37.4356 35.8717C37.0967 37.0962 34.83 37.551 33.9421 37.2245C31.5468 36.3732 29.3386 31.7784 27.7612 29.7959C25.401 26.8338 23.0525 23.8484 20.7391 20.8397C19.6759 19.4636 16.3226 16.5365 16.1356 14.7872C15.5164 15.3003 15.5397 15.2537 16.2057 14.6239C16.4394 14.3907 16.6731 14.1575 16.9068 13.9242C17.6078 13.1779 18.3556 12.4665 19.0683 11.7435C19.9796 10.8222 18.5542 9.4111 17.6428 10.3207C16.4511 11.5219 15.2593 12.7231 14.0675 13.9242C13.7754 14.2158 13.6469 14.7872 13.9156 15.137C19.8628 23.2537 26.067 33.2012 33.7668 39.6968C34.0238 39.9184 34.3977 40.0816 34.7483 39.9534C36.3723 39.3586 38.1133 38.2274 39.1999 36.863C39.492 36.4898 39.5738 36.0817 39.3518 35.6502C36.9098 30.8222 32.5867 26.7522 29.1516 22.6006C25.7749 18.5306 22.3866 14.4607 19.0683 10.3324C18.2738 9.3178 16.86 10.7405 17.6545 11.7435Z'
        fill='#A693C4'
      />
      <path
        d='M13.5652 16.4432C16.0539 14.6589 18.3206 12.828 19.8862 10.1574C20.0615 9.85423 20.0732 9.44607 19.8862 9.14286C19.1852 8.04665 18.6477 7.58018 17.8649 6.414C17.7481 6.86881 17.6195 7.32362 17.5027 7.79009C19.5474 6.34403 21.6622 5.10788 23.4732 3.38193C23.9289 2.93878 23.8238 2.11079 23.2746 1.80759C18.5659 -0.781335 14.0442 -0.653055 9.72113 2.60059C8.30737 3.66181 5.70183 5.42275 5.37468 7.32362C5.07089 9.08455 6.33277 9.76094 7.60633 10.8805C7.75822 10.3207 7.89843 9.76094 8.05032 9.20117C5.64341 10.0525 3.10798 10.0525 0.747803 11.0204C0.163602 11.2653 -0.210287 11.8834 0.151918 12.4898C2.0681 15.7318 4.8489 20.07 8.08537 22.0758C8.54105 22.3557 9.19535 22.1808 9.46409 21.7143C10.9947 19.0554 11.2985 16.1516 11.8126 13.1778C11.2517 13.3295 10.6909 13.4694 10.1301 13.621C10.9012 14.3674 11.5906 15.1837 12.1981 16.0816C12.9459 17.1195 14.6868 16.1166 13.9274 15.0671C13.1913 14.0408 12.4201 13.1195 11.5438 12.2099C11.0531 11.6968 10.0016 11.8951 9.86134 12.6531C9.37061 15.4402 9.1603 18.2041 7.71148 20.7114C8.16716 20.5948 8.62284 20.4665 9.0902 20.3499C6.51971 18.7522 3.37671 14.0175 1.88115 11.4869C1.68253 11.9767 1.4839 12.4665 1.28527 12.9563C3.64544 11.9883 6.19256 11.9883 8.58778 11.137C9.25377 10.9038 9.63935 9.99417 9.03178 9.45773C6.41456 7.14869 9.97818 4.62974 11.9762 3.4519C15.4113 1.44607 18.8697 1.65598 22.2698 3.53353C22.1997 3.00875 22.1296 2.48397 22.0711 1.95919C20.3886 3.56852 18.3907 4.72304 16.4979 6.05248C16.0772 6.35569 15.8085 6.93878 16.1357 7.42858C18.4491 10.8455 15.2009 12.793 12.5487 14.7055C11.5088 15.4636 12.5136 17.2012 13.5652 16.4432Z'
        fill='#A693C4'
      />
    </svg>
  );
});

ToolsIcon.displayName = 'ToolsIcon';

ToolsIcon.propTypes = {};

ToolsIcon.defaultProps = {};

export default ToolsIcon;
