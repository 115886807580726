import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormWithSchema } from 'hooks/use-form-with-validation';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';
import { endorsersAboutEditorSchemaValidator } from 'utilities/validators';

const ProfileAboutEditorEndorser = memo((props) => {
  const { onCancel, onSave, profile } = props;

  const { t } = useTranslation();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useFormWithSchema(endorsersAboutEditorSchemaValidator);

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    return {
      shortBio: profile?.shortBio,
    };
  }, [ profile?.shortBio ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      shortBio: values?.shortBio,
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ onSave, valuesAreToBeUpdated ]);

  // the short bio error
  const shortBioError = useMemo(() => {
    if ('string.max' !== errors?.shortBio?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_about.description_error') }
      </span>
    );
  }, [ errors?.shortBio?.type, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('common:profile_about.editor_title_job_seekers') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        <div className='profile-editor__form-field'>
          <label>
            { t('common:profile_about.short_bio_label_job_seekers') }
            <textarea
              className={
                clsx({
                  'error': errors?.shortBio,
                })
              }
              defaultValue={ profile?.shortBio || '' }
              placeholder={ t('common:profile_about.short_bio_label_job_seekers') }
              rows={ 10 }
              { ...register('shortBio') }
            />
          </label>
          { shortBioError }
        </div>
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors?.shortBio) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileAboutEditorEndorser.displayName = 'ProfileAboutEditorEndorser';

ProfileAboutEditorEndorser.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // the user's profile
  profile: PropTypes.shape({
    // The id of the profile
    id: PropTypes.string,
    // The short bio of the job seeker.
    shortBio: PropTypes.string,
  }),
};

ProfileAboutEditorEndorser.defaultProps = {};

export default ProfileAboutEditorEndorser;
