/**
 * Greek translations for endorsers/dashboard-endorsements-section.
 *
 * @module languages/el/endorsers/dashboard-endorsements-section
 */
const dashboardEndorsementsSection = {
  claimed_endorsement_requests_no_results: 'Δεν έχετε άλλα αιτήματα για συστάσεις',
  claimed_endorsement_requests_title: 'Οι εκκρεμείς αιτήσεις μου για συστάσεις',
  created_endorsement_requests_no_results: 'Δεν έχετε κάποιο αίτημα για σύσταση',
  created_endorsement_requests_title: 'Αιτήματα για συστάσεις',
  view_all_button_title: 'Προβολή όλων',
  view_less_button_title: 'Δείτε λιγότερα',
};

export default dashboardEndorsementsSection;
