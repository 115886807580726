import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import LearnMoreEndorserActionCard from './learn-more-endorser-action-card/learn-more-endorser-action-card';

import './learn-more-what-does-endorser-do-section.scss';

const LearnMoreWhatDoesEndorserDoSection = memo((props) => {
  const { title, endorsersActions } = props;

  // The rendered cards
  const renderedEndorsersCards = useMemo(() => {
    return endorsersActions.map((card, index) => {
      return (
        <LearnMoreEndorserActionCard
          description={ card.description }
          icon={ card.icon }
          key={ card.description + index }
        />
      );
    });
  }, [ endorsersActions ]);

  return (
    <div className='learn-more-what-endorsers-do-section'>
      <h2 className='hdg hdg-lg learn-more-what-endorsers-do-section__title'>
        { title }
      </h2>
      <div className='learn-more-what-endorsers-do-section__cards-container'>
        { renderedEndorsersCards }
      </div>
    </div>
  );
});

LearnMoreWhatDoesEndorserDoSection.displayName = 'LearnMoreWhatDoesEndorserDoSection';

LearnMoreWhatDoesEndorserDoSection.propTypes = {
  // The endorsers cards actions
  endorsersActions: PropTypes.arrayOf(
    PropTypes.shape({
      // The card description
      description: PropTypes.string,
      // The card icon
      icon: PropTypes.node,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

LearnMoreWhatDoesEndorserDoSection.defaultProps = {};

export default LearnMoreWhatDoesEndorserDoSection;
