import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslationKey, isEmpty, isNotEmpty } from 'utilities/chisels';

import './question-box.scss';

const QuestionBox = memo((props) => {
  const { questionsAndAnswersObject, register, error } = props;

  const { t } = useTranslation();

  // The answers
  const answers = useMemo(() => {
    if ('motivationLevel' === questionsAndAnswersObject.id
      || 'communicationCooperationSkillsLevel' === questionsAndAnswersObject.id
      || 'criticalThinkingLevel' === questionsAndAnswersObject.id) {
      return questionsAndAnswersObject?.values?.map((value, index) => {
        return (
          <div
            className='question-box__answer'
            key={ value + index }
          >
            <span className='txt txt-sm'>
              { index + 1 }
            </span>
            <input
              className='question-box__radio'
              id={ value }
              name={ questionsAndAnswersObject.id }
              type='radio'
              value={ value }
              { ...register(questionsAndAnswersObject.id) }
            />
            <label
              className='txt txt-sm question-box__label'
              htmlFor={ value }
            >
              { t(`endorsement:form.${ getTranslationKey(questionsAndAnswersObject.id) }.answers.${ value }`) }
            </label>
          </div>
        );
      });
    }
    if ('professionalCharacteristics' === questionsAndAnswersObject.id
      || 'jobSeekerFeedbacks' === questionsAndAnswersObject.id) {
      return questionsAndAnswersObject?.values?.map((value, index) => {
        return (
          <div
            className='question-box__answer'
            key={ value + index }
          >
            <span className='txt txt-sm'>
              { index + 1 }
            </span>
            <input
              className='question-box__checkbox'
              id={ value }
              name={ questionsAndAnswersObject.id }
              type='checkbox'
              value={ value }
              { ...register(questionsAndAnswersObject.id) }
            />
            <label
              className='txt txt-sm question-box__label'
              htmlFor={ value }
            >
              { t(`endorsement:form.${ getTranslationKey(questionsAndAnswersObject.id) }.answers.${ value }`) }
            </label>
          </div>
        );
      });
    }
    return (
      <div
        className='question-box__answer'
      >
        <textarea
          className='question-box__textarea'
          id={ questionsAndAnswersObject.id }
          name={ questionsAndAnswersObject.id }
          placeholder={ t('endorsement:form.textarea_placeholder') }
          rows={ 10 }
          { ...register(questionsAndAnswersObject.id) }
        />
      </div>
    );
  }, [ questionsAndAnswersObject.id, questionsAndAnswersObject.values, register, t ]);

  // The secondary question section
  const secondaryQuestionSection = useMemo(() => {
    if (!questionsAndAnswersObject.questions?.secondary) {
      return null;
    }
    return (
      <p className='txt txt-sm question-box__questions-section--secondary'>
        { questionsAndAnswersObject.questions.secondary }
      </p>
    );
  }, [ questionsAndAnswersObject.questions?.secondary ]);

  // The error message
  const errorMessage = useMemo(() => {
    if (isEmpty(error)) {
      return null;
    }
    if ('string.max' === error?.type) {
      return (
        <span className='error-message'>
          { t('endorsement:form.textarea_error_message') }
        </span>
      );
    }
    return (
      <span className='error-message'>
        { t('endorsement:form.error_message') }
      </span>
    );
  }, [ error, t ]);

  return (
    <div className='question-box__container'>
      <div className={
        clsx('question-box', {
          'question-box__error': isNotEmpty(error),
        })
      }
      >
        <div className='question-box__questions-section'>
          <p className='txt txt-lg txt-bold'>
            { questionsAndAnswersObject.questions.primary }
          </p>
          { secondaryQuestionSection }
        </div>
        <div className='question-box__answers-section'>
          { answers }
        </div>
      </div>
      { errorMessage }
    </div>
  );
});

QuestionBox.displayName = 'QuestionBox';

QuestionBox.propTypes = {
  // The error
  error: PropTypes.shape({
    // The message
    message: PropTypes.string,
    // The ref
    ref: PropTypes.shape({
      // the name of the element
      name: PropTypes.string,
      // The type
      type: PropTypes.string,
    }),
    // The type
    type: PropTypes.string,
  }),
  // The questions and answers object
  questionsAndAnswersObject: PropTypes.shape({
    // The id of the entity
    id: PropTypes.string,
    // The questions object
    questions: PropTypes.shape({
      // The primary question
      primary: PropTypes.string,
      // The secondary question
      secondary: PropTypes.string,
    }),
    // The values of the specific question box
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  // The registry of the hook form
  register: PropTypes.func,
};

QuestionBox.defaultProps = {};

export default QuestionBox;
