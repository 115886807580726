/**
 * Greek translations for administrators/dashboard.
 *
 * @module languages/el/administrators/dashboard
 */
const dashboard = {
  approve_button_title: 'Επιβεβαίωση',
  approve_companies_confirmation_message: 'Είστε βέβαιοι ότι θέλετε να εγκρίνετε την εταιρεία {{companyName}};',
  approve_endorsers_confirmation_message: 'Είστε βέβαιοι ότι θέλετε να εγκρίνετε τον/τη υποστηριχτή {{endorserName}};',
  controlled_metrics: {
    collaborating_companies_metric_title: 'Συνεργαζόμενες εταιρίες',
    companies_metric_title: 'Εταιρίες',
    employability_services_per_year_metric_title: 'Υπηρεσίες απασχολησιμότητας ανά έτος',
    find_employability_per_year_metric_title: 'Βρήκαν εργασία ανά έτος',
    input_error_message: 'Αυτό το πεδίο είναι υποχρεωτικό',
    input_placeholder: '|...',
    job_positions_from_our_companies_metric_title: 'Θέσεις εργασίας από συνεργαζόμενες εταιρίες',
    matched_job_seekers_with_companies_metric_title: 'Διασυνδεμένοι υποψήφιοι με εταιρείες',
    organization_metrics_section_title: 'Δείκτες απόδοσης Odyssea ',
    people_hired_metric_title: 'Προσλήψεις',
    people_interviewed_metric_title: 'Συνεντεύξεις',
    profiles_metric_title: 'Προφίλ',
    registered_per_year_metric_title: 'Εγγεγραμμένοι ανά έτος',
    students_per_year_metric_title: 'Εκπαιδευόμενοι ανά έτος',
    talent_platform_metrics_section_title: 'Δείκτες απόδοσης Talent platform',
    title: 'Δείκτες ελεγχόμενοι από τον διαχειριστή',
  },
  failed_fetch_pending_companies: 'Απέτυχε η ανάκτηση εταιρειών σε εκκρεμότητα',
  failed_fetch_pending_endorsers: 'Απέτυχε η ανάκτηση των υποστηρικτών',
  failed_fetch_total_users: 'Η ανάκτηση του συνόλου των χρηστών απέτυχε',
  failed_to_update_controlled_metrics: 'Αποτυχία ενημέρωσης',
  general_error: 'Οπ!!  Κάτι πήγε λάθος...',
  no_pending_results: 'Δεν έχετε καμία εκκρεμή αίτηση',
  pending_companies_title: 'Εταιρίες',
  pending_endorsers_title: 'Υποστηρικτές',
  pending_title: 'Εκκρεμής αιτήσεις',
  title: 'Πίνακας ελέγχου διαχειριστή',
  total_companies_title: 'Εταιρίες',
  total_endorsers_title: 'Υποστηρικτές',
  total_job_seekers_title: 'Υποψήφιοι',
  total_users_title: 'Συνολικοί χρήστες',
  update_all_button_title: 'Ενημέρωση',
  view_all_button_title: 'Προβολή όλων',
  view_less_button_title: 'Εμφάνιση λιγότερων',
  view_total_companies_link_title: 'Προβολή εταιριών',
  view_total_endorsers_link_title: 'Προβολή υποστηρικτών',
  view_total_job_seekers_link_title: 'Προβολή υποψήφιων',
};

export default dashboard;
