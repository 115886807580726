/**
 * Greek translations for utilities/work-availabilities.
 *
 * @module languages/el/utilities/work-availabilities
 */
const workAvailabilities = {
  AVAILABLE_AFTER_A_MONTH: 'Διαθέσιμος/η για εργασία μετά από ένα μήνα',
  AVAILABLE_IN_LESS_THAN_A_MONTH: 'Διαθέσιμος/η για εργασία σε λιγότερο από ένα μήνα',
  AVAILABLE_NOW: 'Άμεσα διαθέσιμος/η για εργασία',
  NOT_AVAILABLE: 'Μη διαθέσιμος/η για εργασία',
};

export default workAvailabilities;
