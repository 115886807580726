/**
 * English translations for utilities/work-availabilities.
 *
 * @module languages/en/utilities/work-availabilities
 */
const workAvailabilities = {
  AVAILABLE_AFTER_A_MONTH: 'Available for work after a month',
  AVAILABLE_IN_LESS_THAN_A_MONTH: 'Available for work in less than a month',
  AVAILABLE_NOW: 'Available for work now',
  NOT_AVAILABLE: 'Not available for work',
};

export default workAvailabilities;
