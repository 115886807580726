import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowCardIcon from 'components/common/icons/arrow-card-icon/arrow-card-icon';
import BadgeCardIcon from 'components/common/icons/badge-card-icon/badge-card-icon';
import DiamondCardIcon from 'components/common/icons/diamond-card-icon/diamond-card-icon';
import FlagsCardIcon from 'components/common/icons/flags-card-icon/flags-card-icon';
import LampCardIcon from 'components/common/icons/lamp-card-icon/lamp-card-icon';
import MeterCardIcon from 'components/common/icons/meter-card-icon/meter-card-icon';
import StarCardIcon from 'components/common/icons/star-card-icon/star-card-icon';
import TraitsCardIcon from 'components/common/icons/traits-card-icon/traits-card-icon';
import * as Roles from 'utilities/auth/roles';

import HomeLearnMoreCardsSection from './home-learn-more-cards-section/home-learn-more-cards-section';

import './home-learn-more-section.scss';

const HomeLearnMoreSection = memo(() => {
  const { t } = useTranslation();

  // The job seekers cards collection
  const jobSeekersCardsCollection = useMemo(() => {
    return [
      {
        description: t('home:learn_more_section.job_seekers_cards.item1.description'),
        icon: <ArrowCardIcon />,
        title: t('home:learn_more_section.job_seekers_cards.item1.title'),
      },
      {
        description: t('home:learn_more_section.job_seekers_cards.item2.description'),
        icon: <TraitsCardIcon />,
        title: t('home:learn_more_section.job_seekers_cards.item2.title'),
      },
      {
        description: t('home:learn_more_section.job_seekers_cards.item3.description'),
        icon: <FlagsCardIcon />,
        title: t('home:learn_more_section.job_seekers_cards.item3.title'),
      },
      {
        description: t('home:learn_more_section.job_seekers_cards.item4.description'),
        icon: <StarCardIcon />,
        title: t('home:learn_more_section.job_seekers_cards.item4.title'),
      },
    ];
  }, [ t ]);

  // The companies cards collection
  const companiesCardCollection = useMemo(() => {
    return [
      {
        description: t('home:learn_more_section.companies_cards.item1.description'),
        icon: <LampCardIcon />,
        title: t('home:learn_more_section.companies_cards.item1.title'),
      },
      {
        description: t('home:learn_more_section.companies_cards.item2.description'),
        icon: <DiamondCardIcon />,
        title: t('home:learn_more_section.companies_cards.item2.title'),
      },
      {
        description: t('home:learn_more_section.companies_cards.item3.description'),
        icon: <MeterCardIcon />,
        title: t('home:learn_more_section.companies_cards.item3.title'),
      },
      {
        description: t('home:learn_more_section.companies_cards.item4.description'),
        icon: <BadgeCardIcon />,
        title: t('home:learn_more_section.companies_cards.item4.title'),
      },
    ];
  }, [ t ]);

  return (
    <div className='learn-more-section'>
      <div className='learn-more-section__titles-section'>
        <h1 className='hdg hdg-xxl learn-more-section__title'>
          { t('home:learn_more_section.title') }
        </h1>
        <p className='txt learn-more-section__sub-title'>
          { t('home:learn_more_section.subtitle') }
        </p>
      </div>
      <div className='learn-more-section__cards-section'>
        <HomeLearnMoreCardsSection
          cardsCollection={ jobSeekersCardsCollection }
          learnMoreButtonLink='/job-seekers/learn-more'
          role={ Roles.JOB_SEEKER }
          title={ t('home:learn_more_section.job_seeker_card_section_title') }
        />
        <HomeLearnMoreCardsSection
          cardsCollection={ companiesCardCollection }
          learnMoreButtonLink='/companies/learn-more'
          role={ Roles.COMPANY_AGENT }
          title={ t('home:learn_more_section.company_card_section_title') }
        />
      </div>
    </div>
  );
});

HomeLearnMoreSection.displayName = 'HomeLearnMoreSection';

HomeLearnMoreSection.propTypes = {};

HomeLearnMoreSection.defaultProps = {};

export default HomeLearnMoreSection;
