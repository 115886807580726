import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './about-us-target-group-section.scss';

const AboutUsTargetGroupSection = memo((props) => {
  const { title, cards } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // The rendered cards
  const renderedCards = useMemo(() => {
    return cards.map((card, cardIndex) => {
      return (
        <div
          className={
            clsx('about-us-target-group-section__card', {
              'about-us-target-group-section__card--visible': isVisible,
            })
          }
          key={ `Target Group Card - ${ cardIndex }` }
          ref={ containerRef }
        >
          { card?.text || card?.image }
        </div>
      );
    });
  }, [ cards, containerRef, isVisible ]);

  return (
    <div className='about-us-target-group-section'>
      <h3 className='hdg hdg-lg about-us-target-group-section__title'>
        { title }
      </h3>
      <div className='about-us-target-group-section__cards-container'>
        { renderedCards }
      </div>
    </div>
  );
});

AboutUsTargetGroupSection.displayName = 'AboutUsTargetGroupSection';

AboutUsTargetGroupSection.propTypes = {
  // The section cards
  cards: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string,
      }),
      PropTypes.shape({
        image: PropTypes.node,
      }),
    ])
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsTargetGroupSection.defaultProps = {};

export default AboutUsTargetGroupSection;
