/**
 * Resource method to get company public profiles.
 *
 * @module resources/companies/get-public-profile
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/get-public-profile~Params
 * @type {object}
 * @property {string} id - The ID of the company.
 */

/**
 * Result.
 *
 * @typedef {module:types/companies/profiles~Profile} Result
 */

/**
 * Gets the public profile of a company agent based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/get-public-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/companies/get-public-profile~Result>} - The promise.
 * @static
 */
const getPublicProfile = (params) => {
  return axios.request({
    method: 'get',
    url: `/companies/${ params.id }/profile/public`,
  }).then((response) => {
    return response.data;
  });
};

export default getPublicProfile;
