/**
 * English translations for common/profile-image.
 *
 * @module languages/en/common/profile-image
 */
const profileImage = {
  cancel_button_label: 'Cancel',
  change_button_label: 'Change',
  delete_button_label: 'Delete',
  editor_title_company_agent: 'Logo',
  editor_title_job_seeker: 'Profile picture',
  no_logo_message: 'Upload your logo here.',
  no_picture_message: 'Upload your profile picture here.',
  save_button_label: 'Save',
  too_large_image_error: 'Upload an image up to 8MB.',
  unsupported_image_format_error: 'Upload an image in JPEG or PNG format.',
  upload_button_label: 'Upload',
};

export default profileImage;
