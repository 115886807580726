/**
 * Resource method to get job seeker resumes.
 *
 * @module resources/job-seekers/get-resume
 */
import axios from 'axios';

import { extractFilename } from 'utilities/chisels';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/get-resume~Params
 * @type {object}
 * @property {string} id - The ID of the job seeker.
 */

/**
 * Result.
 *
 * @typedef {module:types/common~Content} Result
 */

/**
 * Gets the resume of a job seeker based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/get-resume~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/get-resume~Result>} - The promise.
 * @static
 */
const getResume = (params) => {
  return axios.request({
    method: 'get',
    raw: true,
    responseType: 'blob',
    url: `/job-seekers/${ params.id }/profile/resume`,
  }).then((response) => {
    return {
      data: response.data,
      filename: extractFilename(response.headers['content-disposition']),
      type: response.headers['content-type'],
    };
  });
};

export default getResume;
