/**
 * English translations for utilities/job-seekers/filter-types.
 *
 * @module languages/en/utilities/job-seekers/filter-types
 */
const filterTypes = {
  COMPUTER_SKILLS_LEVEL: 'Computer skills',
  DRIVING_LICENSE_CATEGORY: 'Driving license',
  EXPERIENCE_LENGTH: 'Experience (in years)',
  LANGUAGE: 'Language',
  PROFESSION: 'Job interested in',
  PROFILE_COMPLETION_PERCENTAGE: 'Profile completion (%)',
  QUALIFICATION_TYPE: 'Qualification',
  WILLING_TO_RELOCATE: 'Willing to relocate',
  WORK_AVAILABILITY: 'Work availability',
};

export default filterTypes;
