/**
 * Work availabilities.
 *
 * @module utilities/work-availabilities
 */

/**
 * Available for work after a month.
 *
 * @constant {string}
 * @static
 */
export const AVAILABLE_AFTER_A_MONTH = 'AVAILABLE_AFTER_A_MONTH';

/**
 * Available for work in less than a month.
 *
 * @constant {string}
 * @static
 */
export const AVAILABLE_IN_LESS_THAN_A_MONTH = 'AVAILABLE_IN_LESS_THAN_A_MONTH';

/**
 * Available for work now.
 *
 * @constant {string}
 * @static
 */
export const AVAILABLE_NOW = 'AVAILABLE_NOW';

/**
 * Not available for work.
 *
 * @constant {string}
 * @static
 */
export const NOT_AVAILABLE = 'NOT_AVAILABLE';

export default [
  AVAILABLE_AFTER_A_MONTH,
  AVAILABLE_IN_LESS_THAN_A_MONTH,
  AVAILABLE_NOW,
  NOT_AVAILABLE,
];
