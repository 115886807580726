/* eslint-disable */
import React, { memo } from 'react';

import './steps-icon.scss';

const StepsIcon = memo(() => {
  return (
    <svg
      className='steps-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 938 315'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7173_16000)'>
        <path
          d='M7.70354 296.044C-1.51453 298.512 -2.59901 301.56 5.14193 306.104C17.0899 313.097 44.5572 315.303 60.5439 313.62C76.6989 311.919 88.759 304.253 69.6311 298.138C52.8029 292.772 11.1253 291.426 1.68281 299.129'
          fill='#74ABC3'
        />
        <path
          d='M926.931 191.579C911.785 187.914 877.699 189.672 869.36 192.458C861.413 195.113 857.468 199.9 865.508 203.863C877.942 209.978 905.484 210.221 921.303 207.397C937.289 204.518 946.451 196.291 926.931 191.579Z'
          fill='#74ABC3'
        />
        <path
          d='M518.736 242.886C527.842 240.044 528.796 236.959 520.868 232.733C508.64 226.226 481.116 225.142 465.204 227.479C449.143 229.835 437.382 237.987 456.753 243.316C473.787 247.991 515.483 247.635 524.608 239.558'
          fill='#74ABC3'
        />
        <path
          d='M38.7234 303.392C41.1167 302.831 41.9768 295.315 42.5191 293.557C45.5668 283.666 49.5121 274.055 54.1679 264.819C72.2862 228.881 100.688 198.927 133.466 175.91C203.471 126.716 293.838 120.863 372.93 151.21C416.085 167.776 455.22 195.562 481.49 233.948C482.65 235.65 485.435 234.042 484.257 232.322C438.522 165.514 355.952 130.624 276.598 129.296C191.691 127.875 110.841 170.6 63.8721 241.708C55.5328 254.329 48.4837 267.81 43.1174 281.965C41.4907 286.246 40.0322 290.566 38.7421 294.96C38.3307 296.362 37.9568 297.783 37.5454 299.185C37.0406 300.868 36.6105 300.569 37.882 300.289C35.8626 300.775 36.7227 303.86 38.7234 303.392Z'
          fill='#222222'
        />
        <path
          d='M901.876 197.057C894.284 161.4 871.884 126.996 850.643 98.1267C824.167 62.1706 790.716 27.355 748.927 9.68551C659.7 -28.0282 586.423 51.7184 544.035 120.957C522.513 156.128 504.433 193.841 490.577 232.714C489.886 234.659 492.989 235.5 493.681 233.574C505.162 201.377 519.671 170.132 536.406 140.347C557.479 102.82 582.796 66.1532 616.003 38.312C648.5 11.0318 689.729 -4.78668 731.968 7.1052C776.076 19.5206 811.976 54.0183 839.724 89.0956C854.645 107.98 867.939 128.23 879.214 149.49C887.31 164.747 895.144 180.921 898.772 197.917C899.202 199.937 902.306 199.077 901.876 197.057Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_7173_16000'>
          <rect fill='white' height='314.163' width='938' />
        </clipPath>
      </defs>
    </svg>
  );
});

StepsIcon.displayName = 'StepsIcon';

StepsIcon.propTypes = {};

StepsIcon.defaultProps = {};

export default StepsIcon;
