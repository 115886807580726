/**
 * Greek translations for companies/profile-pricing-section.
 *
 * @module languages/el/companies/profile-pricing-section
 */
const profilePricingSection = {
  address_label: 'Διεύθυνση',
  address_placeholder: 'Διεύθυνση',
  editor_title: 'Τιμολόγηση',
  legal_name_label: 'Νόμιμο όνομα',
  legal_name_placeholder: 'Νόμιμο όνομα',
  occupation_label: 'Απασχόληση',
  occupation_placeholder: 'Απασχόληση',
  pricing_plan_label: 'Πακέτα τιμών',
  pricing_plan_placeholder: 'Διάλεξε το πακέτο τιμών',
  tax_office_label: 'ΔΟΥ',
  tax_office_placeholder: 'ΔΟΥ',
  title: 'Τιμολόγηση',
  vat_number_label: 'ΑΦΜ',
  vat_number_placeholder: 'ΑΦΜ',
};

export default profilePricingSection;
