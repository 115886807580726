/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const LampCardIcon = memo((props) => {
  const { isPurple } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8256 39.3528C16.7825 34.7349 15.2337 32.8992 13.1398 28.984C11.0173 25.0258 9.66918 19.6334 12.853 15.8329C14.9468 13.3375 18.8334 11.9034 22.0315 11.9607C25.6168 12.0181 29.4317 13.8395 31.5542 16.6934C34.738 20.9958 33.3612 25.0114 30.4212 28.898C27.9115 32.2252 27.1514 35.0074 26.9362 39.1521C26.8645 40.5288 29.0158 40.5288 29.0875 39.1521C29.2739 35.6384 29.7185 33.2291 31.9414 30.4181C32.8736 29.2278 34.1069 28.1379 34.7953 26.7611C36.1004 24.1366 35.9426 21.0962 34.9531 18.3857C33.06 13.1941 27.4382 9.89558 22.0171 9.80953C17.1984 9.73782 11.6196 12.3766 9.5688 16.9372C5.81135 25.3269 14.5883 31.5368 14.6456 39.3528C14.6887 40.7296 16.8399 40.744 16.8256 39.3528Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M22.7344 39.611C22.7487 35.9969 22.591 32.1535 22.2038 28.5538C21.9743 26.3595 21.372 23.4912 19.0917 22.516C15.3772 20.9241 13.6706 26.6177 17.3133 27.9658C18.6184 28.4534 19.1777 26.3739 17.887 25.8863C18.7331 24.2944 19.3355 24.5668 19.7227 26.6894C19.9808 27.6502 20.1243 28.6255 20.1529 29.6294C20.239 30.6763 20.2533 31.7232 20.282 32.7845C20.3394 35.0504 20.5975 37.3307 20.5832 39.5967C20.5832 41.0021 22.7344 41.0021 22.7344 39.611Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M22.935 39.6828C22.8777 37.0009 23.4657 34.4195 23.8672 31.7806C24.025 30.705 24.1684 29.6151 24.3405 28.5395C24.4266 27.9945 24.5126 27.4639 24.6273 26.9332C24.5843 25.8576 25.1866 25.6138 26.4487 26.2018C25.1006 26.3883 25.0719 28.5395 26.4487 28.353C30.9232 27.765 29.1449 21.3688 25.201 22.4587C22.6769 23.1614 22.5048 26.4313 22.1606 28.5108C21.5726 32.1822 20.6834 35.954 20.7695 39.6828C20.8125 41.0595 22.9637 41.0739 22.935 39.6828Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M13.3845 41.418C16.2815 41.3463 19.2214 41.4324 22.1184 41.418C23.6386 41.418 25.1445 41.5758 26.6503 41.6331C27.4534 41.6618 28.6294 41.418 29.3752 41.7048C30.6659 42.1924 31.2252 40.1129 29.9488 39.6253C28.1705 38.9513 25.0584 39.3242 23.2084 39.2811C19.9672 39.1951 16.6113 39.1808 13.3701 39.2668C12.0077 39.2955 11.9934 41.4467 13.3845 41.418Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M13.1831 44.5014C16.539 44.5014 19.938 44.3293 23.2795 44.6305C25.359 44.817 27.711 45.3619 29.7618 44.7166C31.0813 44.3007 30.5219 42.2355 29.1882 42.6371C27.0083 43.3111 24.3838 42.508 22.1896 42.3789C19.2065 42.2068 16.1805 42.3359 13.1975 42.3359C11.8064 42.3502 11.8064 44.5014 13.1831 44.5014Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M4.86508 24.6386C4.86508 24.6099 4.86508 24.5956 4.86508 24.5669C4.86508 23.1758 2.71387 23.1758 2.71387 24.5669C2.71387 24.5956 2.71387 24.6099 2.71387 24.6386C2.71387 26.0297 4.86508 26.0297 4.86508 24.6386Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M5.81173 15.5461C4.59271 14.5852 3.08685 14.1263 1.85349 13.2084C1.38022 12.8642 0.663154 13.1367 0.376326 13.5956C0.046474 14.1406 0.304625 14.7286 0.76355 15.0728C1.8535 15.8759 3.23026 16.2345 4.29152 17.0663C4.75045 17.4248 5.36715 17.5109 5.81173 17.0663C6.18461 16.6934 6.25631 15.9046 5.81173 15.5461Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M33.6775 9.95291C34.2655 8.67652 35.4988 7.77302 36.388 6.71176C36.9903 5.99468 37.8939 5.14854 37.9656 4.15899C38.0516 2.78221 35.9004 2.78221 35.8143 4.15899C35.843 3.68572 35.757 4.10161 35.6279 4.25936C35.4845 4.43146 35.3554 4.60356 35.212 4.77566C34.9108 5.13419 34.624 5.50707 34.3085 5.85126C33.4337 6.82648 32.3724 7.64394 31.8131 8.8773C31.2538 10.1107 33.1038 11.2006 33.6775 9.95291Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M37.1904 14.1693C37.9074 13.5957 38.6389 13.0651 39.4707 12.6922C40.0013 12.4484 40.116 11.6883 39.8579 11.215C39.5424 10.67 38.9114 10.5983 38.3807 10.8278C37.3768 11.2724 36.5163 11.9608 35.6702 12.6348C35.2113 12.9933 35.2973 13.7678 35.6702 14.155C36.1148 14.6283 36.7458 14.5279 37.1904 14.1693Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M39.184 19.9346C40.5751 19.9346 40.5751 17.7834 39.184 17.7834C37.8072 17.7834 37.8072 19.9346 39.184 19.9346Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M24.9437 6.53967C26.3349 6.53967 26.3349 4.38843 24.9437 4.38843C23.5526 4.38843 23.5526 6.53967 24.9437 6.53967Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M34.7245 34.8497C36.1156 34.8497 36.1156 32.6985 34.7245 32.6985C33.3477 32.6985 33.3334 34.8497 34.7245 34.8497Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M10.5023 2.15119C11.8934 2.15119 11.8934 0 10.5023 0C9.11121 0 9.11121 2.15119 10.5023 2.15119Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
      <path
        d='M28.7435 20.4652C30.1346 20.4652 30.1346 18.314 28.7435 18.314C27.3667 18.314 27.3667 20.4652 28.7435 20.4652Z'
        fill={ isPurple ? '#A693C4' : '#161616' }
      />
    </svg>
  );
});

LampCardIcon.displayName = 'LampCardIcon';

LampCardIcon.propTypes = {
  // Whether the icon is purple
  isPurple: PropTypes.bool,
};

LampCardIcon.defaultProps = {
  isPurple: false,
};

export default LampCardIcon;
