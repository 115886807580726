/**
 * Slider.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BaseSlider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import raf from 'rc-util/lib/raf';
import React, { useEffect, useState } from 'react';

import './slider.scss';

const HandleWithTooltip = (props) => {
  // Reference to the entry identifier returned by raf().
  // NOTE: raf = Request Animation Frame.
  const rafRef = React.useRef(undefined);
  // Reference to the tooltip.
  const tooltipRef = React.useRef(undefined);

  // Function that stops the ongoing attempt to keep the handle and the tooltip aligned.
  const stopAligning = () => {
    if (undefined !== rafRef.current) {
      raf.cancel(rafRef.current);
    }
  };

  // Function that aligns the tooltip with the handle.
  const align = () => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  };

  // Keep the tooltip aligned with the handle as the latter moves back and forth.
  React.useEffect(() => {
    if (props.visible) {
      align();
    } else {
      stopAligning();
    }

    return () => {
      stopAligning();
    };
  }, [ props.value, props.visible ]);

  return (
    <Tooltip
      keepAlign={ true }
      overlay={ props.value }
      overlayClassName='ody-slider-tooltip'
      placement='top'
      ref={ tooltipRef }
      showArrow={ false }
      visible={ props.visible }
    >
      { props.children }
    </Tooltip>
  );
};
HandleWithTooltip.propTypes = {
  // The handle.
  children: PropTypes.node.isRequired,
  // The value of the handle.
  value: PropTypes.number,
  // Whether the tooltip is visible.
  visible: PropTypes.bool,
};

const Slider = (props) => {
  // The value of the slider.
  const [ value, setValue ] = useState(undefined);

  // Set the value.
  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  const extraProps = 'double' === props.type && {
    count: 1,
    range: true,
  };

  return (
    <BaseSlider
      className={ clsx('ody-slider', props.type, props.className) }
      disabled={ props.disabled }
      handleRender={
        (origin, handleProps) => {
          return (
            <HandleWithTooltip
              value={ handleProps.value }
              visible={ handleProps.dragging }
            >
              { origin }
            </HandleWithTooltip>
          );
        }
      }
      marks={
        undefined === props.value ? {
          [props.max]: props.max,
          [props.min]: props.min,
        } : ('number' === typeof(props.value) ? [ props.value ] : props.value || []).reduce((acc, v) => {
          return { ...acc, [v]: v };
        }, {})
      }
      max={ props.max }
      min={ props.min }
      onAfterChange={ props.onChange }
      onChange={
        (value) => {
          setValue(value);
        }
      }
      step={ props.step || 1 }
      value={ value }
      { ...extraProps }
    />
  );
};

Slider.propTypes = {
  // The class(es) to apply to the slider.
  className: PropTypes.string,
  // Whether the slider is disabled.
  disabled: PropTypes.bool,
  // The maximum value of the slider.
  max: PropTypes.number.isRequired,
  // The minimum value of the slider.
  min: PropTypes.number.isRequired,
  // The function ((number | number[]) => void) to invoke when the value changes.
  onChange: PropTypes.func,
  // The step of the slider.
  step: PropTypes.number,
  // The type of the slider.
  type: PropTypes.oneOf([ 'double' ]).isRequired,
  // The value of the slider.
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

Slider.defaultProps = {
  disabled: false,
  step: 1,
  suffix: '',
};

export default Slider;
