/**
 * Greek translations for utilities/companies/filter-types.
 *
 * @module languages/el/utilities/companies/filter-types
 */
const filterTypes = {
  NUMBER_OF_EMPLOYEES: 'Αριθμός υπαλλήλων',
  PROFESSION: 'Εργασία που σας ενδιαφέρει',
};

export default filterTypes;
