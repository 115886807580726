/**
 * Greek translations for utilities/companies/sort-keys.
 *
 * @module languages/el/utilities/companies/sort-keys
 */
const sortKeys = {
  COMPANY_NAME: 'Όνομα εταιρίας',
  LAST_MODIFIED_AT: 'Τελευταία ενημέρωση',
};

export default sortKeys;
