/**
 * Resource method for a job seeker to request an endorsement.
 *
 * @module resources/job-seekers/request-endorsement
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/common~EndorsementRequest} Result
 */

/**
 * Job-seeker requests an endorsement.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/job-seekers/request-endorsement~Result>} - The promise.
 * @static
 */
const requestEndorsement = () => {
  return axios.request({
    method: 'post',
    url: '/endorsement-requests/',
  }).then((response) => {
    return response.data;
  });
};

export default requestEndorsement;
