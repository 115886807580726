/**
 * Hooks for media queries.
 *
 * @module hooks/media-queries
 */

import { useEffect, useState } from 'react';

/**
 * Checks whether the current document matches the given media query.
 *
 * @param {string} mediaQuery - The media query (as a string).
 * @returns {boolean} - Whether the current document matches the media query.
 * @static
 */
const _useMediaQuery = (mediaQuery) => {
  // Function that checks whether the current document matches the given media query.
  const _matches = (q) => {
    return 'undefined' === typeof window ? false : window.matchMedia(q).matches;
  };

  // Whether the current document matches the given media query.
  const [ matches, setMatches ] = useState(_matches(mediaQuery));

  // Set everything up.
  useEffect(() => {
    // Function that handles screen size changes.
    const handleChange = () => {
      setMatches(_matches(mediaQuery));
    };

    const result = window.matchMedia(mediaQuery);

    // Handle the first load.
    handleChange();

    // Handle any screen size change.
    result.addEventListener('change', handleChange);

    return () => {
      result.removeEventListener('change', handleChange);
    };
  }, [ mediaQuery ]);

  return matches;
};

/**
 * Checks whether the current screen size falls in the xs breakpoint.
 *
 * @returns {boolean} - Whether the current screen size falls in the xs breakpoint.
 * @static
 */
const useXs = () => {
  return _useMediaQuery('(max-width: 594px)');
};

/**
 * Checks whether the current screen size falls in the sm breakpoint.
 *
 * @returns {boolean} - Whether the current screen size falls in the sm breakpoint.
 * @static
 */
const useSm = () => {
  return _useMediaQuery('(min-width: 595px) and (max-width: 834px');
};

/**
 * Checks whether the current screen size falls in the md breakpoint.
 *
 * @returns {boolean} - Whether the current screen size falls in the md breakpoint.
 * @static
 */
const useMd = () => {
  return _useMediaQuery('(min-width: 835px) and (max-width: 1440px');
};

/**
 * Checks whether the current screen size falls in the lg breakpoint.
 *
 * @returns {boolean} - Whether the current screen size falls in the lg breakpoint.
 * @static
 */
const useLg = () => {
  return _useMediaQuery('(min-width: 1441px)');
};

export {
  useLg,
  useMd,
  useSm,
  useXs,
};
