/**
 * LinkedIn Sign-up form.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import env from 'config/env';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import LinkedIn from 'images/linkedin.png';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as toasts from 'toasts';
import roles, * as Roles from 'utilities/auth/roles';
import { isNotEmpty, omit } from 'utilities/chisels';
import {
  companiesSignUpOAuthSchemaValidator,
  endorsersSignUpOAuthSchemaValidator,
  jobSeekersSignUpOAuthSchemaValidator,
} from 'utilities/validators';

const LinkedInSignUpForm = memo((props) => {
  const { role, handleCreated, accessToken, predefinedUsersFields, resetAuthenticationAuthorizationFlow } = props;

  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  // Whether the recaptcha is successfully submitted
  const [ recaptchaSubmitted, setRecaptchaSubmitted ] = useState(false);

  // The validation schema depending on the role
  const validationSchema = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersSignUpOAuthSchemaValidator;
    case Roles.COMPANY_AGENT:
      return companiesSignUpOAuthSchemaValidator;
    case Roles.ENDORSER:
      return endorsersSignUpOAuthSchemaValidator;
    default:
      return jobSeekersSignUpOAuthSchemaValidator;
    }
  }, [ role ]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useFormWithSchema(validationSchema);

  // Set the value of predefined user's fields when rendering
  useEffect(() => {
    // Reset the recaptcha every time the form is rendering
    recaptchaRef.current.reset();
    // set the email
    setValue('email', predefinedUsersFields?.email ?? '');
    // set the first name
    setValue('firstName', predefinedUsersFields?.firstName ?? '');
    // Set the last name
    setValue('lastName', predefinedUsersFields?.lastName ?? '');
  }, [
    predefinedUsersFields?.email,
    predefinedUsersFields?.firstName,
    predefinedUsersFields?.lastName,
    setValue,
  ]);

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The sign up params values
  const signUpParams = useCallback((values) => {
    if ('' === values.companyName) {
      delete values.companyName;
    }
    if ('' === values.companyPosition) {
      delete values.companyPosition;
    }
    if ('' === values.mobilePhoneNumber) {
      delete values.mobilePhoneNumber;
    }
    return {
      ...omit(values, [
        'agreedWithPrivacyPolicy',
        'agreedWithTermsOfService',
      ]),
      accessToken,
      email: values.email.toLowerCase(),
      language: selectedLanguage,
    };
  }, [ accessToken, selectedLanguage ]);

  // The sign up error
  const setSignUpError = useCallback((error) => {
    switch (error.message) {
    case 'USER_ALREADY_EXISTS':
      toasts.error(t('sign_up:user_already_exists_error'));
      break;
    case 'FAILED_LINKEDIN_VERIFICATION':
      toasts.error(t('sign_up:verify_with_linkedin_error'));
      break;
    default:
      toasts.error(t('sign_up:general_error'));
      break;
    }
    resetAuthenticationAuthorizationFlow();
  }, [ resetAuthenticationAuthorizationFlow, t ]);

  // function that handles the sign up as a job seeker via LinkedIn
  const linkedInSignUpAsAJobSeeker = useCallback((values) => {
    dispatch(requestsActions.request(jobSeekersMethods.linkedInSignUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);

  // function that handles the sign up as a company agent via LinkedIn
  const linkedInSignUpAsACompanyAgent = useCallback((values) => {
    dispatch(requestsActions.request(companiesMethods.linkedInSignUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);

  // function that handles the sign up as an endorser via LinkedIn
  const linkedInSignUpAsAnEndorser = useCallback((values) => {
    dispatch(requestsActions.request(endorsersMethods.linkedInSignUp,
      signUpParams(values),
      {
        onFailure: (error) => {
          setSignUpError(error);
          handleCreated(false);
        },
        onSuccess: (_result) => {
          handleCreated(true);
        },
      }));
  }, [ dispatch, handleCreated, setSignUpError, signUpParams ]);

  // function that triggers when the handleSubmit from react hook form is triggering
  const onSubmit = useCallback((values) => {
    if (!recaptchaSubmitted) {
      return;
    }
    switch (role) {
    case Roles.JOB_SEEKER:
      linkedInSignUpAsAJobSeeker(values);
      break;
    case Roles.COMPANY_AGENT:
      linkedInSignUpAsACompanyAgent(values);
      break;
    case Roles.ENDORSER:
      linkedInSignUpAsAnEndorser(values);
      break;
    default:
      linkedInSignUpAsAJobSeeker(values);
      break;
    }
  }, [
    linkedInSignUpAsACompanyAgent,
    linkedInSignUpAsAJobSeeker,
    linkedInSignUpAsAnEndorser,
    recaptchaSubmitted,
    role,
  ]);

  // company name empty string error
  const companyNameEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.companyName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:company_name_required_error') }
      </span>
    );
  }, [ errors.companyName?.type, t ]);

  // The company name input
  const companyNameInput = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='ody-sign-up__form-control'>
        <label htmlFor='companyName'>
          { t('sign_up:company_name_label') }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.companyName),
            })
          }
          id='companyName'
          maxLength={ 256 }
          name='companyName'
          placeholder={ t('sign_up:company_name_placeholder') }
          type='text'
          { ...register('companyName') }
        />
        { companyNameEmptyStringError }
      </div>
    );
  }, [ companyNameEmptyStringError, errors.companyName, register, role, t ]);

  // the empty phone number error
  const emptyPhoneNumberError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // the mobile phone number register string
  const mobilePhoneNumberRegisterString = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return 'phoneNumber';
    }
    return 'mobilePhoneNumber';
  }, [ role ]);

  // The phone number pattern error
  const phoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:phone_number_pattern_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // The phone number label
  const phoneNumberLabel = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return t('sign_up:phone_number_label');
    }
    return t('sign_up:phone_number_label_endorsers');
  }, [ role, t ]);

  // The phone number input
  const phoneNumberInput = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='ody-sign-up__form-control'>
        <label htmlFor={ mobilePhoneNumberRegisterString }>
          { phoneNumberLabel }
        </label>
        <input
          className={
            clsx({
              'error': isNotEmpty(errors.phoneNumber),
            })
          }
          id={ mobilePhoneNumberRegisterString }
          maxLength={ 14 }
          name={ mobilePhoneNumberRegisterString }
          placeholder={ t('sign_up:phone_number_placeholder') }
          type='text'
          { ...register(mobilePhoneNumberRegisterString) }
        />
        { emptyPhoneNumberError }
        { phoneNumberPatternError }
      </div>
    );
  }, [
    emptyPhoneNumberError,
    errors.phoneNumber,
    mobilePhoneNumberRegisterString,
    phoneNumberLabel,
    phoneNumberPatternError,
    register,
    role,
    t,
  ]);

  // function that is invoked when user has successfully added recaptcha
  const handleRecaptchaChange = useCallback((token) => {
    if (!token) {
      return;
    }
    setRecaptchaSubmitted(true);
  }, []);

  // function that is invoked when recaptcha expires
  const handleRecaptchaExpire = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // function that is invoked when recaptcha has error
  const handleRecaptchaError = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // The endorser's optional company info inputs
  const endorsersCompanyInputs = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <>
        <div className='ody-sign-up__form-control'>
          <label htmlFor='companyName'>
            { t('sign_up:endorser_company_name_label') }
          </label>
          <input
            id='companyName'
            maxLength={ 256 }
            name='companyName'
            placeholder={ t('sign_up:company_name_placeholder') }
            type='text'
            { ...register('companyName') }
          />
        </div>
        <div className='ody-sign-up__form-control'>
          <label htmlFor='companyPosition'>
            { t('sign_up:endorser_company_position_label') }
          </label>
          <input
            id='companyPosition'
            maxLength={ 256 }
            name='companyPosition'
            placeholder={ t('sign_up:company_position_placeholder') }
            type='text'
            { ...register('companyPosition') }
          />
        </div>
      </>
    );
  }, [ register, role, t ]);

  // The 'agreed with terms of service' error
  const agreedWithTermsOfServiceError = useMemo(() => {
    if ('any.only' !== errors.agreedWithTermsOfService?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:must_agree_with_terms_of_service_error') }
      </span>
    );
  }, [ errors.agreedWithTermsOfService?.type, t ]);

  // The 'agreed with privacy policy' error
  const agreedWithPrivacyPolicyError = useMemo(() => {
    if ('any.only' !== errors.agreedWithPrivacyPolicy?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('sign_up:must_agree_with_privacy_policy_error') }
      </span>
    );
  }, [ errors.agreedWithPrivacyPolicy?.type, t ]);

  return (
    <form
      className='ody-sign-up__form'
      noValidate
      onSubmit={ handleSubmit(onSubmit) }
    >
      { companyNameInput }
      <div className='ody-sign-up__form-control'>
        <label htmlFor='firstName'>
          { t('sign_up:first_name_label') }
        </label>
        <input
          defaultValue={ predefinedUsersFields?.firstName ?? '' }
          disabled
          id='firstName'
          maxLength={ 256 }
          name='firstName'
          placeholder={ t('sign_up:first_name_placeholder') }
          type='text'
          { ...register('firstName') }
        />
      </div>
      <div className='ody-sign-up__form-control'>
        <label htmlFor='lastName'>
          { t('sign_up:last_name_label') }
        </label>
        <input
          defaultValue={ predefinedUsersFields?.lastName ?? '' }
          disabled
          id='lastName'
          maxLength={ 256 }
          name='lastName'
          placeholder={ t('sign_up:last_name_placeholder') }
          type='text'
          { ...register('lastName') }
        />
      </div>
      <div className='ody-sign-up__form-control'>
        <label htmlFor='email'>
          { t('sign_up:email_label') }
        </label>
        <input
          defaultValue={ predefinedUsersFields?.email ?? '' }
          disabled
          id='email'
          maxLength={ 256 }
          name='email'
          placeholder={ t('sign_up:email_placeholder') }
          type='text'
          { ...register('email') }
        />
      </div>
      { phoneNumberInput }
      { endorsersCompanyInputs }
      <div className='ody-sign-up__form-control'>
        <ReCAPTCHA
          hl={ selectedLanguage }
          onChange={ handleRecaptchaChange }
          onErrored={ handleRecaptchaError }
          onExpired={ handleRecaptchaExpire }
          ref={ recaptchaRef }
          sitekey={ env.RECAPTCHA_KEY }
          size='normal'
        />
      </div>
      <div className='ody-sign-up__form-control'>
        <div className='ody-sign-up__form-control--link'>
          <input
            className={
              clsx({
                'error': isNotEmpty(errors.agreedWithTermsOfService),
              })
            }
            id='agreedWithTermsOfService'
            name='agreedWithTermsOfService'
            type='checkbox'
            { ...register('agreedWithTermsOfService') }
          />
          <label
            className='inline ody-sign-up__form-control--link-label'
            htmlFor='agreedWithTermsOfService'
          >
            { t('sign_up:agree_to') }
            &nbsp;
            <Link
              className='link inline'
              rel='noopener noreferrer'
              target='_blank'
              to='/terms-of-service'
            >
              { t('sign_up:terms_of_service_link_text') }
            </Link>
          </label>
        </div>
        { agreedWithTermsOfServiceError }
      </div>
      <div className='ody-sign-up__form-control'>
        <div className='ody-sign-up__form-control--link'>
          <input
            className={
              clsx({
                'error': isNotEmpty(errors.agreedWithPrivacyPolicy),
              })
            }
            id='agreedWithPrivacyPolicy'
            name='agreedWithPrivacyPolicy'
            type='checkbox'
            { ...register('agreedWithPrivacyPolicy') }
          />
          <label
            className='inline inline ody-sign-up__form-control--link-label'
            htmlFor='agreedWithPrivacyPolicy'
          >
            { t('sign_up:agree_to') }
            &nbsp;
            <Link
              className='link inline'
              rel='noopener noreferrer'
              target='_blank'
              to='/privacy-policy'
            >
              { t('sign_up:privacy_policy_link_text') }
            </Link>
          </label>
        </div>
        { agreedWithPrivacyPolicyError }
      </div>
      <button
        aria-label='Sign up via Google'
        className='btn btn-custom btn-white btn-rounded-sm ody-sign-up__sign-up-button'
        disabled={ isNotEmpty(errors) || !recaptchaSubmitted }
        id='google-form-sign-up-button'
        name='Google form sign up button'
        type='submit'
      >
        <img alt='Google Logo' src={ LinkedIn } />
        { t('sign_up:sign_up_button_label') }
      </button>
    </form>
  );
});

LinkedInSignUpForm.displayName = 'LinkedInSignUpForm';

LinkedInSignUpForm.propTypes = {
  // The Google access token
  accessToken: PropTypes.string.isRequired,
  // The function (created) => void that handles whether the user has been created
  handleCreated: PropTypes.func.isRequired,
  // The predefined user's fields
  predefinedUsersFields: PropTypes.shape({
    // The email of the user via Google
    email: PropTypes.string,
    // The firstName of the user via Google
    firstName: PropTypes.string,
    // The lastName of the user via Google
    lastName: PropTypes.string,
  }).isRequired,
  // The function () => void to reset the authentication/authorization flow
  resetAuthenticationAuthorizationFlow: PropTypes.func.isRequired,
  // The role of the person that is signing up
  role: PropTypes.oneOf(roles).isRequired,
};

LinkedInSignUpForm.defaultProps = {

};

export default LinkedInSignUpForm;
