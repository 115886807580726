/**
 * Authentication Provider.
 *
 * @module utilities/auth/provider
 */

/**
 * Local authentication provider.
 *
 * @constant {string}
 * @static
 */
export const LOCAL = 'LOCAL';

/**
 * Google authentication provider.
 *
 * @constant {string}
 * @static
 */
export const GOOGLE = 'GOOGLE';

/**
 * LinkedIn's authentication provider.
 *
 * @constant {string}
 * @static
 */
export const LINKEDIN = 'LINKEDIN';

export default [
  LOCAL,
  GOOGLE,
  LINKEDIN,
];
