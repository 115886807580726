/**
 * Companies - Learn more.
 */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import PublicFaqSection from 'components/common/public-faq-section/public-faq-section';
import PublicLearnMoreSignInSection
  from 'components/common/public-learn-more-sign-in-section/public-learn-more-sign-in-section';
import { actions as requestActions } from 'ducks/requests';
import * as companiesMethods from 'resources/companies';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';
import * as BasicPricingPlans from 'utilities/basic-pricing-plans';
import * as PricingPlans from 'utilities/pricing-plans';

import LearnMoreDarkRedSection from './learn-more-dark-red-section/learn-more-dark-red-section';
import LearnMoreHrPackagesSection from './learn-more-hr-packages-section/learn-more-hr-packages-section';
import LearnMoreLightRedSection from './learn-more-light-red-section/learn-more-light-red-section';
import LearnMoreWhiteSection from './learn-more-white-section/learn-more-white-section';

import './learn-more.scss';

const LearnMore = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  // The user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth.role ]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Sets the pricing params to send
  const setPricingInterestParams = useCallback((pricingPlan, basicPricingPlan) => {
    let params = {
      basicPricingPlan,
      pricingPlan,
    };
    if (!basicPricingPlan) {
      delete params.basicPricingPlan;
    }
    return params;
  }, []);

  const showPricingInterest = useCallback((pricingPlan, basicPricingPlan) => {
    if (userRole !== Roles.COMPANY_AGENT) {
      toasts.error(t('companies:learn_more.hr_packages_section.pricing_interest_different_role_error'));
    }
    dispatch(requestActions.request(companiesMethods.showPricingPlansInterest,
      setPricingInterestParams(pricingPlan, basicPricingPlan),
      {
        onFailure: (_error) => {
          toasts.error(t('companies:learn_more.hr_packages_section.failed_email'));
        },
        onSuccess: () => {
          toasts.info(t('companies:learn_more.hr_packages_section.success_email'));
        },
      }));
  }, [ dispatch, setPricingInterestParams, t, userRole ]);

  // The rendered sign in section
  const renderedSignInSection = useMemo(() => {
    if (userAuthenticated) {
      return null;
    }
    return (
      <PublicLearnMoreSignInSection
        role={ Roles.COMPANY_AGENT }
        title={ t('companies:learn_more.sign_in_section.title') }
      />
    );
  }, [ t, userAuthenticated ]);

  // The FAQ's array
  const faqs = useMemo(() => {
    return [
      {
        answer: t('companies:learn_more.faqs_section.faqs.item1.answer'),
        question: t('companies:learn_more.faqs_section.faqs.item1.question'),
      },
      {
        answer: t('companies:learn_more.faqs_section.faqs.item2.answer'),
        question: t('companies:learn_more.faqs_section.faqs.item2.question'),
      },
      {
        answer: t('companies:learn_more.faqs_section.faqs.item3.answer'),
        question: t('companies:learn_more.faqs_section.faqs.item3.question'),
      },
      {
        answer: t('companies:learn_more.faqs_section.faqs.item4.answer'),
        question: t('companies:learn_more.faqs_section.faqs.item4.question'),
      },
    ];
  }, [ t ]);

  // The HR packages collection
  const hrPackagesCollection = useMemo(() => {
    const hrPackages = [
      {
        basicPricingPlan: BasicPricingPlans.STARTER,
        buttonLabel: t('companies:learn_more.hr_packages_section.pricing_button_label'),
        description: [
          t('companies:learn_more.hr_packages_section.pricing_plans.item1.description.item1'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item1.description.item2'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item1.description.item3'),
        ],
        pricingDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item1.pricing_description'),
        pricingPlan: PricingPlans.BASIC,
        title: t('companies:learn_more.hr_packages_section.pricing_plans.item1.title'),
        titleDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item1.title_description'),
      },
      {
        basicPricingPlan: BasicPricingPlans.ADVANCE,
        buttonLabel: t('companies:learn_more.hr_packages_section.pricing_button_label'),
        description: [
          t('companies:learn_more.hr_packages_section.pricing_plans.item2.description.item1'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item2.description.item2'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item2.description.item3'),
        ],
        pricingDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item2.pricing_description'),
        pricingPlan: PricingPlans.BASIC,
        title: t('companies:learn_more.hr_packages_section.pricing_plans.item2.title'),
        titleDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item2.title_description'),
      },
      {
        basicPricingPlan: BasicPricingPlans.BUSINESS,
        buttonLabel: t('companies:learn_more.hr_packages_section.pricing_button_label'),
        description: [
          t('companies:learn_more.hr_packages_section.pricing_plans.item3.description.item1'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item3.description.item2'),
        ],
        pricingDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item3.pricing_description'),
        pricingPlan: PricingPlans.BASIC,
        title: t('companies:learn_more.hr_packages_section.pricing_plans.item3.title'),
        titleDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item3.title_description'),
      },
      {
        buttonLabel: t('companies:learn_more.hr_packages_section.pricing_button_label'),
        description: [
          t('companies:learn_more.hr_packages_section.pricing_plans.item4.description.item1'),
          t('companies:learn_more.hr_packages_section.pricing_plans.item4.description.item2'),
        ],
        pricingDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item4.pricing_description'),
        pricingPlan: PricingPlans.ENTERPRISE,
        title: t('companies:learn_more.hr_packages_section.pricing_plans.item4.title'),
        titleDescription: t('companies:learn_more.hr_packages_section.pricing_plans.item4.title_description'),
      },
    ];
    if (userAuthenticated) {
      return hrPackages;
    }
    return [
      {
        buttonLabel: t('companies:learn_more.hr_packages_section.free_pricing_button_label'),
        description: [
          t('companies:learn_more.hr_packages_section.free_pricing_plan.description.item1'),
          t('companies:learn_more.hr_packages_section.free_pricing_plan.description.item2'),
        ],
        pricingPlan: PricingPlans.FREE,
        title: t('companies:learn_more.hr_packages_section.free_pricing_plan.title'),
      },
      ...hrPackages,
    ];
  }, [ t, userAuthenticated ]);

  return (
    <div className='ody-companies-learn-more'>
      { renderedSignInSection }
      <LearnMoreLightRedSection
        buttonLabel={ t('companies:learn_more.pool_of_verified_job_seekers_section.button_label') }
        description={ t('companies:learn_more.pool_of_verified_job_seekers_section.description') }
        seeTalent
        title={ t('companies:learn_more.pool_of_verified_job_seekers_section.title') }
      />
      <LearnMoreWhiteSection
        buttonLabel={ t('companies:learn_more.example_profile_section.button_label') }
        description={ t('companies:learn_more.example_profile_section.description') }
        hasButton
        title={ t('companies:learn_more.example_profile_section.title') }
      />
      <LearnMoreDarkRedSection
        buttonLabel={ t('companies:learn_more.ai_talent_recommender_section.button_label') }
        description={ t('companies:learn_more.ai_talent_recommender_section.description') }
        isComingSoon
        title={ t('companies:learn_more.ai_talent_recommender_section.title') }
      />
      <LearnMoreWhiteSection
        buttonLabel={ t('companies:learn_more.endorsement_section.button_label') }
        description={ t('companies:learn_more.endorsement_section.description') }
        hasButton
        isEndorsementSection
        title={ t('companies:learn_more.endorsement_section.title') }
      />
      <LearnMoreLightRedSection
        description={ t('companies:learn_more.diversity_section.description') }
        title={ t('companies:learn_more.diversity_section.title') }
      />
      <LearnMoreWhiteSection
        description={ t('companies:learn_more.consulting_section.description') }
        title={ t('companies:learn_more.consulting_section.title') }
      />
      <LearnMoreDarkRedSection
        buttonLabel={ t('companies:learn_more.csr_packages_section.button_label') }
        description={ t('companies:learn_more.csr_packages_section.description') }
        title={ t('companies:learn_more.csr_packages_section.title') }
      />
      <LearnMoreHrPackagesSection
        handlePricingPlanClick={ showPricingInterest }
        packagesCollection={ hrPackagesCollection }
        title={ t('companies:learn_more.hr_packages_section.title') }
        userAuthenticated={ userAuthenticated }
        userRole={ userRole }
      />
      <PublicFaqSection
        faqs={ faqs }
        isRed
        title={ t('companies:learn_more.faqs_section.title') }
      />
    </div>
  );
};

LearnMore.propTypes = {
};

LearnMore.defaultProps = {
};

export default LearnMore;
