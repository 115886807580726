/**
 * Settings - Preferences.
 */
import React from 'react';

import Language from './language/language';
import './preferences.scss';

const Preferences = (_props) => {
  return (
    <div className='preferences section'>
      <Language />
    </div>
  );
};

Preferences.propTypes = {
};

Preferences.defaultProps = {
};

export default Preferences;
