import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select/select';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import { areNotEqual, isEmpty, isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import { endorsersProfileProfessionalDetailsSchemaValidator } from 'utilities/validators';

const ProfileProfessionalDetailsEditor = memo((props) => {
  const { profile, onSave, onCancel } = props;

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useFormWithSchema(endorsersProfileProfessionalDetailsSchemaValidator);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      companyName: profile?.companyName || undefined,
      companyPosition: profile?.companyPosition || undefined,
      linkedinUrl: profile?.linkedinUrl || undefined,
      location: profile?.location || undefined,
      phoneNumber: profile?.phoneNumber || undefined,
      website: profile?.website || undefined,
    };
  }, [
    profile?.companyName,
    profile?.companyPosition,
    profile?.linkedinUrl,
    profile?.location,
    profile?.phoneNumber,
    profile?.website,
  ]);

  // The location to be updated
  const renderLocationToBeUpdated = useCallback((location) => {
    if (isEmpty(location?.city?.value) && isEmpty(location?.district)) {
      return undefined;
    }
    return {
      ...location,
      city: location?.city?.value || undefined,
    };
  }, []);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      companyName: values?.companyName || undefined,
      companyPosition: values?.companyPosition || undefined,
      linkedinUrl: values?.linkedinUrl || undefined,
      location: renderLocationToBeUpdated(values?.location),
      phoneNumber: values?.phoneNumber || undefined,
      website: values?.website || undefined,
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ onSave, renderLocationToBeUpdated, valuesAreToBeUpdated ]);

  // The phone number empty field error
  const phoneNumberEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.phoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('endorsers:profile_professional_details.phone_number_required_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // The phone number pattern error
  const phoneNumberPatternError = useMemo(() => {
    if (-1 === [ 'string.pattern.base' ].indexOf(errors.phoneNumber?.type)) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('endorsers:profile_professional_details.phone_number_invalid_error') }
      </span>
    );
  }, [ errors.phoneNumber?.type, t ]);

  // The cities options
  const citiesOptions = useMemo(() => {
    return allCities.map((city) => {
      return { label: t(`utilities:cities.${ city }`), value: city };
    });
  }, [ t ]);

  // The rendered city field
  const renderCityField = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ citiesOptions }
        placeholder={ t('endorsers:profile_professional_details.city_placeholder') }
      />
    );
  }, [ citiesOptions, t ]);

  // The city field defaultValue
  const cityFieldDefaultValue = useMemo(() => {
    if (!profile?.location?.city) {
      return null;
    }
    return {
      label: t(`utilities:cities.${ profile.location.city }`),
      value: profile.location.city,
    };
  }, [ profile?.location?.city, t ]);

  // The LinkedIn profile url field invalid string error
  const linkedInEmptyStringError = useMemo(() => {
    if ('string.uri' !== errors?.linkedinUrl?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('endorsers:profile_professional_details.website_invalid_url') }
      </span>
    );
  }, [ errors?.linkedinUrl?.type, t ]);

  // The website invalid error
  const websiteInvalidError = useMemo(() => {
    if ('string.uri' !== errors?.website?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('endorsers:profile_professional_details.website_invalid_url') }
      </span>
    );
  }, [ errors?.website?.type, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('endorsers:profile_professional_details.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.company_name_label') }
            <input
              defaultValue={ profile?.companyName || '' }
              id='companyName'
              maxLength={ 256 }
              placeholder={ t('endorsers:profile_professional_details.company_name_placeholder') }
              type='text'
              { ...register('companyName') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.company_position_label') }
            <input
              defaultValue={ profile?.companyPosition || '' }
              id='companyPosition'
              maxLength={ 256 }
              placeholder={ t('endorsers:profile_professional_details.company_website_placeholder') }
              type='text'
              { ...register('companyPosition') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.company_website_label') }
            <input
              className={
                clsx({
                  'error': errors?.linkedinUrl,
                })
              }
              defaultValue={ profile?.website || '' }
              id='website'
              maxLength={ 256 }
              placeholder={ t('endorsers:profile_professional_details.company_website_placeholder') }
              type='text'
              { ...register('website') }
            />
          </label>
          { websiteInvalidError }
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.phone_number_label') }
            <input
              className={
                clsx({
                  'error': errors?.phoneNumber,
                })
              }
              defaultValue={ profile?.phoneNumber || '' }
              id='phoneNumber'
              maxLength={ 14 }
              placeholder={ t('endorsers:profile_professional_details.phone_number_placeholder') }
              type='text'
              { ...register('phoneNumber') }
            />
          </label>
          { phoneNumberEmptyFieldError }
          { phoneNumberPatternError }
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.location_label') }
            <input
              className={
                clsx({
                  'error': errors?.location?.district,
                })
              }
              defaultValue={ profile?.location?.district || '' }
              maxLength={ 128 }
              placeholder={ t('endorsers:profile_professional_details.district_placeholder') }
              type='text'
              { ...register('location.district') }
            />
          </label>
          <label>
            <Controller
              control={ control }
              defaultValue={ cityFieldDefaultValue }
              name='location.city'
              render={ renderCityField }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('endorsers:profile_professional_details.linkedin_profile_url_label') }
            <input
              className={
                clsx({
                  'error': errors?.linkedinUrl,
                })
              }
              defaultValue={ profile?.linkedinUrl || '' }
              id='linkedinProfileUrl'
              placeholder={ t('endorsers:profile_professional_details.linkedin_profile_url_placeholder') }
              type='text'
              { ...register('linkedinUrl') }
            />
          </label>
          { linkedInEmptyStringError }
        </div>
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileProfessionalDetailsEditor.displayName = 'ProfileProfessionalDetailsEditor';

ProfileProfessionalDetailsEditor.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the company agent.
  profile: PropTypes.shape({
    // the company name
    companyName: PropTypes.string,
    // The company position
    companyPosition: PropTypes.string,
    // The linked in url
    linkedinUrl: PropTypes.string,
    // The location where the endorser is.
    location: PropTypes.shape({
      // The city.
      city: PropTypes.oneOf(allCities),
      // The district.
      district: PropTypes.string,
    }),
    // the professional phone number
    phoneNumber: PropTypes.string,
    // the company's website
    website: PropTypes.string,
  }),
};

ProfileProfessionalDetailsEditor.defaultProps = {};

export default ProfileProfessionalDetailsEditor;
