/**
 * English translations for utilities/driving-license-categories.
 *
 * @module languages/en/utilities/driving-license-categories
 */
const drivingLicenseCategories = {
  CATEGORY_A: 'Category A (Motorcycles)',
  CATEGORY_B: 'Category B (Motor vehicles)',
  CATEGORY_C: 'Category C (Large goods vehicles)',
  CATEGORY_D: 'Category D (Buses)',
};

export default drivingLicenseCategories;
