/**
 * ProfilePricingSection editor.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select/select';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import roles, * as Roles from 'utilities/auth/roles';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';
import pricingPlans from 'utilities/pricing-plans';
import { pricingSchemaValidator } from 'utilities/validators';

const ProfilePricingEditor = memo((props) => {
  const { profile, onSave, onCancel, userRole } = props;

  const { t } = useTranslation();

  const { control, formState: { errors }, handleSubmit, register } = useFormWithSchema(pricingSchemaValidator);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      address: profile?.address || undefined,
      legalName: profile?.legalName || undefined,
      occupation: profile?.occupation || undefined,
      pricingPlan: profile?.pricingPlan?.value || undefined,
      taxOffice: profile?.taxOffice || undefined,
      vatNumber: profile?.vatNumber || undefined,
    };
  }, [
    profile?.address,
    profile?.legalName,
    profile?.occupation,
    profile?.pricingPlan?.value,
    profile?.taxOffice,
    profile?.vatNumber,
  ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      address: values?.address || undefined,
      legalName: values?.legalName || undefined,
      occupation: values?.occupation || undefined,
      pricingPlan: values?.pricingPlan?.value || undefined,
      taxOffice: values?.taxOffice || undefined,
      vatNumber: values?.vatNumber || undefined,
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ onSave, valuesAreToBeUpdated ]);

  // rendered options of pricing plans
  const pricingPlansOptions = useMemo(() => {
    return pricingPlans.map((pricingPlan) => {
      return { label: t(`utilities:pricing_plans.${ pricingPlan }`), value: pricingPlan };
    });
  }, [ t ]);

  // The rendered Select field
  const renderedSelectField = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ pricingPlansOptions }
        placeholder={ t('companies:profile_pricing_section.pricing_plan_placeholder') }
      />
    );
  }, [ pricingPlansOptions, t ]);

  // The pricing plan default value
  const pricingPlanDefaultValue = useMemo(() => {
    if (profile?.pricingPlan === undefined) {
      return null;
    }
    return {
      label: t(`utilities:pricing_plans.${ profile.pricingPlan }`),
      value: profile.pricingPlan,
    };
  }, [ profile?.pricingPlan, t ]);

  // The pricing plan field
  const pricingPlanField = useMemo(() => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('companies:profile_pricing_section.pricing_plan_label') }
          <Controller
            control={ control }
            defaultValue={ pricingPlanDefaultValue }
            name='pricingPlan'
            render={ renderedSelectField }
          />
        </label>
      </div>
    );
  }, [ control, pricingPlanDefaultValue, renderedSelectField, t, userRole ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('companies:profile_pricing_section.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { pricingPlanField }
        <div className='profile-editor__form-field'>
          <label>
            { t('companies:profile_pricing_section.legal_name_label') }
            <input
              defaultValue={ profile?.legalName || '' }
              id='legalName'
              maxLength={ 256 }
              placeholder={ t('companies:profile_pricing_section.legal_name_placeholder') }
              type='text'
              { ...register('legalName') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('companies:profile_pricing_section.occupation_label') }
            <input
              defaultValue={ profile?.occupation || '' }
              id='occupation'
              maxLength={ 256 }
              placeholder={ t('companies:profile_pricing_section.occupation_placeholder') }
              type='text'
              { ...register('occupation') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('companies:profile_pricing_section.address_label') }
            <input
              defaultValue={ profile?.address || '' }
              id='legalName'
              maxLength={ 256 }
              placeholder={ t('companies:profile_pricing_section.address_placeholder') }
              type='text'
              { ...register('address') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('companies:profile_pricing_section.vat_number_label') }
            <input
              defaultValue={ profile?.vatNumber || '' }
              id='legalName'
              maxLength={ 256 }
              placeholder={ t('companies:profile_pricing_section.vat_number_placeholder') }
              type='text'
              { ...register('vatNumber') }
            />
          </label>
        </div>
        <div className='profile-editor__form-field'>
          <label>
            { t('companies:profile_pricing_section.tax_office_label') }
            <input
              defaultValue={ profile?.taxOffice || '' }
              id='legalName'
              maxLength={ 256 }
              placeholder={ t('companies:profile_pricing_section.tax_office_placeholder') }
              type='text'
              { ...register('taxOffice') }
            />
          </label>
        </div>
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfilePricingEditor.displayName = 'ProfilePricingEditor';

ProfilePricingEditor.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the company agent.
  profile: PropTypes.shape({
    // The access level of the user.
    accessLevel: PropTypes.string,
    // The address of the company.
    address: PropTypes.string,
    // The legal name of the company.
    legalName: PropTypes.string,
    // The occupation of the company.
    occupation: PropTypes.string,
    // The pricing plan of the company.
    pricingPlan: PropTypes.string,
    // The tax office of the company.
    taxOffice: PropTypes.string,
    // The vat number of the company.
    vatNumber: PropTypes.string,
  }),
  // The signed in user role
  userRole: PropTypes.oneOf(roles),
};

export default ProfilePricingEditor;
