/**
 * Resource method to sign in via LinkedIn.
 *
 * @module resources/auth/linkedin-sign-in
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/auth/linkedin-sign-in~Params
 * @type {object}
 * @property {string} accessToken - The accessToken provided by Google.
 * @property {string} email - The email.
 */

/**
 * Result.
 *
 * @typedef module:resources/auth/linkedin-sign-in~Result
 * @type {object}
 * @property {string} accessToken - The access token.
 * @property {string} id - The ID of the user.
 * @property {string} [companyId] - The company id that the user belongs if he is COMPANY_AGENT
 * @property {string} language - The language of the user.
 * @property {('ADMINISTRATOR' | 'COMPANY_AGENT' | 'ENDORSER' | 'JOB_SEEKER')} role - The role of the user.
 */

/**
 * Signs in a user based on the given parameters via LinkedIn.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/auth/linkedin-sign-in~Params} params - The parameters.
 * @returns {Promise<module:resources/auth/linkedin-sign-in~Result>} - The promise.
 * @static
 */
const linkedInSignIn = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/auth/linkedin/sign-in',
  }).then((response) => {
    return response.data;
  });
};

export default linkedInSignIn;
