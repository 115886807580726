/**
 * English translations for endorsers/search-result-card.
 *
 * @module languages/en/endorsers/search-result-card
 */
const searchResultCard = {
  company_website_label: 'Company\'s website',
};

export default searchResultCard;
