/**
 * Custom validators.
 *
 * @module utilities/validators
 */
import Joi from 'joi';
import { DateTime } from 'luxon';

import { LANGUAGES } from 'i18n';

import { currentYear } from './chisels';
import allCities from './cities';
import countries from './countries';
import genders from './genders';
import languageLevels from './language-levels';
import languages from './languages';
import pricingPlans from './pricing-plans';

// The minimum allowed age.
const MINIMUM_ALLOWED_AGE = 16;

/**
 * The special characters allowed in passwords.
 *
 * @constant {string}
 * @default
 * @static
 */
const SPECIAL_CHARACTERS = '!"#\\$%&\'\\(\\)\\*\\+,\\-\\./:;<=>\\?@\\[\\\\\\]\\^_`\\{\\|\\}~';

/**
 * The regular expression that valid passwords match.
 *
 * @constant {RegExp}
 * @default
 * @static
 */
const PASSWORD_REGEX = new RegExp(`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[${ SPECIAL_CHARACTERS }]).*$`);

/**
 * Validates the entries in the given work availabilities facet.
 *
 * @param {*} value - The password to validate.
 * @param {object} helpers - The helpers.
 * @returns {* | Error} - The password, if valid; error, otherwise.
 * @static
 */
const validatePassword = (value, helpers) => {
  // The password has too few characters.
  if (8 > value.length) {
    return helpers.error('string.min');
  }
  // The password has too many characters.
  if (256 < value.length) {
    return helpers.error('string.max');
  }
  // The password has incorrect format.
  if (!PASSWORD_REGEX.test(value)) {
    return helpers.error('string.pattern.base');
  }
  return value;
};

/**
 * The sign in form validation schema
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const signInFormSchemaValidation = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256).required(),
  password: Joi.string().min(1).max(256).required(),
});

/**
 * The sign in form oauth validation schema
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const signInFormOAuthSchemaValidation = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256).required(),
});

/**
 * The languages editor schema validator in settings
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const languagesEditorSchemaValidator = Joi.object({
  language: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...LANGUAGES),
  }).required(),
});

/**
 * The password editor schema validator in settings
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const passwordEditorSchemaValidator = Joi.object({
  currentPassword: Joi.string().min(1).max(256).required(),
  newPassword: Joi.string().required().custom(validatePassword),
  newPasswordConfirmation: Joi.string().valid(Joi.ref('newPassword')),
});

/**
 * The reset password schema validator in settings
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const resetPasswordSchemaValidator = Joi.object({
  newPassword: Joi.string().required().custom(validatePassword),
  newPasswordConfirmation: Joi.string().valid(Joi.ref('newPassword')),
});

/**
 * The forgot password schema validator in settings
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const forgotPasswordSchemaValidator = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256).required(),
});

/**
 * The job seekers sign up schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersSignUpSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
  password: Joi.string().required().custom(validatePassword),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
});

/**
 * The job seekers sign up oauth schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersSignUpOAuthSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
});

/**
 * The companies sign up schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const companiesSignUpSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  companyName: Joi.string().min(1).max(256).required(),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
  password: Joi.string().required().custom(validatePassword),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
  phoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).required(),
});

/**
 * The companies sign up oauth schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const companiesSignUpOAuthSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  companyName: Joi.string().min(1).max(256).required(),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
  phoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).required(),
});

/**
 * The endorsers sign up schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const endorsersSignUpSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  companyName: Joi.string().min(1).max(256).allow('').optional(),
  companyPosition: Joi.string().min(1).max(256).allow('').optional(),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
  mobilePhoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).allow('').optional(),
  password: Joi.string().required().custom(validatePassword),
  passwordConfirmation: Joi.string().valid(Joi.ref('password')),
});

/**
 * The endorsers sign up oauth schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const endorsersSignUpOAuthSchemaValidator = Joi.object({
  agreedWithPrivacyPolicy: Joi.boolean().valid(true),
  agreedWithTermsOfService: Joi.boolean().valid(true),
  companyName: Joi.string().min(1).max(256).allow('').optional(),
  companyPosition: Joi.string().min(1).max(256).allow('').optional(),
  email: Joi.string().email({ tlds: { allow: false } }).min(1).max(256),
  firstName: Joi.string().min(1).max(256).required(),
  lastName: Joi.string().min(1).max(256).required(),
  mobilePhoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).allow('').optional(),
});

/**
 * The companies details editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const companiesDetailsEditorSchemaValidator = Joi.object({
  hiresPerYear: Joi.number().integer().min(0).allow(null).allow(''),
  location: Joi.object({
    city: Joi.object({
      label: Joi.string(),
      value: Joi.string().valid(...allCities),
    }).allow(null).allow(''),
    district: Joi.string().max(128).allow(null).allow(''),
  }).required(),
  numberOfEmployees: Joi.number().integer().min(0).allow(null).allow(''),
  website: Joi.string().uri().max(256).allow(null).allow(''),
});

/**
 * The endorsers details editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const endorsersPersonalDetailsEditorSchemaValidator = Joi.object({
  firstName: Joi.string().min(1).max(256).required(),
  gender: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...genders),
  }).allow(null),
  lastName: Joi.string().min(1).max(256).required(),
  mobilePhoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14),
});

/**
 * The job seekers personal details editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersPersonalDetailsSchemaValidator = Joi.object({
  bornOn: Joi.object({
    day: Joi.object({
      label: Joi.string(),
      value: Joi.number().integer().min(1).max(31),
    }).allow(null),
    month: Joi.object({
      label: Joi.string(),
      value: Joi.number().integer().min(1).max(12),
    }).allow(null),
    year: Joi.object({
      label: Joi.string(),
      value: Joi.number().integer().min(1940).max(currentYear() - MINIMUM_ALLOWED_AGE),
    }).allow(null),
  }).custom((value, helpers) => {
    // Everything is missing.
    if (null === value.day && null === value.month && null === value.year) {
      return value;
    }
    // Everything is there.
    if (null !== value.day && null !== value.month && null !== value.year) {
      const dt = DateTime.fromObject({ day: value.day.value, month: value.month.value, year: value.year.value });
      if (!dt.isValid) {
        return helpers.error('any.custom');
      }
      return value;
    }
    // Something is missing.
    return helpers.error('any.custom');
  }),
  citizenship: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...countries),
  }).allow(null),
  firstName: Joi.string().min(1).max(256).required(),
  gender: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...genders),
  }).allow(null),
  lastName: Joi.string().min(1).max(256).required(),
  location: Joi.object({
    city: Joi.object({
      label: Joi.string(),
      value: Joi.string().valid(...allCities),
    }).allow(null),
    district: Joi.string().max(128).allow(null).allow(''),
  }),
  origin: Joi.object({
    country: Joi.object({
      label: Joi.string(),
      value: Joi.string().valid(...countries),
    }).allow(null),
  }),
});

/**
 * The job seekers contact details editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersContactDetailsSchemaValidator = Joi.object({
  linkedinProfileUrl: Joi.string().uri().max(256).allow(null).allow(''),
  phoneNumber1: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).allow(null).allow(''),
  phoneNumber2: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).allow(null).allow(''),
});

/**
 * The companies contact details editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const companiesContactDetailsSchemaValidator = Joi.object({
  companyPosition: Joi.string().min(1).max(256),
  firstName: Joi.string().min(1).max(256),
  lastName: Joi.string().min(1).max(256),
  phoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14),
});

/**
 * The companies about editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const companiesAboutEditorSchemaValidator = Joi.object({
  description: Joi.string().max(1024).allow(null).allow(''),
  file: Joi.any(),
  video: Joi.string().uuid().allow(null).allow(''),
});

/**
 * The companies about editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersAboutEditorSchemaValidator = Joi.object({
  file: Joi.any(),
  shortBio: Joi.string().max(1024).allow(null).allow(''),
  video: Joi.string().uuid().allow(null).allow(''),
});

/**
 * The endorsers about editor schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const endorsersAboutEditorSchemaValidator = Joi.object({
  shortBio: Joi.string().max(1024).allow(null).allow(''),
});

/**
 * The profile background image schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const profileBackgroundImageSchemaValidator = Joi.object({
  backgroundImage: Joi.number(),
});

/**
 * The profile image schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const profileImageSchemaValidator = Joi.object({
  file: Joi.any(),
  profileImage: Joi.string().uuid().allow(null).allow(''),
});

/**
 * The companies pricing schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const pricingSchemaValidator = Joi.object({
  address: Joi.string().min(1).max(256).allow('').allow(null),
  legalName: Joi.string().min(1).max(256).allow('').allow(null),
  occupation: Joi.string().min(1).max(256).allow('').allow(null),
  pricingPlan: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...pricingPlans),
  }).allow(null),
  taxOffice: Joi.string().min(1).max(256).allow('').allow(null),
  vatNumber: Joi.string().min(1).max(256).allow('').allow(null),
});

/**
 * The job seekers languages schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersLanguagesSchemaValidator = Joi.object({
  englishLevel: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...languageLevels),
  }).allow(null),
  greekLevel: Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...languageLevels),
  }).allow(null),
  otherLanguages: Joi.array().max(8).items(Joi.object({
    label: Joi.string(),
    value: Joi.string().valid(...languages),
  })),
});

/**
 * The job seekers resume schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const jobSeekersResumeSchemaValidator = Joi.object({
  file: Joi.any(),
  resume: Joi.string().uuid().allow(null).allow(''),
});

/**
 * The endorsers profile professional details schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const endorsersProfileProfessionalDetailsSchemaValidator = Joi.object({
  companyName: Joi.string().min(1).max(256).allow('').optional(),
  companyPosition: Joi.string().min(1).max(256).allow('').optional(),
  linkedinUrl: Joi.string().uri().max(256).allow(null).allow(''),
  location: Joi.object({
    city: Joi.object({
      label: Joi.string(),
      value: Joi.string().valid(...allCities),
    }).allow(null).allow(''),
    district: Joi.string().max(128).allow(null).allow(''),
  }).required(),
  phoneNumber: Joi.string().pattern(/^\+?\d{0,14}$/).max(14).required(),
  website: Joi.string().uri().max(256).allow(null).allow(''),
});

/**
 * The admin controlled metrics schema validator
 *
 * @constant {Joi.ObjectSchema<any>}
 * @default
 * @static
 */
const adminControlledMetricsSchemaValidator = Joi.object({
  collaboratingCompanies: Joi.string().min(1).max(256),
  companies: Joi.string().min(1).max(256),
  employabilityServicesPerYear: Joi.string().min(1).max(256),
  findEmployabilityPerYear: Joi.string().min(1).max(256),
  jobPositionsFromOurCompanies: Joi.string().min(1).max(256),
  matchedJobSeekersWithCompanies: Joi.string().min(1).max(256),
  peopleHired: Joi.string().min(1).max(256),
  peopleInterviewed: Joi.string().min(1).max(256),
  profiles: Joi.string().min(1).max(256),
  registeredPerYear: Joi.string().min(1).max(256),
  studentsPerYear: Joi.string().min(1).max(256),
});

export {
  MINIMUM_ALLOWED_AGE,
  adminControlledMetricsSchemaValidator,
  companiesAboutEditorSchemaValidator,
  companiesContactDetailsSchemaValidator,
  companiesDetailsEditorSchemaValidator,
  companiesSignUpOAuthSchemaValidator,
  companiesSignUpSchemaValidator,
  endorsersAboutEditorSchemaValidator,
  endorsersPersonalDetailsEditorSchemaValidator,
  endorsersProfileProfessionalDetailsSchemaValidator,
  endorsersSignUpOAuthSchemaValidator,
  endorsersSignUpSchemaValidator,
  forgotPasswordSchemaValidator,
  jobSeekersAboutEditorSchemaValidator,
  jobSeekersContactDetailsSchemaValidator,
  jobSeekersLanguagesSchemaValidator,
  jobSeekersSignUpOAuthSchemaValidator,
  jobSeekersSignUpSchemaValidator,
  jobSeekersPersonalDetailsSchemaValidator,
  jobSeekersResumeSchemaValidator,
  languagesEditorSchemaValidator,
  passwordEditorSchemaValidator,
  profileBackgroundImageSchemaValidator,
  profileImageSchemaValidator,
  pricingSchemaValidator,
  resetPasswordSchemaValidator,
  signInFormOAuthSchemaValidation,
  signInFormSchemaValidation,
};
