import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import ImageTestimonial from './image-testimonial/image-testimonial';
import VideoTestimonial from './video-testimonial/video-testimonial';

import './public-testimonial.scss';

const PublicTestimonial = memo((props) => {
  const { testimonial } = props;

  // The video testimonials
  const videoTestimonials = useMemo(() => {
    return testimonial.filter((testimonialItem) => {
      return testimonialItem?.videoUrl !== undefined;
    });
  }, [ testimonial ]);

  // The image testimonials
  const imageTestimonials = useMemo(() => {
    return testimonial.filter((testimonialItem) => {
      return testimonialItem?.imageUrl !== undefined;
    });
  }, [ testimonial ]);

  // The rendered video testimonials
  const renderedVideoTestimonials = useMemo(() => {
    return videoTestimonials?.map((videoTestimonialItem, videoTestimonialItemIndex) => {
      return (
        <VideoTestimonial
          ageLocation={ videoTestimonialItem.ageLocation }
          key={ videoTestimonialItem.title + videoTestimonialItemIndex }
          name={ videoTestimonialItem.name }
          title={ videoTestimonialItem.title }
          videoUrl={ videoTestimonialItem.videoUrl }
        />
      );
    });
  }, [ videoTestimonials ]);

  // The rendered image testimonials
  const renderedImageTestimonials = useMemo(() => {
    return imageTestimonials?.map((imageTestimonialItem, imageTestimonialItemIndex) => {
      return (
        <ImageTestimonial
          ageLocation={ imageTestimonialItem.ageLocation }
          backgroundPosition={ imageTestimonialItem?.backgroundPosition }
          description={ imageTestimonialItem.description }
          imageUrl={ imageTestimonialItem.imageUrl }
          key={ imageTestimonialItem.title + imageTestimonialItemIndex }
          name={ imageTestimonialItem.name }
          title={ imageTestimonialItem.title }
        />
      );
    });
  }, [ imageTestimonials ]);

  return (
    <div className='public-testimonial'>
      <div className='public-testimonial__video-testimonials-container'>
        { renderedVideoTestimonials }
      </div>
      <div className='public-testimonial__image-testimonials-container'>
        { renderedImageTestimonials }
      </div>
    </div>
  );
});

PublicTestimonial.displayName = 'PublicTestimonial';

PublicTestimonial.propTypes = {
  // the testimonial
  testimonial: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Video testimonial
      PropTypes.shape({
        // the age location
        ageLocation: PropTypes.string,
        // the name of the person of the testimonial
        name: PropTypes.string,
        // The title of the testimonial
        title: PropTypes.string,
        // the video url of the testimonial
        videoUrl: PropTypes.string,
      }),
      // The description image testimonial
      PropTypes.shape({
        // the age location
        ageLocation: PropTypes.string,
        // the image backgroundPosition
        backgroundPosition: PropTypes.string,
        // The description of the testimonial
        description: PropTypes.string,
        // The image url of the testimonial
        imageUrl: PropTypes.string,
        // the name of the person of the testimonial
        name: PropTypes.string,
        // The title of the testimonial
        title: PropTypes.string,
      }),
    ])
  ).isRequired,
};

PublicTestimonial.defaultProps = {};

export default PublicTestimonial;
