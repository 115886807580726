/**
 * Resource method to update job seeker profiles.
 *
 * @module resources/job-seekers/update-profile
 */
import axios from 'axios';

import { omit } from 'utilities/chisels';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/update-profile~Params
 * @type {object}
 * @property {File} [file] - The file.
 * @property {string} part - The part of the profile to update.
 * @property {module:types/job-seekers/profiles~Profile} profile - The profile.
 */

/**
 * Result.
 *
 * @typedef {module:types/job-seekers/profiles~Profile} Result
 */

/**
 * Updates the profile of a job seeker based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/update-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/update-profile~Result>} - The promise.
 * @static
 */
const updateProfile = (params) => {
  const data = new FormData();
  data.append('data', new Blob([
    JSON.stringify({
      ...omit(params.profile, [ 'id' ]),
      part: params.part,
    }),
  ], {
    type: 'application/json',
  }));
  if (undefined !== params.file) {
    data.append('file', params.file);
  }
  return axios.request({
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'patch',
    url: `/job-seekers/${ params.profile.id }/profile`,
  }).then((response) => {
    return response.data;
  });
};

export default updateProfile;
