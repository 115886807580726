import React, { useEffect } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { useNavigate } from 'react-router-dom';

const LinkedInCallbackComponent = (_props) => {
  const navigate = useNavigate();

  // We always redirect to '/' we don't want for this route to be hit manually in the browser
  useEffect(() => {
    navigate('/', { replace: true });
  }, [ navigate ]);

  return (
    <LinkedInCallback />
  );
};

LinkedInCallbackComponent.propTypes = {};

LinkedInCallbackComponent.defaultProps = {};

export default LinkedInCallbackComponent;
