/**
 * Greek translations for utilities for endorsers.
 *
 * @module languages/el/utilities/companies
 */
import filterTypes from './filter-types';
import sortKeys from './sort-keys';

const companies = {
  filter_types: filterTypes,
  sort_keys: sortKeys,
};

export default companies;
