/**
 * Greek translations for dashboard.
 *
 * @module languages/el/dashboard
 */
const dashboard = {
  common: {
    changes_saved_message: 'Οι αλλαγές σας αποθηκεύτηκαν.',
    general_error: 'Ωχ! Κάτι πήγε στραβά.',
  },
  failed_claimed_endorsement_requests: 'Απέτυχε η ανάκτηση των συστάσεων σας',
  failed_created_endorsement_requests: 'Απέτυχε η ανάκτηση των συστάσεων σας',
  failed_download_profile: 'Η λήψη του προφίλ σας απέτυχε',
  failed_to_claim_endorsement_request: 'Απέτυχε η ανάκτηση της σύστασης',
  failed_to_revoke_endorsement_request: 'Απέτυχε η ακύρωση της σύστασης',
  // eslint-disable-next-line max-len
  full_capacity_title: 'Ενδιαφέρεστε να ξεκλειδώσετε όλες τις δυνατότητες της πλατφόρμας; Επιλέξτε ένα από τα πακέτα τιμολόγησης ή επικοινωνήστε με την Odyssea',
  learn_more_button_title: 'Μάθετε περισσότερα',
  next_steps: {
    milestones: {
      item1: 'Προσωπικά στοιχεία και στοιχεία επικοινωνίας',
      item2: 'Θέσεις εργασίας και επαγγελματικοί κλάδοι',
      item3: 'Γλώσσες και δεξιότητες',
      item4: 'Αξιολόγηση ηπίων δεξιοτήτων',
      item5: 'Σύντομη περιγραφή και βίντεο',
      item6: 'Εμπειρία και προσόντα (εκπαίδευση/κατάρτιση)',
      item7: 'Βιογραφικό',
      item8: 'Σύσταση',
    },
  },
  profile_overview_and_work_availability: {
    edit_profile_button_label: 'Επεξεργασία προφίλ\n',
    work_availability_update_failed_error: 'Η ενημέρωση απέτυχε.',
    work_availability_updated_message: 'Η διαθεσιμότητα σας για εργασία ενημερώθηκε.',
  },
  saved_job_seekers: {
    about_title: 'Σχετικά',
    jobs_interested_title: 'Θέσεις εργασίας που ενδιαφέρεστε',
    no_saved_job_seekers: 'Δεν έχετε αποθηκεύσει ακόμη κάποιον/α υποψήφιο/α',
    title: 'Αποθηκευμένοι υποψήφιοι',
    view_all_button_title: 'Προβολή όλων',
    view_less_button_title: 'Εμφάνιση λιγότερων',
    view_profile_title: 'Εμφάνιση λιγότερων',
  },
  statistics: {
    companies_interested_label: 'Εταιρίες που ενδιαφέρονται για το προφίλ σας',
    job_ready_profiles: 'Προφίλ έτοιμα για εργασία',
    job_seekers_available: 'Διαθέσιμα προφίλ υποψηφίων',
    new_job_seeker_profiles: 'Καινούργια προφίλ προηγουμένης εβδομάδας',
    profile_views_label: 'Εταιρίες που είδαν το προφίλ σας',
    profile_views_last_week_label: 'Εταιρίες που είδαν το προφίλ σας την προηγουμένη εβδομάδα',
  },
};

export default dashboard;
