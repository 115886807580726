/**
 * English translations for common/search-form.
 *
 * @module languages/en/common/search-form
 */
const searchForm = {
  at_least_one_criterion_required_error: 'Specify at least one criterion.',
  companies_form_title: 'Company list',
  company_dashboard_title: 'Search for talent',
  job_seekers_form_title: 'Job seeker list',
  location_city_placeholder: 'Location/Country',
  search_button_label: 'Search',
  search_placeholder: 'Search',
};

export default searchForm;
