/**
 * Greek translations for common/sidebar-profile-completion.
 *
 * @module languages/el/common/sidebar-profile-completion
 */
const sidebarProfileCompletion = {
  title: 'Ολοκλήρωση προφίλ',
};

export default sidebarProfileCompletion;
