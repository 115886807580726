/**
 * Job seekers - Profile - Resume.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import Download from 'images/download.png';
import accessLevels from 'utilities/access-levels';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import { isEmpty } from '../../../utilities/chisels';

import ProfileResumeEditor from './profile-resume-editor/profile-resume-editor';

import './profile-resume.scss';

const ProfileResume = memo((props) => {
  const { profile, onChange, canEdit, onDownloadResume, jobSeekerId } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // function that handles the change of resume
  const onResumeChange = useCallback((paramsToBeUpdated, isToBeUpdated, file) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, JobSeekerParts.RESUME, isToBeUpdated, file);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileResumeEditor
          jobSeekerId={ jobSeekerId }
          onCancel={ toggleEditor(false) }
          onSave={ onResumeChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, jobSeekerId, onResumeChange, profile, toggleEditor ]);

  // the rendered download button
  const renderedDownloadButton = useMemo(() => {
    if (isEmpty(profile?.resume)) {
      return null;
    }
    return (
      <div className='profile-resume__download-container'>
        <button
          className='btn btn-sm btn-rounded-sm btn-blue profile-resume__download-button'
          onClick={ onDownloadResume }
          type='button'
        >
          <img alt='download resume' src={ Download } />
          { t('job_seekers:profile_resume.download_resume_button_label') }
        </button>
      </div>
    );
  }, [ onDownloadResume, profile?.resume, t ]);

  return (
    <div className='profile-resume'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('job_seekers:profile_resume.title') }
      />
      { renderedDownloadButton }
      { editorModal }
    </div>
  );
});

ProfileResume.displayName = 'ProfileResume';

ProfileResume.propTypes = {
  // Whether the user can edit the competencies
  canEdit: PropTypes.bool.isRequired,
  // The job seeker id
  jobSeekerId: PropTypes.string,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The function () => void to invoke when downloading resume
  onDownloadResume: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The access level that the viewer has on the profile.
    accessLevel: PropTypes.oneOf(accessLevels),
    // The resume of the job seeker.
    resume: PropTypes.shape({
      // The ID of the resume.
      id: PropTypes.string.isRequired,
      // The MIME type of the resume.
      type: PropTypes.string.isRequired,
    }),
  }),
};

export default ProfileResume;
