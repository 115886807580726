/**
 * English translations for utilities/language-levels.
 *
 * @module languages/en/utilities/language-levels
 */
const languageLevels = {
  ADVANCED: 'Advanced',
  BASIC: 'Basic',
  INTERMEDIATE: 'Intermediate',
  NATIVE: 'Native',
  NO: 'No',
};

export default languageLevels;
