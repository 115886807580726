/**
 * Greek translations for job-seekers/profile-resume.
 *
 * @module languages/el/job-seekers/profile-resume
 */
const profileResume = {
  download_failed_error: 'Η λήψη απέτυχε.',
  download_resume_button_label: 'Κατεβάστε το βιογραφικό',
  editor_title: 'Βιογραφικό',
  load_failed_error: 'Η φόρτωση απέτυχε.',
  no_resume_message: 'Ανεβάστε το βιογραφικό σας εδώ.',
  title: 'Βιογραφικό',
  too_large_document_error: 'Ανέβασε ένα έγγραφο έως 4MB.',
  unsupported_document_format_error: 'Ανεβάστε ένα έγγραφο σε μορφή PDF ή DOCX.',
};

export default profileResume;
