import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from 'components/common/date-picker/date-picker';
import MultiSelect from 'components/common/multi-select/multi-select';
import Select from 'components/common/select/select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';
import qualificationTypes from 'utilities/qualification-types';

const ProfileQualificationsEditor = memo((props) => {
  const { profile, onSave, onCancel, allSkills, qualificationIndex } = props;

  const qualification = useMemo(() => {
    return profile?.qualifications?.[qualificationIndex];
  }, [ profile?.qualifications, qualificationIndex ]);

  const { i18n, t } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  const { control, formState: { errors }, handleSubmit, register, watch, setValue }
    = useFormWithSchemaBuilder((Joi) => {
      return Joi.object({
        credentialUrl: Joi.string().uri().max(256).allow(null).allow(''),
        description: Joi.string().max(512).allow(null).allow(''),
        expiresIn: Joi.alternatives().conditional('notSubjectToExpiration', {
          is: true,
          otherwise: Joi.date().min(Joi.ref('issuedIn')).allow(null),
          then: Joi.date().valid(null),
        }),
        issuedIn: Joi.date().max('now').allow(null),
        notSubjectToExpiration: Joi.boolean().required(),
        organization: Joi.string().min(1).max(64).required(),
        skills: Joi.array().max(16).items(Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...allSkills.map((s) => {
            return s.id;
          })),
        })),
        title: Joi.string().min(1).max(64).required(),
        type: Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...qualificationTypes),
        }).required(),
      });
    });

  // Whether the credential expires.
  const notSubjectToExpiration = watch('notSubjectToExpiration');

  const comparator = useCallback((q1, q2) => {
    const ii1 = DateTime.fromISO(q1.issuedIn);
    const ii2 = DateTime.fromISO(q2.issuedIn);
    if (ii1 > ii2) {
      return -1;
    }
    if (ii1 < ii2) {
      return 1;
    }
    const t1 = q1.title;
    const t2 = q2.title;
    return t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
  }, []);

  // Clear the date of expiry if the credential does not expire.
  useEffect(() => {
    if (true === notSubjectToExpiration) {
      setValue('expiresIn', null);
    }
  }, [ notSubjectToExpiration, setValue ]);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      qualifications: profile?.qualifications?.map((qualification) => {
        return {
          credentialUrl: qualification?.credentialUrl || '',
          description: qualification?.description || '',
          expiresIn: qualification?.expiresIn || undefined,
          issuedIn: qualification?.issuedIn || undefined,
          notSubjectToExpiration: qualification?.notSubjectToExpiration === undefined ? false
            : qualification?.notSubjectToExpiration,
          organization: qualification?.organization || '',
          skills: qualification?.skills || [],
          subjectToExpiration: undefined === qualification?.subjectToExpiration
            ? false
            : qualification.subjectToExpiration,
          title: qualification?.title || '',
          type: qualification?.type || '',
        };
      }),
    };
  }, [ profile?.qualifications ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // the expires in field to be updated
  const expiresInFieldToBeUpdated = useCallback((expiresIn, notSubjectToExpiration) => {
    if (null === expiresIn || true === notSubjectToExpiration) {
      return undefined;
    }
    return DateTime.fromJSDate(expiresIn).toISODate();
  }, []);

  // the issued in field to be updated
  const issuedInFieldToBeUpdated = useCallback((issuedIn) => {
    if (null === issuedIn) {
      return undefined;
    }
    return DateTime.fromJSDate(issuedIn).toISODate();
  }, []);

  // the skills' field to be updated
  const skillsFieldToBeUpdated = useCallback((skills) => {
    return skills?.map((skill) => {
      return skill.value;
    });
  }, []);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const qualification = {
      ...values,
      expiresIn: expiresInFieldToBeUpdated(values?.expiresIn, values?.notSubjectToExpiration),
      issuedIn: issuedInFieldToBeUpdated(values?.issuedIn),
      skills: skillsFieldToBeUpdated(values?.skills),
      subjectToExpiration: !values.notSubjectToExpiration,
      type: values.type?.value || undefined,
    };
    const qualifications = [
      ...profile.qualifications.slice(0, qualificationIndex),
      qualification,
      ...profile.qualifications.slice(qualificationIndex + 1),
    ];
    qualifications.sort(comparator);
    const paramsToBeUpdated = {
      qualifications,
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [
    comparator,
    expiresInFieldToBeUpdated,
    issuedInFieldToBeUpdated,
    onSave,
    profile.qualifications,
    qualificationIndex,
    skillsFieldToBeUpdated,
    valuesAreToBeUpdated,
  ]);

  // The title empty string error
  const titleEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors?.title?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.title_required_error') }
      </span>
    );
  }, [ errors?.title?.type, t ]);

  // The title field
  const titleField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.title_label') }
          <input
            className={
              clsx({
                'error': errors?.title,
              })
            }
            defaultValue={ qualification?.title || '' }
            maxLength={ 128 }
            placeholder={ t('job_seekers:profile_qualifications.title_placeholder') }
            type='text'
            { ...register('title') }
          />
        </label>
        { titleEmptyStringError }
      </div>
    );
  }, [ errors?.title, qualification?.title, register, t, titleEmptyStringError ]);

  // The organization empty string error
  const organizationEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors?.organization?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.organization_required_error') }
      </span>
    );
  }, [ errors?.organization?.type, t ]);

  // The organization field
  const organizationField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.organization_label') }
          <input
            className={
              clsx({
                'error': errors?.organization,
              })
            }
            defaultValue={ qualification?.organization || '' }
            maxLength={ 128 }
            placeholder={ t('job_seekers:profile_qualifications.organization_placeholder') }
            type='text'
            { ...register('organization') }
          />
        </label>
        { organizationEmptyStringError }
      </div>
    );
  }, [ errors?.organization, organizationEmptyStringError, qualification?.organization, register, t ]);

  // The qualification type options
  const qualificationTypeOptions = useMemo(() => {
    return qualificationTypes.map((qualificationType) => {
      return { label: t(`utilities:qualification_types.${ qualificationType }`), value: qualificationType };
    });
  }, [ t ]);

  // The qualification type select component
  const qualificationSelectComponent = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        className={
          clsx({
            'error': errors?.type,
          })
        }
        options={ qualificationTypeOptions }
        placeholder={ t('job_seekers:profile_qualifications.type_placeholder') }
      />
    );
  }, [ errors?.type, qualificationTypeOptions, t ]);

  // The qualification type field error
  const qualificationTypeFieldError = useMemo(() => {
    if ('object.base' !== errors?.type?.type ) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.type_required_error') }
      </span>
    );
  }, [ errors?.type?.type, t ]);

  // The qualification type default value
  const qualificationTypeDefaultValue = useMemo(() => {
    if (qualification?.type === undefined) {
      return null;
    }
    return {
      label: t(`utilities:qualification_types.${ qualification.type }`),
      value: qualification.type,
    };
  }, [ qualification?.type, t ]);

  // The qualification type field
  const qualificationTypeField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.type_label') }
          <Controller
            control={ control }
            defaultValue={ qualificationTypeDefaultValue }
            name='type'
            render={ qualificationSelectComponent }
          />
        </label>
        { qualificationTypeFieldError }
      </div>
    );
  }, [ control, qualificationSelectComponent, qualificationTypeDefaultValue, qualificationTypeFieldError, t ]);

  // The issued in date picker
  const issuedInDatePicker = useCallback(({ field }) => {
    return (
      <DatePicker
        { ...field }
        className={
          clsx({
            'error': errors?.issuedIn,
          })
        }
        placeholder={ t('job_seekers:profile_qualifications.issued_in_placeholder') }
        type='month-year'
      />
    );
  }, [ errors?.issuedIn, t ]);

  // The issued in field error
  const issuedInFieldError = useMemo(() => {
    if ( 'date.max' !== errors?.issuedIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.issued_in_after_now_error') }
      </span>
    );
  }, [ errors?.issuedIn?.type, t ]);

  // The issued in default value
  const issuedInFieldDefaultValue = useMemo(() => {
    if (undefined === qualification?.issuedIn) {
      return null;
    }
    return DateTime.fromISO(qualification.issuedIn).toJSDate();
  }, [ qualification?.issuedIn ]);

  // The issued in field
  const issuedInField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.issued_in_label') }
          <Controller
            control={ control }
            defaultValue={ issuedInFieldDefaultValue }
            name='issuedIn'
            render={ issuedInDatePicker }
          />
        </label>
        { issuedInFieldError }
      </div>
    );
  }, [ control, issuedInDatePicker, issuedInFieldDefaultValue, issuedInFieldError, t ]);

  // The expires in date picker
  const expiresInDatePicker = useCallback(({ field }) => {
    return (
      <DatePicker
        { ...field }
        className={
          clsx({
            'error': errors?.expiresIn,
          })
        }
        disabled={ notSubjectToExpiration }
        placeholder={ t('job_seekers:profile_qualifications.expires_in_placeholder') }
        type='month-year'
      />
    );
  }, [ errors?.expiresIn, notSubjectToExpiration, t ]);

  // The expires in field error
  const expiresInFieldError = useMemo(() => {
    if ('date.min' !== errors?.expiresIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.expires_in_before_issued_in_error') }
      </span>
    );
  }, [ errors?.expiresIn?.type, t ]);

  // The expires in default value
  const expiresInDefaultValue = useMemo(() => {
    if (undefined === qualification?.expiresIn) {
      return null;
    }
    return DateTime.fromISO(qualification.expiresIn).toJSDate();
  }, [ qualification?.expiresIn ]);

  // The expires in field
  const expiresInField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.expires_in_label') }
          <Controller
            control={ control }
            defaultValue={ expiresInDefaultValue }
            name='expiresIn'
            render={ expiresInDatePicker }
          />
        </label>
        { expiresInFieldError }
      </div>
    );
  }, [ control, expiresInDatePicker, expiresInDefaultValue, expiresInFieldError, t ]);

  // The not subject to expiration default value
  const notSubjectToExpirationDefaultValue = useMemo(() => {
    if (undefined === qualification?.subjectToExpiration) {
      return false;
    }
    return !qualification?.subjectToExpiration;
  }, [ qualification?.subjectToExpiration ]);

  // not expire checkbox
  const notSubjectToExpirationField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label className='inline'>
          <input
            className={
              clsx({
                'error': errors?.notSubjectToExpiration,
              })
            }
            defaultChecked={ notSubjectToExpirationDefaultValue }
            type='checkbox'
            { ...register('notSubjectToExpiration') }
          />
          { t('job_seekers:profile_qualifications.not_subject_to_expiration_label') }
        </label>
      </div>
    );
  }, [ errors?.notSubjectToExpiration, notSubjectToExpirationDefaultValue, register, t ]);

  // The credential url error
  const credentialUrlErrorField = useMemo(() => {
    if ('string.uri' !== errors?.credentialUrl?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_qualifications.credential_url_invalid_error') }
      </span>
    );
  }, [ errors?.credentialUrl?.type, t ]);

  // The credential url field
  const credentialUrlField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.credential_url_label') }
          <input
            className={
              clsx({
                'error': errors?.credentialUrl,
              })
            }
            defaultValue={ qualification?.credentialUrl || '' }
            maxLength={ 256 }
            placeholder={ t('job_seekers:profile_qualifications.credential_url_placeholder') }
            type='text'
            { ...register('credentialUrl') }
          />
        </label>
        { credentialUrlErrorField }
      </div>
    );
  }, [ credentialUrlErrorField, errors?.credentialUrl, qualification?.credentialUrl, register, t ]);

  // The description  field
  const descriptionField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.description_label') }
          <textarea
            className={
              clsx({
                'error': errors?.description,
              })
            }
            defaultValue={ qualification?.description || '' }
            maxLength={ 512 }
            placeholder={ t('job_seekers:profile_qualifications.description_placeholder') }
            rows={ 10 }
            { ...register('description') }
          />
        </label>
      </div>
    );
  }, [ errors?.description, qualification?.description, register, t ]);

  // skills multi select is disabled
  const skillsMultiSelectIsDisabled = useCallback((field) => {
    return 16 <= field.value?.length;
  }, []);

  // the skills multi select component options
  const skillsMultiSelectComponentOptions = useMemo(() => {
    return allSkills.map((skill) => {
      return { label: skill[selectedLanguage], value: skill.id };
    });
  }, [ allSkills, selectedLanguage ]);

  // The skills multi select component
  const skillsMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ skillsMultiSelectIsDisabled(field) }
        options={ skillsMultiSelectComponentOptions }
        placeholder={ t('job_seekers:profile_qualifications.skills_placeholder') }
      />
    );
  }, [ skillsMultiSelectComponentOptions, skillsMultiSelectIsDisabled, t ]);

  // The skills field default value
  const skillsFieldDefaultValue = useMemo(() => {
    if (undefined === qualification?.skills) {
      return [];
    }
    return allSkills.filter((skill) => {
      return -1 !== qualification.skills.indexOf(skill.id);
    }).map((skill) => {
      return { label: skill[selectedLanguage], value: skill.id };
    });
  }, [ allSkills, qualification?.skills, selectedLanguage ]);

  // The skills field
  const skillsField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_qualifications.skills_label') }
          <Controller
            control={ control }
            defaultValue={ skillsFieldDefaultValue }
            name='skills'
            render={ skillsMultiSelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, skillsFieldDefaultValue, skillsMultiSelectComponent, t ]);

  // function that handles when delete button is clicked
  const onDelete = useCallback(() => {
    const qualifications = [
      ...profile.qualifications.slice(0, qualificationIndex),
      ...profile.qualifications.slice(qualificationIndex + 1),
    ];
    qualifications.sort(comparator);
    const paramsToBeUpdated = {
      qualifications,
    };
    onSave(paramsToBeUpdated, true);
  }, [ comparator, onSave, profile.qualifications, qualificationIndex ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('job_seekers:profile_qualifications.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { titleField }
        { organizationField }
        { qualificationTypeField }
        { issuedInField }
        { expiresInField }
        { notSubjectToExpirationField }
        { credentialUrlField }
        { descriptionField }
        { skillsField }
        <div className='profile-editor__actions'>
          <div className='profile-editor__actions profile-editor__actions--left'>
            <button
              className='btn btn-sm btn-rounded-sm btn-red'
              onClick={ onDelete }
              type='reset'
            >
              { t('profile:common.delete_button_label') }
            </button>
          </div>
          <div className='profile-editor__actions profile-editor__actions--right'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white margin-right-1'
              onClick={ onCancel }
              type='reset'
            >
              { t('profile:common.cancel_button_label') }
            </button>
            <button
              className='btn btn-sm btn-rounded-sm btn-blue'
              disabled={ isNotEmpty(errors) }
              type='submit'
            >
              { t('profile:common.save_button_label') }
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

ProfileQualificationsEditor.displayName = 'ProfileQualificationsEditor';

ProfileQualificationsEditor.propTypes = {
  // All the skills available
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of skill
      el: PropTypes.string,
      // english translation of skill
      en: PropTypes.string,
      // the id of the skill
      id: PropTypes.string,
    })
  ),
  // The function (() => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((object) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The qualifications of the job seeker.
    qualifications: PropTypes.arrayOf(PropTypes.shape({
      // The URL to the credential.
      credentialUrl: PropTypes.string,
      // The description of the qualification.
      description: PropTypes.string,
      // The date when the credential expires.
      expiresIn: PropTypes.string,
      // The date when the qualification was issued
      issuedIn: PropTypes.string,
      // The organization that issued the credential.
      organization: PropTypes.string,
      // The IDs of the skills associated to the qualification.
      skills: PropTypes.arrayOf(PropTypes.string),
      // Whether the credential expires.
      subjectToExpiration: PropTypes.bool,
      // The title of the qualification.
      title: PropTypes.string,
      // The type of the qualification.
      type: PropTypes.oneOf(qualificationTypes),
    })),
  }),
  // The qualification index
  qualificationIndex: PropTypes.number,
};

ProfileQualificationsEditor.defaultProps = {};

export default ProfileQualificationsEditor;
