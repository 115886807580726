/**
 * Resource method to get pending endorsers profiles.
 *
 * @module resources/administrators/get-pending-endorsers
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/endorsers/profiles~Profile[]} Result
 */

/**
 * Gets the profile of pending endorsers.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/administrators/get-pending-endorsers~Result>} - The promise.
 * @static
 */
const getProfile = () => {
  return axios.request({
    method: 'get',
    url: 'administrators/endorsers/pending',
  }).then((response) => {
    return response.data;
  });
};

export default getProfile;
