/**
 * Header.
 */
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import GuestsHeader from './guests-header/guests-header';
import UsersHeader from './users-header/users-header';

const Header = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return auth?.id !== undefined;
  }, [ auth?.id ]);

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <UsersHeader />
      );
    }
    return (
      <GuestsHeader />
    );
  }, [ userAuthenticated ]);
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
