/**
 * Resource method to get the user profile via LinkedIn.
 *
 * @module resources/users/get-linkedin-profile
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/users/get-linkedin-profile~Params
 * @type {object}
 * @property {string} authorizationCode - The LinkedIn authorization code.
 */

/**
 * Result.
 *
 * @typedef {module:types/users~UsersLinkedInProfile} Result
 */

/**
 * Gets the user profile via LinkedIn.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/users/get-linkedin-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/users/get-linkedin-profile~Result>} - The promise.
 * @static
 */
const getUsersLinkedInProfile = (params) => {
  return axios.request({
    method: 'get',
    url: `/users/linkedin-profile/${ params.authorizationCode }`,
  }).then((response) => {
    return response.data;
  });
};

export default getUsersLinkedInProfile;
