/**
 * Duck for metrics.
 *
 * @module ducks/metrics
 */

/**
 * The type of actions to describe that the controlled metrics are set.
 *
 * @constant {string}
 * @default
 * @static
 */
const CONTROLLED_METRICS_SET = 'CONTROLLED_METRICS_SET';

/**
 * The type of actions to describe that the metrics are reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const CONTROLLED_METRICS_RESET = 'CONTROLLED_METRICS_RESET';

/**
 * Creates an action to set the controlled metrics.
 *
 * @param {module:types/metrics~ControlledMetrics} controlledMetrics - The controlled metrics.
 * @returns {object} - The action.
 * @static
 */
const setControlledMetrics = (controlledMetrics) => {
  return {
    controlledMetrics,
    type: CONTROLLED_METRICS_SET,
  };
};

/**
 * Creates an action to reset the metrics.
 *
 * @returns {object} - The action.
 * @static
 */
const resetMetrics = () => {
  return {
    type: CONTROLLED_METRICS_RESET,
  };
};

/**
 * The talent platform metrics
 *
 * @typedef module:ducks/metrics~TalentPlatformMetrics
 * @type {object}
 * @property {string} [companies] - The total sum of companies in the platform.
 * @property {string} [jobPositionsFromOurCompanies] - The job positions from our companies
 * @property {string} [matchedJobSeekersWithCompanies] - The number of the matched job seekers with companies
 * @property {string} [peopleHired] - The hired job seekers
 * @property {string} [peopleInterviewed] - The interviewed job seekers
 * @property {string} [profiles] - The total number of profiles
 */

/**
 * The organization metrics
 *
 * @typedef module:ducks/metrics~OrganizationMetrics
 * @type {object}
 * @property {string} [collaboratingCompanies] - The collaborating companies
 * @property {string} [employabilityServicesPerYear] - The employability services per year
 * @property {string} [findEmployabilityPerYear] - The people where found employability per year
 * @property {string} [registeredPerYear] - The registered users per year
 * @property {string} [studentsPerYear] - The students per year
 */

/**
 * Metrics State.
 *
 * @typedef module:ducks/metrics~State
 * @type {object}
 * @property {module:ducks/metrics~TalentPlatformMetrics} talentPlatformMetrics - The talent platform metrics
 * @property {module:ducks/metrics~OrganizationMetrics} organizationMetrics - The organization metrics
 */

/**
 * The initial metrics' state.
 *
 * @type {module:ducks/metrics~State}
 * @static
 */
const initialState = {
  id: undefined,
  talentPlatformMetrics: {
    profiles: '',
    // eslint-disable-next-line sort-keys
    companies: '',
    // eslint-disable-next-line sort-keys
    peopleHired: '',
    // eslint-disable-next-line sort-keys
    matchedJobSeekersWithCompanies: '',
    peopleInterviewed: '',
    // eslint-disable-next-line sort-keys
    jobPositionsFromOurCompanies: '',
  },
  // eslint-disable-next-line sort-keys
  organizationMetrics: {
    registeredPerYear: '',
    studentsPerYear: '',
    // eslint-disable-next-line sort-keys
    employabilityServicesPerYear: '',
    findEmployabilityPerYear: '',
    // eslint-disable-next-line sort-keys
    collaboratingCompanies: '',
  },
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/metrics~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/metrics~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case CONTROLLED_METRICS_SET:
    return {
      ...state,
      id: action.controlledMetrics.id,
      organizationMetrics: {
        ...state.organizationMetrics,
        collaboratingCompanies: action.controlledMetrics.collaboratingCompanies || '',
        employabilityServicesPerYear: action.controlledMetrics.employabilityServicesPerYear || '',
        findEmployabilityPerYear: action.controlledMetrics.findEmployabilityPerYear || '',
        registeredPerYear: action.controlledMetrics.registeredPerYear || '',
        studentsPerYear: action.controlledMetrics.studentsPerYear || '',
      },
      talentPlatformMetrics: {
        ...state.talentPlatformMetrics,
        companies: action.controlledMetrics.companies || '',
        jobPositionsFromOurCompanies: action.controlledMetrics.jobPositionsFromOurCompanies || '',
        matchedJobSeekersWithCompanies: action.controlledMetrics.matchedJobSeekersWithCompanies || '',
        peopleHired: action.controlledMetrics.peopleHired || '',
        peopleInterviewed: action.controlledMetrics.peopleInterviewed || '',
        profiles: action.controlledMetrics.profiles || '',
      },
    };
  case CONTROLLED_METRICS_RESET:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  resetMetrics,
  setControlledMetrics,
};

const types = {};

export {
  actions,
  reducer,
  types,
};
