/**
 * Toasts.
 */
import React, { memo } from 'react';
import Toastr from 'react-redux-toastr';

import './toasts.scss';

const Toasts = memo((_props) => {
  return (
    <Toastr
      getState={ (state) => { return state.toasts; } }
      newestOnTop
      position='top-center'
      preventDuplicates
      progressBar={ false }
      timeOut={ 15000 }
      transitionIn='fadeIn'
      transitionOut='fadeOut'
    />
  );
});

Toasts.displayName = 'Toasts';

Toasts.propTypes = {
};

Toasts.defaultProps = {
};

export default Toasts;
