/**
 * Greek translations for job-seekers/learn-more.
 *
 * @module languages/el/job-seekers/learn-more
 */
const learnMore = {
  create_cv_section: {
    button_label: 'Κατέβασε ένα παράδειγμα βιογραφικού',
    // eslint-disable-next-line max-len
    description: 'Δημιούργησε εύκολα ένα επαγγελματικό βιογραφικό σημείωμα με λίγα μόνο κλικ, χρησιμοποιώντας τον αυτοματοποιημένο δημιουργό βιογραφικού σημειώματος που αντλεί πληροφορίες απευθείας από το προφίλ σου.',
    error_downloading_cv: 'Σφάλμα κατά τη λήψη βιογραφικού',
    title: 'Δημιούργησε ένα όμορφο βιογραφικό',
  },
  different_section: {
    info: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Δημιούργησε τη διαδικτυακή σου παρουσία με ένα πλήρες προφίλ που αναδεικνύει τις δεξιότητες, την εμπειρία και την προσωπικότητά σου.',
        title: 'Δημιούργησε το προφίλ σου',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Οι ισχυροί αλγόριθμοι αναζήτησης και τα φίλτρα μας σας βοηθούν να γίνετε αντιληπτοί από κορυφαίους εργοδότες που αναζητούν τα ταλέντα και τα προσόντα σας.',
        title: 'Ανακαλυφθείτε από εταιρείες',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Με την υποστήριξη και την καθοδήγηση μας, μπορείτε να είστε άριστοι στις συνεντεύξεις, να διαπραγματευτείτε προσφορές και να εξασφαλίσετε τη δουλειά των ονείρων σας σε χρόνο μηδέν.',
        title: 'Βρές την επομένη θέση εργασίας σου',
      },
    },
    sub_title: 'Βρες την επόμενη ευκαιρία καριέρας σου σε μόλις 3 εύκολα βήματα',
    title: 'Η πορεία προς τη δουλειά των ονείρων σου',
  },
  discover_traits_section: {
    button_label: 'Κάντε την αξιολόγηση',
    // eslint-disable-next-line max-len
    description: 'Μάθετε περισσότερα για τις δεξιότητές σας χρησιμοποιώντας ερωτηματολόγια και αξιολόγηση βασισμένη σε παιχνίδια AI. Είναι εύκολο και γρήγορο και θα σας βοηθήσει να παρουσιάσετε καλύτερα τον εαυτό σας σε μια συνέντευξη.',
    title: 'Ανακαλύψτε τα χαρακτηριστικά σας',
  },
  faq_section: {
    faqs: {
      item1: {
        // eslint-disable-next-line max-len
        answer: 'Η Talent platform είναι ένας χώρος στον οποίο οι εταιρείες:\n - Εξοικονομούν χρόνο στην αναζήτηση υποψήφιων, \n - Φιλτράρουν τους υποψηφίους με βάση τις δεξιότητές τους\n - Λαμβάνουν προτάσεις υποψηφίων από τον ΑΙ αλγόριθμό μας και τους συμβούλους απασχολησιμότητάς μας\n - Συνδυάσουν τις HR ανάγκες τους με CSR πακέτα μας',
        question: 'Τι ειναι η talent platform by Odyssea?',
      },
      item2: {
        // eslint-disable-next-line max-len
        answer: 'Για να εγγραφείτε στην πλατφόρμα, πρέπει να δημιουργήσετε το προσωπικό σας προφίλ κάνοντας κλικ στο κουμπί εγγραφής στο επάνω μέρος της σελίδας.',
        question: 'Πως μπορώ να συμμετέχω στην πλατφόρμα;',
      },
      item3: {
        // eslint-disable-next-line max-len
        answer: 'Ναι, η δημιουργία του λογαριασμού σας στην πλατφόρμα είναι δωρεάν!\n\n\nΓια να επωφεληθείτε πλήρως από τα πλεονεκτήματα της πλατφόρμας μας, επιλέξτε ένα από τα πακέτα μας εδώ!',
        question: 'Η εγγραφή στην πλατφόρμα είναι δωρεάν;',
      },
      item4: {
        answer: 'Για οποιαδήποτε απορία μπορείτε να επικοινωνήσετε μαζί μας στο: talent@odyssea.com',
        question: 'Πώς μπορώ να επικοινωνήσω;',
      },
    },
    title: 'FAQ',
  },
  profile_features_section: {
    cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Ενημέρωνε διαρκώς το προφίλ σου με την πιο πρόσφατη εργασιακή και εκπαιδευτική σου εμπειρία για να προβάλλεις τα προσόντα και τα επιτεύγματά σου.',
        title: 'Ενημέρωσε την εργασιακή σου εμπειρία και τις εκπαιδεύσεις σου',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Επισήμανε τις κορυφαίες δεξιότητες και τους τομείς εξειδίκευσής σου για να ξεχωρίσεις στους πιθανούς εργοδότες και να αυξήσεις τις πιθανότητες πρόσληψής σου.',
        title: 'Ανάδειξε τα δυνατά σου χαρακτηριστικά',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Άφησε μια ανεξίτηλη εντύπωση δημιουργώντας μια παρουσίαση βίντεο για να συστηθείς και να αναδείξεις την προσωπικότητα και τις επικοινωνιακές σου δεξιότητες.',
        title: 'Δημιούργησε βίντεο αυτοπαρουσίασης ',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Δημιούργησε εύκολα ένα επαγγελματικό βιογραφικό σημείωμα με λίγα μόνο κλικ, χρησιμοποιώντας τον αυτοματοποιημένο δημιουργό βιογραφικού σημειώματος που αντλεί πληροφορίες απευθείας από το προφίλ σου.',
        title: 'Εργαλείο αυτόματης δημιουργίας βιογραφικού',
      },
      item5: {
        // eslint-disable-next-line max-len
        description: 'Συνδέσου με το δίκτυό μας έμπειρων επαγγελματιών που μπορούν να σου προσφέρουν καθοδήγηση και συμβουλές για να βελτιώσεις το προφίλ σου και να πετύχεις στην αναζήτηση εργασίας.',
        title: 'Συμβουλέψου έμπειρους επαγγελματίες',
      },
      item6: {
        // eslint-disable-next-line max-len
        description: 'Διερεύνησε διαφορετικές διαδρομές καριέρας με βάση τα ενδιαφέροντα, τις δεξιότητες και την εμπειρία σου για να βρεις τη δουλειά που σου ταιριάζει.',
        title: 'Βρές τη κατάλληλη καριέρα για εσένα',
      },
      item7: {
        // eslint-disable-next-line max-len
        description: 'Με το τεστ προσωπικότητας μπορείς να ανακαλύψεις τα δυνατά και τα αδύνατα σημεία σου και να λάβεις πληροφορίες που θα σε βοηθήσουν να εντοπίσεις τις καλύτερες επαγγελματική πορεία και ευκαιρίες απασχόλησης.',
        title: 'Ανακάλυψε τα χαρακτηριστικά της προσωπικότητάς σου',
      },
    },
    title: 'Τα χαρακτηριστικά του προφίλ σου',
  },
  request_endorsement_section: {
    button_label: 'Μάθετε περισσότερα',
    // eslint-disable-next-line max-len
    description: 'Συνδεθείτε με υποστηρικτές που μπορούν να παρέχουν πολύτιμες πληροφορίες. Μπορούν να σας βοηθήσουν με τα επόμενα βήματά σας και να παρέχουν εξαιρετικές συστάσεις σε πιθανούς εργοδότες.',
    title: 'Ζητήστε μια σύσταση',
  },
  sign_in_section: {
    title: 'Ανακαλύψτε τις δυνατότητές σας, δημιουργήστε ένα ανταγωνιστικό προφίλ και μπείτε στον κόσμο της εργασίας',
  },
  statistics_section: {
    statistics: {
      item1: {
        title: 'Προσλήψεις',
      },
      item2: {
        title: 'Προφίλ',
      },
      item3: {
        title: 'Εταιρίες',
      },
    },
  },
  testimonials_section: {
    testimonials: {
      item1: {
        item1: {
          age_location: '29 χρονών, Αθήνα',
          name: 'Awng Nu La',
          title: 'Δείτε την ιστορία του Awng Nu La’s από το Myanmar',
          video_url: 'https://www.youtube.com/embed/igBvNpJN6fA',
        },
        item2: {
          age_location: '28 χρονών, Αθήνα',
          name: 'Hana Ganji',
          title: '«Σας ευχαριστώ για την ευκαιρία να βελτιώσω τις δεξιότητές μου και να βρω δουλειά»',
          video_url: 'https://www.youtube.com/embed/IjViKd72tE8',
        },
        item3: {
          age_location: '30 χρονών, από την Guinea',
          // eslint-disable-next-line max-len
          description: 'Πρώτα από όλα θα ήθελα να εκφράσω την ευγνωμοσύνη μου στην Odyssea, για την προσπάθεια και τη βοήθεια προς όλους τους ανθρώπους. Μια φιλόξενη ομάδα, χαμογελαστή και ευαισθητοποιημένη στις ανησυχίες των ανθρώπων. Από την πλευρά μου, \n τους προτείνω σε όποιον ψάχνει απαντήσεις για τα ταλέντα του ή για το τι πρέπει να ασχοληθεί.',
          name: 'Mamadou Oury Diallo',
          title: '“Μια φιλόξενη ομάδα, χαμογελαστή και ευαισθητοποιημένη στις ανησυχίες των ανθρώπων.”',
        },
      },
      item2: {
        item1: {
          age_location: '30 χρονών, Αθήνα',
          name: 'Shah in Alon',
          title: 'Η ιστορία του Shah in Alon από το Bangladesh',
          video_url: 'https://www.youtube.com/embed/AOOuSiYLG7Q',
        },
        item2: {
          age_location: '26 χρονών, Αθήνα',
          name: 'Hamed',
          title: '”Η Odyssea μου έδωσε την ευκαιρία να εργαστώ στην ειδικότητά μου”',
          video_url: 'https://www.youtube.com/embed/UPzTNeYNvi0',
        },
        item3: {
          age_location: '18 χρονών, Gambia',
          // eslint-disable-next-line max-len
          description: 'Momodou θέλοντας να ενθαρρύνει την αδερφή του, μπήκε ο ίδιος στο μάθημα για ΞενοδοχειακόςΥπάλληλος. Μετά την αποφοίτησή του, παρακολούθησε το Odyssea Job Fair και εξασφάλισε δουλειά σε έναν κορυφαίο ξενοδοχειακό όμιλο',
          name: 'Momodou Jallow',
          // eslint-disable-next-line max-len
          title: '«Θέλω να κάνω την αδερφή μου να πιστέψει ότι μπορεί να ακολουθήσει τα όνειρά της. Θέλω να ευχαριστήσω την Odyssea και τους συνεργάτες της για την υποστήριξή τους στο κυνήγι της ευτυχίας μου».',
        },
      },
      item3: {
        item1: {
          age_location: 'Σεράφειο, Αθήνα',
          name: 'Job Fair',
          title: 'Job Fair “ΘΕΛΗΣΗ” με έμφαση στην απασχολησιμότητα και τον εθελοντισμό',
          video_url: 'https://www.youtube.com/embed/5kIPN3Bob80',
        },
        item2: {
          age_location: 'Αθήνα',
          name: 'Τεχνική κατάρτιση',
          title: 'Tailor Made Course με την Mathioudakis Elevators',
          video_url: 'https://www.youtube.com/embed/seAWxtSWLYU',
        },
        item3: {
          age_location: 'από την Αθήνα',
          // eslint-disable-next-line max-len
          description: '" Ήρθα στην Odyssea και παρακολούθησα το σεμινάριο τροφοδοσίας και εξυπηρέτησης προκειμένου να αποκτήσω τις επαγγελματικές δεξιότητες που απαιτούνται για να αποκτήσω μια νέα θέση εργασίας. Τελικά, κατάφερα να βρω δουλειά και μπόρεσα να ενσωματωθώ αμέσως στον εργασιακό χώρο".',
          name: 'Ελευθερία Σ.',
          // eslint-disable-next-line max-len
          title: '"Η τύχη με έφερε στην Odyssea, η ομάδα και όλα όσα έμαθα ήταν η ικανοποίηση του να βρίσκομαι σε ένα περιβάλλον τόσο ωραίο που ήταν ταυτόχρονα και αυθεντικό."',
        },
      },
    },
    title: 'Οι ιστορίες των υποψηφίων μας',
  },
};

export default learnMore;
