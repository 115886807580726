/**
 * Job seekers - Profile - Competencies.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import accessLevels, * as AccessLevels from 'utilities/access-levels';
import roles from 'utilities/auth/roles';
import { formatDate } from 'utilities/chisels';
import computerSkillsLevels from 'utilities/computer-skills-levels';
import drivingLicenseCategories from 'utilities/driving-license-categories';
import * as JobSeekerParts from 'utilities/job-seekers/parts';
import workAvailabilities from 'utilities/work-availabilities';

import ProfileCompetenciesEditor from './profile-competencies-editor/profile-competencies-editor';

import './profile-competencies.scss';

const ProfileCompetencies = memo((props) => {
  const { profile, onChange, canEdit, userRole } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The strict access levels
  const strictAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The computer skills field
  const computerSkillsField = useMemo(() => {
    if (!profile?.computerSkillsLevel) {
      return null;
    }
    return (
      <ProfileInfoRow
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_competencies.computer_skills') }: ` }
        value={ t(`utilities:computer_skills_levels.${ profile.computerSkillsLevel }`) }
      />
    );
  }, [ profile?.computerSkillsLevel, t ]);

  // The driving license categories field value
  const drivingLicenseCategoriesFieldValue = useMemo(() => {
    return profile?.drivingLicenseCategories?.map((drivingLicense) => {
      return t(`utilities:driving_license_categories.${ drivingLicense }`);
    }).join(', ');
  }, [ profile?.drivingLicenseCategories, t ]);

  // The driving license category field
  const drivingLicenseCategoryField = useMemo(() => {
    if (!profile?.drivingLicenseCategories?.length) {
      return null;
    }
    return (
      <ProfileInfoRow
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_competencies.driving_licenses') }: ` }
        value={ drivingLicenseCategoriesFieldValue }
      />
    );
  }, [ drivingLicenseCategoriesFieldValue, profile?.drivingLicenseCategories?.length, t ]);

  // The work authorization field value
  const workAuthorizationFieldValue = useMemo(() => {
    if (!profile?.authorizedToWork) {
      return t('job_seekers:profile_competencies.no');
    }
    return t('job_seekers:profile_competencies.yes');
  }, [ profile?.authorizedToWork, t ]);

  // The work authorization expiry value
  const workAuthorizationExpiryValue = useMemo(() => {
    if (undefined === profile?.workAuthorizationExpiresOn) {
      return '';
    }
    return `${ t('job_seekers:profile_competencies.expires_on') } 
    ${ formatDate(profile.workAuthorizationExpiresOn, 'dd LLL yyyy', selectedLanguage) }`;
  }, [ profile.workAuthorizationExpiresOn, selectedLanguage, t ]);

  // The authorized to work field
  const authorizedToWorkField = useMemo(() => {
    if (!strictAccessLevels.includes(profile?.accessLevel) || profile?.authorizedToWork === undefined) {
      return null;
    }
    return (
      <ProfileInfoRow
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_competencies.work_authorization') }: ` }
        value={ `${ workAuthorizationFieldValue }, ${ workAuthorizationExpiryValue }` }
      />
    );
  }, [
    profile?.accessLevel,
    profile?.authorizedToWork,
    strictAccessLevels,
    t,
    workAuthorizationExpiryValue,
    workAuthorizationFieldValue,
  ]);

  // The work availability field
  const workAvailabilityField = useMemo(() => {
    if (!strictAccessLevels.includes(profile?.accessLevel) || profile?.workAvailability === undefined) {
      return null;
    }
    return (
      <ProfileInfoRow
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_competencies.work_availability') }: ` }
        value={ t(`utilities:work_availabilities.${ profile.workAvailability }`) }
      />
    );
  }, [ profile?.accessLevel, profile.workAvailability, strictAccessLevels, t ]);

  // The willing to relocate value field
  const willingToRelocateFieldValue = useMemo(() => {
    if (!profile?.willingToRelocate) {
      return t('job_seekers:profile_competencies.no');
    }
    return t('job_seekers:profile_competencies.yes');
  }, [ profile?.willingToRelocate, t ]);

  // The willing to relocate field
  const willingToRelocate = useMemo(() => {
    if (!strictAccessLevels.includes(profile?.accessLevel) || profile?.willingToRelocate === undefined) {
      return null;
    }
    return (
      <ProfileInfoRow
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_competencies.willing_to_relocate') }: ` }
        value={ willingToRelocateFieldValue }
      />
    );
  }, [ profile?.accessLevel, profile?.willingToRelocate, strictAccessLevels, t, willingToRelocateFieldValue ]);

  // function that handles the change of profile competencies
  const onProfileCompetenciesChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, JobSeekerParts.COMPETENCIES, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileCompetenciesEditor
          onCancel={ toggleEditor(false) }
          onSave={ onProfileCompetenciesChange }
          profile={ profile }
          userRole={ userRole }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onProfileCompetenciesChange, profile, toggleEditor, userRole ]);

  return (
    <div className='profile-competencies'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('job_seekers:profile_competencies.title') }
      />
      <div className='profile-competencies__fields'>
        { computerSkillsField }
        { drivingLicenseCategoryField }
        { authorizedToWorkField }
        { workAvailabilityField }
        { willingToRelocate }
      </div>
      { editorModal }
    </div>
  );
});

ProfileCompetencies.displayName = 'ProfileCompetencies';

ProfileCompetencies.propTypes = {
  // Whether the user can edit the competencies
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The access level that the viewer has on the profile.
    accessLevel: PropTypes.oneOf(accessLevels),
    // Whether the job seeker is authorized to work.
    authorizedToWork: PropTypes.bool,
    // The level of the computer skills that the job seeker has.
    computerSkillsLevel: PropTypes.oneOf(computerSkillsLevels),
    // The categories of the driving licenses that the job seeker has.
    drivingLicenseCategories: PropTypes.arrayOf(PropTypes.oneOf(drivingLicenseCategories)),
    // Whether the job seeker is willing to relocate.
    willingToRelocate: PropTypes.bool,
    // The date when the work authorization of the job seeker expires.
    workAuthorizationExpiresOn: PropTypes.string,
    // The work availability of the job seeker.
    workAvailability: PropTypes.oneOf(workAvailabilities),
  }),
  // The signed in user role
  userRole: PropTypes.oneOf(roles),
};

ProfileCompetencies.defaultProps = {};

export default ProfileCompetencies;
