/**
 * Company Profile parts.
 *
 * @module utilities/companies/parts
 */

/**
 * About me.
 *
 * @constant {string}
 * @static
 */
export const ABOUT_ME = 'ABOUT_ME';

/**
 * Background image.
 *
 * @constant {string}
 * @static
 */
export const BACKGROUND_IMAGE = 'BACKGROUND_IMAGE';

/**
 * Personal.
 *
 * @constant {string}
 * @static
 */
export const PERSONAL = 'PERSONAL';

/**
 * PROFESSIONAL.
 *
 * @constant {string}
 * @static
 */
export const PROFESSIONAL = 'PROFESSIONAL';

/**
 * Profile picture.
 *
 * @constant {string}
 * @static
 */
export const PROFILE_PICTURE = 'PROFILE_PICTURE';

export default [
  ABOUT_ME,
  BACKGROUND_IMAGE,
  PERSONAL,
  PROFESSIONAL,
  PROFILE_PICTURE,
];
