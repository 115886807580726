/**
 * Resource method to update the controlled metrics.
 *
 * @module resources/metrics/update-controlled-metrics
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef {module:types/metrics~ControlledMetrics} Params
 */

/**
 * Result.
 *
 * @typedef {module:types/metrics~ControlledMetrics} Result
 */

/**
 * Updates the controlled metrics.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/metrics/update-controlled-metrics~Params} params - The params
 * @returns {Promise<void>} - The promise.
 * @static
 */
const updateControlledMetrics = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/metrics/${ params.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default updateControlledMetrics;
