import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import Location from 'images/location.png';
import { isEmpty, isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import communicationCooperationSkillsLevels
  from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import './profile-endorsement-card.scss';

const ProfileEndorsementCard = memo((props) => {
  const { endorsement, endorsementIndex, onViewReviewButtonClick } = props;

  const { t } = useTranslation();

  // The endorsee profile
  const endorseeProfile = useMemo(() => {
    return endorsement?.endorsee;
  }, [ endorsement?.endorsee ]);

  // The endorsee profile picture
  const userProfilePicture = useMemo(() => {
    if (undefined === endorseeProfile?.profilePicture) {
      return (
        <CircularImage
          alt='Default image'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='endorsee image'
        imageSource={
          `${ env.CORE_BASE_URL }/job-seekers/${ endorseeProfile?.id }
        /profile/picture?id=${ endorseeProfile?.profilePicture?.id }`
        }
      />
    );
  }, [ endorseeProfile?.id, endorseeProfile?.profilePicture ]);

  // The location display string of the endorsee location
  const locationDisplayString = useMemo(() => {
    let city = '';
    if (endorseeProfile?.location?.city) {
      city = t(`utilities:cities.${ endorseeProfile?.location?.city }`);
    }
    return [
      endorseeProfile?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ endorseeProfile?.location?.city, endorseeProfile?.location?.district, t ]);

  // The location section
  const locationSection = useMemo(() => {
    if (isEmpty(endorseeProfile?.location)) {
      return null;
    }
    return (
      <div className='profile-endorsement-card__location-container'>
        <img alt='endorsee location' className='profile-endorsement-card__location-icon' src={ Location } />
        <span className='txt txt-sm'>
          { locationDisplayString }
        </span>
      </div>
    );
  }, [ endorseeProfile?.location, locationDisplayString ]);

  // The function to be invoked when view review is clicked
  const handleViewReviewClicked = useCallback(() => {
    onViewReviewButtonClick(endorsementIndex);
  }, [ endorsementIndex, onViewReviewButtonClick ]);

  return (
    <div className='profile-endorsement-card'>
      <div className='profile-endorsement-card__top-section'>
        <div className='profile-endorsement-card__header'>
          <div className={
            clsx('profile-endorsement-card__header-picture-container', {
              'no-picture': undefined === endorseeProfile?.profilePicture,
            })
          }
          >
            { userProfilePicture }
          </div>
          <div className='profile-endorsement-card__name-container dark'>
            <p className='txt txt-md txt-bold'>
              { `${ endorseeProfile?.firstName },` }
            </p>
            <p className='txt txt-md txt-bold'>
              { endorseeProfile?.lastName }
            </p>
          </div>
        </div>
        { locationSection }
        <div className='profile-endorsement-card__overview-container'>
          <p className='txt txt-sm txt-bold'>
            { t('endorsers:endorsement_card.endorsement_overview_title') }
          </p>
          <ul className='profile-endorsement-card__overview-list'>
            <li className='profile-endorsement-card__overview-list-item txt txt-sm'>
              {
                t('endorsers:endorsement_card.overview_list.motivation',
                  { level: `${ endorsement?.motivationScore } / 5` })
              }
            </li>
            <li className='profile-endorsement-card__overview-list-item txt txt-sm'>
              {
                t('endorsers:endorsement_card.overview_list.communication',
                  { level: `${ endorsement?.communicationCooperationScore } / 5` })
              }
            </li>
            <li className='profile-endorsement-card__overview-list-item txt txt-sm'>
              {
                t('endorsers:endorsement_card.overview_list.critical_thinking',
                  { level: `${ endorsement?.criticalThinkingScore } / 5` })
              }
            </li>
            <li className='profile-endorsement-card__overview-list-item txt txt-sm'>
              {
                t('endorsers:endorsement_card.overview_list.professionalism',
                  { level: `${ endorsement?.professionalismScore } / 5` })
              }
            </li>
          </ul>
        </div>
      </div>
      <div className='profile-endorsement-card__bottom-section'>
        <Link
          className='link txt txt-sm txt-bold'
          to={ `/job-seekers/${ endorseeProfile?.id }/profile` }
        >
          { t('endorsers:endorsement_card.see_profile_link') }
        </Link>
        <button
          className='profile-endorsement-card__confirm-button btn btn-sm btn-rounded-sm btn-blue'
          onClick={ handleViewReviewClicked }
        >
          { t('endorsers:endorsement_card.processed_button_title') }
        </button>
      </div>
    </div>
  );
});

ProfileEndorsementCard.displayName = 'ProfileEndorsementCard';

ProfileEndorsementCard.propTypes = {
  // The endorsement
  endorsement: PropTypes.shape({
    // The communication and cooperation skills level score
    communicationCooperationScore: PropTypes.number,
    // The communication and cooperation skills level
    communicationCooperationSkillsLevel: PropTypes.oneOf(communicationCooperationSkillsLevels),
    // The critical thinking level
    criticalThinkingLevel: PropTypes.oneOf(criticalThinkingLevels),
    // The critical thinking score
    criticalThinkingScore: PropTypes.number,
    // The employer feedback
    employerFeedback: PropTypes.string,
    // The endorsee profile
    endorsee: PropTypes.shape({
      // The first name of the endorsee
      firstName: PropTypes.string,
      // The id of the endorsee
      id: PropTypes.string,
      // The last name of the endorsee
      lastName: PropTypes.string,
      // The location where the endorsee is.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The profile picture of the endorsee.
      profilePicture: PropTypes.shape({
        // The ID of the picture.
        id: PropTypes.string,
        // The MIME type of the picture.
        type: PropTypes.string,
      }),
    }),
    // The endorser id
    endorser: PropTypes.string,
    // The hashtags UUID array
    hashtags: PropTypes.arrayOf(PropTypes.string),
    // The id of the endorsement
    id: PropTypes.string,
    // The job seeker feedbacks array
    jobSeekerFeedbacks: PropTypes.arrayOf(PropTypes.oneOf(jobSeekerFeedbackLevels)),
    // The motivation level
    motivationLevel: PropTypes.oneOf(motivationalLevels),
    // The motivation score
    motivationScore: PropTypes.number,
    // The professional characteristics array
    professionalCharacteristics: PropTypes.arrayOf(PropTypes.oneOf(professionalCharacteristicLevels)),
    // The profesionalism score
    professionalismScore: PropTypes.number,
    // The request id
    requestId: PropTypes.string,
    // The testimonial
    testimonial: PropTypes.string,
  }),
  // The endorsement index
  endorsementIndex: PropTypes.number,
  // function (endorsementIndex) => void to be invoked when view review button is clicked
  onViewReviewButtonClick: PropTypes.func,
};

ProfileEndorsementCard.defaultProps = {};

export default ProfileEndorsementCard;
