/**
 * English translations for job-seekers/learn-more.
 *
 * @module languages/en/job-seekers/learn-more
 */
const learnMore = {
  create_cv_section: {
    button_label: 'Download an example CV',
    // eslint-disable-next-line max-len
    description: 'Easily generate a professional-looking CV with just a few clicks, using our automated CV creator that pulls information directly from your profile.',
    error_downloading_cv: 'Error Downloading CV',
    title: 'Create a beautiful CV',
  },
  different_section: {
    info: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Build your online presence by creating a complete profile that showcases your skills, experience, and personality.',
        title: 'Create your profile',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Our powerful search algorithms and filters help you get noticed by top employers who are looking for your talents and qualifications.',
        title: 'Get discovered by companies',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'With our support, guidance, and resources, you can ace interviews, negotiate offers, and secure your dream job in no time.',
        title: 'Land your next job',
      },
    },
    sub_title: 'Find your next career opportunity in just 3 easy steps',
    title: 'Your Path to Your Dream Job',
  },
  discover_traits_section: {
    button_label: 'Take Assessment',
    // eslint-disable-next-line max-len
    description: 'Learn more about your skills using questionnaires and AI game-based assessment. It is easy and quick and will help you best present your self in an interview.',
    title: 'Discover your traits',
  },
  faq_section: {
    faqs: {
      item1: {
        // eslint-disable-next-line max-len
        answer: 'The talent platform is a virtual space that companies can:\n  - Save time looking for talent by joining a pool of verified jobseekers.\n  - Filter candidates by their skills.\n  - Get candidate recommendations by our Al talent recommender and our employability officers.\n  - Combine your HR needs with CSR packages to scale social impact.',
        question: 'What is the talent platform by Odyssea?',
      },
      item2: {
        // eslint-disable-next-line max-len
        answer: 'To join the platform, you have to create your personal profile by clicking the sign-up button on the top of the page.',
        question: 'How can I join the platform?',
      },
      item3: {
        // eslint-disable-next-line max-len
        answer: 'Yes, creating your account in the platform is for free!\n\nTo get the full advantages of our platform choose one of our pricing plans here!',
        question: 'Is the registration in the platform free?',
      },
      item4: {
        answer: 'For any questions you can contact us at: talent@odyssea.com',
        question: 'How can I get in touch?',
      },
    },
    title: 'FAQ',
  },
  profile_features_section: {
    cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Keep your profile up-to-date with your latest work and education experience to showcase your qualifications and achievements.',
        title: 'Update your work and education',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Highlight your top skills and areas of expertise to stand out to potential employers and increase your chances of getting hired.',
        title: 'Showcase your best skills',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Make a lasting impression by creating a video presentation to introduce yourself and showcase your personality and communication skills.',
        title: 'Create personalised video presentation',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Easily generate a professional-looking CV with just a few clicks, using our automated CV creator that pulls information directly from your profile.',
        title: 'Automated CV Creator',
      },
      item5: {
        // eslint-disable-next-line max-len
        description: 'Connect with our network of experienced professionals who can offer guidance and advice to help you improve your profile and succeed in your job search.',
        title: 'Receive guidance by experienced professionals',
      },
      item6: {
        // eslint-disable-next-line max-len
        description: 'Explore different career paths based on your interests, skills, and experience to find the job that\'s right for you.',
        title: 'Find your suitable career paths',
      },
      item7: {
        // eslint-disable-next-line max-len
        description: 'Take personality tests to discover your strengths and weaknesses, and get insights to help you identify the best career paths and job opportunities.',
        title: 'Discover your personality traits',
      },
    },
    title: 'Your profile features',
  },
  request_endorsement_section: {
    button_label: 'Learn More',
    // eslint-disable-next-line max-len
    description: 'Connect with endorsers that can provide valuable insights. They can help you with your next steps and provide great recommendations to possible employers.',
    title: 'Request an endorsement',
  },
  sign_in_section: {
    title: 'Discover your full potential, create your competitive profile and enter the world of work',
  },
  statistics_section: {
    statistics: {
      item1: {
        title: 'People found job',
      },
      item2: {
        title: 'Profiles',
      },
      item3: {
        title: 'Companies',
      },
    },
  },
  testimonials_section: {
    testimonials: {
      item1: {
        item1: {
          age_location: '29 years old, Athens',
          name: 'Awng Nu La',
          title: 'Awng Nu La’s from Myanmar empowering story',
          video_url: 'https://www.youtube.com/embed/igBvNpJN6fA',
        },
        item2: {
          age_location: '28 years old, Athens',
          name: 'Hana Ganji',
          title: '“Thank you about this opportunity to improve my skills and find a job”',
          video_url: 'https://www.youtube.com/embed/IjViKd72tE8',
        },
        item3: {
          age_location: '30 years old from Guinea',
          // eslint-disable-next-line max-len
          description: 'First of all I express all my gratitude and thanks to Odyssea, for the effort and the help towards all the people on the training until obtaining work and good integration. A welcoming team, smiling and attentive to people\'s concerns. For my part, I suggest to anyone looking for answers about their talents or what they should get into. Please accept the expression of my best regards!',
          name: 'Mamadou Oury Diallo',
          title: '“A welcoming team, smiling and attentive to people\'s concerns.”',
        },
      },
      item2: {
        item1: {
          age_location: '30 years old, Athens',
          name: 'Shah in Alon',
          title: 'The story of Shah in Alon from Bangladesh',
          video_url: 'https://www.youtube.com/embed/AOOuSiYLG7Q',
        },
        item2: {
          age_location: '26 years old, Athens',
          name: 'Hamed',
          title: '”Odyssea gave me the opportunity to work in my specialisation”',
          video_url: 'https://www.youtube.com/embed/UPzTNeYNvi0',
        },
        item3: {
          age_location: '18 years old from Gambia',
          // eslint-disable-next-line max-len
          description: 'Momodou wanting to encourage his sister, entered the course for Hotel Employee himself. Upon graduation, he attended the Odyssea Job Fair and secured a job within a top hotel group.',
          name: 'Momodou Jallow',
          // eslint-disable-next-line max-len
          title: '“I want to make my sister believe that she can follow her dreams. I want to thank Odyssea and its partners for their support in my pursuit of happiness”',
        },
      },
      item3: {
        item1: {
          age_location: 'Serafeio, Athens',
          name: 'Job Fair',
          title: 'Job Fair “ΘΕΛΗΣΗ” focusing on employability and volunteering',
          video_url: 'https://www.youtube.com/embed/5kIPN3Bob80',
        },
        item2: {
          age_location: 'Athens',
          name: 'Technical Training',
          title: 'Tailor Made Course with Mathioudakis Elevators',
          video_url: 'https://www.youtube.com/embed/seAWxtSWLYU',
        },
        item3: {
          age_location: 'from Athens',
          // eslint-disable-next-line max-len
          description: '“I joined Odyssea and attended the catering and service course in order to acquire the professional skills needed to obtain a new job position. Eventually, I was able to find a job and was able to integrate into the workplace immediately.”',
          name: 'Eleftheria S.',
          // eslint-disable-next-line max-len
          title: '"Luck brought me to Odyssea, the team and everything I learned was the satisfaction of being in an environment so nice that it was also authentic."',
        },
      },
    },
    title: 'Job seekers tell their stories',
  },
};

export default learnMore;
