/**
 * Profile Overview
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import env from 'config/env';
import defaultProfilePicture from 'images/default-profile-picture.png';
import locationImage from 'images/location.png';
import tagImage from 'images/tag.png';
import roles, * as ROLES from 'utilities/auth/roles';
import { isNotEmpty } from 'utilities/chisels';

import './profile-overview.scss';
import DashboardWorkAvailability from '../../job-seekers/dashboard-work-availability/dashboard-work-availability';

const ProfileOverview = memo((props) => {
  // The props
  const { companyId, userId, profile, role, companyJobSector, location, onWorkAvailabilityChange } = props;

  const { t } = useTranslation();

  // Whether the user needs a placeholder image
  const addPlaceholderImage = useMemo(() => {
    return undefined === profile?.profilePicture && undefined === profile?.logo;
  }, [ profile?.logo, profile?.profilePicture ]);

  // The image source
  const overviewImageSource = useMemo(() => {
    switch (role) {
    case ROLES.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ userId }/profile/picture?id=${ profile?.profilePicture?.id }`;
    case ROLES.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ companyId }/profile/logo?id=${ profile?.logo?.id }`;
    case ROLES.ENDORSER:
      return `${ env.CORE_BASE_URL }/endorsers/${ userId }/profile/picture?id=${ profile?.profilePicture?.id }`;
    default:
      return '';
    }
  }, [ companyId, profile?.logo?.id, profile?.profilePicture?.id, role, userId ]);

  // Overview image
  const overviewImage = useMemo(() => {
    if (addPlaceholderImage) {
      return (
        <CircularImage
          alt='Default dashboard overview image'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='Dashboard overview image'
        imageSource={ overviewImageSource }
      />
    );
  }, [ addPlaceholderImage, overviewImageSource ]);

  // The name displayed
  const overviewName = useMemo(() => {
    if (undefined === profile?.firstName && undefined === profile?.lastName) {
      return null;
    }
    if (role === ROLES.JOB_SEEKER || role === ROLES.ENDORSER) {
      return `${ profile?.firstName || '' }\n${ profile?.lastName || '' }`;
    }
    return `${ profile?.companyName }`;
  }, [ profile?.companyName, profile?.firstName, profile?.lastName, role ]);

  // The description displayed
  const overviewDescription = useMemo(() => {
    if (role === ROLES.JOB_SEEKER || role === ROLES.ENDORSER) {
      return profile?.shortBio;
    }
    return profile?.description;
  }, [ profile?.description, profile?.shortBio, role ]);

  // Whether to render the short bio - description
  const renderDescription = useMemo(() => {
    if (((role === ROLES.JOB_SEEKER || role === ROLES.ENDORSER) && !profile?.shortBio)
      || (role === ROLES.COMPANY_AGENT && !profile?.description)) {
      return null;
    }
    return (
      <div className='profile-overview__description txt txt-sm'>
        { overviewDescription }
      </div>
    );
  }, [ overviewDescription, profile?.description, profile?.shortBio, role ]);

  // The location display string
  const locationDisplayString = useMemo(() => {
    let locationArray = [ location?.district ];
    if (location?.city) {
      locationArray = [
        ...locationArray,
        t(`utilities:cities.${ location?.city }`),
      ];
    }
    return locationArray.filter(isNotEmpty).join(', ');
  }, [ location?.city, location?.district, t ]);

  // The overview location
  const overviewLocation = useMemo(() => {
    if (undefined === location) {
      return null;
    }
    return (
      <>
        <img alt='dashboard overview location' className='profile-overview__icon' src={ locationImage } />
        <div className='txt txt-sm'>
          { locationDisplayString }
        </div>
      </>
    );
  }, [ location, locationDisplayString ]);

  // The profile linking
  const linkTo = useMemo(() => {
    switch (role) {
    case ROLES.JOB_SEEKER:
      return `/job-seekers/${ userId }/profile`;
    case ROLES.COMPANY_AGENT:
      return `/companies/${ companyId }/profile`;
    case ROLES.ENDORSER:
      return `/endorsers/${ userId }/profile`;
    default:
      return '';
    }
  }, [ companyId, role, userId ]);

  // Company industry
  const overviewCompanyIndustry = useMemo(() => {
    if (!companyJobSector || role !== ROLES.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-overview__company-industry'>
        <img alt='dashboard overview company industry' className='profile-overview__icon' src={ tagImage } />
        <div className='txt txt-sm'>
          { companyJobSector }
        </div>
      </div>
    );
  }, [ companyJobSector, role ]);

  // Whether to render the location information with industry information
  const renderLocationWithIndustryInfo = useMemo(() => {
    if (undefined === location) {
      return null;
    }
    return (
      <div className='profile-overview__location-with-industry'>
        <div className='profile-overview__location'>
          { overviewLocation }
        </div>
        { overviewCompanyIndustry }
      </div>
    );
  }, [ location, overviewCompanyIndustry, overviewLocation ]);

  // The rendered work availability
  const renderedWorkAvailability = useMemo(() => {
    if (role !== ROLES.JOB_SEEKER) {
      return null;
    }
    return (
      <DashboardWorkAvailability
        onChange={ onWorkAvailabilityChange }
        userProfile={ profile }
      />
    );
  }, [ onWorkAvailabilityChange, profile, role ]);

  return (
    <>
      <div className='profile-overview__left-section'>
        <div className='profile-overview__image-and-name'>
          <div className={
            clsx('profile-overview__image', {
              'profile-overview__no-picture': addPlaceholderImage,
            })
          }
          >
            { overviewImage }
          </div>
          <p className='profile-overview__name hdg hdg-md'>
            { overviewName }
          </p>
        </div>
        { renderDescription }
        { renderLocationWithIndustryInfo }
        <div className='profile-overview__actions'>
          <Link to={ linkTo }>
            <button className='btn btn-sm btn-rounded-sm btn-blue profile-overview__button-link'>
              { t('common:profile_overview.edit_profile_button_label') }
            </button>
          </Link>
        </div>
      </div>
      <div className={
        clsx('profile-overview__right-section', {
          'profile-overview__right-section--companies': role === ROLES.COMPANY_AGENT,
          'profile-overview__right-section--endorsers': role === ROLES.ENDORSER,
          'profile-overview__right-section--job-seekers': role === ROLES.JOB_SEEKER,
        })
      }
      >
        { renderedWorkAvailability }
      </div>
    </>
  );
});

ProfileOverview.propTypes = {
  // The companyId that the user belongs
  companyId: PropTypes.string,
  // Company job sector
  companyJobSector: PropTypes.string,
  // The location
  location: PropTypes.shape({
    // The city of the location of the job seeker
    city: PropTypes.string,
    // The district of the location of the job seeker
    district: PropTypes.string,
  }),
  // The function (workAvailability) => void that is triggered when the work availability is changed
  onWorkAvailabilityChange: PropTypes.func,
  // The profile of either job seeker, company agent or endorser
  profile: PropTypes.oneOfType([
    // The job seeker profile
    PropTypes.shape({
      // The first name of the job seeker
      firstName: PropTypes.string,
      // The last name of the job seeker
      lastName: PropTypes.string,
      // The profile picture
      profilePicture: PropTypes.shape({
        // the id of the profile picture
        id: PropTypes.string,
      }),
      // The short bio of the job seeker profile
      shortBio: PropTypes.string,
    }),
    // The company agent profile
    PropTypes.shape({
      // The company name
      companyName: PropTypes.string,
      // The company description
      description: PropTypes.string,
      // The logo of the company
      logo: PropTypes.shape({
        // the id of the logo
        id: PropTypes.string,
      }),
    }),
  ]),
  // The signed-in user role
  role: PropTypes.oneOf(roles),
  // The signed-in user id
  userId: PropTypes.string.isRequired,
};

ProfileOverview.defaultProps = {
  companyId: '',
  companyJobSector: '',
  profile: {},
};

// The display name of the memoized component in dev tools
ProfileOverview.displayName = 'ProfileOverview';

export default ProfileOverview;
