import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ShipAnimationIcon from 'animations/ship2.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import LearnMoreInfoSection from './learn-more-info-section/learn-more-info-section';

import './learn-more-different-section.scss';

const LearnMoreDifferentSection = memo(() => {
  const { t } = useTranslation();

  // The learn more information to be displayed
  const learnMoreInformation = useMemo(() => {
    return [
      {
        description: t('job_seekers:learn_more.different_section.info.item1.description'),
        title: t('job_seekers:learn_more.different_section.info.item1.title'),
      },
      {
        description: t('job_seekers:learn_more.different_section.info.item2.description'),
        title: t('job_seekers:learn_more.different_section.info.item2.title'),
      },
      {
        description: t('job_seekers:learn_more.different_section.info.item3.description'),
        title: t('job_seekers:learn_more.different_section.info.item3.title'),
      },
    ];
  }, [ t ]);

  return (
    <div className='learn-more-section-different-section'>
      <div className='learn-more-section-different-section__title-section'>
        <h2 className='hdg hdg-xxl learn-more-section-different-section__title'>
          { t('job_seekers:learn_more.different_section.title') }
        </h2>
        <p className='txt txt-md learn-more-section-different-section__subtitle'>
          { t('job_seekers:learn_more.different_section.sub_title') }
        </p>
      </div>
      <div className='learn-more-section-different-section__content-section'>
        <LottieAnimationContainer
          animatedIconClassName='learn-more-section-different-section__animated-icon'
          animationData={ ShipAnimationIcon }
          className='learn-more-section-different-section__animation-container'
        />
        <LearnMoreInfoSection
          info={ learnMoreInformation }
        />
      </div>
    </div>
  );
});

LearnMoreDifferentSection.displayName = 'LearnMoreDifferentSection';

LearnMoreDifferentSection.propTypes = {};

LearnMoreDifferentSection.defaultProps = {};

export default LearnMoreDifferentSection;
