/**
 * The professional characteristics
 *
 * @module utilities/endorsement/professional-characteristics
 */

/**
 * Consistency.
 *
 * @constant {string}
 * @static
 */
export const CONSISTENCY = 'CONSISTENCY';

/**
 * Politeness.
 *
 * @constant {string}
 * @static
 */
export const POLITENESS = 'POLITENESS';

/**
 * Self-control.
 *
 * @constant {string}
 * @static
 */
export const SELF_CONTROL = 'SELF_CONTROL';

/**
 * Attitude.
 *
 * @constant {string}
 * @static
 */
export const ATTITUDE = 'ATTITUDE';

/**
 * Good Intention.
 *
 * @constant {string}
 * @static
 */
export const GOOD_INTENTION = 'GOOD_INTENTION';

export default [
  CONSISTENCY,
  POLITENESS,
  SELF_CONTROL,
  ATTITUDE,
  GOOD_INTENTION,
];
