/**
 * English translations for search.
 *
 * @module languages/en/search
 */
const search = {
  endorsers_title: 'Our endorsers',
  search_failed_error: 'Search failed.',
};

export default search;
