import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Carousel from 'components/common/carousel/carousel';

import './about-us-our-supporters-section.scss';

const AboutUsOurSupportersSection = memo((props) => {
  const { title, supporters } = props;

  // The rendered supporters
  const renderedSupporters = useMemo(() => {
    return supporters.map((supporter, supporterIndex) => {
      return (
        <div
          className='about-us-supporters-section__image-container'
          key={ `Supporter Logo ${ supporterIndex }` }
        >
          <img
            alt='Supporter logo'
            src={ supporter }
          />
        </div>
      );
    });
  }, [ supporters ]);

  return (
    <div className='about-us-supporters-section'>
      <h3 className='hdg hdg-lg about-us-supporters-section__title'>
        { title }
      </h3>
      <Carousel
        autoplay={ 1 < supporters?.length }
        infiniteLoop
      >
        { renderedSupporters }
      </Carousel>
    </div>
  );
});

AboutUsOurSupportersSection.displayName = 'AboutUsOurSupportersSection';

AboutUsOurSupportersSection.propTypes = {
  // The supporters collection
  supporters: PropTypes.arrayOf(PropTypes.string).isRequired,
  // the section title
  title: PropTypes.string.isRequired,
};

AboutUsOurSupportersSection.defaultProps = {};

export default AboutUsOurSupportersSection;
