/**
 * English translations for utilities/pricing-plans.
 *
 * @module languages/en/utilities/pricing-plans
 */
const pricingPlans = {
  BASIC: 'Basic',
  ENTERPRISE: 'Enterprise',
  FREE: 'Free',
};

export default pricingPlans;
