/**
 * Tags.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { WithContext as BaseTags } from 'react-tag-input';

import './tags.scss';

const Tags = (props) => {
  return (
    <BaseTags
      allowDragDrop={ props.moveable }
      classNames={
        {
          remove: 'ody-tags-remove',
          selected: 'ody-tags-selected-options',
          tag: 'ody-tags-selected-option',
          tagInputField: 'ody-tags-input-field',
          tags: clsx('ody-tags', props.className),
        }
      }
      handleDelete={
        (index) => {
          const tag = props.tags[index];
          props.onDelete?.(tag, index);
        }
      }
      handleDrag={
        (tag, currPos, newPos) => {
          if (currPos === newPos) {
            return;
          }
          props.onMove?.(props.tags[currPos], currPos, newPos);
        }
      }
      tags={
        props.tags.map((tag) => {
          return { id: tag.value.toString(), text: tag.label };
        })
      }
    />
  );
};

Tags.propTypes = {
  // The class(es) to apply to the tags.
  className: PropTypes.string,
  // Whether the tags can be moved around.
  moveable: PropTypes.bool,
  // The function ((object, number) => void) to invoke when a tag is deleted.
  onDelete: PropTypes.func,
  // The function ((object, number, number) => void) to invoke when a tag is moved.
  onMove: PropTypes.func,
  // The tags.
  tags: PropTypes.arrayOf(PropTypes.shape({
    // The label of the tag.
    label: PropTypes.string.isRequired,
    // The value of the tag.
    value: PropTypes.any.isRequired,
  })).isRequired,
};

Tags.defaultProps = {
  moveable: true,
};

export default Tags;
