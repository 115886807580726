/**
 * Users - Header.
 */
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import CircularImage from 'components/common/circular-image/circular-image';
import PopupMenu from 'components/common/popup-menu/popup-menu';
import env from 'config/env';
import { actions as authActions } from 'ducks/auth';
import { actions as requestsActions } from 'ducks/requests';
import burger from 'images/burger.png';
import defaultProfilePicture from 'images/default-profile-picture.png';
import * as authMethods from 'resources/auth';
import * as Permissions from 'utilities/auth/permissions';
import * as Roles from 'utilities/auth/roles';

import './users-header.scss';

const UsersHeader = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);
  // The profiles state object
  const profiles = useSelector((store) => {
    return store.profiles;
  }, shallowEqual);

  // The permissions of the signed-in user.
  const permissions = useMemo(() => {
    return auth?.permissions || [];
  }, [ auth?.permissions ]);

  // The role of the signed-in user.
  const role = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // The ID of the signed-in user.
  const userId = useMemo(() => {
    return auth?.id;
  }, [ auth?.id ]);

  // The ID of the company that the user belongs if he is a COMPMANY_AGENT.
  const companyId = useMemo(() => {
    return auth?.companyId;
  }, [ auth?.companyId ]);

  // The signed in user profile
  const userProfile = useMemo(() => {
    return profiles?.profile;
  }, [ profiles?.profile ]);

  // The profile picture of the signed-in user.
  const userProfilePicture = useMemo(() => {
    return userProfile?.profilePicture || userProfile?.logo;
  }, [ userProfile?.logo, userProfile?.profilePicture ]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  // Function that signs out the signed-in user.
  const signOut = useCallback(() => {
    dispatch(requestsActions.request(authMethods.signOut, null, {
      onSuccess: () => {
        navigate('/home');
        dispatch(authActions.signOut());
      },
    }));
  }, [ dispatch, navigate ]);

  // The profile link
  const profileUserLink = useMemo(() => {
    let profileLink = '';
    switch (role) {
    case Roles.JOB_SEEKER:
      profileLink = `/job-seekers/${ userId }/profile`;
      break;
    case Roles.COMPANY_AGENT:
      profileLink = `/companies/${ companyId }/profile`;
      break;
    case Roles.ENDORSER:
      profileLink = `/endorsers/${ userId }/profile`;
      break;
    default:
      break;
    }
    return profileLink;
  }, [ companyId, role, userId ]);

  // The profile picture url
  const profilePictureUrl = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return `${ env.CORE_BASE_URL }/job-seekers/${ userId }/profile/picture?id=${ userProfilePicture?.id }`;
    case Roles.COMPANY_AGENT:
      return `${ env.CORE_BASE_URL }/companies/${ companyId }/profile/logo?id=${ userProfilePicture?.id }`;
    case Roles.ENDORSER:
      return `${ env.CORE_BASE_URL }/endorsers/${ userId }/profile/picture?id=${ userProfilePicture?.id }`;
    default:
      return '';
    }
  }, [ companyId, role, userId, userProfilePicture?.id ]);

  // The 'FIND WORK' popup menu for large screens
  const findWorkPopupMenuLargeScreens = useMemo(() => {
    if (0 > permissions.indexOf(Permissions.FIND_WORK)) {
      return null;
    }
    return (
      <PopupMenu
        className='header-popupmenu'
        offsetY={ 20 }
        position='bottom center'
        trigger={
          <button
            className='btn-link'
            type='button'
          >
            { t('header:find_work_menu.title') }
          </button>
        }
      >
        <Link className='link' to='/job-seekers/learn-more'>
          { t('header:find_work_menu.items.item1') }
        </Link>
        <Link className='link' to='/job-seekers/take-assessment'>
          { t('header:find_work_menu.items.item2') }
        </Link>
        <Link className='link' to='/companies/search'>
          { t('header:find_work_menu.items.item3') }
        </Link>
      </PopupMenu>
    );
  }, [ permissions, t ]);

  // The FIND_TALENT learn more link
  const findTalentLearnMoreLink = useMemo(() => {
    if (role === Roles.ENDORSER) {
      return '/endorsers/learn-more';
    }
    return '/companies/learn-more';
  }, [ role ]);

  // The 'FIND TALENT' popup menu for large screens
  const findTalentPopupMenuLargeScreens = useMemo(() => {
    if (0 > permissions.indexOf(Permissions.FIND_TALENT)) {
      return null;
    }
    return (
      <PopupMenu
        className='header-popupmenu'
        offsetY={ 20 }
        position='bottom center'
        trigger={
          <button
            className='btn-link'
            type='button'
          >
            { t('header:find_talent_menu.title') }
          </button>
        }
      >
        <Link className='link' to={ findTalentLearnMoreLink }>
          { t('header:find_talent_menu.items.item1') }
        </Link>
        <Link className='link' to='/job-seekers/search'>
          { t('header:find_talent_menu.items.item4') }
        </Link>
      </PopupMenu>
    );
  }, [ findTalentLearnMoreLink, permissions, t ]);

  // The 'FIND WORK' menu for large screens
  const findWorkMenuSmallScreens = useMemo(() => {
    if (0 > permissions.indexOf(Permissions.FIND_WORK)) {
      return null;
    }
    return (
      <>
        <li role='menuitem'>
          <Link className='link' to='/job-seekers/learn-more'>
            { t('header:find_work_menu.items.item1') }
          </Link>
        </li>
        <li role='menuitem'>
          <Link className='link' to='/job-seekers/take-assessment'>
            { t('header:find_work_menu.items.item2') }
          </Link>
        </li>
        <li role='menuitem'>
          <Link className='link' to='/companies/search'>
            { t('header:find_work_menu.items.item3') }
          </Link>
        </li>
      </>
    );
  }, [ permissions, t ]);

  // The 'FIND TALENT' menu for large screens
  const findTalentMenuSmallScreens = useMemo(() => {
    if (0 > permissions.indexOf(Permissions.FIND_TALENT)) {
      return null;
    }
    return (
      <>
        <li role='menuitem'>
          <Link className='link' to={ findTalentLearnMoreLink }>
            { t('header:find_talent_menu.items.item1') }
          </Link>
        </li>
        <li role='menuitem'>
          <Link className='link' to='/job-seekers/search'>
            { t('header:find_talent_menu.items.item4') }
          </Link>
        </li>
      </>
    );
  }, [ findTalentLearnMoreLink, permissions, t ]);

  // The profile picture
  const profilePicture = useMemo(() => {
    if (undefined === userProfilePicture || !profilePictureUrl) {
      return (
        <CircularImage
          alt='Default Profile Picture'
          imageSource={ defaultProfilePicture }
        />
      );
    }
    return (
      <CircularImage
        alt='Profile Picture'
        imageSource={ profilePictureUrl }
      />
    );
  }, [ profilePictureUrl, userProfilePicture ]);

  return (
    <header className='ody-header users'>
      <div className='brand'>
        <Link to='/dashboard'>odyssea</Link>
      </div>
      <div className='links hidden-xs hidden-sm hidden-md'>
        { findWorkPopupMenuLargeScreens }
        { findTalentPopupMenuLargeScreens }
      </div>
      <div className='profile-menu hidden-xs hidden-sm hidden-md'>
        <PopupMenu
          className='header-popupmenu'
          offsetY={ 10 }
          position='bottom right'
          trigger={
            <button
              className={
                clsx('btn btn-trans profile-picture', {
                  'no-picture': undefined === userProfilePicture || !profilePictureUrl,
                })
              }
              id='menu-trigger-button'
              name='trigger menu'
              type='button'
            >
              { profilePicture }
            </button>
          }
        >
          <div className='group'>
            <Link
              className='link'
              to={ profileUserLink }
            >
              { t('header:profile_menu.items.item1') }
            </Link>
            <Link className='link' to='/settings'>
              { t('header:profile_menu.items.item2') }
            </Link>
          </div>
          <div className='divider' />
          <div className='group'>
            <button
              className='btn-link'
              onClick={ signOut }
              type='button'
            >
              { t('header:profile_menu.items.item3') }
            </button>
          </div>
        </PopupMenu>
      </div>
      <div className='profile-menu-and-links hidden-lg'>
        <PopupMenu
          className='users-header-mobile-popupmenu'
          offsetY={ 10 }
          position='bottom right'
          trigger={
            <button
              className='btn btn-trans trigger-button'
              id='trigger-menu-button'
              name='trigger menu'
              type='button'
            >
              <img alt='Burger' src={ burger } />
            </button>
          }
        >
          <div
            className={
              clsx('profile-picture', {
                'no-picture': undefined === userProfilePicture,
              })
            }
          >
            { profilePicture }
          </div>
          <div className='divider' />
          <div className='group links'>
            <ul role='menu'>
              { findWorkMenuSmallScreens }
              { findTalentMenuSmallScreens }
            </ul>
          </div>
          <div className='divider' />
          <div className='group profile-links'>
            <ul role='menu'>
              <li role='menuitem'>
                <Link
                  className='link'
                  to={ profileUserLink }
                >
                  { t('header:profile_menu.items.item1') }
                </Link>
              </li>
              <li role='menuitem'>
                <Link className='link' to='/settings'>
                  { t('header:profile_menu.items.item2') }
                </Link>
              </li>
            </ul>
          </div>
          <div className='divider' />
          <div className='group profile-links'>
            <ul role='menu'>
              <li role='menuitem'>
                <button
                  className='btn-link'
                  onClick={ signOut }
                  type='button'
                >
                  { t('header:profile_menu.items.item3') }
                </button>
              </li>
            </ul>
          </div>
        </PopupMenu>
      </div>
    </header>
  );
};

UsersHeader.propTypes = {};

UsersHeader.defaultProps = {};

export default UsersHeader;
