/**
 * Greek translations for utilities/cities.
 *
 * @module languages/el/utilities/cities
 *
 * NOTE: Taken from https://geodata.gov.gr/en/dataset/poleis.
 */
const cities = {
  AGIOS_NIKOLAOS: 'Άγιος Νικόλαος',
  ALEKSANDROYPOLI: 'Αλεξανδρούπολη',
  AMFISSA: 'Άμφισσα',
  ARGOSTOLI: 'Αργοστόλι',
  ARTA: 'Άρτα',
  ATHENS: 'Αθήνα',
  BEROIA: 'Βέροια',
  BOLOS: 'Βόλος',
  DRAMA: 'Δράμα',
  EDESSA: 'Έδεσσα',
  ERMOYPOLI: 'Ερμούπολη',
  FLORINA: 'Φλώρινα',
  GREBENA: 'Γρεβενά',
  HALKIDA: 'Χαλκίδα',
  HANIA: 'Χανιά',
  HGOYMENITSA: 'Ηγουμενίτσα',
  HRAKLEIO: 'Ηράκλειο',
  IOANNINA: 'Ιωάννινα',
  KABALA: 'Καβάλα',
  KALAMATA: 'Καλαμάτα',
  KARDITSA: 'Καρδίτσα',
  KARPENISI: 'Καρπενήσι',
  KASTORIA: 'Καστοριά',
  KATERINI: 'Κατερίνη',
  KERKYRA: 'Κέρκυρα',
  KILKIS: 'Κιλκίς',
  KOMOTINI: 'Κομοτηνή',
  KORINTHOS: 'Κόρινθος',
  KOZANI: 'Κοζάνη',
  KSANTHI: 'Ξάνθη',
  LAMIA: 'Λαμία',
  LARISSA: 'Λάρισα',
  LEIVADIA: 'Λιβάδια',
  LEYKADA: 'Λευκάδα',
  MESOLOGGI: 'Μεσολόγγι',
  MYTILINI: 'Μυτιλήνη',
  NAYPLION: 'Ναύπλιο',
  PATRA: 'Πάτρα',
  POLYGYROS: 'Πολύγυρος',
  PREBEZA: 'Πρέβεζα',
  PYRGOS: 'Πύργος',
  RETHIMNO: 'Ρέθυμνο',
  RODOS: 'Ρόδος',
  SAMOS: 'Σάμος',
  SERRES: 'Σέρρες',
  SPARTI: 'Σπάρτη',
  THESSALONIKI: 'Θεσσαλονίκη',
  TRIKALA: 'Τρίκαλα',
  TRIPOLI: 'Τρίπολη',
  XIOS: 'Χίος',
  ZAKYNTHOS: 'Ζάκυνθος',
};

export default cities;
