import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './about-us-key-metrics-section.scss';
import AboutUsKeyMetric from './about-us-key-metric/about-us-key-metric';

const AboutUsKeyMetricsSection = memo((props) => {
  const { keyMetricsTitle, keyMetrics } = props;

  // The rendered key metrics
  const renderedKeyMetrics = useMemo(() => {
    return keyMetrics.map((keyMetric, keyMetricIndex) => {
      return (
        <AboutUsKeyMetric
          key={ keyMetric.number + keyMetricIndex }
          label={ keyMetric.label }
          number={ keyMetric.number }
        />
      );
    });
  }, [ keyMetrics ]);

  return (
    <div className='about-us-key-metrics-section'>
      <h4 className='hdg hdg-lg about-us-key-metrics-section__title'>
        { keyMetricsTitle }
      </h4>
      <div className='about-us-key-metrics-section__metrics-container'>
        { renderedKeyMetrics }
      </div>
    </div>
  );
});

AboutUsKeyMetricsSection.displayName = 'AboutUsKeyMetricsSection';

AboutUsKeyMetricsSection.propTypes = {
  // The key metrics collection
  keyMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      // the key metric label
      label: PropTypes.string,
      // the key metric number
      number: PropTypes.string,
    })
  ).isRequired,
  // The key metrics title
  keyMetricsTitle: PropTypes.string.isRequired,
};

AboutUsKeyMetricsSection.defaultProps = {};

export default AboutUsKeyMetricsSection;
