/**
 * Language editor.
 */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Select from 'components/common/select/select';
import { actions as requestsActions } from 'ducks/requests';
import { actions as settingsActions } from 'ducks/settings';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import { LANGUAGES } from 'i18n';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';
import { isEmpty } from 'utilities/chisels';
import { languagesEditorSchemaValidator } from 'utilities/validators';

import './language-editor.scss';

const LanguageEditor = (props) => {
  const { onClose } = props;

  // The application language.
  const language = useSelector((store) => {
    return store.settings.language;
  }, shallowEqual);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // The error when saving the changes.
  const [ error, setError ] = useState(undefined);

  const { control, formState: { errors, isDirty }, handleSubmit, reset }
      = useFormWithSchema(languagesEditorSchemaValidator);

  // Function that updates the language of the currently signed-in user based on the given values.
  const updateLanguage = useCallback((values) => {
    setError(undefined);
    dispatch(requestsActions.request(usersMethods.updateMyLanguage, {
      language: values.language.value,
    }, {
      onFailure: (_error) => {
        setError(t('settings:common.general_error'));
      },
      onSuccess: (result) => {
        dispatch(settingsActions.setLanguage(result.language));
        toasts.info(t('settings:preferences.language.language_changed_message'));
        onClose?.();
      },
    }));
  }, [ dispatch, onClose, t ]);

  // Fill in the form with the application language.
  useEffect(() => {
    const values = {};
    values.language = undefined === language ? null : {
      label: t(`utilities:languages.${ language }`),
      value: language,
    };
    reset(values);
  }, [ language, reset, t ]);

  // languages options
  const languagesOptions = useMemo(() => {
    return LANGUAGES.map((language) => {
      return {
        label: t(`utilities:languages.${ language }`),
        value: language,
      };
    });
  }, [ t ]);

  // languages select component
  const renderedLanguagesSelectComponent = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ languagesOptions }
        placeholder={ t('settings:preferences.language.language_placeholder') }
      />
    );
  }, [ languagesOptions, t ]);

  // language error
  const languageError = useMemo(() => {
    if ('object.base' !== errors.language?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('settings:preferences.language.language_required_error') }
      </span>
    );
  }, [ errors.language?.type, t ]);

  // generic error
  const genericError = useMemo(() => {
    if (undefined === error) {
      return null;
    }
    return (
      <div className='error'>{ error }</div>
    );
  }, [ error ]);

  // function that handles the close button click
  const handleClose = useCallback(() => {
    onClose?.();
  }, [ onClose ]);

  // whether the button should be disabled
  const buttonDisabled = useMemo(() => {
    return !isDirty || !isEmpty(errors);
  }, [ errors, isDirty ]);

  return (
    <div className='language editor dark'>
      <div className='hdg hdg-md'>{ t('settings:preferences.language.editor_title') }</div>
      <form onSubmit={ handleSubmit(updateLanguage) }>
        <div className='fields'>
          <div className='field language'>
            <label>
              { t('settings:preferences.language.language_label') }
              <Controller
                control={ control }
                name='language'
                render={ renderedLanguagesSelectComponent }
              />
            </label>
            { languageError }
          </div>
        </div>
        { genericError }
        <div className='buttons'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ handleClose }
            type='reset'
          >
            { t('settings:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ buttonDisabled }
            type='submit'
          >
            { t('settings:common.change_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
};

LanguageEditor.propTypes = {
  // The function ((object) => void) to invoke when the editor is closed.
  onClose: PropTypes.func,
};

LanguageEditor.defaultProps = {
};

export default LanguageEditor;
