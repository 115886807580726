/**
 * Resume editor.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import FilePreview from 'components/common/file-preview/file-preview';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import Upload from 'images/upload.png';
import * as jobSeekersMethods from 'resources/job-seekers';
import { areNotEqual, isEmpty } from 'utilities/chisels';
import { jobSeekersResumeSchemaValidator } from 'utilities/validators';

import './profile-resume-editor.scss';

const ProfileResumeEditor = memo((props) => {
  const { profile, onSave, onCancel, jobSeekerId } = props;

  // The allowed document formats.
  const ALLOWED_DOCUMENT_FORMATS = useMemo(() => {
    return [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
  }, []);

  // The maximum allowed document size (in bytes).
  const MAXIMUM_ALLOWED_DOCUMENT_SIZE = useMemo(() => {
    return 4 * 1024 * 1024;
  }, []);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // Whether the resume failed to be loaded.
  const [ failed, setFailed ] = useState(false);
  // The document.
  const [ document, setDocument ] = useState(undefined);
  // The error when uploading a document.
  const [ _documentError, setDocumentError ] = useState('');

  // Reference to the file input.
  const fileRef = useRef(undefined);

  const { handleSubmit, register, reset, setValue } = useFormWithSchema(jobSeekersResumeSchemaValidator);

  // Preview the resume in the profile.
  useEffect(() => {
    if (undefined === profile?.resume) {
      setDocument(undefined);
      return;
    }
    setDocument({
      id: profile?.resume?.id,
    });
    dispatch(requestsActions.request(jobSeekersMethods.getResume, {
      id: jobSeekerId,
    }, {
      onFailure: () => {
        setFailed(true);
      },
      onSuccess: (result) => {
        setDocument((document) => {
          return {
            ...document,
            ...result,
          };
        });
        setFailed(false);
      },
    }));
  }, [ dispatch, jobSeekerId, profile?.resume ]);

  // the params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      file: undefined,
      resume: {
        id: profile?.resume?.id,
      },
    };
  }, [ profile?.resume ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // renders resume to be updated
  const renderResumeToBeUpdated = useCallback((resume) => {
    if (isEmpty(resume)) {
      return undefined;
    }
    return {
      id: resume,
    };
  }, []);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const { file } = values;
    const paramsToBeUpdated = {
      ...values,
      file: undefined,
      resume: renderResumeToBeUpdated(values?.resume),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated), file);
  }, [ onSave, renderResumeToBeUpdated, valuesAreToBeUpdated ]);

  // Fill in the form with values from the profile.
  useEffect(() => {
    const values = {};
    values.file = null;
    values.resume = profile?.resume?.id || '';
    reset(values);
  }, [ profile?.resume?.id, reset ]);

  const { ref, ...rest } = register('file', {
    onChange: (e) => {
      const [ file, ..._others ] = e.target.files;
      if (undefined === file) {
        return;
      }
      setDocumentError('');
      setDocument(undefined);
      setFailed(false);
      setValue('resume', '');
      // Check that the uploaded document has one of the allowed formats.
      if (-1 === ALLOWED_DOCUMENT_FORMATS.indexOf(file.type)) {
        setDocumentError(t('job_seekers:profile_resume.unsupported_document_format_error'));
        setValue('file', null);
        return;
      }
      // Check that the uploaded document is not too large.
      if (MAXIMUM_ALLOWED_DOCUMENT_SIZE < file.size) {
        setDocumentError(t('job_seekers:profile_resume.too_large_document_error'));
        setValue('file', null);
        return;
      }
      setDocument({
        data: file,
        id: uuidv4(),
        type: file.type,
      });
    },
  });

  // The file input reference
  const onRefFile = useCallback((event) => {
    ref(event);
    fileRef.current = event;
  }, [ ref ]);

  // function that handles the file click
  const onFileClick = useCallback(() => {
    fileRef.current?.click();
  }, []);

  // The rendered file preview
  const renderedFilePreview = useMemo(() => {
    if (undefined === document?.data) {
      return null;
    }
    return (
      <FilePreview
        file={ document }
      />
    );
  }, [ document ]);

  // The rendered preview section
  const renderedPreviewSection = useMemo(() => {
    if (undefined === document?.id) {
      return (
        <button
          className='profile-editor__resume-container--no-resume btn btn-trans'
          onClick={ onFileClick }
          type='button'
        >
          <img alt='Upload resume' src={ Upload } />
          <div className='txt txt-sm'>
            { t('job_seekers:profile_resume.no_resume_message') }
          </div>
        </button>
      );
    }
    if (true === failed) {
      return (
        <div className='txt txt-sm'>
          { t('job_seekers:profile_resume.no_resume_message') }
        </div>
      );
    }
    return renderedFilePreview;
  }, [ document?.id, failed, onFileClick, renderedFilePreview, t ]);

  // function that handles the delete button click
  const onDeleteButtonClick = useCallback(() => {
    setDocument(undefined);
    setValue('resume', '');
    setValue('file', null);
  }, [ setValue ]);

  // the change/upload button label
  const uploadChangeButtonLabel = useMemo(() => {
    if (undefined === document?.id) {
      return t('profile:common.upload_button_label');
    }
    return t('profile:common.change_button_label');
  }, [ document?.id, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('job_seekers:profile_resume.editor_title') }
      </h2>
      <div className='profile-editor__resume-container'>
        { renderedPreviewSection }
        <div className='profile-editor__resume-container--buttons'>
          <button
            className='btn btn-sm btn-rounded-sm btn-red'
            onClick={ onDeleteButtonClick }
            type='button'
          >
            { t('profile:common.delete_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onFileClick }
            type='button'
          >
            { uploadChangeButtonLabel }
          </button>
        </div>
      </div>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        <div className='profile-editor__form-field'>
          <input
            type='hidden'
            { ...register('resume') }
          />
          <input
            accept={ ALLOWED_DOCUMENT_FORMATS.join(', ') }
            className='hidden'
            ref={ onRefFile }
            type='file'
            { ...rest }
          />
        </div>
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileResumeEditor.displayName = 'ProfileResumeEditor';

ProfileResumeEditor.propTypes = {
  // The job seeker id
  jobSeekerId: PropTypes.string,
  // The function (() => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((object) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The resume of the job seeker.
    resume: PropTypes.shape({
      // The ID of the resume.
      id: PropTypes.string.isRequired,
      // The MIME type of the resume.
      type: PropTypes.string.isRequired,
    }),
  }),
};

ProfileResumeEditor.defaultProps = {};

export default ProfileResumeEditor;
