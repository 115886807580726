/**
 * Resource method to get job seeker profiles.
 *
 * @module resources/job-seekers/get-profile
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/get-profile~Params
 * @type {object}
 * @property {string} id - The ID of the job seeker.
 */

/**
 * Result.
 *
 * @typedef {module:types/job-seekers/profiles~Profile} Result
 */

/**
 * Gets the profile of a job seeker based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/get-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/get-profile~Result>} - The promise.
 * @static
 */
const getProfile = (params) => {
  return axios.request({
    method: 'get',
    url: `/job-seekers/${ params.id }/profile`,
  }).then((response) => {
    return response.data;
  });
};

export default getProfile;
