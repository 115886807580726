/**
 * Middleware for profiles.
 *
 * @module middlewares/profiles
 */

import { actions as administratorsActions } from 'ducks/administrators';
import { types as authTypes } from 'ducks/auth';
import { actions as companiesActions } from 'ducks/companies';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as jobSeekersActions } from 'ducks/job-seekers';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as settingsActions } from 'ducks/settings';

const middleware = (store) => {
  return (next) => {
    return (action) => {
      const { type } = action;
      switch (type) {
      case authTypes.AUTH_RESET:
      case authTypes.USER_SIGNED_OUT:
        // Reset the settings.
        store.dispatch(settingsActions.resetSettings());
        // Reset everything about job seeker searches.
        store.dispatch(jobSeekersActions.resetSearch());
        // Reset everything about company agents
        store.dispatch(companiesActions.reset());
        // Reset the administrators
        store.dispatch(administratorsActions.resetAdministrators());
        // Reset the endorsers
        store.dispatch(endorsersActions.resetEndorsers());
        // Reset the profiles
        store.dispatch(profilesActions.resetProfile());
        break;
      default:
        break;
      }
      return next(action);
    };
  };
};

export {
  middleware,
};
