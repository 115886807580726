/**
 * Greek translations for common/contact-button.
 *
 * @module languages/el/common/contact-button
 */
const contactButton = {
  description: 'Για οποιοδήποτε αίτημα, στείλτε μας ένα email ή καλέστε μας!',
  email: 'talent@odyssea.com',
  label: 'Επικοινωνήστε με την Odyssea',
  phoneNumber1: ' 30 210 8839877',
  phoneNumber2: ' 30 210 4815780',
  title: 'Στοιχεία επικοινωνίας',
};

export default contactButton;
