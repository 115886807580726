/**
 * Resource method to claim a CREATED endorsement request.
 *
 * @module resources/endorsers/claim-endorsement-request
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/endorsers/claim-endorsement-request~Params
 * @type {object}
 * @property {string} id - The ID of the endorsement
 */

/**
 * Claims a CREATED endorsement request.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/endorsers/claim-endorsement-request~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const claimEndorsementRequest = (params) => {
  return axios.request({
    method: 'post',
    url: `/endorsement-requests/${ params.id }/claim`,
  }).then((response) => {
    return response.data;
  });
};

export default claimEndorsementRequest;
