import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import StepsIcon from 'components/common/icons/steps-icon/steps-icon';

import './about-us-how-does-it-work-section.scss';
import AboutUsHowDoesItWorkStep from './about-us-how-does-it-work-step/about-us-how-does-it-work-step';

const AboutUsHowDoesItWorkSection = memo((props) => {
  const { title, steps } = props;

  // The rendered steps
  const renderedSteps = useMemo(() => {
    return steps.map((step, stepIndex) => {
      return (
        <AboutUsHowDoesItWorkStep
          description={ step.description }
          key={ step.title + stepIndex }
          title={ step.title }
        />
      );
    });
  }, [ steps ]);

  return (
    <div className='about-us-how-does-it-work-section'>
      <h2 className='hdg hdg-xxl about-us-how-does-it-work-section__title'>
        { title }
      </h2>
      <div className='about-us-how-does-it-work-section__icon-container hidden-sm hidden-xs'>
        <StepsIcon />
      </div>
      <div className='about-us-how-does-it-work-section__steps-container'>
        { renderedSteps }
      </div>
    </div>
  );
});

AboutUsHowDoesItWorkSection.displayName = 'AboutUsHowDoesItWorkSection';

AboutUsHowDoesItWorkSection.propTypes = {
  // The steps
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      // The step description
      description: PropTypes.string,
      // The step title
      title: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsHowDoesItWorkSection.defaultProps = {};

export default AboutUsHowDoesItWorkSection;
