/**
 * English translations for sign-up.
 *
 * @module languages/en/sign-up
 */
const signUp = {
  agree_to: 'I agree to the',
  already_have_account: 'Already have an account?',
  company_link_text: 'a company',
  company_name_label: 'Company Name',
  company_name_placeholder: 'Add your company\'s name',
  company_name_required_error: 'Company name is required.',
  company_position_placeholder: 'Add your company\'s position',
  continue_with_google_button_label: 'Continue with Google',
  continue_with_linkedin_button_label: 'Continue with LinkedIn',
  // eslint-disable-next-line max-len
  created_message: 'Your account has been created, but, before you can sign in, you must first verify your email address.\n\nAn email has been sent to the email address you specified. Please check your inbox, and follow the instructions in that email to activate your account.',
  email_invalid_error: 'Enter a valid email address.',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Email is required.',
  endorser_company_name_label: 'Company Name (Optional)',
  endorser_company_position_label: 'Company Position (Optional)',
  endorser_link_text: 'an endorser',
  first_name_label: 'First name',
  first_name_placeholder: 'First name',
  first_name_required_error: 'First name is required.',
  general_error: 'Oops! Something went wrong.',
  job_seeker_link_text: 'a job seeker',
  last_name_label: 'Last name',
  last_name_placeholder: 'Last name',
  last_name_required_error: 'Last name is required.',
  must_agree_with_privacy_policy_error: 'You must agree with the Privacy Policy.',
  must_agree_with_terms_of_service_error: 'You must agree with the Terms of Service.',
  not_company_agent: 'Not a company agent?',
  not_endorser: 'Not an endorser?',
  not_job_seeker: 'Not a job seeker?',
  or: 'or',
  password_and_password_confirmation_mismatch_error: 'Password and password confirmation do not match.',
  password_confirmation_label: 'Confirm password',
  password_confirmation_placeholder: 'Confirm password',
  // eslint-disable-next-line max-len
  password_invalid_error: 'Password must contain:\nAt least 8 characters.\nAt least one digit.\nAt least one lowercase letter.\nAt least one uppercase letter.\nAt least one special character (!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~).',
  password_label: 'Password',
  password_placeholder: 'Password',
  password_required_error: 'Password is required.',
  phone_number_label: 'Phone Number',
  phone_number_label_endorsers: 'Phone Number (Optional)',
  // eslint-disable-next-line max-len
  phone_number_pattern_error: 'Phone number must be a number with the country prefix (e.g +30) and not exceed 10 numbers',
  phone_number_placeholder: 'Add your phone number',
  phone_number_required_error: 'Phone Number is required.',
  privacy_policy_link_text: 'Privacy Policy',
  reset_authentication_provider_button_label: 'Back',
  retrieve_google_profile_error: 'Failed to retrieve user\'s information via Google',
  retrieve_linkedin_profile_error: 'Failed to retrieve user\'s information via LinkedIn',
  sign_in_link_text: 'Sign in',
  sign_up_as: 'Sign up as',
  sign_up_button_label: 'Sign up',
  terms_of_service_link_text: 'Terms of Service',
  title_company_agent: 'Sign up as a Company Agent',
  title_endorser: 'Sign up as an Endorser',
  title_job_seeker: 'Sign up as a Job Seeker',
  user_already_exists_error: 'Email is taken.',
  verify_with_google_error: 'Something went wrong verifying with Google. Please refresh your browser and try again.',
  // eslint-disable-next-line max-len
  verify_with_linkedin_error: 'Something went wrong verifying with LinkedIn. Please refresh your browser and try again.',
};

export default signUp;
