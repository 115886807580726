/**
 * Filter types.
 *
 * @module utilities/endorsers/filter-types
 */

/**
 * The total endorsements range.
 *
 * @constant {string}
 * @static
 */
export const TOTAL_ENDORSEMENTS_RANGE = 'TOTAL_ENDORSEMENTS_RANGE';

export default [ TOTAL_ENDORSEMENTS_RANGE ];
