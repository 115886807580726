/**
 * Resource method to get the claimed endorsement requests.
 *
 * @module resources/endorsers/get-claimed-endorsement-requests
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/endorsers/get-claimed-endorsement-requests~Result
 * @type {object}
 * @property {module:types/common~EndorsementRequest[]} endorsementRequests
 */

/**
 * Gets the claimed endorsement requests.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/endorsers/get-claimed-endorsement-requests~Result>} - The promise.
 * @static
 */
const getClaimedEndorsementRequests = () => {
  return axios.request({
    method: 'get',
    url: '/endorsement-requests/claimed',
  }).then((response) => {
    return response.data;
  });
};

export default getClaimedEndorsementRequests;
