/**
 * Take assessment page.
 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeCardIcon from 'components/common/icons/badge-card-icon/badge-card-icon';
import DiamondCardIcon from 'components/common/icons/diamond-card-icon/diamond-card-icon';
import FlagsCardIcon from 'components/common/icons/flags-card-icon/flags-card-icon';
import StarCardIcon from 'components/common/icons/star-card-icon/star-card-icon';
import TraitsCardIcon from 'components/common/icons/traits-card-icon/traits-card-icon';

import TakeAssessmentFirstSection from './take-assesment-first-section/take-assesment-first-section';
import TakeAssessmentCardsSection from './take-assessment-cards-section/take-assessment-cards-section';

import './take-assessment.scss';

const TakeAssessment = () => {
  const { t } = useTranslation();

  // The cards information
  const cardsInformation = useMemo(() => {
    return [
      {
        description: t('job_seekers:take_assessment.cards_section.cards.item1.description'),
        icon: <DiamondCardIcon isWhite />,
        title: t('job_seekers:take_assessment.cards_section.cards.item1.title'),
      },
      {
        description: t('job_seekers:take_assessment.cards_section.cards.item2.description'),
        icon: <TraitsCardIcon isWhite />,
        title: t('job_seekers:take_assessment.cards_section.cards.item2.title'),
      },
      {
        description: t('job_seekers:take_assessment.cards_section.cards.item3.description'),
        icon: <FlagsCardIcon isWhite />,
        title: t('job_seekers:take_assessment.cards_section.cards.item3.title'),
      },
      {
        description: t('job_seekers:take_assessment.cards_section.cards.item4.description'),
        icon: <StarCardIcon isWhite />,
        title: t('job_seekers:take_assessment.cards_section.cards.item4.title'),
      },
      {
        description: t('job_seekers:take_assessment.cards_section.cards.item5.description'),
        icon: <BadgeCardIcon isWhite />,
        title: t('job_seekers:take_assessment.cards_section.cards.item5.title'),
      },
      {
        description: t('job_seekers:take_assessment.cards_section.cards.last_item.description'),
      },
    ];
  }, [ t ]);

  return (
    <div className='ody-take-assessment'>
      <TakeAssessmentFirstSection />
      <TakeAssessmentCardsSection
        cards={ cardsInformation }
      />
    </div>
  );
};

TakeAssessment.propTypes = {
};

TakeAssessment.defaultProps = {
};

export default TakeAssessment;
