import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import roles, * as Roles from 'utilities/auth/roles';
import { getTranslationKey } from 'utilities/chisels';

import './dashboard-statistics.scss';

const DashboardStatistics = memo((props) => {
  const { statistics, role } = props;

  const { t } = useTranslation();

  // The statistics
  const statisticsComponents = useMemo(() => {
    if (statistics === undefined) {
      return null;
    }
    return Object.keys(statistics)?.map((statistic, index) => {
      return (
        <div
          className={
            clsx('dashboard-statistics__statistic light', {
              'dashboard-statistics__statistic--dark-companies': 0 !== index % 2 && role === Roles.COMPANY_AGENT,
              'dashboard-statistics__statistic--dark-endorsers': 0 !== index % 2 && role === Roles.ENDORSER,
              'dashboard-statistics__statistic--dark-job-seekers': 0 !== index % 2 && role === Roles.JOB_SEEKER,
              'dashboard-statistics__statistic--light-companies': 0 === index % 2 && role === Roles.COMPANY_AGENT,
              'dashboard-statistics__statistic--light-endorsers': 0 === index % 2 && role === Roles.ENDORSER,
              'dashboard-statistics__statistic--light-job-seekers': 0 === index % 2 && role === Roles.JOB_SEEKER,
            })
          }
          key={ statistic }
        >
          <p className='dashboard-statistics__number'>
            { statistics[statistic] }
          </p>
          <p className='dashboard-statistics__label'>
            { t(`common:dashboard_statistics.${ getTranslationKey(statistic) }`) }
          </p>
        </div>
      );
    });
  }, [ role, statistics, t ]);

  return (
    <div className='dashboard-statistics'>
      { statisticsComponents }
    </div>
  );
});

DashboardStatistics.displayName = 'DashboardStatistics';

DashboardStatistics.propTypes = {
  // the signed in user role
  role: PropTypes.oneOf(roles),
  // The job seekers statistics
  statistics: PropTypes.oneOfType([
    // companies statistics
    PropTypes.shape({
      // The job ready profiles
      jobReadyProfiles: PropTypes.number,
      // Job seekers available
      jobSeekersAvailable: PropTypes.number,
      // the new job seeker profiles
      newJobSeekerProfiles: PropTypes.number,
    }),
    // job seekers statistics
    PropTypes.shape({
      // The job ready profiles
      jobReadyProfiles: PropTypes.number,
      // Job seekers available
      jobSeekersAvailable: PropTypes.number,
      // the new job seeker profiles
      newJobSeekerProfiles: PropTypes.number,
    }),
    // endorsers statistics
    PropTypes.shape({
      // The endorsements viewed by companies
      endorsementsViewedByCompanies: PropTypes.number,
      // My total endorsements
      myTotalEndorsements: PropTypes.number,
      // the endorsements last week
      newEndorsementsLastWeek: PropTypes.number,
    }),
  ]),
};

DashboardStatistics.defaulProps = {};

export default DashboardStatistics;
