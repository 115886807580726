/**
 * Greek translations for common/option-filter.
 *
 * @module languages/el/common/option-filter
 */
const optionFilter = {
  no_options_message: 'Χωρίς δεδομένα.',
  see_less_button_label: 'Δείτε λιγότερα',
  see_more_button_label: 'Δείτε περισσότερα',
};

export default optionFilter;
