/**
 * Greek translations for about-us.
 *
 * @module languages/el/about-us
 */
const aboutUs = {
  how_does_it_work_section: {
    steps: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Οι υποψήφιοι κάνουν την έγγραφή τους. Συμπληρώνουν το προφίλ τους δημιουργώντας ένα βίντεο αυτοπαρουσίασης και χρησιμοποιώντας τα ψυχομετρικά μας εργαλεία.',
        title: 'Βήμα 1',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Η Odyssea και οι συνεργάτες μας συναντώνται με υποψήφιους και παρέχουν σχόλια και συμβουλές σχετικά με το προφίλ τους',
        title: 'Βήμα 2',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Οι εταιρείες μπορούν να αναζητήσουν υποψήφιους. Μπορούν να φιλτράρουν την αναζήτησή τους σύμφωνα με τις δεξιότητες, τα ψυχομετρικά αποτελέσματα και να επικοινωνήσουν με πιθανούς υποψηφίους.',
        title: 'Βήμα 3',
      },
    },
    title: 'Πώς λειτουργεί;',
  },
  matching_problem_section: {
    matching_title: 'Η διασύνδεση υποψήφιων με θέση εργασίας είναι δύσκολη',
    problems: {
      item1: {
        description: 'HORECA, Construction, Services etc.',
        title: 'Αυξανόμενες ανάγκες στην αγορά εργασίας',
      },
      item2: {
        description: '1 εκατομμύριο άνεργοι στην Ελλάδα',
        title: 'Υψηλό ποσοστό ανεργίας',
      },
    },
    title: 'Το πρόβλημα',
  },
  milestones_section: {
    milestones: {
      item1: {
        description: 'Δημόσια παρουσίαση των εγγραμμένων υποψήφιων και εταιριών',
        title: 'Q2 2023',
      },
      item2: {
        description: 'Ανατροφοδότηση από την αγορά και προετοιμασία των νέων χαρακτηριστικών',
        title: 'Q3 2023',
      },
      item3: {
        description: 'Ανάπτυξη νέων χαρακτηριστικών και βελτιώσεων της πλατφόρμας.',
        title: 'Q4 2023',
      },
      item4: {
        description: 'Η καινούργια έκδοση είναι ενεργή',
        title: 'Q1 2024',
      },
    },
    months: {
      item1: 'Απρίλιος',
      item10: 'Ιανουάριος',
      item11: 'Φεβρουάριος',
      item12: 'Μάρτιος',
      item2: 'Μάιος',
      item3: 'Ιούνιος',
      item4: 'Ιούλιος',
      item5: 'Αύγουστος',
      item6: 'Σεπτέμβριος',
      item7: 'Οκτώβριος',
      item8: 'Νοέμβριος',
      item9: 'Δεκέμβριος',
    },
    title: 'Σημεία αναφοράς',
  },
  our_supporters_section: {
    title: 'Οι υποστηρικτές μας',
  },
  solution_section: {
    // eslint-disable-next-line max-len
    description: 'Η Odyssea δημιουργεί μια καινοτόμο διαδικτυακή πλατφόρμα απασχολησιμότητας για την αντιμετώπιση του προβλήματος.\n\nΗ πλατφόρμα συνδυάζει καινοτόμα εργαλεία όπως ψυχομετρικά, επαλήθευση των προφίλ και συστάσεις τεχνητής νοημοσύνης για την υποστήριξη τόσο των ατόμων που αναζητούν εργασία όσο και των εργοδοτών',
    title: 'Η Λύση',
  },
  target_group_section: {
    cards: {
      item1: 'Ξενοδοχειακός κλάδος\n\nΚατασκευαστικές Υπηρεσίες\n\nBusiness\n\nTech εταιρίες\n',
      item2: 'Άνεργοι\n\nΥπήκοοι τρίτων χωρών\n\nΈνταξη των νέων στην αγορά εργασίας',
    },
    title: 'Η ομάδα στόχου μας',
  },
  team_section: {
    team_members: {
      member1: {
        job_title: 'CEO',
        name: 'Τζέι Μέξης',
      },
      member2: {
        job_title: 'CTO',
        name: 'Λουκάς Οικονομάκης',
      },
      member3: {
        job_title: 'Οικονομική διευθύντρια & Υπεύθυνη ανθρώπινου δυναμικού',
        name: 'Αναστασία Ζήση',
      },
      member4: {
        job_title: 'Υπεύθυνος Προγράμματος ',
        name: 'Θεόδωρος Κωστούλας',
      },
      member5: {
        job_title: 'Υπεύθυνη Απασχολησιμότητας',
        name: 'Χαρούλα Ρούφα',
      },
      member6: {
        job_title: 'Υπάλληλος Απασχολησιμότητας',
        name: 'Παναγιώτα Κούτρη',
      },
      member7: {
        job_title: 'Επιστάτης',
        name: 'Janette Mensah',
      },
    },
    title: 'Η ομάδα',
  },
  unemployment_section: {
    info: {
      item1: 'Οι εργοδότες πρέπει να φιλτράρουν πάρα πολλά βιογραφικά',
      item2: 'Ανάγκη για κουραστικό έλεγχο ιστορικού για το προφίλ και τα έγγραφα πιθανού υπαλλήλου',
      // eslint-disable-next-line max-len
      item3: 'Οι υποψήφιοι δεν έχουν τα εργαλεία για να αποτυπώσουν αποτελεσματικά την εμπειρία και τις ήπιες δεξιότητές τους',
      item4: 'Αναντιστοιχία προσδοκιών μεταξύ εργαζομένων και εργοδοτών',
    },
    title: 'Γιατί;',
  },
  vision_mission_section: {
    // eslint-disable-next-line max-len
    mission_description: 'Η αποστολή μας είναι να διασφαλίσουμε ότι όλοι οι νέοι έχουν ίσες ευκαιρίες στην κοινωνία αξιοποιώντας τη δύναμη της γνώσης για την απασχόληση και την προσωπική ανάπτυξη',
    mission_title: 'Αποστολή',
    title: 'Σχετικά με την Odyssea',
    vision_description: 'Ένας κόσμος όπου όλοι οι νέοι μπορούν να αξιοποιήσουν πλήρως τις δυνατότητές τους',
    vision_title: 'Όραμα',
  },
  welcome_section: {
    button_label: 'Επισκεφτείτε την ιστοσελίδα μας',
    // eslint-disable-next-line max-len
    description: 'Η Odyssea είναι ένας μη κερδοσκοπικός οργανισμός που υποστηρίζει νέους ευάλωτους ανθρώπους να έχουν πρόσβαση σε ευκαιρίες απασχόλησης στην κοινωνία. Παρέχουμε εξατομικευμένη επαγγελματική κατάρτιση και κατάρτιση δεξιοτήτων ζωής σε συνδυασμό με υπηρεσίες απασχολησιμότητας που γεφυρώνουν τους νέους με τον κόσμο της εργασίας και τους δίνουν τη δυνατότητα να αξιοποιήσουν πλήρως τις δυνατότητές τους.',
    key_metrics: {
      item1: {
        label: 'Εγγεγραμμένοι ανά έτος',
      },
      item2: {
        label: 'Σπουδαστές ανά χρόνο',
      },
      item3: {
        label: 'Υπηρεσίες απασχολησιμότητας ανά χρόνο',
      },
      item4: {
        label: 'Βρήκαν εργασία ανά χρόνο',
      },
      item5: {
        label: 'Συνεργαζόμενες εταιρίες',
      },
    },
    key_metrics_title: 'Βασικοί Δείκτες της Odyssea',
    title: 'Σχετικά με μας',
  },
};

export default aboutUs;
