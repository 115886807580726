import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import LearnMoreProfileFeaturesCardsSection
  from './learn-more-profile-features-cards-section/learn-more-profile-features-cards-section';

import './learn-more-profile-features-section.scss';

const LearnMoreProfileFeaturesSection = memo((props) => {
  const { cardsCollection } = props;

  const { t } = useTranslation();

  return (
    <div className='learn-more-profile-features-section'>
      <h2 className='hdg hdg-xxl learn-more-profile-features-section__title'>
        { t('job_seekers:learn_more.profile_features_section.title') }
      </h2>
      <LearnMoreProfileFeaturesCardsSection
        cardsCollection={ cardsCollection }
      />
    </div>
  );
});

LearnMoreProfileFeaturesSection.displayName = 'LearnMoreProfileFeaturesSection';

LearnMoreProfileFeaturesSection.propTypes = {
  // The collection to map the cards
  cardsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      // The card description
      description: PropTypes.string,
      // The icon of the card
      icon: PropTypes.node,
      // The title of the card
      title: PropTypes.string,
    })
  ).isRequired,
};

LearnMoreProfileFeaturesSection.defaultProps = {};

export default LearnMoreProfileFeaturesSection;
