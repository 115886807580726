/**
 * Greek translations for home.
 *
 * @module languages/el/home
 */
const home = {
  companies_collection_section: {
    button_label: 'Δείτε περισσότερα',
    title: 'Δείτε τις εταιρίες μας',
  },
  create_profile_section: {
    button_label: 'Δείτε ένα παράδειγμα προφίλ',
    description: 'Βρείτε την επόμενη εργασίας σας μέσω της Odyssea Talent Platform. Ξεκινήστε σήμερα.',
    title: 'Δημιουργήστε το προφίλ σας',
  },
  find_talent_section: {
    button_label: 'Ανακαλύψτε Ταλέντα',
    // eslint-disable-next-line max-len
    info: 'Δυσκολεύεστε να βρείτε έναν κατάλληλο υποψήφιο; Ψάξτε στο μητρώο των φιλτραρισμένων ταλέντων μας για να βρείτε αυτούς που σας ταιριάζουν καλύτερα στις ανάγκες σας.',
    title: 'ΒρείτεΤαλέντα',
  },
  grow_social_impact_section: {
    button_label: 'Μάθατε περισσότερα',
    // eslint-disable-next-line max-len
    info: 'Πετύχετε τους στόχους σας βιώσιμης ανάπτυξης (SDGs), αξιοποιώντας την τεχνογνωσία μας σε θέματα επαγγελματικής κατάρτισης, απασχολησιμότητας, αξιοπρεπούς απασχόλησης ή/και διαφορετικότητας και ενσωμάτωσης στον εργασιακό χώρο.\n\nΑς συνεργαστούμε μαζί για την κλιμάκωση του κοινωνικού αντίκτυπου.',
    title: 'Αυξήστε τον κοινωνικό σας αντίκτυπο',
  },
  learn_more_section: {
    companies_cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Εξοικονομήστε χρόνο και πόρους χρησιμοποιώντας την πλατφόρμα μας για να φιλτράρετε τους προ-ελεγμένους αιτούντες εργασία που ανταποκρίνονται στις απαιτήσεις σας.',
        title: 'Εξάλειψη του χρόνου διαλογής',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Αποκτήστε πρόσβαση σε προφίλ υψηλής ποιότητας, επαληθευμένων ταλέντων με πλήρη προφίλ και εγκρίσεις από αξιόπιστες πηγές.',
        title: 'Ανακαλύψτε φιλτραρισμένα προφίλ',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Διαφοροποιήστε το εργατικό σας δυναμικό και προωθήστε τη συμμετοχικότητα με την υποστήριξη μας ώστε να προσλάβετε ένα πολυποίκιλο φάσμα ατόμων που αναζητούν εργασία.',
        title: 'Βελτιώστε την DEI στρατηγική σας',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Κάντε τη διαφορά στον κόσμο, συνεργαζόμενοι μαζί μας και υποστηρίζοντας έναν σκοπό με κοινωνικό αντίκτυπο, ενώ παράλληλα προσλαμβάνετε κορυφαία ταλέντα.',
        title: 'Κοινωνικός αντίκτυπος',
      },
    },
    company_card_section_title: 'Εταιρία',
    job_seeker_card_section_title: 'Υποψήφιος',
    job_seekers_cards: {
      item1: {
        // eslint-disable-next-line max-len
        description: 'Δημιουργήστε ένα εντυπωσιακό προφίλ, κατεβάστε το ως βιογραφικό σημείωμα και παρουσιάστε τις δεξιότητες και την εμπειρία σας σε πιθανούς εργοδότες.',
        title: 'Δημιουργήστε και μοιραστείτε το βιογραφικό σας',
      },
      item2: {
        // eslint-disable-next-line max-len
        description: 'Κάντε ψυχομετρικά τεστ για να εντοπίσετε τα δυνατά και αδύνατα σημεία σας και χρησιμοποιήστε τις γνώσεις σας για να βελτιώσετε τις προοπτικές σταδιοδρομίας σας.',
        title: 'Γνωρίστε τα χαρακτηριστικά σας',
      },
      item3: {
        // eslint-disable-next-line max-len
        description: 'Συνδεθείτε με υποστηρικτές που παρέχουν συμβουλές και καθοδήγηση για να σας βοηθήσουν να βελτιώσετε το προφίλ σας και να ξεχωρίσετε στους εργοδότες.',
        title: 'Καθοδήγηση από υποστηρικτές',
      },
      item4: {
        // eslint-disable-next-line max-len
        description: 'Χρησιμοποιήστε τα ισχυρά εργαλεία αναζήτησης και τους αλγορίθμους μας για να αυξήσετε την προβολή σας και να γίνετε αντιληπτοί από κορυφαίες εταιρείες.',
        title: 'Ανακαλυφθείτε από εταιρείες',
      },
    },
    learn_more_button_label: 'Μάθετε περισσότερα',
    subtitle: 'Εξερευνήστε τα πλεονεκτήματα της επιλογής της πλατφόρμας ταλέντων μας',
    title: 'Γιατί να επιλέξετε Odyssea Talent Platform',
  },
  our_companies_say_section: {
    collection: {
      item1: {
        item1: {
          // eslint-disable-next-line max-len
          description: '“Ευχαριστούμε πάρα πολύ την Οδύσσεια για τη βοήθεια και ελπίζουμε σε μία μακροχρόνια συνεργασία.”',
          name: 'Ελένη Τσακίρη',
          occupation: 'Talent Acquisition Senior Manager στον Όμιλο Sani Ikos',
        },
        item2: {
          // eslint-disable-next-line max-len
          description: '“Έχουμε μια πολύ ωραία συνεργασία με την Οδύσσεια. Ελπίζουμε να βρούμε και εμείς έναν κατάλληλο υποψήφιο για τη θέση που ψάχνουμε και εκείνος μια εργασία που θα μπορέσει να φανεί χρήσιμος και στην κοινωνία μας.”',
          name: 'Μυλωνιδης Στέφανος',
          occupation: 'Διεύθυνση ανθρώπινου δυναμικού του Regency Casino Mont Parnes',
        },
        item3: {
          // eslint-disable-next-line max-len
          description: '“Με πολλή χαρά συνεργαζόμαστε με την Οδύσσεια για την υποστήριξη των ατόμων από ευπαθής ομάδες.”',
          name: 'Άντια Ζειμπογλου',
          occupation: 'Supervisor στο Karriera.gr',
        },
        item4: {
          // eslint-disable-next-line max-len
          description: '“Είναι πολύ ευχάριστο να βοηθήσουμε και εμείς από την πλευρά μας ανθρώπους που έχουν πραγματική ανάγκη να βρουν δουλειά.”',
          name: 'Σπύρος Μαθιουδάκης',
          occupation: 'Mathioudakis Elevators',
        },
      },
      item2: {
        item1: {
          // eslint-disable-next-line max-len
          description: '“Χαιρόμαστε που στηρίζουμε την Οδύσσεια και νέους ανθρώπους που αναζητούν εργασία, που ίσως έχουν αντιμετωπίσει παραπάνω δυσκολίες στην κοινωνία.”',
          name: 'Σταύρου Χριστίνα',
          occupation: 'Barilla',
        },
        item2: {
          // eslint-disable-next-line max-len
          description: '“Γνωρίσαμε κάποιους πάρα πολύ ωραίο υποψηφίους μέσω της Οδύσσεια και σίγουρα θέλουμε να τους ξαναδούμε και να συνεργαστούμε.”',
          name: 'Νεφέλη Mαρακά',
          occupation: 'Talent Acquisition Specialist στην Apply2day',
        },
        item3: {
          description: '“Το επίπεδο των συμμετεχόντων από την Οδύσσεια είναι πάρα πολύ καλό.”',
          name: 'Γιαννακάκη Κατερίνα',
          occupation: 'Senior Talent Acquisition Partner στην Pepsico',
        },
        item4: {
          description: '“Είμαστε εταιρεία ανθρώπινου δυναμικού και έχουμε μια πολύ καλή συνεργασία με την Οδύσσεια.”',
          name: 'Βέτα Παπανικολάου',
          occupation: 'KSM',
        },
      },
    },
    title: 'Our companies say...',
  },
  statistics_section: {
    statistics: {
      item1: {
        title: 'Προφίλ',
      },
      item2: {
        title: 'Εταιρίες',
      },
      item3: {
        title: 'Προσλήψεις',
      },
      item4: {
        title: 'Διασυνδεδεμένοι υποψήφιοι με εταιρίες',
      },
    },
  },
  testimonials_section: {
    testimonials: {
      item1: {
        item1: {
          age_location: '29 χρονών, Αθήνα',
          name: 'Awng Nu La',
          title: 'Δείτε την ιστορία του Awng Nu La’s από το Myanmar',
          video_url: 'https://www.youtube.com/embed/igBvNpJN6fA',
        },
        item2: {
          age_location: '28 χρονών, Αθήνα',
          name: 'Hana Ganji',
          title: '«Σας ευχαριστώ για την ευκαιρία να βελτιώσω τις δεξιότητές μου και να βρω δουλειά»',
          video_url: 'https://www.youtube.com/embed/IjViKd72tE8',
        },
        item3: {
          age_location: '30 χρονών, από την Guinea',
          // eslint-disable-next-line max-len
          description: 'Πρώτα από όλα θα ήθελα να εκφράσω την ευγνωμοσύνη μου στην Odyssea, για την προσπάθεια και τη βοήθεια προς όλους τους ανθρώπους. Μια φιλόξενη ομάδα, χαμογελαστή και ευαισθητοποιημένη στις ανησυχίες των ανθρώπων. Από την πλευρά μου, \nτους προτείνω σε όποιον ψάχνει απαντήσεις για τα ταλέντα του ή για το τι πρέπει να ασχοληθεί.',
          name: 'Mamadou Oury Diallo',
          title: '“Μια φιλόξενη ομάδα, χαμογελαστή και ευαισθητοποιημένη στις ανησυχίες των ανθρώπων.”',
        },
      },
      item2: {
        item1: {
          age_location: '30 χρονών, Αθήνα',
          name: 'Shah in Alon',
          title: 'Η ιστορία του Shah in Alon από το Bangladesh',
          video_url: 'https://www.youtube.com/embed/AOOuSiYLG7Q',
        },
        item2: {
          age_location: '26 χρονών, Αθήνα',
          name: 'Hamed',
          title: '”Η Odyssea μου έδωσε την ευκαιρία να εργαστώ στην ειδικότητά μου”',
          video_url: 'https://www.youtube.com/embed/UPzTNeYNvi0',
        },
        item3: {
          age_location: '18 χρονών, Gambia',
          // eslint-disable-next-line max-len
          description: 'Momodou θέλοντας να ενθαρρύνει την αδερφή του, μπήκε ο ίδιος στο μάθημα για Ξενοδοχειακός Υπάλληλος. Μετά την αποφοίτησή του, παρακολούθησε το Odyssea Job Fair και εξασφάλισε δουλειά σε έναν κορυφαίο ξενοδοχειακό όμιλο',
          name: 'Momodou Jallow',
          // eslint-disable-next-line max-len
          title: '«Θέλω να κάνω την αδερφή μου να πιστέψει ότι μπορεί να ακολουθήσει τα όνειρά της. Θέλω να ευχαριστήσω την Odyssea και τους συνεργάτες της για την υποστήριξή τους στο κυνήγι της ευτυχίας μου».',
        },
      },
      item3: {
        item1: {
          age_location: 'Σεράφειο, Αθήνα',
          name: 'Job Fair',
          title: 'Job Fair “ΘΕΛΗΣΗ” με έμφαση στην απασχολησιμότητα και τον εθελοντισμό',
          video_url: 'https://www.youtube.com/embed/5kIPN3Bob80',
        },
        item2: {
          age_location: 'Αθήνα',
          name: 'Τεχνική κατάρτιση',
          title: 'Tailor Made Course με την Mathioudakis Elevators',
          video_url: 'https://www.youtube.com/embed/seAWxtSWLYU',
        },
        item3: {
          age_location: 'Από την Αθήνα',
          // eslint-disable-next-line max-len
          description: '" Ήρθα στην Odyssea και παρακολούθησα το σεμινάριο τροφοδοσίας και εξυπηρέτησης προκειμένου να αποκτήσω τις επαγγελματικές δεξιότητες που απαιτούνται για να αποκτήσω μια νέα θέση εργασίας. Τελικά, κατάφερα να βρω δουλειά και μπόρεσα να ενσωματωθώ αμέσως στον εργασιακό χώρο".',
          name: 'Ελευθερία Σ.',
          // eslint-disable-next-line max-len
          title: '"Η τύχη με έφερε στην Odyssea, η ομάδα και όλα όσα έμαθα ήταν η ικανοποίηση του να βρίσκομαι σε ένα περιβάλλον τόσο ωραίο που ήταν ταυτόχρονα και αυθεντικό."',
        },
      },
    },
    title: 'Οι ιστορίες των υποψηφίων μας',
  },
  welcome_section: {
    // eslint-disable-next-line max-len
    card_description: 'Καλώς ήρθατε στην Odyssea Talent platform. Βοηθάμε όσους αναζητούν εργασία να βρουν ουσιαστική απασχόληση. Βοηθάμε τις εταιρείες να βρουν τα καλύτερα ταλέντα. Όραμά μας είναι να δημιουργήσουμε ίσες ευκαιρίες για όλους.',
    company_button_label: 'Είστε εταιρία? Εγγραφείτε τώρα',
    job_seeker_button_label: 'Είστε υποψήφιοι Εγγραφείτε τώρα',
    or: 'Ή',
  },
};

export default home;
