/**
 * English translations for common/sidebar-profile-completion.
 *
 * @module languages/en/common/sidebar-profile-completion
 */
const sidebarProfileCompletion = {
  title: 'Profile completion',
};

export default sidebarProfileCompletion;
