/**
 * Sign-up page.
 */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import AuthSocialButtons from 'components/common/auth-social-buttons/auth-social-buttons';
import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import { actions as requestsActions } from 'ducks/requests';
import * as usersMethods from 'resources/users';
import * as toasts from 'toasts';
import * as AuthenticationProviders from 'utilities/auth/provider';
import roles, * as Roles from 'utilities/auth/roles';

import GoogleSignUpForm from './google-sign-up-form/google-sign-up-form';
import LinkedInSignUpForm from './linkedin-sign-up-form/linkedin-sign-up-form';
import SignUpForm from './sign-up-form/sign-up-form';

import './sign-up.scss';

const SignUp = (props) => {
  const { role } = props;

  // The auth reducer.
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  const dispatch = useDispatch();

  // The authentication provider
  const [ authenticationProvider, setAuthenticationProvider ] = useState(AuthenticationProviders.LOCAL);

  // The oauth authorization token (e.g. linkedin authorization code, google authentication token).
  const [ oauthAuthorizationToken, setOAuthAuthorizationToken ] = useState('');

  // The oauth predefined user's fields
  const [ oauthPredefinedUsersField, setOAuthPredefinedUsersFields ] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  // Whether the user has been created.
  const [ created, setCreated ] = useState(false);

  const { t } = useTranslation();

  // whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  // the sign up title
  const signUpTitle = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('sign_up:title_job_seeker');
    case Roles.COMPANY_AGENT:
      return t('sign_up:title_company_agent');
    case Roles.ENDORSER:
      return t('sign_up:title_endorser');
    default:
      return t('sign_up:title_job_seeker');
    }
  }, [ role, t ]);

  // function that handles whether the user has been created
  const handleCreated = useCallback((created) => {
    setCreated(created);
  }, []);

  // Function that resets the authentication and authorization flow
  const resetAuthenticationAuthorizationFlow = useCallback(() => {
    // reset the authentication provider
    setAuthenticationProvider(AuthenticationProviders.LOCAL);
    // reset the oauth authorization token
    setOAuthAuthorizationToken('');
    // reset the predefined user's fields
    setOAuthPredefinedUsersFields({
      email: '',
      firstName: '',
      lastName: '',
    });
  }, []);

  // The rendered sign up form
  const renderedSignUpForm = useMemo(() => {
    if (authenticationProvider === AuthenticationProviders.LINKEDIN) {
      return (
        <LinkedInSignUpForm
          accessToken={ oauthAuthorizationToken }
          handleCreated={ handleCreated }
          key={ role }
          predefinedUsersFields={ oauthPredefinedUsersField }
          resetAuthenticationAuthorizationFlow={ resetAuthenticationAuthorizationFlow }
          role={ role }
        />
      );
    }
    if (authenticationProvider === AuthenticationProviders.GOOGLE) {
      return (
        <GoogleSignUpForm
          accessToken={ oauthAuthorizationToken }
          handleCreated={ handleCreated }
          key={ role }
          predefinedUsersFields={ oauthPredefinedUsersField }
          resetAuthenticationAuthorizationFlow={ resetAuthenticationAuthorizationFlow }
          role={ role }
        />
      );
    }
    return (
      <SignUpForm
        handleCreated={ handleCreated }
        key={ role }
        role={ role }
      />
    );
  }, [
    authenticationProvider,
    handleCreated,
    oauthAuthorizationToken,
    oauthPredefinedUsersField,
    resetAuthenticationAuthorizationFlow,
    role,
  ]);

  //  The 'not current role' display text
  const notCurrentRoleText = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('sign_up:not_job_seeker');
    case Roles.COMPANY_AGENT:
      return t('sign_up:not_company_agent');
    case Roles.ENDORSER:
      return t('sign_up:not_endorser');
    default:
      return t('sign_up:not_job_seeker');
    }
  }, [ role, t ]);

  // The first bottom link state
  const firstBottomLinkState = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return { role: Roles.COMPANY_AGENT };
    }
    return { role: Roles.JOB_SEEKER };
  }, [ role ]);

  // The first bottom link to url
  const firstBottomLinkToUrl = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return '/companies/sign-up';
    }
    return '/job-seekers/sign-up';
  }, [ role ]);

  // The first bottom link text
  const firstBottomLinkText = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return t('sign_up:company_link_text');
    }
    return t('sign_up:job_seeker_link_text');
  }, [ role, t ]);

  // The second bottom link state
  const secondBottomLinkState = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return { role: Roles.ENDORSER };
    }
    return { role: Roles.JOB_SEEKER };
  }, [ role ]);

  // The second bottom link to url
  const secondBottomLinkToUrl = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return '/endorsers/sign-up';
    }
    return '/job-seekers/sign-up';
  }, [ role ]);

  // The second bottom link text
  const secondBottomLinkText = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return t('sign_up:endorser_link_text');
    }
    return t('sign_up:job_seeker_link_text');
  }, [ role, t ]);

  // The function that is invoked when handling the Google sign up
  const handleGoogleSignUp = useCallback((accessToken, tokenType) => {
    dispatch(requestsActions.request(usersMethods.getUsersGoogleProfile, {
      accessToken,
      tokenType,
    }, {
      onFailure: (_error) => {
        toasts.error(t('sign_up:retrieve_google_profile_error'));
      },
      onSuccess: (res) => {
        // We set the authentication provider
        setAuthenticationProvider(AuthenticationProviders.GOOGLE);
        // We set the oauth authorization token (e.g linkedin authorization code, or google access token)
        setOAuthAuthorizationToken(accessToken);
        // We set the predefined fields in the form from the Google login
        setOAuthPredefinedUsersFields({
          email: res.email,
          firstName: res.given_name,
          lastName: res.family_name,
        });
      },
    }));
  }, [ dispatch, t ]);

  // The function that is invoked when handling the linked in sign up
  const handleLinkedinSignUp = useCallback((authorizationCode) => {
    dispatch(requestsActions.request(usersMethods.getUsersLinkedInProfile, {
      authorizationCode,
    }, {
      onFailure: (_error) => {
        toasts.error(t('sign_up:retrieve_linkedin_profile_error'));
      },
      onSuccess: (res) => {
        // We set the authentication provider
        setAuthenticationProvider(AuthenticationProviders.LINKEDIN);
        // We set the oauth authorization token (e.g linkedin access token, or google access token)
        setOAuthAuthorizationToken(res.accessToken);
        // We set the predefined fields in the form from the Google login
        setOAuthPredefinedUsersFields({
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
        });
      },
    }));
  }, [ dispatch, t ]);

  // The rendered social buttons
  const renderedSocialButtons = useMemo(() => {
    if (authenticationProvider !== AuthenticationProviders.LOCAL) {
      return (
        <>
          <button
            className='btn btn-custom btn-rounded-sm btn-blue ody-sign-up__reset-authentication-provider-button'
            id='reset-authentication-provider'
            name='reset authentication provider'
            onClick={ resetAuthenticationAuthorizationFlow }
          >
            { t('sign_up:reset_authentication_provider_button_label') }
          </button>
        </>
      );
    }
    return (
      <div className='ody-sign-up__social-buttons-container'>
        <AuthSocialButtons
          googleButtonLabel={ t('sign_up:continue_with_google_button_label') }
          handleGoogleSign={ handleGoogleSignUp }
          handleLinkedinSign={ handleLinkedinSignUp }
          linkedinButtonLabel={ t('sign_up:continue_with_linkedin_button_label') }
        />
      </div>
    );
  }, [
    authenticationProvider,
    handleGoogleSignUp,
    handleLinkedinSignUp,
    resetAuthenticationAuthorizationFlow,
    t,
  ]);

  // The rendered content whether the user is created
  const renderedContent = useMemo(() => {
    if (created) {
      return (
        <p className='ody-sign-up__created txt txt-sm'>
          { t('sign_up:created_message') }
        </p>
      );
    }
    return (
      <>
        <h1 className='ody-sign-up__title hdg hdg-xl'>
          { signUpTitle }
        </h1>
        { renderedSignUpForm }
        <div className='txt txt-sm'>
          <span>{ t('sign_up:already_have_account') }</span>
                 &nbsp;
          <Link className='link inline' to='/sign-in'>
            { t('sign_up:sign_in_link_text') }
          </Link>
        </div>
        <span className='ody-sign-up__separator'>
          { t('sign_up:or') }
        </span>
        { renderedSocialButtons }
        <div className='txt txt-sm'>
          <span>{ notCurrentRoleText }</span>
             &nbsp;
          <span>{ t('sign_up:sign_up_as') }</span>
             &nbsp;
          <Link
            className='link inline'
            onClick={ resetAuthenticationAuthorizationFlow }
            state={ firstBottomLinkState }
            to={ firstBottomLinkToUrl }
          >
            { firstBottomLinkText }
          </Link>
                  &nbsp;
          { t('sign_up:or') }
                  &nbsp;
          <Link
            className='link inline'
            onClick={ resetAuthenticationAuthorizationFlow }
            state={ secondBottomLinkState }
            to={ secondBottomLinkToUrl }
          >
            { secondBottomLinkText }
          </Link>
        </div>
      </>
    );
  }, [
    created,
    firstBottomLinkState,
    firstBottomLinkText,
    firstBottomLinkToUrl,
    notCurrentRoleText,
    renderedSignUpForm,
    renderedSocialButtons,
    resetAuthenticationAuthorizationFlow,
    secondBottomLinkState,
    secondBottomLinkText,
    secondBottomLinkToUrl,
    signUpTitle,
    t,
  ]);

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <div className='ody-sign-up'>
        <div className='ody-sign-up__form-wrapper'>
          { renderedContent }
        </div>
        <div className='ody-sign-up__logo'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [ renderedContent, userAuthenticated ]);
};

SignUp.propTypes = {
  // The role of the person that is signing up
  role: PropTypes.oneOf(roles).isRequired,
};

SignUp.defaultProps = {
};

export default SignUp;
