/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const StarCardIcon = memo((props) => {
  const { isWhite } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 37'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2361 2.66549C16.2209 11.7113 13.1654 27.4309 11.4769 34.8685C16.241 31.8331 36.3427 13.9627 39.559 10.4047C33.1063 9.5001 6.23032 16.6764 1.96875 16.9578C9.86874 20.0334 33.8702 30.1446 38.0313 31.0492C37.4684 29.4008 22.5328 5.70086 19.216 2.04234'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M18.0305 2.34381C14.6735 12.8771 12.6634 23.7723 10.2713 34.5468C10.0502 35.5318 11.1357 36.5771 12.0804 35.9338C22.1916 29.1394 32.0817 20.1338 40.4239 11.269C41.1877 10.4448 40.6048 9.25881 39.5595 9.15831C26.9557 8.05271 14.372 14.425 1.96928 15.7115C0.562156 15.8522 0.320928 17.6211 1.64764 18.1438C13.4876 22.8074 25.3476 29.0992 37.7101 32.2351C38.8358 32.5165 39.5394 31.3707 39.0972 30.4259C34.6547 20.4354 27.1969 9.47993 20.101 1.1779C19.0758 -0.028203 17.327 1.72064 18.3522 2.92675C25.2873 11.068 32.6043 21.8827 36.9664 31.6924C37.4287 31.0893 37.8911 30.4863 38.3534 29.8832C25.9908 26.7473 14.1509 20.4555 2.29091 15.7919C2.1904 16.5959 2.06979 17.4 1.96928 18.2242C14.3519 16.9377 26.9959 10.5654 39.5595 11.671C39.278 10.9675 38.9765 10.2639 38.6951 9.56033C30.5137 18.2644 20.7644 27.1695 10.8542 33.8433C11.4573 34.3056 12.0604 34.768 12.6634 35.2303C15.0555 24.4557 17.0456 13.5606 20.4226 3.02726C20.9051 1.47943 18.513 0.836176 18.0305 2.34381Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
    </svg>
  );
});

StarCardIcon.displayName = 'StarCardIcon';

StarCardIcon.propTypes = {
  // Whether the icon is white
  isWhite: PropTypes.bool,
};

StarCardIcon.defaultProps = {
  isWhite: false,
};

export default StarCardIcon;
