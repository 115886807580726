/**
 * Resource method to deactivate the user that is currently signed-in.
 *
 * @module resources/users/deactivate-me
 */
import axios from 'axios';

/**
 * Deactivates the user that is currently signed-in.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<void>} - The promise.
 * @static
 */
const deactivateMe = () => {
  return axios.request({
    method: 'post',
    url: '/users/me/deactivate',
  }).then((response) => {
    return response.data;
  });
};

export default deactivateMe;
