/**
 * English translations for endorsers/learn-more.
 *
 * @module languages/en/endorsers/learn-more
 */
const learnMore = {
  become_endorser_partner_section: {
    // eslint-disable-next-line max-len
    description: 'We follow a verification process for our endorsers. Sign up and become our partner in scaling social impact.\n\nAnyone that voluntarily wants to support people in employment can join us.',
    example_endorsement_button_label: 'See an endorsment example',
    join_us_button_label: 'Join us',
    title: 'How to become an endorser partner',
  },
  our_endorsers_section: {
    button_label: 'See all Endorsers',
    title: 'Our endorsers',
  },
  sign_in_section: {
    title: 'Help our jobs seekers shine',
  },
  what_endorser_do_section: {
    endorsers_actions: {
      item1: 'Completes a 15-minute interview with a job seeker',
      item2: 'Identifies job seeker’s competent skills',
      item3: 'Rates independently job seekers soft skills',
      item4: 'Highlights job seekers resilient stories',
      item5: 'Provides constructive feedback',
    },
    title: 'What does an endorser do',
  },
  why_section: {
    // eslint-disable-next-line max-len
    description: 'An endorser assists job seekers to identify their strengths and improve their profile. With a few minutes of your time, you can provide valuable support to our beneficiaries.\n\nThe whole process is very well structured and guides you thoroughly.',
    title: 'Why',
  },
};

export default learnMore;
