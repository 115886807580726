/**
 * Driving license categories.
 *
 * @module utilities/driving-license-categories
 */
export default [
  'CATEGORY_A',
  'CATEGORY_B',
  'CATEGORY_C',
  'CATEGORY_D',
];
