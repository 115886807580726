/**
 * Greek translations for endorsers/profile-professional-details.
 *
 * @module languages/el/endorsers/profile-professional-details
 */
const profileProfessionalDetails = {
  city_placeholder: 'Επιλέξτε πόλη',
  company_name_label: 'Όνομα εταιρίας',
  company_name_placeholder: 'Εισαγάγετε το όνομα της εταιρείας σας',
  company_position_label: 'Θέση εργασίας (Προαιρετικό)',
  company_position_placeholder: 'Θέση εργασίας που έχετε στην εταιρεία',
  company_website_label: 'Ιστοσελίδα της εταιρείας (προαιρετικό)',
  company_website_placeholder: 'Εισάγετε την ιστοσελίδα της εταιρίας σας',
  company_website_text_label: 'Ιστοσελίδα της εταιρείας',
  district_label: 'Περιοχή',
  district_placeholder: 'Περιοχή',
  editor_title: 'Επαγγελματικά στοιχεία',
  linkedin_profile: 'Προφίλ LinkedIn',
  linkedin_profile_url_label: 'URL προφίλ LinkedIn',
  linkedin_profile_url_placeholder: 'Επικολλήστε τη διεύθυνση URL του προφίλ σας στο LinkedIn',
  location_label: 'Τοποθεσία',
  location_placeholder: 'Τοποθεσία',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Ο αριθμός τηλεφώνου πρέπει να είναι αριθμός με το πρόθεμα της χώρας (π.χ.  30) και να μην υπερβαίνει τους 10 αριθμούς',
  phone_number_label: 'Αριθμός τηλεφώνου',
  phone_number_placeholder: 'Εισάγετε τον αριθμό του επαγγελματικού σας τηλεφώνου',
  phone_number_required_error: 'Ο αριθμός τηλεφώνου δεν πρέπει να είναι κενός',
  title: 'Επαγγελματικά στοιχεία',
  website_invalid_url: 'Εισαγάγετε μια έγκυρη διεύθυνση URL',
};

export default profileProfessionalDetails;
