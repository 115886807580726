/**
 * Dashboard.
 */
import clsx from 'clsx';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AdminDashboard from 'components/administrators/dashboard/dashboard';
import ContactButton from 'components/common/contact-button/contact-button';
import DashboardContactShareSection
  from 'components/common/dashboard-contact-share-section/dashboard-contact-share-section';
import DashboardStatistics from 'components/common/dashboard-statistics/dashboard-statistics';
import ProfileCompletion from 'components/common/profile-completion/profile-completion';
import ProfileOverview from 'components/common/profile-overview/profile-overview';
import SearchForm from 'components/common/search-form/search-form';
import VideosAndGuidesSection from 'components/common/videos-and-guides-section/videos-and-guides-section';
import DashboardSavedJobSeekers from 'components/companies/dashboard-saved-job-seekers/dashboard-saved-job-seekers';
import DashboardEndorsementsSection
  from 'components/endorsers/dashboard-endorsements-section/dashboard-endorsements-section';
import { actions as companiesActions } from 'ducks/companies';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as jobSeekersActions } from 'ducks/job-seekers';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as requestActions, actions as requestsActions } from 'ducks/requests';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';
import * as EndorsementStatuses from 'utilities/endorsement-status';
import * as JobSeekersParts from 'utilities/job-seekers/parts';
import * as PricingPlans from 'utilities/pricing-plans';

import './dashboard.scss';
import * as Parts from '../../utilities/companies/parts';

const Dashboard = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The profiles state object
  const profiles = useSelector((store) => {
    return store.profiles;
  }, shallowEqual);

  // The job sectors auth state object
  const jobSectors = useSelector((store) => {
    return store.jobSectors;
  }, shallowEqual);

  // The companies state object
  const companies = useSelector((store) => {
    return store.companies;
  }, shallowEqual);

  // The job seekers state object
  const jobSeekers = useSelector((store) => {
    return store.jobSeekers;
  }, shallowEqual);

  // The endorsers state object
  const endorsers = useSelector((store) => {
    return store.endorsers;
  }, shallowEqual);

  // The professions state object
  const professions = useSelector((store) => {
    return store.professions;
  }, shallowEqual);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // The authenticated user id
  const userId = useMemo(() => {
    return auth?.id;
  }, [ auth?.id ]);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return undefined !== userId;
  }, [ userId ]);

  // The authenticated user company id when his role is COMPANY_AGENT
  const userCompanyId = useMemo(() => {
    return auth?.companyId;
  }, [ auth?.companyId ]);

  // The authenticated user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // The authenticated user profile
  const userProfile = useMemo(() => {
    return profiles?.profile;
  }, [ profiles?.profile ]);

  // the company first job sector
  const companyFirstJobSector = useMemo(() => {
    return userProfile?.jobSectors?.[0];
  }, [ userProfile?.jobSectors ]);

  // All the job sectors
  const allJobSectors = useMemo(() => {
    return jobSectors?.jobSectors;
  }, [ jobSectors?.jobSectors ]);

  // The saved job seekers profiles
  const savedJobSeekersProfiles = useMemo(() => {
    return companies?.savedJobSeekersProfiles;
  }, [ companies?.savedJobSeekersProfiles ]);

  // The statistics for the company dashboard
  const jobSeekersStatistics = useMemo(() => {
    return jobSeekers?.statistics;
  }, [ jobSeekers?.statistics ]);

  // The endorsers statistics
  const endorsersStatistics = useMemo(() => {
    return endorsers?.statistics;
  }, [ endorsers?.statistics ]);

  // All the professions
  const allProfessions = useMemo(() => {
    return professions?.professions;
  }, [ professions?.professions ]);

  // The created endorsement requests
  const createdEndorsementRequests = useMemo(() => {
    return endorsers?.createdEndorsementRequests;
  }, [ endorsers?.createdEndorsementRequests ]);

  // The claimed endorsement requests
  const claimedEndorsementRequests = useMemo(() => {
    return endorsers?.claimedEndorsementRequests;
  }, [ endorsers?.claimedEndorsementRequests ]);

  // The statistics based on the role
  const statistics = useMemo(() => {
    switch (userRole) {
    case Roles.COMPANY_AGENT:
      return jobSeekersStatistics;
    case Roles.ENDORSER:
      return endorsersStatistics;
    case Roles.JOB_SEEKER:
      return {
        companiesInterested: 20,
        profileViews: 15,
        profileViewsLastWeek: 10,
      };
    default:
      return undefined;
    }
  }, [ endorsersStatistics, jobSeekersStatistics, userRole ]);

  // selected language
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The company job sector locale display string
  const companyJobSector = useMemo(() => {
    return allJobSectors?.find((jobSector) => {
      return jobSector.id === companyFirstJobSector;
    })?.[selectedLanguage];
  }, [ allJobSectors, companyFirstJobSector, selectedLanguage ]);

  // Function that is invoked when the search button is clicked
  const handleChangeSearchForm = useCallback((formCriteria) => {
    const url = '/job-seekers/search';
    if (0 < formCriteria.length) {
      const criteriaForUrl = formCriteria.map((criterion) => {
        return `c=${ criterion.type }:${ criterion.value }`;
      });
      window.open(`${ url }?${ criteriaForUrl.join('&') }&p1&s=LAST_MODIFIED_AT`);
      return;
    }
    window.open(url, '_blank');
  }, []);

  // The part completions of the company agent profile
  const partCompletions = useMemo(() => {
    return userProfile?.completion?.partCompletions || [];
  }, [ userProfile?.completion?.partCompletions ]);

  // The completion percentage of the company agent profile
  const completionPercentage = useMemo(() => {
    return userProfile?.completion?.percentage || 0;
  }, [ userProfile?.completion?.percentage ]);

  // The 'learn more' link
  const toCompaniesPricingPlans = useMemo(() => {
    return '/companies/learn-more#pricing-plans';
  }, []);

  // The saved job seekers UUID array
  const savedJobSeekers = useMemo(() => {
    return userProfile?.savedJobSeekers;
  }, [ userProfile?.savedJobSeekers ]);

  const fetchSavedJobSeekersProfiles = useCallback(() => {
    dispatch(requestActions.request(companiesMethods.getSavedJobSeekersProfiles, {
      id: userCompanyId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('dashboard:common.general_error'));
      },
      onSuccess: (result) => {
        dispatch(companiesActions.fetchSavedJobSeekersProfiles(result.profiles));
      },
    }));
  }, [ userCompanyId, dispatch, t ]);

  // Function that gets the job seekers statistics
  const fetchJobSeekersStatistics = useCallback(() => {
    dispatch(requestsActions.request(jobSeekersMethods.getStatistics,
      {},
      {
        onFailure: (_error) => {
          toasts.error(t('dashboard:common.general_error'));
        },
        onSuccess: (statistics) => {
          dispatch(jobSeekersActions.setStatistics(statistics));
        },
      }));
  }, [ dispatch, t ]);

  // Function that gets the endorsers statistics
  const fetchEndorsersStatistics = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getStatistics,
      {},
      {
        onFailure: (_error) => {
          toasts.error(t('dashboard:common.general_error'));
        },
        onSuccess: (statistics) => {
          dispatch(endorsersActions.setStatistics(statistics));
        },
      }));
  }, [ dispatch, t ]);

  // function that is invoked when clicking the profile download button
  const handleOnDownloadProfileClick = useCallback(() => {
    dispatch(requestActions.request(jobSeekersMethods.exportProfile, {
      id: userId,
    }, {
      onFailure: () => {
        toasts.error(t('dashboard:failed_download_profile'));
      },
      onSuccess: (result) => {
        fileDownload(result.data, result.filename);
      },
    }));
  }, [ dispatch, t, userId ]);

  // function that gets the created endorsement requests
  const fetchCreatedEndorsementRequests = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getCreatedEndorsementRequests,
      {},
      {
        onFailure: (_error) => {
          toasts.error(t('dashboard:failed_created_endorsement_requests'));
        },
        onSuccess: (result) => {
          dispatch(endorsersActions.setCreatedEndorsementRequests(result.endorsementRequests));
        },
      }));
  }, [ dispatch, t ]);

  // function that gets the claimed endorsement requests
  const fetchClaimedEndorsementRequests = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getClaimedEndorsementRequests,
      {},
      {
        onFailure: (_error) => {
          toasts.error(t('dashboard:failed_claimed_endorsement_requests'));
        },
        onSuccess: (result) => {
          dispatch(endorsersActions.setClaimedEndorsementRequests(result.endorsementRequests));
        },
      }));
  }, [ dispatch, t ]);

  useEffect(() => {
    switch (userRole) {
    case Roles.COMPANY_AGENT:
      fetchSavedJobSeekersProfiles();
      fetchJobSeekersStatistics();
      break;
    case Roles.ENDORSER:
      fetchEndorsersStatistics();
      fetchClaimedEndorsementRequests();
      fetchCreatedEndorsementRequests();
      break;
    default:
      break;
    }
  }, [
    fetchSavedJobSeekersProfiles,
    fetchJobSeekersStatistics,
    userRole,
    fetchEndorsersStatistics,
    fetchClaimedEndorsementRequests,
    fetchCreatedEndorsementRequests,
  ]);

  // the dashboard pricing section
  const pricingSection = useMemo(() => {
    if ((userProfile?.pricingPlan !== undefined && userProfile?.pricingPlan !== PricingPlans.FREE)
      || userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='ody-dashboard__contact light'>
        <h2 className='ody-dashboard__contact-title hdg hdg-md'>
          { t('dashboard:full_capacity_title') }
        </h2>
        <div className='ody-dashboard__contact-buttons'>
          <Link
            className='ody-dashboard__contact-button btn btn-sm btn-rounded-sm'
            to={ toCompaniesPricingPlans }
          >
            { t('dashboard:learn_more_button_title') }
          </Link>
          <ContactButton
            className='ody-dashboard__contact-button btn btn-sm btn-rounded-sm'
          />
        </div>
      </div>
    );
  }, [ t, toCompaniesPricingPlans, userProfile?.pricingPlan, userRole ]);

  // The rendered search form
  const renderedSearchForm = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <SearchForm
        authenticated={ userAuthenticated }
        companyDashboard
        jobSectors={ allJobSectors }
        onChange={ handleChangeSearchForm }
        role={ Roles.JOB_SEEKER }
      />
    );
  }, [ allJobSectors, handleChangeSearchForm, userAuthenticated, userRole ]);

  // The rendered profile completion
  const renderedProfileCompletion = useMemo(() => {
    switch (userRole) {
    case Roles.JOB_SEEKER:
      return (
        <div className='ody-dashboard__section ody-dashboard__section--next-steps-job-seekers light'>
          <ProfileCompletion
            partCompletions={ partCompletions }
            percentage={ completionPercentage }
            userId={ userId }
            userRole={ userRole }
          />
        </div>
      );
    case Roles.COMPANY_AGENT:
      return (
        <div className='ody-dashboard__section ody-dashboard__section--next-steps-companies dark'>
          <ProfileCompletion
            companyId={ userCompanyId }
            partCompletions={ partCompletions }
            percentage={ completionPercentage }
            userId={ userId }
            userRole={ userRole }
          />
        </div>
      );
    case Roles.ENDORSER:
      return null;
    default:
      return null;
    }
  }, [ completionPercentage, partCompletions, userCompanyId, userId, userRole ]);

  // The function that is triggered when the work availability is changed
  const onWorkAvailabilityChange = useCallback((workAvailability) => {
    dispatch(requestsActions.request(jobSeekersMethods.updateProfile, {
      part: JobSeekersParts.WORK_AVAILABILITY,
      profile: {
        id: userId,
        workAvailability,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('dashboard:profile_overview_and_work_availability.'
          + 'work_availability_update_failed_error'));
      },
      onSuccess: (result) => {
        dispatch(profilesActions.setProfile(result));
        toasts.info(t('dashboard:profile_overview_and_work_availability.'
          + 'work_availability_updated_message'));
      },
    }));
  }, [ dispatch, t, userId ]);

  // The job seekers statistics section
  const jobSeekersStatisticsSection = useMemo(() => {
    if (userRole !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <DashboardStatistics
        role={ userRole }
        statistics={ statistics }
      />
    );
  }, [ statistics, userRole ]);

  // companies endorsers statistics section
  const companiesEndorsersStatisticsSection = useMemo(() => {
    if (userRole === Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <DashboardStatistics
        role={ userRole }
        statistics={ statistics }
      />
    );
  }, [ statistics, userRole ]);

  // The function that is invoked when unsaving a job seeker profile from dashboard
  const handleOnUnsaveJobSeeker = useCallback((filteredSavedJobSeekers, filteredSavedJobSeekersProfiles) => {
    dispatch(requestsActions.request(companiesMethods.updateProfile, {
      part: Parts.SAVED_JOB_SEEKERS,
      profile: {
        id: userCompanyId,
        savedJobSeekers: filteredSavedJobSeekers,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('dashboard:common.general_error'));
      },
      onSuccess: (result) => {
        toasts.info(t('dashboard:common.changes_saved_message'));
        dispatch(profilesActions.setProfile(result));
        dispatch(companiesActions.fetchSavedJobSeekersProfiles(filteredSavedJobSeekersProfiles));
      },
    }));
  }, [ dispatch, t, userCompanyId ]);

  // The saved job seekers section
  const savedJobSeekersSection = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <DashboardSavedJobSeekers
        allProfessions={ allProfessions }
        handleOnUnsaveJobSeeker={ handleOnUnsaveJobSeeker }
        savedJobSeekers={ savedJobSeekers }
        savedJobSeekersProfiles={ savedJobSeekersProfiles }
      />
    );
  }, [ allProfessions, handleOnUnsaveJobSeeker, savedJobSeekers, savedJobSeekersProfiles, userRole ]);

  // The function (endorsementId) => void to be invoked when claiming a CREATED endorsement request
  const handleClaimedEndorsementRequest = useCallback((endorsementId) => {
    dispatch(requestsActions.request(endorsersMethods.claimEndorsementRequest, {
      id: endorsementId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('dashboard:failed_to_claim_endorsement_request'));
        fetchCreatedEndorsementRequests();
      },
      onSuccess: () => {
        const foundCreatedEndorsementRequest = createdEndorsementRequests.find((endorsementRequest) => {
          return endorsementRequest.id === endorsementId;
        });
        const newCreatedEndorsementRequests = createdEndorsementRequests.filter((endorsementRequest) => {
          return endorsementRequest.id !== endorsementId;
        });
        const newClaimedEndorsementRequests = [
          ...claimedEndorsementRequests,
          foundCreatedEndorsementRequest,
        ];
        const payloadToDispatch = {
          claimedEndorsementRequests: newClaimedEndorsementRequests,
          createdEndorsementRequests: newCreatedEndorsementRequests,
        };
        dispatch(endorsersActions.claimEndorsementRequest(payloadToDispatch));
      },
    }));
  }, [
    claimedEndorsementRequests,
    createdEndorsementRequests,
    dispatch,
    fetchCreatedEndorsementRequests,
    t,
  ]);

  // The function (endorsementId) => void to be invoked when revoking a CLAIMED endorsement request
  const handleRevokedClaimedEndorsementRequest = useCallback((endorsementId) => {
    dispatch(requestsActions.request(endorsersMethods.revokeClaimedEndorsementRequest, {
      id: endorsementId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('dashboard:failed_to_revoke_endorsement_request'));
        fetchClaimedEndorsementRequests();
      },
      onSuccess: () => {
        const foundClaimedEndorsementRequest = claimedEndorsementRequests.find((endorsementRequest) => {
          return endorsementRequest.id === endorsementId;
        });
        const newClaimedEndorsementRequests = claimedEndorsementRequests.filter((endorsementRequest) => {
          return endorsementRequest.id !== endorsementId;
        });
        const newCreatedEndorsementRequests = [
          ...createdEndorsementRequests,
          foundClaimedEndorsementRequest,
        ];
        const payloadToDispatch = {
          claimedEndorsementRequests: newClaimedEndorsementRequests,
          createdEndorsementRequests: newCreatedEndorsementRequests,
        };
        dispatch(endorsersActions.revokeEndorsementRequest(payloadToDispatch));
      },
    }));
  }, [ claimedEndorsementRequests, createdEndorsementRequests, dispatch, fetchClaimedEndorsementRequests, t ]);

  // The function (endorsement) => void to be invoked when reviewing a claimed endorsement
  const handleReviewClaimedEndorsement = useCallback((endorsement) => {
    dispatch(endorsersActions.setEndorsement(endorsement));
  }, [ dispatch ]);

  // The dashboard endorsements section
  const dashboardEndorsementsSection = useCallback((endorsementRequests, status) => {
    if (userRole !== Roles.ENDORSER) {
      return null;
    }
    return (
      <DashboardEndorsementsSection
        claimEndorsementRequest={ handleClaimedEndorsementRequest }
        endorsementRequests={ endorsementRequests }
        handleReviewClaimedEndorsement={ handleReviewClaimedEndorsement }
        revokeClaimedEndorsementRequest={ handleRevokedClaimedEndorsementRequest }
        status={ status }
      />
    );
  }, [
    handleClaimedEndorsementRequest,
    handleReviewClaimedEndorsement,
    handleRevokedClaimedEndorsementRequest,
    userRole,
  ]);

  return useMemo(() => {
    if (userRole !== Roles.ADMINISTRATOR) {
      return (
        <div className='ody-dashboard'>
          <div className={
            clsx('ody-dashboard__section ody-dashboard__section--profile-overview', {
              'ody-dashboard__section--profile-overview-companies': userRole === Roles.COMPANY_AGENT,
            })
          }
          >
            <ProfileOverview
              companyId={ userCompanyId }
              companyJobSector={ companyJobSector }
              location={ userProfile?.location }
              onWorkAvailabilityChange={ onWorkAvailabilityChange }
              profile={ userProfile }
              role={ userRole }
              userId={ userId }
            />
          </div>
          {
            dashboardEndorsementsSection(
              claimedEndorsementRequests,
              EndorsementStatuses.CLAIMED,
            )
          }
          { renderedSearchForm }
          { renderedProfileCompletion }
          <DashboardContactShareSection
            handleOnDownloadProfileClick={ handleOnDownloadProfileClick }
            role={ userRole }
            userId={ userId }
          />
          {
            dashboardEndorsementsSection(
              createdEndorsementRequests,
              EndorsementStatuses.CREATED,
            )
          }
          { savedJobSeekersSection }
          { companiesEndorsersStatisticsSection }
          <div className='ody-dashboard__section'>
            <VideosAndGuidesSection />
          </div>
          { jobSeekersStatisticsSection }
          { pricingSection }
        </div>
      );
    }
    return (
      <AdminDashboard />
    );
  }, [
    claimedEndorsementRequests,
    companiesEndorsersStatisticsSection,
    companyJobSector,
    createdEndorsementRequests,
    dashboardEndorsementsSection,
    handleOnDownloadProfileClick,
    jobSeekersStatisticsSection,
    onWorkAvailabilityChange,
    pricingSection,
    renderedProfileCompletion,
    renderedSearchForm,
    savedJobSeekersSection,
    userCompanyId,
    userId,
    userProfile,
    userRole,
  ]);
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
