import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Confirm from 'components/common/confirm/confirm';
import MultiSelect from 'components/common/multi-select/multi-select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import { isNotEmpty } from 'utilities/chisels';
import communicationCooperationSkillsLevels from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import QuestionBox from './question-box/question-box';

import './endorsement-form.scss';

const EndorsementForm = memo((props) => {
  const { allHashtags, onSubmit, onCancel } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  const { control, formState: { errors }, handleSubmit, register, reset }
    = useFormWithSchemaBuilder((Joi) => {
      return Joi.object({
        communicationCooperationSkillsLevel: Joi.string().required().valid(...communicationCooperationSkillsLevels),
        criticalThinkingLevel: Joi.string().required().valid(...criticalThinkingLevels),
        employerFeedback: Joi.string().max(1024).required(),
        hashtags: Joi.array().max(3).items(
          Joi.object({
            label: Joi.string(),
            value: Joi.string().valid(...allHashtags.map((h) => {
              return h.id;
            })),
          }).required()
        ).required(),
        jobSeekerFeedbacks: Joi.array()
          .items(Joi.string().valid(...jobSeekerFeedbackLevels)).required(),
        motivationLevel: Joi.string().required().valid(...motivationalLevels),
        professionalCharacteristics: Joi.array()
          .items(Joi.string().valid(...professionalCharacteristicLevels)).required(),
        testimonial: Joi.string().max(1024).allow(null).allow(''),
      });
    });

  const questionsAndAnswers = useMemo(() => {
    return [
      {
        id: 'motivationLevel',
        questions: {
          primary: t('endorsement:form.motivation_level.questions.primary'),
          secondary: t('endorsement:form.motivation_level.questions.secondary'),
        },
        values: motivationalLevels,
      },
      {
        id: 'communicationCooperationSkillsLevel',
        questions: {
          primary: t('endorsement:form.communication_cooperation_skills_level.questions.primary'),
          secondary: t('endorsement:form.communication_cooperation_skills_level.questions.secondary'),
        },
        values: communicationCooperationSkillsLevels,
      },
      {
        id: 'criticalThinkingLevel',
        questions: {
          primary: t('endorsement:form.critical_thinking_level.questions.primary'),
          secondary: t('endorsement:form.critical_thinking_level.questions.secondary'),
        },
        values: criticalThinkingLevels,
      },
      {
        id: 'professionalCharacteristics',
        questions: {
          primary: t('endorsement:form.professional_characteristics.questions.primary'),
          secondary: t('endorsement:form.professional_characteristics.questions.secondary'),
        },
        values: professionalCharacteristicLevels,
      },
      {
        id: 'jobSeekerFeedbacks',
        questions: {
          primary: t('endorsement:form.job_seeker_feedbacks.questions.primary'),
        },
        values: jobSeekerFeedbackLevels,
      },
      {
        id: 'employerFeedback',
        questions: {
          primary: t('endorsement:form.employer_feedback.questions.primary'),
          secondary: t('endorsement:form.employer_feedback.questions.secondary'),
        },
      },
      {
        id: 'testimonial',
        questions: {
          primary: t('endorsement:form.testimonial.questions.primary'),
          secondary: t('endorsement:form.testimonial.questions.secondary'),
        },
      },
    ];
  }, [ t ]);

  const questionsAndAnswersSection = useCallback(() => {
    return questionsAndAnswers.map((questionsAndAnswersObject) => {
      return (
        <QuestionBox
          error={ errors?.[questionsAndAnswersObject.id] }
          key={ questionsAndAnswersObject.id }
          questionsAndAnswersObject={ questionsAndAnswersObject }
          register={ register }
        />
      );
    });
  }, [ errors, questionsAndAnswers, register ]);

  // hashtags multi select is disabled
  const hashtagsMultiSelectIsDisabled = useCallback((field) => {
    return 3 <= field.value?.length;
  }, []);

  // the hashtags multi select component options
  const hashtagsMultiSelectComponentOptions = useMemo(() => {
    return allHashtags?.map((hashtag) => {
      return { label: hashtag[selectedLanguage], value: hashtag.id };
    });
  }, [ allHashtags, selectedLanguage ]);

  // The hashtags multi select component
  const hashtagsMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        className={ errors?.hashtags ? 'error' : '' }
        disabled={ hashtagsMultiSelectIsDisabled(field) }
        options={ hashtagsMultiSelectComponentOptions }
        placeholder={ t('endorsement:form.hashtags_placeholder') }
      />
    );
  }, [ errors?.hashtags, hashtagsMultiSelectComponentOptions, hashtagsMultiSelectIsDisabled, t ]);

  // The hashtags to be submitted
  const hashtagsToBeSubmitted = useCallback((hashtags) => {
    return hashtags?.map((hashtag) => {
      return hashtag.value;
    });
  }, []);

  // The function that is invoked when submitting an endorsement
  const onSubmitEndorsement = useCallback((values) => {
    const valuesToSubmit = {
      ...values,
      hashtags: hashtagsToBeSubmitted(values?.hashtags),
    };
    onSubmit(valuesToSubmit);
  }, [ hashtagsToBeSubmitted, onSubmit ]);

  // The function that is invoked when canceling the endorsement form
  const handleCancel = useCallback(() => {
    reset();
    onCancel();
  }, [ onCancel, reset ]);

  // // The hashtags error
  const hashtagsError = useMemo(() => {
    if (errors?.hashtags === undefined) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('endorsement:form.hashtags_error') }
      </span>
    );
  }, [ errors?.hashtags, t ]);

  return (
    <form
      className='endorsement-form'
      noValidate
    >
      { questionsAndAnswersSection() }
      <div className='endorsement-form__hashtags-container'>
        <p className='txt txt-lg txt-bold'>
          { t('endorsement:form.hashtags_title') }
        </p>
        <p className='txt txt-md'>
          { t('endorsement:form.hashtags_subtitle') }
        </p>
        <div className='endorsement-form__hashtags-box'>
          <Controller
            control={ control }
            defaultValue={ [] }
            name='hashtags'
            render={ hashtagsMultiSelectComponent }
          />
          { hashtagsError }
        </div>
      </div>
      <div className='endorsement-form__actions'>
        <button
          className='btn btn-sm btn-rounded-sm btn-white'
          onClick={ handleCancel }
          type='reset'
        >
          { t('endorsement:form.cancel_button_label') }
        </button>
        <Confirm
          className='endorsement-form__confirmation'
          onConfirm={ handleSubmit(onSubmitEndorsement) }
          text={ t('endorsement:form.confirm_submit') }
          trigger={
            <button
              className='btn btn-sm btn-rounded-sm btn-blue'
              disabled={ isNotEmpty(errors) }
              type='button'
            >
              { t('endorsement:form.submit_button_label') }
            </button>
          }
        />
      </div>
    </form>
  );
});

EndorsementForm.displayName = 'EndorsementForm';

EndorsementForm.propTypes = {
  // All the hashtags
  allHashtags: PropTypes.arrayOf(
    // The hashtag
    PropTypes.shape({
      // greek translation of hashtag
      el: PropTypes.string,
      // english translation of hashtag
      en: PropTypes.string,
      // the id of the hashtag
      id: PropTypes.string,
    })
  ),
  // The function () => void that is invoked when canceling the endorsement
  onCancel: PropTypes.func,
  // The function (values) => void that is invoked when submitting the endorsement
  onSubmit: PropTypes.func,
};

EndorsementForm.defaultProps = {};

export default EndorsementForm;
