/**
 * English translations for the administrators.
 *
 * @module languages/en/administrators
 */
import dashboard from './dashboard';

const administrators = {
  dashboard,
};

export default administrators;
