/**
 * Resource method to get job seeker profiles.
 *
 * @module resources/job-seekers/get-profiles
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/get-profiles~Params
 * @type {object}
 * @property {module:types/job-seekers/searches~Criteria} criteria - The criteria.
 * @property {module:types/job-seekers/searches~Filters} [filters] - The filters.
 * @property {module:types/common~Page} page - The page to get.
 * @property {string} [searchId] - The ID of the search to further refine.
 * @property {module:types/common~Sort} sort - The way to sort.
 */

/**
 * Result.
 *
 * @typedef module:resources/job-seekers/get-profiles~Result
 * @type {object}
 * @property {module:types/job-seekers/searches~Facets} [facets] - The facets.
 * @property {module:types/common~Meta} meta - The pagination metadata.
 * @property {module:types/job-seekers/profiles~Profile[]} profiles - Some matching profiles.
 * @property {string} searchId - The ID of the search for further refinement.
 */

/**
 * Gets job seeker profiles based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/get-profiles~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/get-profiles~Result>} - The promise.
 * @static
 */
const getProfiles = (params) => {
  const searchParams = new URLSearchParams();
  if (0 < (params.criteria.cities || []).length) {
    searchParams.append('criteria.cities', params.criteria.cities);
  }
  if (0 < (params.criteria.jobSectors || []).length) {
    searchParams.append('criteria.jobSectors', params.criteria.jobSectors);
  }
  if (0 < (params.criteria.terms || []).length) {
    searchParams.append('criteria.terms', params.criteria.terms);
  }
  if (0 < (params.filters?.computerSkillsLevels || []).length) {
    searchParams.append('filters.computerSkillsLevels', params.filters?.computerSkillsLevels);
  }
  if (0 < (params.filters?.drivingLicenseCategories || []).length) {
    searchParams.append('filters.drivingLicenseCategories', params.filters?.drivingLicenseCategories);
  }
  if (undefined !== params.filters?.experienceLengthRange) {
    searchParams.append('filters.experienceLengthRange.end', params.filters?.experienceLengthRange.end);
    searchParams.append('filters.experienceLengthRange.start', params.filters?.experienceLengthRange.start);
  }
  if (0 < (params.filters?.languages || []).length) {
    searchParams.append('filters.languages', params.filters?.languages);
  }
  if (0 < (params.filters?.professions || []).length) {
    searchParams.append('filters.professions', params.filters?.professions);
  }
  if (undefined !== params.filters?.profileCompletionPercentageRange) {
    searchParams.append('filters.profileCompletionPercentageRange.end',
      params.filters?.profileCompletionPercentageRange.end);
    searchParams.append('filters.profileCompletionPercentageRange.start',
      params.filters?.profileCompletionPercentageRange.start);
  }
  if (0 < (params.filters?.qualificationTypes || []).length) {
    searchParams.append('filters.qualificationTypes', params.filters?.qualificationTypes);
  }
  if (0 < (params.filters?.relocationIntentions || []).length) {
    searchParams.append('filters.relocationIntentions', params.filters?.relocationIntentions);
  }
  if (0 < (params.filters?.workAvailabilities || []).length) {
    searchParams.append('filters.workAvailabilities', params.filters?.workAvailabilities);
  }
  searchParams.append('page.number', params.page.number);
  searchParams.append('page.size', params.page.size);
  if (undefined !== params.searchId) {
    searchParams.append('searchId', params.searchId);
  }
  searchParams.append('sort.direction', params.sort.direction);
  searchParams.append('sort.key', params.sort.key);
  return axios.request({
    method: 'get',
    params: searchParams,
    url: '/job-seekers/profiles',
  }).then((response) => {
    return response.data;
  });
};

export default getProfiles;
