/**
 * Filter types.
 *
 * @module utilities/companies/filter-types
 */

/**
 * Number of employees.
 *
 * @constant {string}
 * @static
 */
export const NUMBER_OF_EMPLOYEES = 'NUMBER_OF_EMPLOYEES';

/**
 * Profession.
 *
 * @constant {string}
 * @static
 */
export const PROFESSION = 'PROFESSION';

export default [
  NUMBER_OF_EMPLOYEES,
  PROFESSION,
];
