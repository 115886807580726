import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import EndorsementInformation from 'components/endorsement/endorsement-information/endorsement-information';
import ProfileEndorsementCard from 'components/endorsers/profile-endorsement-card/profile-endorsement-card';
import { takeFirstN } from 'utilities/chisels';
import allCities from 'utilities/cities';
import communicationCooperationSkillsLevels
  from 'utilities/endorsement/communication-cooperation-skills-levels';
import criticalThinkingLevels from 'utilities/endorsement/critical-thinking-levels';
import jobSeekerFeedbackLevels from 'utilities/endorsement/job-seeker-feedback-levels';
import motivationalLevels from 'utilities/endorsement/motivational-levels';
import professionalCharacteristicLevels from 'utilities/endorsement/professional-characteristic-levels';

import './profile-completed-endorsements.scss';

const ProfileCompletedEndorsements = memo((props) => {
  const { activeRequest, endorsements, allHashtags, profile } = props;

  const { t } = useTranslation();

  const [ viewAll, setViewAll ] = useState(false);

  // The endorsement index.
  const [ endorsementIndex, setEndorsementIndex ] = useState(false);

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  // Toggles the editor open
  const toggleEditor = useCallback((open) => {
    return (_event) => {
      setEditorOpen(open);
    };
  }, []);

  // The completed endorsements to render
  const completedEndorsementsToRender = useMemo(() => {
    return viewAll ? endorsements : takeFirstN(endorsements, 4);
  }, [ endorsements, viewAll ]);

  // function that is invoked when clicking view all button
  const handleViewAllClick = useCallback(() => {
    setViewAll(!viewAll);
  }, [ viewAll ]);

  // The function to be invoked when the view review button is clicked
  const onViewReviewButtonClick = useCallback((endorsementIndex) => {
    setEndorsementIndex(endorsementIndex);
    setEditorOpen(true);
  }, []);

  // The rendered cards
  const renderedCards = useMemo(() => {
    return completedEndorsementsToRender?.map((completedEndorsement, endorsementIndex) => {
      return (
        <ProfileEndorsementCard
          endorsement={ completedEndorsement }
          endorsementIndex={ endorsementIndex }
          key={ completedEndorsement.id }
          onViewReviewButtonClick={ onViewReviewButtonClick }
        />
      );
    });
  }, [ completedEndorsementsToRender, onViewReviewButtonClick ]);

  // The rendered completed endorsements cards
  const renderedCompletedEndorsementsCards = useMemo(() => {
    if (!completedEndorsementsToRender?.length && !activeRequest) {
      return (
        <div className='profile-completed-endorsements__no-completed-endorsements'>
          <span className='hdg hdg-sm'>
            { t('endorsers:profile_completed_endorsements.no_completed_endorsements') }
          </span>
        </div>
      );
    }
    return (
      <div className='profile-completed-endorsements__cards'>
        { renderedCards }
      </div>
    );
  }, [ activeRequest, completedEndorsementsToRender?.length, renderedCards, t ]);

  // function that sets the endorsement to popup
  const setEndorsementToPopup = useCallback((endorsement) => {
    return {
      ...endorsement,
      endorserProfile: profile,
    };
  }, [ profile ]);

  // view all button title
  const viewAllButtonTitle = useMemo(() => {
    if (viewAll) {
      return t('endorsers:profile_completed_endorsements.view_less_button_title');
    }
    return t('endorsers:profile_completed_endorsements.view_all_button_title');
  }, [ t, viewAll ]);

  return (
    <div className='profile-completed-endorsements'>
      <div className='profile-completed-endorsements__title-section'>
        <h2 className='hdg hdg-md'>
          { t('endorsers:profile_completed_endorsements.title') }
        </h2>
        <button
          className='btn btn-sm btn-rounded-sm btn-blue profile-completed-endorsements__view-all-button'
          disabled={ !endorsements?.length }
          onClick={ handleViewAllClick }
        >
          { viewAllButtonTitle }
        </button>
      </div>
      { renderedCompletedEndorsementsCards }
      <Modal
        className='endorsement-information-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <EndorsementInformation
          allHashtags={ allHashtags }
          endorsement={ setEndorsementToPopup(endorsements?.[endorsementIndex]) }
        />
      </Modal>
    </div>
  );
});

ProfileCompletedEndorsements.displayName = 'ProfileCompletedEndorsements';

ProfileCompletedEndorsements.propTypes = {
  // whether the request is active
  activeRequest: PropTypes.bool,
  // All the hashtags available
  allHashtags: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of hashtag
      el: PropTypes.string,
      // english translation of hashtag
      en: PropTypes.string,
      // the id of the hashtag
      id: PropTypes.string,
    })
  ),
  // The completed endorsements
  endorsements: PropTypes.arrayOf(
    PropTypes.shape({
      // The communication and cooperation skills level score
      communicationCooperationScore: PropTypes.number,
      // The communication and cooperation skills level
      communicationCooperationSkillsLevel: PropTypes.oneOf(communicationCooperationSkillsLevels),
      // The critical thinking level
      criticalThinkingLevel: PropTypes.oneOf(criticalThinkingLevels),
      // The critical thinking score
      criticalThinkingScore: PropTypes.number,
      // The employer feedback
      employerFeedback: PropTypes.string,
      // The endorsee profile
      endorsee: PropTypes.shape({
        // The id of the endorsee
        _id: PropTypes.string,
        // The first name of the endorsee
        firstName: PropTypes.string,
        // The last name of the endorsee
        lastName: PropTypes.string,
        // The location where the endorsee is.
        location: PropTypes.shape({
          // The city.
          city: PropTypes.oneOf(allCities),
          // The district.
          district: PropTypes.string,
        }),
        // The profile picture of the endorsee.
        profilePicture: PropTypes.shape({
          // The ID of the picture.
          id: PropTypes.string,
          // The MIME type of the picture.
          type: PropTypes.string,
        }),
      }),
      // The endorser id
      endorser: PropTypes.string,
      // The hashtags UUID array
      hashtags: PropTypes.arrayOf(PropTypes.string),
      // The id of the endorsement
      id: PropTypes.string,
      // The job seeker feedbacks array
      jobSeekerFeedbacks: PropTypes.arrayOf(PropTypes.oneOf(jobSeekerFeedbackLevels)),
      // The motivation level
      motivationLevel: PropTypes.oneOf(motivationalLevels),
      // The motivation score
      motivationScore: PropTypes.number,
      // The professional characteristics array
      professionalCharacteristics: PropTypes.arrayOf(PropTypes.oneOf(professionalCharacteristicLevels)),
      // The profesionalism score
      professionalismScore: PropTypes.number,
      // The request id
      requestId: PropTypes.string,
      // The testimonial
      testimonial: PropTypes.string,
    }),
  ),
  // The profile of the endorser
  profile: PropTypes.shape({
    // The company name
    companyName: PropTypes.string,
    // The company position
    companyPosition: PropTypes.string,
    // The first name
    firstName: PropTypes.string,
    // The last name
    lastName: PropTypes.string,
  }),
};

ProfileCompletedEndorsements.defaultProps = {};

export default ProfileCompletedEndorsements;
