/**
 * Greek translations for common/profile-job-sectors-interested-in.
 *
 * @module languages/el/common/profile-job-sectors-interested-in
 */
const profileJobSectorsInterestedIn = {
  editor_title: 'Επαγγελματικοί κλάδοι',
  job_sectors_placeholder: 'Επιλέξτε τους κλάδους εργασίας που σας ενδιαφέρουν',
  title: 'Επαγγελματικοί κλάδοι ενδιαφέροντος',
};

export default profileJobSectorsInterestedIn;
