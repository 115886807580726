/**
 * Greek translations for social-responsibility.
 *
 * @module languages/el/social-responsibility
 */
const socialResponsibility = {
  companies_collection_section: {
    title: 'Συνεργαζόμενες εταιρίες',
  },
  csr_packages_section: {
    failed_email: 'Αποτυχία αποστολής email στον διαχειριστή. Παρακαλούμε προσπαθήστε ξανά',
    package_button_label: 'Επικοινωνήστε μαζί μας',
    packages: {
      item1: {
        description: {
          item1: 'Υποστηρίξτε την εκπαίδευση 15 ευάλωτων νέων',
          item2: 'Εκπαίδευση προσαρμοσμένη στις ανάγκες σας και στις ανάγκες της αγοράς εργασίας',
          item3: 'Υποστήριξη στην εύρεση εργασίας και μετά την πρόσληψη',
        },
        title: 'Tailor Made εκπαίδευση',
      },
      item2: {
        description: {
          item1: 'Υποστηρίξτε την εύρεση εργασίας 15 ευάλωτων νέων',
          item2: 'Συμβουλευτική εργασίας, soft skills εκπαίδευση και προετοιμασία για συνέντευξη',
          item3: 'Υποστήριξη στην εύρεση εργασίας και μετά την πρόσληψη',
        },
        title: 'Υποστήριξη στην εύρεση εργασίας',
      },
      item3: {
        description: {
          item1: 'Παροχή 200 γευμάτων σε άτομα που έχουν ανάγκη σε Πειραιά και Αθήνα',
          item2: 'Στηρίξτε 10 οικογένειες που έχουν ανάγκη με μηνιαία κουτιά',
          item3: 'Συμμετέχετε με εθελοντές από την εταιρείας σας στην κοινωνική μας κουζίνα',
        },
        title: 'Δράσεις κοινωνικής στήριξης',
      },
    },
    // eslint-disable-next-line max-len
    pricing_interest_different_role_error: 'Πρέπει να είστε αντιπρόσωπος εταιρείας για να εκδηλώσετε ενδιαφέρον για τα πακέτα μας',
    success_email: 'Έχει σταλεί email στον διαχειριστή',
    title: 'CSR Πακέτα ',
  },
  first_section: {
    // eslint-disable-next-line max-len
    description: 'Υποστηρίξτε ευάλωτο πληθυσμό μέσω δραστηριοτήτων CSR, επαγγελματικής κατάρτισης και ευκαιριών απασχόλησης.\n \nΑυξήστε τον κοινωνικό αντίκτυπο της εταιρείας σας και μοιραστείτε τον με το ευρύτερο κοινό σε συνεργασία με την Odyssea.',
    title: 'Κοινωνική Ευθύνη με τον εύκολο τρόπο',
  },
  grow_social_impact_section: {
    // eslint-disable-next-line max-len
    info: 'Ενισχύστε τους στόχους σας για τα SDG αξιοποιώντας την τεχνογνωσία μας σχετικά με την εκπαίδευση, την αξιοπρεπή απασχόληση ή τη διαφορετικότητα και την ένταξη στον χώρο εργασίας.\n Ας εργαστούμε μαζί για να κλιμακώσουμε τον κοινωνικό αντίκτυπο.',
    title: 'Αυξήστε τον κοινωνικό σας αντίκτυπο',
  },
  impact_stories_section: {
    stories: {
      item1: {
        item1: {
          age_location: 'Άγιος. Ι. Ρέντης',
          name: 'Odyssea Academy',
          title: 'Κάλως ήρθατε στην Odyssea Academy',
          video_url: 'https://www.youtube.com/embed/qZtce2c9i0M',
        },
        item2: {
          age_location: 'Λιμάνι του Πειραιά',
          name: 'Κοινωνική κουζίνα',
          title: 'Προσφέροντας γεύματα μαζί με τη CVC',
          video_url: 'https://www.youtube.com/embed/USGQBDVK0Ck',
        },
        item3: {
          // eslint-disable-next-line max-len
          description: 'Λαμβάνοντας υπόψη τις τρέχουσες κοινωνικές και οικονομικές ανάγκες της αγοράς, το πρόγραμμα «SKILLS 4 ALL» σχεδιάστηκε για να υποστηρίξει και να εκπαιδεύσει επαγγελματικά 100 νέους και ηλικίας 17-34 ετών από ευάλωτες κοινωνικές ομάδες. Για περισσότερες πληροφορίες σχετικά με τη δράση και τον τρόπο εγγραφής, επισκεφτείτε τη σελίδα του προγράμματος.',
          name: 'SKILLS 4 ALL',
          title: 'Η Τράπεζα Πειραιώς, σε συνεργασία με την Odyssea, υλοποιεί το πρόγραμμα «SKILLS 4 ALL».',
        },
      },
    },
    title: 'Impact stories',
  },
  sdg_section: {
    link_label: 'Μάθετε περισσότερα',
    title: 'Ας εργαστούμε μαζί στα ακόλουθα SDGs',
  },
};

export default socialResponsibility;
