/**
 * Greek translations for common/search-sort.
 *
 * @module languages/el/common/search-sort
 */
const searchSort = {
  sort_by_button_label: 'Ταξινόμηση κατά',
};

export default searchSort;
