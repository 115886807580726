/**
 * Footer.
 */
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import facebook from './images/facebook.png';
import instagram from './images/instagram.png';
import linkedin from './images/linkedin.png';
import twitter from './images/twitter.png';
import youtube from './images/youtube.png';

import './footer.scss';

const Footer = memo((_props) => {
  const location = useLocation();

  const { t } = useTranslation();

  // To hide or not to hide the footer.
  const hidden = useMemo(() => {
    return -1 !== [
      '/companies/sign-up',
      '/endorsers/sign-up',
      '/forgot-password',
      '/job-seekers/sign-up',
      '/reset-password',
      '/sign-in',
    ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // whether the footer will be blue
  const isBlue = useMemo(() => {
    return -1 !== [ '/home' ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // whether the footer will be dark blue
  const isDarkBlue = useMemo(() => {
    return -1 !== [ '/about-us' ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // whether the footer will be yellow
  const isYellow = useMemo(() => {
    return -1 !== [
      '/job-seekers/learn-more',
      '/job-seekers/take-assessment',
    ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // whether the footer will be red
  const isRed = useMemo(() => {
    return -1 !== [
      '/companies/learn-more',
      '/social-responsibility',
    ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // whether the footer will be purple
  const isPurple = useMemo(() => {
    return -1 !== [ '/endorsers/learn-more' ].indexOf(location.pathname);
  }, [ location.pathname ]);

  // Whether the links have a black underline
  const isLinkBlackUnderlined = useMemo(() => {
    return isBlue || isDarkBlue || isYellow || isRed || isPurple;
  }, [ isBlue, isDarkBlue, isPurple, isRed, isYellow ]);

  return useMemo(() => {
    if (hidden) {
      return null;
    }
    return (
      <footer
        className={
          clsx('ody-footer', {
            'ody-footer__blue': isBlue,
            'ody-footer__dark-blue': isDarkBlue,
            'ody-footer__purple': isPurple,
            'ody-footer__red': isRed,
            'ody-footer__yellow': isYellow,
          })
        }
      >
        <div className='brand'>odyssea</div>
        <div className='contact'>
          <a
            className={
              clsx('link', {
                'link__black': isLinkBlackUnderlined,
              })
            }
            href='mailto:info@odyssea.com'
          >
            info@odyssea.com
          </a>
          <a
            className={
              clsx('link', {
                'link__black': isLinkBlackUnderlined,
              })
            }
            href='tel:+302104815780'
          >
            (+30) 210-4815780
          </a>
        </div>
        <div className='social'>
          <a href='https://www.facebook.com/OdysseaGreece' rel='noopener noreferrer' target='_blank'>
            <img alt='Facebook' className='icon' src={ facebook } />
          </a>
          <a href='https://twitter.com/odyssea_athens' rel='noopener noreferrer' target='_blank'>
            <img alt='Twitter' className='icon' src={ twitter } />
          </a>
          <a href='https://www.instagram.com/odyssea_athens' rel='noopener noreferrer' target='_blank'>
            <img alt='Instagram' className='icon' src={ instagram } />
          </a>
          <a href='https://www.linkedin.com/company/odyssea-ngo' rel='noopener noreferrer' target='_blank'>
            <img alt='Linkedin' className='icon' src={ linkedin } />
          </a>
          <a href='https://www.youtube.com/channel/UCY6HhnaYObCj9Yw8ekF_3oQ' rel='noopener noreferrer' target='_blank'>
            <img alt='Youtube' className='icon' src={ youtube } />
          </a>
        </div>
        <div className='links'>
          <Link
            className={
              clsx('link', {
                'link__black': isLinkBlackUnderlined,
              })
            }
            to='/about-us'
          >
            { t('footer:about_us_link_text') }
          </Link>
          <Link
            className={
              clsx('link', {
                'link__black': isLinkBlackUnderlined,
              })
            }
            to='/privacy-policy'
          >
            { t('footer:privacy_policy_link_text') }
          </Link>
          <Link
            className={
              clsx('link', {
                'link__black': isLinkBlackUnderlined,
              })
            }
            to='/terms-of-service'
          >
            { t('footer:terms_of_service_link_text') }
          </Link>
        </div>
      </footer>
    );
  }, [ hidden, isBlue, isDarkBlue, isLinkBlackUnderlined, isPurple, isRed, isYellow, t ]);
});

Footer.displayName = 'Footer';

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default Footer;
