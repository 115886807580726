import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ContactButton from 'components/common/contact-button/contact-button';
import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import ShareProfileButton from 'components/job-seekers/share-profile-button/share-profile-button';
import DownloadImage from 'images/download-purple.png';
import roles, * as Roles from 'utilities/auth/roles';

import './dashboard-contact-share-section.scss';

const DashboardContactShareSection = memo((props) => {
  const { role, userId, handleOnDownloadProfileClick } = props;

  const { t } = useTranslation();

  // The title
  const renderedTitle = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('common:dashboard_contact_share_section.share_title');
    case Roles.COMPANY_AGENT:
      return t('common:dashboard_contact_share_section.contact_odyssea_title_companies');
    case Roles.ENDORSER:
      return t('common:dashboard_contact_share_section.contact_odyssea_title_endorsers');
    default:
      return '';
    }
  }, [ role, t ]);

  // The rendered contact button
  const renderedContactShareButton = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return (
        <ShareProfileButton
          className='dashboard-contact__button dashboard-contact__button--purple btn-sm btn-rounded-sm'
          jobSeekerId={ userId }
          variant='purple'
        />
      );
    }
    return (
      <ContactButton
        className={
          clsx('dashboard-contact__button btn btn-sm btn-rounded-sm', {
            'dashboard-contact__button--purple': role === Roles.ENDORSER,
          })
        }
      />
    );
  }, [ role, userId ]);

  // The rendered download profile or endorsement guide button
  const downloadProfileEndorsementGuide = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return null;
    }
    if (role === Roles.JOB_SEEKER) {
      return (
        <button
          className='dashboard-contact__button btn btn-sm btn-rounded-sm dashboard-contact__button--download-profile'
          onClick={ handleOnDownloadProfileClick }
          type='button'
        >
          <img
            alt='download icon'
            className='ody-dashboard__share-profile-button-image'
            src={ DownloadImage }
          />
          { t('common:dashboard_contact_share_section.download_profile') }
        </button>
      );
    }
    return (
      <button
        className='dashboard-contact__button btn btn-sm btn-rounded-sm dashboard-contact__button--download-profile'
        type='button'
      >
        { t('common:dashboard_contact_share_section.endorsement_guide') }
      </button>
    );
  }, [ handleOnDownloadProfileClick, role, t ]);

  return (
    <div className='dashboard-contact light'>
      <div className={
        clsx('dashboard-contact__section dashboard-contact__section--left', {
          'dashboard-contact__section--left-purple': role === Roles.JOB_SEEKER || role === Roles.ENDORSER,
        })
      }
      >
        <h2 className='dashboard-contact__text hdg hdg-md'>
          { renderedTitle }
        </h2>
        <div className='dashboard-contact__buttons'>
          { renderedContactShareButton }
          { downloadProfileEndorsementGuide }
        </div>
      </div>
      <div className={
        clsx('dashboard-contact__section dashboard-contact__section--right', {
          'dashboard-contact__section--right-job-seekers': role === Roles.JOB_SEEKER,
          'dashboard-contact__section--right-purple': role === Roles.JOB_SEEKER || role === Roles.ENDORSER,
        })
      }
      >
        <OdysseaLogo />
      </div>
    </div>
  );
});

DashboardContactShareSection.displayName = 'DashboardContactShareSection';

DashboardContactShareSection.propTypes = {
  // The function that handles the download profile () => void
  handleOnDownloadProfileClick: PropTypes.func,
  // the signed in user role
  role: PropTypes.oneOf(roles),
  // The signed in user id
  userId: PropTypes.string.isRequired,
};

DashboardContactShareSection.defaultProps = {};

export default DashboardContactShareSection;
