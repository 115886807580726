/**
 * Settings page.
 */
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import Select from 'components/common/select/select';
import { isEmpty } from 'utilities/chisels';

import Account from './account/account';
import Preferences from './preferences/preferences';
import './settings.scss';

const Settings = (_props) => {
  const { hash } = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  // The sections.
  const sections = [ 'account', 'preferences' ].map((id) => {
    return {
      hash: `#${ id }`,
      title: t(`settings:${ id }:title`),
    };
  });

  return (
    <div className='ody-settings'>
      <div className='index-list-and-selected'>
        <div className='section-index hidden-xs hidden-sm'>
          {
            sections.map((section, index) => {
              return (
                <Link
                  className={ clsx('link', hash === section.hash && 'active') }
                  key={ index }
                  to={
                    {
                      hash: section.hash,
                      pathname: '/settings',
                    }
                  }
                >
                  { section.title }
                </Link>
              );
            })
          }
        </div>
        <div className='section-list hidden-md hidden-lg'>
          <Select
            clearable={ false }
            onChange={
              (value) => {
                navigate({
                  hash: value?.value || '#account',
                  pathname: '/settings',
                });
              }
            }
            options={
              sections.map((s) => {
                return {
                  label: s.title,
                  value: s.hash,
                };
              })
            }
            value={
              isEmpty(hash) ? null : sections.filter((s) => {
                return hash === s.hash;
              }).map((s) => {
                return {
                  label: s.title,
                  value: s.hash,
                };
              }).find((e) => {
                return !!e;
              }) || null
            }
          />
        </div>
        {
          isEmpty(hash) && (
            <Navigate
              replace
              to={
                {
                  hash: '#account',
                  pathname: '/settings',
                }
              }
            />
          )
        }
        {
          '#account' === hash && (
            <Account />
          )
        }
        {
          '#preferences' === hash && (
            <Preferences />
          )
        }
      </div>
    </div>
  );
};

Settings.propTypes = {
};

Settings.defaultProps = {
};

export default Settings;
