/**
 * English translations for the companies.
 *
 * @module languages/en/companies
 */
import learnMore from './learn-more';
import profilePricingSection from './profile-pricing-section';
import steps from './steps';

const companies = {
  learn_more: learnMore,
  profile_pricing_section: profilePricingSection,
  steps,
};

export default companies;
