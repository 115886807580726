/**
 * English translations for common/contact-button.
 *
 * @module languages/en/common/contact-button
 */
const contactButton = {
  description: 'For any request, drop us an email or call us!',
  email: 'talent@odyssea.com',
  label: 'Contact Odyssea',
  phoneNumber1: '+30 210 8839877',
  phoneNumber2: '+30 210 4815780',
  title: 'Contact details',
};

export default contactButton;
