import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MultiSelect from 'components/common/multi-select/multi-select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import { areNotEqual } from 'utilities/chisels';

const ProfileJobSectorsInterestedEditor = memo((props) => {
  const { allJobSectors, profile, onSave, onCancel } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // all the job sectors to be validated
  const allJobSectorsToBeValidated = useMemo(() => {
    return allJobSectors.map((jobSector) => {
      return jobSector.id;
    });
  }, [ allJobSectors ]);

  const { control, handleSubmit } = useFormWithSchemaBuilder((Joi) => {
    return Joi.object({
      jobSectors: Joi.array().max(16).items(Joi.object({
        label: Joi.string(),
        value: Joi.string().valid(...allJobSectorsToBeValidated),
      })),
    });
  });

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    return {
      jobSectors: profile?.jobSectors,
    };
  }, [ profile?.jobSectors ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that constructs the job sectors to be updated
  const constructJobSectorsTobeUpdated = useCallback((jobSectors) => {
    return jobSectors.map((jobSector) => {
      return jobSector.value;
    });
  }, []);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      jobSectors: constructJobSectorsTobeUpdated(values.jobSectors),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ constructJobSectorsTobeUpdated, onSave, valuesAreToBeUpdated ]);

  // Whether the multi select is disabled
  const multiSelectDisabled = useCallback((field) => {
    return 16 <= field.value?.length;
  }, []);

  // The job sectors options for the multi select component
  const jobSectorsOptions = useMemo(() => {
    return allJobSectors.map((jobSector) => {
      return { label: jobSector[selectedLanguage], value: jobSector.id };
    });
  }, [ allJobSectors, selectedLanguage ]);

  // The rendered multi select component
  const renderedMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ multiSelectDisabled(field) }
        options={ jobSectorsOptions }
        placeholder={ t('common:profile_job_sectors_interested_in.job_sectors_placeholder') }
      />
    );
  }, [ jobSectorsOptions, multiSelectDisabled, t ]);

  // The job sectors field default value
  const jobSectorsFieldDefaultValue = useMemo(() => {
    if (!profile?.jobSectors?.length) {
      return [];
    }
    return allJobSectors.filter((jobSector) => {
      return -1 !== profile.jobSectors.indexOf(jobSector.id);
    }).map((jobSector) => {
      return { label: jobSector[selectedLanguage], value: jobSector.id };
    });
  }, [ allJobSectors, profile.jobSectors, selectedLanguage ]);

  // The job Sectors field
  const jobSectorsField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          <Controller
            control={ control }
            defaultValue={ jobSectorsFieldDefaultValue }
            name='jobSectors'
            render={ renderedMultiSelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, jobSectorsFieldDefaultValue, renderedMultiSelectComponent ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('common:profile_job_sectors_interested_in.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { jobSectorsField }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileJobSectorsInterestedEditor.displayName = 'ProfileJobSectorsInterestedEditor';

ProfileJobSectorsInterestedEditor.propTypes = {
  // The all job sectors
  allJobSectors: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the job sector
      el: PropTypes.string,
      // The english translation of the job sector
      en: PropTypes.string,
      // The id of the job sector
      id: PropTypes.string,
    })
  ).isRequired,
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the user.
  profile: PropTypes.shape({
    // The IDs of the job sectors that the user is interested in.
    jobSectors: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProfileJobSectorsInterestedEditor.defaultProps = {};

export default ProfileJobSectorsInterestedEditor;
