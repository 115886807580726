/**
 * Greek translations for see-example-profile-button.
 *
 * @module languages/el/see-example-profile-button
 */
const seeExampleProfileButton = {
  see_example_profile_button_label: 'Δείτε ένα παράδειγμα προφίλ',
};

export default seeExampleProfileButton;
