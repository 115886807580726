/**
 * Greek translations for common/search-form.
 *
 * @module languages/el/common/search-form
 */
const searchForm = {
  at_least_one_criterion_required_error: 'Προσδιορίστε τουλάχιστον ένα κριτήριο.',
  companies_form_title: 'Κατάλογος εταιρειών',
  company_dashboard_title: 'Αναζήτηση ταλέντων',
  job_seekers_form_title: 'Λίστα ατόμων που αναζητούν εργασία',
  location_city_placeholder: 'Τοποθεσία/Χώρα',
  search_button_label: 'Αναζήτηση',
  search_placeholder: 'Αναζήτηση',
};

export default searchForm;
