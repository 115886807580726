/**
 * Company Profile parts.
 *
 * @module utilities/companies/parts
 */

/**
 * About.
 *
 * @constant {string}
 * @static
 */
export const ABOUT = 'ABOUT';

/**
 * Background image.
 *
 * @constant {string}
 * @static
 */
export const BACKGROUND_IMAGE = 'BACKGROUND_IMAGE';

/**
 * Contact.
 *
 * @constant {string}
 * @static
 */
export const CONTACT = 'CONTACT';

/**
 * Details.
 *
 * @constant {string}
 * @static
 */
export const DETAILS = 'DETAILS';

/**
 * Job sectors.
 *
 * @constant {string}
 * @static
 */
export const JOB_SECTORS = 'JOB_SECTORS';

/**
 * Logo.
 *
 * @constant {string}
 * @static
 */
export const LOGO = 'LOGO';

/**
 * ProfilePricingSection.
 *
 * @constant {string}
 * @static
 */
export const PRICING = 'PRICING';

/**
 * Professions.
 *
 * @constant {string}
 * @static
 */
export const PROFESSIONS = 'PROFESSIONS';

/**
 * Job Positions.
 *
 * @constant {string}
 * @static
 */
export const JOB_POSITIONS = 'JOB_POSITIONS';

/**
 * Video.
 *
 * @constant {string}
 * @static
 */
export const VIDEO = 'VIDEO';

/**
 * Description.
 *
 * @constant {string}
 * @static
 */
export const DESCRIPTION = 'DESCRIPTION';

/**
 * Saved job seekers.
 *
 * @constant {string}
 * @static
 */
export const SAVED_JOB_SEEKERS = 'SAVED_JOB_SEEKERS';

export default [
  ABOUT,
  BACKGROUND_IMAGE,
  CONTACT,
  DESCRIPTION,
  DETAILS,
  JOB_SECTORS,
  LOGO,
  PRICING,
  PROFESSIONS,
  SAVED_JOB_SEEKERS,
  JOB_POSITIONS,
  VIDEO,
];
