import PropTypes from 'prop-types';
import React, { memo } from 'react';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';

import AboutUsKeyMetricsSection from './about-us-key-metrics-section/about-us-key-metrics-section';

import './about-us-welcome-section.scss';

const AboutUsWelcomeSection = memo((props) => {
  const { title, description, buttonLabel, keyMetricsTitle, keyMetrics } = props;

  return (
    <div className='about-us-welcome-section'>
      <div className='about-us-welcome-section__content-section'>
        <div className='about-us-welcome-section__logo-link-container'>
          <OdysseaLogo />
          <a
            className='btn btn-sm btn-rounded-sm btn-white about-us-welcome-section__link'
            href='https://odyssea.com'
            rel='noopener noreferrer'
            target='_blank'
          >
            { buttonLabel }
          </a>
        </div>
        <div className='about-us-welcome-section__info-container'>
          <h4 className='hdg hdg-lg about-us-welcome-section__title'>
            { title }
          </h4>
          <p className='txt txt-lg txt-bold about-us-welcome-section__description'>
            { description }
          </p>
        </div>
      </div>
      <AboutUsKeyMetricsSection
        keyMetrics={ keyMetrics }
        keyMetricsTitle={ keyMetricsTitle }
      />
    </div>
  );
});

AboutUsWelcomeSection.displayName = 'AboutUsWelcomeSection';

AboutUsWelcomeSection.propTypes = {
  // The button label of the section
  buttonLabel: PropTypes.string.isRequired,
  // The description of the section
  description: PropTypes.string.isRequired,
  // The key metrics collection
  keyMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      // the key metric label
      label: PropTypes.string,
      // the key metric number
      number: PropTypes.string,
    })
  ).isRequired,
  // The key metrics title
  keyMetricsTitle: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
};

AboutUsWelcomeSection.defaultProps = {};

export default AboutUsWelcomeSection;
