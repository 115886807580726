import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from 'components/common/date-picker/date-picker';
import MultiSelect from 'components/common/multi-select/multi-select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import { areNotEqual, isNotEmpty } from 'utilities/chisels';

const ProfileExperienceEditor = memo((props) => {
  const { profile, onSave, onCancel, allSkills, experienceIndex } = props;

  const experience = useMemo(() => {
    return profile?.experience?.[experienceIndex];
  }, [ profile?.experience, experienceIndex ]);

  const { i18n, t } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  const { control, formState: { errors }, handleSubmit, register, watch, setValue }
    = useFormWithSchemaBuilder((Joi) => {
      return Joi.object({
        company: Joi.string().min(1).max(64).required(),
        current: Joi.boolean().required(),
        description: Joi.string().max(512).allow(null).allow(''),
        endedIn: Joi.alternatives().conditional('current', {
          is: true,
          otherwise: Joi.date().max('now').required(),
          then: Joi.date().valid(null),
        }),
        skills: Joi.array().max(16).items(Joi.object({
          label: Joi.string(),
          value: Joi.string().valid(...allSkills.map((s) => {
            return s.id;
          })),
        })),
        startedIn: Joi.alternatives().conditional('current', {
          is: true,
          otherwise: Joi.date().max(Joi.ref('endedIn')).required(),
          then: Joi.date().max('now').required(),
        }),
        title: Joi.string().min(1).max(64).required(),
      });
    });

  // Whether this is the current position of the job seeker.
  const current = watch('current');

  // Clear the end date if this is not the current position of the job seeker.
  useEffect(() => {
    if (true === current) {
      setValue('endedIn', null);
    }
  }, [ current, setValue ]);

  const comparator = useCallback((p1, p2) => {
    const si1 = DateTime.fromISO(p1.startedIn);
    const si2 = DateTime.fromISO(p2.startedIn);
    if (si1 > si2) {
      return -1;
    }
    if (si1 < si2) {
      return 1;
    }
    const ei1 = undefined === p1.endedIn ? undefined : DateTime.fromISO(p1.endedIn);
    const ei2 = undefined === p2.endedIn ? undefined : DateTime.fromISO(p2.endedIn);
    const c1 = p1.company;
    const c2 = p2.company;
    if (undefined !== ei1 && undefined !== ei2) {
      if (ei1 < ei2) {
        return -1;
      }
      if (ei1 > ei2) {
        return 1;
      }
      return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
    }
    if (undefined !== ei1) {
      return -1;
    }
    if (undefined !== ei2) {
      return 1;
    }
    return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
  }, []);

  // The params from profile
  const paramsFromProfile = useMemo(() => {
    return {
      experience: profile?.experience?.map((experience) => {
        return {
          company: experience?.company || '',
          current: experience?.current || false,
          description: experience?.description || '',
          endedIn: experience?.endedIn,
          skills: experience?.skills || [],
          startedIn: experience?.startedIn,
          title: experience?.title || '',
        };
      }),
    };
  }, [ profile?.experience ]);

  // The endedIn field to be updated
  const endedInFieldToBeUpdated = useCallback((endedIn, current) => {
    if (null === endedIn || true === current) {
      return undefined;
    }
    return DateTime.fromJSDate(endedIn).toISODate();
  }, []);

  // The started in field to be updated
  const startedInFieldToBeUpdated = useCallback((startedIn) => {
    if (null === startedIn) {
      return undefined;
    }
    return DateTime.fromJSDate(startedIn).toISODate();
  }, []);

  // the skills' field to be updated
  const skillsFieldToBeUpdated = useCallback((skills) => {
    return skills?.map((skill) => {
      return skill.value;
    });
  }, []);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const experience = {
      ...values,
      endedIn: endedInFieldToBeUpdated(values?.endedIn, values?.current),
      skills: skillsFieldToBeUpdated(values?.skills),
      startedIn: startedInFieldToBeUpdated(values?.startedIn),
    };
    const experiences = [
      ...profile.experience.slice(0, experienceIndex),
      experience,
      ...profile.experience.slice(experienceIndex + 1),
    ];
    experiences.sort(comparator);
    const paramsToBeUpdated = {
      experience: experiences,
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [
    comparator,
    endedInFieldToBeUpdated,
    experienceIndex,
    onSave,
    profile.experience,
    skillsFieldToBeUpdated,
    startedInFieldToBeUpdated,
    valuesAreToBeUpdated,
  ]);

  // function that handles when delete button is clicked
  const onDelete = useCallback(() => {
    const experiences = [
      ...profile.experience.slice(0, experienceIndex),
      ...profile.experience.slice(experienceIndex + 1),
    ];
    experiences.sort(comparator);
    const paramsToBeUpdated = {
      experience: experiences,
    };
    onSave(paramsToBeUpdated, true);
  }, [ comparator, experienceIndex, onSave, profile.experience ]);

  // The company field empty string error
  const companyFieldEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.company?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_experiences.company_required_error') }
      </span>
    );
  }, [ errors.company?.type, t ]);

  // the company field
  const companyField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.company_label') }
          <input
            className={
              clsx({
                'error': errors?.company,
              })
            }
            defaultValue={ experience?.company || '' }
            maxLength={ 128 }
            placeholder={ t('job_seekers:profile_experiences.company_placeholder') }
            type='text'
            { ...register('company') }
          />
        </label>
        { companyFieldEmptyStringError }
      </div>
    );
  }, [ companyFieldEmptyStringError, errors?.company, experience?.company, register, t ]);

  // title field empty string error
  const titleFieldEmptyStringError = useMemo(() => {
    if ('string.empty' !== errors.title?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_experiences.title_required_error') }
      </span>
    );
  }, [ errors.title?.type, t ]);

  // title field
  const titleField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.title_label') }
          <input
            className={
              clsx({
                'error': errors?.title,
              })
            }
            defaultValue={ experience?.title || '' }
            maxLength={ 128 }
            placeholder={ t('job_seekers:profile_experiences.title_placeholder') }
            type='text'
            { ...register('title') }
          />
        </label>
        { titleFieldEmptyStringError }
      </div>
    );
  }, [ errors?.title, experience?.title, register, t, titleFieldEmptyStringError ]);

  // the started in default value
  const startedInDefaultValue = useMemo(() => {
    if (experience?.startedIn === undefined) {
      return null;
    }
    return DateTime.fromISO(experience.startedIn).toJSDate();
  }, [ experience?.startedIn ]);

  // The started in datepicker component
  const startedInDatePicker = useCallback(({ field }) => {
    return (
      <DatePicker
        { ...field }
        className={
          clsx({
            'error': errors?.startedIn,
          })
        }
        placeholder={ t('job_seekers:profile_experiences.started_in_placeholder') }
        type='month-year'
      />
    );
  }, [ errors?.startedIn, t ]);

  // The started in base error
  const startedInBaseError = useMemo(() => {
    if ('date.base' !== errors.startedIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_experiences.started_in_required_error') }
      </span>
    );
  }, [ errors.startedIn?.type, t ]);

  // rendered started in max error value
  const renderedStartedInMaxError = useMemo(() => {
    return current ? t('job_seekers:profile_experiences.started_in_after_now_error')
      : t('job_seekers:profile_experiences.started_in_after_ended_in_error');
  }, [ current, t ]);

  // The started in max error
  const startedInMaxError = useMemo(() => {
    if ('date.max' !== errors.startedIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { renderedStartedInMaxError }
      </span>
    );
  }, [ errors.startedIn?.type, renderedStartedInMaxError ]);

  // The started in field
  const startedInField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.started_in_label') }
          <Controller
            control={ control }
            defaultValue={ startedInDefaultValue }
            name='startedIn'
            render={ startedInDatePicker }
          />
        </label>
        { startedInBaseError }
        { startedInMaxError }
      </div>
    );
  }, [ control, startedInBaseError, startedInDatePicker, startedInDefaultValue, startedInMaxError, t ]);

  // The ended in field default value
  const endedInDefaultValue = useMemo(() => {
    if (experience?.endedIn === undefined) {
      return null;
    }
    return DateTime.fromISO(experience.endedIn).toJSDate();
  }, [ experience?.endedIn ]);

  // The ended in field date picker
  const endedInFieldDatePicker = useCallback(({ field }) => {
    return (
      <DatePicker
        { ...field }
        className={
          clsx({
            'error': errors?.endedIn,
          })
        }
        disabled={ current }
        placeholder={ t('job_seekers:profile_experiences.ended_in_placeholder') }
        type='month-year'
      />
    );
  }, [ current, errors?.endedIn, t ]);

  // The ended in base error
  const endedInFieldBaseError = useMemo(() => {
    if ('date.base' !== errors.endedIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_experiences.ended_in_required_error') }
      </span>
    );
  }, [ errors.endedIn?.type, t ]);

  // The ended in field max error
  const endedInFieldMaxError = useMemo(() => {
    if ('date.max' !== errors.endedIn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('job_seekers:profile_experiences.ended_in_after_now_error') }
      </span>
    );
  }, [ errors.endedIn?.type, t ]);

  // The ended in field
  const endedInField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.ended_in_label') }
          <Controller
            control={ control }
            defaultValue={ endedInDefaultValue }
            name='endedIn'
            render={ endedInFieldDatePicker }
          />
        </label>
        { endedInFieldBaseError }
        { endedInFieldMaxError }
      </div>
    );
  }, [ control, endedInDefaultValue, endedInFieldBaseError, endedInFieldDatePicker, endedInFieldMaxError, t ]);

  // The current field
  const currentField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label className='inline'>
          <input
            className={
              clsx({
                'error': errors?.current,
              })
            }
            defaultChecked={ experience?.current || false }
            type='checkbox'
            { ...register('current') }
          />
          { t('job_seekers:profile_experiences.current_label') }
        </label>
      </div>
    );
  }, [ errors, experience, register, t ]);

  // The description field
  const descriptionField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.description_label') }
          <textarea
            className={
              clsx({
                'error': errors?.description,
              })
            }
            defaultValue={ experience?.description || '' }
            maxLength={ 512 }
            placeholder={ t('job_seekers:profile_experiences.description_placeholder') }
            rows={ 10 }
            { ...register('description') }
          />
        </label>
      </div>
    );
  }, [ errors?.description, experience?.description, register, t ]);

  // skills multi select is disabled
  const skillsMultiSelectIsDisabled = useCallback((field) => {
    return 16 <= field.value?.length;
  }, []);

  // the skills multi select component options
  const skillsMultiSelectComponentOptions = useMemo(() => {
    return allSkills.map((skill) => {
      return { label: skill[selectedLanguage], value: skill.id };
    });
  }, [ allSkills, selectedLanguage ]);

  // The skills multi select component
  const skillsMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ skillsMultiSelectIsDisabled(field) }
        options={ skillsMultiSelectComponentOptions }
        placeholder={ t('job_seekers:profile_experiences.skills_placeholder') }
      />
    );
  }, [ skillsMultiSelectComponentOptions, skillsMultiSelectIsDisabled, t ]);

  // The skills field default value
  const skillsFieldDefaultValue = useMemo(() => {
    if (undefined === experience?.skills) {
      return [];
    }
    return allSkills.filter((skill) => {
      return -1 !== experience.skills.indexOf(skill.id);
    }).map((skill) => {
      return { label: skill[selectedLanguage], value: skill.id };
    });
  }, [ allSkills, experience?.skills, selectedLanguage ]);

  // The skills field
  const skillsField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('job_seekers:profile_experiences.skills_label') }
          <Controller
            control={ control }
            defaultValue={ skillsFieldDefaultValue }
            name='skills'
            render={ skillsMultiSelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, skillsFieldDefaultValue, skillsMultiSelectComponent, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('job_seekers:profile_experiences.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { companyField }
        { titleField }
        { startedInField }
        { endedInField }
        { currentField }
        { descriptionField }
        { skillsField }
        <div className='profile-editor__actions'>
          <div className='profile-editor__actions profile-editor__actions--left'>
            <button
              className='btn btn-sm btn-rounded-sm btn-red'
              onClick={ onDelete }
              type='reset'
            >
              { t('profile:common.delete_button_label') }
            </button>
          </div>
          <div className='profile-editor__actions profile-editor__actions--right'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white margin-right-1'
              onClick={ onCancel }
              type='reset'
            >
              { t('profile:common.cancel_button_label') }
            </button>
            <button
              className='btn btn-sm btn-rounded-sm btn-blue'
              disabled={ isNotEmpty(errors) }
              type='submit'
            >
              { t('profile:common.save_button_label') }
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

ProfileExperienceEditor.displayName = 'ProfileExperienceEditor';

ProfileExperienceEditor.propTypes = {
  // All the skills available
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of skill
      el: PropTypes.string,
      // english translation of skill
      en: PropTypes.string,
      // the id of the skill
      id: PropTypes.string,
    })
  ),
  // The experience index
  experienceIndex: PropTypes.number,
  // The function (() => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((object) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The experience of the job seeker.
    experience: PropTypes.arrayOf(PropTypes.shape({
      // The company where the position was.
      company: PropTypes.string,
      // Whether this is the current position of the job seeker.
      current: PropTypes.bool,
      // The description of the position.
      description: PropTypes.string,
      // The date when the job seeker stopped working in the position.
      endedIn: PropTypes.string,
      // The IDs of the skills associated to the position.
      skills: PropTypes.arrayOf(PropTypes.string),
      // The date when the job seeker started working in the position
      startedIn: PropTypes.string,
      // The job title of the position.
      title: PropTypes.string,
    })),
  }),
};

ProfileExperienceEditor.defaultProps = {};

export default ProfileExperienceEditor;
