import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ArrowCardRightIcon from 'components/common/icons/arrow-card-right-icon/arrow-card-right-icon';
import CardListArrowIcon from 'components/common/icons/card-list-arrow-icon/card-list-arrow-icon';
import useElementOnScreen from 'hooks/use-element-on-screen';
import roles, * as Roles from 'utilities/auth/roles';
import csrPricingPackages from 'utilities/csr-pricing-plans';

import './social-responsibility-csr-package.scss';

const SocialResponsibilityCsrPackage = memo((props) => {
  const {
    descriptionCollection,
    title,
    buttonLabel,
    userRole,
    userAuthenticated,
    handleCsrPackageClick,
    csrPricingPackage,
  } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // The function to invoke when a csr packages is clicked
  const handleCsrPricingPackagesClick = useCallback((csrPricingPackage) => {
    return (_event) => {
      return handleCsrPackageClick(csrPricingPackage);
    };
  }, [ handleCsrPackageClick ]);

  // The rendered description items
  const renderedDescriptionItems = useMemo(() => {
    return descriptionCollection.map((descriptionItem, index) => {
      return (
        <li
          className='social-responsibility-csr-package__description-item'
          key={ descriptionItem + index }
        >
          <div className='social-responsibility-csr-package__description-icon-container'>
            <CardListArrowIcon />
          </div>
          <span className='txt txt-md social-responsibility-csr-package__description-text'>
            { descriptionItem }
          </span>
        </li>
      );
    });
  }, [ descriptionCollection ]);

  // Whether the pricing interest button should be disabled
  const buttonDisabled = useMemo(() => {
    return userRole !== Roles.COMPANY_AGENT;
  }, [ userRole ]);

  // The rendered button
  const renderedButton = useMemo(() => {
    if (userAuthenticated) {
      return (
        <button
          className='btn btn-sm btn-rounded-sm btn-blue social-responsibility-csr-package__button'
          disabled={ buttonDisabled }
          id='choose-csr-package'
          name='Choose Csr Package'
          onClick={ handleCsrPricingPackagesClick(csrPricingPackage) }
          type='button'
        >
          { buttonLabel }
          <ArrowCardRightIcon />
        </button>
      );
    }
    return (
      <Link
        className='btn btn-sm btn-rounded-sm btn-blue social-responsibility-csr-package__button'
        to='/companies/sign-up'
      >
        { buttonLabel }
        <ArrowCardRightIcon />
      </Link>
    );
  }, [ buttonDisabled, buttonLabel, csrPricingPackage, handleCsrPricingPackagesClick, userAuthenticated ]);

  return (
    <div
      className={
        clsx('social-responsibility-csr-package', {
          'social-responsibility-csr-package__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <h4 className='txt txt-lg txt-bold social-responsibility-csr-package__title'>
        { title }
      </h4>
      <ul className='social-responsibility-csr-package__description-container'>
        { renderedDescriptionItems }
      </ul>
      { renderedButton }
    </div>
  );
});

SocialResponsibilityCsrPackage.displayName = 'SocialResponsibilityCsrPackage';

SocialResponsibilityCsrPackage.propTypes = {
  // The button label of the card
  buttonLabel: PropTypes.string.isRequired,
  // The csr package
  csrPricingPackage: PropTypes.oneOf(csrPricingPackages).isRequired,
  // The description collection of the card
  descriptionCollection: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The function (csrPricingPlan) => void toe handle the pricing package click
  handleCsrPackageClick: PropTypes.func,
  // The card title
  title: PropTypes.string.isRequired,
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
  // The user role
  userRole: PropTypes.oneOf(roles),
};

SocialResponsibilityCsrPackage.defaultProps = {};

export default SocialResponsibilityCsrPackage;
