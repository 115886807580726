import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import HomeCreateProfileAnimationIcon from 'animations/writing-hand.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';
import SeeExampleProfileButton from 'components/see-example-profile-button/see-example-profile-button';

import './home-create-profile-section.scss';

const HomeCreateProfileSection = memo(() => {
  const { t } = useTranslation();

  return (
    <div className='home-create-profile-section'>
      <LottieAnimationContainer
        animatedIconClassName='home-create-profile-section__animated-icon'
        animationData={ HomeCreateProfileAnimationIcon }
        className='home-create-profile-section__animation-container'
        loop={ false }
      />
      <div className='home-create-profile-section__info-container'>
        <h2 className='hdg hdg-xxl home-create-profile-section__info-title'>
          { t('home:create_profile_section.title') }
        </h2>
        <p className='txt txt-md home-create-profile-section__info'>
          { t('home:create_profile_section.description') }
        </p>
        <SeeExampleProfileButton
          className='btn-sm btn-rounded-sm btn-blue txt txt-bold
          home-create-profile-section__see-example-profile-button'
        />
      </div>
    </div>
  );
});

HomeCreateProfileSection.displayName = 'HomeCreateProfileSection';

HomeCreateProfileSection.propTypes = {};

HomeCreateProfileSection.defaultProps = {};

export default HomeCreateProfileSection;
