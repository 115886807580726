/**
 * Greek translations for common/profile-completion.
 *
 * @module languages/el/common/profile-completion
 */
const profileCompletion = {
  almost_there: 'Έχετε σχεδόν ολοκληρώσει το προφίλ σας',
  complete: 'Το προφίλ σας είναι κατά {{percentage}}% συμπληρωμένο',
  great_job: 'Τέλεια!',
  see_your_profile_link: 'Δείτε το προφίλ σας',
  title: 'Επόμενα βήματα',
};

export default profileCompletion;
