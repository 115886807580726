import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import HomeOurCompaniesChildCollectionItem
  from './home-our-companies-say-child-collection-item/home-our-companies-say-child-collection-item';

import './home-our-companies-say-root-collection-item.scss';

const HomeOurCompaniesSayRootCollectionItem = memo((props) => {
  const { collectionItem } = props;

  const renderedCollectionChildItems = useMemo(() => {
    return collectionItem.map((collectionChildItem) => {
      return (
        <HomeOurCompaniesChildCollectionItem
          description={ collectionChildItem.description }
          imageUrl={ collectionChildItem.imageUrl }
          key={ collectionChildItem.description }
          name={ collectionChildItem.name }
          occupation={ collectionChildItem.occupation }
        />
      );
    });
  }, [ collectionItem ]);

  return (
    <div className='home-our-companies-say-root-collection-item'>
      { renderedCollectionChildItems }
    </div>
  );
});

HomeOurCompaniesSayRootCollectionItem.displayName = 'HomeOurCompaniesSayRootCollectionItem';

HomeOurCompaniesSayRootCollectionItem.propTypes = {
  // Collection item
  collectionItem: PropTypes.arrayOf(
    PropTypes.shape({
      // the description
      description: PropTypes.string,
      // The image url
      imageUrl: PropTypes.string,
      // The name
      name: PropTypes.string,
      // The occupation
      occupation: PropTypes.string,
    })
  ).isRequired,
};

HomeOurCompaniesSayRootCollectionItem.defaultProps = {};

export default HomeOurCompaniesSayRootCollectionItem;
