/**
 * English translations for common/file-preview.
 *
 * @module languages/en/common/file-preview
 */
const filePreview = {
  file_loading_message: 'File loading...',
  no_preview_available_message: 'No preview available.',
  number_of_total: '{{number}} of {{total}}',
  page_loading_message: 'Page loading...',
  preview_failed_error: 'Preview failed.',
};

export default filePreview;
