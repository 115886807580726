/**
 * Duck for skills.
 *
 * @module ducks/skills
 */

/**
 * The type of actions to describe that the skills were set.
 *
 * @constant {string}
 * @default
 * @static
 */
const SKILLS_SET = 'SKILLS_SET';

/**
 * Creates an action to set the skills to the given skills.
 *
 * @param {module:types/skills~Skill[]} skills - The skills.
 * @returns {object} - The action.
 * @static
 */
const setSkills = (skills) => {
  return {
    skills,
    type: SKILLS_SET,
  };
};

/**
 * Skills state.
 *
 * @typedef module:ducks/skills~State
 * @type {object}
 * @property {module:types/skills~Skill[]} skills - The different skills.
 */

/**
 * The initial skills state.
 *
 * @type {module:ducks/skills~State}
 * @static
 */
const initialState = {
  skills: [],
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/skills~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/skills~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SKILLS_SET:
    return {
      ...state,
      skills: action.skills,
    };
  default:
    return state;
  }
};

const actions = {
  setSkills,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
