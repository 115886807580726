/**
 * Share-profile button.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import env from 'config/env';
import * as toasts from 'toasts';

import sharePurple from './images/purple-share.png';
import shareWhite from './images/white-share.png';
import './share-profile-button.scss';

const ShareProfileButton = (props) => {
  const { t } = useTranslation();

  // The link.
  const link = `${ env.PUBLIC_URL }/job-seekers/${ props.jobSeekerId }/profile`;

  return (
    <div className='share-profile-button'>
      <Modal
        className='share-profile-modal'
        trigger={
          <button
            className={ clsx('btn', props.className) }
            type='button'
          >
            <img alt='Share' src={ 'purple' === props.variant ? sharePurple : shareWhite } />
            { t('job_seekers:share_profile_button.share_profile_button_label') }
          </button>
        }
      >
        <div className='share-profile'>
          <div className='hdg hdg-md'>{ t('job_seekers:share_profile_button.modal.title') }</div>
          <div className='profile-link-and-copy'>
            <input
              className='profile-link'
              onFocus={
                (e) => {
                  e.target.select();
                }
              }
              readOnly
              value={ link }
            />
            <button
              className='btn btn-sm btn-rounded-sm btn-blue'
              onClick={
                () => {
                  navigator.clipboard.writeText(link).then(() => {
                    toasts.info(t('job_seekers:share_profile_button.modal.link_copied_message'));
                  }).catch((_error) => {
                    toasts.error(t('job_seekers:share_profile_button.link_modal.copy_failed_error'));
                  });
                }
              }
              type='button'
            >
              { t('job_seekers:share_profile_button.modal.copy_link_button_label') }
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ShareProfileButton.propTypes = {
  // The class(es) to apply to the button.
  className: PropTypes.string,
  // The ID of the job seeker whose profile to share.
  jobSeekerId: PropTypes.string,
  // The variant.
  variant: PropTypes.oneOf([ 'purple', 'white' ]).isRequired,
};

ShareProfileButton.defaultProps = {};

export default ShareProfileButton;
