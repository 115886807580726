/**
 * Greek translations for common/range-filter.
 *
 * @module languages/el/common/range-filter
 */
const rangeFilter = {
  no_range_message: 'Χωρίς δεδομένα.',
};

export default rangeFilter;
