/**
 * Endorsement status.
 *
 * @module utilities/endorsement-status
 */

/**
 * Created.
 *
 * @constant {string}
 * @static
 */
export const CREATED = 'CREATED';

/**
 * Claimed.
 *
 * @constant {string}
 * @static
 */
export const CLAIMED = 'CLAIMED';

/**
 * PROCESSED.
 *
 * @constant {string}
 * @static
 */
export const PROCESSED = 'PROCESSED';

export default [
  CREATED,
  CLAIMED,
  PROCESSED,
];
