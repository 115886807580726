import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import GrowSocialImpactAnimatedIcon from 'animations/employable.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './public-grow-social-impact-section.scss';

const PublicGrowSocialImpactSection = memo((props) => {
  const { title, info, buttonLabel, isBlue, hasButton } = props;

  // The rendered button
  const renderedButton = useMemo(() => {
    if (!hasButton) {
      return null;
    }
    return (
      <Link
        className='btn btn-sm btn-rounded-sm txt txt-bold btn-white public-grow-social-impact-section__button'
        to='/social-responsibility'
      >
        { buttonLabel }
      </Link>
    );
  }, [ buttonLabel, hasButton ]);

  return (
    <div
      className={
        clsx('public-grow-social-impact-section', {
          'public-grow-social-impact-section__blue': isBlue,
        })
      }
    >
      <LottieAnimationContainer
        animatedIconClassName='public-grow-social-impact-section__animated-icon'
        animationData={ GrowSocialImpactAnimatedIcon }
        className='public-grow-social-impact-section__animation-container'
      />
      <div className='public-grow-social-impact-section__info-container'>
        <h4 className='hdg hdg-xxl public-grow-social-impact-section__title'>
          { title }
        </h4>
        <p
          className={
            clsx('txt txt-lg public-grow-social-impact-section__info', {
              'txt txt-lg public-grow-social-impact-section__info--has-button': hasButton,
            })
          }
        >
          { info }
        </p>
        { renderedButton }
      </div>
    </div>
  );
});

PublicGrowSocialImpactSection.displayName = 'PublicGrowSocialImpactSection';

PublicGrowSocialImpactSection.propTypes = {
  // The button label
  buttonLabel: PropTypes.string,
  // Whether the section has button
  hasButton: PropTypes.bool,
  // The info
  info: PropTypes.string.isRequired,
  // Whether the section is blue
  isBlue: PropTypes.bool,
  // The title
  title: PropTypes.string.isRequired,
};

PublicGrowSocialImpactSection.defaultProps = {
  hasButton: false,
  isBlue: false,
};

export default PublicGrowSocialImpactSection;
