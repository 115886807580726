/**
 * Sort Keys types for job seekers.
 *
 * @module utilities/job-seekers/sort-keys
 */

/**
 * Based on when their profile was last modified.
 *
 * @constant {string}
 * @static
 */
export const LAST_MODIFIED_AT = 'LAST_MODIFIED_AT';

/**
 * Based on the completion percentage of their profile.
 *
 * @constant {string}
 * @static
 */
export const PROFILE_COMPLETION = 'PROFILE_COMPLETION';

/**
 * Based on their work availability.
 *
 * @constant {string}
 * @static
 */
export const WORK_AVAILABILITY = 'WORK_AVAILABILITY';

/**
 * The default sort key.
 *
 * @constant {string}
 * @default
 * @static
 */
export const DEFAULT_SORT_KEY = LAST_MODIFIED_AT;

export default [
  LAST_MODIFIED_AT,
  PROFILE_COMPLETION,
  WORK_AVAILABILITY,
];
