import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './about-us-how-does-it-work-step.scss';

const AboutUsHowDoesItWorkStep = memo((props) => {
  const { description, title } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('about-us-how-does-it-work-step', {
          'about-us-how-does-it-work-step__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <p className='txt txt-lg txt-bold about-us-how-does-it-work-step__title'>
        { title }
      </p>
      <p className='txt txt-md about-us-how-does-it-work-step__description'>
        { description }
      </p>
    </div>
  );
});

AboutUsHowDoesItWorkStep.displayName = 'AboutUsHowDoesItWorkStep';

AboutUsHowDoesItWorkStep.propTypes = {
  // The step description
  description: PropTypes.string.isRequired,
  // The step title
  title: PropTypes.string.isRequired,
};

AboutUsHowDoesItWorkStep.defaultProps = {};

export default AboutUsHowDoesItWorkStep;
