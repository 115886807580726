/**
 * Resource method to submit an endorsement.
 *
 * @module resources/endorsers/submit-endorsement
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/endorsers/submit-endorsement~Params
 * @type {object}
 * @property {('POOR' | 'WILLNESS' | 'ABILITY' | 'GOOD_LEVEL' | 'PROFESSIONAL')} communicationCooperationSkillsLevel
 * - The communication and cooperation skills level.
 * @property {('SLOW_RESPONSIVENESS' | 'UNDERSTANDING' | 'NEED_GUIDANCE'
 * | 'NICE_RESPONSIVENESS' | 'FAST_RESPONSIVENESS')} criticalThinkingLevel - The critical thinking level.
 * @property {string} employerFeedback - The employer feedback.
 * @property {string[]} [hashtags] - The UUID's array of selected hashtags.
 * @property {('MORE_PREPARATION' | 'KNOWLEDGE_UPGRADE' | 'SUPPORT_NEEDED' | 'LANGUAGES_IMPROVEMENT'
 * | 'PRIORITIZATION'  | 'PERSONAL_FAMILY_MATTERS')[]} jobSeekerFeedbacks - The array of the job seeker feedbacks
 * @property {('FIRST_STEP' | 'FIND_JOB' | 'TAKE_JOB' | 'KEEP_JOB' | 'WORK_HARD')} motivationLevel
 * - The motivation level.
 * @property {('CONSISTENCY' | 'POLITENESS' | 'SELF_CONTROL' | 'ATTITUDE'
 * | 'GOOD_INTENTION')[]} professionalCharacteristics - The professional characteristics.
 * @property {string} testimonial - The testimonial.
 */

/**
 * Result.
 *
 * @typedef module:resources/endorsers/sign-up~Result
 * @type {object}
 * @property {string} id - The ID of the company agent.
 */

/**
 * Submits an endorsement.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/endorsers/submit-endorsement~Params} params - The parameters.
 * @returns {Promise<module:resources/endorsers/submit-endorsement~Result>} - The promise.
 * @static
 */
const submitEndorsement = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/endorsements/',
  }).then((response) => {
    return response.data;
  });
};

export default submitEndorsement;
