/**
 * English translations for job-seekers/steps.
 *
 * @module languages/en/job-seekers/steps
 */
const steps = {
  COMPETENCIES: 'Submit your competencies',
  CONTACT: 'Submit your contact details',
  EXPERIENCE: 'Upload your experience',
  JOB_SECTORS: 'Submit the job sectors you are interested in',
  LANGUAGES: 'Submit your languages knowledge',
  PERSONAL: 'Submit your personal details',
  PROFESSIONS: 'Submit the jobs you are interested in',
  PROFILE_PICTURE: 'Upload your profile picture',
  QUALIFICATIONS: 'Upload your qualifications',
  RESUME: 'Upload your resume',
  SHORT_BIO: 'Submit your short bio',
  VIDEO: 'Upload your video resume',
};

export default steps;
