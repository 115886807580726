/**
 * Greek translations for common/profile-contact-details.
 *
 * @module languages/el/common/profile-contact-details
 */
const profileContactDetails = {
  company_position_label: 'Θέση εργασίας',
  company_position_placeholder: 'Θέση εργασίας',
  company_position_required_error: 'Η θέση εργασίας είναι απαραίτητη',
  editor_title: 'Πληροφορίες επικοινωνίας',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Η καταχώρηση του Email είναι υποχρεωτική',
  first_name_label: 'Όνομα',
  first_name_placeholder: 'Όνομα',
  first_name_required_error: 'Η καταχώρηση του ονόματος είναι υποχρεωτική',
  last_name_label: 'Επίθετο',
  last_name_placeholder: 'Επίθετο',
  last_name_required_error: 'Η καταχώρηση του επιθέτου είναι υποχρεωτική',
  linkedin_profile: 'Προφίλ LinkedIn ',
  linkedin_profile_url: 'URL προφίλ LinkedIn',
  linkedin_profile_url_invalid_error: 'Εισαγάγετε μια έγκυρη διεύθυνση URL.',
  linkedin_profile_url_label: 'URL προφίλ LinkedIn',
  linkedin_profile_url_placeholder: 'Επικολλήστε τη διεύθυνση URL του προφίλ σας στο LinkedIn',
  phone_number_1_placeholder: 'Αριθμός κινητού τηλεφώνου',
  phone_number_2_placeholder: 'Άλλος αριθμός τηλεφώνου',
  // eslint-disable-next-line max-len
  phone_number_invalid_error: 'Ο αριθμός τηλεφώνου πρέπει να είναι αριθμός με το πρόθεμα της χώρας (π.χ.  30) και να μην υπερβαίνει τους 10 αριθμούς',
  phone_number_label: 'Τηλέφωνο',
  phone_number_placeholder: 'Αριθμός τηλεφώνου',
  phone_number_required_error: 'Η καταχώρηση του αριθμού τηλεφώνου είναι υποχρεωτική',
  title: 'Πληροφορίες επικοινωνίας',
};

export default profileContactDetails;
