import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ClassRoomAnimationIcon from 'animations/consulting-service.json';
import ShipAnimationIcon from 'animations/hope.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';
import SeeExampleProfileButton from 'components/see-example-profile-button/see-example-profile-button';

import './learn-more-white-section.scss';

const LearnMoreWhiteSection = memo((props) => {
  const { isEndorsementSection, hasButton, title, description, buttonLabel } = props;

  // The rendered button content
  const renderedButtonContent = useMemo(() => {
    if (isEndorsementSection) {
      return (
        <Link
          className='btn btn-white btn-sm btn-rounded-sm learn-more-white-section__button'
          to='/endorsers/learn-more'
        >
          { buttonLabel }
        </Link>
      );
    }
    return (
      <SeeExampleProfileButton
        className='btn btn-sm btn-rounded-sm btn-red learn-more-white-section__button'
      />
    );
  }, [ buttonLabel, isEndorsementSection ]);

  // The rendered button
  const renderedButton = useMemo(() => {
    if (!hasButton) {
      return null;
    }
    return renderedButtonContent;
  }, [ hasButton, renderedButtonContent ]);

  // The rendered content
  const renderedIcon = useMemo(() => {
    if (isEndorsementSection) {
      return (
        <LottieAnimationContainer
          animatedIconClassName='learn-more-white-section__animated-icon'
          animationData={ ShipAnimationIcon }
          className='learn-more-white-section__animation-container'
        />
      );
    }
    return (
      <LottieAnimationContainer
        animatedIconClassName='learn-more-white-section__animated-icon'
        animationData={ ClassRoomAnimationIcon }
        className='learn-more-white-section__animation-container'
      />
    );
  }, [ isEndorsementSection ]);

  return (
    <div className='learn-more-white-section'>
      <div className='learn-more-white-section__content-container'>
        <h2 className='hdg hdg-xxl learn-more-white-section__title'>
          { title }
        </h2>
        <p
          className={
            clsx('txt txt-md learn-more-white-section__description', {
              'learn-more-white-section__description--has-button': hasButton,
            })
          }
        >
          { description }
        </p>
        { renderedButton }
      </div>
      { renderedIcon }
    </div>
  );
});

LearnMoreWhiteSection.displayName = 'LearnMoreWhiteSection';

LearnMoreWhiteSection.propTypes = {
  // The button label of the button
  buttonLabel: PropTypes.string,
  // The description of the section
  description: PropTypes.string.isRequired,
  // Whether the section has button
  hasButton: PropTypes.bool,
  // Whether this is an endorsement section
  isEndorsementSection: PropTypes.bool,
  // The title of the section
  title: PropTypes.string.isRequired,
};

LearnMoreWhiteSection.defaultProps = {
  buttonLabel: '',
  hasButton: false,
  isEndorsementSection: false,
};

export default LearnMoreWhiteSection;
