/**
 * Resource method to sign up as a company.
 *
 * @module resources/companies/sign-up
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/sign-up~Params
 * @type {object}
 * @property {string} companyName - The name of the company.
 * @property {string} email - The email of the company agent.
 * @property {string} firstName - The first name of the company agent.
 * @property {string} language - The ISO 639-1 alpha-2 code of the language of the company agent.
 * @property {string} lastName - The last name of the company agent.
 * @property {string} phoneNumber - The phone number of the company agent.
 * @property {string} password - The password of the company agent (in plain text).
 */

/**
 * Result.
 *
 * @typedef module:resources/companies/sign-up~Result
 * @type {object}
 * @property {string} id - The ID of the company.
 */

/**
 * Signs up a company agent based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/sign-up~Params} params - The parameters.
 * @returns {Promise<module:resources/companies/sign-up~Result>} - The promise.
 * @static
 */
const signUp = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/companies/sign-up',
  }).then((response) => {
    return response.data;
  });
};

export default signUp;
