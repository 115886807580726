import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import { useSm, useXs } from 'hooks/media-queries';
import accessLevels from 'utilities/access-levels';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileExperienceCard from './profile-experience-card/profile-experience-card';
import ProfileExperienceEditor from './profile-experience-editor/profile-experience-editor';

import './profile-experience.scss';

const ProfileExperience = memo((props) => {
  const { profile, onChange, canEdit, allSkills } = props;

  const isSm = useSm();

  const { t } = useTranslation();

  const isXs = useXs();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // Whether the modal with the experience details is open.
  const [ detailsOpen, setDetailsOpen ] = useState(false);
  // Whether the modal with the experience editor is open.
  const [ editorOpen, setEditorOpen ] = useState(false);
  // Whether each experience card is expanded.
  const [ expanded, setExpanded ] = useState(Array(profile?.experience?.length || 0).fill(!isXs && !isSm));
  // The experience, whose details are being shown.
  const [ experience, setExperience ] = useState(undefined);
  // The index of the experience that is being edited.
  const [ experienceIndex, setExperienceIndex ] = useState(undefined);
  // Whether to show all experiences.
  const [ showAll, setShowAll ] = useState(isXs || isSm);

  // Change whether to show all or some experiences as the screen size changes.
  useEffect(() => {
    setShowAll(isXs || isSm);
  }, [ isSm, isXs ]);

  // Collapse everything in xs and sm.
  useEffect(() => {
    setExpanded(Array(profile?.experience?.length || 0).fill(!isXs && !isSm));
  }, [ isSm, isXs, profile?.experience ]);

  // The minimum number of experiences to show.
  const MINIMUM_NUMBER_OF_EXPERIENCES = useMemo(() => {
    return 1024;
  }, []);

  // The number of experiences to show.
  const maximumNumberOfExperiences = useMemo(() => {
    return showAll ? (profile?.experience?.length || 0) : MINIMUM_NUMBER_OF_EXPERIENCES;
  }, [ MINIMUM_NUMBER_OF_EXPERIENCES, profile?.experience?.length, showAll ]);

  // Whether the title is expandable
  const isTitleExpandable = useMemo(() => {
    return MINIMUM_NUMBER_OF_EXPERIENCES < profile?.experience?.length;
  }, [ MINIMUM_NUMBER_OF_EXPERIENCES, profile?.experience?.length ]);

  // Function that triggers when the create button is clicked
  const onCreateClick = useCallback(() => {
    setExperienceIndex(profile?.experience?.length || 0);
    setEditorOpen(true);
  }, [ profile?.experience?.length ]);

  // function that handles the on click show all button
  const onClickShowAllButton = useCallback(() => {
    setShowAll(!showAll);
  }, [ showAll ]);

  // function that handles the expanded state when the card is clicked
  const onExperienceCardExpanded = useCallback((experienceIndex) => {
    return (expandedItem) => {
      return expandedItem?.map((expanded, index) => {
        return experienceIndex === index ? !expanded : expanded;
      });
    };
  }, []);

  // function that handles when the experience card is clicked
  const onProfileExperienceCardClick = useCallback((experience, experienceIndex) => {
    return () => {
      if (isSm || isXs) {
        setExpanded(onExperienceCardExpanded(experienceIndex));
        return;
      }
      setExperience(experience);
      setDetailsOpen(true);
    };
  }, [ isSm, isXs, onExperienceCardExpanded ]);

  // function that handles when clicking the edit of the profile experience card
  const onProfileExperienceCardEdit = useCallback((experienceIndex) => {
    return () => {
      setExperienceIndex(experienceIndex);
      setEditorOpen(true);
    };
  }, []);

  // The rendered experience cards
  const renderedExperienceCards = useMemo(() => {
    return profile?.experience?.slice(0, maximumNumberOfExperiences)?.map((experience, index) => {
      return (
        <ProfileExperienceCard
          allSkills={ allSkills }
          canEdit={ canEdit }
          detailed={ isSm || isXs || false }
          expanded={ expanded[index] || false }
          experience={ experience }
          key={ experience.company + index }
          onClick={ onProfileExperienceCardClick(experience, index) }
          onEdit={ onProfileExperienceCardEdit(index) }
        />
      );
    });
  }, [
    allSkills,
    canEdit,
    expanded,
    isSm,
    isXs,
    maximumNumberOfExperiences,
    onProfileExperienceCardClick,
    onProfileExperienceCardEdit,
    profile?.experience,
  ]);

  // The function that handles the close of details modal
  const onCloseDetailsModal = useCallback(() => {
    setDetailsOpen(false);
  }, []);

  // Function that handles when the experience of the user changes
  const onExperienceChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, JobSeekerParts.EXPERIENCE, isToBeUpdated);
  }, [ onChange ]);

  // The details modal
  const detailsModal = useMemo(() => {
    if (experience === undefined) {
      return null;
    }
    return (
      <Modal
        className='profile-experience-details-modal'
        onClose={ onCloseDetailsModal }
        open={ detailsOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileExperienceCard
          allSkills={ allSkills }
          detailed={ true }
          expanded={ true }
          experience={ experience }
        />
      </Modal>
    );
  }, [ allSkills, detailsOpen, experience, onCloseDetailsModal ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileExperienceEditor
          allSkills={ allSkills }
          experienceIndex={ experienceIndex }
          onCancel={ toggleEditor(false) }
          onSave={ onExperienceChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ allSkills, canEdit, editorOpen, experienceIndex, onExperienceChange, profile, toggleEditor ]);

  return (
    <div className='profile-experiences'>
      <ProfileInfoRow
        expandableButtonLabel={ t(`job_seekers:profile_experiences.see_${ showAll ? 'less' : 'all' }_button_label`) }
        isCreatable={ canEdit }
        isExpandable={ isTitleExpandable }
        isMainSection
        isTitle
        onCreateClick={ onCreateClick }
        onExpandableClick={ onClickShowAllButton }
        textDisplayed={ t('job_seekers:profile_experiences.title') }
      />
      <div className='profile-experiences__cards-container'>
        { renderedExperienceCards }
      </div>
      { detailsModal }
      { editorModal }
    </div>
  );
});

ProfileExperience.displayName = 'ProfileExperience';

ProfileExperience.propTypes = {
  // All the skills available
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      // greek translation of skill
      el: PropTypes.string,
      // english translation of skill
      en: PropTypes.string,
      // the id of the skill
      id: PropTypes.string,
    })
  ),
  // Whether the user can edit
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The access level that the viewer has on the profile.
    accessLevel: PropTypes.oneOf(accessLevels),
    // The experience of the job seeker.
    experience: PropTypes.arrayOf(
      PropTypes.shape({
        // The company of the position
        company: PropTypes.string,
        // whether the user is currently working on the same position
        current: PropTypes.bool,
        // The description of the position
        description: PropTypes.string,
        // the skills UUID array
        skills: PropTypes.arrayOf(PropTypes.string),
        // when the position started in
        startedIn: PropTypes.string,
        // The title of the position
        title: PropTypes.string,
      })
    ),
  }),
};

ProfileExperience.defaultProps = {};

export default ProfileExperience;
