/**
 * Range filter.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from 'components/common/slider/slider';
import caretDown from 'images/caret-down.png';
import caretUp from 'images/caret-up.png';

import './range-filter.scss';

const RangeFilter = (props) => {
  const { t } = useTranslation();

  // Whether the filter is expanded.
  const [ expanded, setExpanded ] = useState(true);

  return (
    <div className={ clsx('ody-filter range', props.className, expanded ? 'expanded' : 'collapsed') }>
      <div className='title-and-expand-collapse'>
        <div className='title'>
          { props.title }
        </div>
        <button
          className='btn btn-trans'
          onClick={
            () => {
              setExpanded((expanded) => {
                return !expanded;
              });
            }
          }
          type='button'
        >
          <img alt={ expanded ? 'Collapse' : 'Expand' } src={ expanded ? caretUp : caretDown } />
        </button>
      </div>
      <div className='range'>
        {
          undefined === props.range ? (
            <div className='no-range txt txt-sm'>
              { t('common:range_filter.no_range_message') }
            </div>
          ) : (
            <Slider
              max={ props.range.end }
              min={ props.range.start }
              onChange={
                (value) => {
                  const start = undefined === value[0] ? props.range.start : value[0];
                  const end = undefined === value[1] ? props.range.end : value[1];
                  props.onChange?.(`${ start }-${ end }`);
                }
              }
              type='double'
              value={
                undefined === props.selected ? null
                  : [ Math.max(props.selected.start, props.range.start), Math.min(props.selected.end, props.range.end) ]
              }
            />
          )
        }
      </div>
    </div>
  );
};

RangeFilter.propTypes = {
  // The class(es) to apply to the filter.
  className: PropTypes.string,
  // The function ((any) => void) to invoke when the selected range changes.
  onChange: PropTypes.func,
  // The range.
  range: PropTypes.shape({
    // The end of the range.
    end: PropTypes.number.isRequired,
    // The start of the range.
    start: PropTypes.number.isRequired,
  }),
  // The selected range.
  selected: PropTypes.shape({
    // The end of the range.
    end: PropTypes.number.isRequired,
    // The start of the range.
    start: PropTypes.number.isRequired,
  }),
  // The step for the range.
  step: PropTypes.number,
  // The title of the filter.
  title: PropTypes.string,
};

RangeFilter.defaultProps = {
};

export default RangeFilter;
