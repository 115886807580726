/**
 * Verify-email page.
 */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import { actions as requestsActions } from 'ducks/requests';
import * as authMethods from 'resources/auth';
import * as Roles from 'utilities/auth/roles';

import './verify-email.scss';

const VerifyEmail = memo((_props) => {
  // The auth reducer.
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  const dispatch = useDispatch();

  const [ searchParams ] = useSearchParams();

  const { t } = useTranslation();

  const [ verified, setVerified ] = useState(undefined);

  // The email verification token.
  const emailVerificationToken = useMemo(() => {
    return searchParams.get('t');
  }, [ searchParams ]);

  // the role
  const userRole = useMemo(() => {
    return searchParams.get('role');
  }, [ searchParams ]);

  // Verify the email.
  useEffect(() => {
    if (false !== userAuthenticated) {
      return;
    }
    if (undefined === emailVerificationToken) {
      setVerified(false);
      return;
    }
    dispatch(requestsActions.request(authMethods.verifyEmail, {
      emailVerificationToken,
    }, {
      onFailure: (_error) => {
        setVerified(false);
      },
      onSuccess: (_result) => {
        setVerified(true);
      },
    }));
  }, [ dispatch, emailVerificationToken, userAuthenticated ]);

  // The verification text
  const verificationText = useMemo(() => {
    switch (userRole) {
    case Roles.JOB_SEEKER:
      return t('verify_email:job_seekers_verified_message');
    case Roles.COMPANY_AGENT:
      return t('verify_email:companies_verified_message');
    case Roles.ENDORSER:
      return t('verify_email:companies_verified_message');
    default:
      return t('verify_email:job_seekers_verified_message');
    }
  }, [ t, userRole ]);

  // The content
  const renderedContent = useMemo(() => {
    if (!verified) {
      return (
        <div className='error'>{ t('verify_email:general_error') }</div>
      );
    }
    return (
      <div className='verified txt txt-sm'>
        { verificationText }
      </div>
    );
  }, [ t, verificationText, verified ]);

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <div className='ody-verify-email'>
        <div className='wrapper'>
          { renderedContent }
        </div>
        <div className='logo'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [ renderedContent, userAuthenticated ]);
});

VerifyEmail.displayName = 'VerifyEmail';

VerifyEmail.propTypes = {
};

VerifyEmail.defaultProps = {
};

export default VerifyEmail;
