/**
 * Resource method to get the user email via LinkedIn.
 *
 * @module resources/users/get-linkedin-email
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/users/get-linkedin-email~Params
 * @type {object}
 * @property {string} authorizationCode - The LinkedIn authorization code.
 */

/**
 * Result.
 *
 * @typedef {module:types/users~UsersLinkedInEmail} Result
 */

/**
 * Gets the user email via LinkedIn.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/users/get-linkedin-email~Params} params - The parameters.
 * @returns {Promise<module:resources/users/get-linkedin-email~Result>} - The promise.
 * @static
 */
const getUsersLinkedInEmail = (params) => {
  return axios.request({
    method: 'get',
    url: `/users/linkedin-email/${ params.authorizationCode }`,
  }).then((response) => {
    return response.data;
  });
};

export default getUsersLinkedInEmail;
