/**
 * Resource method to get the created endorsement requests from job seekers.
 *
 * @module resources/endorsers/get-created-endorsement-requests
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/endorsers/get-created-endorsement-requests~Result
 * @type {object}
 * @property {module:types/common~EndorsementRequest[]} endorsementRequests
 */

/**
 * Gets the created endorsement requests.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/endorsers/get-created-endorsement-requests~Result>} - The promise.
 * @static
 */
const getStatistics = () => {
  return axios.request({
    method: 'get',
    url: '/endorsement-requests/created',
  }).then((response) => {
    return response.data;
  });
};

export default getStatistics;
