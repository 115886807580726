/**
 * English translations for common/range-filter.
 *
 * @module languages/en/common/range-filter
 */
const rangeFilter = {
  no_range_message: 'No data.',
};

export default rangeFilter;
