/**
 * English translations for companies/learn-more.
 *
 * @module languages/en/companies/learn-more
 */
const learnMore = {
  ai_talent_recommender_section: {
    button_label: 'Coming soon',
    // eslint-disable-next-line max-len
    description: 'Our AI algorithm matches your needs with available candidates on the platform. You will never miss a great opportunity for you.',
    title: 'AI talent recommender',
  },
  consulting_section: {
    // eslint-disable-next-line max-len
    description: 'According to your contribution in our job seekers’ lives and careers, we proudly award you with our social sustainability badge.',
    title: 'Consulting services for your DE&I strategy',
  },
  csr_packages_section: {
    button_label: 'Our CSR packages',
    // eslint-disable-next-line max-len
    description: 'Browse our CSR packages and pick the one you prefer. You can support vulnerable populations through education, career guidance and other ways. By collaborating on that level, we are offering to you our services free of charge.',
    title: 'Combine your HR needs with our CSR packages',
  },
  diversity_section: {
    // eslint-disable-next-line max-len
    description: 'Connect with candidates with a diverse background. Improve the diversity in your company and successfully communicate within and outside your organisation your efforts towards an inclusive society.',
    title: 'Its all about diversity and inclusion in the workplace',
  },
  endorsement_section: {
    button_label: 'Learn more',
    // eslint-disable-next-line max-len
    description: 'Our network of mentors and partners meet with job seekers using our platform and provide valuable feedback on candidates’ skills and mentality.',
    title: 'Endorsement',
  },
  example_profile_section: {
    button_label: 'See an example profile',
    // eslint-disable-next-line max-len
    description: 'Search for candidates according to their skills, traits, language proficiency, work history and more.',
    title: 'Filter candidates according to their skills',
  },
  faqs_section: {
    faqs: {
      item1: {
        // eslint-disable-next-line max-len
        answer: 'The talent platform is a virtual space that companies can:\n  - Save time looking for talent by joining a pool of verified jobseekers\n  - Filter candidates by their skills\n  - Get candidate recommendations by our Al talent recommender and our employability officers.\n  - Combine your HR needs with CSR packages to scale social impact.',
        question: 'What is the talent platform by Odyssea?',
      },
      item2: {
        // eslint-disable-next-line max-len
        answer: 'To join the platform, you have to create your personal profile by clicking the sign-up button on the top of the page.',
        question: 'How can I join the platform?',
      },
      item3: {
        // eslint-disable-next-line max-len
        answer: 'Yes, creating your account in the platform is for free!\n\nTo get the full advantages of our platform choose one of our pricing plans here!',
        question: 'Is the registration in the platform free?',
      },
      item4: {
        answer: 'For any questions you can contact us at: talent@odyssea.com',
        question: 'How can I get in touch?',
      },
    },
    title: 'FAQ',
  },
  hr_packages_section: {
    failed_email: 'We failed to send email to an admin. Please try again',
    free_pricing_button_label: 'Sign Up',
    free_pricing_plan: {
      description: {
        item1: 'Familiarize with the platform',
        item2: 'View profiles without having contact details access',
      },
      title: 'Free',
    },
    pricing_button_label: 'Choose Plan',
    pricing_interest_different_role_error: 'You have to be a company agent to send us a pricing interest',
    pricing_plans: {
      item1: {
        description: {
          item1: '5 recommended and filtered profiles for your job positions',
          item2: 'Full access to the database for 6 months',
          item3: 'Support us in the social kitchen with 100 meals provided',
        },
        pricing_description: '350€   VAT',
        title: 'Starter',
        title_description: '1 job position',
      },
      item2: {
        description: {
          item1: '10 recommended and filtered profiles for your job positions',
          item2: 'Full access to the database for 6 months',
          item3: 'Support us in the social kitchen with 200 meals provided',
        },
        pricing_description: '600€   VAT',
        title: 'Advance',
        title_description: '2-5 job positions',
      },
      item3: {
        description: {
          item1: '40 recommended and filtered profiles for your job positions',
          item2: 'Full access to the database for 6 months',
        },
        pricing_description: '1200€   VAT',
        title: 'Business',
        title_description: '6-10 job positions',
      },
      item4: {
        description: {
          item1: 'Organisation of job days with job seeker interviews for your job positions',
          item2: 'Full access to the database for 6 months',
        },
        pricing_description: 'Contact us',
        title: 'Enterprise',
        title_description: '10  job positions',
      },
    },
    success_email: 'An email has been sent to an admin, for a pricing interest.',
    title: 'HR packages',
  },
  pool_of_verified_job_seekers_section: {
    button_label: 'See  talent',
    // eslint-disable-next-line max-len
    description: 'Connect with candidates. Search efficiently for talent. Everything according to your profile and your needs. Sign up to join our community of companies and partners.',
    title: 'Pool of verified job seekers',
  },
  sign_in_section: {
    title: 'Identify the talent that best fits your hiring needs',
  },
};

export default learnMore;
