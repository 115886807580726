/**
 * Administrators - Dashboard - Pending Section - Pending Card.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Confirm from 'components/common/confirm/confirm';
import Email from 'images/email.png';
import Company from 'images/legalname.png';
import Person from 'images/man.png';
import Position from 'images/position.png';
import Phone from 'images/tel.png';
import roles, * as Roles from 'utilities/auth/roles';

import './pending-card.scss';

const PendingCard = memo((props) => {
  const { pendingProfile, onApproval, role } = props;

  const { t } = useTranslation();

  // The full name
  const fullName = useMemo(() => {
    return `${ pendingProfile?.lastName } ${ pendingProfile?.firstName }`;
  }, [ pendingProfile?.firstName, pendingProfile?.lastName ]);

  // The first company agent id
  // Right now only one company agent belongs to a company. In case of a different scenario
  // we will address this
  const companyAgentId = useMemo(() => {
    return pendingProfile?.companyAgentIds?.[0];
  }, [ pendingProfile?.companyAgentIds ]);

  // Function that handles the on click 'yes' on approval
  const handleConfirmApprove = useCallback(() => {
    if (role === Roles.COMPANY_AGENT) {
      return onApproval(companyAgentId);
    }
    return onApproval(pendingProfile?.id);
  }, [ companyAgentId, onApproval, pendingProfile?.id, role ]);

  // The card title
  const cardTitle = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return pendingProfile?.companyName;
    }
    return pendingProfile?.lastName;
  }, [ pendingProfile?.companyName, pendingProfile?.lastName, role ]);

  // The company if role is ENDORSER
  const companyName = useMemo(() => {
    if (role !== Roles.ENDORSER || !pendingProfile?.companyName) {
      return null;
    }
    return (
      <div className='pending-profile-card__info-container'>
        <img
          alt='info-icon'
          className='pending-profile-card__info-icon'
          src={ Company }
        />
        <span className='txt txt-sm pending-profile-card__info'>
          { pendingProfile?.companyName }
        </span>
      </div>
    );
  }, [ pendingProfile?.companyName, role ]);

  // The company position if it exists and role is ENDORSER
  const companyPosition = useMemo(() => {
    if (role !== Roles.ENDORSER || !pendingProfile?.companyPosition) {
      return null;
    }
    return (
      <div className='pending-profile-card__info-container'>
        <img
          alt='info-icon'
          className='pending-profile-card__info-icon'
          src={ Position }
        />
        <span className='txt txt-sm pending-profile-card__info'>
          { pendingProfile?.companyPosition }
        </span>
      </div>
    );
  }, [ pendingProfile?.companyPosition, role ]);

  // The confirmation message depending the role
  const confirmationMessage = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return t('administrators:dashboard.approve_companies_confirmation_message',
        { companyName: pendingProfile.companyName });
    }
    return t('administrators:dashboard.approve_endorsers_confirmation_message',
      { endorserName: fullName });
  }, [ fullName, pendingProfile.companyName, role, t ]);

  // The phone number
  const phoneNumberSection = useMemo(() => {
    if (!pendingProfile?.phoneNumber && !pendingProfile?.mobilePhoneNumber) {
      return null;
    }
    return (
      <a
        className='pending-profile-card__info-container link'
        href={ `tel:${ pendingProfile?.phoneNumber || pendingProfile?.mobilePhoneNumber }` }
      >
        <img
          alt='info-icon'
          className='pending-profile-card__info-icon'
          src={ Phone }
        />
        <span className='txt txt-sm pending-profile-card__info'>
          { pendingProfile?.phoneNumber || pendingProfile?.mobilePhoneNumber }
        </span>
      </a>
    );
  }, [ pendingProfile?.mobilePhoneNumber, pendingProfile?.phoneNumber ]);

  return (
    <div className='pending-profile-card dark'>
      <div className='pending-profile-card__container'>
        <p className='hdg hdg-sm pending-profile-card__title'>
          { cardTitle }
        </p>
        <div className='pending-profile-card__info-container'>
          <img
            alt='info-icon'
            className='pending-profile-card__info-icon'
            src={ Person }
          />
          <span className='txt txt-sm pending-profile-card__info'>
            { fullName }
          </span>
        </div>
        <a
          className='pending-profile-card__info-container link'
          href={ `mailto:${ pendingProfile.email }` }
        >
          <img
            alt='info-icon'
            className='pending-profile-card__info-icon'
            src={ Email }
          />
          <span className='txt txt-sm pending-profile-card__info'>
            { pendingProfile.email }
          </span>
        </a>
        { phoneNumberSection }
        { companyName }
        { companyPosition }
      </div>
      <Confirm
        onConfirm={ handleConfirmApprove }
        text={ confirmationMessage }
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-blue pending-profile-card__approval-button'
          >
            { t('administrators:dashboard.approve_button_title') }
          </button>
        }
      />
    </div>
  );
});

PendingCard.displayName = 'PendingCard';

PendingCard.propTypes = {
  // Function (userId) => void that is invoked on approval 'yes' clicked
  onApproval: PropTypes.func,
  // The profile to be rendered in the card
  pendingProfile: PropTypes.oneOfType([
    // pending company
    PropTypes.shape({
      // the UUID's array of company agent ids
      companyAgentIds: PropTypes.arrayOf(PropTypes.string),
      // the company name
      companyName: PropTypes.string,
      // The email of the company agent
      email: PropTypes.string,
      // The firstName of the company agent
      firstName: PropTypes.string,
      // The company id
      id: PropTypes.string,
      // The lastName of the company agent
      lastName: PropTypes.string,
      // phone number
      phoneNumber: PropTypes.string,
    }),
    // pending endorser
    PropTypes.shape({
      // the company name
      companyName: PropTypes.string,
      // The company position
      companyPosition: PropTypes.string,
      // The email of the endorser
      email: PropTypes.string,
      // The firstName of the endorser
      firstName: PropTypes.string,
      // The endorser id
      id: PropTypes.string,
      // The lastName of the endorser
      lastName: PropTypes.string,
      // The phone number
      mobilePhoneNumber: PropTypes.string,
    }),
  ]).isRequired,
  // The role of the section
  role: PropTypes.oneOf(roles).isRequired,
};

PendingCard.defaultProps = {};

export default PendingCard;
