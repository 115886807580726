/**
 * English translations for utilities/number-of-employees.
 *
 * @module languages/en/utilities/number-of-employees
 */
const numberOfEmployees = {
  LARGE: '251+',
  MEDIUM: '51-250',
  MICRO: '1-20',
  SMALL: '21-50',
};

export default numberOfEmployees;
