import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import PublicCompaniesCollectionSection
  from 'components/common/public-companies-collection-section/public-companies-collection-section';
import PublicGrowSocialImpactSection
  from 'components/common/public-grow-social-impact-section/public-grow-social-impact-section';
import PublicTestimonialsSection from 'components/common/public-testimonials-section/public-testimonials-section';
import { actions as requestActions } from 'ducks/requests';
import Endless from 'images/companies/endless.png';
import Eurolife from 'images/companies/eurolife.png';
import Karamolegkos from 'images/companies/karamolegkos.png';
import PiraeusBank from 'images/companies/piraeus-bank.png';
import Xatzigiannakis from 'images/companies/xatzigiannakis.png';
import PiraeusBankStory from 'images/impact-stories/Piraeus-Bank-Story.png';
import * as companiesMethods from 'resources/companies';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';
import * as CsrPricingPackages from 'utilities/csr-pricing-plans';

import SocialResponsibilityCsrPackagesSection
  from './social-responsibility-csr-packages-section/social-responsibility-csr-packages-section';
import SocialResponsibilityFirstSection
  from './social-responsibility-first-section/social-responsibility-first-section';
import SocialResponsibilitySdgSection from './social-responsibility-sdg-section/social-responsibility-sdg-section';

import './social-responsibility.scss';

const SocialResponsibility = () => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return auth?.id !== undefined;
  }, [ auth?.id ]);

  // The user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  // The images collection
  const imagesCollection = useMemo(() => {
    return [
      PiraeusBank,
      Xatzigiannakis,
      Endless,
      Karamolegkos,
      Eurolife,
    ];
  }, []);

  const showCsrPricingInterest = useCallback((csrPricingPackage) => {
    if (userRole !== Roles.COMPANY_AGENT) {
      toasts.error(t('social_responsibility:csr_packages_section.pricing_interest_different_role_error'));
    }
    dispatch(requestActions.request(companiesMethods.showCsrPricingInterest,
      {
        csrPricingPackage,
      },
      {
        onFailure: (_error) => {
          toasts.error(t('social_responsibility:csr_packages_section.failed_email'));
        },
        onSuccess: () => {
          toasts.info(t('social_responsibility:csr_packages_section.success_email'));
        },
      }));
  }, [ dispatch, t, userRole ]);

  // The packages collection
  const packagesCollection = useMemo(() => {
    return [
      {
        buttonLabel: t('social_responsibility:csr_packages_section.package_button_label'),
        csrPricingPackage: CsrPricingPackages.TAILOR_MADE_TRAINING,
        description: [
          t('social_responsibility:csr_packages_section.packages.item1.description.item1'),
          t('social_responsibility:csr_packages_section.packages.item1.description.item2'),
          t('social_responsibility:csr_packages_section.packages.item1.description.item3'),
        ],
        title: t('social_responsibility:csr_packages_section.packages.item1.title'),
      },
      {
        buttonLabel: t('social_responsibility:csr_packages_section.package_button_label'),
        csrPricingPackage: CsrPricingPackages.EMPLOYABILITY_SUPPORT,
        description: [
          t('social_responsibility:csr_packages_section.packages.item2.description.item1'),
          t('social_responsibility:csr_packages_section.packages.item2.description.item2'),
          t('social_responsibility:csr_packages_section.packages.item2.description.item3'),
        ],
        title: t('social_responsibility:csr_packages_section.packages.item2.title'),
      },
      {
        buttonLabel: t('social_responsibility:csr_packages_section.package_button_label'),
        csrPricingPackage: CsrPricingPackages.RELIEF_INTERVENTIONS,
        description: [
          t('social_responsibility:csr_packages_section.packages.item3.description.item1'),
          t('social_responsibility:csr_packages_section.packages.item3.description.item2'),
          t('social_responsibility:csr_packages_section.packages.item3.description.item3'),
        ],
        title: t('social_responsibility:csr_packages_section.packages.item3.title'),
      },
    ];
  }, [ t ]);

  // The testimonials collection
  const testimonialsCollection = useMemo(() => {
    return [
      [
        {
          ageLocation: t('social_responsibility:impact_stories_section.stories.item1.item1.age_location'),
          name: t('social_responsibility:impact_stories_section.stories.item1.item1.name'),
          title: t('social_responsibility:impact_stories_section.stories.item1.item1.title'),
          videoUrl: t('social_responsibility:impact_stories_section.stories.item1.item1.video_url'),
        },
        {
          ageLocation: t('social_responsibility:impact_stories_section.stories.item1.item2.age_location'),
          name: t('social_responsibility:impact_stories_section.stories.item1.item2.name'),
          title: t('social_responsibility:impact_stories_section.stories.item1.item2.title'),
          videoUrl: t('social_responsibility:impact_stories_section.stories.item1.item2.video_url'),
        },
        {
          backgroundPosition: 'top right',
          description: t('social_responsibility:impact_stories_section.stories.item1.item3.description'),
          imageUrl: PiraeusBankStory,
          name: t('social_responsibility:impact_stories_section.stories.item1.item3.name'),
          title: t('social_responsibility:impact_stories_section.stories.item1.item3.title'),
        },
      ],
    ];
  }, [ t ]);

  return (
    <div className='social-responsibility'>
      <SocialResponsibilityFirstSection
        description={ t('social_responsibility:first_section.description') }
        title={ t('social_responsibility:first_section.title') }
      />
      <PublicGrowSocialImpactSection
        info={ t('social_responsibility:grow_social_impact_section.info') }
        title={ t('social_responsibility:grow_social_impact_section.title') }
      />
      <PublicCompaniesCollectionSection
        imagesCollection={ imagesCollection }
        isSocialResponsibility
        title={ t('social_responsibility:companies_collection_section.title') }
      />
      <SocialResponsibilitySdgSection
        linkLabel={ t('social_responsibility:sdg_section.link_label') }
        title={ t('social_responsibility:sdg_section.title') }
      />
      <SocialResponsibilityCsrPackagesSection
        handleCsrPackageClick={ showCsrPricingInterest }
        packagesCollection={ packagesCollection }
        title={ t('social_responsibility:csr_packages_section.title') }
        userAuthenticated={ userAuthenticated }
        userRole={ userRole }
      />
      <PublicTestimonialsSection
        testimonials={ testimonialsCollection }
        title={ t('social_responsibility:impact_stories_section.title') }
      />
    </div>
  );
};

SocialResponsibility.propTypes = {};

SocialResponsibility.defaultProps = {};

export default SocialResponsibility;
