/**
 * Companies - Profile - ProfilePricingSection.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import LegalName from 'images/legalname.png';
import Address from 'images/location.png';
import Occupation from 'images/occupation.png';
import PricingPlan from 'images/pricingplan.png';
import TaxOffice from 'images/taxoffice.png';
import VatNumber from 'images/vatnumber.png';
import roles from 'utilities/auth/roles';
import * as CompaniesParts from 'utilities/companies/parts';
import pricingPlans from 'utilities/pricing-plans';

import ProfilePricingEditor from './profile-pricing-editor/profile-pricing-editor';

import './profile-pricing-section.scss';

const ProfilePricingSection = memo((props) => {
  const { profile, onChange, canEdit, userRole } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The pricing plan section
  const pricingPlan = useMemo(() => {
    if (!profile?.pricingPlan) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='pricing plan'
        boldTitle
        icon={ PricingPlan }
        textDisplayed={ `${ t('companies:profile_pricing_section.pricing_plan_label') }: ` }
        value={ t(`utilities:pricing_plans.${ profile.pricingPlan }`) }
      />
    );
  }, [ profile?.pricingPlan, t ]);

  // The legal name section
  const legalName = useMemo(() => {
    if (!profile?.legalName) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='legal name'
        boldTitle
        icon={ LegalName }
        textDisplayed={ `${ t('companies:profile_pricing_section.legal_name_label') }: ` }
        value={ profile.legalName }
      />
    );
  }, [ profile.legalName, t ]);

  // The occupation section
  const occupation = useMemo(() => {
    if (!profile?.occupation) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='occupation'
        boldTitle
        icon={ Occupation }
        textDisplayed={ `${ t('companies:profile_pricing_section.occupation_label') }: ` }
        value={ profile.occupation }
      />
    );
  }, [ profile?.occupation, t ]);

  // The address section
  const address = useMemo(() => {
    if (!profile?.address) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='address'
        boldTitle
        icon={ Address }
        textDisplayed={ `${ t('companies:profile_pricing_section.address_label') }: ` }
        value={ profile.address }
      />
    );
  }, [ profile?.address, t ]);

  // The vatNumber section
  const vatNumber = useMemo(() => {
    if (!profile?.vatNumber) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='vat number'
        boldTitle
        icon={ VatNumber }
        textDisplayed={ `${ t('companies:profile_pricing_section.vat_number_label') }: ` }
        value={ profile.vatNumber }
      />
    );
  }, [ profile?.vatNumber, t ]);

  // The tax office section
  const taxOffice = useMemo(() => {
    if (!profile?.taxOffice) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='tax office'
        boldTitle
        icon={ TaxOffice }
        textDisplayed={ `${ t('companies:profile_pricing_section.tax_office_label') }: ` }
        value={ profile.taxOffice }
      />
    );
  }, [ profile?.taxOffice, t ]);

  // function that handles the change on profile pricing details
  const onProfilePricingDetailsChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, CompaniesParts.PRICING, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfilePricingEditor
          onCancel={ toggleEditor(false) }
          onSave={ onProfilePricingDetailsChange }
          profile={ profile }
          userRole={ userRole }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onProfilePricingDetailsChange, profile, toggleEditor, userRole ]);

  return (
    <div className='profile-pricing-section'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('companies:profile_pricing_section.title') }
      />
      <div className='profile-pricing-section__fields'>
        { pricingPlan }
        { legalName }
        { occupation }
        { address }
        { vatNumber }
        { taxOffice }
      </div>
      { editorModal }
    </div>
  );
});

ProfilePricingSection.displayName = 'ProfilePricingSection';

ProfilePricingSection.propTypes = {
  // Whether the user can edit the pricing section
  canEdit: PropTypes.bool.isRequired,
  // The function ((Profile) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The profile of the company agent.
  profile: PropTypes.shape({
    // The company address
    address: PropTypes.string,
    // The company legal name
    legalName: PropTypes.string,
    // The company occupation
    occupation: PropTypes.string,
    // The company pricing plan
    pricingPlan: PropTypes.oneOf(pricingPlans),
    // The company tax office
    taxOffice: PropTypes.string,
    // The company tax office
    vatNumber: PropTypes.string,
  }),
  // The signed in user role
  userRole: PropTypes.oneOf(roles).isRequired,
};

export default ProfilePricingSection;
