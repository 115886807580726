/**
 * Greek translations for common/search-filters.
 *
 * @module languages/el/common/search-filters
 */
const searchFilters = {
  clear_all_button_label: 'Εκκαθάριση',
  filters: {
    computer_skills_level: {
      title: 'Δεξιότητες ΗΎ',
    },
    driving_license_category: {
      title: 'Δίπλωμα/τα οδήγησης',
    },
    experience_length: {
      title: 'Εμπειρία (σε χρόνια)',
    },
    language: {
      title: 'Γλώσσες',
    },
    number_of_employees: {
      title: 'Αριθμός υπαλλήλων',
    },
    profession: {
      title: 'Θέσεις εργασίας που σας ενδιαφέρουν',
    },
    profile_completion_percentage: {
      title: 'Ολοκλήρωση προφίλ (%)',
    },
    qualification_type: {
      title: 'Εκπαίδευση/Κατάρτιση',
    },
    total_endorsements_range: {
      title: 'Αριθμός συστάσεων',
    },
    willing_to_relocate: {
      title: 'Μετεγκατάσταση για εργασία',
    },
    work_availability: {
      title: 'Διαθεσιμότητα για εργασία',
    },
  },
  filters_button_label: 'Φίλτρα',
  title: 'Φίλτρα',
};

export default searchFilters;
