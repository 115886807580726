/**
 * Duck for profiles.
 *
 * @module ducks/profiles
 */

/**
 * The type of actions to describe that the profile was reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const PROFILE_RESET = 'PROFILE_RESET';

/**
 * The type of actions to describe that the profile was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const PROFILE_SET = 'PROFILE_SET';

/**
 * The type of actions to describe that the attribute allowedToRequestEndorsement attribute in the profile is set
 *
 * @constant {string}
 * @default
 * @static
 */
const PROFILE_SET_ALLOWED_TO_REQUEST_ENDORSEMENT = 'PROFILE_SET_ALLOWED_TO_REQUEST_ENDORSEMENT';

/**
 * Creates an action to reset the proofile.
 *
 * @returns {object} - The action.
 * @static
 */
const resetProfile = () => {
  return {
    type: PROFILE_RESET,
  };
};

/**
 * Creates an action to set the profile to the given profile.
 *
 * @param {module:types/job-seekers/profiles~Profile} profile - The profile.
 * @returns {object} - The action.
 * @static
 */
const setProfile = (profile) => {
  return {
    profile,
    type: PROFILE_SET,
  };
};

/**
 * Creates an action to set the allowedToRequestEndorsement attribute in the profile.
 *
 * @param {boolean} allowedToRequestEndorsement - if the user is allowed to request for an endorsement.
 * @returns {object} - The action.
 * @static
 */
const setAllowedToRequestEndorsement = (allowedToRequestEndorsement) => {
  return {
    allowedToRequestEndorsement,
    type: PROFILE_SET_ALLOWED_TO_REQUEST_ENDORSEMENT,
  };
};

/**
 * Profiles state.
 *
 * @typedef module:ducks/profiles~State
 * @type {object}
 * @property {module:types/job-seekers/profiles~Profile} [profile] - The profile of the signed-in user.
 */

/**
 * The initial profiles state.
 *
 * @type {module:ducks/profiles~State}
 * @static
 */
const initialState = {
  profile: undefined,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/profiles~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/profiles~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case PROFILE_RESET:
    return {
      ...initialState,
    };
  case PROFILE_SET:
    return {
      ...state,
      profile: action.profile,
    };
  case PROFILE_SET_ALLOWED_TO_REQUEST_ENDORSEMENT:
    return {
      ...state,
      profile: {
        ...state.profile,
        allowedToRequestEndorsement: action.allowedToRequestEndorsement,
      },
    };
  default:
    return state;
  }
};

const actions = {
  resetProfile,
  setAllowedToRequestEndorsement,
  setProfile,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
