/**
 * Resource method to get companies public profiles.
 *
 * @module resources/companies/get-public-profiles
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/companies/get-public-profiles~Params
 * @type {object}
 * @property {module:types/companies/searches~Criteria} [criteria] - The criteria.
 * @property {module:types/common~Page} page - The page to get.
 * @property {string} [searchId] - The ID of the search to further refine.
 * @property {module:types/common~Sort} sort - The way to sort.
 */

/**
 * Result.
 *
 * @typedef module:resources/companies/get-public-profiles~Result
 * @type {object}
 * @property {module:types/common~Meta} meta - The pagination metadata.
 * @property {module:types/companies/profiles~Profile[]} profiles - Some matching profiles.
 * @property {string} searchId - The ID of the search for further refinement.
 */

/**
 * Gets companies public profiles based the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/companies/get-public-profiles~Params} params - The parameters.
 * @returns {Promise<module:resources/companies/get-public-profiles~Result>} - The promise.
 * @static
 */
const getPublicProfiles = (params) => {
  const searchParams = new URLSearchParams();
  if (0 < (params.criteria.cities || []).length) {
    searchParams.append('criteria.cities', params.criteria.cities);
  }
  if (0 < (params.criteria.jobSectors || []).length) {
    searchParams.append('criteria.jobSectors', params.criteria.jobSectors);
  }
  searchParams.append('page.number', params.page.number);
  searchParams.append('page.size', params.page.size);
  if (undefined !== params.searchId) {
    searchParams.append('searchId', params.searchId);
  }
  searchParams.append('sort.direction', params.sort.direction);
  searchParams.append('sort.key', params.sort.key);
  return axios.request({
    method: 'get',
    params: searchParams,
    url: '/companies/profiles/public',
  }).then((response) => {
    return response.data;
  });
};

export default getPublicProfiles;
