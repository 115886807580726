/**
 * Duck for hashtags.
 *
 * @module ducks/hashtags
 */

/**
 * The type of actions to describe that the hashtags were set.
 *
 * @constant {string}
 * @default
 * @static
 */
const HASHTAGS_SET = 'HASHTAGS_SET';

/**
 * Creates an action to set the hashtags.
 *
 * @param {module:types/hashtags~Hashtag[]} hashtags - The hashtags.
 * @returns {object} - The action.
 * @static
 */
const setHashtags = (hashtags) => {
  return {
    hashtags,
    type: HASHTAGS_SET,
  };
};

/**
 * Job sectors state.
 *
 * @typedef module:ducks/hashtags~State
 * @type {object}
 * @property {module:types/hashtags~Hashtag[]} hashtags - The different hashtags.
 */

/**
 * The initial job sectors state.
 *
 * @type {module:ducks/hashtags~State}
 * @static
 */
const initialState = {
  hashtags: [],
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/hashtags~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/hashtags~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case HASHTAGS_SET:
    return {
      ...state,
      hashtags: action.hashtags,
    };
  default:
    return state;
  }
};

const actions = {
  setHashtags,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
