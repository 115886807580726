/**
 * Profile Details Section.
 */
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import Bars from 'images/bars.png';
import Cake from 'images/cake.png';
import Compass from 'images/compass.png';
import Email from 'images/email.png';
import Gender from 'images/gender.png';
import Location from 'images/location.png';
import Passport from 'images/passport.png';
import Website from 'images/share.png';
import Tel from 'images/tel.png';
import accessLevels, * as AccessLevels from 'utilities/access-levels';
import roles, * as Roles from 'utilities/auth/roles';
import { diff, isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import * as CompanyParts from 'utilities/companies/parts';
import countries from 'utilities/countries';
import * as EndorsersParts from 'utilities/endorsers/parts';
import genders from 'utilities/genders';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileDetailsEditor from './profile-details-section-editor/profile-details-section-editor';

import './profile-details-section.scss';

const ProfileDetailsSection = memo((props) => {
  const { profile, onChange, canEdit, role } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The name to be displayed on the details section
  const nameDisplayed = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return `${ profile?.firstName } ${ profile?.lastName }`;
    case Roles.COMPANY_AGENT:
      return profile?.companyName;
    default:
      return `${ profile?.firstName } ${ profile?.lastName }`;
    }
  }, [ profile?.companyName, profile?.firstName, profile?.lastName, role ]);

  // the displayed title
  const title = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return t('common:profile_details_section.job_seekers_title');
    case Roles.COMPANY_AGENT:
      return t('common:profile_details_section.companies_endorsers_title');
    default:
      return '';
    }
  }, [ role, t ]);

  // The strict access levels
  const strictAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The lean access levels
  const leanAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_1,
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The website field
  const companyWebsiteField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT || !profile?.website) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='Website'
        href={ profile.website }
        icon={ Website }
        textDisplayed={ t('common:profile_details_section.company_website_label') }
      />
    );
  }, [ profile?.website, role, t ]);

  // The location
  const locationDisplayString = useMemo(() => {
    let city = '';
    if (profile?.location?.city) {
      city = t(`utilities:cities.${ profile?.location.city }`);
    }
    return [
      profile?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ profile?.location?.city, profile?.location?.district, t ]);

  // the age count
  const ageCount = useMemo(() => {
    return parseInt(diff(profile?.bornOn, DateTime.now(), [ 'years' ])?.years);
  }, [ profile?.bornOn ]);

  // location section
  const locationSection = useMemo(() => {
    if (!profile?.location || role === Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='location'
        icon={ Location }
        textDisplayed={ locationDisplayString }
      />
    );
  }, [ locationDisplayString, profile?.location, role ]);

  // number of employees section
  const numberOfEmployeesSection = useMemo(() => {
    if (0 >= profile?.numberOfEmployees || role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='Number of Employees'
        icon={ Bars }
        textDisplayed={ `${ t('common:profile_details_section:number_of_employees_label') }:` }
        value={ profile.numberOfEmployees }
      />
    );
  }, [ profile.numberOfEmployees, role, t ]);

  // hires per year section
  const hiresPerYearSection = useMemo(() => {
    if (0 >= profile?.hiresPerYear || role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='Hires Per Year'
        icon={ Bars }
        textDisplayed={ `${ t('common:profile_details_section.hires_per_year_label') }:` }
        value={ profile.hiresPerYear }
      />
    );
  }, [ profile.hiresPerYear, role, t ]);

  // The email
  const emailSection = useMemo(() => {
    if (!profile?.email || role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='email'
        href={ `mailto:${ profile.email }` }
        icon={ Email }
        textDisplayed={ profile.email }
      />
    );
  }, [ profile.email, role ]);

  // The phone number section for the endorser
  const mobilePhoneNumberSection = useMemo(() => {
    if (!profile?.mobilePhoneNumber || role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='phone Number'
        href={ `tel:${ profile.mobilePhoneNumber }` }
        icon={ Tel }
        textDisplayed={ profile?.mobilePhoneNumber || '' }
      />
    );
  }, [ profile?.mobilePhoneNumber, role ]);

  // The gender displayed
  const gender = useMemo(() => {
    if (!leanAccessLevels.includes(profile?.accessLevel) || !profile?.gender || role === Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <ProfileInfoRow
        icon={ Gender }
        textDisplayed={ t(`utilities:genders.${ profile.gender }`) }
      />
    );
  }, [ leanAccessLevels, profile?.accessLevel, profile.gender, role, t ]);

  // The citizenship
  const citizenship = useMemo(() => {
    if (!strictAccessLevels.includes(profile?.accessLevel) || !profile?.citizenship || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='citizenship'
        icon={ Passport }
        textDisplayed={
          `${ t(`utilities:citizenships.${ profile.citizenship }`) } `
          + `${ t('common:profile_details_section.citizen') }`
        }
      />
    );
  }, [ profile?.accessLevel, profile.citizenship, role, strictAccessLevels, t ]);

  // The origin
  const origin = useMemo(() => {
    if (!strictAccessLevels.includes(profile?.accessLevel) || !profile?.origin?.country || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='origin'
        icon={ Compass }
        textDisplayed={ t(`utilities:countries.${ profile?.origin?.country }`) }
      />
    );
  }, [ profile?.accessLevel, profile?.origin?.country, role, strictAccessLevels, t ]);

  // The age
  const age = useMemo(() => {
    if (!leanAccessLevels.includes(profile?.accessLevel) || !profile?.bornOn || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='age'
        icon={ Cake }
        textDisplayed={ t('common:profile_details_section.years_old', { count: ageCount }) }
      />
    );
  }, [ ageCount, leanAccessLevels, profile?.accessLevel, profile?.bornOn, role, t ]);

  // Whether to render the section based on role
  const sectionIsToBeRendered = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return (profile?.gender || profile?.location || profile?.citizenship || profile?.origin || profile?.bornOn);
    case Roles.COMPANY_AGENT:
      return (profile?.website || profile?.location || 0 <= profile?.numberOfEmployees || 0 <= profile?.hiresPerYear);
    case Roles.ENDORSER:
      return (profile?.gender || profile?.mobilePhoneNumber || profile?.email);
    default:
      return (profile?.gender || profile?.location || profile?.citizenship || profile?.origin || profile?.bornOn);
    }
  }, [
    profile?.bornOn,
    profile?.citizenship,
    profile?.email,
    profile?.gender,
    profile?.hiresPerYear,
    profile?.location,
    profile?.mobilePhoneNumber,
    profile?.numberOfEmployees,
    profile?.origin,
    profile?.website,
    role,
  ]);

  // The part to be updated
  const partToBeUpdated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekerParts.PERSONAL;
    case Roles.COMPANY_AGENT:
      return CompanyParts.DETAILS;
    case Roles.ENDORSER:
      return EndorsersParts.PERSONAL;
    default:
      return '';
    }
  }, [ role ]);

  // function that handles when the profile details are changed
  const onProfileDetailsChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, partToBeUpdated, isToBeUpdated);
  }, [ onChange, partToBeUpdated ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileDetailsEditor
          onCancel={ toggleEditor(false) }
          onSave={ onProfileDetailsChange }
          profile={ profile }
          role={ role }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onProfileDetailsChange, profile, role, toggleEditor ]);

  // The main section
  const mainSection = useMemo(() => {
    if (!sectionIsToBeRendered && !canEdit) {
      return null;
    }
    return (
      <>
        <ProfileInfoRow
          isEditable={ canEdit }
          isTitle
          onToggleEditor={ toggleEditor }
          textDisplayed={ title }
        />
        <div className='profile-details-section__fields'>
          { gender }
          { emailSection }
          { mobilePhoneNumberSection }
          { companyWebsiteField }
          { locationSection }
          { citizenship }
          { origin }
          { age }
          { numberOfEmployeesSection }
          { hiresPerYearSection }
        </div>
        { editorModal }
      </>
    );
  }, [
    age,
    canEdit,
    citizenship,
    companyWebsiteField,
    editorModal,
    emailSection,
    gender,
    hiresPerYearSection,
    locationSection,
    mobilePhoneNumberSection,
    numberOfEmployeesSection,
    origin,
    sectionIsToBeRendered,
    title,
    toggleEditor,
  ]);

  return (
    <div className='profile-details-section dark'>
      <p className='profile-details-section__displayed-name hdg hdg-md'>
        { nameDisplayed }
      </p>
      { mainSection }
    </div>
  );
});

ProfileDetailsSection.displayName = 'ProfileDetailsSection';

ProfileDetailsSection.propTypes = {
  // Whether the user can edit the details section
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func.isRequired,
  // the user's profile
  profile: PropTypes.oneOfType([
    //job seeker profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The date when the job seeker was born.
      bornOn: PropTypes.string,
      // The ISO 3166-1 alpha-3 code of the country of citizenship of the job seeker.
      citizenship: PropTypes.oneOf(countries),
      // The first name of the job seeker.
      firstName: PropTypes.string,
      // The gender of the job seeker.
      gender: PropTypes.oneOf(genders),
      // The last name of the job seeker.
      lastName: PropTypes.string,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // Where the job seeker comes from.
      origin: PropTypes.shape({
        // The ISO 3166-1 alpha-3 code of the country.
        country: PropTypes.oneOf(countries),
      }),
    }),
    // company profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The name of the company.
      companyName: PropTypes.string,
      // The hires per year of employees of the company.
      hiresPerYear: PropTypes.number,
      // The location where the company is.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The number of employees of the company.
      numberOfEmployees: PropTypes.number,
      // The website of the company.
      website: PropTypes.string,
    }),
    // endorser profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The email of the endorser
      email: PropTypes.string,
      // The first name of the job seeker.
      firstName: PropTypes.string,
      // The gender of the job seeker.
      gender: PropTypes.oneOf(genders),
      // The last name of the job seeker.
      lastName: PropTypes.string,
      // The phone number of the endorser.
      mobilePhoneNumber: PropTypes.string,
    }),
  ]),
  // the role of the profile based on route
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileDetailsSection.defaultProps = {};

export default ProfileDetailsSection;
