/**
 * English translations for the endorsers.
 *
 * @module languages/en/endorsers
 */
import dashboardEndorsementsSection from './dashboard-endorsements-section';
import endorsementCard from './endorsement-card';
import learnMore from './learn-more';
import profileCompletedEndorsements from './profile-completed-endorsements';
import profileProfessionalDetails from './profile-professional-details';
import searchResultCard from './search-result-card';
import steps from './steps';

const endorsers = {
  dashboard_endorsements_section: dashboardEndorsementsSection,
  endorsement_card: endorsementCard,
  learn_more: learnMore,
  profile_completed_endorsements: profileCompletedEndorsements,
  profile_professional_details: profileProfessionalDetails,
  search_result_card: searchResultCard,
  steps,
};

export default endorsers;
