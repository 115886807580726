import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import CompanyName from 'images/legalname.png';
import LinkedIn from 'images/linkedin-black.png';
import Location from 'images/location.png';
import Position from 'images/position.png';
import Website from 'images/share.png';
import Tel from 'images/tel.png';
import { isNotEmpty } from 'utilities/chisels';
import allCities from 'utilities/cities';
import * as EndorsersParts from 'utilities/endorsers/parts';

import ProfileProfessionalDetailsEditor
  from './profile-professional-details-editor/profile-professional-details-editor';

import './profile-professional-details.scss';

const ProfileProfessionalDetails = memo((props) => {
  const { profile, onChange, canEdit } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The company name field
  const companyNameField = useMemo(() => {
    if (!profile?.companyName) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company name'
        icon={ CompanyName }
        textDisplayed={ profile?.companyName }
      />
    );
  }, [ profile?.companyName ]);

  // The company position field
  const companyPositionField = useMemo(() => {
    if (!profile?.companyPosition) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='company position'
        icon={ Position }
        textDisplayed={ profile?.companyPosition }
      />
    );
  }, [ profile?.companyPosition ]);

  // The company's website
  const companyWebsite = useMemo(() => {
    if (!profile?.website) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='Website'
        href={ profile?.website }
        icon={ Website }
        textDisplayed={ t('endorsers:profile_professional_details:company_website_text_label') }
      />
    );
  }, [ profile?.website, t ]);

  // The phone number section
  const phoneNumberSection = useMemo(() => {
    if (!profile?.phoneNumber) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='phone Number'
        href={ `tel:${ profile?.phoneNumber }` }
        icon={ Tel }
        textDisplayed={ profile?.phoneNumber || '' }
      />
    );
  }, [ profile?.phoneNumber ]);

  const locationDisplayString = useMemo(() => {
    let city = '';
    if (profile?.location?.city) {
      city = t(`utilities:cities.${ profile?.location.city }`);
    }
    return [
      profile?.location?.district,
      city,
    ].filter(isNotEmpty).join(', ');
  }, [ profile?.location?.city, profile?.location?.district, t ]);

  // location section
  const locationSection = useMemo(() => {
    if (!profile?.location) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='location'
        icon={ Location }
        textDisplayed={ locationDisplayString }
      />
    );
  }, [ locationDisplayString, profile?.location ]);

  // the linked in url
  const linkedInProfileUrlSection = useMemo(() => {
    if (!profile?.linkedinUrl) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='linkedIn url'
        href={ profile?.linkedinUrl }
        icon={ LinkedIn }
        textDisplayed={ t('endorsers:profile_professional_details:linkedin_profile') }
      />
    );
  }, [ profile?.linkedinUrl, t ]);

  // function that handles when profile professional details are changed
  const onProfileProfessionalDetailsChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, EndorsersParts.PROFESSIONAL, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileProfessionalDetailsEditor
          onCancel={ toggleEditor(false) }
          onSave={ onProfileProfessionalDetailsChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onProfileProfessionalDetailsChange, profile, toggleEditor ]);

  return (
    <div className='profile-professional-section'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('endorsers:profile_professional_details.title') }
      />
      <div className='profile-professional-section__fields'>
        { companyNameField }
        { companyPositionField }
        { companyWebsite }
        { phoneNumberSection }
        { locationSection }
        { linkedInProfileUrlSection }
      </div>
      { editorModal }
    </div>
  );
});

ProfileProfessionalDetails.displayName = 'ProfileProfessionalDetails';

ProfileProfessionalDetails.propTypes = {
  // Whether the user can edit the pricing section
  canEdit: PropTypes.bool.isRequired,
  // The function ((Profile) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The profile of the endorser.
  profile: PropTypes.shape({
    // the company name
    companyName: PropTypes.string,
    // The company position
    companyPosition: PropTypes.string,
    // The linked in url
    linkedinUrl: PropTypes.string,
    // The location where the endorser is.
    location: PropTypes.shape({
      // The city.
      city: PropTypes.oneOf(allCities),
      // The district.
      district: PropTypes.string,
    }),
    // the professional phone number
    phoneNumber: PropTypes.string,
    // the company's website
    website: PropTypes.string,
  }),
};

ProfileProfessionalDetails.defaultProps = {};

export default ProfileProfessionalDetails;
