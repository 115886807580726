/**
 * CSR Pricing Packages.
 *
 * @module utilities/csr-pricing-plans
 */

/**
 * The tailor made training plan.
 *
 * @constant {string}
 * @static
 */
export const TAILOR_MADE_TRAINING = 'TAILOR_MADE_TRAINING';

/**
 * The Employability support plan.
 *
 * @constant {string}
 * @static
 */
export const EMPLOYABILITY_SUPPORT = 'EMPLOYABILITY_SUPPORT';

/**
 * The relief interventions plan.
 *
 * @constant {string}
 * @static
 */
export const RELIEF_INTERVENTIONS = 'RELIEF_INTERVENTIONS';

export default [
  TAILOR_MADE_TRAINING,
  EMPLOYABILITY_SUPPORT,
  RELIEF_INTERVENTIONS,
];
