/**
 * English translations for utilities/companies/sort-keys.
 *
 * @module languages/en/utilities/companies/sort-keys
 */
const sortKeys = {
  COMPANY_NAME: 'Company name',
  LAST_MODIFIED_AT: 'Last updated',
};

export default sortKeys;
