/**
 * Guests - Header.
 */
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import PopupMenu from 'components/common/popup-menu/popup-menu';
import { actions as settingsActions } from 'ducks/settings';
import { LANGUAGES } from 'i18n';
import burger from 'images/burger.png';
import caret from 'images/caret-down.png';
import el from 'images/languages/el.png';
import en from 'images/languages/en.png';

import './guests-header.scss';

const GuestsHeader = memo((_props) => {
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  // The selected language.
  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // The icons for all supported languages.
  const icons = useMemo(() => {
    return {
      'el': el,
      'en': en,
    };
  }, []);

  // The languages options
  const languagesOptions = useMemo(() => {
    return LANGUAGES.map((language, index) => {
      return (
        <div
          className='available-language'
          key={ index }
          onClick={
            () => {
              dispatch(settingsActions.setLanguage(language));
            }
          }
          onKeyPress={ () => {} }
          role='button'
          tabIndex={ -1 }
        >
          { t(`utilities:languages.${ language }`) }
          <img alt='Flag' src={ icons[language] } />
        </div>
      );
    });
  }, [ dispatch, icons, t ]);

  // The languages popup
  const languagesPopup = useMemo(() => {
    if (!selectedLanguage) {
      return null;
    }
    return (
      <PopupMenu
        className='guests-header-languages-popupmenu'
        offsetY={ 20 }
        position='bottom right'
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-trans selected-language'
            type='button'
          >
            <img
              alt={ `${ selectedLanguage?.toUpperCase() } Flag` }
              className='flag'
              src={ icons[selectedLanguage] }
            />
            <span className='name'>{ selectedLanguage?.toUpperCase() }</span>
            <img alt='Caret' src={ caret } />
          </button>
        }
      >
        { languagesOptions }
      </PopupMenu>
    );
  }, [ icons, languagesOptions, selectedLanguage ]);

  return (
    <header className='ody-header guests'>
      <div className='brand'>
        <Link to='/home'>odyssea</Link>
      </div>
      <div className='links hidden-xs hidden-sm hidden-md'>
        <PopupMenu
          className='header-popupmenu'
          offsetY={ 20 }
          position='bottom center'
          trigger={
            <button
              className='btn-link'
              type='button'
            >
              { t('header:find_work_menu.title') }
            </button>
          }
        >
          <Link className='link' to='/job-seekers/learn-more'>{ t('header:find_work_menu.items.item1') }</Link>
          <Link className='link' to='/job-seekers/take-assessment'>{ t('header:find_work_menu.items.item2') }</Link>
          <Link className='link' to='/companies/search'>{ t('header:find_work_menu.items.item3') }</Link>
        </PopupMenu>
        <PopupMenu
          className='header-popupmenu'
          offsetY={ 20 }
          position='bottom center'
          trigger={
            <button
              className='btn-link'
              type='button'
            >
              { t('header:find_talent_menu.title') }
            </button>
          }
        >
          <Link className='link' to='/companies/learn-more'>{ t('header:find_talent_menu.items.item1') }</Link>
          <Link className='link' to='/endorsers/learn-more'>{ t('header:find_talent_menu.items.item2') }</Link>
          <Link className='link' to='/social-responsibility'>{ t('header:find_talent_menu.items.item3') }</Link>
          <Link className='link' to='/job-seekers/search'>{ t('header:find_talent_menu.items.item4') }</Link>
        </PopupMenu>
        <Link
          className='link'
          to='/about-us'
        >
          { t('header:about_us_link_text') }
        </Link>
      </div>
      <div className='buttons-and-language hidden-xs hidden-sm hidden-md'>
        <div className='buttons'>
          <Link to='/sign-in'>
            <button
              className='btn btn-custom btn-rounded-sm btn-white'
              type='button'
            >
              { t('header:sign_in_button_label') }
            </button>
          </Link>
          <Link to='/job-seekers/sign-up'>
            <button
              className='btn btn-custom btn-rounded-sm btn-blue'
              type='button'
            >
              { t('header:sign_up_button_label') }
            </button>
          </Link>
        </div>
        <div className='language'>
          { languagesPopup }
        </div>
      </div>
      <div className='buttons-links-and-language hidden-lg'>
        <div className='buttons'>
          <Link to='/sign-in'>
            <button
              className='btn btn-custom btn-rounded-sm btn-white'
              type='button'
            >
              { t('header:sign_in_button_label') }
            </button>
          </Link>
          <Link to='/job-seekers/sign-up'>
            <button
              className='btn btn-custom btn-rounded-sm btn-blue'
              type='button'
            >
              { t('header:sign_up_button_label') }
            </button>
          </Link>
        </div>
        <PopupMenu
          className='guests-header-mobile-popupmenu'
          offsetY={ 0 }
          position='bottom right'
          trigger={
            <button
              className='btn btn-trans'
              id='trigger-mobile-popup-menu'
              name='trigger popup menu'
              type='button'
            >
              <img alt='Burger' src={ burger } />
            </button>
          }
        >
          <div className='links'>
            <ul role='menu'>
              <li role='menuitem'>
                { t('header:find_work_menu.title') }
                <ul role='menu'>
                  <li role='menuitem'>
                    <Link className='link' to='/job-seekers/learn-more'>
                      { t('header:find_work_menu.items.item1') }
                    </Link>
                  </li>
                  <li role='menuitem'>
                    <Link className='link' to='/job-seekers/take-assessment'>
                      { t('header:find_work_menu.items.item2') }
                    </Link>
                  </li>
                  <li role='menuitem'>
                    <Link className='link' to='/companies/search'>
                      { t('header:find_work_menu.items.item3') }
                    </Link>
                  </li>
                </ul>
              </li>
              <li role='menuitem'>
                { t('header:find_talent_menu.title') }
                <ul role='menu'>
                  <li role='menuitem'>
                    <Link className='link' to='/companies/learn-more'>
                      { t('header:find_talent_menu.items.item1') }
                    </Link>
                  </li>
                  <li role='menuitem'>
                    <Link className='link' to='/endorsers/learn-more'>
                      { t('header:find_talent_menu.items.item2') }
                    </Link>
                  </li>
                  <li role='menuitem'>
                    <Link className='link' to='/social-responsibility'>
                      { t('header:find_talent_menu.items.item3') }
                    </Link>
                  </li>
                  <li role='menuitem'>
                    <Link className='link' to='/job-seekers/search'>
                      { t('header:find_talent_menu.items.item4') }
                    </Link>
                  </li>
                </ul>
              </li>
              <li role='menuitem'>
                <Link
                  className='link'
                  to='/about-us'
                >
                  { t('header:about_us_link_text') }
                </Link>
              </li>
            </ul>
          </div>
          <div className='divider' />
          <div className='languages'>
            { languagesOptions }
          </div>
        </PopupMenu>
      </div>
    </header>
  );
});

GuestsHeader.displayName = 'GuestsHeader';

GuestsHeader.propTypes = {
};

GuestsHeader.defaultProps = {
};

export default GuestsHeader;
