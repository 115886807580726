/**
 * English translations for footer.
 *
 * @module languages/en/footer
 */
const footer = {
  about_us_link_text: 'About us',
  privacy_policy_link_text: 'Privacy Policy',
  terms_of_service_link_text: 'Terms of Service',
};

export default footer;
