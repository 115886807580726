/**
 * Greek translations for endorsers/steps.
 *
 * @module languages/el/endorsers/steps
 */
const steps = {
  ABOUT_ME: 'Υποβάλετε μια σύντομη περιγραφή για εσάς',
  ENDORSEMENTS: 'Ολοκληρώστε την πρώτη σας σύσταση',
  PERSONAL: 'Υποβάλετε τα προσωπικά σας στοιχεία',
  PROFESSIONAL: 'Υποβάλετε τα επαγγελματικά σας στοιχεία',
  PROFILE_PICTURE: 'Ανεβάστε την εικόνα του προφίλ σας',
};

export default steps;
