/**
 * Sort directions.
 *
 * @module utilities/sort-directions
 */

/**
 * Ascending.
 *
 * @constant {string}
 * @static
 */
export const ASC = 'ASC';

/**
 * Descending.
 *
 * @constant {string}
 * @static
 */
export const DESC = 'DESC';

/**
 * The default sort direction.
 *
 * @constant {string}
 * @default
 * @static
 */
export const DEFAULT_SORT_DIRECTION = DESC;

export default [
  ASC,
  DESC,
];
