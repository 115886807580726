/**
 * English translations for utilities/endorsers/filter-types.
 *
 * @module languages/en/utilities/endorsers/filter-types
 */
const filterTypes = {
  TOTAL_ENDORSEMENTS_RANGE: 'Total endorsements range',
};

export default filterTypes;
