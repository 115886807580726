import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import './image-testimonial.scss';

const ImageTestimonial = memo((props) => {
  const { ageLocation, backgroundPosition, description, name, imageUrl, title } = props;

  // The image styling
  const imageStyling = useMemo(() => {
    return `linear-gradient(
    180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.301) 75.52%, rgba(0, 0, 0, 0.7) 100%), url(${ imageUrl })`;
  }, [ imageUrl ]);

  // The image styles
  const imageStyles = useMemo(() => {
    return {
      background: imageStyling,
      backgroundPosition: backgroundPosition ? backgroundPosition : 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }, [ backgroundPosition, imageStyling ]);

  // The rendered age location section
  const renderedAgeLocation = useMemo(() => {
    if (!ageLocation) {
      return null;
    }
    return (
      <p className='txt txt-md image-testimonial__age-location'>
        { ageLocation }
      </p>
    );
  }, [ ageLocation ]);

  return (
    <div className='image-testimonial'>
      <div className='image-testimonial__info-content'>
        <p className='image-testimonial__quote-mark'>
          “
        </p>
        <h5 className='txt txt-lg txt-bold image-testimonial__title'>
          { title }
        </h5>
        <p className='txt txt-md image-testimonial__description'>
          { description }
        </p>
      </div>
      <div
        className='image-testimonial__image'
        style={ imageStyles }
      >
        <div className='image-testimonial__image-info'>
          <p className='txt txt-md light txt-bold image-testimonial__name'>
            { name }
          </p>
          { renderedAgeLocation }
        </div>
      </div>
    </div>
  );
});

ImageTestimonial.displayName = 'ImageTestimonial';

ImageTestimonial.propTypes = {
  // the age location
  ageLocation: PropTypes.string,
  // the image backgroundPosition
  backgroundPosition: PropTypes.string,
  // The description of the testimonial
  description: PropTypes.string.isRequired,
  // The image url of the testimonial
  imageUrl: PropTypes.string.isRequired,
  // the name of the person of the testimonial
  name: PropTypes.string.isRequired,
  // The title of the testimonial
  title: PropTypes.string.isRequired,
};

ImageTestimonial.defaultProps = {
  ageLocation: '',
  backgroundPosition: '',
};

export default ImageTestimonial;
