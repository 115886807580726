/**
 * Forgot-password page.
 */
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import env from 'config/env';
import { actions as requestsActions } from 'ducks/requests';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import * as authMethods from 'resources/auth';
import * as toasts from 'toasts';
import { isNotEmpty } from 'utilities/chisels';
import { forgotPasswordSchemaValidator } from 'utilities/validators';

import './forgot-password.scss';

const ForgotPassword = (_props) => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return undefined !== auth?.id;
  }, [ auth?.id ]);

  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  // Whether the password reset request was sent.
  const [ sent, setSent ] = useState(false);
  // Whether the recaptcha is successfully submitted
  const [ recaptchaSubmitted, setRecaptchaSubmitted ] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useFormWithSchema(forgotPasswordSchemaValidator);

  useEffect(() => {
    // Reset the recaptcha every time the form is rendering
    recaptchaRef.current?.reset();
  }, []);

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // function that sets the forgot password error
  const setForgotPasswordError = useCallback((error) => {
    switch (error.message) {
    case 'LOCKED_ACCOUNT':
      toasts.error(t('forgot_password:locked_account_error'));
      break;
    case 'NEW_ACCOUNT':
      toasts.error(t('forgot_password:new_account_error'));
      break;
    case 'REJECTED_ACCOUNT':
      toasts.error(t('forgot_password:rejected_account_error'));
      break;
    case 'SUSPENDED_ACCOUNT':
      toasts.error(t('forgot_password:suspended_account_error'));
      break;
    case 'VERIFIED_ACCOUNT':
      toasts.error(t('forgot_password:verified_account_error'));
      break;
    case 'USER_NOT_FOUND':
      toasts.error(t('forgot_password:user_not_found_error'));
      break;
    default:
      toasts.error(t('forgot_password:general_error'));
      break;
    }
  }, [ t ]);

  // Function that requests a password reset based on the given values.
  const requestPasswordReset = useCallback((values) => {
    if (!recaptchaSubmitted) {
      return;
    }
    dispatch(requestsActions.request(authMethods.requestPasswordReset, {
      ...values,
      email: values.email.toLowerCase(),
    }, {
      onFailure: (error) => {
        // Sets the error
        setForgotPasswordError(error);
        // resets the email
        setValue('email', '');
        // sets the boolean sent to the state
        setSent(false);
        // manually trigger the email validation
        trigger('email');
      },
      onSuccess: (_result) => {
        setSent(true);
      },
    }));
  }, [ dispatch, recaptchaSubmitted, setForgotPasswordError, setValue, trigger ]);

  // The empty string email error
  const emptyStringEmailError = useMemo(() => {
    if ('string.empty' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('forgot_password:email_required_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // The wrong pattern email error
  const wrongPatternEmailError = useMemo(() => {
    if ('string.email' !== errors.email?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('forgot_password:email_invalid_error') }
      </span>
    );
  }, [ errors.email?.type, t ]);

  // function that is invoked when user has successfully added recaptcha
  const handleRecaptchaChange = useCallback((token) => {
    if (!token) {
      return;
    }
    setRecaptchaSubmitted(true);
  }, []);

  // function that is invoked when recaptcha expires
  const handleRecaptchaExpire = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // function that is invoked when recaptcha has error
  const handleRecaptchaError = useCallback(() => {
    setRecaptchaSubmitted(false);
  }, []);

  // Whether to render the sent message or the content of the form
  const renderedContent = useMemo(() => {
    if (sent) {
      return (
        <p className='ody-forgot-password__sent txt txt-sm'>
          { t('forgot_password:sent_message') }
        </p>
      );
    }
    return (
      <>
        <h1
          className='ody-forgot-password__form-title hdg hdg-xl'
        >
          { t('forgot_password:title') }
        </h1>
        <p className='txt txt-sm'>
          { t('forgot_password:instructions') }
        </p>
        <form
          className='ody-forgot-password__form'
          onSubmit={ handleSubmit(requestPasswordReset) }
        >
          <div className='ody-forgot-password__form-control'>
            <label htmlFor='email' />
            <input
              className={ clsx(errors.email && 'error') }
              defaultValue=''
              id='email'
              maxLength={ 256 }
              name='email'
              placeholder={ t('forgot_password:email_placeholder') }
              type='text'
              { ...register('email') }
            />
            { emptyStringEmailError }
            { wrongPatternEmailError }
          </div>
          <div className='ody-forgot-password__form-control'>
            <ReCAPTCHA
              hl={ selectedLanguage }
              onChange={ handleRecaptchaChange }
              onErrored={ handleRecaptchaError }
              onExpired={ handleRecaptchaExpire }
              ref={ recaptchaRef }
              sitekey={ env.RECAPTCHA_KEY }
              size='normal'
            />
          </div>
          <button
            className='ody-forgot-password__submit-button btn btn-custom btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) || !recaptchaSubmitted }
            id='submit-forgot-password'
            name='submit forgot password'
            type='submit'
          >
            { t('forgot_password:reset_password_button_label') }
          </button>
        </form>
      </>
    );
  }, [
    emptyStringEmailError,
    errors,
    handleRecaptchaChange,
    handleRecaptchaError,
    handleRecaptchaExpire,
    handleSubmit,
    recaptchaSubmitted,
    register,
    requestPasswordReset,
    selectedLanguage,
    sent,
    t,
    wrongPatternEmailError,
  ]);

  return useMemo(() => {
    if (userAuthenticated) {
      return (
        <Navigate replace to='/dashboard' />
      );
    }
    return (
      <div className='ody-forgot-password'>
        <div className='ody-forgot-password__form-wrapper'>
          { renderedContent }
        </div>
        <div className='ody-forgot-password__logo'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [
    renderedContent,
    userAuthenticated,
  ]);
};

ForgotPassword.propTypes = {
};

ForgotPassword.defaultProps = {
};

export default ForgotPassword;
