import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import EyesAnimationIcon from 'animations/eyes.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';

import './learn-more-light-red-section.scss';

const LearnMoreLightRedSection = memo((props) => {
  const { seeTalent, title, description, buttonLabel } = props;

  // The rendered button
  const renderedButton = useMemo(() => {
    if (!seeTalent) {
      return null;
    }
    return (
      <Link
        className='btn btn-white btn-sm btn-rounded-sm learn-more-light-red-section__button'
        to='/job-seekers/search'
      >
        { buttonLabel }
      </Link>
    );
  }, [ buttonLabel, seeTalent ]);

  return (
    <div className='learn-more-light-red-section'>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-light-red-section__animated-icon'
        animationData={ EyesAnimationIcon }
        className='learn-more-light-red-section__animation-container'
      />
      <div className='learn-more-light-red-section__content-container'>
        <h2 className='hdg hdg-xxl learn-more-light-red-section__title'>
          { title }
        </h2>
        <p className='txt txt-md learn-more-light-red-section__description'>
          { description }
        </p>
        { renderedButton }
      </div>
    </div>
  );
});

LearnMoreLightRedSection.displayName = 'LearnMoreLightRedSection';

LearnMoreLightRedSection.propTypes = {
  // The button label if the section is to see a job seeker talent
  buttonLabel: PropTypes.string,
  // The description of the section
  description: PropTypes.string.isRequired,
  // Whether the section is to see a job seeker talent
  seeTalent: PropTypes.bool,
  // The title of the section
  title: PropTypes.string.isRequired,
};

LearnMoreLightRedSection.defaultProps = {
  buttonLabel: '',
  seeTalent: false,
};

export default LearnMoreLightRedSection;
