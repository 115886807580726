/* eslint-disable */
import React, { memo } from 'react';

import './arrow-up-icon.scss';

const ArrowUpIcon = memo(() => {
  return (
    <svg
      className='arrow-up-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 26 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.75 14.5215C23.2578 14.5215 22.8203 14.3574 22.4922 14.0293L13.25 4.78711L3.95312 14.0293C3.29688 14.7402 2.14844 14.7402 1.49219 14.0293C0.78125 13.373 0.78125 12.2246 1.49219 11.5684L11.9922 1.06836C12.6484 0.357422 13.7969 0.357422 14.4531 1.06836L24.9531 11.5684C25.6641 12.2246 25.6641 13.373 24.9531 14.0293C24.625 14.3574 24.1875 14.5215 23.75 14.5215Z'
        fill='black'
      />
    </svg>
  );
});

ArrowUpIcon.displayName = 'ArrowUpIcon';

ArrowUpIcon.propTypes = {};

ArrowUpIcon.defaultProps = {};

export default ArrowUpIcon;
