import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './about-us-mission-vision-section.scss';

const MissionVisionSection = memo((props) => {
  const { title, missionTitle, missionDescription, visionTitle, visionDescription } = props;

  return (
    <div className='about-us-mission-vision-section'>
      <h3 className='hdg hdg-lg about-us-mission-vision-section__title'>
        { title }
      </h3>
      <div className='about-us-mission-vision-section__info-container'>
        <div className='about-us-mission-vision-section__content'>
          <h2 className='hdg hdg-xxl about-us-mission-vision-section__mission-vision-title'>
            { visionTitle }
          </h2>
          <p className='txt txt-md about-us-mission-vision-section__description'>
            { visionDescription }
          </p>
        </div>
        <div className='about-us-mission-vision-section__content'>
          <h2 className='hdg hdg-xxl about-us-mission-vision-section__mission-vision-title'>
            { missionTitle }
          </h2>
          <p className='txt txt-md about-us-mission-vision-section__description'>
            { missionDescription }
          </p>
        </div>
      </div>
    </div>
  );
});

MissionVisionSection.displayName = 'MissionVisionSection';

MissionVisionSection.propTypes = {
  // The mission description
  missionDescription: PropTypes.string.isRequired,
  // The mission title
  missionTitle: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
  // The vision description
  visionDescription: PropTypes.string.isRequired,
  // The vision title
  visionTitle: PropTypes.string.isRequired,
};

MissionVisionSection.defaultProps = {};

export default MissionVisionSection;
