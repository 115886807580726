/**
 * Profile background Image
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';

import Modal from 'components/common/modal/modal';
import edit from 'images/edit.png';
import roles, * as Roles from 'utilities/auth/roles';
import * as CompanyParts from 'utilities/companies/parts';
import * as EndorsersParts from 'utilities/endorsers/parts';
import * as JobSeekerParts from 'utilities/job-seekers/parts';

import ProfileBackgroundImageEditor from './profile-background-image-editor/profile-background-image-editor';
import backgroundImages from './profile-background-images';
import './profile-background-image.scss';

const ProfileBackgroundImage = memo((props) => {
  const { onBackgroundImageChange, canEdit, profile, role } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  // The background image index of the signed-in user
  const backgroundImage = useMemo(() => {
    return profile?.backgroundImage;
  }, [ profile?.backgroundImage ]);

  // function that handles the editor open and close state
  const handleEditorOpen = useCallback((open) => {
    return (_event) => {
      setEditorOpen(open);
    };
  }, []);

  // The edit button
  const editButton = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <button
        className='edit-button btn btn-trans'
        onClick={ handleEditorOpen(true) }
      >
        <img alt='Edit' src={ edit } />
      </button>
    );
  }, [ canEdit, handleEditorOpen ]);

  // The part to be updated
  const partToBeUpdated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekerParts.BACKGROUND_IMAGE;
    case Roles.COMPANY_AGENT:
      return CompanyParts.BACKGROUND_IMAGE;
    case Roles.ENDORSER:
      return EndorsersParts.BACKGROUND_IMAGE;
    default:
      return JobSeekerParts.BACKGROUND_IMAGE;
    }
  }, [ role ]);

  // Function that handles the save when the background image editor is changing the background image
  const handleBackgroundImageEditorSave = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onBackgroundImageChange(paramsToBeUpdated, partToBeUpdated, isToBeUpdated);
  }, [ onBackgroundImageChange, partToBeUpdated ]);

  // The background image editor modal
  const backgroundImageEditorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-background-image-modal'
        onClose={ handleEditorOpen(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileBackgroundImageEditor
          onCancel={ handleEditorOpen(false) }
          onSave={ handleBackgroundImageEditorSave }
          profile={ profile }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, handleBackgroundImageEditorSave, handleEditorOpen, profile ]);

  return (
    <div className='ody-profile-background-image'>
      <img
        alt='Background Image'
        src={ backgroundImages[backgroundImage - 1] }
      />
      { editButton }
      { backgroundImageEditorModal }
    </div>
  );
});

ProfileBackgroundImage.displayName = 'ProfileBackgroundImage';

ProfileBackgroundImage.propTypes = {
  // Whether the user can edit the background image of his profile
  canEdit: PropTypes.bool.isRequired,
  // The function ((profile, part) => void) to invoke when the profile changes.
  onBackgroundImageChange: PropTypes.func.isRequired,
  // The profile of the company Agent.
  profile: PropTypes.shape({
    // The ID of the background image of the company.
    backgroundImage: PropTypes.number,
  }),
  // The role based on route
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileBackgroundImage.defaultProps = {
};

export default ProfileBackgroundImage;
