/**
 * English translations for job-seekers/profile-resume.
 *
 * @module languages/en/job-seekers/profile-resume
 */
const profileResume = {
  download_failed_error: 'Download failed.',
  download_resume_button_label: 'Download resume',
  editor_title: 'Resume',
  load_failed_error: 'Load failed.',
  no_resume_message: 'Upload your resume here.',
  title: 'Resume',
  too_large_document_error: 'Upload a document up to 4MB.',
  unsupported_document_format_error: 'Upload a document in PDF or DOCX format.',
};

export default profileResume;
