/**
 * English translations for profile.
 *
 * @module languages/en/profile
 */
const profile = {
  banner: {
    // eslint-disable-next-line max-len
    free_pricing_company_agent: 'Contact details of this profile are not accessible as you are using the free tier. Please see our pricing plans or contact us for more details.',
    login_sign_up_button_label: 'Sign In/Sign up',
    pricing_plans: 'Pricing Plans',
    // eslint-disable-next-line max-len
    text: 'This is a public view of the profile. For more details on this profile, please sign in or register.\n\nYou can see a full version of the profile in this example profile.',
  },
  common: {
    add_button_label: 'Add',
    cancel_button_label: 'Cancel',
    change_button_label: 'Change',
    changes_saved_message: 'Your changes were saved.',
    delete_button_label: 'Delete',
    edit_button_label: 'Edit',
    general_error: 'Oops! Something went wrong.',
    load_failed_error: 'Load failed.',
    save_button_label: 'Save',
    upload_button_label: 'Upload',
  },
  confirm_hired: 'Are you sure you have hired this job seeker?',
  confirm_interviewed: 'Are you sure you have interviewed this job seeker?',
  download_button_label: 'Download Profile',
  download_failed_error: 'Profile failed to download',
  endorsement_request_success: 'The endorsement request was successful',
  failed_endorsement_request: 'The endorsement request is failed',
  failed_to_fetch_completed_endorsements: 'Failed to fetch the completed endorsements',
  failed_to_fetch_profile: 'Failed to fetch the profile information',
  hired_button_title: 'Hired Profile',
  hired_error: 'Something went wrong while triggering a hired event',
  hired_success: 'The job seeker is hired successfully',
  interviewed_button_title: 'Interviewed Profile',
  interviewed_error: 'Something went wrong while triggering an interview event',
  interviewed_success: 'The job seeker interviewed successfully',
  save_button_title: 'Save Profile',
  unsave_button_title: 'Unsave Profile',
  user_not_found: 'The user you requested has not been found',
};

export default profile;
