/* eslint-disable */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const TraitsCardIcon = memo((props) => {
  const { isWhite } = props;

  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 39'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5205 15.3466C7.79377 19.6744 3.81459 24.2546 0.893321 29.1835C0.628844 29.6283 0.821215 30.1452 1.21793 30.4217C4.83646 32.9343 8.67138 36.1801 12.7107 37.9473C13.3719 38.2358 13.997 37.8872 14.0691 37.1659C14.3937 33.9321 13.7566 30.6021 13.7926 27.3442C13.8287 23.5093 13.3598 19.8186 13.2396 16.0078C12.6746 16.164 12.1216 16.3083 11.5566 16.4646C12.5304 18.1837 13.6484 19.7104 14.9587 21.1891C16.5095 22.9443 20.2603 25.6011 20.8494 27.8251C21.3302 29.6524 19.6712 30.0851 18.2286 31.2272C16.197 32.8261 14.2735 34.6053 12.5304 36.5047C12.9992 36.8654 13.4801 37.238 13.9489 37.5987C13.9369 37.6348 13.9249 37.6588 13.9008 37.6949C14.7664 36.9255 13.4921 35.6512 12.6265 36.4206C12.5303 36.5047 12.4462 36.5889 12.3861 36.6971C11.8571 37.5626 13.1074 38.5484 13.8047 37.791C16.7259 34.6173 20.2362 32.4414 23.4821 29.7125C23.7586 29.472 23.8428 28.919 23.6264 28.6185C20.3805 24.0863 15.8603 20.4197 13.1074 15.575C12.6746 14.8056 11.4003 15.1302 11.4243 16.0318C11.5446 19.8427 12.0134 23.5453 11.9773 27.3682C11.9413 30.6261 12.5784 33.9561 12.2538 37.1899C12.7107 36.9255 13.1555 36.673 13.6123 36.4085C9.57301 34.6414 5.73809 31.3955 2.11956 28.883C2.22775 29.2917 2.33594 29.7004 2.44413 30.1212C5.29327 25.3125 9.16428 20.8765 12.7948 16.6449C13.5402 15.7433 12.2779 14.469 11.5205 15.3466Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M18.8297 14.7575C18.469 17.8351 18.1564 21.1891 18.4329 24.2787C18.469 24.6874 18.6854 25.024 19.0942 25.1442C22.8569 26.2863 26.5596 28.1617 30.1661 29.7245C30.647 29.9289 31.3803 29.8207 31.4885 29.1835C31.8732 27.0557 31.6087 24.7595 31.4524 22.6197C31.3803 21.5137 31.5967 19.2897 30.8273 18.34C30.106 17.4624 27.858 17.0416 26.8842 16.6569C24.5761 15.7673 22.2559 14.9138 19.9597 13.9761C19.9597 14.5531 19.9597 15.1302 19.9597 15.7192C22.9892 14.5411 27.1006 12.6778 30.3224 12.4013C29.8415 12.4373 30.118 12.4373 30.8273 12.7258C31.7169 13.0985 32.6185 13.4351 33.5202 13.7958C35.4076 14.5531 37.283 15.3586 39.2064 15.9957C39.1343 15.4427 39.0621 14.9018 38.99 14.3488C35.9966 15.8515 32.6065 16.5608 29.6732 18.1717C28.6514 18.7247 29.565 20.2875 30.5869 19.7345C33.5202 18.1356 36.9103 17.4263 39.9037 15.9116C40.637 15.5389 40.3725 14.493 39.6873 14.2646C36.8021 13.3029 32.9912 10.586 29.9497 10.6461C28.6514 10.6701 26.9443 11.6078 25.7061 11.9925C23.6263 12.6297 21.5105 13.1947 19.4789 13.9881C18.6133 14.3247 18.6373 15.3826 19.4789 15.7313C21.1138 16.4045 22.7728 17.0176 24.4197 17.6547C25.8143 18.1957 28.2787 18.6044 29.3727 19.5902C29.7333 19.8066 29.7213 19.7225 29.3486 19.3498C29.3967 19.6984 29.4328 20.047 29.4689 20.4077C29.541 21.153 29.6011 21.8864 29.6492 22.6317C29.7814 24.5912 30.094 26.7672 29.7453 28.7147C30.1901 28.5343 30.6229 28.354 31.0677 28.1737C27.8339 26.7792 24.1793 24.5311 20.7411 23.8699C19.575 23.6415 19.8034 22.8962 20.2242 24.1825C20.0318 23.5814 20.2242 22.5716 20.2362 21.9585C20.2723 19.5542 20.3564 17.1498 20.6329 14.7575C20.7652 13.6034 18.9619 13.6154 18.8297 14.7575Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M31.3208 29.8086C33.3164 29.1354 35.276 28.354 37.2235 27.5485C38.1492 27.1638 39.9765 26.7671 40.5295 25.8294C41.0705 24.9158 40.5415 22.6677 40.5175 21.694C40.4454 19.5181 40.3852 17.3301 40.3371 15.1422C40.3131 13.9881 38.5098 13.9761 38.5339 15.1422C38.5699 16.8853 38.618 18.6285 38.6782 20.3716C38.7262 21.8262 39.0989 23.6175 38.8344 25.048C38.7382 25.565 38.9907 24.7956 39.0388 24.7595C38.9066 24.8557 38.594 25.036 38.4377 25.0601C37.6082 25.1803 36.6465 25.8415 35.8771 26.154C34.2181 26.8272 32.5471 27.4884 30.852 28.0535C29.746 28.4381 30.2148 30.1813 31.3208 29.8086Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
      <path
        d='M25.381 1.37737C22.7723 3.02434 21.7625 6.02976 22.592 9.03518C24.4914 15.8875 33.5197 15.1662 35.8399 10.4658C39.1218 3.82979 31.2716 -2.33733 25.381 1.37737Z'
        fill={ isWhite ?  '#ffffff' : '#161616' }
      />
    </svg>
  );
});

TraitsCardIcon.displayName = 'TraitsCardIcon';

TraitsCardIcon.propTypes = {
  // Whether the icon is white
  isWhite: PropTypes.bool,
};

TraitsCardIcon.defaultProps = {
  isWhite: false,
};

export default TraitsCardIcon;
