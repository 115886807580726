/**
 * English translations for administrators/dashboard.
 *
 * @module languages/en/administrators/dashboard
 */
const dashboard = {
  approve_button_title: 'Approve',
  approve_companies_confirmation_message: 'Are you sure you want to approve the company {{companyName}}?',
  approve_endorsers_confirmation_message: 'Are you sure you want to approve the endorser {{endorserName}}?',
  controlled_metrics: {
    collaborating_companies_metric_title: 'Collaborating companies',
    companies_metric_title: 'Companies',
    employability_services_per_year_metric_title: 'Employability services per year',
    find_employability_per_year_metric_title: 'Find employment per year',
    input_error_message: 'The metric is required',
    input_placeholder: '|...',
    job_positions_from_our_companies_metric_title: 'Job positions from our companies',
    matched_job_seekers_with_companies_metric_title: 'Matched job seekers with companies',
    organization_metrics_section_title: 'Odyssea Organization Metrics',
    people_hired_metric_title: 'People hired',
    people_interviewed_metric_title: 'People interviewed',
    profiles_metric_title: 'Profiles',
    registered_per_year_metric_title: 'Registered per year',
    students_per_year_metric_title: 'Students per year',
    talent_platform_metrics_section_title: 'Talent Platform Metrics',
    title: 'Admin-Controlled Metrics',
  },
  failed_fetch_pending_companies: 'Failed to fetch pending companies',
  failed_fetch_pending_endorsers: 'Failed to fetch pending endorsers',
  failed_fetch_total_users: 'Failed to fetch the total users',
  failed_to_update_controlled_metrics: 'Failed to update controlled metrics',
  general_error: 'Oops! Something went wrong.',
  no_pending_results: 'You do not have any pending approval yet',
  pending_companies_title: 'Companies',
  pending_endorsers_title: 'Endorsers',
  pending_title: 'Pending approval',
  title: 'Admin Dashboard',
  total_companies_title: 'Companies',
  total_endorsers_title: 'Endorsers',
  total_job_seekers_title: 'Job Seekers',
  total_users_title: 'Total Users',
  update_all_button_title: 'Update all',
  view_all_button_title: 'View All',
  view_less_button_title: 'View Less',
  view_total_companies_link_title: 'View Companies',
  view_total_endorsers_link_title: 'View Endorsers',
  view_total_job_seekers_link_title: 'View Job Seekers',
};

export default dashboard;
