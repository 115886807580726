/**
 * Resource method to sign out.
 *
 * @module resources/auth/sign-out
 */
import axios from 'axios';

/**
 * Signs out the currently signed-in user.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<void>} - The promise.
 * @static
 */
const signOut = () => {
  return axios.request({
    method: 'post',
    url: '/auth/sign-out',
  }).then((response) => {
    return response.data;
  });
};

export default signOut;
