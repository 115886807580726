/**
 * Greek translations for profile.
 *
 * @module languages/el/profile
 */
const profile = {
  banner: {
    // eslint-disable-next-line max-len
    free_pricing_company_agent: 'Τα στοιχεία επικοινωνίας αυτού του προφίλ δεν είναι προσβάσιμα καθώς χρησιμοποιείτε το δωρεάν επίπεδο. Δείτε τα πακέτα τιμών μας ή επικοινωνήστε μαζί μας για περισσότερες λεπτομέρειες.',
    login_sign_up_button_label: 'Συνδεθείτε/Εγγραφείτε',
    pricing_plans: 'Πακέτα τιμών',
    // eslint-disable-next-line max-len
    text: 'Αυτή είναι μια δημόσια προβολή του προφίλ. Για περισσότερες λεπτομέρειες σχετικά με αυτό το προφίλ, συνδεθείτε ή εγγραφείτε.\n\nΜπορείτε να δείτε ένα ολοκληρωμένο προφίλ σε αυτό το παράδειγμα',
  },
  common: {
    add_button_label: 'Προσθέστε',
    cancel_button_label: 'Ακύρωση',
    change_button_label: 'Μετατροπή',
    changes_saved_message: 'Οι αλλαγές σας αποθηκεύτηκαν.',
    delete_button_label: 'Διαγραφή',
    edit_button_label: 'Επεξεργασία',
    general_error: 'Ωχ! Κάτι πήγε στραβά.',
    load_failed_error: 'Η φόρτωση απέτυχε.',
    save_button_label: 'Αποθήκευση',
    upload_button_label: 'Μεταφόρτωση',
  },
  confirm_hired: 'Είστε σίγουροι ότι έχετε προσλάβει αυτόν/ην υποψήφιο/α;',
  confirm_interviewed: 'Είστε σίγουροι ότι έχετε πάρει συνέντευξη αυτόν/ην υποψήφιο/α;',
  download_button_label: 'Λήψη προφίλ',
  download_failed_error: 'Η λήψη του προφίλ απέτυχε.',
  endorsement_request_success: 'Το αίτημα για σύσταση ήταν επιτυχές',
  failed_endorsement_request: 'Το αίτημα για σύσταση απέτυχε',
  failed_to_fetch_completed_endorsements: 'Αποτυχία λήψης των ολοκληρωμένων συστάσεων',
  failed_to_fetch_profile: 'Αποτυχία λήψης πληροφοριών για αυτό το προφίλ',
  hired_button_title: 'Προσλήψεις',
  hired_error: 'Ωχ! Κάτι πήγε στραβά.',
  hired_success: 'Η πρόσληψη έγινε επιτυχώς',
  interviewed_button_title: 'Προφίλ που ολοκλήρωσαν τη συνέντευξη',
  interviewed_error: 'Ωχ! Κάτι πήγε στραβά.',
  interviewed_success: 'Η συνέντευξη έγινε επιτυχώς',
  save_button_title: 'Αποθηκευμένο προφίλ',
  unsave_button_title: 'Κατάργηση αποθήκευσης προφίλ',
  user_not_found: 'Ο χρήστης που ζητήσατε δε βρέθηκε',
};

export default profile;
