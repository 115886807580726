import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormWithSchema } from 'hooks/use-form-with-validation';
import Upload from 'images/upload.png';
import accessLevels from 'utilities/access-levels';
import roles, * as Roles from 'utilities/auth/roles';
import { areNotEqual, isEmpty, isNotEmpty } from 'utilities/chisels';
import { companiesAboutEditorSchemaValidator, jobSeekersAboutEditorSchemaValidator } from 'utilities/validators';

const ProfileAboutEditor = memo((props) => {
  const { onCancel, onSave, profile, role, videoSource } = props;

  const { t } = useTranslation();

  // The allowed video formats.
  const ALLOWED_VIDEO_FORMATS = useMemo(() => {
    return [
      'video/mp4',
      'video/ogg',
    ];
  }, []);

  // The maximum allowed video size (in bytes).
  const MAXIMUM_ALLOWED_VIDEO_SIZE = useMemo(() => {
    return 16 * 1024 * 1024;
  }, []);

  // The video.
  const [ video, setVideo ] = useState(undefined);

  // The error when uploading a video.
  const [ videoError, setVideoError ] = useState('');

  // Reference to the file input.
  const fileRef = useRef(null);

  // Reference to the video.
  const videoRef = useRef(null);

  // The schema to be validated
  const schemaToBeValidated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersAboutEditorSchemaValidator;
    case Roles.COMPANY_AGENT:
      return companiesAboutEditorSchemaValidator;
    default:
      return jobSeekersAboutEditorSchemaValidator;
    }
  }, [ role ]);

  const { formState: { errors }, handleSubmit, register, reset, setValue } = useFormWithSchema(schemaToBeValidated);

  const { ref, ...rest } = register('file', {
    onChange: (e) => {
      const [ file, ..._others ] = e.target.files;
      if (undefined === file) {
        return;
      }
      if (undefined !== video) {
        URL.revokeObjectURL(video.url);
      }
      setVideoError('');
      setVideo(undefined);
      setValue('video', '');
      // Check that the uploaded video has one of the allowed formats.
      if (-1 === ALLOWED_VIDEO_FORMATS.indexOf(file.type)) {
        setVideoError(t('common:profile_about.unsupported_video_format_error'));
        setValue('file', null);
        return;
      }
      // Check that the uploaded video is not too large.
      if (MAXIMUM_ALLOWED_VIDEO_SIZE < file.size) {
        setVideoError(t('common:profile_about.too_large_video_error'));
        setValue('file', null);
        return;
      }
      setVideo({
        type: file.type,
        url: URL.createObjectURL(file),
      });
      videoRef.current?.load();
    },
  });

  // The values on init based on the role
  const valuesToInit = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return {
        file: null,
        shortBio: profile?.shortBio || '',
        video: profile?.video?.id || '',
      };
    case Roles.COMPANY_AGENT:
      return {
        description: profile?.description || '',
        file: null,
        video: profile?.video?.id || '',
      };
    default:
      return {};
    }
  }, [ profile?.description, profile?.shortBio, profile?.video?.id, role ]);

  // Fill in the form with values from the profile.
  useEffect(() => {
    reset(valuesToInit);
  }, [ reset, valuesToInit ]);

  // Preview the video in the profile.
  useEffect(() => {
    if (undefined === profile?.video) {
      setVideo(undefined);
      return;
    }
    setVideo({
      ...profile.video,
      url: videoSource,
    });
    videoRef.current?.load();
  }, [ profile.video, videoSource ]);

  // The editor title depending on the role
  const editorTitle = useMemo(() => {
    switch (role) {
    case Roles.COMPANY_AGENT:
      return t('common:profile_about.editor_title_companies');
    case Roles.JOB_SEEKER:
      return t('common:profile_about.editor_title_job_seekers');
    default:
      return '';
    }
  }, [ role, t ]);

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return {
        file: undefined,
        shortBio: profile?.shortBio,
        video: {
          id: profile?.video?.id || '',
        },
      };
    case Roles.COMPANY_AGENT:
      return {
        description: profile?.description,
        file: undefined,
        video: {
          id: profile?.video?.id || '',
        },
      };
    default:
      return {};
    }
  }, [ profile?.description, profile?.shortBio, profile?.video, role ]);

  // renders video to be updated
  const renderVideoToUpdate = useCallback((video) => {
    if (isEmpty(video)) {
      return undefined;
    }
    return {
      id: video,
    };
  }, []);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const { file } = values;
    const paramsToBeUpdated = {
      ...values,
      file: undefined,
      video: renderVideoToUpdate(values.video),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated), file);
  }, [ onSave, renderVideoToUpdate, valuesAreToBeUpdated ]);

  // Function that handles the placeholder upload click
  const onUploadClick = useCallback(() => {
    fileRef.current?.click();
  }, []);

  // no video message text
  const noVideoMessageText = useMemo(() => {
    if (role === Roles.JOB_SEEKER) {
      return t('common:profile_about.no_video_message_job_seekers');
    }
    return t('common:profile_about.no_video_message_companies');
  }, [ role, t ]);

  // rendered video preview or placeholder
  const renderedVideoSection = useMemo(() => {
    if (video?.url === undefined) {
      return (
        <button
          className='profile-editor__video-container--no-video btn btn-trans'
          onClick={ onUploadClick }
        >
          <img alt='Upload' className='profile-editor__video-container--image' src={ Upload } />
          <p className='txt txt-sm'>
            { noVideoMessageText }
          </p>
        </button>
      );
    }
    return (
      <video
        className='profile-editor__video-container--video'
        controls
        crossOrigin='anonymous'
        preload='auto'
        ref={ videoRef }
      >
        <source
          src={ video?.url }
          type={ video?.type }
        />
      </video>
    );
  }, [ noVideoMessageText, onUploadClick, video?.type, video?.url ]);

  // Function that handles the delete button click
  const onDeleteVideoButtonClick = useCallback(() => {
    if (undefined !== video) {
      URL.revokeObjectURL(video.url);
    }
    setVideo(undefined);
    setValue('video', '');
    setValue('file', null);
  }, [ setValue, video ]);

  // The rendered upload button title
  const renderedUploadButtonTitle = useMemo(() => {
    if (undefined === video?.url) {
      return t('common:profile_about.upload_button_label');
    }
    return t('common:profile_about.change_button_label');
  }, [ t, video?.url ]);

  // The video error
  const videoErrorSection = useMemo(() => {
    if (undefined === videoError) {
      return null;
    }
    return (
      <span className='error'>{ videoError }</span>
    );
  }, [ videoError ]);

  // Function that handles the file input click
  const onFileInputClick = useCallback((e) => {
    ref(e);
    fileRef.current = e;
  }, [ ref ]);

  // Description label based on role
  const descriptionLabel = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('common:profile_about.short_bio_label_job_seekers');
    case Roles.COMPANY_AGENT:
      return t('common:profile_about.short_bio_label_companies');
    default:
      return '';
    }
  }, [ role, t ]);

  // The description registry based on role
  const descriptionRegistry = useMemo(() => {
    switch (role) {
    case Roles.COMPANY_AGENT:
      return 'description';
    case Roles.JOB_SEEKER:
      return 'shortBio';
    default:
      return '';
    }
  }, [ role ]);

  // The video container
  const videoContainer = useMemo(() => {
    return (
      <div className='profile-editor__video-container'>
        { renderedVideoSection }
        <div className='profile-editor__video-container--buttons'>
          <button
            className='btn btn-sm btn-rounded-sm btn-red'
            onClick={ onDeleteVideoButtonClick }
          >
            { t('common:profile_about.delete_button_label') }
          </button>
          { videoErrorSection }
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            disabled={ isNotEmpty(errors) }
            onClick={ onUploadClick }
          >
            { renderedUploadButtonTitle }
          </button>
        </div>
      </div>
    );
  }, [
    errors,
    onDeleteVideoButtonClick,
    onUploadClick,
    renderedUploadButtonTitle,
    renderedVideoSection,
    t,
    videoErrorSection,
  ]);

  // The file and video inputs
  const fileAndVideoInputFields = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          <input
            id='video'
            type='hidden'
            { ...register('video') }
          />
        </label>
        <label>
          <input
            accept={ ALLOWED_VIDEO_FORMATS.join(', ') }
            className='hidden'
            ref={ onFileInputClick }
            type='file'
            { ...rest }
          />
        </label>
      </div>
    );
  }, [ ALLOWED_VIDEO_FORMATS, onFileInputClick, register, rest ]);

  // the short bio error
  const shortBioError = useMemo(() => {
    if ('string.max' !== errors?.shortBio?.type && 'string.max' !== errors?.description?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_about.description_error') }
      </span>
    );
  }, [ errors?.description?.type, errors?.shortBio?.type, t ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { editorTitle }
      </h2>
      { videoContainer }
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { fileAndVideoInputFields }
        <div className='profile-editor__form-field'>
          <label>
            { descriptionLabel }
            <textarea
              className={
                clsx({
                  'error': errors?.shortBio || errors?.description,
                })
              }
              placeholder={ descriptionLabel }
              rows={ 10 }
              { ...register(descriptionRegistry) }
            />
          </label>
          { shortBioError }
        </div>
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileAboutEditor.displayName = 'ProfileAboutEditor';

ProfileAboutEditor.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // the user's profile
  profile: PropTypes.oneOfType([
    //job seeker profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The id of the profile
      id: PropTypes.string,
      // The short bio of the job seeker.
      shortBio: PropTypes.string,
      // The video of the job seeker.
      video: PropTypes.shape({
        // The ID of the video.
        id: PropTypes.string,
        // The MIME type of the video.
        type: PropTypes.string,
      }),
    }),
    // company profile
    PropTypes.shape({
      // The access level that the viewer has on the profile.
      accessLevel: PropTypes.oneOf(accessLevels),
      // The description of the company.
      description: PropTypes.string,
      // The id of the profile
      id: PropTypes.string,
      // The video of the company.
      video: PropTypes.shape({
        // The ID of the video.
        id: PropTypes.string,
        // The MIME type of the video.
        type: PropTypes.string,
      }),
    }),
  ]),
  // The role based on the router
  role: PropTypes.oneOf(roles).isRequired,
  // The video source
  videoSource: PropTypes.string.isRequired,
};

ProfileAboutEditor.defaultProps = {};

export default ProfileAboutEditor;
