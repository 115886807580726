/**
 * Profile Info Row.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Add from 'images/add.png';
import Edit from 'images/edit.png';
import Options from 'images/options.png';

import './profile-info-row.scss';

const ProfileInfoRow = memo((props) => {
  const {
    isMainSection,
    boldTitle,
    icon,
    expandableButtonLabel,
    isEditable,
    isExpandable,
    isCreatable,
    isTitle,
    textDisplayed,
    onCreateClick,
    onExpandableClick,
    onToggleEditor,
    href,
    value,
    alt,
  } = props;

  // Whether to render the show all button in expandable area
  const renderedShowAllButton = useMemo(() => {
    if (!isExpandable) {
      return null;
    }
    return (
      <button
        className='btn btn-sm btn-rounded-sm btn-blue hidden-xs hidden-sm'
        onClick={ onExpandableClick }
      >
        { expandableButtonLabel }
      </button>
    );
  }, [ expandableButtonLabel, isExpandable, onExpandableClick ]);

  // whether to render create button
  const renderedCreatableArea = useMemo(() => {
    if (isEditable) {
      return null;
    }

    if (!isCreatable) {
      return (
        <div className='profile-info-row__actions'>
          { renderedShowAllButton }
        </div>
      );
    }

    return (
      <div className='profile-info-row__actions'>
        <button
          className='btn btn-trans profile-info-row__action'
          onClick={ onCreateClick }
        >
          <img alt='Edit' className='hidden-sm hidden-xs' src={ Add } />
          <img alt='Options' className='hidden-lg hidden-md' src={ Options } />
        </button>
        { renderedShowAllButton }
      </div>
    );
  }, [ isCreatable, isEditable, onCreateClick, renderedShowAllButton ]);

  // whether to render edit button
  const renderedEditableArea = useMemo(() => {
    if (isCreatable) {
      return null;
    }

    if (!isEditable) {
      return (
        <div className='profile-info-row__actions'>
          { renderedShowAllButton }
        </div>
      );
    }

    return (
      <div className='profile-info-row__actions'>
        <button
          className='btn btn-trans profile-info-row__action'
          onClick={ onToggleEditor(true) }
        >
          <img alt='Edit' className='hidden-sm hidden-xs' src={ Edit } />
          <img alt='Options' className='hidden-lg hidden-md' src={ Options } />
        </button>
        { renderedShowAllButton }
      </div>
    );
  }, [ isCreatable, isEditable, onToggleEditor, renderedShowAllButton ]);

  // The rendered value of a row that is not a title
  const renderedValue = useMemo(() => {
    if (href) {
      return (
        <a
          className='link inline'
          href={ href }
          rel='noopener noreferrer'
          target='_blank'
        >
          { textDisplayed }
        </a>
      );
    }
    if (value) {
      return (
        <div className='profile-info-field__name-value-container'>
          <span className={
            clsx('profile-info-field__name txt txt-sm', {
              'txt-bold': boldTitle,
            })
          }
          >
            { textDisplayed }
          </span>
          &nbsp;
          <span className='profile-info-field__value txt txt-sm'>
            { value }
          </span>
        </div>
      );
    }
    return (
      <span className='profile-info-field__value txt txt-sm'>
        { textDisplayed }
      </span>
    );
  }, [ boldTitle, href, textDisplayed, value ]);

  // Whether to render icon
  const renderedIconSection = useMemo(() => {
    if (!icon) {
      return null;
    }
    return (
      <div className='profile-info-field__icon'>
        <img alt={ alt } src={ icon } />
      </div>
    );
  }, [ alt, icon ]);

  // rendered section
  const renderedSection = useMemo(() => {
    if (!isTitle) {
      return (
        <>
          { renderedIconSection }
          { renderedValue }
        </>
      );
    }
    return (
      <>
        <h2
          className={
            clsx('hdg', {
              'hdg-md': isMainSection,
              'hdg-sm': !isMainSection,
            })
          }
        >
          { textDisplayed }
        </h2>
        { renderedEditableArea }
        { renderedCreatableArea }
      </>
    );
  }, [
    isMainSection,
    isTitle,
    renderedCreatableArea,
    renderedEditableArea,
    renderedIconSection,
    renderedValue,
    textDisplayed,
  ]);

  return (
    <div className={
      clsx({
        'profile-info-field': !isTitle,
        'profile-info-row': isTitle,
      })
    }
    >
      { renderedSection }
    </div>
  );
});

ProfileInfoRow.displayName = 'ProfileInfoRow';

ProfileInfoRow.propTypes = {
  // The alt of the img tag
  alt: PropTypes.string,
  // Whether the title to be bold
  boldTitle: PropTypes.bool,
  // the expandable button label
  expandableButtonLabel: PropTypes.string,
  // The href if the text displayed is link
  href: PropTypes.string,
  // the icon to be displayed on the left of the row if it is not a title
  icon: PropTypes.string,
  // Whether the row is creatable
  isCreatable: PropTypes.bool,
  // Whether to render the edit button
  isEditable: PropTypes.bool,
  // Whether the section is expandable
  isExpandable: PropTypes.bool,
  // Whether this is included in the main section of the profile
  isMainSection: PropTypes.bool,
  // Whether the row is a title
  isTitle: PropTypes.bool,
  // the function () => void to be invoked when clicking the create button
  onCreateClick: PropTypes.func,
  // the function () => void to be invoked when clicking the show more button
  onExpandableClick: PropTypes.func,
  // the function () => void to be invoked when clicking the edit button
  onToggleEditor: PropTypes.func,
  // The text to be displayed
  textDisplayed: PropTypes.string.isRequired,
  // the value of the row
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ProfileInfoRow.defaultProps = {
  boldTitle: false,
  isCreatable: false,
  isEditable: false,
  isExpandable: false,
  isLink: false,
  isMainSection: false,
  isTitle: false,
};

export default ProfileInfoRow;
