import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import { useLg, useMd, useSm, useXs } from 'hooks/media-queries';
import filter from 'images/filter.png';
import roles, * as Roles from 'utilities/auth/roles';
import companiesFilterTypes from 'utilities/companies/filter-types';
import companiesValidators from 'utilities/companies/validators';
import jobSeekersFilterTypes from 'utilities/job-seekers/filter-types';
import JobSeekersValidators from 'utilities/job-seekers/validators';

import FilterBox from './filter-box/filter-box';
import './search-filters.scss';

const SearchFilters = memo((props) => {
  const { filters, facets, role, onChange, allProfessions } = props;

  const isSm = useSm();

  const { t } = useTranslation();

  const isXs = useXs();

  const isMd = useMd();

  const isLg = useLg();

  // Whether the modal is open.
  const [ open, setOpen ] = useState(false);

  // Close the modal if resizing changed things.
  useEffect(() => {
    if (!isSm && !isXs) {
      setOpen(false);
    }
  }, [ isSm, isXs ]);

  // Function that toggles the modal
  const toggleModal = useCallback((toggle) => {
    return (_event) => {
      setOpen(toggle);
    };
  }, []);

  // The trigger button to invoke modal
  const triggerButton = useMemo(() => {
    return (
      <button className={
        clsx('btn btn-custom btn-rounded-sm', {
          'btn-purple': role === Roles.ENDORSER,
          'btn-red': role === Roles.JOB_SEEKER,
          'btn-yellow': role === Roles.COMPANY_AGENT,
        })
      }
      >
        <img alt='Filter' src={ filter } />
        <span>
          { t('common:search_filters.filters_button_label') }
        </span>
      </button>
    );
  }, [ role, t ]);

  const handleClearAllButtonClick = useCallback(() => {
    onChange([]);
  }, [ onChange ]);

  // Function that checks whether to render the clear all filters button
  // If no filter is applied the button will not be rendered
  const renderClearAllButton = useMemo(() => {
    if (1 > filters?.length) {
      return null;
    }
    return (
      <button
        className='btn btn-trans'
        onClick={ handleClearAllButtonClick }
      >
        { t('common:search_filters.clear_all_button_label') }
      </button>
    );
  }, [ filters?.length, handleClearAllButtonClick, t ]);

  // The filter boxes
  const renderedFilterBoxes = useMemo(() => {
    return Object.keys(facets).map((facetKey) => {
      return (
        <FilterBox
          allProfessions={ allProfessions }
          facet={ facets[facetKey] }
          facetKey={ facetKey }
          filters={ filters }
          key={ facetKey }
          onChange={ onChange }
        />
      );
    });
  }, [ allProfessions, facets, filters, onChange ]);

  // The filters rendered based on the screen size
  const filtersRendered = useMemo(() => {
    if (isLg || isMd) {
      return (
        <div className='container'>
          <div className='title-and-clear'>
            <div className='hdg hdg-md'>
              { t('common:search_filters.title') }
            </div>
            { renderClearAllButton }
          </div>
          { renderedFilterBoxes }
        </div>
      );
    }
    return (
      <Modal
        className='search-filters-modal'
        onClose={ toggleModal(false) }
        onOpen={ toggleModal(true) }
        open={ open }
        trigger={ triggerButton }
      >
        <div className='container'>
          <div className='title-and-clear'>
            <div className='hdg hdg-md'>
              { t('common:search_filters.title') }
            </div>
            { renderClearAllButton }
          </div>
          { renderedFilterBoxes }
        </div>
      </Modal>
    );
  }, [ isLg, isMd, open, renderClearAllButton, renderedFilterBoxes, t, toggleModal, triggerButton ]);

  return (
    <div className='search-filters dark'>
      { filtersRendered }
    </div>
  );
});

SearchFilters.displayName = 'SearchFilters';

SearchFilters.propTypes = {
  // All the professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // The green translation of the profession
      el: PropTypes.string.isRequired,
      // The english translation of the profession
      en: PropTypes.string.isRequired,
      // The id of the profession
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  // The facets.
  facets: PropTypes.oneOfType([
    // The job seekers facets
    PropTypes.shape({
      // The computer skills levels.
      computerSkillsLevels: PropTypes.objectOf(JobSeekersValidators.validateComputerSkillsLevelsFacet),
      // The driving license categories.
      drivingLicenseCategories: PropTypes.objectOf(
        JobSeekersValidators.validateDrivingLicenseCategoriesFacet
      ),
      // The experience length range.
      experienceLengthRange: PropTypes.shape({
        // The end of the range.
        end: PropTypes.number.isRequired,
        // The start of the range.
        start: PropTypes.number,
      }),
      // The ISO 639-1 alpha-2 codes of the languages.
      languages: PropTypes.objectOf(JobSeekersValidators.validateLanguagesFacet),
      // The IDs of the professions.
      professions: PropTypes.objectOf(JobSeekersValidators.validateProfessionsFacet),
      // The profile completion percentage length range.
      profileCompletionPercentageRange: PropTypes.shape({
        // The end of the range.
        end: PropTypes.number,
        // The start of the range.
        start: PropTypes.number,
      }),
      // The qualification types.
      qualificationTypes: PropTypes.objectOf(JobSeekersValidators.validateQualificationTypes),
      // The relocation intentions.
      relocationIntentions: PropTypes.objectOf(JobSeekersValidators.validateRelocationIntentions),
      // The work availabilities.
      workAvailabilities: PropTypes.objectOf(JobSeekersValidators.validateWorkAvailabilities),
    }),
    // The company agents facets
    PropTypes.shape({
      // The number of employees
      numberOfEmployees: PropTypes.objectOf(companiesValidators.validateNumberOfEmployees),
      // The IDs of the professions.
      professions: PropTypes.objectOf(companiesValidators.validateProfessionsFacet),
    }),
  ]).isRequired,
  // The filters
  filters: PropTypes.oneOfType([
    // The job Seeker filters
    PropTypes.arrayOf(
      PropTypes.shape({
        // The type of the filter
        type: PropTypes.oneOf(jobSeekersFilterTypes).isRequired,
        // The value of the filter
        value: PropTypes.string.isRequired,
      }).isRequired
    ),
    // the company agents filters
    PropTypes.arrayOf(
      PropTypes.shape({
        // The type of the filters
        type: PropTypes.oneOf(companiesFilterTypes).isRequired,
        // The value of the filter
        value: PropTypes.string.isRequired,
      }).isRequired
    ),
  ]).isRequired,
  // The function ((object[]) => void) to invoke when the filters change.
  onChange: PropTypes.func.isRequired,
  // the role from where this component is triggered
  role: PropTypes.oneOf(roles).isRequired,
};

SearchFilters.defaultProps = {

};

export default SearchFilters;
