/**
 * Search criterion types.
 *
 * @module utilities/criterion-types
 */

/**
 * Cities.
 *
 * @constant {string}
 * @static
 */
export const CITY = 'CITY';

/**
 * Job sectors.
 *
 * @constant {string}
 * @static
 */
export const JOB_SECTOR = 'JOB_SECTOR';

/**
 * Terms.
 *
 * @constant {string}
 * @static
 */
export const TERM = 'TERM';

export default [
  CITY,
  JOB_SECTOR,
  TERM,
];
