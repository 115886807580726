/**
 * Search Total
 */
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import roles, * as Roles from 'utilities/auth/roles';

import './search-total.scss';

const SearchTotal = memo((props) => {
  const { total, role } = props;

  const { t } = useTranslation();

  // The total found records text
  const searchTotalText = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return t('common:search_total.job_seekers_found', { count: total });
    case Roles.COMPANY_AGENT:
      return t('common:search_total.companies_found', { count: total });
    case Roles.ENDORSER:
      return t('common:search_total.endorsers_found', { count: total });
    default:
      return '';
    }
  }, [ role, t, total ]);

  return (
    <div className='search-total dark'>
      <div className='hdg hdg-md'>
        { searchTotalText }
      </div>
    </div>
  );
});

SearchTotal.displayName = 'SearchTotal';

SearchTotal.propTypes = {
  // The search role
  role: PropTypes.oneOf(roles).isRequired,
  // The total records
  total: PropTypes.number,
};

SearchTotal.defaultProps = {};

export default SearchTotal;
