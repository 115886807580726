/**
 * Resource method to sign up with LinkedIn as a job seeker.
 *
 * @module resources/job-seekers/linkedin-sign-up
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/job-seekers/linkedin-sign-up~Params
 * @type {object}
 * @property {string} accessToken - The Google access token.
 * @property {string} email - The email of the job seeker.
 * @property {string} firstName - The first name of the job seeker.
 * @property {string} language - The ISO 639-1 alpha-2 code of the language of the job seeker.
 * @property {string} lastName - The last name of the job seeker.
 */

/**
 * Result.
 *
 * @typedef module:module:resources/job-seekers/linkedin-sign-up~Result
 * @type {object}
 * @property {string} id - The ID of the job seeker.
 */

/**
 * Signs up a job seeker via LinkedIn based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/job-seekers/linkedin-sign-up~Params} params - The parameters.
 * @returns {Promise<module:resources/job-seekers/linkedin-sign-up~Result>} - The promise.
 * @static
 */
const linkedInSignUp = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/job-seekers/linkedin/sign-up',
  }).then((response) => {
    return response.data;
  });
};

export default linkedInSignUp;
