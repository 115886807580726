/**
 * Resource method to get total users.
 *
 * @module resources/administrators/get-total-users
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/administrators~TotalUsers} Result
 */

/**
 * Gets the total number of users.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/administrators/get-total-users~Result>} - The promise.
 * @static
 */
const getTotalUsers = () => {
  return axios.request({
    method: 'get',
    url: 'administrators/users/total',
  }).then((response) => {
    return response.data;
  });
};

export default getTotalUsers;
