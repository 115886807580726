/**
 * Job seekers - Profile - Languages.
 */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal/modal';
import ProfileInfoRow from 'components/common/profile-info-row/profile-info-row';
import GreekFlag from 'images/languages/el.png';
import EnglishFlag from 'images/languages/en.png';
import accessLevels, * as AccessLevels from 'utilities/access-levels';
import { isEmpty } from 'utilities/chisels';
import * as JobSeekerParts from 'utilities/job-seekers/parts';
import languageLevels from 'utilities/language-levels';
import languages from 'utilities/languages';

import ProfileLanguagesEditor from './languages-editor/profile-languages-editor';

import './profile-languages.scss';

const ProfileLanguages = memo((props) => {
  const { profile, onChange, canEdit } = props;

  // Whether the editor modal is open.
  const [ editorOpen, setEditorOpen ] = useState(false);

  const { t } = useTranslation();

  // Function that toggles the editor modal
  const toggleEditor = useCallback((toggle) => {
    return (_event) => {
      setEditorOpen(toggle);
    };
  }, []);

  // The lean access levels
  const leanAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_1,
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The greek level field
  const greekLevelField = useMemo(() => {
    if (!profile?.greekLevel) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='greek language level'
        boldTitle
        icon={ GreekFlag }
        textDisplayed={ `${ t('utilities:languages.el') }: ` }
        value={ t(`utilities:language_levels.${ profile.greekLevel }`) }
      />
    );
  }, [ profile?.greekLevel, t ]);

  // The english level field
  const englishLevelField = useMemo(() => {
    if (!profile?.englishLevel) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='english language level'
        boldTitle
        icon={ EnglishFlag }
        textDisplayed={ `${ t('utilities:languages.en') }: ` }
        value={ t(`utilities:language_levels.${ profile.englishLevel }`) }
      />
    );
  }, [ profile?.englishLevel, t ]);

  // the other languages value
  const otherLanguagesValue = useMemo(() => {
    return profile?.otherLanguages?.map((language) => {
      return t(`utilities:languages.${ language }`);
    }).join(', ');
  }, [ profile?.otherLanguages, t ]);

  // The other languages field
  const otherLanguagesField = useMemo(() => {
    if (isEmpty(profile?.otherLanguages) || !leanAccessLevels.includes(profile?.accessLevel)) {
      return null;
    }
    return (
      <ProfileInfoRow
        alt='greek language level'
        boldTitle
        textDisplayed={ `${ t('job_seekers:profile_languages.other') }: ` }
        value={ otherLanguagesValue }
      />
    );
  }, [ leanAccessLevels, otherLanguagesValue, profile?.accessLevel, profile?.otherLanguages, t ]);

  // Function that handles when the languages of the user change
  const onLanguagesChange = useCallback((paramsToBeUpdated, isToBeUpdated) => {
    setEditorOpen(false);
    onChange(paramsToBeUpdated, JobSeekerParts.LANGUAGES, isToBeUpdated);
  }, [ onChange ]);

  // The editor modal
  const editorModal = useMemo(() => {
    if (!canEdit) {
      return null;
    }
    return (
      <Modal
        className='profile-editor-modal'
        onClose={ toggleEditor(false) }
        open={ editorOpen }
        trigger={
          <button className='hidden'>&nbsp;</button>
        }
      >
        <ProfileLanguagesEditor
          onCancel={ toggleEditor(false) }
          onSave={ onLanguagesChange }
          profile={ profile }
        />
      </Modal>
    );
  }, [ canEdit, editorOpen, onLanguagesChange, profile, toggleEditor ]);

  return (
    <div className='profile-languages'>
      <ProfileInfoRow
        isEditable={ canEdit }
        isTitle
        onToggleEditor={ toggleEditor }
        textDisplayed={ t('job_seekers:profile_languages.title') }
      />
      <div className='profile-languages__fields'>
        { greekLevelField }
        { englishLevelField }
        { otherLanguagesField }
      </div>
      { editorModal }
    </div>
  );
});

ProfileLanguages.displayName = 'ProfileLanguages';

ProfileLanguages.propTypes = {
  // Whether the user can edit the competencies
  canEdit: PropTypes.bool.isRequired,
  // The function ((object) => void) to invoke when the profile changes.
  onChange: PropTypes.func,
  // The profile of the job seeker.
  profile: PropTypes.shape({
    // The access level that the viewer has on the profile.
    accessLevel: PropTypes.oneOf(accessLevels),
    // The level that the job seeker knows English at.
    englishLevel: PropTypes.oneOf(languageLevels),
    // The level that the job seeker knows Greek at.
    greekLevel: PropTypes.oneOf(languageLevels),
    // The ISO 639-1 alpha-2 codes of the other languages that the job seeker knows.
    otherLanguages: PropTypes.arrayOf(PropTypes.oneOf(languages)),
  }),
};

ProfileLanguages.defaultProps = {};

export default ProfileLanguages;
