import clsx from 'clsx';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Confirm from 'components/common/confirm/confirm';
import ContactButton from 'components/common/contact-button/contact-button';
import OdysseaLogo from 'components/common/odyssea-logo/odyssea-logo';
import ProfileAbout from 'components/common/profile-about/profile-about';
import ProfileBackgroundImage from 'components/common/profile-background-image/profile-background-image';
import ProfileContactDetails from 'components/common/profile-contact-details/profile-contact-details';
import ProfileDetailsSection from 'components/common/profile-details-section/profile-details-section';
import ProfileImage from 'components/common/profile-image/profile-image';
import ProfileJobSectorsInterested
  from 'components/common/profile-job-sectors-interested/profile-job-sectors-interested';
import ProfileProfessions from 'components/common/profile-professions/profile-professions';
import SidebarProfileCompletion from 'components/common/sidebar-profile-completion/sidebar-profile-completion';
import ProfilePricingSection from 'components/companies/profile-pricing-section/profile-pricing-section';
import ProfileCompletedEndorsements
  from 'components/endorsers/profile-completed-endorsements/profile-completed-endorsements';
import ProfileProfessionalDetails from 'components/endorsers/profile-professional-details/profile-professional-details';
import ProfileCompetencies from 'components/job-seekers/profile-competencies/profile-competencies';
import ProfileEndorserFeedback from 'components/job-seekers/profile-endorser-feedback/profile-endorser-feedback';
import ProfileExperience from 'components/job-seekers/profile-experience/profile-experience';
import ProfileLanguages from 'components/job-seekers/profile-languages/profile-languages';
import ProfileQualifications from 'components/job-seekers/profile-qualifications/profile-qualifications';
import ProfileResume from 'components/job-seekers/profile-resume/profile-resume';
import ShareProfileButton from 'components/job-seekers/share-profile-button/share-profile-button';
import SeeExampleProfileButton from 'components/see-example-profile-button/see-example-profile-button';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as requestsActions } from 'ducks/requests';
import DownloadIcon from 'images/download.png';
import HiredIcon from 'images/hired.png';
import InterviewedIcon from 'images/interviewed.png';
import StarIconFilled from 'images/star-filled-white.png';
import StarIconOutlined from 'images/star-outlined-white.png';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as toasts from 'toasts';
import * as AccessLevels from 'utilities/access-levels';
import roles, * as Roles from 'utilities/auth/roles';
import { isEmpty, isNotEmpty } from 'utilities/chisels';
import * as interactionEventType from 'utilities/companies/interaction-event-type';
import * as companiesParts from 'utilities/companies/parts';
import * as PricingPlans from 'utilities/pricing-plans';

import './profile.scss';

const Profile = (props) => {
  const { role } = props;

  // The auth state object.
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The profiles state object
  const profiles = useSelector((store) => {
    return store.profiles;
  }, shallowEqual);

  // The professions state object
  const professions = useSelector((store) => {
    return store.professions;
  }, shallowEqual);

  // The requests state object
  const requests = useSelector((store) => {
    return store.requests;
  }, shallowEqual);

  // The job sectors state object
  const jobSectors = useSelector((store) => {
    return store.jobSectors;
  }, shallowEqual);

  // The skills state object
  const skills = useSelector((store) => {
    return store.skills;
  }, shallowEqual);

  // The hashtags state object
  const hashtags = useSelector((store) => {
    return store.hashtags;
  }, shallowEqual);

  // The endorsers state object
  const endorsers = useSelector((store) => {
    return store.endorsers;
  }, shallowEqual);

  // Whether the request is active
  const requestIsActive = useMemo(() => {
    return requests?.active;
  }, [ requests?.active ]);

  // Whether the signed-in user is deactivated.
  const userDeactivated = useMemo(() => {
    return auth?.deactivated;
  }, [ auth?.deactivated ]);

  // The ID of the signed-in user.
  const userId = useMemo(() => {
    return auth?.id;
  }, [ auth?.id ]);

  // Whether the user is authenticated.
  const userAuthenticated = useMemo(() => {
    return undefined !== userId;
  }, [ userId ]);

  // The signed-in user company id if he is a COMPANY_AGENT
  const userCompanyId = useMemo(() => {
    return auth?.companyId;
  }, [ auth?.companyId ]);

  // The role of the signed-in user.
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // The signed-in user profile
  const userProfile = useMemo(() => {
    return profiles?.profile;
  }, [ profiles?.profile ]);

  // the all job sectors
  const allJobSectors = useMemo(() => {
    return jobSectors?.jobSectors || [];
  }, [ jobSectors?.jobSectors ]);

  // The all professions
  const allProfessions = useMemo(() => {
    return professions?.professions;
  }, [ professions?.professions ]);

  // The all skills
  const allSkills = useMemo(() => {
    return skills?.skills;
  }, [ skills?.skills ]);

  // The all hashtags
  const allHashtags = useMemo(() => {
    return hashtags?.hashtags;
  }, [ hashtags?.hashtags ]);

  // The completed endorsements
  const completedEndorsements = useMemo(() => {
    return endorsers?.completedEndorsements;
  }, [ endorsers?.completedEndorsements ]);

  // The profile.
  const [ profile, setProfile ] = useState(undefined);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  // The ID of the job seeker that the profile belongs to.
  const { jobSeekerId, companyId, endorserId } = useParams();

  // The profile id to be updated based on the role
  const profileIdToBeUpdated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekerId;
    case Roles.COMPANY_AGENT:
      return companyId;
    case Roles.ENDORSER:
      return endorserId;
    default:
      return jobSeekerId;
    }
  }, [ companyId, endorserId, jobSeekerId, role ]);

  // The strict access levels
  const strictAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // The lean access levels
  const leanAccessLevels = useMemo(() => {
    return [
      AccessLevels.ACCESS_LEVEL_1,
      AccessLevels.ACCESS_LEVEL_2,
      AccessLevels.ACCESS_LEVEL_3,
    ];
  }, []);

  // the equality on the id's based on role to see whether the profile is the signed in user's profile
  const idsAreEqual = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekerId === userId;
    case Roles.COMPANY_AGENT:
      return companyId === userCompanyId;
    case Roles.ENDORSER:
      return endorserId === userId;
    default:
      return false;
    }
  }, [ companyId, endorserId, jobSeekerId, role, userCompanyId, userId ]);

  // Whether the profile is the current signed-in user's
  const isCurrentSignedInUserProfile = useMemo(() => {
    return userAuthenticated && idsAreEqual;
  }, [ idsAreEqual, userAuthenticated ]);

  // Whether the user can edit the profile
  const canEdit = useMemo(() => {
    return !userDeactivated && (isCurrentSignedInUserProfile || Roles.ADMINISTRATOR === userRole);
  }, [ isCurrentSignedInUserProfile, userDeactivated, userRole ]);

  // Function that is invoked when the pricing plans button on the free tier banner is clicked
  const handleOnPricingPlansButtonClick = useCallback(() => {
    navigate('/companies/learn-more#pricing-plans');
  }, [ navigate ]);

  // The banner that is displayed when a user is company agent and has free pricing plan
  const freePricingPlanBanner = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT
      || userProfile?.pricingPlan !== PricingPlans.FREE
      || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='ody-profile__banner'>
        <div className='ody-profile__banner-content light'>
          <div className='ody-profile__banner-text'>
            { t('profile:banner.free_pricing_company_agent') }
          </div>
          <div className='ody-profile__banner-buttons-section'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white ody-profile__banner-button'
              onClick={ handleOnPricingPlansButtonClick }
              type='button'
            >
              { t('profile:banner.pricing_plans') }
            </button>
            <ContactButton
              className='btn-sm btn-rounded-sm btn-white ody-profile__banner-button'
            />
          </div>
        </div>
        <div className='ody-profile__banner-logo hidden-xs hidden-sm'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [ handleOnPricingPlansButtonClick, role, t, userProfile?.pricingPlan, userRole ]);

  // Function that is invoked when login/signUp button is pressed on the unauthenticated banner
  const handleOnLoginSignUpButtonClick = useCallback(() => {
    navigate('/sign-in');
  }, [ navigate ]);

  // The banner that is displayed when a user is not authenticated and previews the job seeker profile
  const unauthenticatedBanner = useMemo(() => {
    if (userAuthenticated || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='ody-profile__banner'>
        <div className='ody-profile__banner-content light'>
          <div className='ody-profile__banner-text'>
            { t('profile:banner.text') }
          </div>
          <div className='ody-profile__banner-buttons-section'>
            <button
              className='btn btn-sm btn-rounded-sm btn-white ody-profile__banner-button'
              onClick={ handleOnLoginSignUpButtonClick }
              type='button'
            >
              { t('profile:banner.login_sign_up_button_label') }
            </button>
            <SeeExampleProfileButton
              className='btn-sm btn-rounded-sm btn-white ody-profile__banner-button'
            />
          </div>
        </div>
        <div className='ody-profile__banner-logo hidden-xs hidden-sm'>
          <OdysseaLogo />
        </div>
      </div>
    );
  }, [ handleOnLoginSignUpButtonClick, role, t, userAuthenticated ]);

  // The public or private method of the job seeker get profile
  const jobSeekerGetProfileMethod = useMemo(() => {
    if (userAuthenticated) {
      return jobSeekersMethods.getProfile;
    }
    return jobSeekersMethods.getPublicProfile;
  }, [ userAuthenticated ]);

  // The public or private method of the companies get profile
  const companiesGetProfileMethod = useMemo(() => {
    if (userAuthenticated) {
      return companiesMethods.getProfile;
    }
    return companiesMethods.getPublicProfile;
  }, [ userAuthenticated ]);

  // The public or private method of the endorsers get profile
  const endorsersGetProfileMethod = useMemo(() => {
    if (userAuthenticated) {
      return endorsersMethods.getProfile;
    }
    return endorsersMethods.getPublicProfile;
  }, [ userAuthenticated ]);

  // The update profile method based on the role
  const updateProfileMethod = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersMethods.updateProfile;
    case Roles.COMPANY_AGENT:
      return companiesMethods.updateProfile;
    case Roles.ENDORSER:
      return endorsersMethods.updateProfile;
    default:
      return jobSeekersMethods.updateProfile;
    }
  }, [ role ]);

  // function that updates the local profile
  const updateLocalProfile = useCallback((profile) => {
    setProfile(profile);
    if (isCurrentSignedInUserProfile) {
      dispatch(profilesActions.setProfile(profile));
    }
  }, [ dispatch, isCurrentSignedInUserProfile ]);

  // Function that updates the profile
  const onUpdateProfile = useCallback((paramsToBeUpdated, partToBeUpdated, isToBeUpdated = false, file = null) => {
    if (!isToBeUpdated) {
      return;
    }
    dispatch(requestsActions.request(updateProfileMethod, {
      ...(null !== file && {
        file: file[0],
      }),
      part: partToBeUpdated,
      profile: {
        ...paramsToBeUpdated,
        id: profileIdToBeUpdated,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:common.general_error'));
      },
      onSuccess: (result) => {
        toasts.info(t('profile:common.changes_saved_message'));
        updateLocalProfile(result);
      },
    }));
  }, [ dispatch, profileIdToBeUpdated, t, updateLocalProfile, updateProfileMethod ]);

  // function that handles to fetch the profile public or private of the job seeker
  const fetchJobSeekerProfile = useCallback(() => {
    dispatch(requestsActions.request(jobSeekerGetProfileMethod, {
      id: jobSeekerId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:failed_to_fetch_profile'));
        navigate(userAuthenticated ? '/dashboard' : '/home');
      },
      onSuccess: (result) => {
        setProfile(result);
        // This is the profile of the signed-in user. Keep it.
        if (isCurrentSignedInUserProfile) {
          dispatch(profilesActions.setProfile(result));
        }
      },
    }));
  }, [
    dispatch,
    isCurrentSignedInUserProfile,
    jobSeekerGetProfileMethod,
    jobSeekerId,
    navigate,
    t,
    userAuthenticated,
  ]);

  // function that handles to fetch the profile public or private of the company
  const fetchCompanyProfile = useCallback(() => {
    dispatch(requestsActions.request(companiesGetProfileMethod, {
      id: companyId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:failed_to_fetch_profile'));
        navigate(userAuthenticated ? '/dashboard' : '/home');
      },
      onSuccess: (result) => {
        setProfile(result);
        // This is the profile of the signed-in user. Keep it.
        if (isCurrentSignedInUserProfile) {
          dispatch(profilesActions.setProfile(result));
        }
      },
    }));
  }, [
    companiesGetProfileMethod,
    companyId,
    dispatch,
    isCurrentSignedInUserProfile,
    navigate,
    t,
    userAuthenticated,
  ]);

  // function that handles to fetch the profile public or private of the endorser
  const fetchEndorserProfile = useCallback(() => {
    dispatch(requestsActions.request(endorsersGetProfileMethod, {
      id: endorserId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:failed_to_fetch_profile'));
        navigate(userAuthenticated ? '/dashboard' : '/home');
      },
      onSuccess: (result) => {
        setProfile(result);
        // This is the profile of the signed-in user. Keep it.
        if (isCurrentSignedInUserProfile) {
          dispatch(profilesActions.setProfile(result));
        }
      },
    }));
  }, [
    dispatch,
    endorserId,
    endorsersGetProfileMethod,
    isCurrentSignedInUserProfile,
    navigate,
    t,
    userAuthenticated,
  ]);

  // function that fetches the completed enoorsements
  const fetchCompletedEndorsements = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getCompletedEndorsements, {
      id: endorserId,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:failed_to_fetch_completed_endorsements'));
      },
      onSuccess: (result) => {
        dispatch(endorsersActions.setCompletedEndorsements(result.endorsements));
      },
    }));
  }, [ dispatch, endorserId, t ]);

  useEffect(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return fetchJobSeekerProfile();
    case Roles.COMPANY_AGENT:
      return fetchCompanyProfile();
    case Roles.ENDORSER:
      if (canEdit) {
        fetchCompletedEndorsements();
      }
      return fetchEndorserProfile();
    default:
      return fetchJobSeekerProfile();
    }
  }, [
    canEdit,
    fetchCompanyProfile,
    fetchCompletedEndorsements,
    fetchEndorserProfile,
    fetchJobSeekerProfile,
    role,
  ]);

  // The profile details section
  const personalDetailsSection = useMemo(() => {
    return (
      <ProfileDetailsSection
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        role={ role }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, role ]);

  // whether to render job seeker contact details
  const renderJobSeekerContactDetails = useMemo(() => {
    return (profile?.phoneNumbers?.length || profile?.email !== undefined || profile?.linkedinProfileUrl !== undefined);
  }, [ profile?.email, profile?.linkedinProfileUrl, profile?.phoneNumbers?.length ]);

  // Whether to render company contact details
  const renderCompanyContactDetails = useMemo(() => {
    return profile?.firstName !== undefined
      || profile?.lastName !== undefined
      || profile?.companyPosition !== undefined
      || profile?.email !== undefined
      || profile?.phoneNumber !== undefined;
  }, [ profile?.companyPosition, profile?.email, profile?.firstName, profile?.lastName, profile?.phoneNumber ]);

  // whether to render the contact details
  const renderContactDetails = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return renderJobSeekerContactDetails && strictAccessLevels.includes(profile?.accessLevel);
    case Roles.COMPANY_AGENT:
      return renderCompanyContactDetails && leanAccessLevels.includes(profile?.accessLevel);
    default:
      return false;
    }
  }, [
    leanAccessLevels,
    profile?.accessLevel,
    renderCompanyContactDetails,
    renderJobSeekerContactDetails,
    role,
    strictAccessLevels,
  ]);

  // The profile contact details section
  const contactDetailsSection = useMemo(() => {
    if ((!canEdit && !renderContactDetails) || role === Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileContactDetails
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        role={ role }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderContactDetails, role ]);

  // The profile job sectors interested in
  const jobSectorsInterestedIn = useMemo(() => {
    if ((!canEdit && !profile?.jobSectors?.length) || role === Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileJobSectorsInterested
        allJobSectors={ allJobSectors }
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        role={ role }
      />
    );
  }, [ allJobSectors, canEdit, onUpdateProfile, profile, role ]);

  // whether to render the sidebar professions that the user is interested
  const renderedSidebarProfessions = useMemo(() => {
    if ((!canEdit && !profile?.professions?.length) || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileProfessions
        allProfessions={ allProfessions }
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
      />
    );
  }, [ allProfessions, canEdit, onUpdateProfile, profile, role ]);

  // whether to render the main professions that the user is interested
  const renderedMainProfessions = useMemo(() => {
    if ((!canEdit && !profile?.professions?.length) || role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <ProfileProfessions
        allProfessions={ allProfessions }
        canEdit={ canEdit }
        isMainSection
        onChange={ onUpdateProfile }
        profile={ profile }
      />
    );
  }, [ allProfessions, canEdit, onUpdateProfile, profile, role ]);

  // Whether to render the professional info
  const renderProfessionalInfo = useMemo(() => {
    return profile?.companyName !== undefined
      || profile?.companyPosition !== undefined
      || profile?.website !== undefined
      || profile?.phoneNumber !== undefined
      || profile?.location !== undefined
      || profile?.linkedinUrl !== undefined;
  }, [
    profile?.companyName,
    profile?.companyPosition,
    profile?.linkedinUrl,
    profile?.location,
    profile?.phoneNumber,
    profile?.website,
  ]);

  // Whether to render the professional section when the role is ENDORSER
  const renderedProfessionalSection = useMemo(() => {
    if ((!canEdit && !renderProfessionalInfo) || role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <ProfileProfessionalDetails
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        role={ role }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderProfessionalInfo, role ]);

  // whether to render my endorsements section when the role is ENDORSER
  const myEndorsementsSection = useMemo(() => {
    if (role !== Roles.ENDORSER || !canEdit) {
      return null;
    }
    return (
      <ProfileCompletedEndorsements
        activeRequest={ requestIsActive }
        allHashtags={ allHashtags }
        endorsements={ completedEndorsements }
        profile={ profile }
      />
    );
  }, [ allHashtags, canEdit, completedEndorsements, profile, requestIsActive, role ]);

  // The profile completion
  const profileCompletion = useMemo(() => {
    if (!canEdit && (!strictAccessLevels.includes(profile?.accessLevel) || role === Roles.COMPANY_AGENT)) {
      return null;
    }
    return (
      <SidebarProfileCompletion
        profile={ profile }
        role={ role }
      />
    );
  }, [ canEdit, profile, role, strictAccessLevels ]);

  // Whether to render profile description based on the role of the component
  const renderProfileDescription = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return profile?.shortBio !== undefined;
    case Roles.COMPANY_AGENT:
      return profile?.description !== undefined;
    default:
      return false;
    }
  }, [ profile?.description, profile?.shortBio, role ]);

  // whether to render the about section
  const renderedAboutSection = useMemo(() => {
    if (!canEdit && isEmpty(profile?.video) && !renderProfileDescription) {
      return null;
    }
    return (
      <ProfileAbout
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        role={ role }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderProfileDescription, role ]);

  // whether to render pricing section
  const renderPricingSection = useMemo(() => {
    return profile?.address !== undefined
      || profile?.legalName !== undefined
      || profile?.occupation !== undefined
      || profile?.pricingPlan !== undefined
      || profile?.taxOffice !== undefined
      || profile?.vatNumber !== undefined;
  }, [
    profile?.address,
    profile?.legalName,
    profile?.occupation,
    profile?.pricingPlan,
    profile?.taxOffice,
    profile?.vatNumber,
  ]);

  // whether to render the pricing section
  const profilePricingSection = useMemo(() => {
    if ((!canEdit && !renderPricingSection) || role !== Roles.COMPANY_AGENT || userRole !== Roles.ADMINISTRATOR) {
      return null;
    }
    return (
      <ProfilePricingSection
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        userRole={ userRole }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderPricingSection, role, userRole ]);

  // Whether to render the competencies
  const renderCompetencies = useMemo(() => {
    return profile?.computerSkillsLevel !== undefined
      || !profile?.drivingLicenseCategories?.length
      || profile?.authorizedToWork !== undefined
      || profile?.workAvailability !== undefined
      || profile?.willingToRelocate !== undefined;
  }, [
    profile?.authorizedToWork,
    profile?.computerSkillsLevel,
    profile?.drivingLicenseCategories?.length,
    profile?.willingToRelocate,
    profile?.workAvailability,
  ]);

  // Whether to render the job seeker competencies section
  const profileCompetenciesSection = useMemo(() => {
    if ((!canEdit && !renderCompetencies) || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileCompetencies
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
        userRole={ userRole }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderCompetencies, role, userRole ]);

  // whether to render the languages section
  const renderLanguages = useMemo(() => {
    return profile?.greekLevel !== undefined
      || profile?.englishLevel !== undefined
      || isNotEmpty(profile?.otherLanguages);
  }, [ profile?.englishLevel, profile?.greekLevel, profile?.otherLanguages ]);

  // Whether to render the languages section in the job seeker profile
  const languagesSection = useMemo(() => {
    if ((!canEdit && !renderLanguages) || role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <ProfileLanguages
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
      />
    );
  }, [ canEdit, onUpdateProfile, profile, renderLanguages, role ]);

  // Function that downloads the resume
  const onDownloadResume = useCallback(() => {
    dispatch(requestsActions.request(jobSeekersMethods.getResume, {
      id: jobSeekerId,
    }, {
      onFailure: () => {
        toasts.error(t('job_seekers:profile_resume.download_failed_error'));
      },
      onSuccess: (result) => {
        fileDownload(result.data, result.filename);
      },
    }));
  }, [ dispatch, jobSeekerId, t ]);

  // Whether to render the resume section in the job seeker profile
  const resumeSection = useMemo(() => {
    if ((!canEdit && isEmpty(profile?.resume))
      || role !== Roles.JOB_SEEKER
      || !strictAccessLevels.includes(profile?.accessLevel)) {
      return null;
    }
    return (
      <ProfileResume
        canEdit={ canEdit }
        jobSeekerId={ jobSeekerId }
        onChange={ onUpdateProfile }
        onDownloadResume={ onDownloadResume }
        profile={ profile }
      />
    );
  }, [ canEdit, jobSeekerId, onDownloadResume, onUpdateProfile, profile, role, strictAccessLevels ]);

  // The rendered profile qualifications
  const renderedProfileQualifications = useMemo(() => {
    if ((!canEdit && !profile?.qualifications?.length)
      || role !== Roles.JOB_SEEKER
      || !leanAccessLevels.includes(profile?.accessLevel)) {
      return null;
    }
    return (
      <ProfileQualifications
        allSkills={ allSkills }
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
      />
    );
  }, [ allSkills, canEdit, leanAccessLevels, onUpdateProfile, profile, role ]);

  // Function that handles the request endorsement
  const handleRequestEndorsement = useCallback(() => {
    dispatch(requestsActions.request(jobSeekersMethods.requestEndorsement,
      {},
      {
        onFailure: () => {
          toasts.error(t('profile:failed_endorsement_request'));
        },
        onSuccess: () => {
          toasts.info(t('profile:endorsement_request_success'));
          if (isCurrentSignedInUserProfile) {
            setProfile((profile) => {
              return {
                ...profile,
                allowedToRequestEndorsement: false,
              };
            });
            dispatch(profilesActions.setAllowedToRequestEndorsement(false));
          }
        },
      }));
  }, [ dispatch, isCurrentSignedInUserProfile, t ]);

  // The endorser feedback section
  const endorserFeedbackSection = useMemo(() => {
    if (role !== Roles.JOB_SEEKER || (!canEdit && !profile?.endorserFeedback)) {
      return null;
    }
    return (
      <ProfileEndorserFeedback
        allHashtags={ allHashtags }
        isCurrentSignedInUserProfile={ isCurrentSignedInUserProfile }
        onRequestEndorsement={ handleRequestEndorsement }
        profile={ profile }
      />
    );
  }, [ allHashtags, canEdit, handleRequestEndorsement, isCurrentSignedInUserProfile, profile, role ]);

  // The rendered profile experience
  const renderedProfileExperience = useMemo(() => {
    if ((!canEdit && !profile?.experience?.length)
      || role !== Roles.JOB_SEEKER
      || !leanAccessLevels.includes(profile?.accessLevel)) {
      return null;
    }
    return (
      <ProfileExperience
        allSkills={ allSkills }
        canEdit={ canEdit }
        onChange={ onUpdateProfile }
        profile={ profile }
      />
    );
  }, [ allSkills, canEdit, leanAccessLevels, onUpdateProfile, profile, role ]);

  // Function that is invoked when the download button is clicked
  const handleOnDownloadProfileClick = useCallback(() => {
    dispatch(requestsActions.request(jobSeekersMethods.exportProfile, {
      id: jobSeekerId,
    }, {
      onFailure: () => {
        toasts.error(t('profile:download_failed_error'));
      },
      onSuccess: (result) => {
        fileDownload(result.data, result.filename);
      },
    }));
  }, [ dispatch, t, jobSeekerId ]);

  // Filtered saved job seekers
  const filteredSavedJobSeekers = useMemo(() => {
    return userProfile?.savedJobSeekers?.filter((savedJobSeekerId) => {
      return savedJobSeekerId !== profile?.id;
    });
  }, [ profile?.id, userProfile?.savedJobSeekers ]);

  // Whether the current job seeker id is in the list of company agent saved job seekers
  const foundSavedJobSeeker = useMemo(() => {
    return userProfile?.savedJobSeekers?.find((savedJobSeeker) => {
      return savedJobSeeker === profile?.id;
    });
  }, [ profile?.id, userProfile?.savedJobSeekers ]);

  // function that is invoked when saving a job seeker as a company agent
  const handleSaveJobSeeker = useCallback(() => {
    const savedJobSeekersList = undefined !== foundSavedJobSeeker ? filteredSavedJobSeekers : [
      ...userProfile.savedJobSeekers,
      profile?.id,
    ];
    dispatch(requestsActions.request(companiesMethods.updateProfile, {
      part: companiesParts.SAVED_JOB_SEEKERS,
      profile: {
        id: userProfile?.id,
        savedJobSeekers: savedJobSeekersList,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:common.general_error'));
      },
      onSuccess: (result) => {
        toasts.info(t('profile:common.changes_saved_message'));
        dispatch(profilesActions.setProfile(result));
      },
    }));
  }, [
    dispatch,
    filteredSavedJobSeekers,
    foundSavedJobSeeker,
    profile?.id,
    t,
    userProfile?.id,
    userProfile?.savedJobSeekers,
  ]);

  // The save profile button image source
  const saveProfileButtonImageSource = useMemo(() => {
    return foundSavedJobSeeker ? StarIconFilled : StarIconOutlined;
  }, [ foundSavedJobSeeker ]);

  // The save profile button label
  const savedProfileButtonLabel = useMemo(() => {
    return foundSavedJobSeeker
      ? t('profile:unsave_button_title')
      : t('profile:save_button_title');
  }, [ foundSavedJobSeeker, t ]);

  // The save profile button
  const saveProfileButton = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <button
        className='ody-profile__profile-button btn btn-sm btn-rounded-sm btn-red'
        onClick={ handleSaveJobSeeker }
        type='button'
      >
        <img
          alt='save profile'
          className='ody-profile__profile-button-icon'
          src={ saveProfileButtonImageSource }
        />
        { savedProfileButtonLabel }
      </button>
    );
  }, [ handleSaveJobSeeker, saveProfileButtonImageSource, savedProfileButtonLabel, userRole ]);

  // function that is invoked when interviewed button is clicked
  const handleOnConfirmInterviewed = useCallback(() => {
    dispatch(requestsActions.request(companiesMethods.interact, {
      companyId: userCompanyId,
      interactionEventType: interactionEventType.INTERVIEWED,
      jobSeekerId: profile?.id,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:interviewed_error'));
      },
      onSuccess: () => {
        toasts.info(t('profile:interviewed_success'));
      },
    }));
  }, [ dispatch, profile?.id, t, userCompanyId ]);

  // The interviewed profile button
  const interviewedProfileButton = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <Confirm
        className='profile-confirm'
        onConfirm={ handleOnConfirmInterviewed }
        text={ t('profile:confirm_interviewed') }
        trigger={
          <button
            className='ody-profile__profile-button btn btn-sm btn-rounded-sm btn-yellow'
            type='button'
          >
            <img
              alt='save profile'
              className='ody-profile__profile-button-icon'
              src={ InterviewedIcon }
            />
            { t('profile:interviewed_button_title') }
          </button>
        }
      />
    );
  }, [ handleOnConfirmInterviewed, t, userRole ]);

  // function that is invoked when hired button is clicked
  const handleOnConfirmHired = useCallback(() => {
    dispatch(requestsActions.request(companiesMethods.interact, {
      companyId: userCompanyId,
      interactionEventType: interactionEventType.HIRED,
      jobSeekerId: profile?.id,
    }, {
      onFailure: (_error) => {
        toasts.error(t('profile:hired_error'));
      },
      onSuccess: () => {
        toasts.info(t('profile:hired_success'));
      },
    }));
  }, [ dispatch, profile?.id, t, userCompanyId ]);

  // The hired profile button
  const hiredProfileButton = useMemo(() => {
    if (userRole !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <Confirm
        className='profile-confirm'
        onConfirm={ handleOnConfirmHired }
        text={ t('profile:confirm_hired') }
        trigger={
          <button
            className='profile-button btn btn-sm btn-rounded-sm btn-purple'
            type='button'
          >
            <img
              alt='save profile'
              className='profile-button-icon'
              src={ HiredIcon }
            />
            { t('profile:hired_button_title') }
          </button>
        }
      />
    );
  }, [ handleOnConfirmHired, t, userRole ]);

  // The rendered download profile button
  const renderedDownloadProfileButton = useMemo(() => {
    // If the user is not authenticated and the profile is not from a job seeker
    if (!userAuthenticated || role !== Roles.JOB_SEEKER) {
      return null;
    }
    // If the signed-in user role is JOB SEEKER, but he is not on his profile
    if (userRole === Roles.JOB_SEEKER && !isCurrentSignedInUserProfile) {
      return null;
    }
    return (
      <button
        className='btn btn-sm btn-rounded-sm btn-blue ody-profile__profile-button'
        onClick={ handleOnDownloadProfileClick }
        type='button'
      >
        <img
          alt='download icon'
          className='ody-profile__profile-button-icon'
          src={ DownloadIcon }
        />
        { t('profile:download_button_label') }
      </button>
    );
  }, [
    handleOnDownloadProfileClick,
    isCurrentSignedInUserProfile,
    role,
    t,
    userAuthenticated,
    userRole,
  ]);

  // The rendered buttons' section of the job seeker profile
  const renderedButtonsSection = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='ody-profile__buttons-section'>
        <ShareProfileButton
          className='btn-sm btn-rounded-sm btn-blue'
          jobSeekerId={ jobSeekerId }
          variant='white'
        />
        { renderedDownloadProfileButton }
        { saveProfileButton }
        { interviewedProfileButton }
        { hiredProfileButton }
      </div>
    );
  }, [
    hiredProfileButton,
    interviewedProfileButton,
    jobSeekerId,
    renderedDownloadProfileButton,
    role,
    saveProfileButton,
  ]);

  // The main profile section
  const mainProfileSection = useMemo(() => {
    if (profile === undefined) {
      return null;
    }
    return (
      <div className='ody-profile__profile-section'>
        <div className='ody-profile__profile-section--left'>
          <div className='ody-profile__images-section'>
            <ProfileBackgroundImage
              canEdit={ canEdit }
              onBackgroundImageChange={ onUpdateProfile }
              profile={ profile }
              role={ role }
            />
            <ProfileImage
              canEdit={ canEdit }
              onProfilePictureChange={ onUpdateProfile }
              profile={ profile }
              role={ role }
            />
          </div>
          <div className='ody-profile__information-section'>
            { personalDetailsSection }
            { renderedProfessionalSection }
            { contactDetailsSection }
            { jobSectorsInterestedIn }
            { renderedSidebarProfessions }
            { languagesSection }
            { profileCompetenciesSection }
            { resumeSection }
            { profilePricingSection }
            { profileCompletion }
          </div>
          { renderedButtonsSection }
        </div>
        <div className='ody-profile__profile-section--right'>
          { renderedAboutSection }
          { endorserFeedbackSection }
          { renderedProfileExperience }
          { renderedProfileQualifications }
          { renderedMainProfessions }
          { myEndorsementsSection }
        </div>
      </div>
    );
  }, [
    profile,
    canEdit,
    onUpdateProfile,
    role,
    personalDetailsSection,
    renderedProfessionalSection,
    contactDetailsSection,
    jobSectorsInterestedIn,
    renderedSidebarProfessions,
    languagesSection,
    profileCompetenciesSection,
    resumeSection,
    profilePricingSection,
    profileCompletion,
    renderedButtonsSection,
    renderedAboutSection,
    endorserFeedbackSection,
    renderedProfileExperience,
    renderedProfileQualifications,
    renderedMainProfessions,
    myEndorsementsSection,
  ]);

  return (
    <div className={
      clsx('ody-profile', {
        'ody-profile--not-found': profile === undefined && !requestIsActive,
      })
    }
    >
      { freePricingPlanBanner }
      { unauthenticatedBanner }
      { mainProfileSection }
    </div>
  );
};

Profile.propTypes = {
  // The role of the component based on route
  role: PropTypes.oneOf(roles),
};

Profile.defaultProps = {};

export default Profile;
