import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ClothesAnimation from 'animations/clothes.json';
import LottieAnimationContainer from 'components/common/lottie-animation-container/lottie-animation-container';
import Modal from 'components/common/modal/modal';
import ExampleEndorsement from 'images/example-endorsement.png';

import './learn-more-become-endorser-partner-section.scss';

const LearnMoreBecomeEndorserPartnerSection = memo((props) => {
  const { title, description, userAuthenticated, joinUsButtonLabel, exampleEndorsementButtonLabel } = props;

  // The rendered button link
  const renderedButtonLink = useMemo(() => {
    if (userAuthenticated) {
      return null;
    }
    return (
      <Link
        className='btn btn-sm btn-rounded-sm btn-white learn-more-become-endorser-partner-section__join-us-button'
        to='/endorsers/sign-up'
      >
        { joinUsButtonLabel }
      </Link>
    );
  }, [ joinUsButtonLabel, userAuthenticated ]);

  return (
    <div className='learn-more-become-endorser-partner-section'>
      <Modal
        className='see-example-endorsement-modal'
        trigger={
          <button
            className='btn btn-sm btn-rounded-sm btn-white
            learn-more-become-endorser-partner-section__example-endorsement-button'
            id='see-example-endorsement'
            name='See example endorsement'
            type='button'
          >
            { exampleEndorsementButtonLabel }
          </button>
        }
      >
        <div className='see-example-endorsement'>
          <div className='image'>
            <img alt='Example Profile' src={ ExampleEndorsement } />
          </div>
        </div>
      </Modal>
      <div className='learn-more-become-endorser-partner-section__content'>
        <h2 className='hdg hdg-xxl learn-more-become-endorser-partner-section__title'>
          { title }
        </h2>
        <p className='txt txt-md learn-more-become-endorser-partner-section__description'>
          { description }
        </p>
        { renderedButtonLink }
      </div>
      <LottieAnimationContainer
        animatedIconClassName='learn-more-become-endorser-partner-section__animated-icon'
        animationData={ ClothesAnimation }
        className='learn-more-become-endorser-partner-section__animation-container'
      />
    </div>
  );
});

LearnMoreBecomeEndorserPartnerSection.displayName = 'LearnMoreBecomeEndorserPartnerSection';

LearnMoreBecomeEndorserPartnerSection.propTypes = {
  // The section description
  description: PropTypes.string.isRequired,
  // The example endorsement button label
  exampleEndorsementButtonLabel: PropTypes.string.isRequired,
  // The join us button label
  joinUsButtonLabel: PropTypes.string.isRequired,
  // The section title
  title: PropTypes.string.isRequired,
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
};

LearnMoreBecomeEndorserPartnerSection.defaultProps = {};

export default LearnMoreBecomeEndorserPartnerSection;
