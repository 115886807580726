/**
 * Profile parts.
 *
 * @module utilities/job-seekers/parts
 */

/**
 * About me.
 *
 * @constant {string}
 * @static
 */
export const ABOUT_ME = 'ABOUT_ME';

/**
 * Background image.
 *
 * @constant {string}
 * @static
 */
export const BACKGROUND_IMAGE = 'BACKGROUND_IMAGE';

/**
 * Competencies.
 *
 * @constant {string}
 * @static
 */
export const COMPETENCIES = 'COMPETENCIES';

/**
 * Contact.
 *
 * @constant {string}
 * @static
 */
export const CONTACT = 'CONTACT';

/**
 * Experience.
 *
 * @constant {string}
 * @static
 */
export const EXPERIENCE = 'EXPERIENCE';

/**
 * Job sectors.
 *
 * @constant {string}
 * @static
 */
export const JOB_SECTORS = 'JOB_SECTORS';

/**
 * Languages.
 *
 * @constant {string}
 * @static
 */
export const LANGUAGES = 'LANGUAGES';

/**
 * Personal.
 *
 * @constant {string}
 * @static
 */
export const PERSONAL = 'PERSONAL';

/**
 * Professions.
 *
 * @constant {string}
 * @static
 */
export const PROFESSIONS = 'PROFESSIONS';

/**
 * Profile picture.
 *
 * @constant {string}
 * @static
 */
export const PROFILE_PICTURE = 'PROFILE_PICTURE';

/**
 * Resume.
 *
 * @constant {string}
 * @static
 */
export const RESUME = 'RESUME';

/**
 * Qualifications.
 *
 * @constant {string}
 * @static
 */
export const QUALIFICATIONS = 'QUALIFICATIONS';

/**
 * Work availability.
 *
 * @constant {string}
 * @static
 */
export const WORK_AVAILABILITY = 'WORK_AVAILABILITY';

/**
 * SHORT_BIO.
 *
 * @constant {string}
 * @static
 */
export const SHORT_BIO = 'SHORT_BIO';

export default [
  ABOUT_ME,
  BACKGROUND_IMAGE,
  COMPETENCIES,
  CONTACT,
  EXPERIENCE,
  JOB_SECTORS,
  LANGUAGES,
  PERSONAL,
  PROFESSIONS,
  PROFILE_PICTURE,
  RESUME,
  QUALIFICATIONS,
  WORK_AVAILABILITY,
  SHORT_BIO,
];
