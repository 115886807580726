import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import SearchFilters from 'components/common/search-filters/search-filters';
import SearchForm from 'components/common/search-form/search-form';
import SearchPages from 'components/common/search-pages/search-pages';
import SearchSelectedFilters from 'components/common/search-selected-filters/search-selected-filters';
import SearchSort from 'components/common/search-sort/search-sort';
import SearchTotal from 'components/common/search-total/search-total';
import constants from 'config/constants';
import { actions as companiesActions } from 'ducks/companies';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as jobSeekersActions } from 'ducks/job-seekers';
import { actions as requestsActions } from 'ducks/requests';
import wavesImage from 'images/waves.png';
import * as companiesMethods from 'resources/companies';
import * as endorsersMethods from 'resources/endorsers';
import * as jobSeekersMethods from 'resources/job-seekers';
import * as toasts from 'toasts';
import roles, * as Roles from 'utilities/auth/roles';
import {
  areNotEqual,
  fromUrlSearchParams,
  isEmpty, isNotEmpty,
  parseRange,
  sanitizeParams,
  toUrlSearchParams,
} from 'utilities/chisels';
import * as CompaniesFilterTypes from 'utilities/companies/filter-types';
import { DEFAULT_SORT_KEY as CompaniesDefaultSortKey } from 'utilities/companies/sort-keys';
import * as CriterionTypes from 'utilities/criterion-types';
import * as EndorsersFilterTypes from 'utilities/endorsers/filter-types';
import { DEFAULT_SORT_KEY as EndorsersDefaultSortKey } from 'utilities/endorsers/sort-keys';
import * as JobSeekersFilterTypes from 'utilities/job-seekers/filter-types';
import { DEFAULT_SORT_KEY, DEFAULT_SORT_KEY as JobSeekersDefaultSortKey } from 'utilities/job-seekers/sort-keys';
import { DEFAULT_SORT_DIRECTION } from 'utilities/sort-directions';

import Results from './results/results';

import './search.scss';

const Search = (props) => {
  const { role } = props;

  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The job seekers state object
  const jobSeekers = useSelector((store) => {
    return store.jobSeekers;
  }, shallowEqual);

  // The companies state object
  const companies = useSelector((store) => {
    return store.companies;
  }, shallowEqual);

  // The endorsers state object
  const endorsers = useSelector((store) => {
    return store.endorsers;
  }, shallowEqual);

  // The job sectors state object
  const jobSectors = useSelector((store) => {
    return store.jobSectors;
  }, shallowEqual);

  // The professions state object
  const professions = useSelector((store) => {
    return store.professions;
  }, shallowEqual);

  // The profiles state object
  const profiles = useSelector((store) => {
    return store.profiles;
  }, shallowEqual);

  // The signed in user id
  const userId = useMemo(() => {
    return auth?.id;
  }, [ auth?.id ]);

  // The signed in user role
  const userRole = useMemo(() => {
    return auth?.role;
  }, [ auth?.role ]);

  // The signed in user company id if he has a role of a COMPANY_AGENT
  const userCompanyId = useMemo(() => {
    return auth?.companyId;
  }, [ auth?.companyId ]);

  // All the job sectors
  const allJobSectors = useMemo(() => {
    return jobSectors?.jobSectors;
  }, [ jobSectors?.jobSectors ]);

  // All the professions
  const allProfessions = useMemo(() => {
    return professions?.professions;
  }, [ professions?.professions ]);

  // Whether the user is authentication
  const userAuthenticated = useMemo(() => {
    return undefined !== userId;
  }, [ userId ]);

  // The profile saved job seekers when my user role is COMPANY_AGENT
  const savedJobSeekers = useMemo(() => {
    return profiles?.profile?.savedJobSeekers;
  }, [ profiles?.profile?.savedJobSeekers ]);

  // the job seekers search parameters
  const jobSeekersParams = useMemo(() => {
    return {
      criteria: jobSeekers?.criteria,
      filters: jobSeekers?.filters,
      page: jobSeekers?.page,
      sort: jobSeekers?.sort,
    };
  }, [ jobSeekers?.criteria, jobSeekers?.filters, jobSeekers?.page, jobSeekers?.sort ]);

  // The job seekers results
  const jobSeekersResults = useMemo(() => {
    return jobSeekers?.profiles;
  }, [ jobSeekers?.profiles ]);

  // The job seekers facets
  const jobSeekersFacets = useMemo(() => {
    return jobSeekers?.facets;
  }, [ jobSeekers?.facets ]);

  // The job seekers page
  const jobSeekersPage = useMemo(() => {
    return jobSeekers?.meta?.page?.number;
  }, [ jobSeekers?.meta?.page?.number ]);

  // The job seekers total results
  const jobSeekersTotal = useMemo(() => {
    return jobSeekers?.meta?.total;
  }, [ jobSeekers?.meta?.total ]);

  // the companies search parameters
  const companiesParams = useMemo(() => {
    return {
      criteria: companies?.criteria,
      filters: companies?.filters,
      page: companies?.page,
      sort: companies?.sort,
    };
  }, [ companies?.criteria, companies?.filters, companies?.page, companies?.sort ]);

  // The companies results
  const companiesResults = useMemo(() => {
    return companies?.profiles;
  }, [ companies?.profiles ]);

  // The companies facets
  const companiesFacets = useMemo(() => {
    return companies?.facets;
  }, [ companies?.facets ]);

  // The companies page
  const companiesPage = useMemo(() => {
    return companies?.meta?.page?.number;
  }, [ companies?.meta?.page?.number ]);

  // The companies total results
  const companiesTotal = useMemo(() => {
    return companies?.meta?.total;
  }, [ companies?.meta?.total ]);

  // the endorsers search parameters
  const endorsersParams = useMemo(() => {
    return {
      criteria: endorsers?.criteria,
      filters: endorsers?.filters,
      page: endorsers?.page,
      sort: endorsers?.sort,
    };
  }, [ endorsers?.criteria, endorsers?.filters, endorsers?.page, endorsers?.sort ]);

  // The endorsers results
  const endorsersResults = useMemo(() => {
    return endorsers?.profiles;
  }, [ endorsers?.profiles ]);

  // The endorsers facets
  const endorsersFacets = useMemo(() => {
    return endorsers?.facets;
  }, [ endorsers?.facets ]);

  // The endorsers page
  const endorsersPage = useMemo(() => {
    return endorsers?.meta?.page?.number;
  }, [ endorsers?.meta?.page?.number ]);

  // The endorsers total results
  const endorsersTotal = useMemo(() => {
    return endorsers?.meta?.total;
  }, [ endorsers?.meta?.total ]);

  // The search parameters.
  const params = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersParams;
    case Roles.COMPANY_AGENT:
      return companiesParams;
    case Roles.ENDORSER:
      return endorsersParams;
    default:
      return {};
    }
  }, [ companiesParams, endorsersParams, jobSeekersParams, role ]);

  // The search results
  const results = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersResults;
    case Roles.COMPANY_AGENT:
      return companiesResults;
    case Roles.ENDORSER:
      return endorsersResults;
    default:
      return [];
    }
  }, [ companiesResults, endorsersResults, jobSeekersResults, role ]);

  // The search facets
  const facets = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersFacets;
    case Roles.COMPANY_AGENT:
      return companiesFacets;
    case Roles.ENDORSER:
      return endorsersFacets;
    default:
      return {};
    }
  }, [ companiesFacets, endorsersFacets, jobSeekersFacets, role ]);

  // The search page
  const page = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersPage;
    case Roles.COMPANY_AGENT:
      return companiesPage;
    case Roles.ENDORSER:
      return endorsersPage;
    default:
      return 0;
    }
  }, [ companiesPage, endorsersPage, jobSeekersPage, role ]);

  // The total results
  const total = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersTotal;
    case Roles.COMPANY_AGENT:
      return companiesTotal;
    case Roles.ENDORSER:
      return endorsersTotal;
    default:
      return 0;
    }
  }, [ companiesTotal, endorsersTotal, jobSeekersTotal, role ]);

  // The filter types
  const filterTypes = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekersFilterTypes;
    case Roles.COMPANY_AGENT:
      return CompaniesFilterTypes;
    case Roles.ENDORSER:
      return EndorsersFilterTypes;
    default:
      return undefined;
    }
  }, [ role ]);

  // the cities
  const cities = useMemo(() => {
    return params.criteria?.filter((criterion) => {
      return CriterionTypes.CITY === criterion.type;
    }).map((criterion) => {
      return criterion.value;
    }) || undefined;
  }, [ params.criteria ]);

  // The job sectors to send as a request
  const jobSectorsToSend = useMemo(() => {
    return params.criteria?.filter((criterion) => {
      return CriterionTypes.JOB_SECTOR === criterion.type;
    }).map((criterion) => {
      return criterion.value;
    }) || undefined;
  }, [ params.criteria ]);

  // The terms to send as a request
  const termsToSend = useMemo(() => {
    if (!userAuthenticated || Roles.JOB_SEEKER === userRole) {
      return {};
    }
    const terms = params.criteria?.filter((criterion) => {
      return CriterionTypes.TERM === criterion.type;
    }).map((criterion) => {
      return criterion.value;
    }) || undefined;
    return {
      terms,
    };
  }, [ params.criteria, userAuthenticated, userRole ]);

  // the computer skills levels filter
  const computerSkillsLevels = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.COMPUTER_SKILLS_LEVEL === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.COMPUTER_SKILLS_LEVEL, params.filters ]);

  // The driving license categories filter
  const drivingLicenseCategories = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.DRIVING_LICENSE_CATEGORY === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.DRIVING_LICENSE_CATEGORY, params.filters ]);

  // the experience length percentage filter
  const experienceLengthPercentage = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.EXPERIENCE_LENGTH === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((experience) => {
      return !!experience;
    }) || undefined;
  }, [ filterTypes?.EXPERIENCE_LENGTH, params.filters ]);

  // The languages filter
  const languages = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.LANGUAGE === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.LANGUAGE, params.filters ]);

  // The professions filter
  const professionsFilter = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.PROFESSION === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.PROFESSION, params.filters ]);

  // The profile completion percentage filter
  const profileCompletionPercentageRange = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.PROFILE_COMPLETION_PERCENTAGE === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((profileCompletion) => {
      return !!profileCompletion;
    }) || undefined;
  }, [ filterTypes?.PROFILE_COMPLETION_PERCENTAGE, params.filters ]);

  // The qualification types filter
  const qualificationTypes = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.QUALIFICATION_TYPE === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.QUALIFICATION_TYPE, params.filters ]);

  // The relocation intention filter
  const relocationIntentions = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.WILLING_TO_RELOCATE === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.WILLING_TO_RELOCATE, params.filters ]);

  // The work availabilities filter
  const workAvailabilities = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.WORK_AVAILABILITY === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.WORK_AVAILABILITY, params.filters ]);

  // The number of employees filter
  const numberOfEmployees = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.NUMBER_OF_EMPLOYEES === filter.type;
    }).map((filter) => {
      return filter.value;
    }) || undefined;
  }, [ filterTypes?.NUMBER_OF_EMPLOYEES, params.filters ]);

  // The total endorsements range
  const totalEndorsementsRange = useMemo(() => {
    return params.filters?.filter((filter) => {
      return filterTypes?.TOTAL_ENDORSEMENTS_RANGE === filter.type;
    }).map((filter) => {
      return parseRange(filter.value);
    }).find((totalEndorsementsRange) => {
      return !!totalEndorsementsRange;
    }) || undefined;
  }, [ filterTypes?.TOTAL_ENDORSEMENTS_RANGE, params.filters ]);

  // The job seekers filters to send
  const jobSeekersFiltersToSend = useMemo(() => {
    if (!userAuthenticated || Roles.JOB_SEEKER === userRole) {
      return undefined;
    }
    return {
      computerSkillsLevels,
      drivingLicenseCategories,
      experienceLengthPercentage,
      languages,
      professionsFilter,
      profileCompletionPercentageRange,
      qualificationTypes,
      relocationIntentions,
      workAvailabilities,
    };
  }, [
    computerSkillsLevels,
    drivingLicenseCategories,
    experienceLengthPercentage,
    languages,
    professionsFilter,
    profileCompletionPercentageRange,
    qualificationTypes,
    relocationIntentions,
    userAuthenticated,
    userRole,
    workAvailabilities,
  ]);

  // The companies filters to send
  const companiesFiltersToSend = useMemo(() => {
    if (!userAuthenticated || Roles.COMPANY_AGENT === userRole) {
      return undefined;
    }
    return {
      numberOfEmployees,
      professionsFilter,
    };
  }, [ numberOfEmployees, professionsFilter, userAuthenticated, userRole ]);

  // The endorsers filters to send
  const endorsersFiltersToSend = useMemo(() => {
    if (!userAuthenticated || Roles.ENDORSER === userRole) {
      return undefined;
    }
    return {
      totalEndorsementsRange,
    };
  }, [ totalEndorsementsRange, userAuthenticated, userRole ]);

  // The filters to send
  const filtersToSend = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersFiltersToSend;
    case Roles.COMPANY_AGENT:
      return companiesFiltersToSend;
    case Roles.ENDORSER:
      return endorsersFiltersToSend;
    default:
      return {};
    }
  }, [ companiesFiltersToSend, endorsersFiltersToSend, jobSeekersFiltersToSend, role ]);

  // The private profiles method to fetch depending on the role
  const privateProfilesGetMethod = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersMethods.getProfiles;
    case Roles.COMPANY_AGENT:
      return companiesMethods.getProfiles;
    case Roles.ENDORSER:
      return endorsersMethods.getProfiles;
    default:
      return undefined;
    }
  }, [ role ]);

  // The public profiles method to fetch depending on the role
  const publicProfilesGetMethod = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersMethods.getPublicProfiles;
    case Roles.COMPANY_AGENT:
      return companiesMethods.getPublicProfiles;
    case Roles.ENDORSER:
      return endorsersMethods.getPublicProfiles;
    default:
      return undefined;
    }
  }, [ role ]);

  const dispatch = useDispatch();

  const [ searchParams, setSearchParams ] = useSearchParams();

  const { t } = useTranslation();

  // Reference to the ID of the current search.
  const searchIdRef = useRef(undefined);

  // The sanitized search parameters from redux
  const sanitizedParams = useMemo(() => {
    return sanitizeParams(params, role, userRole, userAuthenticated);
  }, [ params, role, userAuthenticated, userRole ]);

  // There are no search parameters from redux
  const noParams = useMemo(() => {
    return Object.keys(sanitizedParams).every((sanitizedParamKey) => {
      return undefined === sanitizedParams[sanitizedParamKey];
    });
  }, [ sanitizedParams ]);

  // The sanitized search params from query string
  const sanitizedSearchParams = useMemo(() => {
    return toUrlSearchParams(sanitizeParams(
      fromUrlSearchParams(searchParams, role),
      role,
      userRole,
      userAuthenticated
    ));
  }, [ role, searchParams, userAuthenticated, userRole ]);

  // There are no search parameters from query string
  const noSearchParams = useMemo(() => {
    return isEmpty(sanitizedSearchParams.toString());
  }, [ sanitizedSearchParams ]);

  // The params to send
  const paramsToSend = useMemo(() => {
    const constructedParams = {
      filters: filtersToSend,
      page: {
        number: params.page,
        size: constants.PAGE_SIZE,
      },
      searchId: searchIdRef.current,
      sort: {
        direction: DEFAULT_SORT_DIRECTION,
        key: params.sort,
      },
    };
    if (role === Roles.ENDORSER) {
      return constructedParams;
    }
    return {
      ...constructedParams,
      criteria: {
        cities,
        jobSectors: jobSectorsToSend,
        ...termsToSend,
      },
    };
  }, [ cities, filtersToSend, jobSectorsToSend, params.page, params.sort, role, termsToSend ]);

  // search action to dispatch
  const searchActionToCompleteSearch = useCallback((result) => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return dispatch(jobSeekersActions.completeSearch(result));
    case Roles.COMPANY_AGENT:
      return dispatch(companiesActions.completeSearch(result));
    case Roles.ENDORSER:
      return dispatch(endorsersActions.completeSearch(result));
    default:
      return undefined;
    }
  }, [ dispatch, role ]);

  // the action to be dispatched when changing meta data
  const searchActionRefineSearch = useCallback((params) => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return dispatch(jobSeekersActions.refineSearch(params));
    case Roles.COMPANY_AGENT:
      return dispatch(companiesActions.refineSearch(params));
    case Roles.ENDORSER:
      return dispatch(endorsersActions.refineSearch(params));
    default:
      return undefined;
    }
  }, [ dispatch, role ]);

  // the action to be dispatched when making a search
  const searchActionMakeSearch = useCallback((params) => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return dispatch(jobSeekersActions.makeSearch(params));
    case Roles.COMPANY_AGENT:
      return dispatch(companiesActions.makeSearch(params));
    case Roles.ENDORSER:
      return dispatch(endorsersActions.makeSearch(params));
    default:
      return undefined;
    }
  }, [ dispatch, role ]);

  // Get profiles when the search parameters change.
  useEffect(() => {
    // No criteria. No search.
    if (isEmpty(params.criteria || []) && role !== Roles.ENDORSER) {
      return;
    }
    // if the search is for endorsers fill the page and the sort key
    // No sort default key or no page. No search. This only applies if the component is searching for endorsers
    // Because the async dispatch is not completed to set the page and sort key in the redux
    if (role === Roles.ENDORSER && (params?.page === undefined || params?.sort === undefined)) {
      searchActionMakeSearch({
        filters: [],
        page: 1,
        sort: DEFAULT_SORT_KEY,
      });
    }
    // No sort default key or no page. No search. This only applies if the component is searching for endorsers
    // Because the async dispatch is not completed to set the page and sort key in the redux
    if (params?.page === undefined || params?.sort === undefined) {
      return;
    }
    const method = userAuthenticated ? privateProfilesGetMethod : publicProfilesGetMethod;
    dispatch(requestsActions.request(method, paramsToSend, {
      onFailure: (_error) => {
        searchActionToCompleteSearch({});
        toasts.error(t('search:search_failed_error'));
      },
      onSuccess: (result) => {
        searchIdRef.current = result.searchId;
        searchActionToCompleteSearch(result);
      },
    }));
  }, [
    dispatch,
    params.criteria,
    params?.page,
    params?.sort,
    paramsToSend,
    privateProfilesGetMethod,
    publicProfilesGetMethod,
    role,
    searchActionMakeSearch,
    searchActionToCompleteSearch,
    t,
    userAuthenticated,
  ]);

  // Make the search parameters and the query string match.
  useEffect(() => {
    if (noParams && areNotEqual(searchParams.toString(), sanitizedSearchParams.toString())) {
      setSearchParams(sanitizedSearchParams);
      return;
    }
    if (!noParams && areNotEqual(params, sanitizedParams)) {
      searchActionMakeSearch(sanitizedParams);
      return;
    }
    // There are no search parameters. There is a query string.
    // Align the search parameters with the query string.
    if (noParams && !noSearchParams) {
      const newParams = fromUrlSearchParams(sanitizedSearchParams);
      searchActionMakeSearch(newParams);
      return;
    }
    // There are search parameters.
    // Align the query string with the search parameters.
    if (!noParams) {
      const newSearchParams = toUrlSearchParams(params);
      setSearchParams(newSearchParams);
    }
  }, [
    noParams,
    noSearchParams,
    params,
    sanitizedParams,
    sanitizedSearchParams,
    searchActionMakeSearch,
    searchParams,
    setSearchParams,
  ]);

  // The default sort key
  const defaultSortKey = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return JobSeekersDefaultSortKey;
    case Roles.COMPANY_AGENT:
      return CompaniesDefaultSortKey;
    case Roles.ENDORSER:
      return EndorsersDefaultSortKey;
    default:
      return '';
    }
  }, [ role ]);

  // on change search form
  const handleChangeSearchForm = useCallback((criteria) => {
    const params = {
      criteria,
      filters: [],
      page: 1,
      sort: defaultSortKey,
    };
    searchIdRef.current = undefined;
    searchActionMakeSearch(params);
  }, [ defaultSortKey, searchActionMakeSearch ]);

  // on change page
  const handleChangePage = useCallback((page) => {
    const newParams = {
      ...params,
      page,
    };
    searchActionRefineSearch(newParams);
  }, [ params, searchActionRefineSearch ]);

  // On change the sorting criteria
  const handleChangeSortingCriteria = useCallback((sort) => {
    const newParams = {
      ...params,
      page: 1,
      sort,
    };
    searchActionRefineSearch(newParams);
  }, [ params, searchActionRefineSearch ]);

  // Whether the search is filtered.
  const searchFiltered = useMemo(() => {
    return isNotEmpty(params.filters || []);
  }, [ params.filters ]);

  // The search total component
  const searchTotalComponent = useMemo(() => {
    if (total === undefined) {
      return null;
    }
    return (
      <SearchTotal
        role={ role }
        total={ total }
      />
    );
  }, [ role, total ]);

  // The search sort component
  const searchSortComponent = useMemo(() => {
    if (total === undefined) {
      return null;
    }
    if (0 === total && !searchFiltered) {
      return null;
    }
    return (
      <SearchSort
        onChange={ handleChangeSortingCriteria }
        role={ role }
        searchFiltered={ searchFiltered }
        sort={ params.sort }
        total={ total }
      />
    );
  }, [ handleChangeSortingCriteria, params.sort, role, searchFiltered, total ]);

  // The search pages component
  const searchPagesComponent = useMemo(() => {
    if (0 >= total || total === undefined) {
      return null;
    }
    return (
      <SearchPages
        onChange={ handleChangePage }
        page={ page }
        total={ total }
      />
    );
  }, [ handleChangePage, page, total ]);

  // handle the change of filters
  const handleChangeFilters = useCallback((filters) => {
    const newParams = {
      ...params,
      filters,
      page: 1,
    };
    searchActionRefineSearch(newParams);
  }, [ params, searchActionRefineSearch ]);

  // The selected filters
  const selectedFilters = useMemo(() => {
    if (total === undefined) {
      return null;
    }
    if (!userAuthenticated || role === userRole
      || (0 >= total && (0 !== total || isEmpty(params.filters)))) {
      return null;
    }
    return (
      <SearchSelectedFilters
        allProfessions={ allProfessions }
        filters={ params.filters }
        onChange={ handleChangeFilters }
        role={ role }
      />
    );
  }, [ allProfessions, handleChangeFilters, params.filters, role, total, userAuthenticated, userRole ]);

  // The filters
  const searchFilters = useMemo(() => {
    if (total === undefined) {
      return null;
    }
    if (role === Roles.ENDORSER && userRole !== Roles.ADMINISTRATOR) {
      return null;
    }
    if (!userAuthenticated
      || role === userRole
      || undefined === facets
      || (0 >= total && (0 !== total || isEmpty(params.filters)))) {
      return null;
    }
    return (
      <SearchFilters
        allProfessions={ allProfessions }
        facets={ facets }
        filters={ params.filters }
        onChange={ handleChangeFilters }
        role={ role }
      />
    );
  }, [ allProfessions, facets, handleChangeFilters, params.filters, role, total, userAuthenticated, userRole ]);

  // The results component
  const resultsComponent = useMemo(() => {
    if (0 >= (results || []).length) {
      return null;
    }
    return (
      <Results
        allProfessions={ allProfessions }
        companyId={ userCompanyId }
        results={ results }
        role={ role }
        savedJobSeekers={ savedJobSeekers }
        userAuthenticated={ userAuthenticated }
        userRole={ userRole }
      />
    );
  }, [ allProfessions, results, role, savedJobSeekers, userAuthenticated, userCompanyId, userRole ]);

  // The rendered top section
  const renderedTopSection = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return (
        <SearchForm
          authenticated={ userAuthenticated }
          criteria={ params.criteria || [] }
          jobSectors={ allJobSectors }
          onChange={ handleChangeSearchForm }
          role={ role }
        />
      );
    }
    return (
      <div className='ody-search__top-section ody-search__top-section--endorsers'>
        <p className='hdg hdg-md light'>
          { t('search:endorsers_title') }
        </p>
        <img
          alt='waves design'
          className='ody-search__top-section--image'
          src={ wavesImage }
        />
      </div>
    );
  }, [ allJobSectors, handleChangeSearchForm, params.criteria, role, t, userAuthenticated ]);

  return (
    <div className='ody-search'>
      { renderedTopSection }
      <div className='ody-search__section dark hidden-md hidden-lg'>
        { searchTotalComponent }
      </div>
      <div className='ody-search__section hidden-xs hidden-sm ody-search__total-and-sort'>
        { searchTotalComponent }
        { searchSortComponent }
      </div>
      <div className='ody-search__filters-and-sort ody-search__section hidden-md hidden-lg'>
        { searchFilters }
        { searchSortComponent }
      </div>
      <div className='ody-search__section light'>
        { selectedFilters }
      </div>
      <div className='ody-search__filters-results-and-pages ody-search__section dark hidden-xs hidden-sm'>
        { searchFilters }
        <div className='ody-search__results-and-pages'>
          { resultsComponent }
          { searchPagesComponent }
        </div>
      </div>
      <div className='ody-search__results-and-pages ody-search__section dark hidden-md hidden-lg'>
        { resultsComponent }
        { searchPagesComponent }
      </div>
    </div>
  );
};

Search.propTypes = {
  // whether the component is searching for the provided role
  role: PropTypes.oneOf(roles).isRequired,
};

Search.defaultProps = {};

export default Search;
