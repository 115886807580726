/**
 * Middleware for requests.
 *
 * @module middlewares/requests
 */
import axios from 'axios';

import env from 'config/env';
import { actions as administratorsActions } from 'ducks/administrators';
import { actions as authActions, types as authTypes } from 'ducks/auth';
import { actions as companiesActions } from 'ducks/companies';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as jobSeekersActions } from 'ducks/job-seekers';
import { actions as profilesActions } from 'ducks/profiles';
import { actions as requestsActions, types as requestsTypes } from 'ducks/requests';
import { actions as settingsActions } from 'ducks/settings';
import RequestError from 'errors/request-error';
import RequestSetupError from 'errors/request-setup-error';
import ResponseError from 'errors/response-error';
import UnauthorizedError from 'errors/unauthorized-error';
import * as authMethods from 'resources/auth';

// Set the base URL for all requests.
axios.defaults.baseURL = env.CORE_BASE_URL;

// Set some common request headers.
axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
axios.defaults.headers.common['Pragma'] = 'no-cache';

// Register a request interceptor.
axios.interceptors.request.use((config) => {
  // Set the request timeout.
  config.timeout = (config.data instanceof FormData ? env.UPLOAD_TIMEOUT : env.REQUEST_TIMEOUT) * 1000;
  config.withCredentials = true;
  return config;
});

// Register a response interceptor.
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const { config } = error;
  // The request that failed was an access token refresh.
  if ('/auth/access-token/refresh' === config.url) {
    throw new UnauthorizedError();
  }
  // The request that failed was a sign-in attempt.
  if ('/auth/sign-in' === config.url || '/auth/google/sign-in' === config.url) {
    throw new UnauthorizedError(error.response?.data);
  }
  if (undefined !== error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx.
    // 401 (UNAUTHORIZED) was returned, and it wasn't a provider sign up.
    if (401 === error.response.status && !config.url.includes('google') && !config.url.includes('linkedin')) {
      // The request has already been retried.
      if (config._retry) {
        throw new UnauthorizedError();
      }
      // The request has not been retried.
      // Refresh the access token and retry.
      config._retry = true;
      return authMethods.refreshAccessToken().then((result) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${ result.accessToken }`;
        config.headers['Authorization'] = `Bearer ${ result.accessToken }`;
        return axios.request(config);
      });
    }
    throw new ResponseError(error.response.data);
  } else if (undefined !== error.request) {
    // The request was made but no response was received.
    throw new RequestError();
  } else {
    // Something happened when setting up the request that triggered an error.
    throw new RequestSetupError();
  }
});

const middleware = (store) => {
  return (next) => {
    return (action) => {
      const { type } = action;
      // Some user signed in.
      if (authTypes.USER_SIGNED_IN === type) {
        const { accessToken } = action;
        // Use the user's access token for any subsequent requests.
        axios.defaults.headers.common['Authorization'] = `Bearer ${ accessToken }`;
        return next(action);
      }
      // The signed-in user signed out.
      if (authTypes.USER_SIGNED_OUT === type) {
        // Do not use the user's access token any more.
        axios.defaults.headers.common['Authorization'] = undefined;
        return next(action);
      }
      if (requestsTypes.REQUEST_MADE !== type) {
        return next(action);
      }
      // Make the actual request.
      store.dispatch(requestsActions.startRequest());
      return action.method.call(null, action.params).then((result) => {
        // The request succeeded.
        action.options?.onSuccess?.(result);
      }).catch((error) => {
        // The request failed.
        if (error instanceof UnauthorizedError && '/sign-in' !== window.location.pathname) {
          // Access was denied, and the request was not a sign-in attempt.
          // Reset authn/authz.
          store.dispatch(authActions.reset());
          // Reset the settings.
          store.dispatch(settingsActions.resetSettings());
          // Reset everything about job seeker searches.
          store.dispatch(jobSeekersActions.resetSearch());
          // Reset everything about company agents
          store.dispatch(companiesActions.reset());
          // Reset the administrators
          store.dispatch(administratorsActions.resetAdministrators());
          // Reset the endorsers
          store.dispatch(endorsersActions.resetEndorsers());
          // Reset the profiles
          store.dispatch(profilesActions.resetProfile());
          // Do not use the user's access token any more.
          axios.defaults.headers.common['Authorization'] = undefined;
          // Redirect to the sign-in page.
          action.options?.navigate?.('/sign-in', { state: { from: window.location.pathname } });
        } else {
          // Something else went wrong.
          action.options?.onFailure?.(error);
        }
      }).finally(() => {
        store.dispatch(requestsActions.endRequest());
        action.options?.onCompletion?.();
      });
    };
  };
};

export {
  middleware,
};
