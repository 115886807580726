import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './home-our-companies-say-child-collection-item.scss';

const HomeOurCompaniesChildCollectionItem = memo((props) => {
  const { description, name, imageUrl, occupation } = props;
  return (
    <div className='home-our-companies-say-child-collection-item'>
      <div className='home-our-companies-say-child-collection-item__description-container'>
        <p className='txt txt-lg home-our-companies-say-child-collection-item__description'>
          { description }
        </p>
      </div>
      <div className='home-our-companies-say-child-collection-item__photo-name-container'>
        <div className='home-our-companies-say-child-collection-item__photo-container'>
          <img
            alt='Company Representative'
            className='home-our-companies-say-child-collection-item__photo'
            src={ imageUrl }
          />
        </div>
        <div className='home-our-companies-say-child-collection-item__name-occupation-container'>
          <p className='txt txt-md txt-bold home-our-companies-say-child-collection-item__name'>
            { name }
          </p>
          <p className='txt txt-sm home-our-companies-say-root-collection-item__occupation'>
            { occupation }
          </p>
        </div>
      </div>
    </div>
  );
});

HomeOurCompaniesChildCollectionItem.displayName = 'HomeOurCompaniesChildCollectionItem';

HomeOurCompaniesChildCollectionItem.propTypes = {
  // the description
  description: PropTypes.string.isRequired,
  // The image url
  imageUrl: PropTypes.string.isRequired,
  // The name
  name: PropTypes.string.isRequired,
  // The occupation
  occupation: PropTypes.string.isRequired,
};

HomeOurCompaniesChildCollectionItem.defaultProps = {};

export default HomeOurCompaniesChildCollectionItem;
