/**
 * Greek translations for utilities/endorsers/sort-keys.
 *
 * @module languages/el/utilities/endorsers/sort-keys
 */
const sortKeys = {
  LAST_MODIFIED_AT: 'Τελευταία ενημέρωση',
  LAST_NAME: 'Επίθετο',
  TOTAL_ENDORSEMENTS: 'Συνολικές συστάσεις',
};

export default sortKeys;
