import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'components/common/carousel/carousel';
import { useSm, useXs } from 'hooks/media-queries';
import useElementOnScreen from 'hooks/use-element-on-screen';

import './public-companies-collection-section.scss';

const PublicCompaniesCollectionSection = memo((props) => {
  const { title, buttonLabel, hasButton, imagesCollection, isSocialResponsibility } = props;

  const isXs = useXs();

  const isSm = useSm();

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // Whether we are on small screens
  const smallScreens = useMemo(() => {
    return isXs || isSm;
  }, [ isSm, isXs ]);

  // The rendered companies images
  const renderedCompaniesImageComponents = useMemo(() => {
    return imagesCollection.map((image) => {
      return (
        <div
          className={
            clsx('public-companies-collection-section__company', {
              'public-companies-collection-section__company--social-responsibility': isSocialResponsibility,
            })
          }
          key={ image }
        >
          <img
            alt='company image'
            className='public-companies-collection-section__image'
            src={ image }
          />
        </div>
      );
    });
  }, [ imagesCollection, isSocialResponsibility ]);

  // Whether to render a button link
  const renderedButtonLink = useMemo(() => {
    if (!hasButton) {
      return null;
    }
    return (
      <Link
        className='btn btn-sm btn-rounded-sm btn-blue txt
            txt-bold public-companies-collection-section__see-all-button'
        to='/companies/search'
      >
        { buttonLabel }
      </Link>
    );
  }, [ buttonLabel, hasButton ]);

  // The rendered content
  const renderedContent = useMemo(() => {
    // render the content and fetch the images only when the section is visible in the DOM
    if (!isVisible) {
      return null;
    }
    if (!smallScreens) {
      return (
        <div className='public-companies-collection-section__container hidden-xs hidden-sm'>
          { renderedCompaniesImageComponents }
          { renderedButtonLink }
        </div>
      );
    }
    return (
      <>
        <Carousel
          autoplay={ isVisible }
          className={
            clsx('hidden-lg hidden-md public-companies-collection-section__carousel', {
              'public-companies-collection-section__carousel--no-button': !hasButton,
              'public-companies-collection-section__carousel--social-responsibility': isSocialResponsibility,
            })
          }
          infiniteLoop
        >
          { renderedCompaniesImageComponents }
        </Carousel>
        { renderedButtonLink }
      </>
    );
  }, [
    hasButton,
    isSocialResponsibility,
    isVisible,
    renderedButtonLink,
    renderedCompaniesImageComponents,
    smallScreens,
  ]);

  return (
    <div
      className='public-companies-collection-section'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl public-companies-collection-section__title'>
        { title }
      </h2>
      { renderedContent }
    </div>
  );
});

PublicCompaniesCollectionSection.displayName = 'PublicCompaniesCollectionSection';

PublicCompaniesCollectionSection.propTypes = {
  // The button label
  buttonLabel: PropTypes.string,
  // Whether the section has see all button
  hasButton: PropTypes.bool,
  // the images collection
  imagesCollection: PropTypes.arrayOf(PropTypes.string).isRequired,
  // Whether the section is rendered in the social responsibility page
  isSocialResponsibility: PropTypes.bool,
  // The title of the section
  title: PropTypes.string.isRequired,
};

PublicCompaniesCollectionSection.defaultProps = {
  buttonLabel: '',
  hasButton: false,
  isSocialResponsibility: false,
};

export default PublicCompaniesCollectionSection;
