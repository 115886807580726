/**
 * Loader.
 */
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import './loader.scss';

const Loader = memo((_props) => {
  // The requests state object
  const requests = useSelector((store) => {
    return store.requests;
  }, shallowEqual);

  // Whether there is an active request.
  const requestActive = useMemo(() => {
    return requests?.active;
  }, [ requests?.active ]);

  return useMemo(() => {
    if (!requestActive) {
      return null;
    }
    return (
      <div className='ody-loader'>
        <svg
          className='spinner'
          preserveAspectRatio='xMidYMid'
          viewBox='0 0 100 100'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
        >
          <circle
            cx='50'
            cy='50'
            fill='none'
            r='35'
            stroke='#5096b4'
            strokeDasharray='164.93361431346415 56.97787143782138'
            strokeWidth='5'
          >
            <animateTransform
              attributeName='transform'
              dur='1s'
              keyTimes='0;1'
              repeatCount='indefinite'
              type='rotate'
              values='0 50 50;360 50 50'
            />
          </circle>
        </svg>
      </div>
    );
  }, [ requestActive ]);
});

Loader.displayName = 'Loader';

Loader.propTypes = {
};

Loader.defaultProps = {
};

export default Loader;
