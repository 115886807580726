import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './video-testimonial.scss';

const VideoTestimonial = memo((props) => {
  const { ageLocation, name, title, videoUrl } = props;

  return (
    <div className='video-testimonial'>
      <h5 className='hdg hdg-lg video-testimonial__title'>
        { title }
      </h5>
      <div className='video-testimonial__video-container'>
        <iframe
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          frameBorder='0'
          src={ videoUrl }
          title={ title }
        />
      </div>
      <div className='video-testimonial__name-age-location-container'>
        <p className='txt txt-lg txt-bold video-testimonial__name'>
          { name }
        </p>
        <p className='txt txt-lg video-testimonial__age-location'>
          { ageLocation }
        </p>
      </div>
    </div>
  );
});

VideoTestimonial.displayName = 'VideoTestimonial';

VideoTestimonial.propTypes = {
  // the age location
  ageLocation: PropTypes.string.isRequired,
  // Whether the section is visible in the DOM
  isSectionVisible: PropTypes.bool,
  // the name of the person of the testimonial
  name: PropTypes.string.isRequired,
  // The title of the testimonial
  title: PropTypes.string.isRequired,
  // the video url of the testimonial
  videoUrl: PropTypes.string.isRequired,
};

VideoTestimonial.defaultProps = {};

export default VideoTestimonial;
