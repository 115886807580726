/**
 * English translations for job-seekers/share-profile-button.
 *
 * @module languages/en/job-seekers/share-profile-button
 */
const shareProfileButton = {
  modal: {
    copy_link_button_label: 'Copy link',
    link_copied_message: 'Link copied.',
    link_copy_failed_error: 'Link copy failed.',
    title: 'Share profile',
  },
  share_profile_button_label: 'Share profile',
};

export default shareProfileButton;
