/**
 * English translations for job-seekers/profile-competencies.
 *
 * @module languages/en/job-seekers/profile-competencies
 */
const profileCompetencies = {
  authorized_to_work_label: 'I am authorized to work',
  computer_skills: 'Computer skills',
  computer_skills_level_label: 'Computer skills',
  computer_skills_level_placeholder: 'Select your computer skills level',
  driving_license_categories_label: 'Driving licenses',
  driving_license_categories_placeholder: 'Select the categories of your driving licenses',
  driving_licenses: 'Driving licenses',
  editor_title: 'Competencies',
  expires_on: 'Expires on',
  no: 'No',
  skills_label: 'Skills',
  title: 'Competencies',
  willing_to_relocate: 'Willing to relocate',
  willing_to_relocate_label: 'I am willing to relocate',
  work_authorization: 'Work authorization',
  work_authorization_expires_on_label: 'Date of expiry',
  work_authorization_expires_on_placeholder: 'Date of expiry',
  work_authorization_label: 'Work authorization',
  work_availability: 'Work availability',
  work_availability_label: 'Work availability',
  work_availability_placeholder: 'Select your work availability',
  yes: 'Yes',
};

export default profileCompetencies;
