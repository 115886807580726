import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import Carousel from 'components/common/carousel/carousel';
import { useSm, useXs } from 'hooks/media-queries';
import useElementOnScreen from 'hooks/use-element-on-screen';
import roles from 'utilities/auth/roles';
import csrPricingPackages from 'utilities/csr-pricing-plans';

import SocialResponsibilityCsrPackage from './social-responsibility-csr-package/social-responsibility-csr-package';

import './social-responsibility-csr-packages-section.scss';

const SocialResponsibilityCsrPackagesSection = memo((props) => {
  const { title, packagesCollection, userAuthenticated, userRole, handleCsrPackageClick } = props;

  const isXs = useXs();

  const isSm = useSm();

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // Whether we are on small screens
  const smallScreens = useMemo(() => {
    return isXs || isSm;
  }, [ isSm, isXs ]);

  // The rendered packages
  const renderedPackages = useMemo(() => {
    return packagesCollection.map((csrPackage, index) => {
      return (
        <SocialResponsibilityCsrPackage
          buttonLabel={ csrPackage.buttonLabel }
          csrPricingPackage={ csrPackage.csrPricingPackage }
          descriptionCollection={ csrPackage.description }
          handleCsrPackageClick={ handleCsrPackageClick }
          key={ csrPackage.title + index }
          title={ csrPackage.title }
          userAuthenticated={ userAuthenticated }
          userRole={ userRole }
        />
      );
    });
  }, [ handleCsrPackageClick, packagesCollection, userAuthenticated, userRole ]);

  // The rendered content
  const renderedContent = useMemo(() => {
    if (smallScreens) {
      return (
        <Carousel
          autoplay={ isVisible }
          infiniteLoop
        >
          { renderedPackages }
        </Carousel>
      );
    }
    return renderedPackages;
  }, [ isVisible, renderedPackages, smallScreens ]);

  return (
    <div
      className='social-responsibility-csr-packages-section'
      id='csr-packages'
      ref={ containerRef }
    >
      <h2 className='hdg hdg-xxl social-responsibility-csr-packages-section__title'>
        { title }
      </h2>
      { renderedContent }
    </div>
  );
});

SocialResponsibilityCsrPackagesSection.displayName = 'SocialResponsibilityCsrPackagesSection';

SocialResponsibilityCsrPackagesSection.propTypes = {
  // The function (csrPricingPlan) => void toe handle the pricing package click
  handleCsrPackageClick: PropTypes.func,
  // The packages collection
  packagesCollection: PropTypes.arrayOf(
    PropTypes.shape({
      // The button label of the packages
      buttonLabel: PropTypes.string,
      // The csr package
      csrPricingPackage: PropTypes.oneOf(csrPricingPackages),
      // The package description collection
      description: PropTypes.arrayOf(PropTypes.string),
      // The package title
      title: PropTypes.string,
    })
  ).isRequired,
  // The section title
  title: PropTypes.string.isRequired,
  // Whether the user is authenticated
  userAuthenticated: PropTypes.bool.isRequired,
  // The user role
  userRole: PropTypes.oneOf(roles),
};

SocialResponsibilityCsrPackagesSection.defaultProps = {};

export default SocialResponsibilityCsrPackagesSection;
