/**
 * Greek translations for common/profile-about.
 *
 * @module languages/el/common/profile-about
 */
const profileAbout = {
  change_button_label: 'Μεταβολή',
  delete_button_label: 'Διαγραφή',
  description_error: 'Η περιγραφή δεν πρέπει να υπερβαίνει τους 1024 χαρακτήρες',
  editor_title_companies: 'Σχετικά',
  editor_title_job_seekers: 'Σχετικά με εμάς',
  no_video_message_companies: 'Ανεβάστε ένα βίντεο που περιγράφει την εταιρεία σας εδώ.',
  no_video_message_job_seekers: 'Ανεβάστε ένα βίντεο που περιγράφετε τον εαυτό σας εδώ.',
  short_bio_label_companies: 'Περιγραφή εταιρείας',
  short_bio_label_job_seekers: 'Σύντομη περιγραφή',
  title_companies: 'Σχετικά',
  title_job_seekers: 'Σχετικά με εμένα',
  too_large_video_error: 'Ανεβάστε ένα βίντεο έως 16 MB.',
  unsupported_video_format_error: 'Ανεβάστε ένα βίντεο σε μορφή MP4 ή OGG.',
  upload_button_label: 'Μεταφόρτωση',
};

export default profileAbout;
