/**
 * Greek translations for job-seekers/steps.
 *
 * @module languages/el/job-seekers/steps
 */
const steps = {
  COMPETENCIES: 'Συμπληρώστε τις δεξιότητές σας',
  CONTACT: 'Συμπληρώστε τα στοιχεία επικοινωνίας σας',
  EXPERIENCE: 'Συμπληρώστε την εργασιακή σας εμπειρία',
  JOB_SECTORS: 'Συμπληρώστε τους τομείς εργασίας που σας ενδιαφέρουν',
  LANGUAGES: 'Συμπληρώστε τις γλώσσες που γνωρίζεται',
  PERSONAL: 'Συμπληρώστε τα προσωπικά σας στοιχεία',
  PROFESSIONS: 'Συμπληρώστε τις θέσεις εργασίας που σας ενδιαφέρουν',
  PROFILE_PICTURE: 'Ανεβάστε την εικόνα του προφίλ σας',
  QUALIFICATIONS: 'Συμπληρώστε την εκπαίδευση / καταρτίσεις σας',
  RESUME: 'Ανεβάστε το βιογραφικό σας',
  SHORT_BIO: 'Συμπληρώστε μια σύντομη περιγραφή για εσάς',
  VIDEO: 'Ανεβάστε το βίντεο αυτοπαρουσίασης σας',
};

export default steps;
