/**
 * English translations for utilities/cities.
 *
 * @module languages/en/utilities/cities
 *
 * NOTE: Taken from https://geodata.gov.gr/en/dataset/poleis.
 */
const cities = {
  AGIOS_NIKOLAOS: 'Agios Nikolaos',
  ALEKSANDROYPOLI: 'Aleksandroypoli',
  AMFISSA: 'Amfissa',
  ARGOSTOLI: 'Argostoli',
  ARTA: 'Arta',
  ATHENS: 'Athens',
  BEROIA: 'Beroia',
  BOLOS: 'Bolos',
  DRAMA: 'Drama',
  EDESSA: 'Edessa',
  ERMOYPOLI: 'Ermoypoli',
  FLORINA: 'Florina',
  GREBENA: 'Grebena',
  HALKIDA: 'Halkida',
  HANIA: 'Hania',
  HGOYMENITSA: 'Hgoymenitsa',
  HRAKLEIO: 'Hrakleio',
  IOANNINA: 'Ioannina',
  KABALA: 'Kabala',
  KALAMATA: 'Kalamata',
  KARDITSA: 'Karditsa',
  KARPENISI: 'Karpenisi',
  KASTORIA: 'Kastoria',
  KATERINI: 'Katerini',
  KERKYRA: 'Kerkyra',
  KILKIS: 'Kilkis',
  KOMOTINI: 'Komotini',
  KORINTHOS: 'Korinthos',
  KOZANI: 'Kozani',
  KSANTHI: 'Ksanthi',
  LAMIA: 'Lamia',
  LARISSA: 'Larissa',
  LEIVADIA: 'Leivadia',
  LEYKADA: 'Leykada',
  MESOLOGGI: 'Mesologgi',
  MYTILINI: 'Mytilini',
  NAYPLION: 'Nayplion',
  PATRA: 'Patra',
  POLYGYROS: 'Polygyros',
  PREBEZA: 'Prebeza',
  PYRGOS: 'Pyrgos',
  RETHIMNO: 'Rethimno',
  RODOS: 'Rodos',
  SAMOS: 'Samos',
  SERRES: 'Serres',
  SPARTI: 'Sparti',
  THESSALONIKI: 'Thessaloniki',
  TRIKALA: 'Trikala',
  TRIPOLI: 'Tripoli',
  XIOS: 'Xios',
  ZAKYNTHOS: 'Zakynthos',
};

export default cities;
