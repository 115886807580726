/**
 * Greek translations for sign-up.
 *
 * @module languages/el/sign-up
 */
const signUp = {
  agree_to: 'Συμφωνώ με',
  already_have_account: 'Έχετε ήδη λογαριασμό;',
  company_link_text: 'μια εταιρία',
  company_name_label: 'Όνομα εταιρίας',
  company_name_placeholder: 'Προσθέστε το όνομα της εταιρίας σας',
  company_name_required_error: 'Το πεδίο όνομα εταιρίας είναι υποχρεωτικό',
  company_position_placeholder: 'Προσθέστε τη θέση σας',
  continue_with_google_button_label: 'Συνδεθείτε με το Google',
  continue_with_linkedin_button_label: 'Συνδεθείτε με το LinkedIn',
  // eslint-disable-next-line max-len
  created_message: 'Ο λογαριασμός σας έχει δημιουργηθεί, αλλά, για να μπορέσετε να συνδεθείτε, πρέπει πρώτα να επαληθεύσετε τη διεύθυνση email σας.\n\nΈνα email έχει σταλεί στη διεύθυνση email που ορίσατε. Ελέγξτε τα εισερχόμενα σας και ακολουθήστε τις οδηγίες σε αυτό το email για να ενεργοποιήσετε τον λογαριασμό σας.',
  email_invalid_error: 'Εισάγετε μια έγκυρη διεύθυνση email.',
  email_label: 'Email',
  email_placeholder: 'Email',
  email_required_error: 'Το πεδίο email είναι υποχρεωτικό',
  endorser_company_name_label: 'Όνομα εταιρίας (Προαιρετικό)',
  endorser_company_position_label: 'Προσθέστε τη θέση σας (Προαιρετικό)',
  endorser_link_text: 'υποστηρικτής',
  first_name_label: 'Όνομα',
  first_name_placeholder: 'Όνομα',
  first_name_required_error: 'Το πεδίο όνομα είναι υποχρεωτικό',
  general_error: 'Ωχ! Κάτι πήγε στραβά.',
  job_seeker_link_text: 'υποψήφιος',
  last_name_label: 'Επίθετο',
  last_name_placeholder: 'Επίθετο',
  last_name_required_error: 'Το πεδίο επίθετο είναι υποχρεωτικό',
  must_agree_with_privacy_policy_error: 'Πρέπει να συμφωνήσετε με την Πολιτική Απορρήτου.',
  must_agree_with_terms_of_service_error: 'Πρέπει να συμφωνήσετε με τους Όρους Παροχής Υπηρεσιών.',
  not_company_agent: 'Δεν είστε εταιρία;',
  not_endorser: 'Δεν είστε υποστηρικτής;',
  not_job_seeker: 'Δεν είστε υποψήφιος/α;',
  or: 'ή',
  // eslint-disable-next-line max-len
  password_and_password_confirmation_mismatch_error: 'Ο κωδικός πρόσβασης και η επιβεβαίωση κωδικού πρόσβασης δεν ταιριάζουν.',
  password_confirmation_label: 'Επιβεβαίωση κωδικού πρόσβασης',
  password_confirmation_placeholder: 'Επιβεβαίωση κωδικού πρόσβασης',
  // eslint-disable-next-line max-len
  password_invalid_error: 'Ο κωδικός πρόσβασης πρέπει να περιέχει: \nΤουλάχιστον 8 χαρακτήρες.\nΤουλάχιστον ένα ψηφίο.\nΤουλάχιστον ένα πεζό γράμμα.\nΤουλάχιστον ένα κεφαλαίο γράμμα.\nΤουλάχιστον ένας ειδικός χαρακτήρας (!"#$%&\'()* ,-./:;<=>?@[]^_`{|}~).',
  password_label: ' Κωδικός πρόσβασης',
  password_placeholder: ' Κωδικός πρόσβασης',
  password_required_error: 'Το πεδίο κωδικός πρόσβασης είναι υποχρεωτικό',
  phone_number_label: 'Αριθμός τηλεφώνου',
  phone_number_label_endorsers: 'Αριθμός τηλεφώνου (Προαιρετικό)',
  // eslint-disable-next-line max-len
  phone_number_pattern_error: 'Ο αριθμός τηλεφώνου πρέπει να είναι αριθμός με το πρόθεμα της χώρας (π.χ.  30) και να μην υπερβαίνει τους 10 αριθμούς',
  phone_number_placeholder: 'Προσθέστε τον αριθμό τηλεφώνου σας',
  phone_number_required_error: 'Το πεδίο αριθμός τηλεφώνου είναι υποχρεωτικό',
  privacy_policy_link_text: 'Πολιτική Απορρήτου',
  reset_authentication_provider_button_label: 'Επαναφορά',
  retrieve_google_profile_error: 'Απέτυχε η ανάκτηση των στοιχείων του χρήστη μέσω της Google',
  retrieve_linkedin_profile_error: 'Απέτυχε η ανάκτηση των στοιχείων του χρήστη μέσω του LinkedIn',
  sign_in_link_text: 'Συνδεθείτε',
  sign_up_as: 'Εγγραφείτε ως',
  sign_up_button_label: 'Εγγραφείτε ',
  terms_of_service_link_text: 'Όροι χρήσης',
  title_company_agent: 'Εγγραφείτε ως Εταιρεία',
  title_endorser: 'Εγγραφείτε ως Υποστηρικτής',
  title_job_seeker: 'Εγγραφείτε ως Υποψήφιος',
  user_already_exists_error: 'Αυτό το email χρησιμοποιείται ήδη',
  // eslint-disable-next-line max-len
  verify_with_google_error: 'Κάτι πήγε στραβά κατά την επαλήθευση με την Google. Ανανεώστε το πρόγραμμα περιήγησής σας και δοκιμάστε ξανά.',
  // eslint-disable-next-line max-len
  verify_with_linkedin_error: 'Κάτι πήγε στραβά κατά την επαλήθευση με τo LinkedIn. Ανανεώστε το πρόγραμμα περιήγησής σας και δοκιμάστε ξανά.',
};

export default signUp;
