/**
 * Qualification types.
 *
 * @module utilities/qualification-types
 */
export default [
  'AWARD',
  'BACHELORS_DEGREE',
  'DIPLOMA',
  'DOCTORAL_DEGREE',
  'MASTERS_DEGREE',
  'MBA',
  'OTHER_CERTIFICATE',
  'VOCATIONAL_CERTIFICATE',
];
