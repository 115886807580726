/**
 * Greek translations for endorsement.
 *
 * @module languages/el/endorsement
 */
const endorsement = {
  endorsement_information: {
    communication_cooperation: {
      answers: {
        ABILITY: 'Μπορούν να επικοινωνούν και να συνεργάζονται με κάποιους περιορισμούς',
        GOOD_LEVEL: 'Επικοινωνούν και συνεργάζονται σε καλό επίπεδο',
        POOR: 'Έχουν αδυναμία στην επικοινωνία και συνεργασία',
        PROFESSIONAL: 'Επικοινωνούν και συνεργάζονται επαγγελματικά',
        WILLNESS: 'Επιθυμούν να επικοινωνούν και να συνεργαστούν',
      },
      // eslint-disable-next-line max-len
      question: 'Έχουν την ικανότητα αλληλεπίδρασης λεκτικά και μη με το περιβάλλον τους; Μπορούν να λειτουργήσουν μέσα σε μια ομάδα;',
      title: 'Επικοινωνία και συνεργασία',
    },
    companies_feedback: {
      title: 'Ανατροφοδότηση εταιρειών',
    },
    critical_thinking: {
      answers: {
        FAST_RESPONSIVENESS: 'Φαίνονται να είναι έξυπνοι, γρήγοροι στις απαντήσεις και μαθαίνουν γρήγορα',
        NEED_GUIDANCE: 'Φαίνεται να καταλαβαίνουν αλλά χρειάζονται καθοδήγηση και χρόνο',
        NICE_RESPONSIVENESS: 'Φαίνονται έξυπνοι, ανταποκρίνονται ευγενικά και στοχευμένα',
        SLOW_RESPONSIVENESS: 'Φαίνονται αργοί στην ανταπόκριση',
        UNDERSTANDING: 'Φαίνονται λίγο αργοί στην ανταπόκριση, αλλά δίνουν προσοχή και καταλαβαίνουν\n',
      },
      // eslint-disable-next-line max-len
      question: 'Μπορούν να κατανοήσουν, να επεξεργαστούν και να αξιολογήσουν τις πληροφορίες που τους δίνονται; Μπορούν να καταφέρουν να προτείνουν προτάσεις ή λύσεις αν χρειαστεί;',
      title: 'Κριτική σκέψη',
    },
    endorsed_by_title: 'Σύσταση από:',
    feedback: {
      title: 'Ανατροφοδότηση',
    },
    job_seekers_feedback: {
      answers: {
        // eslint-disable-next-line max-len
        KNOWLEDGE_UPGRADE: 'Πρέπει να αναβαθμίσετε τις γνώσεις σας για να ανταποκριθείτε στα νέα δεδομένα (εκπαιδευτικά μαθήματα, σεμινάρια κ.λπ.)',
        // eslint-disable-next-line max-len
        LANGUAGES_IMPROVEMENT: 'Πρέπει να βελτιώσετε τις γνώσεις σας στην ελληνική ή/και στην αγγλική γλώσσα για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία',
        // eslint-disable-next-line max-len
        MORE_PREPARATION: 'Πρέπει να προετοιμαστείτε περισσότερο για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία (Ανανεώστε το βιογραφικό σας, προετοιμαστείτε για τη συνέντευξη, στοχεύστε τις θέσεις για τις οποίες θέλετε να κάνετε αίτηση)',
        // eslint-disable-next-line max-len
        PERSONAL_FAMILY_MATTERS: 'Μερικά προσωπικά ή οικογενειακά ζητήματα πρέπει να διευθετηθούν προκειμένου να αναζητήσετε εργασία',
        // eslint-disable-next-line max-len
        PRIORITIZATION: 'Πρέπει να διερευνήσετε τις προτεραιότητες σας προκειμένου να εστιάσετε αποτελεσματικά στην εύρεση εργασίας',
        // eslint-disable-next-line max-len
        SUPPORT_NEEDED: 'Χρειάζεστε υποστήριξη και δικτύωση για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία. Είστε έτοιμοι να αναλάβετε καθήκοντα',
      },
      title: 'Ανατροφοδότηση από τον/ην υποψήφιο/α',
    },
    motivation: {
      answers: {
        FIND_JOB: 'Φαίνονται έτοιμοι να κάνουν μια προσπάθεια για να βρουν εργασία',
        FIRST_STEP: 'Φαίνονται έτοιμοι να κάνουν το πρώτο βήμα (πρόθεση)',
        // eslint-disable-next-line max-len
        KEEP_JOB: 'Φαίνεται να είναι έτοιμοι να διατηρήσουν την εργασία τους / να κάνουν μια προσπάθεια να είναι αποτελεσματικοί',
        TAKE_JOB: 'Φαίνεται να είναι έτοιμοι να αναλάβουν μια θέση εργασίας',
        WORK_HARD: 'Φαίνονται πραγματικά πρόθυμοι να δουλέψουν σκληρά / ψάχνουν επίμονα για εργασία',
      },
      // eslint-disable-next-line max-len
      question: 'Είναι έτοιμοι για τις ευθύνες μιας νέας θέσης εργασίας; Πιστεύουν ότι η καριέρα τους αλλάζει με θετικό τρόπο;',
      title: 'Κίνητρο',
    },
    personality_qualities: {
      title: 'Προσωπικές ικανότητες',
    },
    professionalism: {
      answers: {
        ATTITUDE: 'Συμπεριφορά',
        CONSISTENCY: 'Συνέπεια ',
        GOOD_INTENTION: 'Καλοπροαίρετος',
        POLITENESS: 'Ευγένεια',
        SELF_CONTROL: 'Αυτοέλεγχος',
      },
      question: 'Μπορεί η γενική τους εικόνα να χαρακτηριστεί τυπικά επαγγελματική;',
      title: 'Επαγγελματισμός',
    },
    testimonial: {
      title: 'Είπε για εμάς',
    },
  },
  form: {
    cancel_button_label: 'Ακύρωση',
    communication_cooperation_skills_level: {
      answers: {
        ABILITY: 'Μπορούν να επικοινωνούν και να συνεργάζονται με κάποιους περιορισμούς',
        GOOD_LEVEL: 'Επικοινωνούν και συνεργάζονται σε καλό επίπεδο',
        POOR: 'Έχουν αδυναμία στην επικοινωνία και συνεργασία',
        PROFESSIONAL: 'Επικοινωνούν και συνεργάζονται επαγγελματικά',
        WILLNESS: 'Επιθυμούν να επικοινωνούν και να συνεργαστούν',
      },
      questions: {
        primary: 'Πόσο καλές είναι οι δεξιότητες επικοινωνίας και συνεργασίας τους;',
        // eslint-disable-next-line max-len
        secondary: 'Έχουν την ικανότητα αλληλεπίδρασης λεκτικά και μη με το περιβάλλον τους; Μπορούν να λειτουργήσουν μέσα σε μια ομάδα;',
      },
    },
    confirm_submit: 'Είστε βέβαιοι ότι θέλετε να υποβάλετε τη φόρμα;',
    critical_thinking_level: {
      answers: {
        FAST_RESPONSIVENESS: 'Φαίνονται να είναι έξυπνοι, γρήγοροι στις απαντήσεις και γρήγοροι μαθαίνουν',
        NEED_GUIDANCE: 'Φαίνεται να καταλαβαίνουν αλλά χρειάζονται καθοδήγηση και χρόνο',
        NICE_RESPONSIVENESS: 'Φαίνονται έξυπνοι, ανταποκρίνονται ευγενικά και στοχευμένα',
        SLOW_RESPONSIVENESS: 'Φαίνονται αργοί στην ανταπόκριση',
        UNDERSTANDING: 'Φαίνονται λίγο αργοί στην ανταπόκριση, αλλά δίνουν προσοχή και καταλαβαίνουν',
      },
      questions: {
        primary: 'Πώς θα αξιολογούσατε την κριτική τους σκέψη;',
        // eslint-disable-next-line max-len
        secondary: 'Μπορούν να κατανοήσουν, να επεξεργαστούν και να αξιολογήσουν τις πληροφορίες που τους δίνονται; Μπορούν να καταφέρουν να προτείνουν προτάσεις ή λύσεις αν χρειαστεί;',
      },
    },
    employer_feedback: {
      questions: {
        // eslint-disable-next-line max-len
        primary: 'Δώστε κάποια σχόλια στον/την πιθανό εργοδότη του/της (Μόνο οι εργοδότες μπορούν να δουν αυτό το σχόλιο)',
        // eslint-disable-next-line max-len
        secondary: 'Η συνάντηση με τον υποψήφιο δημιούργησε έντονα ένα συναίσθημα ή μια εντύπωση που πρέπει να αναφερθεί με στόχο την προώθηση του υποψηφίου στην επιχείρηση. Τότε θα λέγαμε ότι...',
      },
    },
    error_message: 'Απαιτείται η απάντηση σε αυτή την ερώτηση.',
    failed_submit_endorsement: 'Η σύσταση απέτυχε να υποβληθεί',
    hashtags_error: 'Τα hashtag είναι απαραίτητα',
    hashtags_placeholder: 'Προσθέστε ένα Hashtag',
    hashtags_subtitle: 'Δώστε 3 ιδιότητες του υποψήφιου',
    hashtags_title: 'Hashtags',
    job_seeker_feedbacks: {
      answers: {
        // eslint-disable-next-line max-len
        KNOWLEDGE_UPGRADE: 'Πρέπει να αναβαθμίσετε τις γνώσεις σας για να ανταποκριθείτε στα νέα δεδομένα της αγοράς εργασίας(εκπαιδευτικά μαθήματα, σεμινάρια κ.λπ.)',
        // eslint-disable-next-line max-len
        LANGUAGES_IMPROVEMENT: 'Πρέπει να βελτιώσετε τις γνώσεις σας στην ελληνική ή/και στην αγγλική γλώσσα για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία',
        // eslint-disable-next-line max-len
        MORE_PREPARATION: 'Πρέπει να προετοιμαστείτε περισσότερο για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία (Ανανεώστε το βιογραφικό σας, προετοιμαστείτε για τη συνέντευξη, στοχεύστε τις θέσεις για τις οποίες θέλετε να κάνετε αίτηση)',
        // eslint-disable-next-line max-len
        PERSONAL_FAMILY_MATTERS: 'Μερικά προσωπικά ή οικογενειακά ζητήματα πρέπει να διευθετηθούν προκειμένου να αναζητήσετε εργασία',
        // eslint-disable-next-line max-len
        PRIORITIZATION: 'Πρέπει να διερευνήσετε τις προτεραιότητες σας προκειμένου να εστιάσετε αποτελεσματικά στην εύρεση εργασίας',
        // eslint-disable-next-line max-len
        SUPPORT_NEEDED: 'Χρειάζεστε υποστήριξη και δικτύωση για να αυξήσετε τις πιθανότητες σας να βρείτε εργασία. Είστε έτοιμοι να αναλάβετε καθήκοντα',
      },
      questions: {
        // eslint-disable-next-line max-len
        primary: 'Υποβάλετε κάποια προσωπικά σχόλια για τον υποψήφιο (Μόνο ο/η υποψήφιος/α μπορεί να δει αυτό το σχόλιο)',
      },
    },
    motivation_level: {
      answers: {
        FIND_JOB: 'Φαίνονται έτοιμοι να κάνουν μια προσπάθεια για να βρουν εργασία',
        FIRST_STEP: 'Φαίνονται έτοιμοι να κάνουν το πρώτο βήμα (πρόθεση)',
        // eslint-disable-next-line max-len
        KEEP_JOB: 'Φαίνεται να είναι έτοιμοι να διατηρήσουν την εργασία τους / να κάνουν μια προσπάθεια να είναι αποτελεσματικοί',
        TAKE_JOB: 'Φαίνεται να είναι έτοιμοι να αναλάβουν μια θέση εργασίας',
        WORK_HARD: 'Φαίνονται πραγματικά πρόθυμοι να δουλέψουν σκληρά / ψάχνουν επίμονα για εργασία',
      },
      questions: {
        primary: 'Πόσο κινητοποιημένοι είναι να βρουν μια θέση εργασίας;',
        // eslint-disable-next-line max-len
        secondary: 'Είναι έτοιμοι για τις ευθύνες μιας νέας θέσης εργασίας; Πιστεύουν ότι η καριέρα τους αλλάζει με θετικό τρόπο;',
      },
    },
    professional_characteristics: {
      answers: {
        ATTITUDE: 'Συμπεριφορά',
        CONSISTENCY: 'Συνέπεια ',
        GOOD_INTENTION: 'Καλοπροαίρετος',
        POLITENESS: 'Ευγένεια',
        SELF_CONTROL: 'Αυτοέλεγχος',
      },
      questions: {
        primary: 'Πώς θα αξιολογούσατε τον επαγγελματισμό τους;',
        secondary: 'Μπορεί η γενική τους εικόνα να χαρακτηριστεί τυπικά επαγγελματική;',
      },
    },
    submit_button_label: 'Υποβολή',
    successful_submitted_endorsement: 'Έχετε υποβάλει με επιτυχία τη σύστασή σας',
    testimonial: {
      questions: {
        primary: 'Δώστε μια απλή και γρήγορη συνολική άποψη για τον υποψήφιο (προαιρετικό)',
        // eslint-disable-next-line max-len
        secondary: '\nΜπορείτε να γράψετε μια πρόταση για κάτι που σας έκανε εντύπωση ή κάτι που αξίζει να αναφέρετε για αυτόν τον  υποψήφιο.',
      },
    },
    textarea_error_message: 'Τα σχόλιά σας δεν πρέπει να υπερβαίνουν τους 1024 χαρακτήρες',
    textarea_placeholder: 'Κείμενο...',
  },
  list_of_questions_title: 'Λίστα ερωτήσεων',
  profile_overview_title: 'Επισκόπηση προφίλ',
  title: 'Έντυπο σύστασης',
  view_profile_button_title: 'Εμφάνιση προφίλ',
};

export default endorsement;
