/**
 * Option filter.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import caretDown from 'images/caret-down.png';
import caretUp from 'images/caret-up.png';
import { max, min } from 'utilities/chisels';

import './option-filter.scss';

const OptionFilter = (props) => {
  // The maximum number of options to show.
  const MAXIMUM_NUMBER_OF_OPTIONS = 3;

  const { t } = useTranslation();

  // Whether the filter is expanded.
  const [ expanded, setExpanded ] = useState(true);
  // Whether to see more options.
  const [ seeMore, setSeeMore ] = useState(false);

  // The number of selected options.
  const numberOfSelectedOptions = props.options.filter((o) => {
    return o.selected;
  }).length;

  // Function that sorts options by whether they are selected (selected first), by their number (descending) and by
  // their label (ascending).
  const bySelectedNumberAndLabel = (o1, o2) => {
    const label1 = o1.label.toUpperCase();
    const number1 = o1.number;
    const selected1 = o1.selected;
    const label2 = o2.label.toUpperCase();
    const number2 = o2.number;
    const selected2 = o2.selected;
    if (selected1 !== selected2) {
      return selected1 ? -1 : 1;
    }
    if (number1 !== number2) {
      return number2 - number1;
    }
    return label1 < label2 ? -1 : label1 > label2 ? 1 : 0;
  };

  // Function that sorts options by their number (descending) and by their label (ascending).
  const byNumberAndLabel = (o1, o2) => {
    const label1 = o1.label.toUpperCase();
    const number1 = o1.number;
    const label2 = o2.label.toUpperCase();
    const number2 = o2.number;
    if (number1 !== number2) {
      return number2 - number1;
    }
    return label1 < label2 ? -1 : label1 > label2 ? 1 : 0;
  };

  // The sorted options.
  const sortedOptions = props.options.sort(bySelectedNumberAndLabel);

  // The number of options to show with "less".
  const numberOfLessOptions = max([
    numberOfSelectedOptions,
    min([ MAXIMUM_NUMBER_OF_OPTIONS, props.options.length ]),
  ]);

  // The options to show with "less".
  const lessOptions = sortedOptions.slice(0, numberOfLessOptions).sort(byNumberAndLabel);

  // The number of extra options to show with "more".
  const numberOfMoreOptions = props.options.length - numberOfLessOptions;

  // The extra options to show with "more".
  const moreOptions = 0 < numberOfMoreOptions
    ? sortedOptions.slice(numberOfLessOptions, props.options.length).sort(byNumberAndLabel) : [];

  return (
    <div className={ clsx('ody-filter option', props.className, expanded ? 'expanded' : 'collapsed') }>
      <div className='title-and-expand-collapse'>
        <div className='title'>
          { props.title }
        </div>
        <button
          className='btn btn-trans'
          onClick={
            () => {
              setExpanded((expanded) => {
                return !expanded;
              });
            }
          }
          type='button'
        >
          <img alt={ expanded ? 'Collapse' : 'Expand' } src={ expanded ? caretUp : caretDown } />
        </button>
      </div>
      <div className='options'>
        {
          0 === props.options.length && (
            <div className='no-options txt txt-sm'>
              { t('common:option_filter.no_options_message') }
            </div>
          )
        }
        {
          lessOptions.map((option, index) => {
            return (
              <div className={ clsx('option', 0 === option.number && 'disabled') } key={ index }>
                <label className='inline'>
                  <input
                    checked={ option.selected }
                    disabled={ 0 === option.number }
                    onChange={
                      () => {
                        if (!option.selected) {
                          props.onSelect?.(option.value);
                        } else {
                          props.onUnselect?.(option.value);
                        }
                      }
                    }
                    type='checkbox'
                  />
                  { option.label }
                </label>
                <div className='number'>{ option.number }</div>
              </div>
            );
          })
        }
        {
          0 < numberOfMoreOptions && (
            <button
              className='btn btn-trans'
              onClick={
                () => {
                  setSeeMore((seeMore) => {
                    return !seeMore;
                  });
                }
              }
              type='button'
            >
              { t(`common:option_filter.${ seeMore ? 'see_less_button_label' : 'see_more_button_label' }`) }
            </button>
          )
        }
        {
          (seeMore ? moreOptions : []).map((option, index) => {
            return (
              <div className={ clsx('option', 0 === option.number && 'disabled') } key={ index }>
                <label className='inline'>
                  <input
                    checked={ option.selected }
                    disabled={ 0 === option.number }
                    onChange={
                      () => {
                        if (!option.selected) {
                          props.onSelect?.(option.value);
                        } else {
                          props.onUnselect?.(option.value);
                        }
                      }
                    }
                    type='checkbox'
                  />
                  { option.label }
                </label>
                <div className='number'>{ option.number }</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

OptionFilter.propTypes = {
  // The class(es) to apply to the filter.
  className: PropTypes.string,
  // The function ((any) => void) to invoke when an option is selected.
  onSelect: PropTypes.func,
  // The function ((any) => void) to invoke when an option is unselected.
  onUnselect: PropTypes.func,
  // The options.
  options: PropTypes.arrayOf(PropTypes.shape({
    // The label of the option.
    label: PropTypes.string.isRequired,
    // The number of the option.
    number: PropTypes.number.isRequired,
    // Whether the option is selected.
    selected: PropTypes.bool.isRequired,
    // The value of the option.
    value: PropTypes.string.isRequired,
  })).isRequired,
  // The title of the filter.
  title: PropTypes.string,
};

OptionFilter.defaultProps = {
};

export default OptionFilter;
