/* eslint-disable */
import React, { memo } from 'react';

const VideoIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 60 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7173_15054)'>
        <path
          d='M3.85922 44.2669C3.79325 33.9922 3.0346 23.7174 3.21601 13.4427C3.26549 10.7713 2.20997 5.89083 3.9087 4.09276C6.03624 1.83232 14.3155 3.09954 16.9543 3.28791C25.481 3.93864 34.5024 2.29468 42.9301 4.09276C46.6904 4.89761 46.0802 6.40457 46.2287 10.2062C46.3606 13.8709 46.4596 17.3643 47.2017 20.9433C47.3996 21.8851 48.5376 22.279 49.2798 21.8166C52.5453 19.8644 55.3655 17.2444 58.73 15.4806C58.0209 15.0524 57.3117 14.6415 56.6025 14.2133C56.7344 21.6454 57.4601 29.3172 57.0808 36.6978C57.79 36.2697 58.4991 35.8587 59.2083 35.4306C55.6294 33.5127 52.067 31.3379 48.3892 29.6939C47.4326 29.2658 46.2451 29.7453 46.2616 30.9611C46.2616 34.2661 48.2408 41.5269 45.9648 44.1299C43.6888 46.7328 34.1726 44.9176 31.039 44.6607C21.5393 43.8559 12.188 44.1984 2.72124 43.9072C2.85318 44.8662 2.96863 45.8423 3.10057 46.8013C3.18303 46.7842 3.28198 46.767 3.36445 46.767C5.16214 46.493 4.40348 43.6504 2.60579 43.9244C2.52333 43.9415 2.42437 43.9586 2.34191 43.9586C0.709142 44.1984 1.18743 46.8013 2.72124 46.8527C10.0439 47.0753 17.3666 46.5615 24.7388 47.0582C32.6883 47.6061 40.3903 48.548 48.3562 47.5034C49.0654 47.4006 49.8405 46.904 49.7746 46.0307C49.4117 41.0646 49.1149 35.9443 49.0984 30.944C48.3892 31.3721 47.68 31.7831 46.9708 32.2112C50.6652 33.8723 54.2111 36.03 57.79 37.9479C58.7795 38.4788 59.868 37.8109 59.9175 36.6807C60.2968 29.3 59.5712 21.6111 59.4392 14.1962C59.4227 13.0318 58.2682 12.4153 57.3117 12.929C53.9472 14.71 51.1105 17.33 47.8614 19.2651C48.5541 19.5562 49.2468 19.8473 49.9395 20.1384C49.0324 15.7374 50.8136 6.50731 48.5046 2.67142C47.1028 0.325365 45.536 1.18159 42.9301 1.11309C35.7559 0.907598 28.5816 0.8391 21.4238 0.616482C14.8268 0.410988 7.50408 -0.958973 1.03899 1.02747C0.230857 1.26721 -0.0495165 2.03782 0.049439 2.84267C1.81414 16.1484 0.956531 30.7899 1.03899 44.2326C1.0225 46.1506 3.85922 46.1506 3.85922 44.2669Z'
          fill='#A693C4'
        />
        <path
          d='M9.54915 38.3589C9.6811 33.1188 10.3903 27.9301 10.2418 22.7071C10.1429 19.5391 9.25229 15.6004 9.8955 12.5009C10.3903 10.1206 9.61513 11.5248 11.8911 10.7028C13.3589 10.172 15.7339 10.5145 17.2842 10.4802C20.6652 10.3775 24.0461 10.3261 27.4271 10.309C29.7691 10.309 32.144 10.4973 34.4859 10.4802C36.6465 10.4631 37.7515 9.88085 38.6091 11.3022C39.78 13.2201 38.807 19.5904 38.8565 21.8851C39.0049 27.5191 39.5492 33.3072 39.2853 38.9411C39.7636 38.4445 40.2253 37.965 40.7036 37.4684C34.8982 37.2629 29.0929 37.3143 23.304 37.2801C20.2199 37.2629 17.1522 37.2458 14.0681 37.1944C13.0291 37.1773 9.43371 37.5369 8.85647 37.0917C7.37213 35.9615 5.95377 38.513 7.42161 39.6261C9.94498 41.5441 17.614 40.1741 20.4507 40.1912C27.1962 40.2426 33.9417 40.1741 40.6871 40.3967C41.4788 40.431 42.0725 39.6946 42.1055 38.924C42.5838 29.1459 41.5283 19.1452 41.3963 9.34999C41.3963 8.75063 40.9675 8.0314 40.3573 7.92865C34.7993 6.95256 28.8785 7.31217 23.2545 7.38067C19.5931 7.43204 12.1385 6.16483 8.83997 8.08277C6.3331 9.57261 7.04228 13.3742 7.10825 15.9943C7.28967 23.5291 6.89385 30.8241 6.71243 38.3589C6.66295 40.2597 9.49968 40.2597 9.54915 38.3589Z'
          fill='#A693C4'
        />
        <path
          d='M21.4568 29.2487C24.7553 28.0157 28.1528 26.9882 31.3193 25.3956C32.1275 24.9847 32.2429 23.7003 31.5997 23.0838C28.8784 20.4467 25.0027 19.0253 21.5227 17.7924C20.6321 17.4841 19.7251 18.3061 19.7251 19.2137C19.7251 22.0735 19.7251 24.9504 19.6591 27.8102C19.6096 29.711 22.4463 29.711 22.4958 27.8102C22.5618 24.9504 22.5618 22.0735 22.5618 19.2137C21.968 19.6932 21.3578 20.1555 20.7641 20.635C23.7492 21.6796 27.2457 22.8783 29.5876 25.173C29.6866 24.4024 29.7855 23.6318 29.868 22.8612C26.9158 24.3339 23.7492 25.2587 20.6816 26.406C18.9994 27.0396 19.7251 29.8823 21.4568 29.2487Z'
          fill='#A693C4'
        />
      </g>
      <defs>
        <clipPath id='clip0_7173_15054'>
          <rect fill='white' height='48' width='60' />
        </clipPath>
      </defs>
    </svg>
  );
});

VideoIcon.displayName = 'VideoIcon';

VideoIcon.propTypes = {};

VideoIcon.defaultProps = {};

export default VideoIcon;
