/* eslint-disable */
import React, { memo } from 'react';

const MeterCardIcon = memo(() => {
  return (
    <svg
      className='card-icon'
      fill='none'
      preserveAspectRatio='xMidYMin meet'
      viewBox='0 0 41 31'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38.2176 29.9439C44.2684 16.5258 36.1159 1.64113 21.0234 0.567219C5.35349 -0.552884 -4.26555 16.4103 2.3396 29.9439C2.46662 30.2095 2.8015 30.3712 3.09019 30.3712C14.5453 30.3712 26.0119 30.3712 37.467 30.3712C38.5871 30.3712 38.5871 28.6391 37.467 28.6391C26.8549 28.6391 16.2543 28.6391 5.64217 28.6391C1.84306 28.6391 2.30496 26.3065 1.92389 22.4843C1.12712 14.4703 5.80384 5.77511 13.7023 3.15384C29.7533 -2.16954 43.4139 14.2163 36.7164 29.0663C36.266 30.071 37.7557 30.9486 38.2176 29.9439Z'
        fill='#222222'
      />
      <path
        d='M3.14765 13.3733C4.07145 13.5466 4.77584 14.4242 5.51488 14.9553C6.55415 15.6944 7.63962 16.4103 8.79436 16.93C9.79899 17.3919 10.6882 15.8907 9.67198 15.4288C8.42485 14.863 7.21236 14.0893 6.13844 13.2463C5.36476 12.6343 4.62572 11.8837 3.62109 11.6874C2.52408 11.5027 2.05064 13.1655 3.14765 13.3733Z'
        fill='#222222'
      />
      <path
        d='M19.7655 1.53719C19.5461 2.58801 19.6847 3.58109 19.7655 4.64346C19.8579 5.83284 19.8233 7.02222 19.8233 8.21161C19.8233 9.33171 21.5554 9.33171 21.5554 8.21161C21.5554 7.17234 21.5785 6.13307 21.5323 5.09381C21.4745 4.06608 21.232 3.02681 21.4399 1.99908C21.6478 0.913626 19.9849 0.440189 19.7655 1.53719Z'
        fill='#222222'
      />
      <path
        d='M32.3629 16.7913C32.9633 15.9368 34.2336 15.5442 35.1112 15.0477C36.1042 14.4934 37.1089 13.8698 37.998 13.1539C38.8641 12.461 37.6401 11.237 36.774 11.9299C34.9726 13.3733 32.2243 13.9969 30.8732 15.9253C30.2266 16.8375 31.7277 17.7036 32.3629 16.7913Z'
        fill='#222222'
      />
      <path
        d='M20.3534 22.7383C20.2957 22.7614 20.238 22.7961 20.1802 22.8192C20.0301 22.8192 19.8684 22.8192 19.7183 22.8192C19.5913 22.7153 19.4643 22.5998 19.3373 22.4958C19.3373 22.3457 19.3373 22.1841 19.3373 22.034C19.5567 22.1379 19.7761 22.2418 19.9955 22.3342C19.8453 22.3688 19.7068 22.415 19.5567 22.4497C19.1871 22.4843 19.2333 21.8492 19.4874 21.7337C19.6375 21.6991 19.7761 21.6529 19.9262 21.6183C20.1109 21.5952 20.4343 21.9531 20.4112 22.1263C20.3765 22.2765 20.3303 22.415 20.2957 22.5651C20.007 22.7383 19.9839 22.7845 20.2149 22.7037C21.3003 22.4381 20.8384 20.7753 19.753 21.0294C19.1063 21.191 18.5174 21.7222 18.7022 22.4497C18.8407 22.9808 19.3488 23.3503 19.8915 23.3503C21.5313 23.3273 21.1618 20.7407 19.649 20.706C17.9169 20.6714 18.1594 23.4196 19.8338 23.385C20.5844 23.3734 21.2079 22.6806 20.9885 21.9185C20.7345 21.0293 19.7299 20.9139 19.1179 21.5028C18.3326 22.288 19.5567 23.512 20.3534 22.7383Z'
        fill='#222222'
      />
      <path
        d='M24.7764 14.2509C20.781 14.9553 11.1158 21.4912 16.7625 25.9254C18.7256 27.4728 21.5201 27.3573 23.1713 25.4173C25.4231 22.7614 25.5501 18.3849 25.8734 15.0939C25.9774 13.9854 24.2453 13.9969 24.1413 15.0939C23.9335 17.2302 23.7025 19.3318 23.1367 21.4104C22.8365 22.5305 22.4439 23.8815 21.5085 24.6552C20.3538 25.6136 18.5177 25.3827 17.5131 24.2972C14.3375 20.8445 22.6748 16.3757 25.2383 15.9253C26.3353 15.729 25.8619 14.0546 24.7764 14.2509Z'
        fill='#222222'
      />
    </svg>
  );
});

MeterCardIcon.displayName = 'MeterCardIcon';

MeterCardIcon.propTypes = {};

MeterCardIcon.defaultProps = {};

export default MeterCardIcon;
