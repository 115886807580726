/**
 * Resource method to get job hashtags.js.
 *
 * @module resources/hashtags/get-hashtags
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/hashtags/get-hashtags~Result
 * @type {object}
 * @property {module:types/hashtags~Hashtag[]} hashtags - The hashtags.js.
 */

/**
 * Gets the hashtags.js.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/hashtags/get-hashtags~Result>} - The promise.
 * @static
 */
const getHashtags = () => {
  return axios.request({
    method: 'get',
    url: '/hashtags/',
  }).then((response) => {
    return response.data;
  });
};

export default getHashtags;
