import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select/select';
import { useFormWithSchema } from 'hooks/use-form-with-validation';
import roles, * as Roles from 'utilities/auth/roles';
import { areNotEqual, currentYear, isEmpty, isNotEmpty, months } from 'utilities/chisels';
import allCities from 'utilities/cities';
import countries from 'utilities/countries';
import genders from 'utilities/genders';
import {
  MINIMUM_ALLOWED_AGE,
  companiesDetailsEditorSchemaValidator,
  endorsersPersonalDetailsEditorSchemaValidator,
  jobSeekersPersonalDetailsSchemaValidator,
} from 'utilities/validators';

const ProfileDetailsSectionEditor = memo((props) => {
  const { role, profile, onSave, onCancel } = props;

  const { t } = useTranslation();

  // the schema to be validated depending on the role
  const schemaToBeValidated = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return jobSeekersPersonalDetailsSchemaValidator;
    case Roles.COMPANY_AGENT:
      return companiesDetailsEditorSchemaValidator;
    case Roles.ENDORSER:
      return endorsersPersonalDetailsEditorSchemaValidator;
    default:
      return {};
    }
  }, [ role ]);

  const { control, formState: { errors }, handleSubmit, register } = useFormWithSchema(schemaToBeValidated);

  // The editor title
  const editorTitle = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
    case Roles.ENDORSER:
      return t('common:profile_details_section.job_seekers_title');
    case Roles.COMPANY_AGENT:
      return t('common:profile_details_section.companies_endorsers_title');
    default:
      return '';
    }
  }, [ role, t ]);

  // The location to be updated
  const renderLocationToBeUpdated = useCallback((location) => {
    if (isEmpty(location?.city?.value) && isEmpty(location?.district)) {
      return undefined;
    }
    return {
      ...location,
      city: location?.city?.value || undefined,
    };
  }, []);

  // the bornOn attribute
  const renderBornOnToBeUpdated = useCallback((bornOn) => {
    if (undefined === bornOn.year?.value || undefined === bornOn.month?.value
      || undefined === bornOn.day?.value) {
      return undefined;
    }
    return DateTime.local(bornOn.year.value, bornOn.month.value, bornOn.day.value).toISODate();
  }, []);

  // the origin attribute
  const renderOriginToBeUpdated = useCallback((origin) => {
    if (isEmpty(origin?.country?.value)) {
      return undefined;
    }
    return {
      country: origin?.country?.value || undefined,
    };
  }, []);

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    switch (role) {
    case Roles.JOB_SEEKER:
      return {
        bornOn: profile?.bornOn,
        citizenship: profile?.citizenship,
        firstName: profile?.firstName,
        gender: profile?.gender,
        lastName: profile?.lastName,
        location: profile?.location,
        origin: profile?.origin,
      };
    case Roles.COMPANY_AGENT:
      return {
        hiresPerYear: profile?.hiresPerYear,
        location: profile?.location,
        numberOfEmployees: profile?.numberOfEmployees,
        website: profile?.website,
      };
    case Roles.ENDORSER:
      return {
        firstName: profile?.firstName,
        gender: profile?.gender,
        lastName: profile?.lastName,
        mobilePhoneNumber: profile?.mobilePhoneNumber,
      };
    default:
      return {};
    }
  }, [
    profile?.bornOn,
    profile?.citizenship,
    profile?.firstName,
    profile?.gender,
    profile?.hiresPerYear,
    profile?.lastName,
    profile?.location,
    profile?.mobilePhoneNumber,
    profile?.numberOfEmployees,
    profile?.origin,
    profile?.website,
    role,
  ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    let paramsToBeUpdated = {};
    switch (role) {
    case Roles.COMPANY_AGENT:
      paramsToBeUpdated = {
        ...values,
        hiresPerYear: values.hiresPerYear || undefined,
        location: renderLocationToBeUpdated(values.location),
        numberOfEmployees: values.numberOfEmployees || undefined,
        website: values.website || '',
      };
      break;
    case Roles.JOB_SEEKER:
      paramsToBeUpdated = {
        ...values,
        bornOn: renderBornOnToBeUpdated(values.bornOn),
        citizenship: values.citizenship?.value || undefined,
        gender: values.gender?.value || undefined,
        location: renderLocationToBeUpdated(values.location),
        origin: renderOriginToBeUpdated(values.origin),
      };
      break;
    case Roles.ENDORSER:
      paramsToBeUpdated = {
        ...values,
        gender: values.gender?.value || undefined,
        mobilePhoneNumber: values.mobilePhoneNumber || undefined,
      };
      break;
    default:
      break;
    }
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [
    onSave,
    renderBornOnToBeUpdated,
    renderLocationToBeUpdated,
    renderOriginToBeUpdated,
    role,
    valuesAreToBeUpdated,
  ]);

  // company website error
  const companyWebsiteError = useMemo(() => {
    if ('string.uri' !== errors.website?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.website_invalid_error') }
      </span>
    );
  }, [ errors.website?.type, t ]);

  // website field
  const websiteField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('Website') }
          <input
            className={
              clsx({
                'error': errors?.website,
              })
            }
            defaultValue={ profile?.website || '' }
            id='website'
            placeholder={ t('common:profile_details_section.website_placeholder') }
            type='text'
            { ...register('website') }
          />
        </label>
        { companyWebsiteError }
      </div>
    );
  }, [ companyWebsiteError, errors?.website, profile?.website, register, role, t ]);

  // district field
  const districtField = useMemo(() => {
    if (role === Roles.ENDORSER) {
      return null;
    }
    return (
      <label>
        { t('common:profile_details_section.location_label') }
        <input
          className={
            clsx({
              'error': errors?.location?.district,
            })
          }
          defaultValue={ profile?.location?.district || '' }
          maxLength={ 128 }
          placeholder={ t('common:profile_details_section.district_placeholder') }
          type='text'
          { ...register('location.district') }
        />
      </label>
    );
  }, [ errors?.location?.district, profile?.location?.district, register, role, t ]);

  // The cities options
  const citiesOptions = useMemo(() => {
    return allCities.map((city) => {
      return { label: t(`utilities:cities.${ city }`), value: city };
    });
  }, [ t ]);

  // The rendered city field
  const renderCityField = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ citiesOptions }
        placeholder={ t('common:profile_details_section.city_placeholder') }
      />
    );
  }, [ citiesOptions, t ]);

  // The city field defaultValue
  const cityFieldDefaultValue = useMemo(() => {
    if (!profile?.location?.city) {
      return null;
    }
    return {
      label: t(`utilities:cities.${ profile.location.city }`),
      value: profile.location.city,
    };
  }, [ profile?.location?.city, t ]);

  // city field
  const cityField = useMemo(() => {
    if (role === Roles.ENDORSER) {
      return null;
    }
    return (
      <label>
        <Controller
          control={ control }
          defaultValue={ cityFieldDefaultValue }
          name='location.city'
          render={ renderCityField }
        />
      </label>
    );
  }, [ cityFieldDefaultValue, control, renderCityField, role ]);

  // location district field
  const locationField = useMemo(() => {
    if (role === Roles.ENDORSER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        { districtField }
        { cityField }
      </div>
    );
  }, [ cityField, districtField, role ]);

  // the number of employees field
  const numberOfEmployeesField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.number_of_employees_label') }
          <input
            className={
              clsx({
                'error': errors?.numberOfEmployees,
              })
            }
            defaultValue={ profile?.numberOfEmployees || 0 }
            maxLength={ 256 }
            placeholder={ t('common:profile_details_section.number_of_employees_placeholder') }
            type='text'
            { ...register('numberOfEmployees') }
          />
        </label>
      </div>
    );
  }, [ errors?.numberOfEmployees, profile?.numberOfEmployees, register, role, t ]);

  // The hires per year field
  const hiresPerYearField = useMemo(() => {
    if (role !== Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.hires_per_year_label') }
          <input
            className={
              clsx({
                'error': errors?.hiresPerYear,
              })
            }
            defaultValue={ profile?.hiresPerYear || 0 }
            maxLength={ 256 }
            placeholder={ t('common:profile_details_section.hires_per_year_placeholder') }
            type='text'
            { ...register('hiresPerYear') }
          />
        </label>
      </div>
    );
  }, [ errors?.hiresPerYear, profile?.hiresPerYear, register, role, t ]);

  // The first name empty field error
  const firstNameEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.firstName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.first_name_required_error') }
      </span>
    );
  }, [ errors.firstName?.type, t ]);

  // The first name field
  const firstNameField = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.first_name_label') }
          <input
            className={
              clsx({
                'error': errors?.firstName,
              })
            }
            defaultValue={ profile?.firstName || '' }
            maxLength={ 256 }
            placeholder={ t('common:profile_details_section.first_name_placeholder') }
            type='text'
            { ...register('firstName') }
          />
        </label>
        { firstNameEmptyFieldError }
      </div>
    );
  }, [ errors?.firstName, firstNameEmptyFieldError, profile?.firstName, register, role, t ]);

  // The last name empty field error
  const lastNameEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.lastName?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.last_name_required_error') }
      </span>
    );
  }, [ errors.lastName?.type, t ]);

  // The lastName name field
  const lastNameField = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.last_name_label') }
          <input
            className={
              clsx({
                'error': errors?.lastName,
              })
            }
            defaultValue={ profile?.lastName || '' }
            maxLength={ 256 }
            placeholder={ t('common:profile_details_section.last_name_placeholder') }
            type='text'
            { ...register('lastName') }
          />
        </label>
        { lastNameEmptyFieldError }
      </div>
    );
  }, [ errors?.lastName, lastNameEmptyFieldError, profile?.lastName, register, role, t ]);

  // the gender options
  const genderOptions = useMemo(() => {
    return genders.map((gender) => {
      return { label: t(`utilities:genders.${ gender }`), value: gender };
    });
  }, [ t ]);

  // The gender select component
  const renderedGenderSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ genderOptions }
        placeholder={ t('common:profile_details_section.gender_placeholder') }
      />
    );
  }, [ genderOptions, t ]);

  // The gender default value
  const genderDefaultValue = useMemo(() => {
    if (!profile?.gender) {
      return null;
    }
    return {
      label: t(`utilities:genders.${ profile.gender }`),
      value: profile.gender,
    };
  }, [ profile.gender, t ]);

  // The gender field
  const genderField = useMemo(() => {
    if (role === Roles.COMPANY_AGENT) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.gender_label') }
          <Controller
            control={ control }
            defaultValue={ genderDefaultValue }
            name='gender'
            render={ renderedGenderSelect }
          />
        </label>
      </div>
    );
  }, [ control, genderDefaultValue, renderedGenderSelect, role, t ]);

  // The citizenship options
  const citizenshipsOptions = useMemo(() => {
    return countries.map((country) => {
      return { label: t(`utilities:citizenships.${ country }`), value: country };
    });
  }, [ t ]);

  // The countries options
  const countriesOptions = useMemo(() => {
    return countries.map((country) => {
      return { label: t(`utilities:countries.${ country }`), value: country };
    });
  }, [ t ]);

  // The citizenship select component
  const renderedCitizenshipSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ citizenshipsOptions }
        placeholder={ t('common:profile_details_section.citizenship_placeholder') }
      />
    );
  }, [ citizenshipsOptions, t ]);

  // The citizenship default value
  const citizenshipDefaultValue = useMemo(() => {
    if (!profile?.citizenship) {
      return null;
    }
    return {
      label: t(`utilities:citizenships.${ profile.citizenship }`),
      value: profile.citizenship,
    };
  }, [ profile.citizenship, t ]);

  // The citizenship field
  const citizenshipField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.citizenship_label') }
          <Controller
            control={ control }
            defaultValue={ citizenshipDefaultValue }
            name='citizenship'
            render={ renderedCitizenshipSelect }
          />
        </label>
      </div>
    );
  }, [ citizenshipDefaultValue, control, renderedCitizenshipSelect, role, t ]);

  // The origin select component
  const renderedOriginSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        options={ countriesOptions }
        placeholder={ t('common:profile_details_section.origin_placeholder') }
      />
    );
  }, [ countriesOptions, t ]);

  // the origin default value
  const originDefaultValue = useMemo(() => {
    if (!profile?.origin?.country) {
      return null;
    }
    return {
      label: t(`utilities:countries.${ profile.origin.country }`),
      value: profile.origin.country,
    };
  }, [ profile?.origin?.country, t ]);

  // The origin field
  const originField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.origin_label') }
          <Controller
            control={ control }
            defaultValue={ originDefaultValue }
            name='origin.country'
            render={ renderedOriginSelect }
          />
        </label>
      </div>
    );
  }, [ control, originDefaultValue, renderedOriginSelect, role, t ]);

  // day select options
  const daySelectOptions = useMemo(() => {
    return Array(31).fill(0).map((_, i) => {
      return { label: `${ i + 1 }`, value: i + 1 };
    });
  }, []);

  // rendered day select
  const renderedDaySelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        className={
          clsx({
            'error': errors?.bornOn,
          })
        }
        options={ daySelectOptions }
        placeholder={ t('common:profile_details_section.day_placeholder') }
      />
    );
  }, [ daySelectOptions, errors.bornOn, t ]);

  // the day field default value
  const dayFieldDefaultValue = useMemo(() => {
    if (!profile?.bornOn) {
      return null;
    }
    return {
      label: DateTime.fromISO(profile.bornOn).toObject().day.toString(),
      value: DateTime.fromISO(profile.bornOn).toObject().day,
    };
  }, [ profile.bornOn ]);

  // The day field
  const dayField = useMemo(() => {
    return (
      <label>
        <Controller
          control={ control }
          defaultValue={ dayFieldDefaultValue }
          name='bornOn.day'
          render={ renderedDaySelect }
        />
      </label>
    );
  }, [ control, dayFieldDefaultValue, renderedDaySelect ]);

  // the month select options
  const monthSelectOptions = useMemo(() => {
    return Array(12).fill(0).map((_, i) => {
      return { label: months()[i], value: i + 1 };
    });
  }, []);

  // The rendered month select
  const renderedMonthSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        className={
          clsx({
            'error': errors?.bornOn,
          })
        }
        options={ monthSelectOptions }
        placeholder={ t('common:profile_details_section.month_placeholder') }
      />
    );
  }, [ errors?.bornOn, monthSelectOptions, t ]);

  // The month field default value
  const monthFieldDefaultValue = useMemo(() => {
    if (!profile?.bornOn) {
      return null;
    }
    return {
      label: months()[DateTime.fromISO(profile.bornOn).toObject().month - 1],
      value: DateTime.fromISO(profile.bornOn).toObject().month,
    };
  }, [ profile.bornOn ]);

  // The month field
  const monthField = useMemo(() => {
    return (
      <label>
        <Controller
          control={ control }
          defaultValue={ monthFieldDefaultValue }
          name='bornOn.month'
          render={ renderedMonthSelect }
        />
      </label>
    );
  }, [ control, monthFieldDefaultValue, renderedMonthSelect ]);

  // The year options
  const yearOptions = useMemo(() => {
    return Array(currentYear() - MINIMUM_ALLOWED_AGE - 1939).fill(0).map((_, i) => {
      return { label: `${ 1940 + i }`, value: 1940 + i };
    });
  }, []);

  // the rendered year select
  const renderedYearSelect = useCallback(({ field }) => {
    return (
      <Select
        { ...field }
        className={
          clsx({
            'error': errors?.bornOn,
          })
        }
        options={ yearOptions }
        placeholder={ t('common:profile_details_section.year_placeholder') }
      />
    );
  }, [ errors.bornOn, t, yearOptions ]);

  // The month field default value
  const yearFieldDefaultValue = useMemo(() => {
    if (!profile?.bornOn) {
      return null;
    }
    return {
      label: DateTime.fromISO(profile.bornOn).toObject().year.toString(),
      value: DateTime.fromISO(profile.bornOn).toObject().year,
    };
  }, [ profile.bornOn ]);

  // The year field
  const yearField = useMemo(() => {
    return (
      <label>
        <Controller
          control={ control }
          defaultValue={ yearFieldDefaultValue }
          name='bornOn.year'
          render={ renderedYearSelect }
        />
      </label>
    );
  }, [ control, renderedYearSelect, yearFieldDefaultValue ]);

  // The born on field errors
  const bornOnError = useMemo(() => {
    if ('any.custom' !== errors.bornOn?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.born_on_invalid_error') }
      </span>
    );
  }, [ errors.bornOn?.type, t ]);

  // The born on field
  const bornOnField = useMemo(() => {
    if (role !== Roles.JOB_SEEKER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.born_on_label') }
        </label>
        <div className='profile-editor__form-field--born-on'>
          { dayField }
          { monthField }
          { yearField }
        </div>
        { bornOnError }
      </div>
    );
  }, [ bornOnError, dayField, monthField, role, t, yearField ]);

  // The phone number empty field error
  const mobilePhoneNumberEmptyFieldError = useMemo(() => {
    if ('string.empty' !== errors.mobilePhoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.phone_number_required_error') }
      </span>
    );
  }, [ errors.mobilePhoneNumber?.type, t ]);

  // The phone number pattern error
  const mobilePhoneNumberPatternError = useMemo(() => {
    if ('string.pattern.base' !== errors.mobilePhoneNumber?.type) {
      return null;
    }
    return (
      <span className='error-message'>
        { t('common:profile_details_section.phone_number_invalid_error') }
      </span>
    );
  }, [ errors.mobilePhoneNumber?.type, t ]);

  // the mobile phone number field
  const mobilePhoneNumberField = useMemo(() => {
    if (role !== Roles.ENDORSER) {
      return null;
    }
    return (
      <div className='profile-editor__form-field'>
        <label>
          { t('common:profile_details_section.phone_number_label') }
          <input
            className={
              clsx({
                'error': errors?.mobilePhoneNumber,
              })
            }
            defaultValue={ profile?.mobilePhoneNumber || '' }
            id='phoneNumber'
            maxLength={ 14 }
            placeholder={ t('common:profile_details_section.phone_number_placeholder') }
            type='text'
            { ...register('mobilePhoneNumber') }
          />
        </label>
        { mobilePhoneNumberEmptyFieldError }
        { mobilePhoneNumberPatternError }
      </div>
    );
  }, [
    errors?.mobilePhoneNumber,
    mobilePhoneNumberEmptyFieldError,
    mobilePhoneNumberPatternError,
    profile?.mobilePhoneNumber,
    register,
    role,
    t,
  ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { editorTitle }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { websiteField }
        { firstNameField }
        { lastNameField }
        { genderField }
        { mobilePhoneNumberField }
        { locationField }
        { citizenshipField }
        { originField }
        { bornOnField }
        { numberOfEmployeesField }
        { hiresPerYearField }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            disabled={ isNotEmpty(errors) }
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileDetailsSectionEditor.displayName = 'ProfileDetailsSectionEditor';

ProfileDetailsSectionEditor.propTypes = {
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the user.
  profile: PropTypes.oneOfType([
    // The profile of the company
    PropTypes.shape({
      // The company's hires per year.
      hiresPerYear: PropTypes.number,
      // Where the company is located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // The number of company's employees.
      numberOfEmployees: PropTypes.number,
      // The website of the company.
      website: PropTypes.string,
    }),
    // The profile of the endorser
    PropTypes.shape({
      // The first name of the job seeker.
      firstName: PropTypes.string,
      // The gender of the job seeker.
      gender: PropTypes.oneOf(genders),
      // The last name of the job seeker.
      lastName: PropTypes.string,
      // The phone number of the endorser.
      mobilePhoneNumber: PropTypes.string,
    }),
    // The profile of the job seeker
    PropTypes.shape({
      // The date when the job seeker was born.
      bornOn: PropTypes.string,
      // The ISO 3166-1 alpha-3 code of the country of citizenship of the job seeker.
      citizenship: PropTypes.oneOf(countries),
      // The first name of the job seeker.
      firstName: PropTypes.string,
      // The gender of the job seeker.
      gender: PropTypes.oneOf(genders),
      // The last name of the job seeker.
      lastName: PropTypes.string,
      // Where the job seeker is currently located.
      location: PropTypes.shape({
        // The city.
        city: PropTypes.oneOf(allCities),
        // The district.
        district: PropTypes.string,
      }),
      // Where the job seeker comes from.
      origin: PropTypes.shape({
        // The ISO 3166-1 alpha-3 code of the country.
        country: PropTypes.oneOf(countries),
      }),
    }),
  ]).isRequired,
  // The role based on the router
  role: PropTypes.oneOf(roles).isRequired,
};

ProfileDetailsSectionEditor.defaultProps = {};

export default ProfileDetailsSectionEditor;
