/**
 * Endorsers - Learn more.
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import BadgeTickIcon from 'components/common/icons/badge-tick-icon/badge-tick-icon';
import CogIcon from 'components/common/icons/cog-icon/cog-icon';
import LampCardIcon from 'components/common/icons/lamp-card-icon/lamp-card-icon';
import ToolsIcon from 'components/common/icons/tools-icon/tools-icon';
import VideoIcon from 'components/common/icons/video-icon/video-icon';
import PublicLearnMoreSignInSection
  from 'components/common/public-learn-more-sign-in-section/public-learn-more-sign-in-section';
import constants from 'config/constants';
import { actions as endorsersActions } from 'ducks/endorsers';
import { actions as requestsActions } from 'ducks/requests';
import * as endorsersMethods from 'resources/endorsers';
import * as toasts from 'toasts';
import * as Roles from 'utilities/auth/roles';
import { takeFirstN } from 'utilities/chisels';
import { DEFAULT_SORT_KEY } from 'utilities/endorsers/sort-keys';
import { DEFAULT_SORT_DIRECTION } from 'utilities/sort-directions';

import LearnMoreBecomeEndorserPartnerSection
  from './learn-more-become-endorser-partner-section/learn-more-become-endorser-partner-section';
import LearnMoreOurEndorsersSection from './learn-more-our-endorsers-section/learn-more-our-endorsers-section';
import LearnMoreWhatDoesEndorserDoSection
  from './learn-more-what-does-endorser-do-section/learn-more-what-does-endorser-do-section';
import LearnMoreWhySection from './learn-more-why-section/learn-more-why-section';

import './learn-more.scss';

const LearnMore = () => {
  // The auth state object
  const auth = useSelector((store) => {
    return store.auth;
  }, shallowEqual);

  // The endorsers state object
  const endorsers = useSelector((store) => {
    return store.endorsers;
  }, shallowEqual);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const fetchEndorsersPublicProfiles = useCallback(() => {
    dispatch(requestsActions.request(endorsersMethods.getPublicProfiles, {
      page: {
        number: 1,
        size: constants.PAGE_SIZE,
      },
      sort: {
        direction: DEFAULT_SORT_DIRECTION,
        key: DEFAULT_SORT_KEY,
      },
    }, {
      onFailure: (_error) => {
        toasts.error(t('search:search_failed_error'));
      },
      onSuccess: (result) => {
        dispatch(endorsersActions.completeSearch(result));
      },
    }));
  }, [ dispatch, t ]);

  useEffect(() => {
    fetchEndorsersPublicProfiles();
  }, [ fetchEndorsersPublicProfiles ]);

  // whether the user is authenticated
  const userAuthenticated = useMemo(() => {
    return auth?.id !== undefined;
  }, [ auth?.id ]);

  // The endorsers profiles
  const endorsersProfiles = useMemo(() => {
    return takeFirstN(endorsers?.profiles ?? [], 3);
  }, [ endorsers?.profiles ]);

  // The rendered sign in section
  const renderedSignInSection = useMemo(() => {
    if (userAuthenticated) {
      return null;
    }
    return (
      <PublicLearnMoreSignInSection
        role={ Roles.ENDORSER }
        title={ t('endorsers:learn_more.sign_in_section.title') }
      />
    );
  }, [ t, userAuthenticated ]);

  // The endorsers actions collection
  const endorsersActionsCollection = useMemo(() => {
    return [
      {
        description: t('endorsers:learn_more.what_endorser_do_section.endorsers_actions.item1'),
        icon: <VideoIcon />,
      },
      {
        description: t('endorsers:learn_more.what_endorser_do_section.endorsers_actions.item2'),
        icon: <CogIcon />,
      },
      {
        description: t('endorsers:learn_more.what_endorser_do_section.endorsers_actions.item3'),
        icon: <ToolsIcon />,
      },
      {
        description: t('endorsers:learn_more.what_endorser_do_section.endorsers_actions.item4'),
        icon: <LampCardIcon isPurple />,
      },
      {
        description: t('endorsers:learn_more.what_endorser_do_section.endorsers_actions.item5'),
        icon: <BadgeTickIcon />,
      },
    ];
  }, [ t ]);

  return (
    <div className='ody-endorsers-learn-more'>
      { renderedSignInSection }
      <LearnMoreWhySection
        description={ t('endorsers:learn_more.why_section.description') }
        title={ t('endorsers:learn_more.why_section.title') }
      />
      <LearnMoreWhatDoesEndorserDoSection
        endorsersActions={ endorsersActionsCollection }
        title={ t('endorsers:learn_more.what_endorser_do_section.title') }
      />
      <LearnMoreBecomeEndorserPartnerSection
        description={ t('endorsers:learn_more.become_endorser_partner_section.description') }
        exampleEndorsementButtonLabel={
          t('endorsers:learn_more.become_endorser_partner_section.example_endorsement_button_label')
        }
        joinUsButtonLabel={ t('endorsers:learn_more.become_endorser_partner_section.join_us_button_label') }
        title={ t('endorsers:learn_more.become_endorser_partner_section.title') }
        userAuthenticated={ userAuthenticated }
      />
      <LearnMoreOurEndorsersSection
        buttonLabel={ t('endorsers:learn_more.our_endorsers_section.button_label') }
        endorsersProfiles={ endorsersProfiles }
        title={ t('endorsers:learn_more.our_endorsers_section.title') }
      />
    </div>
  );
};

LearnMore.propTypes = {
};

LearnMore.defaultProps = {
};

export default LearnMore;
