/**
 * Resource method to get professions.
 *
 * @module resources/professions/get-professions
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/professions/get-professions~Result
 * @type {object}
 * @property {module:types/professions~Profession[]} professions - The professions.
 */

/**
 * Gets the professions.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/professions/get-professions~Result>} - The promise.
 * @static
 */
const getProfessions = () => {
  return axios.request({
    method: 'get',
    url: '/professions/',
  }).then((response) => {
    return response.data;
  });
};

export default getProfessions;
