/**
 * Resource method to get saved job seeker profiles by the company.
 *
 * @module resources/companies/get-saved-job-seeker-profiles
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef {module:types/job-seekers/profiles~Profile[]} Result
 */

/**
 * Gets a list of the profiles of saved job-seekers.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 * @returns {Promise<module:resources/companies/get-saved-job-seeker-profiles~Result>} - The promise.
 * @static
 */
const getCompanySavedJobSeekers = () => {
  return axios.request({
    method: 'get',
    url: '/companies/profiles/saved',
  }).then((response) => {
    return response.data;
  });
};
export default getCompanySavedJobSeekers;
