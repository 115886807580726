import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

import './learn-more-endorser-action-card.scss';

const LearnMoreEndorserActionCard = memo((props) => {
  const { icon, description } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  return (
    <div
      className={
        clsx('learn-more-endorser-action-card', {
          'learn-more-endorser-action-card__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <div className='learn-more-endorser-action-card__icon-container'>
        { icon }
      </div>
      <p className='txt txt-md light learn-more-endorser-action-card__description'>
        { description }
      </p>
    </div>
  );
});

LearnMoreEndorserActionCard.displayName = 'LearnMoreEndorserActionCard';

LearnMoreEndorserActionCard.propTypes = {
  // The card description
  description: PropTypes.string.isRequired,
  // The card icon
  icon: PropTypes.node.isRequired,
};

LearnMoreEndorserActionCard.defaultProps = {};

export default LearnMoreEndorserActionCard;
