import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import MultiSelect from 'components/common/multi-select/multi-select';
import { useFormWithSchemaBuilder } from 'hooks/use-form-with-validation';
import { areNotEqual } from 'utilities/chisels';

const ProfileProfessionsEditor = memo((props) => {
  const { allProfessions, profile, onSave, onCancel } = props;

  const { t, i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return i18n.language;
  }, [ i18n.language ]);

  // all the professions to be validated
  const allProfessionsToBeValidated = useMemo(() => {
    return allProfessions.map((professions) => {
      return professions.id;
    });
  }, [ allProfessions ]);

  const { control, handleSubmit } = useFormWithSchemaBuilder((Joi) => {
    return Joi.object({
      professions: Joi.array().max(16).items(Joi.object({
        label: Joi.string(),
        value: Joi.string().valid(...allProfessionsToBeValidated),
      })),
    });
  });

  // The params based on the props from profile depending on the role
  const paramsFromProfile = useMemo(() => {
    return {
      professions: profile?.professions,
    };
  }, [ profile?.professions ]);

  // whether the values are to be updated
  const valuesAreToBeUpdated = useCallback((paramsToBeUpdated) => {
    return areNotEqual(paramsFromProfile, paramsToBeUpdated);
  }, [ paramsFromProfile ]);

  // function that constructs the job sectors to be updated
  const constructJobSectorsTobeUpdated = useCallback((professions) => {
    return professions.map((profession) => {
      return profession.value;
    });
  }, []);

  // function that handles the submit of the form
  const onSubmit = useCallback((values) => {
    const paramsToBeUpdated = {
      ...values,
      professions: constructJobSectorsTobeUpdated(values.professions),
    };
    onSave(paramsToBeUpdated, valuesAreToBeUpdated(paramsToBeUpdated));
  }, [ constructJobSectorsTobeUpdated, onSave, valuesAreToBeUpdated ]);

  // Whether the multi select is disabled
  const multiSelectDisabled = useCallback((field) => {
    return 16 <= field.value?.length;
  }, []);

  // The professions options for the multi select component
  const professionsOptions = useMemo(() => {
    return allProfessions.map((profession) => {
      return { label: profession[selectedLanguage], value: profession.id };
    });
  }, [ allProfessions, selectedLanguage ]);

  // The rendered multi select component
  const renderedMultiSelectComponent = useCallback(({ field }) => {
    return (
      <MultiSelect
        { ...field }
        disabled={ multiSelectDisabled(field) }
        options={ professionsOptions }
        placeholder={ t('common:profile_professions.professions_placeholder') }
      />
    );
  }, [ multiSelectDisabled, professionsOptions, t ]);

  // The professions field default value
  const professionsFieldDefaultValue = useMemo(() => {
    if (!profile?.professions?.length) {
      return [];
    }
    return allProfessions.filter((profession) => {
      return -1 !== profile.professions.indexOf(profession.id);
    }).map((profession) => {
      return { label: profession[selectedLanguage], value: profession.id };
    });
  }, [ allProfessions, profile.professions, selectedLanguage ]);

  // The professions field
  const professionsField = useMemo(() => {
    return (
      <div className='profile-editor__form-field'>
        <label>
          <Controller
            control={ control }
            defaultValue={ professionsFieldDefaultValue }
            name='professions'
            render={ renderedMultiSelectComponent }
          />
        </label>
      </div>
    );
  }, [ control, professionsFieldDefaultValue, renderedMultiSelectComponent ]);

  return (
    <div className='profile-editor dark'>
      <h2 className='hdg hdg-md'>
        { t('common:profile_professions.editor_title') }
      </h2>
      <form
        className='profile-editor__form-fields'
        noValidate
        onSubmit={ handleSubmit(onSubmit) }
      >
        { professionsField }
        <div className='profile-editor__actions profile-editor__actions--right'>
          <button
            className='btn btn-sm btn-rounded-sm btn-white'
            onClick={ onCancel }
            type='reset'
          >
            { t('profile:common.cancel_button_label') }
          </button>
          <button
            className='btn btn-sm btn-rounded-sm btn-blue'
            type='submit'
          >
            { t('profile:common.save_button_label') }
          </button>
        </div>
      </form>
    </div>
  );
});

ProfileProfessionsEditor.displayName = 'ProfileProfessionsEditor';

ProfileProfessionsEditor.propTypes = {
  // The all professions
  allProfessions: PropTypes.arrayOf(
    PropTypes.shape({
      // the greek translation of the job sector
      el: PropTypes.string,
      // The english translation of the job sector
      en: PropTypes.string,
      // The id of the job sector
      id: PropTypes.string,
    })
  ).isRequired,
  // The function ((Profile) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func,
  // The function ((Profile) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func,
  // The profile of the user.
  profile: PropTypes.shape({
    // The IDs of the professions that the user is interested in.
    professions: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProfileProfessionsEditor.defaultProps = {};

export default ProfileProfessionsEditor;
