import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslationKey } from 'utilities/chisels';

import ControlledMetricsCard from './controlled-metrics-card/controlled-metrics-card';

import './controlled-metrics-section.scss';

const ControlledMetricsSection = (props) => {
  const { metricsGroup, metricsGroupKey, register, errors } = props;

  const { t } = useTranslation();

  // The rendered controlled metrics cards
  const renderControllerMetricsCards = useCallback(() => {
    if (!metricsGroup) {
      return null;
    }
    return Object.keys(metricsGroup).map((subMetricsKey, index) => {
      return (
        <ControlledMetricsCard
          errors={ errors }
          key={ subMetricsKey + index }
          register={ register }
          subMetric={ metricsGroup?.[subMetricsKey] }
          subMetricsKey={ subMetricsKey }
        />
      );
    });
  }, [ errors, metricsGroup, register ]);

  return (
    <div className='controlled-metrics-section'>
      <h3 className='hdg hdg-sm txt-bold'>
        { t(`administrators:dashboard.controlled_metrics.${ getTranslationKey(metricsGroupKey) }_section_title`) }
      </h3>
      <div className='controlled-metrics-section__cards'>
        { renderControllerMetricsCards() }
      </div>
    </div>
  );
};

ControlledMetricsSection.propTypes = {
  // The errors of the form
  errors: PropTypes.shape({
    // The collaborating companies
    collaboratingCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The employability services per year
    employabilityServicesPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The founded employability per year
    findEmployabilityPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The job positions from odysea's companies
    jobPositionsFromOurCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // the matched job seekers with companies
    matchedJobSeekersWithCompanies: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The people hired
    peopleHired: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The people interviewed
    peopleInterviewed: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // People registered per year
    registeredPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
    // The students registered per year
    studentsPerYear: PropTypes.shape({
      // The error message
      message: PropTypes.string,
      // The reference of the input
      ref: PropTypes.object,
      // The type of the error
      type: PropTypes.string,
    }),
  }),
  // The Metric group
  metricsGroup: PropTypes.oneOfType([
    // the talent platform metrics
    PropTypes.shape({
      // The job positions from odysea's companies
      jobPositionsFromOurCompanies: PropTypes.string,
      // the matched job seekers with companies
      matchedJobSeekersWithCompanies: PropTypes.string,
      // The people hired
      peopleHired: PropTypes.string,
      // The people interviewed
      peopleInterviewed: PropTypes.string,
    }),
    // The organization metrics
    PropTypes.shape({
      // The collaborating companies
      collaboratingCompanies: PropTypes.string,
      // The employability services per year
      employabilityServicesPerYear: PropTypes.string,
      // The founded employability per year
      findEmployabilityPerYear: PropTypes.string,
      // People registered per year
      registeredPerYear: PropTypes.string,
      // The students registered per year
      studentsPerYear: PropTypes.string,
    }),
  ]),
  // The metrics group key
  metricsGroupKey: PropTypes.string,
  // The register function to bind the metric with the form validation
  register: PropTypes.func,
};

ControlledMetricsSection.defaultProps = {};

export default ControlledMetricsSection;
