/**
 * English translations for dashboard.
 *
 * @module languages/en/dashboard
 */
const dashboard = {
  common: {
    changes_saved_message: 'Your changes were saved.',
    general_error: 'Oops! Something went wrong.',
  },
  failed_claimed_endorsement_requests: 'Failed to fetch your claimed endorsement requests',
  failed_created_endorsement_requests: 'Failed to fetch the created endorsements requests',
  failed_download_profile: 'Failed to download profile',
  failed_to_claim_endorsement_request: 'Failed to claim the endorsement request',
  failed_to_revoke_endorsement_request: 'Failed to revoke the endorsement request',
  // eslint-disable-next-line max-len
  full_capacity_title: 'Are you interested in unlocking the full capacity of the platform? Check the pricing plans or contact Odyssea!',
  learn_more_button_title: 'Learn more',
  next_steps: {
    milestones: {
      item1: 'Personal and contact details',
      item2: 'Jobs and job sectors',
      item3: 'Languages and competencies',
      item4: 'Soft skills assessment',
      item5: 'Short-bio and video',
      item6: 'Experience and qualifications',
      item7: 'Resume',
      item8: 'Endorsement',
    },
  },
  profile_overview_and_work_availability: {
    edit_profile_button_label: 'Edit profile',
    work_availability_update_failed_error: 'Update failed.',
    work_availability_updated_message: 'Your work availability was updated.',
  },
  saved_job_seekers: {
    about_title: 'About',
    jobs_interested_title: 'Jobs interested in',
    no_saved_job_seekers: 'You do not have any job seekers saved yet',
    title: 'Saved Job Seekers',
    view_all_button_title: 'View All',
    view_less_button_title: 'View Less',
    view_profile_title: 'View Profile',
  },
  statistics: {
    companies_interested_label: 'Companies interested in you',
    job_ready_profiles: 'Job ready Profiles',
    job_seekers_available: 'Job Seekers Profiles Available',
    new_job_seeker_profiles: 'New Profiles Last Week',
    profile_views_label: 'Companies viewed your profile',
    profile_views_last_week_label: 'Companies viewed your profile last week',
  },
};

export default dashboard;
