/**
 * English translations for reset-password.
 *
 * @module languages/en/reset-password
 */
const resetPassword = {
  general_error: 'Oops! Something went wrong.',
  new_password_and_new_password_confirmation_mismatch_error: 'New password and new password confirmation do not match.',
  new_password_confirmation_label: 'Confirm new password',
  new_password_confirmation_placeholder: 'Confirm new password',
  // eslint-disable-next-line max-len
  new_password_invalid_error: 'New password must contain:\nAt least 8 characters.\nAt least one digit.\nAt least one lowercase letter.\nAt least one uppercase letter.\nAt least one special character (!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~).',
  new_password_label: 'New password',
  new_password_placeholder: 'New password',
  new_password_required_error: 'New password is required.',
  reset_message: 'Your password has been reset. You can now sign in.',
  reset_password_button_label: 'Reset password',
  title: 'Reset password',
};

export default resetPassword;
